import React from 'react'
import SideBarBibliote from '../SideBar/SideBarBibliote'
import PersonlivreCard from './PersonlivreCard'
function PersonlivreCardCompoenent({ getSizeMobille, getMenuMobille }) {
    return (
        <div className='flex justify-between  overflow-hidden bg-white'>
            <SideBarBibliote getMenuMobille={getMenuMobille} />
            <PersonlivreCard getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default PersonlivreCardCompoenent
