/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import Swal from "sweetalert";
import FootterCopy from "../../FootterCopy/FootterCopy";
import { Link } from "react-router-dom";
import { API } from "../../../Api/API";
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { toast } from "react-toastify";
function RetourLivresVisiteur({ SetResumeQui, SetEmpruntId, SetResume, getSizeMobille, LivreReturn, setLivreReturn }) {
    const handleAlert = (id) => {
        Swal({
            title: "Resume du Livre",
            text: "Avez-vous fait le Résume du livre ?? ",
            icon: "info",
            buttons: {
                confirm: "Oui",
                cancel: "Non",
            },
            customClass: {
                icon: "my-swal-icon",
            },
        }).then((value) => {
            if (value) {
                SetResume(true)
                SetEmpruntId(id)
                SetResumeQui("autres")
            } else {
                API.put(`/livres/remisSansResumerVisiteur/${id}`).then((res) => {
                    toast.success("La remise du livre a été enregistrée avec succès.")
                    setLivreReturn((prevLivreReturn) =>
                        prevLivreReturn.filter((livre) => livre.id !== id)
                    );
                }).catch((err) => {
                    toast.warn("La remise du livre n'a pas été enregistrée avec succès.");
                    console.log("err", err.message)
                })
            }
        });
    };
    const [mobile1, Setmonile1] = useState(window.innerWidth < 349)

    const [mobile11, SetMobile11] = useState(window.innerWidth < 501)

    useEffect(() => {
        const hundlesSize = () => {
            Setmonile1(window.innerWidth < 349)
            SetMobile11(window.innerWidth < 501)
        }

        window.addEventListener('resize', hundlesSize)

        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])

    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [totalElements, setTotalElements] = useState(0)
    const [rechercher, setRechercher] = useState(false)
    const [isrechercher, setisRechercher] = useState(0)
    function handleSearch() {
        setRechercher(true)
        setisRechercher(isrechercher + 1)
    }
    useEffect(() => {
        fetchStaff(currentPage, pageSize, searchTerm, rechercher);
    }, [currentPage, pageSize, isrechercher]);

    const fetchStaff = async (page, size, searchTerm, rechercher) => {
        try {
            let url = `/livres/EmpruntsLivreVisiteur?page=${page}&pageSize=${size}`;
            if (rechercher) {
                url = `/livres/EmpruntsLivreVisiteur/search?page=${page}&pageSize=${size}&searchTerm=${searchTerm}`;
            }
            const response = await API.get(url);
            setLivreReturn(response.data.emprunts);
            setTotalPages(response.data.totalPages);
            setTotalElements(response.data.totalItems);
        } catch (error) {
            console.error('Erreur lors de la récupération des livres :', error.message);
        }
    };


    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePageSizeChange = (size) => {
        setPageSize(size);
        setCurrentPage(1);
    };



    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const formatDate = date => {
        return format(new Date(date), "'Le 'd, MMMM yyyy", { locale: fr });
    };

    const getRange = () => {
        const from = (currentPage - 1) * pageSize + 1;
        const to = Math.min(currentPage * pageSize, totalElements);
        return `${from} à ${to} livres sur ${totalElements}`;
    };
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        if (searchTerm.toString().trim().length === 1) {
            setRechercher(false);
            setisRechercher(isrechercher + 1);
        }
    };


    return (

        <>
            <div className={`bg-white  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
                <Link to='/Retour' className="ml-2 text-[18px] text-blue-500 hover:text-blue-800 underline">Retour</Link>

                <div class="my-5  p-4">
                    <div class="w-full flex flex-row items-center justify-between">
                        <p class="text-navy-700 shrink ">
                            <a
                                class="hover:text-navy-700 text-2xl font-bold "

                            >
                                Retour des livres Pour les Visiteurs
                            </a>
                        </p>

                    </div>
                </div>
                <div class="sm:flex items-center pr-4  h-max  justify-end">

                    <div class="mx-2">
                        <div class="relative lg:w-64">
                            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <svg
                                    class="w-5 h-5 text-gray-500"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                        clip-rule="evenodd"
                                    ></path>
                                </svg>
                            </div>
                            <input
                                type="text"
                                name="email"
                                id="topbar-search"
                                class="bg-white border border-gray-300 h-10 text-gray-900 sm:text-lg first-letter:uppercase rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full pl-10 .5"
                                placeholder="Rechercher..."

                            />
                        </div>
                    </div>
                    <button class="mt-4 sm:mt-0 inline-flex items-start justify-start px-1 py-3 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg">
                        <p class="text-lg first-letter:uppercase font-semibold leading-none text-white">
                            Rerchercher
                        </p>
                    </button>
                </div>
                <div class="w-full my-4 grid  p-4 grid-cols-1">
                    <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">
                        <div class="mb-4 flex items-center justify-between">
                            <div>
                                <h3 class="text-xl font-bold text-gray-900 mb-2">
                                    Listes des Livres En Emprunt
                                </h3>
                            </div>
                        </div>
                        <div class="flex flex-col mt-8">
                            <div class="overflow-x-auto rounded-lg">
                                <div class={`align-middle inline-block   ${mobile1 ? 'w-[70em]' : 'min-w-full'}`}>
                                    <div class="shadow overflow-hidden mb-10 sm:rounded-lg">
                                        <table class="min-w-full divide-y  divide-gray-200">
                                            <thead class="bg-gray-50">
                                                <tr>
                                                    <th
                                                        scope="col"
                                                        class=" py-4 px-2 relative border-l text-left cursor-pointer text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                                                    >
                                                        Nom & Prénom

                                                    </th>
                                                    <th
                                                        scope="col"
                                                        class=" py-4 px-2 border-l text-left cursor-pointer relative text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                                                    >
                                                        Tel


                                                    </th>
                                                    <th
                                                        scope="col"
                                                        class=" py-4 px-2 border-l text-left cursor-pointer relative text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                                                    >

                                                        CNI

                                                    </th>
                                                    <th
                                                        scope="col"
                                                        class="  py-4 px-2 border-l flex-shrink-0  cursor-pointer relative  text-left text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                                                    >
                                                        Date d'ajout


                                                    </th>
                                                    <th
                                                        scope="col"
                                                        class="  py-4 px-2 border-l text-left cursor-pointer relative text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                                                    >
                                                        nombre de jours

                                                    </th>
                                                    <th
                                                        scope="col"
                                                        class="  py-4 px-2 border-l text-left cursor-pointer relative text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                                                    >
                                                        jours Restants

                                                    </th>
                                                    <th
                                                        scope="col"
                                                        class="  py-4 px-2 border-l text-left cursor-pointer relative text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                                                    >
                                                        N° Livre


                                                    </th>
                                                    <th
                                                        scope="col"
                                                        class=" py-4 px-2 border-l text-left cursor-pointer relative text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                                                    >
                                                        Action
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody class="bg-white">
                                                {LivreReturn.map((data, index) => {
                                                    return (<tr key={index}>
                                                        <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                            {data.Nom}
                                                        </td>
                                                        <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                            {data.tel}
                                                        </td>
                                                        <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase  text-gray-500">
                                                            {data.cni}
                                                        </td>
                                                        <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase  text-gray-500">
                                                            {formatDate(data.createdAt)}
                                                        </td>
                                                        <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase  text-gray-500">
                                                            {data.nombrejours}

                                                        </td>
                                                        <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase  text-gray-500">

                                                            {data.joursRestants}
                                                        </td>
                                                        <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase  text-gray-500">
                                                            {data.NumeroLivre}

                                                        </td>
                                                        <td class="border-b border-gray-200  pt-2 px-3  border-l border-r whitespace-nowrap text-lg first-letter:uppercase  text-gray-500">
                                                            <div
                                                                onClick={() => handleAlert(data.id)}
                                                                class="my-2 cursor-pointer sm:mt-0 inline-flex items-start justify-start px-3 py-2 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg"
                                                            >
                                                                <p class="text-lg first-letter:uppercase font-semibold leading-none text-white">
                                                                    Retourne
                                                                </p>
                                                            </div>
                                                        </td>
                                                    </tr>)
                                                })}

                                                {LivreReturn.length == 0 &&
                                                    <tr>
                                                        <td colSpan={8} className="h-20 w-full text-center text-2xl text-gray-400"> Aucun élément correspondant à <span className="font-bold">{ }</span> </td>
                                                    </tr>}
                                            </tbody>
                                        </table>
                                        {LivreReturn.length > 0 && (
                                            <>
                                                {/* Pagination */}
                                                {/* Pagination controls */}
                                                <div className="mt-4 flex items-center justify-center">
                                                    <div className="flex items-center">
                                                        <button
                                                            disabled={currentPage === 1}
                                                            onClick={handlePreviousPage}
                                                            className="mx-1 px-3 py-1 rounded bg-gray-300 text-gray-700"
                                                        >
                                                            Précédent
                                                        </button>
                                                        {Array.from({ length: totalPages }, (_, index) => index + 1).map((pageNumber) => (
                                                            <button
                                                                key={pageNumber}
                                                                onClick={() => handlePageChange(pageNumber)}
                                                                className={`mx-1 px-3 py-1 rounded ${currentPage === pageNumber ? 'bg-gray-700 text-white' : 'bg-gray-300 text-gray-700'}`}
                                                            >
                                                                {pageNumber}
                                                            </button>
                                                        ))}
                                                        <button
                                                            disabled={currentPage === totalPages}
                                                            onClick={handleNextPage}
                                                            className="mx-1 px-3 py-1 rounded bg-gray-300 text-gray-700"
                                                        >
                                                            Suivant
                                                        </button>
                                                    </div>
                                                </div>

                                                <div className="mt-4 flex items-center justify-center">
                                                    <p className="text-gray-600">{getRange()}</p>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FootterCopy />
            </div>
        </>

    );
}
export default RetourLivresVisiteur;


