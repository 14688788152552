import React, { useState } from "react";
import AjouterPersonnelComponent from "../../component/Secretariat/DossierPersonnel/AjouterPersonnelComponent";
function AjouterPersonnelPage({ getMenuMobille, getSizeMobille }) {
  return (
    <>
      <AjouterPersonnelComponent getSizeMobille={getSizeMobille} getMenuMobille={getMenuMobille} />
    </>
  );
}

export default AjouterPersonnelPage;
