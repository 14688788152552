import React from 'react'
import SideBarsPrefetDisplin from '../SideBarsPrefetDisplin/SideBarsPrefetDisplin'
import GarconCompartementDortoirePrefetDisplin from './GarconCompartementDortoirePrefetDisplin'
function GarconCompartementDortoirePrefetDisplinComponent({ getSizeMobille, getMenuMobille }) {
    return (
        <div className='flex justify-between overflow-hidden bg-white'>
            <SideBarsPrefetDisplin getMenuMobille={getMenuMobille} />
            <GarconCompartementDortoirePrefetDisplin getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default GarconCompartementDortoirePrefetDisplinComponent
