import React from 'react'
import PresentantEncadrement from './PresentantEncadrement'
import SlideEncadrementNavBars from '../SlideEncadrementNavBars/SlideEncadrementNavBars'
function PresentantEncadrementComponent({ getMenuMobille, getSizeMobille }) {
    return (
        <div className='flex justify-between overflow-hidden bg-white'>
            <SlideEncadrementNavBars getMenuMobille={getMenuMobille} />
            <PresentantEncadrement getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default PresentantEncadrementComponent
