import React, { useState } from "react";
import EmprunterEnseignantComponent from "../../component/Bibliotheque/Emprunter/EmprunterEnseignantComponent";
function EmprunterEnseignantPage({ getSizeMobille, getMenuMobille }) {
  return (
    <>
      <EmprunterEnseignantComponent getMenuMobille={getMenuMobille} getSizeMobille={getSizeMobille} />
    </>
  );
}

export default EmprunterEnseignantPage;
