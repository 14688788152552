/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { API } from '../../../Api/API'
function LatrinePreftDisplin({ getSizeMobille }) {

    const [moblieSize1, GetMobileSize1] = useState(window.innerWidth < 1179)
    const [moblieSize3, GetMobileSize3] = useState(window.innerWidth < 861)
    const [moblieSize4, GetMobileSize4] = useState(window.innerWidth < 587)
    const [mobile11, SetMobile11] = useState(window.innerWidth < 501)
    const [mobile4, SetMobile4] = useState(window.innerWidth < 389)
    useEffect(() => {
        const hundlesSize = () => {
            SetMobile11(window.innerWidth < 501)
            GetMobileSize1(window.innerWidth < 1179)
            GetMobileSize3(window.innerWidth < 861)
            GetMobileSize4(window.innerWidth < 587)
            SetMobile4(window.innerWidth < 384)
        }
        window.addEventListener('resize', hundlesSize)
        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])



    const [latrinesData, setlatrinesData] = useState({})
    useEffect(() => {
        API.get('/LT/getAll').then((resp) => {
            setlatrinesData(resp.data)
        }).catch((err) => {
            console.log(err.message)
        })
    }, [])


    return (
        <>
            <div className={`bg-white  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
                <div className="sm:p-6 p-2 min-h-[90vh]">
                    <div className={`bg-[#0000ff09] flex  relative flex-col items-center mt-4 ${moblieSize1 ? 'w-[99.9900%] ' : 'w-[95%] '}`}>
                        <div className={`w-full text-center  border-b-2 border-white pb-5  font-thin ${moblieSize4 ? 'text-[1rem]' : 'text-2xl'}`}>
                            Latrine
                        </div>
                        <div className='absolute w-full mt-2 right-2 flex justify-end'>
                            <Link to="/Latrines/ajout" class=" font-bold text-gray-900  bg-blue-200 p-2 rounded-full cursor-pointer">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle-fill w-4 h-4 sm:w-6 text-blue-700 sm:h-6" viewBox="0 0 16 16">
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z" />
                                </svg>
                            </Link>
                        </div>
                        <div className={` text-blue-800 bg-[#0000ff1e] my-5 p-2  rounded-md text-center text-xl`}>Nombres total de latrine {latrinesData.Nombres_total_latrine} </div>
                        <div className='w-full  flex justify-between flex-wrap'>
                            <Link to={`/Latrines/Modifier/garçons/${latrinesData.Nombres_reserver_garcon}`} className={`w-max ${mobile4 ? ' p-1' : ' p-4 '} flex-1`}>
                                <div className={`bg-white shadow rounded mx-2 justify-between border border-blue-300 mt-2 flex items-center ${mobile4 ? 'p-[2px]' : 'p-2'}`}>
                                    <div className='flex items-center'>
                                        <div className={`rounded-full flex justify-center items-center border border-blue-700 overflow-hidden ${mobile4 ? ' w-[2.5em] h-[2.5em]' : ' w-[4em] h-[4em]'}`}>
                                            <div className={` ${mobile4 ? 'text-2xl' : 'text-5xl'} `}>🧑🏿‍🤝‍🧑</div>
                                        </div>
                                        <div className={`flex ml-3  ${moblieSize3 ? 'flex-col  items-start' : 'items-center'}`}>
                                            <div>
                                                <div className={`flex`}>
                                                    <div className={`font-semibold ${mobile4 ? 'text-[15px]' : 'text-2xl  '} `}>{latrinesData.Nombres_reserver_garcon}</div>
                                                    <div className={`text-blue-600 ${mobile4 ? 'text-[18px]' : 'text-3xl  '} mx-2`}>≒</div>
                                                    <div className={`text-green-600 ${mobile4 ? 'text-[15px]' : 'text-2xl  '} `}>{(latrinesData.Nombres_reserver_garcon * 100 / latrinesData.Nombres_total_latrine).toFixed(2)}%</div>
                                                </div>
                                                <div className={`text-gray-400 ${mobile4 ? 'text-[12px]' : ''} `}>réservé aux garçons</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`bg-[#0000ff36] ml-2 p-2 rounded-full`}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class={`bi bi-badge-wc-fill text-blue-700 ${mobile4 ? 'w-4 h-4' : 'w-6 h-6'}`} viewBox="0 0 16 16">
                                            <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm11.666 1.89c.682 0 1.139.47 1.187 1.107H14v-.11c-.053-1.187-1.024-2-2.342-2-1.604 0-2.518 1.05-2.518 2.751v.747c0 1.7.905 2.73 2.518 2.73 1.314 0 2.285-.792 2.342-1.939v-.114h-1.147c-.048.615-.497 1.05-1.187 1.05-.839 0-1.318-.62-1.318-1.727v-.742c0-1.112.488-1.754 1.318-1.754zm-6.188.926h.044L6.542 11h1.006L9 5.001H7.818l-.82 4.355h-.056L5.97 5.001h-.94l-.972 4.355h-.053l-.827-4.355H2L3.452 11h1.005l1.02-4.184z" />
                                        </svg>
                                    </div>
                                </div>
                            </Link>
                            <Link to={`/Latrines/Modifier/filles/${latrinesData.Nombres_reserver_fill}`} className={`w-max ${mobile4 ? ' p-1' : ' p-4 '} flex-1`}>
                                <div className={`bg-white shadow rounded mx-2 justify-between border border-blue-300 mt-2 flex items-center ${mobile4 ? 'p-[2px]' : 'p-2'}`}>
                                    <div className='flex items-center'>
                                        <div className={`rounded-full flex justify-center items-center border border-blue-700 overflow-hidden ${mobile4 ? ' w-[2.5em] h-[2.5em]' : ' w-[4em] h-[4em]'}`}>      <div className={` ${mobile4 ? 'text-2xl' : 'text-5xl'} `}>👩‍🤝‍👩</div>
                                        </div>
                                        <div className={`flex ml-3  ${moblieSize3 ? 'flex-col  items-start' : 'items-center'}`}>
                                            <div>
                                                <div className={`flex`}>
                                                    <div className={`font-semibold ${mobile4 ? 'text-[15px]' : 'text-2xl  '} `}>{latrinesData.Nombres_reserver_fill}</div>
                                                    <div className={`text-blue-600 ${mobile4 ? 'text-[18px]' : 'text-3xl  '} mx-2`}>≒</div>
                                                    <div className={`text-green-600 ${mobile4 ? 'text-[15px]' : 'text-2xl  '} `}>{(latrinesData.Nombres_reserver_fill * 100 / latrinesData.Nombres_total_latrine).toFixed(2)}%</div>
                                                </div>
                                                <div className={`text-gray-400 ${mobile4 ? 'text-[12px]' : ''} `}>réservé aux filles</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`bg-[#0000ff36] ml-2 p-2 rounded-full`}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class={`bi bi-badge-wc-fill text-blue-700 ${mobile4 ? 'w-4 h-4' : 'w-6 h-6'}`} viewBox="0 0 16 16">
                                            <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm11.666 1.89c.682 0 1.139.47 1.187 1.107H14v-.11c-.053-1.187-1.024-2-2.342-2-1.604 0-2.518 1.05-2.518 2.751v.747c0 1.7.905 2.73 2.518 2.73 1.314 0 2.285-.792 2.342-1.939v-.114h-1.147c-.048.615-.497 1.05-1.187 1.05-.839 0-1.318-.62-1.318-1.727v-.742c0-1.112.488-1.754 1.318-1.754zm-6.188.926h.044L6.542 11h1.006L9 5.001H7.818l-.82 4.355h-.056L5.97 5.001h-.94l-.972 4.355h-.053l-.827-4.355H2L3.452 11h1.005l1.02-4.184z" />
                                        </svg>
                                    </div>
                                </div>

                            </Link>
                            <Link to={`/Latrines/Modifier/autorites/${latrinesData.Nombres_reserver_Autorite}`} className={`w-max ${mobile4 ? ' p-1' : ' p-4 '} flex-1`}>
                                <div className={`bg-white shadow rounded mx-2 justify-between border border-blue-300 mt-2 flex items-center ${mobile4 ? 'p-[2px]' : 'p-2'}`}>
                                    <div className='flex items-center'>
                                        <div className={`rounded-full flex justify-center items-center border border-blue-700 overflow-hidden ${mobile4 ? ' w-[2.5em] h-[2.5em]' : ' w-[4em] h-[4em]'}`}>      <div className={` ${mobile4 ? 'text-2xl' : 'text-5xl'} `}>👨🏼‍👩🏻‍👧‍👧🏽</div>
                                        </div>
                                        <div className={`flex ml-3  ${moblieSize3 ? 'flex-col  items-start' : 'items-center'}`}>
                                            <div>
                                                <div className={`flex`}>
                                                    <div className={`font-semibold ${mobile4 ? 'text-[15px]' : 'text-2xl  '} `}>{latrinesData.Nombres_reserver_Autorite}</div>
                                                    <div className={`text-blue-600 ${mobile4 ? 'text-[18px]' : 'text-3xl  '} mx-2`}>≒</div>
                                                    <div className={`text-green-600 ${mobile4 ? 'text-[15px]' : 'text-2xl  '} `}>{(latrinesData.Nombres_reserver_Autorite * 100 / latrinesData.Nombres_total_latrine).toFixed(2)}%</div>
                                                </div>
                                                <div className={`text-gray-400 ${mobile4 ? 'text-[12px]' : ''} `}>réservé aux autorites</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`bg-[#0000ff36] ml-2 p-2 rounded-full`}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class={`bi bi-badge-wc-fill text-blue-700 ${mobile4 ? 'w-4 h-4' : 'w-6 h-6'}`} viewBox="0 0 16 16">
                                            <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm11.666 1.89c.682 0 1.139.47 1.187 1.107H14v-.11c-.053-1.187-1.024-2-2.342-2-1.604 0-2.518 1.05-2.518 2.751v.747c0 1.7.905 2.73 2.518 2.73 1.314 0 2.285-.792 2.342-1.939v-.114h-1.147c-.048.615-.497 1.05-1.187 1.05-.839 0-1.318-.62-1.318-1.727v-.742c0-1.112.488-1.754 1.318-1.754zm-6.188.926h.044L6.542 11h1.006L9 5.001H7.818l-.82 4.355h-.056L5.97 5.001h-.94l-.972 4.355h-.053l-.827-4.355H2L3.452 11h1.005l1.02-4.184z" />
                                        </svg>
                                    </div>
                                </div>

                            </Link>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default LatrinePreftDisplin