import React from 'react'
import NeuwPrevisionMatiersEns from './NeuwPrevisionMatiersEns'
import SildeEnseign from '../SildeEnseign/SildeEnseign'
function NeuwPrevisionMatiersEnsComponent() {
  return (
    <div class="overflow-hidden flex justify-between">
      <SildeEnseign />
      <NeuwPrevisionMatiersEns />
    </div>
  )
}

export default NeuwPrevisionMatiersEnsComponent
