import React from "react";
import SideBarSecretaire from "../SideBarSecretaire/SideBarSecretaire";
import DetailDossierEleveSecretaire from "./DetailDossierEleveSecretaire";
function DetailDossierComponent({ getSizeMobille, getMenuMobille }) {
  return (
    <>
      <div class="overflow-hidden flex justify-between">
        <SideBarSecretaire getMenuMobille={getMenuMobille} />
        <DetailDossierEleveSecretaire getSizeMobille={getSizeMobille} />
      </div>
    </>
  );
}

export default DetailDossierComponent;
