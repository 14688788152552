import React, { useState } from "react";
import EmprunterEleveListeComponent from "../../component/Bibliotheque/Emprunter/EmprunterEleveListeComponent";
function EmprunterEleveListePage({ getSizeMobille, getMenuMobille }) {
  return (
    <>
      <EmprunterEleveListeComponent getMenuMobille={getMenuMobille} getSizeMobille={getSizeMobille} />
    </>
  );
}

export default EmprunterEleveListePage;
