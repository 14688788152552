/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import FootterCopy from "../../FootterCopy/FootterCopy";
import "./sweetalert.css";
import { API, STATIC_URL } from "../../../Api/API";
import { decryptData, encryptData } from "../../../encryptionModule";
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
function DetailDossierEleveSecretaire({ getSizeMobille }) {
  const { id, classId, schoolyearId } = useParams()
  const handleConfirmation = async () => {
    try {
      const result = await swal({
        title: "Confirmation",
        text: "Vous Voulez Supprimer ?",
        icon: "warning",
        buttons: ["No", "Oui"],
        dangerMode: true,
        className: "centered-buttons",
      });

      if (result) {

        console.log("Confirmed");
      } else {

      }
    } catch (error) {

      console.log("Error or dismissal:", error);
    }
  };
  const [mobile1, Setmonile1] = useState(window.innerWidth < 349)

  const [mobile11, SetMobile11] = useState(window.innerWidth < 501)
  const [oneStudent, setOneState] = useState({})
  useEffect(() => {
    const hundlesSize = () => {
      Setmonile1(window.innerWidth < 349)
      SetMobile11(window.innerWidth < 501)
    }

    window.addEventListener('resize', hundlesSize)

    return () => {
      window.removeEventListener('resize', hundlesSize)
    }
  }, [])
  const studentid = decryptData(id)

  useEffect(() => {
    API.get(`/student/studentDetails/${studentid}`).then((res) => {
      setOneState(res.data)
    }).catch((err) => {
      console.log(err.message)
    })
  }, [studentid])
  const formatDate = date => {
    return format(new Date(date), "'Le 'd, MMMM yyyy", { locale: fr });
  };


  return (
    <>
      <div className={`bg-white  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>

        <Link to={`/ClasseChoixSecretaire/DossierEleve/${schoolyearId}/${classId}`} className="ml-2 text-[18px] text-blue-500 hover:text-blue-800 underline">Retour</Link>
        <div className="sm:p-6 p-2">
          <div className="text-[25px] text-gray-700 font-bold">Les dossiers</div>
          <div class="w-full sm:my-4 my-1 grid grid-cols-1 ">
            <div class="bg-white shadow rounded-lg flex justify-center p-2 sm:p-6 xl:p-8">
              <div class=" rounded-lg bg-white border flex items-center w-full sm:w-max p-4 justify-center flex-col ">

                {oneStudent && oneStudent.student && oneStudent.student.photo && (
                  <div className="max-w-[200px]  rounded-md max-h-[200px] bg-red-500">
                    <img
                      src={`${STATIC_URL}/students/${oneStudent.student.photo}`}
                      alt=" "
                      className="w-full h-full object-cover"
                    />
                  </div>
                )}

                <div class="flex flex-col justify-start sm:p-6 p-2">
                  <p class="mb-4  text-xl text-neutral-600 ">
                    Nom & Prenom : {oneStudent && oneStudent.student && oneStudent.student.nomprenom}
                  </p>
                  <p class="mb-4  text-xl text-neutral-600 ">
                    Matricule : {oneStudent && oneStudent.student && oneStudent.student.matricule}
                  </p>
                  <p class="mb-4  text-xl text-neutral-600 ">
                    Lieu de naissance :{oneStudent && oneStudent.student && oneStudent.student.lieu}
                  </p>
                  <p class="mb-4  text-xl text-neutral-600 ">
                    Date de naissance :{oneStudent && oneStudent.student && formatDate(oneStudent.student.date)}
                  </p>
                  <p class="mb-4  text-xl text-neutral-600 ">
                    Nom du Pere:{oneStudent && oneStudent.student && oneStudent.student.nomP}
                  </p>
                  <p class="mb-4  text-xl text-neutral-600 ">
                    Nom de la Mere :{oneStudent && oneStudent.student && oneStudent.student.nomM}
                  </p>
                  <p class="mb-4  text-xl text-neutral-600 ">
                    Religion :{oneStudent && oneStudent.student && oneStudent.student.religion}
                  </p>

                  <p class="mb-4  text-xl text-neutral-600 ">
                    situation :{oneStudent && oneStudent.student && oneStudent.student.type_acces}
                  </p>

                  <p class="mb-4  text-xl text-neutral-600 ">
                    Ecole d'origine :{oneStudent && oneStudent.student && oneStudent.student.origine}
                  </p>

                  <p class="mb-4  text-xl text-neutral-600 ">
                    Classe :{oneStudent && oneStudent.classe && oneStudent.classe.nom}
                  </p>

                  <p class="mb-4  text-xl text-neutral-600 ">
                    Nationalite :{oneStudent && oneStudent.student && oneStudent.student.nationalite}
                  </p>

                  <p class="text-lg text-neutral-500 ">
                    Inscrit {oneStudent && oneStudent.student && oneStudent.student.createdAt && formatDate(oneStudent.student.createdAt)}
                  </p>
                  <div class=" mt-8 flex items-center justify-end ">
                    <div class={`flex flex-row flex-shrink-0  ${mobile1 ? ' flex-col w-full' : 'space-x-3'}`}>

                      {oneStudent && oneStudent.student && (
                        <Link
                          to={`/ClasseChoixSecretaire/DossierEleve/ModifierEleve/${encryptData((oneStudent.student.id).toString())}`}
                          class="mt-4 sm:mt-0 inline-flex items-start justify-start px-6 py-3 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg"
                        >
                          <p class="text-lg font-medium text-center w-full leading-none text-white">
                            Modifier les informations personnel
                          </p>
                        </Link>
                      )
                      }


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FootterCopy />
      </div>
    </>
  );
}

export default DetailDossierEleveSecretaire;
