/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import FootterCopy from "../../FootterCopy/FootterCopy";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select"
import { FadeLoader } from 'react-spinners'
import { API } from "../../../Api/API";

function AddHoraireEncaPreftDisplin({ getSizeMobille }) {
const history = useNavigate()
    const [mobile11, SetMobile11] = useState(window.innerWidth < 501)

    useEffect(() => {
        const hundlesSize = () => {
            SetMobile11(window.innerWidth < 501)
        }
        window.addEventListener('resize', hundlesSize)
        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])



    const [mobile, SetMobile] = useState(window.innerWidth <= 640)
    const [eledemmade, GeteleDemmande] = useState([])
    const [boutLoading, setboutLoading] = useState(false)

    useEffect(() => {
        const hundleSize = () => {
            SetMobile(window.innerWidth <= 640)
        }
        window.addEventListener('resize', hundleSize)

        return () => {
            window.removeEventListener('resize', hundleSize)
        }
    }, [])

    const OptionEncadreur = [];
const [isloading, setloading] = useState(true);
    API.get('/staff/getAllEncadreur').then(response => {
        const encadreurs = response.data;
        setloading(false)
        encadreurs.forEach(encadreur => {
          const value = `${(encadreur.id).toString()}`;
          const label = `${encadreur.nomcomplet}: ${encadreur.matricule}`;
          OptionEncadreur.push({ value, label });
        });
    
      })
      .catch(error => {
        setloading(false)
        console.error(error);
      });

       
    const Optiondate = [ 
        { value: '', label: 'Lundi' },      
        { value: 'Lundis', label: 'Lundi' },

        { value: 'Mardi', label: 'Mardi' },
        { value: 'Mercredi', label: 'Mercredi' },
        { value: 'Jeudi', label: 'Jeudi' },
        { value: 'Vendredi', label: 'Vendredi' },
        { value: 'Samedi', label: 'Samedi' },
        { value: 'Dimanche', label: 'Dimanche' },
    ]

       
    const OptionHeure = [
        { value: '', label: 'Heure de travail' },
        { value: '8h à 14h', label: '8h à 14h' },
        { value: '14h à 17h30', label: '14h à 17h30' },
        { value: '17h30 à 8h du matin', label: '17h30 à 8h du matin' }
    ]

  
    const hiddenOptiondate = Optiondate.slice(1);
    const hiddenOptionHeure = OptionHeure.slice(1);


    const customStyles = {
        control: (provided) => ({
            ...provided,
            height: mobile ? 25 : 50,
            backgroundColor: "white",
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? "blue" : state.isFocused ? "lightblue" : "white",
            color: state.isSelected ? "white" : "black",
        }),
    };

    const [blocs, setBlocs] = useState([
        { nomEncadreur: '', date: '', heures: '',labelEnc:"" }
    ]);
    const addBloc = () => {
        setBlocs([...blocs, { nomEncadreur: '', date: '', heures: '',labelEnc:"" }]);
    };
    const removeBloc = (index) => {
        const newBlocs = [...blocs];
        newBlocs.splice(index, 1);
        setBlocs(newBlocs);
    };
    const inputRefs = useRef([]);
    if (inputRefs.current.length !== blocs.length) {
        inputRefs.current = Array(blocs.length).fill({}).map((_, index) => ({
            nomEncadreur: React.createRef(null),
            date: React.createRef(null),
            heures: React.createRef(null),
            labelEnc: React.createRef(null),
        }));
    }

    const handleInputChange = (index, field, value) => {
        const newBlocs = [...blocs];
        newBlocs[index][field] = value;
        setBlocs(newBlocs);
        if (value && inputRefs.current[index][field].current) {
            inputRefs.current[index][field].current.classList.remove('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500');
        }
    };


    const animateAndScrollToRef = (ref) => {
        if (ref && ref.current) {
            ref.current.classList.add('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500', 'outline-none');
            setTimeout(() => {
                ref.current.classList.remove('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500', 'outline-none');
            }, 5000);
            ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            ref.current.focus();
        }
    };


    const validateBloc = (bloc, index) => {
        const requiredFields = ['nomEncadreur', 'date', 'heures'];

        //Validation pour les champs vide
        for (const field of requiredFields) {
            if (!bloc[field]) {
                toast.warning(<div>Le <strong>{field == 'nomEncadreur' ? 'nom d\'encadreur ' : field == 'date' ? 'date de travail ' : field == 'heures' ? 'heurs de travail' : null}</strong> est obligatoire à l'horaire {index + 1}.</div>);
                if (inputRefs.current[index][field].current) {
                    inputRefs.current[index][field].current.classList.add('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500', 'outline-none');
                    setTimeout(() => {
                        inputRefs.current[index][field].current.classList.remove('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500', 'outline-none');
                    }, 3000);
                    inputRefs.current[index][field].current.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    inputRefs.current[index][field].current.focus();
                    return false;
                }
            }
        }

        //Validation pour empeche le personne le travail le meme date les heures differentes && b.nomEncadreur.value === bloc.nomEncadreur.value
        const isSamenomEncadreur = blocs.some((b, i) => i !== index  && b.date === bloc.date && b.heures.value === bloc.heures.value)
        if (isSamenomEncadreur) {
            toast.warning(<div>Vous avez déjà utilise le nom <span className="font-bold">{bloc.labelEnc.label}</span> qui travaillera <span className="font-bold">{bloc.date}</span> de <span className="font-bold">{bloc.heures.value}</span> </div>);
            animateAndScrollToRef(inputRefs.current[index].date);
                return false;
        }

        //Validation pour eviter de sousmettre deux block de meme information
        const isdepluque = blocs.some((b, i) => i !== index && b.nomEncadreur.value === bloc.nomEncadreur.value && b.date === bloc.date && b.heures.value === bloc.heures.value);
        if (isdepluque) {
            const duplicateBlockIndex = blocs.findIndex((b, i) => i !== index && b.nomEncadreur.value === bloc.nomEncadreur.value && b.date === bloc.date && b.heures.value === bloc.heures.value);
            const duplicateBlockNumber = toRoman(duplicateBlockIndex + 1);
            toast.warning(
                <div>
                    Évitez la redondance, l'horaire <span className="font-bold">{toRoman(index + 1)}</span> et l'horaire <span className="font-bold">{duplicateBlockNumber}</span> ont la même information.
                </div>
            );
            animateAndScrollToRef(inputRefs.current[index].date);
            return false;
        }
        return true;
    };



    function toRoman(num) {
        const romanNumerals = [
            "I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX", "X",
            "XI", "XII", "XIII", "XIV", "XV", "XVI", "XVII", "XVIII", "XIX", "XX",
            "XXI", "XXII", "XXIII", "XXIV", "XXV", "XXVI", "XXVII", "XXVIII", "XXIX", "XXX"
        ];
        return romanNumerals[num - 1] || num.toString();
    }


    const Datas = blocs.map((bloc) => ({
        staffId: bloc.nomEncadreur,
        date: bloc.date,
        time: bloc.heures.value,
    }))


    const handleSubmit = (e) => {
        e.preventDefault();
        for (let index = 0; index < blocs.length; index++) {
            if (!validateBloc(blocs[index], index)) {
                return;
            }           
        }

     


        API.post(`/HI/addHoraireaugarde`, Datas)
    .then(response => {        
        toast.success('Données envoyées avec succès');
        history("/Encadreur/List")
    })
    .catch(error => {
        if (error.response) {
            
            const errorData = error.response.data;
            console.log(errorData)
            if (Array.isArray(errorData)) {
               
                toast.error(errorData[0]);
                return false;
            } else {
                toast.error(errorData || 'Erreur serveur');
                return false;
            }
        } else {
            toast.error('Erreur serveur');
        }
    });
    
    };

 
    return (
        !isloading &&(
        <div className={`bg-white  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
            <Link to='/Encadreur/List' className="ml-2 text-[18px] text-blue-500 hover:text-blue-800 underline">Retour</Link>

            <div className="sm:p-4 p-2 min-h-screen">
                <div class="my-5">
                    <div class="w-full flex flex-row items-center justify-between">
                        <p class="text-navy-700   ">
                            <a
                                class="hover:text-navy-700 text-2xl font-bold  "
                            >
                                Enregistrement des horaires
                            </a>
                        </p>

                    </div>
                </div>
                <div className="w-full my-4 grid grid-cols-1">
                    <div className="w-full my-4 grid grid-cols-1">
                        <form onSubmit={handleSubmit} className="flex flex-col items-center">
                            <div className="rounded-lg p-2 border border-blue-500 md:w-[40em] w-[95%] sm:p-4">
                                {blocs.map((bloc, index) => (
                                    <div key={index} className="justify_center rounded-xl w-full  p-1 sm:p-2 mb-10  border-[1px] flex flex-col">
                                        <div className="w-full">
                                            <div className="h-max w-full">
                                                <div className="px-3  w-full">
                                                    <div className="w-full flex items_center justify-between">
                                                        <label className="block  text-xs mt-5 font-serif text-gray-700 first_letter:uppercase tracking_wide text-grey-darker sm:text-lg  mb-1">
                                                            {toRoman(index + 1)}. Horaire
                                                        </label>
                                                        {index > 0 && (
                                                            <button
                                                                montant="button"
                                                                className="text-red-500 px-1 max-sm:text-xs py-2"
                                                                onClick={() => removeBloc(index)}
                                                            >
                                                                Supprimer
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mb-2">
                                            <div className="rounded-xl p-2 w-full">
                                                <div className="flex flex-col">
                                                    <div className="rounded-lg w-full">
                                                        <div class="w-full mb-5">
                                                            <label class="block  tracking-wide text-gray-700 text-xs sm:text-lg  mb-1">
                                                                Nom d'encadreur
                                                            </label>
                                                            <div ref={inputRefs.current[index].nomEncadreur} className={` rounded `}>
                                                                <Select
                                                                    value={bloc.labelEnc}
                                                                    options={OptionEncadreur}
                                                                    styles={customStyles}
                                                                    onChange={(option) =>{ handleInputChange(index, "nomEncadreur", option.value);  handleInputChange(index, "labelEnc", option); }} />
                                                            </div>
                                                                                       
                                                            

                                                                                                </div>
                                                    </div>
                                                    <div className="rounded-lg w-full">
                                                        <div class="w-full mb-5">
                                                            <label class="block  tracking-wide text-gray-700 text-xs sm:text-lg  mb-1">
                                                                Date de travail
                                                            </label>
                                                           
                                                            <div ref={inputRefs.current[index].date} className={` rounded border  py-3 pl-3 `}>
                                                                <input type="date"
                                                                             className="outline-none border-none"                         
                                                                    onChange={(option1) => handleInputChange(index, "date", option1.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="rounded-lg w-full">
                                                        <div class="w-full mb-5">
                                                            <label class="block  tracking-wide text-gray-700 text-xs sm:text-lg  mb-1">
                                                                Heure de travail
                                                            </label>
                                                            <div ref={inputRefs.current[index].heures} className={` rounded `}>
                                                                <Select
                                                                    value={bloc.heures}
                                                                    options={hiddenOptionHeure}
                                                                    styles={customStyles}
                                                                    onChange={(option2) => handleInputChange(index, "heures", option2)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <div className="w-full flex justify-end">
                                    <button montant="button" className="bg-blue-500 hover-bg-blue-700 text-white py-1 px-2  max-sm:text-xs sm:py-2 sm:px-4 rounded" onClick={addBloc}>
                                        Ajouter autre heure
                                    </button>
                                </div>
                            </div>

                            <div className=" flex justify-end  md:w-[40em] w-[95%]">

                                {boutLoading ? (
                                    <>
                                        <label disabled className="cursor-no-drop w-max relative  mt-3 flex justify-center  items-center   bg-green-950    p-2 rounded  text-gray-400">
                                            <input type="submit" id="send" value='Soumettre' className='pointer-events-none' />
                                            <i class="bi bi-send ml-2  pointer-events-none "></i>
                                            <div disabled className='absolute pointer-events-none  bg-transparent pt-4  pl-4  w-full h-full flex justify-center items-center z-50'>
                                                <FadeLoader
                                                    color="rgb(255, 255, 255)"
                                                    height={10}
                                                    margin={-9}
                                                    radius={100}
                                                    speedMultiplier={1}
                                                    width={1}
                                                />
                                            </div>
                                        </label>
                                    </>
                                ) : (<>
                                    <label for="send" className=" mt-3 cursor-pointer w-max  flex justify-end  bg-green-600   p-2 rounded  text-white">
                                        <input type="submit" id="send" value='Soumettre' className='cursor-pointer'></input>
                                        <i class="bi bi-send ml-2 "></i>
                                    </label>
                                </>)}

                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <FootterCopy />
        </div>)

    );
}


export default AddHoraireEncaPreftDisplin;










