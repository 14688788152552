import React, { useState } from "react";
import StatsComponent from "../../component/Bibliotheque/Stats/StatsComponent";
function StatsPage({getSizeMobille,getMenuMobille}) {
  return (
    <>
      <StatsComponent getMenuMobille={getMenuMobille} getSizeMobille={getSizeMobille} />
    </>
  );
}

export default StatsPage;
