import React from 'react'
import SideBarSecretaire from '../SideBarSecretaire/SideBarSecretaire'
import CongeAnnuelDetail from './CongeAnnuelDetail'
function CongeAnnuelSecretDetailComponent({ getMenuMobille, getSizeMobille }) {
    return (
        <div className='flex justify-between overflow-hidden bg-white'>
            <SideBarSecretaire getMenuMobille={getMenuMobille} />
            <CongeAnnuelDetail getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default CongeAnnuelSecretDetailComponent
