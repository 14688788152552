import React, { useState } from "react";
import AjouterComponent from "../../component/Bibliotheque/Ajouter/AjouterComponent";
function AjouterPage({ getSizeMobille, getMenuMobille }) {
  return (
    <>
      <AjouterComponent getMenuMobille={getMenuMobille} getSizeMobille={getSizeMobille} />
    </>
  );
}

export default AjouterPage;
