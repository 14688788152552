import React from "react";
import MedicamentsExpiresComponent from "../../component/Infirmerie/MedicamentExpire/MedicamentsExpiresComponent";
function MedicamentsExpiresPage({ getSizeMobille, getMenuMobille }) {
  return (
    <>
      <MedicamentsExpiresComponent getMenuMobille={getMenuMobille} getSizeMobille={getSizeMobille} />
    </>
  );
}

export default MedicamentsExpiresPage;
