import React, { useState } from "react";
import DepensesComponent from "../../component/Bibliotheque/Depenses/DepensesComponent";
function DepensesPage({ getSizeMobille, getMenuMobille }) {
  return (
    <>
      <DepensesComponent getMenuMobille={getMenuMobille} getSizeMobille={getSizeMobille} />
    </>
  );
}

export default DepensesPage;
