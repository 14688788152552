/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FootterCopy from "../../FootterCopy/FootterCopy";
import { API } from "../../../Api/API";
import { decryptData, encryptData } from "../../../encryptionModule";
function EmprunterEleve({ getSizeMobille }) {

  const [mobile1, Setmonile1] = useState(window.innerWidth < 349)

  const [mobile11, SetMobile11] = useState(window.innerWidth < 501)

  useEffect(() => {
    const hundlesSize = () => {
      Setmonile1(window.innerWidth < 349)
      SetMobile11(window.innerWidth < 501)
    }
    window.addEventListener('resize', hundlesSize)
    return () => {
      window.removeEventListener('resize', hundlesSize)
    }
  }, [])

  const [CountByClassAndLasterSchoolYear, setCountByClassAndLasterSchoolYear] = useState([])
  useEffect(() => {
    API.get('/student/CountByClassAndLasterSchoolYear').then((resp) => {
      console.log(resp.data)
      setCountByClassAndLasterSchoolYear(resp.data)
    }).catch((err) => {
      console.log(err.message)
    })
  }, [])

  return (

    <div className={`bg-white  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
      <Link to='/Emprunter' className="ml-2 text-[18px] text-blue-500 hover:text-blue-800 underline">Retour</Link>

      <div className="sm:p-4 p-2 min-h-screen">
        <div class="my-5 flex items-center justify-center">
          <h3 class="text-xl sm:text-2xl max-sm:w-full max-sm:text-center leading-none font-bold text-gray-900">
            Quelle Classe L'elève etudie ?
          </h3>
        </div>

        <div class="my-4 w-full flex flex-wrap  gap-4">


          {CountByClassAndLasterSchoolYear.map((value) => {
            return (
              <Link to={`/Emprunter/eleve/Liste/${encryptData((value.schoolyearId).toString())}/${encryptData((value.classId).toString())}`} class="bg-white shadow rounded-lg hover:scale-105 transition-all cursor-pointer m-2 p-8 ">
                <div class="flex items-center">
                  <div class="">
                    <span class="text-2xl sm:text-3xl leading-none font-bold text-blue-900">
                      {value.class}
                    </span>
                    <h3 class="text-xl font-normal w-full text-center text-gray-500">
                      Nombre total  des élèves
                    </h3>
                  </div>
                  <div class="w-9 h-9    mb-4 text-blue-900 font-bold transition duration-75"
                  >
                    <div class="w-9 h-9    mb-4 text-green-700 font-bold transition duration-75"
                    >
                      {value.totaleleves}
                    </div>
                  </div>

                </div>

                <div className={`flex justify-between ${mobile1 ? 'flex-col ' : ''} mt-10`}>
                  <div class="flex items-center">
                    <div class="">
                      <span class="text-2xl sm:text-2xl leading-none font-bold text-blue-900">
                        {value.Filles}
                      </span>
                      <h3 class="text-xl font-normal text-gray-500">
                        Féminin
                      </h3>
                    </div>
                    <div class="w-9 h-9    mb-4 text-green-700 font-bold transition duration-75"
                    >
                      {parseFloat((value.Filles * 100 / value.totaleleves).toFixed(1))}%
                    </div>

                  </div>
                  <div class="flex items-center mt-2">
                    <div class="">
                      <span class="text-2xl sm:text-2xl leading-none font-bold text-blue-900">
                        {value.garcon}
                      </span>
                      <h3 class="text-xl font-normal text-gray-500">
                        Garcon
                      </h3>
                    </div>
                    <div class="w-9 h-9    mb-4 text-blue-900 font-bold transition duration-75"
                    >
                      <div class="w-9 h-9    mb-4 text-green-700 font-bold transition duration-75"
                      >
                        {parseFloat((value.garcon * 100 / value.totaleleves).toFixed(1))}%
                      </div>
                    </div>

                  </div>

                </div>
                <div className='mt-5 font-serif text-gray-600'>Année scolaire :<span className='text-blue-900 tracking-widest'>{value.aneescholaire}</span> </div>
              </Link>
            )
          })}
        </div>
      </div>
      <FootterCopy />

    </div>

  );
}

export default EmprunterEleve;
