/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import Chart from "react-apexcharts";
import "apexcharts/dist/apexcharts.css";
import FootterCopy from '../../FootterCopy/FootterCopy';
function NoteDispline({ getSizeMobille }) {
    const options = {
        chart: {
            id: "basic-bar",
        },
        xaxis: {
            categories: [
                "Le 15/09/2023",
                "Le 15/09/2023",
                "Le 15/09/2023",
                "Le 15/09/2023",
                "Le 15/09/2023",
                "Le 15/09/2023",
                "Le 15/09/2023",
            ],
        },
    };

    const series = [
        {
            name: "Point restant",
            data: [45, 40, 36, 30, 20, 16, 12],
        },
    ]
    const [mobile1, Setmonile1] = useState(window.innerWidth < 621)

    const [mobile11, SetMobile11] = useState(window.innerWidth < 501)

    useEffect(() => {
        const hundlesSize = () => {
            Setmonile1(window.innerWidth < 621)
            SetMobile11(window.innerWidth < 501)
        }

        window.addEventListener('resize', hundlesSize)

        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])


    return (

        <div className={`bg-white  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>

            <div className="p-4 min-h-screen">
                <div class="sm:my-10 my-3">
                    <div class="w-full flex flex-row items-center justify-between">
                        <p class="text-navy-700  ">
                            <p
                                class="hover:text-navy-700 text-2xl font-bold text-gray-500 "

                            >
                                Les pointes displinaire
                            </p>
                        </p>

                    </div>
                </div>

                <div class="w-full my-4">
                    <div class="overflow-x-auto sm:p-4 rounded-lg">
                        <div class="align-middle   inline-block min-w-full">
                            <div class="shadow   overflow-hidden sm:rounded-lg">
                                <table class="w-full">
                                    <thead>
                                        <th class="border-l  border-gray-200 text-start p-2 whitespace-nowrap text-lg  text-gray-500">Date</th>
                                        <th class="border-l  border-gray-200 text-start p-2 whitespace-nowrap text-lg  text-gray-500">Points retraits</th>
                                        <th class="border-l  border-gray-200 text-start p-2 whitespace-nowrap text-lg  text-gray-500">Faute comise</th>
                                    </thead>
                                    <tbody class="bg-white">
                                        <tr><td class="border-l  border-gray-200 text-start p-2 whitespace-nowrap text-lg  text-gray-500">Le 15/09/2023</td><td class="border-l  border-gray-200 text-start p-2 whitespace-nowrap text-lg  text-gray-500">9 points</td><td class="border-l  border-gray-200 text-start p-2 whitespace-nowrap text-lg  text-gray-500">Sorties non autorise</td></tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <>

                        <div

                            class="h-full w-full px-2 bg-gray-50 relative overflow-y-auto "
                        >
                            <main>
                                <div class="pt-6  sm:px-4 ">


                                    <div class="w-full grid grid-cols-1 gap-4">
                                        <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 2xl:col-span-2">
                                            <div class="flex items-center justify-between mb-4">
                                                <div class="sm:flex-shrink-0">
                                                    <h3 class="text-xl text-gray-600 max-sm:text-center sm:text-2xl leading-none font-bold ">
                                                        Evolution de retrait des points disciplinaire
                                                    </h3>
                                                </div>

                                            </div>
                                            <div id="main-chart" style={{ "min-height": "435px" }}>
                                                <Chart
                                                    options={options}
                                                    series={series}
                                                    type="line"
                                                    height={500}
                                                />
                                            </div>
                                        </div>

                                    </div>


                                </div>
                            </main>

                        </div>{" "}
                    </>
                </div>
            </div>

            <FootterCopy />
        </div>

    )
}

export default NoteDispline
