/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

function NavBArsCantin() {
    return (
        <>

            <nav class="bg-white border-b-2 h-[12.9vh] top-0 left-0 border-blue-500 fixed z-30 w-full">
                <div class="px-3 py-3 lg:px-5 lg:pl-3">
                    <div class="flex items-center justify-between">
                        <div class="flex items-center justify-start">
                            <button
                                id="toggleSidebarMobile"
                                aria-expanded="true"
                                aria-controls="sidebar"
                                class="lg:hidden mr-2 text-gray-600 hover:text-gray-900 cursor-pointer p-2 hover:bg-gray-100 focus:bg-gray-100 focus:ring-2 focus:ring-gray-100 rounded"
                            >
                                <svg
                                    id="toggleSidebarMobileHamburger"
                                    class="w-6 h-6"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                                        clip-rule="evenodd"
                                    ></path>
                                </svg>
                                <svg
                                    id="toggleSidebarMobileClose"
                                    class="w-6 h-6 hidden"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                        clip-rule="evenodd"
                                    ></path>
                                </svg>
                            </button>
                            <a
                                href="#"
                                class="text-3xl font-extrabold flex items-center lg:ml-2.5"
                            >
                                <img src="/images/logo.png" class="h-14 mr-2" alt="LENGA" />
                                <div class="flex flex-row">
                                    <span class="self-center whitespace-nowrap mb-2">L</span>
                                    <span class="self-center whitespace-nowrap text-green-900 ">
                                        E
                                    </span>
                                    <span class="self-center whitespace-nowrap mb-2 text-red-500">
                                        N
                                    </span>
                                    <span class="self-center whitespace-nowrap text-red-500">
                                        G
                                    </span>
                                    <span class="self-center whitespace-nowrap mb-2 text-red-500">
                                        A
                                    </span>
                                </div>
                            </a>
                        </div>
                        <div class="flex items-center">
                            <a class="flex items-center gap-4 mx-3" href="#">
                                <span class="h-12 w-12 bg-gray-200 border-blue-500 border p-2 rounded-full flex items-center justify-center">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        class="w-6 h-6"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                                        />
                                    </svg>
                                </span>
                                <span class="h-12 w-12 bg-gray-200  border-blue-500 border p-2 rounded-full flex items-center justify-center">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        class="w-6 h-6"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"
                                        />
                                    </svg>
                                </span>


                                <Link to='/Compte' className='flex'>
                                    <span class="h-12 w-12   rounded-full">
                                        <img
                                            class="h-12 w-12 rounded-full border-blue-500 border object-cover"
                                            src="./images/sample.png"
                                            alt="Neil image"
                                        />
                                    </span>
                                    <span class="hidden text-right lg:block">
                                        <span class="block text-lg font-medium text-black ">
                                            Thomas Anree
                                        </span>
                                        <span class="block text-lg font-medium">Cantine</span>
                                    </span>
                                    <svg
                                        class="hidden fill-current text-gray-500 sm:block"
                                        width="12"
                                        height="8"
                                        viewBox="0 0 12 8"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M0.410765 0.910734C0.736202 0.585297 1.26384 0.585297 1.58928 0.910734L6.00002 5.32148L10.4108 0.910734C10.7362 0.585297 11.2638 0.585297 11.5893 0.910734C11.9147 1.23617 11.9147 1.76381 11.5893 2.08924L6.58928 7.08924C6.26384 7.41468 5.7362 7.41468 5.41077 7.08924L0.410765 2.08924C0.0853277 1.76381 0.0853277 1.23617 0.410765 0.910734Z"
                                            fill=""
                                        ></path>
                                    </svg>
                                </Link>
                            </a>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default NavBArsCantin
