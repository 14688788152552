import React from 'react'
import DemandeEncadreur from './DemandeEncadreur'
import SlideEncadrementNavBars from '../SlideEncadrementNavBars/SlideEncadrementNavBars'
function DemandeEncadreurComponent({ getSizeMobille, getMenuMobille }) {
    return (
        <div className=' flex justify-between overflow-hidden bg-white'>
            <SlideEncadrementNavBars getMenuMobille={getMenuMobille} />
            <DemandeEncadreur getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default DemandeEncadreurComponent
