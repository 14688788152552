import React, { useState } from "react";
import ModiferEleveComponent from "../../component/Secretariat/DossierEleve/ModiferEleveComponent";
function ModifierElevePage({ getMenuMobille, getSizeMobille }) {
  return (
    <>
      <ModiferEleveComponent getSizeMobille={getSizeMobille} getMenuMobille={getMenuMobille} />
    </>
  );
}

export default ModifierElevePage;
