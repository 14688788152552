import React, { useState } from "react";
import SideBarSecretaire from "../SideBarSecretaire/SideBarSecretaire";
import AjouterEleve from "./AjouterEleve";
function AjouterEleveComponent({ getMenuMobille, getSizeMobille }) {
  return (
    <>
      {" "}
      <div class="flex overflow-hidden justify-between">
        <SideBarSecretaire getMenuMobille={getMenuMobille} />
        <AjouterEleve getSizeMobille={getSizeMobille} />
      </div>
    </>
  );
}

export default AjouterEleveComponent;
