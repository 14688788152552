import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import FootterCopy from '../../FootterCopy/FootterCopy'
import { decryptData, encryptData } from '../../../encryptionModule'
import { API } from '../../../Api/API'

function Previsiondetail({ getSizeMobille }) {
    const { id } = useParams()
    const [mobile1, Setmonile1] = useState(window.innerWidth < 349)
    const [mobile11, SetMobile11] = useState(window.innerWidth < 501)

    useEffect(() => {
        const hundlesSize = () => {
            Setmonile1(window.innerWidth < 349)
            SetMobile11(window.innerWidth < 501)
        }

        window.addEventListener('resize', hundlesSize)

        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])
    const prevId = decryptData(id)
    const [prev, setPrev] = useState({})
    useEffect(() => {
        API.get(`/prev/prevencadreur/${prevId}`).then((rep) => {
            setPrev(rep.data)
        }).catch((err) => {
            console.log(err.message)
        })
    }, [id])

    return (

        <div className={`bg-white  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
            <Link to='/Prevision' className="ml-2 text-[18px] text-blue-500 hover:text-blue-800 underline">Retour</Link>

            <div className="sm:p-4 p-2 min-h-screen">
                <div className='text-2xl font-bold uppercase'>Détail</div>
                <div class=" w-full   rounded-lg ">

                    <div class="flex  justify-center space-y-3 gap-4 mt-8">

                        <div class="flex  rounded-lg border border-gray-400 bg-white shadow-lg  w-[90%]">
                            <div class="flex flex-col justify-start w-full p-6">
                                <h5 class="mb-2 text-xl font-medium  ">
                                    Libelle:  {prev && prev.libelle}
                                </h5>
                                <p class="mb-4 text-xl text-neutral-600 ">
                                    Quantite: {prev && prev.quantite}
                                </p>
                                <p class="mb-4 text-xl text-neutral-600 ">
                                    Prix unitaire:  {prev && prev.montant}
                                </p>
                                <p class="mb-4 text-xl text-neutral-600 ">
                                    Status:  {prev && prev.status}
                                </p>
                                <p class="mb-4 text-xl text-neutral-600 ">
                                    Déscription:
                                    <p>
                                        {prev && prev.description}
                                    </p>
                                </p>
                                {prev && prev.status == "Chez Pref Etud" && (
                                    <div class=" mt-8 flex w-full items-center justify-end ">
                                        <div class="flex flex-row flex-shrink-0 space-x-3">
                                            <Link to={`/Prevision/Modifier/${encryptData((prev.id).toString())}`}
                                                class="mt-4 sm:mt-0 inline-flex items-start justify-start px-6 py-3 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg"
                                            >
                                                <p class="text-lg font-medium leading-none text-white">
                                                    Modifier
                                                </p>
                                            </Link>
                                        </div>
                                    </div>
                                )}

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <FootterCopy />
        </div>
    )
}

export default Previsiondetail
