import React from 'react'
import SlideNavBarsCantin from '../SlideNavBarsCantin/SlideNavBarsCantin'
import Achat from './Achat'
function AchatCompoent() {
    return (
        <div className='overflow-hidden pt-16 flex bg-white'>
            <SlideNavBarsCantin />
            <Achat />
        </div>
    )
}

export default AchatCompoent
