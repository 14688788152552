import React from 'react'
import SideBarSecretaire from '../SideBarSecretaire/SideBarSecretaire'
import PresenceSecraitre from './PresenceSecraitre'
function PresenceSecraitreComponent({ getMenuMobille, getSizeMobille }) {
    return (
        <div className='flex justify-between bg-white overflow-hidden'>
            <SideBarSecretaire getMenuMobille={getMenuMobille} />
            <PresenceSecraitre getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default PresenceSecraitreComponent
