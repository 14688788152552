import React from 'react'
import DepenseArgent from './DepenseArgent'
import SildeEnseign from '../SildeEnseign/SildeEnseign'
function DepenseArgentComponent() {
  return (<>
    <div class="overflow-hidden flex justify-between">
      <SildeEnseign />
      <DepenseArgent />
    </div>
  </>)
}

export default DepenseArgentComponent
