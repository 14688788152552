import React from 'react'
import SideBarBibliote from '../SideBar/SideBarBibliote'
import CartEnseign from './CartEnseign'
function CartEnseignComponent() {
    return (
        <div className='flex justify-between bg-white overflow-hidden'>
            <SideBarBibliote />
            <CartEnseign />
        </div>
    )
}

export default CartEnseignComponent
