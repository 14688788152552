import React, { useState } from "react";
import AccueilComponent from "../../component/Bibliotheque/Accueil/AccueilComponent";
function AccueilPage({ getSizeMobille, getMenuMobille }) {
  return (
    <>
      <AccueilComponent getMenuMobille={getMenuMobille} getSizeMobille={getSizeMobille} />
    </>
  );
}

export default AccueilPage;
