import React, { useState } from "react";
import SideBarSecraitre from "../SideBar/SideBarBibliote";
import Accueil from "./Accueil";
function AccueilComponent({ getSizeMobille, getMenuMobille }) {
  return (
    <>

      <div class="overflow-hidden flex justify-between">
        <SideBarSecraitre getMenuMobille={getMenuMobille} />
        <Accueil getSizeMobille={getSizeMobille} />
      </div>
    </>
  );
}

export default AccueilComponent;
