import React, { useState } from "react";
import AccueilSecretaireComponent from "../../component/Secretariat/AccueilSecretaire/AccueilSecretaireComponent";
function AccueilSecretairePage({ getMenuMobille, getSizeMobille, getDivScroll }) {
  return (
    <>
      <AccueilSecretaireComponent getDivScroll={getDivScroll} getMenuMobille={getMenuMobille} getSizeMobille={getSizeMobille} />
    </>
  );
}

export default AccueilSecretairePage;