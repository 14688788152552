import React from 'react'
import SlideNavInfermie from '../SlideNavInfermie/SlideNavInfermie'
import PresenceInfiemier from './PresenceInfiemier'
function PresenceInfiemierComponent({ getSizeMobille, getMenuMobille }) {
    return (
        <div className='flex justify-between overflow-hidden bg-white'>
            <SlideNavInfermie getMenuMobille={getMenuMobille} />
            <PresenceInfiemier getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default PresenceInfiemierComponent
