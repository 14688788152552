import React from 'react'
import PresenceEns from './PresenceEns'
import SildeEnseign from '../SildeEnseign/SildeEnseign'
function PresenceEnsComponent() {
    return (
        <div className='flex overflow-hidden pt-16 bg-white'>
            <SildeEnseign />
            <PresenceEns />
        </div>
    )
}

export default PresenceEnsComponent
