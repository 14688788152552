/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import FootterCopy from "../../FootterCopy/FootterCopy";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select"
import { FadeLoader } from 'react-spinners'

function AddRepreseantPredDisplin({ getSizeMobille }) {
    const [mobile11, SetMobile11] = useState(window.innerWidth < 501)
    useEffect(() => {
        const hundlesSize = () => {
            SetMobile11(window.innerWidth < 501)
        }
        window.addEventListener('resize', hundlesSize)
        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])

    const [mobile, SetMobile] = useState(window.innerWidth <= 640)
    const [eledemmade, GeteleDemmande] = useState([])
    const [boutLoading, setboutLoading] = useState(false)

    useEffect(() => {
        const hundleSize = () => {
            SetMobile(window.innerWidth <= 640)
        }
        window.addEventListener('resize', hundleSize)

        return () => {
            window.removeEventListener('resize', hundleSize)
        }
    }, [])


    const optionsFunction = [
        { value: '', label: 'Doyen' },
        { value: 'doyen', label: 'Doyen' },
        { value: 'vice-doyen', label: 'Vice-doyen' },
        { value: 'délégué', label: 'Délégué' },
        { value: 'vice-délégué', label: 'Vice-délégué' },
        { value: 'gestionnaire', label: 'Gestionnaire' },
        { value: 'vice-gestionnaire', label: 'Vice-gestionnaire' },
        { value: 'responsable-sécurité', label: 'Responsable de la sécurité' },
        { value: 'Responsable de la propriété', label: 'Responsable de la propriété' },
    ];

    const optionsclasse = [
        { value: '', label: '7 anne' },
        { value: '7 anne', label: '7 anne' },
        { value: '8 anne', label: '8 anne' },
        { value: '9 anne', label: '9 anne' },
        { value: '10 anne', label: '10 anne' },
        { value: '11 anne', label: '11 anne' },
        { value: '12 anne', label: '12 anne' },
        { value: '13 anne', label: '13 anne' }
    ];



    const hiddenSoptionsFunction = optionsFunction.slice(1);
    const hiddenoptionsclasse = optionsclasse.slice(1);

    const customStyles = {
        control: (provided) => ({
            ...provided,
            height: mobile ? 25 : 50,
            backgroundColor: "white",
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? "blue" : state.isFocused ? "lightblue" : "white",
            color: state.isSelected ? "white" : "black",
        }),
    };

    const [blocs, setBlocs] = useState([
        { Nom_Presentant: '', Fonction: '', classe: '' }
    ]);
    const addBloc = () => {

        setBlocs([...blocs, { Nom_Presentant: '', Fonction: '', classe: '' }]);

    };
    const removeBloc = (index) => {
        const newBlocs = [...blocs];
        newBlocs.splice(index, 1);
        setBlocs(newBlocs);
    };
    const inputRefs = useRef([]);
    if (inputRefs.current.length !== blocs.length) {
        inputRefs.current = Array(blocs.length).fill({}).map((_, index) => ({
            Nom_Presentant: React.createRef(null),
            Fonction: React.createRef(null),
            classe: React.createRef(null),
        }));
    }

    const handleInputChange = (index, field, value) => {
        const newBlocs = [...blocs];
        newBlocs[index][field] = value;
        setBlocs(newBlocs);
        if (value && inputRefs.current[index][field].current) {
            inputRefs.current[index][field].current.classList.remove('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500');
        }
    };


    const animateAndScrollToRef = (ref) => {
        if (ref && ref.current) {
            ref.current.classList.add('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500', 'outline-none');
            setTimeout(() => {
                ref.current.classList.remove('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500', 'outline-none');
            }, 5000);
            ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            ref.current.focus();
        }
    };


    const validateBloc = (bloc, index) => {
        const requiredFields = ['Nom_Presentant', 'Fonction', 'classe'];
        //Validation pour les champs vide
        for (const field of requiredFields) {
            if (!bloc[field]) {
                toast.warning(<div>Le <strong>{field == 'Nom_Presentant' ? 'nom du representant ' : field == 'Fonction' ? ' fontion' : field == 'classe' ? ' classe' : null}</strong> est obligatoire à la block {index + 1}.</div>);
                if (inputRefs.current[index][field].current) {
                    inputRefs.current[index][field].current.classList.add('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500', 'outline-none');
                    setTimeout(() => {
                        inputRefs.current[index][field].current.classList.remove('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500', 'outline-none');
                    }, 3000);
                    inputRefs.current[index][field].current.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    inputRefs.current[index][field].current.focus();
                    return false;
                }
            }
        }

        //Validation pour eviter de sousmettre deux block de meme nom
        const isdepluqueName = blocs.some((b, i) => i !== index && b.Nom_Presentant === bloc.Nom_Presentant);
        if (isdepluqueName) {
            toast.warning(
                <div>
                    Vous avez déjà utilise le nom "<span className="font-bold underline">{bloc.Nom_Presentant}</span>" de la block <span className="font-bold">{toRoman(index + 1)}</span>  avec fonction de  <span className="font-bold">{bloc.Fonction.value}</span>.
                </div>
            );
            animateAndScrollToRef(inputRefs.current[index].Nom_Presentant);
            return false;
        }

        //Validation pour eviter de sousmettre deux block de meme fonction
        const isdepluqueFonction = blocs.some((b, i) => i !== index && b.Fonction.value === bloc.Fonction.value);
        if (isdepluqueFonction) {
            toast.warning(
                <div>
                    Vous avez déjà utilise la fonction "<span className="font-bold underline">{bloc.Fonction.value}</span>" de la block <span className="font-bold">{toRoman(index + 1)}</span>  avec le nom  <span className="font-bold">{bloc.Nom_Presentant}</span>.
                </div>
            );
            animateAndScrollToRef(inputRefs.current[index].Fonction.value);
            return false;
        }


        return true;
    };



    function toRoman(num) {
        const romanNumerals = [
            "I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX", "X",
            "XI", "XII", "XIII", "XIV", "XV", "XVI", "XVII", "XVIII", "XIX", "XX",
            "XXI", "XXII", "XXIII", "XXIV", "XXV", "XXVI", "XXVII", "XXVIII", "XXIX", "XXX"
        ];
        return romanNumerals[num - 1] || num.toString();
    }


    const Datas = blocs.map((bloc) => ({
        Nom_Presentant: bloc.Nom_Presentant,
        Fonction: bloc.Fonction.value,
        classe: bloc.classe.value,
    }))


    const handleSubmit = (e) => {
        e.preventDefault();
        for (let index = 0; index < blocs.length; index++) {
            if (!validateBloc(blocs[index], index)) {
                return false;
            }
        }
        toast.success(
            <div>
                Enregistrement avec succès
            </div>
        );
        console.log('Datas', Datas);
    };



    return (

        <div className={`bg-white  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
            <Link to='/Represant' className="ml-2 text-[18px] text-blue-500 hover:text-blue-800 underline">Retour</Link>
            <div className="sm:p-4 p-2 min-h-screen">
                <div class="my-5">
                    <div class="w-full flex flex-row items-center justify-between">
                        <p class="text-navy-700   ">
                            <a
                                class="hover:text-navy-700 text-2xl font-bold  "
                            >
                                Enregistrement des représentants
                            </a>
                        </p>

                    </div>
                </div>
                <div className="w-full my-4 grid grid-cols-1">
                    <div className="w-full my-4 grid grid-cols-1">
                        <form onSubmit={handleSubmit} className="flex flex-col items-center">
                            <div className="rounded-lg p-2 border border-blue-500 md:w-[40em] w-[95%] sm:p-4">
                                {blocs.map((bloc, index) => (
                                    <div key={index} className="justify_center rounded-xl w-full  p-1 sm:p-2 mb-10  border-[1px] flex flex-col">
                                        <div className="w-full">
                                            <div className="h-max w-full">
                                                <div className="px-3  w-full">
                                                    <div className="w-full flex items_center justify-between">
                                                        <label className="block  text-xs mt-5 font-serif text-gray-700 first_letter:uppercase tracking_wide text-grey-darker sm:text-lg  mb-1">
                                                            {toRoman(index + 1)}. représentants
                                                        </label>
                                                        {index > 0 && (
                                                            <button
                                                                montant="button"
                                                                className="text-red-500 px-1 max-sm:text-xs py-2"
                                                                onClick={() => removeBloc(index)}
                                                            >
                                                                Supprimer
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mb-2">
                                            <div className="rounded-xl p-2 w-full">
                                                <div className="flex flex-col">
                                                    <div className="rounded-lg w-full">
                                                        <div class="w-full mb-5">
                                                            <label
                                                                class="block  tracking-wide text-grey-darker text-lg  mb-2"
                                                                for="grid-Title"
                                                            >
                                                                Nom complet
                                                            </label>
                                                            <input
                                                                ref={inputRefs.current[index].Nom_Presentant}
                                                                class="appearance-none block w-full bg-grey-lighter text-grey-darker border border-red rounded py-3 px-4 mb-3"
                                                                value={bloc.Nom_Presentant}
                                                                onChange={(e) => handleInputChange(index, "Nom_Presentant", e.target.value)}
                                                                placeholder="Nom complet..."
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="rounded-lg w-full">
                                                        <div class="w-full mb-5">
                                                            <label class="block  tracking-wide text-gray-700 text-xs sm:text-lg  mb-1">
                                                                Fonction
                                                            </label>
                                                            <div ref={inputRefs.current[index].Fonction} className={` rounded `}>
                                                                <Select
                                                                    value={bloc.Fonction}
                                                                    options={hiddenSoptionsFunction}
                                                                    styles={customStyles}
                                                                    onChange={(option) => handleInputChange(index, "Fonction", option)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="rounded-lg w-full">
                                                        <div class="w-full mb-5">
                                                            <label class="block  tracking-wide text-gray-700 text-xs sm:text-lg  mb-1">
                                                                Classe
                                                            </label>
                                                            <div ref={inputRefs.current[index].classe} className={` rounded `}>
                                                                <Select
                                                                    value={bloc.classe}
                                                                    options={hiddenoptionsclasse}
                                                                    styles={customStyles}
                                                                    onChange={(option1) => handleInputChange(index, "classe", option1)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <div className="w-full flex justify-end">
                                    <button montant="button" className="bg-blue-500 hover-bg-blue-700 text-white py-1 px-2  max-sm:text-xs sm:py-2 sm:px-4 rounded" onClick={addBloc}>
                                        Ajouter matériel
                                    </button>
                                </div>
                            </div>

                            <div className=" flex justify-end  md:w-[40em] w-[95%]">

                                {boutLoading ? (
                                    <>
                                        <label disabled className="cursor-no-drop w-max relative  mt-3 flex justify-center  items-center   bg-green-950    p-2 rounded  text-gray-400">
                                            <input type="submit" id="send" value='Soumettre' className='pointer-events-none' />
                                            <i class="bi bi-send ml-2  pointer-events-none "></i>
                                            <div disabled className='absolute pointer-events-none  bg-transparent pt-4  pl-4  w-full h-full flex justify-center items-center z-50'>
                                                <FadeLoader
                                                    color="rgb(255, 255, 255)"
                                                    height={10}
                                                    margin={-9}
                                                    radius={100}
                                                    speedMultiplier={1}
                                                    width={1}
                                                />
                                            </div>
                                        </label>
                                    </>
                                ) : (<>
                                    <label for="send" className=" mt-3 cursor-pointer w-max  flex justify-end  bg-green-600   p-2 rounded  text-white">
                                        <input type="submit" id="send" value='Soumettre' className='cursor-pointer'></input>
                                        <i class="bi bi-send ml-2 "></i>
                                    </label>
                                </>)}

                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <FootterCopy />
        </div>

    );
}


export default AddRepreseantPredDisplin;










