/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import { API } from "../../../Api/API";
import { decryptData } from "../../../encryptionModule";
import Select from "react-select";
function AjouteExpliChaquePerson({ getSizeMobille }) {
    const [mobile1, Setmonile1] = useState(window.innerWidth < 349)
    const [mobile11, SetMobile11] = useState(window.innerWidth < 501)
    const history = useNavigate()
    const { id } = useParams()
    const staffId = decryptData(id)
    useEffect(() => {
        const hundlesSize = () => {
            Setmonile1(window.innerWidth < 349)
            SetMobile11(window.innerWidth < 501)
        }

        window.addEventListener('resize', hundlesSize)

        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])


    const [selectedOption3, setSelectedOption3] = useState({ value: "", label: "Classeur" });
    const [animationClassClasseurs, setanimationClassCpassport] = useState('');
    const elemetRefClasseurs = useRef(null)
    const [Classeursoptions, setClasseursoptions] = useState([]);
    const customStyles = {
        control: (provided) => ({
            ...provided,
            height: 50,
            backgroundColor: "white",

        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? "blue" : state.isFocused ? "lightblue" : "white",
            color: state.isSelected ? "white" : "black",
        }),
    };
    const SelectClasseur = (option) => {
        setSelectedOption3(option)
    }
    useEffect(() => {
        API.get("/classeur/all")
            .then((response) => {
                // Transformation des données pour correspondre à la structure attendue
                const classeursFromAPI = response.data.map(classeur => ({
                    value: classeur.id.toString(), // Conversion en chaîne si nécessaire
                    label: classeur.noClasseur
                }));
                // Mettre à jour l'état avec les nouvelles données
                setClasseursoptions([selectedOption3, ...classeursFromAPI]);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, []);
    const hiddenSClasseursoptions = Classeursoptions.slice(1);
    const [Objectif, SetObjectif] = useState('')
    const [animationClassObjectif, setAnimationClassObjectif] = useState('');
    const elemetRefObjectif = useRef(null)


    const [Explication, SetExplication] = useState(null)
    const [animationClassExplication, setAnimationClassExplication] = useState('');
    const elemetRefExplication = useRef(null)


    useEffect(() => {
        elemetRefObjectif.current.style.height = '7em';
        elemetRefObjectif.current.style.height = `${elemetRefObjectif.current.scrollHeight}px`;
        elemetRefObjectif.current.scrollTop = elemetRefObjectif.current.scrollHeight;
    }, [Objectif]);


    const handleSubmit = (e) => {
        e.preventDefault()
        if (Objectif.trim() == '') {
            toast.warning('Laisse l\'objectif de reponse', {
                autoClose: 2000
            });
            setAnimationClassObjectif('animate__animated animate__shakeX border-2 border-red-500')
            setTimeout(() => {
                setAnimationClassObjectif(' ')
            }, 3000)
            elemetRefObjectif.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            elemetRefObjectif.current.focus()
            return false
        }
        else if (Explication == null) {
            toast.warning('Ajoute un photos d\'explication', {
                autoClose: 2000
            });
            setAnimationClassExplication('animate__animated animate__shakeX border-2 border-red-500')
            setTimeout(() => {
                setAnimationClassExplication(' ')
            }, 3000)
            elemetRefExplication.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            elemetRefExplication.current.focus()
            return false
        }
        else if (selectedOption3.value == '') {
            toast.warning('Le classeurs est obligatoire', {
                autoClose: 2000
            });
            setanimationClassCpassport('animate__animated animate__shakeX border-2 border-red-500')
            setTimeout(() => {
                setanimationClassCpassport(' ')
            }, 3000)
            elemetRefClasseurs.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            return;
        }

        const formdat = new FormData()
        formdat.append("Objectif", Objectif)
        formdat.append("staffId", staffId)
        formdat.append("classeurId", selectedOption3.value)
        formdat.append("LettrePhoto", Explication)

        // console.log(formdat)

        API.post("/staff/demandeexprications", formdat, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }).then((res) => {
            toast.success(res.data)
            history("/DemandeReponse/AjouterListPerson")
        }).catch((error) => {
            if (error.response) {
                // Le serveur a répondu avec un code d'erreur (status code)
                if (Array.isArray(error.response.data)) {
                    // Si c'est un tableau, afficher le premier élément
                    toast.warn(error.response.data[0]);
                } else if (typeof error.response.data === 'object') {
                    // S'il s'agit d'un objet, afficher la première valeur de la propriété
                    const keys = Object.keys(error.response.data);
                    if (keys.length > 0) {
                        toast.warn(error.response.data[keys[0]]);
                    }
                } else if (typeof error.response.data === 'string') {
                    // Si c'est une chaîne de caractères, l'afficher directement
                    toast.warn(error.response.data);
                } else {
                    // Autre type non pris en charge
                    toast.warn("Erreur du serveur")

                }

                // Afficher les erreurs au sein de votre composant ou effectuer une action en conséquence
            } else if (error.request) {
                // La requête a été faite mais aucune réponse n'a été reçue
                console.error('Pas de réponse du serveur :', error.request);
            } else {
                // Une erreur s'est produite lors de la configuration de la requête
                console.error('Erreur lors de la requête :', error.message);
            }
        })

    }






    return (
        <>
            <div className={`bg-white  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>

                <Link to='/DemandeReponse/AjouterListPerson' className="ml-2 text-[18px] text-blue-500 hover:text-blue-800 underline">Retour</Link>

                <div className="p-6 ">

                    <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                        <div class="my-5">
                            <div class="w-full flex flex-row items-center justify-between">
                                <p class="text-navy-700 shrink  ">
                                    <p
                                        class="hover:text-navy-700 text-gray-700 uppercase text-2xl font-bold "

                                    >
                                        Ajouter une demande d'explication
                                    </p>
                                </p>

                            </div>
                        </div>
                        <div class="w-full my-4 grid grid-cols-1 ">
                            <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">
                                <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col my-2">
                                    <div class={`w-full  mb-6`}>
                                        <div class={`w-full`}>
                                            <label
                                                class="block  tracking-wide  text-lg font-bold mb-2"
                                                for="grid-last-name"
                                            >
                                                Objectif
                                            </label>
                                            <textarea
                                                ref={elemetRefObjectif}
                                                value={Objectif}
                                                onInput={(e) => SetObjectif(e.target.value)}
                                                className={`appearance-none resize-none outline-none block w-full bg-grey-lighter border rounded p-2 ${Objectif.trim() === "" ? animationClassObjectif : "focus:border-2 focus:border-blue-700"
                                                    }`}
                                                id="grid-last-name"
                                                rows={1}
                                                placeholder="Objectif.."
                                            ></textarea>
                                        </div>
                                    </div>


                                    <div class="-mx-3 md:flex mb-6">

                                        <div class="md:w-1/2 px-3">
                                            <label htmlFor="explict"
                                                className={`appearance-none resize-none outline-none block w-full bg-grey-lighter border mb-2 rounded p-2 ${Explication == "" ? animationClassExplication : "focus:border-2 focus:border-blue-700"
                                                    }`} ref={elemetRefExplication}
                                            >
                                                Photo de demande d'explication
                                            </label>
                                            <input
                                                id="explict"
                                                hidden
                                                accept="image/*"
                                                onInput={(e) => SetExplication(e.target.files[0])}
                                                type="file"

                                            />

                                        </div>
                                        <div class="md:w-1/2 px-3">
                                            <label
                                                class="block uppercase tracking-wide text-gray-700 text-lg font-bold mb-2"
                                                for="grid-state"
                                            >
                                                Classeurs
                                            </label>
                                            <div ref={elemetRefClasseurs} className={` rounded  ${selectedOption3.value == '' ? animationClassClasseurs : 'focus:border-2 focus:border-blue-700'} `}
                                            >
                                                <Select
                                                    value={selectedOption3}
                                                    options={hiddenSClasseursoptions}
                                                    styles={customStyles}
                                                    onChange={SelectClasseur}

                                                />
                                            </div>

                                        </div>
                                    </div>
                                    {Explication &&
                                        <div className="w-[25em] h-[25em] bg-green-700 rounded-xl mb-5 overflow-hidden">
                                            <img src={Explication ? `${URL.createObjectURL(Explication)}` : ""} className="w-full h-full object-contain bg-white" />
                                        </div>
                                    }


                                    <div class="mb-4 flex items-center justify-end">
                                        <div class="flex-shrink-0">
                                            <button
                                                type="submit"
                                                class="mt-4 sm:mt-0 text-lg font-medium leading-none text-white inline-flex items-start justify-start px-6 py-3 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg"
                                            >
                                                Repondre
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>


        </>
    );
}

export default AjouteExpliChaquePerson;
