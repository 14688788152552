import React from 'react'
import SildeEnseign from '../SildeEnseign/SildeEnseign'
import AcceuilEnseing from './AcceuilEnseing'
function AcceuilEnseingComponent() {
    return (
        <div className='overflow-hidden pt-16 bg-white flex'>
            <SildeEnseign />
            <AcceuilEnseing />
        </div>
    )
}

export default AcceuilEnseingComponent
