import React from 'react'
import Statistique from './Statistique'
import SlideNavBarsCantin from '../SlideNavBarsCantin/SlideNavBarsCantin'
function StatistiqueComponent() {
    return (
        <div className='flex bg-white pt-20'>
            <SlideNavBarsCantin />
            <Statistique />
        </div>
    )
}

export default StatistiqueComponent
