import React from 'react'
import DepenseArgentDetail from './DepenseArgentDetail'
import SildeEnseign from '../SildeEnseign/SildeEnseign'
function DepenseArgentDetailComponet() {
  return (
    <div className='flex bg-white pt-16 overflow-hidden'>
      <SildeEnseign />
      <DepenseArgentDetail />
    </div>)
}

export default DepenseArgentDetailComponet
