import React from 'react'
import SideBarsPrefetDisplin from '../SideBarsPrefetDisplin/SideBarsPrefetDisplin'
import HomeFillDortoirePrefetDisplin from './HomeFillDortoirePrefetDisplin'
function HomeFillDortoirePrefetDisplinComponent({ getSizeMobille, getMenuMobille }) {
    return (
        <div className='flex justify-between overflow-hidden bg-white'>
            <SideBarsPrefetDisplin getMenuMobille={getMenuMobille} />
            <HomeFillDortoirePrefetDisplin getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default HomeFillDortoirePrefetDisplinComponent
