/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FootterCopy from "../../FootterCopy/FootterCopy";
import { classeRepresentants } from "../../../Data/Data";
import { representantScolaire } from "../../../Data/Data";
function PresentantEncadrement({ getSizeMobille }) {
    const [mobile1, Setmonile1] = useState(window.innerWidth < 565)
    const [mobile11, SetMobile11] = useState(window.innerWidth < 501)

    useEffect(() => {
        const hundlesSize = () => {
            Setmonile1(window.innerWidth < 565)
            SetMobile11(window.innerWidth < 501)
        }

        window.addEventListener('resize', hundlesSize)

        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])


    const [inputValue, setInputValue] = useState('');
    const [sortOrder, setSortOrder] = useState('asc');
    const [sortedColumn, setSortedColumn] = useState(null);

    const ValuesInput = (e) => {
        setInputValue(e.target.value);
    };


    const fildata = (data, inputValue) => {
        if (!inputValue.trim()) {
            return data;
        }
        return data.filter((item) => {
            if (
                item.nom_classe.toLowerCase().includes(inputValue.toLowerCase())
            ) {
                return true;
            }
            return false;
        });
    };

    const fildata1 = (data, inputValue) => {
        if (!inputValue.trim()) {
            return data;
        }
        return data.filter((item) => {
            if (
                item.grade.toLowerCase().includes(inputValue.toLowerCase()) ||
                item.Nom_complet.toLowerCase().includes(inputValue.toLowerCase()) ||
                item.Classe.toLowerCase().includes(inputValue.toLowerCase())
            ) {
                return true;
            }
            return false;
        });
    };

    const handleSort = (column) => {
        if (column === sortedColumn) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortOrder('asc');
            setSortedColumn(column);
        }
    };

    const sortData = (data, column, sortOrder) => {
        return data.slice().sort((a, b) => {
            const aValue = a[column];
            const bValue = b[column];

            if (aValue < bValue) return sortOrder === 'asc' ? -1 : 1;
            if (aValue > bValue) return sortOrder === 'asc' ? 1 : -1;
            return 0;
        });
    };

    const filteredData = fildata(classeRepresentants, inputValue);
    const filteredData1 = fildata1(representantScolaire, inputValue);

    const sortedData = sortData(filteredData, sortedColumn, sortOrder);
    const sortedData1 = sortData(filteredData1, sortedColumn, sortOrder);


    function highlightText(text, inputValue) {
        if (!inputValue) return text;
        const highlightedText = [];
        let currentIndex = 0;
        while (currentIndex < text.length) {
            const inputChar = inputValue.toLowerCase();
            const matchIndex = text.substr(currentIndex).toLowerCase().indexOf(inputChar);

            if (matchIndex !== -1) {

                highlightedText.push(text.substring(currentIndex, currentIndex + matchIndex));


                highlightedText.push(
                    <span className="text-orange-500" key={currentIndex + matchIndex}>
                        {text.substring(currentIndex + matchIndex, currentIndex + matchIndex + inputValue.length)}
                    </span>
                );

                currentIndex = currentIndex + matchIndex + inputValue.length;
            } else {
                highlightedText.push(text.substring(currentIndex));
                break;
            }
        }

        return highlightedText;
    }





    return (
        <div className={`bg-white  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
            <div className="sm:p-4 p-2 min-h-screen">
                <div class="sm:flex items-center pr-4  h-max  justify-end">
                    <div class="mx-2">
                        <div class="relative lg:w-64">
                            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <svg
                                    class="w-5 h-5 text-gray-500"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                        clip-rule="evenodd"
                                    ></path>
                                </svg>
                            </div>
                            <input
                                type="text"
                                name="email"
                                id="topbar-search"
                                class="bg-white border border-gray-300 h-10 text-gray-900 sm:text-lg first-letter:uppercase rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full pl-10 .5"
                                placeholder="Rechercher..."
                                onInput={ValuesInput}
                            />
                        </div>
                    </div>
                    <button class="mt-4 sm:mt-0 inline-flex items-start justify-start px-1 py-3 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg">
                        <p class="text-lg first-letter:uppercase font-semibold leading-none text-white">
                            Ok
                        </p>
                    </button>
                </div>
                <div class="w-full  my-4  grid-cols-1 ">
                    <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">
                        <div class="mb-4 flex items-center justify-between">
                            <div>
                                <h3 class="sm:text-xl font-bold text-gray-900 mb-2">
                                    Listes des répresentants des classes
                                </h3>
                            </div>
                        </div>
                        <div class="flex flex-col mt-8">
                            <div class="overflow-x-auto rounded-lg">
                                <div class={`${mobile1 ? 'w-[35em]' : ' min-w-full '} `}>
                                    <div class="shadow s sm:rounded-lg">
                                        <table class="min-w-full divide-y divide-gray-200">
                                            <thead class="bg-gray-50 border">
                                                <tr>
                                                    <th onClick={() => handleSort('classe')}
                                                        scope="col"
                                                        class=" py-4 px-2 font-semibold relative border-l text-left cursor-pointer text-lg first-letter:uppercase  text-gray-500  tracking-wider"
                                                    >
                                                        Classe  {sortedColumn === 'classe' && <span className="pr-0 absolute right-2">{sortOrder === 'asc' ? '▲' : '▼'}</span>}

                                                    </th>
                                                    <th
                                                        class=" py-4 px-2 font-semibold relative border-l text-left cursor-pointer text-lg first-letter:uppercase  text-gray-500  tracking-wider"
                                                    >
                                                        Représentant

                                                    </th>
                                                    <th
                                                        class=" py-4 px-2 font-semibold relative border-l text-left cursor-pointer text-lg first-letter:uppercase  text-gray-500  tracking-wider"
                                                    >
                                                        Nom et prénom

                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody class="bg-white border-r">
                                                {sortedData.map((data, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                                {highlightText(data.nom_classe, inputValue)}
                                                            </td>
                                                            <td class="border-b border-gray-200   border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                                <div className='border-b w-full h-full'>
                                                                    <p className='px-2'>Délégue</p>
                                                                </div>
                                                                <div className='border-b w-full h-full'>
                                                                    <p className='px-2'>Vice-Délégue</p>
                                                                </div>
                                                                <div className='w-full h-full'>
                                                                    <p className='px-2'>Sécretaire</p>
                                                                </div>
                                                            </td>
                                                            <td class="border-b border-gray-200    border-l whitespace-nowrap text-lg first-letter:uppercase  text-gray-500">

                                                                <div className='border-b w-full h-full'>
                                                                    <p className='px-2'>{data.representant.delegue}</p>
                                                                </div>
                                                                <div className='border-b w-full h-full'>
                                                                    <p className='px-2'>{data.representant.viceDelegue}</p>
                                                                </div>
                                                                <div className='w-full h-full'>
                                                                    <p className='px-2'>{data.representant.secretaire}</p>
                                                                </div>
                                                            </td>

                                                        </tr>
                                                    )
                                                })}

                                                {sortedData.length == 0 &&
                                                    <tr>
                                                        <td colSpan={7} className="h-20 w-full text-center text-2xl text-gray-400"> Aucun élément correspondant à <span className="font-bold">{inputValue}</span> </td>
                                                    </tr>}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="w-full my-4 grid grid-cols-1 ">
                    <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">
                        <div class="mb-4 flex items-center justify-between">
                            <div>
                                <h3 class="sm:text-xl font-bold text-gray-900 mb-2">
                                    Listes des répresentants des scolaires
                                </h3>
                            </div>
                        </div>
                        <div class="flex flex-col mt-8">
                            <div class="overflow-x-auto rounded-lg">
                                <div class={`${mobile1 ? 'w-[35em]' : ' min-w-full '} `}>
                                    <div class="shadow s sm:rounded-lg">
                                        <table class="min-w-full divide-y divide-gray-200">
                                            <thead class="bg-gray-50 border">
                                                <tr>
                                                    <th onClick={() => handleSort('grade')}
                                                        scope="col"
                                                        class=" py-4 px-2 font-semibold relative border-l text-left cursor-pointer text-lg first-letter:uppercase  text-gray-500  tracking-wider"
                                                    >
                                                        Grade  {sortedColumn === 'grade' && <span className="pr-0 absolute right-2">{sortOrder === 'asc' ? '▲' : '▼'}</span>}

                                                    </th>
                                                    <th onClick={() => handleSort('Nom_complet')}
                                                        scope="col"
                                                        class=" py-4 px-2 font-semibold relative border-l text-left cursor-pointer text-lg first-letter:uppercase  text-gray-500  tracking-wider"
                                                    >
                                                        Nom et prénom
                                                        {sortedColumn === 'Nom_complet' && <span className="pr-0 absolute right-2">{sortOrder === 'asc' ? '▲' : '▼'}</span>}
                                                    </th>
                                                    <th onClick={() => handleSort('Classe')}
                                                        class=" py-4 px-2 font-semibold relative border-l text-left cursor-pointer text-lg first-letter:uppercase  text-gray-500  tracking-wider"
                                                    >
                                                        Classe
                                                        {sortedColumn === 'Classe' && <span className="pr-0 absolute right-2">{sortOrder === 'asc' ? '▲' : '▼'}</span>}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody class="bg-white border-r">
                                                {sortedData1.map((data, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                                {highlightText(data.grade, inputValue)}
                                                            </td>
                                                            <td class="border-b border-gray-200   border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                                {highlightText(data.Nom_complet, inputValue)}
                                                            </td>
                                                            <td class="border-b border-gray-200    border-l whitespace-nowrap text-lg first-letter:uppercase  text-gray-500">

                                                                {highlightText(data.Classe, inputValue)}
                                                            </td>

                                                        </tr>
                                                    )
                                                })}

                                                {sortedData1.length == 0 &&
                                                    <tr>
                                                        <td colSpan={7} className="h-20 w-full text-center text-2xl text-gray-400"> Aucun élément correspondant à <span className="font-bold">{inputValue}</span> </td>
                                                    </tr>}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FootterCopy />
        </div>

    );
}

export default PresentantEncadrement;
