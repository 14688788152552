import React from 'react'
import PatientDetail from './PatientDetail'
import SlideNavInfermie from '../SlideNavInfermie/SlideNavInfermie'
function PatientDetailComponent() {
    return (
        <div className='flex justify-between overflow-hidden bg-white'>
            <SlideNavInfermie />
            <PatientDetail />
        </div>
    )
}

export default PatientDetailComponent
