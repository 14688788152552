import React, { useRef, useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import FootterCopy from "../../../FootterCopy/FootterCopy";
import { API } from "../../../../Api/API";
import Select from "react-select";
function AjouterExpedier({ getSizeMobille }) {

  const history = useNavigate()
  const [N_de_Ref, SetN_de_Ref] = useState('')
  const [animationClassN_de_Ref, setAnimationClassN_de_Ref] = useState('');
  const elemetRefN_de_Ref = useRef(null)

  const [Nom_de_destinateur, SetNom_de_destinateur] = useState('')
  const [animationClassNom_de_destinateur, setAnimationClassNom_de_destinateur] = useState('');
  const elemetRefNom_de_destinateur = useRef(null)

  const [LettreResume, SetLettreResume] = useState('')
  const [animationClassLettreResume, setAnimationClassLettreResume] = useState('');
  const elemetRefLettreResume = useRef(null)



  const [datteLettre, SetdatteLettre] = useState('')
  const [animationClassgetdatteLettre, setanimationClassgetdatteLettre] = useState('');
  const elemetRefdatteLettre = useRef(null)


  const [datteExpedi, SetdatteExpedi] = useState('')
  const [animationClassgetdatteExpedi, setanimationClassgetdatteExpedi] = useState('');
  const elemetRefdatteExpedi = useRef(null)

  const [status, SetStatus] = useState('')
  const [animationClassgetdattestatus, setanimationClassgetdattestatus] = useState('');
  const elemetRefdattestatus = useRef(null)


  const [Classeursoptions, setClasseursoptions] = useState([]);
  useEffect(() => {
    API.get("/classeur/all")
      .then((response) => {
        // Transformation des données pour correspondre à la structure attendue
        const classeursFromAPI = response.data.map(classeur => ({
          value: classeur.id.toString(), // Conversion en chaîne si nécessaire
          label: classeur.noClasseur
        }));
        // Mettre à jour l'état avec les nouvelles données
        setClasseursoptions([selectedOption3, ...classeursFromAPI]);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  const hiddenSClasseursoptions = Classeursoptions.slice(1);
  const [selectedOption3, setSelectedOption3] = useState({ value: "", label: "Classeur" });
  const [animationClassClasseurs, setanimationClassCpassport] = useState('');
  const elemetRefClasseurs = useRef(null)
  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: 50,
      backgroundColor: "white",

    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "blue" : state.isFocused ? "lightblue" : "white",
      color: state.isSelected ? "white" : "black",
    }),
  };
  const SelectClasseur = (option) => {
    setSelectedOption3(option)
  }

  const isDateValid = (selectedDate) => {
    const today = new Date(); // Obtention de la date d'aujourd'hui
    const dateToValidate = new Date(selectedDate); // Conversion de la date sélectionnée en objet Date
    // Vérification si la date sélectionnée est postérieure à la date d'aujourd'hui
    return dateToValidate <= today;
  };
  const isRecuDateValid = (datteExpedi, datteLettre) => {
    const RecuDate = new Date(datteExpedi); // Date d'Expedition
    const letterDate = new Date(datteLettre); // Date de lettre
    // Vérification si la date d'Expedition est antérieure à la date de lettre
    return RecuDate >= letterDate;
  };

  const [notvalide, setNotValide] = useState(false)
  const handleSubmit = (e) => {
    e.preventDefault()
    if (N_de_Ref.trim() === '') {
      toast.warning('Le N_de_Ref du livre est  obligatoire', {
        autoClose: 2000
      });
      setAnimationClassN_de_Ref('animate__animated animate__shakeX border-2 border-red-500 ')
      setTimeout(() => {
        setAnimationClassN_de_Ref(' ')
      }, 3000)
      elemetRefN_de_Ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      elemetRefN_de_Ref.current.focus()

      return false
    } else if (Nom_de_destinateur.trim() == '') {
      toast.warning('Nom de destinateur  est obligatoire', {
        autoClose: 2000
      });
      setAnimationClassNom_de_destinateur('animate__animated animate__shakeX border-2 border-red-500')
      setTimeout(() => {
        setAnimationClassNom_de_destinateur(' ')
      }, 3000)
      elemetRefNom_de_destinateur.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      elemetRefNom_de_destinateur.current.focus()

      return false
    }

    else if (datteLettre == '') {
      toast.warning("Selectionne la date de lettre", {
        autoClose: 2000
      });
      setanimationClassgetdatteLettre('animate__animated animate__shakeX  border-2 border-red-500')
      setTimeout(() => {
        setanimationClassgetdatteLettre(' ')
      }, 3000)
      elemetRefdatteLettre.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      return false
    }
    else if (!isDateValid(datteLettre)) {
      setNotValide(true)
      toast.warning("La date lettre ne peut pas être postérieure à aujourd'hui.", {
        autoClose: 2000
      });
      setanimationClassgetdatteLettre('animate__animated animate__shakeX  border-2 border-red-500')
      setTimeout(() => {
        setanimationClassgetdatteLettre(' ')
      }, 3000)

      elemetRefdatteLettre.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      return false
    }

    else if (datteExpedi == '') {
      toast.warning("Selectionne la date d'expedition", {
        autoClose: 2000
      });
      setanimationClassgetdatteExpedi('animate__animated animate__shakeX  border-2 border-red-500')
      setTimeout(() => {
        setanimationClassgetdatteExpedi(' ')
      }, 3000)
      elemetRefdatteExpedi.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      return false
    }
    else if (!isRecuDateValid(datteExpedi, datteLettre)) {
      setNotValide(true)
      toast.warning("La date d'Expedition ne peut pas être antérieure à la date de lettre.", {
        autoClose: 2000
      });

      setanimationClassgetdatteExpedi('animate__animated animate__shakeX  border-2 border-red-500')
      setTimeout(() => {
        setanimationClassgetdatteExpedi(' ')
      }, 3000)
      elemetRefdatteExpedi.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      return false
    }
    else if (!isDateValid(datteExpedi)) {
      setNotValide(true)
      toast.warning("La date d'Expedition ne peut pas être postérieure à aujourd'hui.", {
        autoClose: 2000
      });
      setanimationClassgetdatteExpedi('animate__animated animate__shakeX  border-2 border-red-500')
      setTimeout(() => {
        setanimationClassgetdatteExpedi(' ')
      }, 3000)
      elemetRefdatteExpedi.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      return false
    }
    else if (LettreResume.trim() == '') {
      toast.warning("Ecrivez le résume", {
        autoClose: 2000
      });
      setAnimationClassLettreResume('animate__animated animate__shakeX  border-2 border-red-500')
      setTimeout(() => {
        setAnimationClassLettreResume(' ')
      }, 3000)
      elemetRefLettreResume.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      elemetRefLettreResume.current.focus()
      return false
    }
    else if (status == '') {
      toast.warning("Selectionne le status", {
        autoClose: 2000
      });
      setanimationClassgetdattestatus('animate__animated animate__shakeX  border-2 border-red-500')
      setTimeout(() => {
        setanimationClassgetdattestatus(' ')
      }, 3000)
      elemetRefdattestatus.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      return false
    }
    else if (selectedOption3.value == '') {
      toast.warning('Le classeurs est obligatoire', {
        autoClose: 2000
      });
      setanimationClassCpassport('animate__animated animate__shakeX border-2 border-red-500')
      setTimeout(() => {
        setanimationClassCpassport(' ')
      }, 3000)
      elemetRefClasseurs.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      return;
    }

    const data = {
      noref: N_de_Ref,
      recu: 0,
      status: status,
      lettredate: datteLettre,
      lettrerecu: datteExpedi,
      destinateur: Nom_de_destinateur,
      resune: LettreResume,
      classeurId: selectedOption3.value
    }

    API.post("/courriers", data).then((res) => {
      toast.success(res.data)
      SetN_de_Ref("")
      SetNom_de_destinateur("")
      SetLettreResume("")
      SetdatteLettre("")
      SetdatteExpedi("")
      SetStatus("")
      history("/Courriers/ListeExpedier")
    }).catch((error) => {
      if (error.response) {
        // Le serveur a répondu avec un code d'erreur (status code)

        toast.warn(error.response.data[0])
        // Afficher les erreurs au sein de votre composant ou effectuer une action en conséquence
      } else if (error.request) {
        // La requête a été faite mais aucune réponse n'a été reçue
        console.error('Pas de réponse du serveur :', error.request);
      } else {
        // Une erreur s'est produite lors de la configuration de la requête
        console.error('Erreur lors de la requête :', error.message);
      }
    })
  }


  return (
    <>
      <div className={`bg-white  overflow-x-auto  h-[85vh] ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
        <Link to='/Courriers/ListeExpedier' className="ml-2 text-[18px] text-blue-500 hover:text-blue-800 underline">Retour</Link>
        <div className="sm:p-6  p-2 min-h-[85vh]">


          <div class="sm:pt-6 px-2 pt-1">
            <div class="sm:my-10 my-3">
              <div class="w-full flex flex-row items-center justify-between">
                <p class="text-navy-700 shrink">
                  <p
                    class="hover:text-navy-700 text-gray-700 uppercase sm:text-3xl font-bold"
                  >
                    Ajouter une lettre expedie
                  </p>
                </p>

              </div>
            </div>
            <div class="w-full my-2 grid grid-cols-1 ">
              <div class="bg-white shadow rounded-lg p-2 sm:p-6 xl:p-8">
                <form onSubmit={handleSubmit}>
                  <div class="bg-white shadow-md rounded px-4 pt-6 pb-8 mb-4 flex flex-col my-2">
                    <div class="-mx-3 md:flex mb-6">
                      <div class="md:w-1/2 px-1 mb-6 md:mb-0">
                        <label
                          class="block uppercase tracking-wide text-gray-700 text-lg font-bold mb-2"
                          for="grid-Title"
                        >
                          N° de Ref
                        </label>
                        <input
                          class={` outline-none block w-full bg-grey-lighter  border rounded py-3 px-4 mb-3 ${N_de_Ref.trim() === '' ? animationClassN_de_Ref : 'focus:border-2 focus:border-blue-700'} `}
                          type="text"
                          ref={elemetRefN_de_Ref} value={N_de_Ref} onInput={(e) => SetN_de_Ref(e.target.value)}

                          placeholder="N° de Ref"
                          name="nRef"
                        />

                      </div>
                      <div class="md:w-1/2 px-3">
                        <label
                          class="block uppercase tracking-wide text-gray-700 text-lg font-bold mb-2"
                          for="grid-last-name"
                        >
                          Nom de destinateur
                        </label>
                        <input
                          ref={elemetRefNom_de_destinateur} value={Nom_de_destinateur} onInput={(e) => SetNom_de_destinateur(e.target.value)}
                          class={`appearance-none outline-none block w-full bg-grey-lighter  border rounded py-3 px-4  ${Nom_de_destinateur.trim() === '' ? animationClassNom_de_destinateur : 'focus:border-2 focus:border-blue-700'}`}
                          id="grid-last-name"
                          placeholder="Nom de destinateur"

                        />

                      </div>
                    </div>
                    <div class="-mx-3 md:flex mb-6">


                      <div class="md:w-1/2 px-3">
                        <label
                          class="block uppercase tracking-wide text-gray-700 text-lg font-bold mb-2"
                          for="grid-password"
                        >
                          Date de la lettre
                        </label>
                        <input
                          class={`appearance-none outline-none block w-full bg-grey-lighter  border rounded py-3 px-4  ${datteLettre == '' || notvalide ? animationClassgetdatteLettre : 'focus:border-2 focus:border-blue-700'}`}
                          onChange={(e) => { SetdatteLettre(e.target.value); }}

                          type="date"
                          ref={elemetRefdatteLettre}
                          placeholder="Date d'Expedition"

                        />

                      </div>
                      <div class="md:w-1/2 px-3">
                        <label
                          class="block uppercase tracking-wide text-gray-700 text-lg font-bold mb-2"
                          for="grid-password"
                        >
                          Date d'Expedition
                        </label>
                        <input
                          class={`appearance-none outline-none block w-full bg-grey-lighter  border rounded py-3 px-4  ${datteExpedi == '' || notvalide ? animationClassgetdatteExpedi : 'focus:border-2 focus:border-blue-700'}`}
                          onChange={(e) => { SetdatteExpedi(e.target.value) }}
                          ref={elemetRefdatteExpedi}

                          type="date"

                          placeholder="Date d'Expedition"
                          name="dateExpedition"
                        />
                      </div>


                    </div>
                    <div class="-mx-3 md:flex mb-6">
                      <div class="md:w-1/2 px-3">
                        <label
                          class="block uppercase tracking-wide text-gray-700 text-lg font-bold mb-2"
                          for="grid-password"
                        >
                          Resume de la lettre
                        </label>
                        <textarea
                          ref={elemetRefLettreResume} value={LettreResume} onInput={(e) => SetLettreResume(e.target.value)}
                          class={`appearance-none outline-none block w-full bg-grey-lighter  border rounded py-3 px-4  ${LettreResume.trim() === '' ? animationClassLettreResume : 'focus:border-2 focus:border-blue-700'}`}
                          id="grid-last-name"
                          placeholder=" Resume de la lettre"


                        ></textarea>

                      </div>
                      <div class="md:w-1/2 px-3">
                        <label
                          class="block uppercase tracking-wide text-gray-700 text-lg font-bold mb-2"
                          for="grid-password"
                        >
                          Status
                        </label>
                        <select
                          class={`appearance-none outline-none block w-full bg-white  border rounded py-3 px-4  ${status == '' ? animationClassgetdattestatus : 'focus:border-2 focus:border-blue-700'}`}
                          id="grid-password"
                          type="date"
                          placeholder="Date d'Expedition"
                          ref={elemetRefdattestatus}
                          onChange={(e) => { SetStatus(e.target.value) }}
                        >
                          <option value='' hidden> Status</option>
                          <option value=' Non répondu'> Non arrivé</option>
                          <option value=' répondu'> Arrivé</option>
                        </select>
                      </div>
                    </div>

                    <div class="md:w-1/2 px-3">
                      <label
                        class="block uppercase tracking-wide text-gray-700 text-lg font-bold mb-2"
                        for="grid-state"
                      >
                        Classeurs
                      </label>
                      <div ref={elemetRefClasseurs} className={` rounded  ${selectedOption3.value == '' ? animationClassClasseurs : 'focus:border-2 focus:border-blue-700'} `}
                      >
                        <Select
                          value={selectedOption3}
                          options={hiddenSClasseursoptions}
                          styles={customStyles}
                          onChange={SelectClasseur}

                        />
                      </div>

                    </div>
                    <div class="mb-4 flex items-center justify-end">
                      <div class="flex-shrink-0">
                        <button
                          type="submit"
                          class="mt-4 sm:mt-0 text-lg font-medium leading-none text-white inline-flex items-start justify-start px-6 py-3 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg"
                        >
                          Ajouter
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

        </div>
        <FootterCopy />
      </div>
    </>
  );
}

export default AjouterExpedier;
