/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { FadeLoader } from 'react-spinners'
import Webcam from 'react-webcam';
import { Link, useNavigate } from "react-router-dom";
import FootterCopy from "../../FootterCopy/FootterCopy";
import { API } from "../../../Api/API";
function AjouteVisiteurBiblio({ getSizeMobille }) {
    const history = useNavigate()
    const webcamRef = useRef(null);
    const webcamRef1 = useRef(null);

    const [cameraActive, setCameraActive] = useState(false);
    const [cameraActive1, setCameraActive1] = useState(false);
    const [shoMenu, GetMenus] = useState(false);
    const [shoMenu1, GetMenus1] = useState(false);

    const [mobile1, Setmonile1] = useState(window.innerWidth < 509)

    const [mobile11, SetMobile11] = useState(window.innerWidth < 501)

    useEffect(() => {
        const hundlesSize = () => {
            Setmonile1(window.innerWidth < 509)
            SetMobile11(window.innerWidth < 501)
        }

        window.addEventListener('resize', hundlesSize)

        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])

    const [imageLoading, SetImageLoading] = useState(true)
    const [spinnerButton, SetSpinnerButton] = useState(false)

    const [nom, Setnom] = useState("");
    const [animationClassNom, setAnimationClassNom] = useState("");
    const elemetRefNom = useRef(null);



    const [tel, SetTel] = useState("");
    const [animationClassTel, setAnimationClassTel] = useState("");
    const elemetRefTel = useRef(null);

    const [codeIndentite, SetcodeIndentite] = useState("");
    const [animationClasscodeIndentite, setanimationClassIdentite] = useState("");
    const elemetRefcodeIndentite = useRef(null);
    const [cnii, setcnii] = useState(null);
    const [cniiCap, setcniiCap] = useState(null);
    const [cnio, setcnio] = useState(null);
    const [cnioCap, setcnioCap] = useState(null);


    const [PhotoAvantCNI, SetPhotoAvantCNI] = useState("")
    const [animationClassPhotoAvantCNI, setAnimationClassPhotoAvantCNI] = useState('');
    const elementPhotosAvant = useRef(null)

    const [PhotoAprestCNI, SetPhotoAprestCNI] = useState("")
    const [animationClassPhotoAprestCNI, setAnimationClassPhotoAprestCNI] = useState('');
    const elementPhotosApres = useRef(null)

    const [imageLoading1, SetImageLoading1] = useState(true)

    const [codeAddress, SetcodeAddress] = useState("");
    const [animationClasscodeAddress, setanimationClasscodeAddress] = useState("");
    const elemetRefcodeAddress = useRef(null);

    // Convertit une URI de données en fichier
    const dataURLtoFile = (dataurl, filename) => {
        const arr = dataurl.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    };

    const capture = () => {
        const imageSrc = webcamRef.current.getScreenshot();
        SetPhotoAvantCNI(imageSrc);
        setcniiCap(imageSrc)
        GetMenus(false)
        setCameraActive(false);
    };

    const capture1 = () => {
        const imageSrc1 = webcamRef1.current.getScreenshot();
        SetPhotoAprestCNI(imageSrc1);
        setcnioCap(imageSrc1)
        GetMenus1(false)
        setCameraActive1(false);
    };

    const OpenCamera = () => {
        SetPhotoAvantCNI(null);
        setCameraActive(true);
    };
    const OpenCamera1 = () => {
        SetPhotoAprestCNI(null);
        setCameraActive1(true);
    };

    const FermeCamera = () => {
        SetPhotoAvantCNI(null);
        setCameraActive(false);
    }

    const FermeCamera1 = () => {
        SetPhotoAprestCNI(null);
        setCameraActive1(false);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (nom.trim() === "") {
            toast.warning("Le Nom & Prenom est obligatoire", {
                autoClose: 2000,
            });
            setAnimationClassNom(
                "animate__animated animate__shakeX placeholder-shown:border-red-500"
            );
            setTimeout(() => {
                setAnimationClassNom(" ");
            }, 3000);
            elemetRefNom.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            elemetRefNom.current.focus();
            return;
        } else if (tel.trim() == "") {
            toast.warning("Le numero de téléphone  est obligatoire !!", {
                autoClose: 2000,
            });
            setAnimationClassTel(
                "animate__animated animate__shakeX placeholder-shown:border-yellow-700 text-yellow-500 border-yellow-700"
            );
            setTimeout(() => {
                setAnimationClassTel(" ");
            }, 3000);
            elemetRefTel.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            elemetRefTel.current.focus();
            return;
        } else if (codeIndentite.trim() == "") {
            toast.warning("Le numéro d'identité est obligatoire", {
                autoClose: 2000,
            });
            setanimationClassIdentite(
                "animate__animated animate__shakeX border-red-500"
            );
            setTimeout(() => {
                setanimationClassIdentite(" ");
            }, 3000);
            elemetRefcodeIndentite.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            elemetRefcodeIndentite.current.focus();
            return;
        } else if (PhotoAvantCNI == "") {
            toast.warning(`Photo face avant du C.N.I est obligatoire`, {
                autoClose: 5000,
                position: "top-center",

            })
            setAnimationClassPhotoAvantCNI('animate__animated animate__shakeX  text-white border-b-2 bg-red-500 border-orange-500')
            setTimeout(() => {
                setAnimationClassPhotoAvantCNI(' ')
            }, 5000)
            elementPhotosAvant.current.scrollIntoView({ behavior: 'smooth', block: 'center' })

            return
        }
        else if (PhotoAprestCNI == "") {
            toast.warning(`Photo face arrière du C.N.I est obligatoire`, {
                autoClose: 5000,
                position: "top-center",

            })
            setAnimationClassPhotoAprestCNI('animate__animated animate__shakeX  text-white border-b-2 bg-red-500 border-orange-500')
            setTimeout(() => {
                setAnimationClassPhotoAprestCNI(' ')
            }, 5000)
            elementPhotosApres.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
            return
        } else if (codeAddress.trim() == "") {
            toast.warning("Votre Adresse Complet est obligatoire", {
                autoClose: 2000,
            });
            setanimationClasscodeAddress(
                "animate__animated animate__shakeX border-red-500"
            );
            setTimeout(() => {
                setanimationClassIdentite(" ");
            }, 3000);
            elemetRefcodeAddress.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            elemetRefcodeAddress.current.focus();
            return;
        } else {
            // Créez un objet FormData pour envoyer l'image capturée au backend
            const formData = new FormData();
            if (cniiCap) {
                formData.append('photocniavant', dataURLtoFile(cniiCap, 'photo1.png'));
            } else {
                formData.append('photocniavant', cnii);
            }
            if (cnioCap) {
                formData.append('photocniapres', dataURLtoFile(cnioCap, 'photo2.png'));
            } else {
                formData.append('photocniapres', cnio);
            }
            formData.append('nomcomplet', nom);
            formData.append('addresscomplet', codeAddress);
            formData.append('tel', tel);
            formData.append('cni', codeIndentite);

            API.post("/visitorlivre/addNew", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }).then((res) => {
                toast.success(res.data)
                history("/Emprunter/visitor")
            }).catch((error) => {
                if (error.response) {
                    // Le serveur a répondu avec un code d'erreur (status code)
                    if (Array.isArray(error.response.data)) {
                        // Si c'est un tableau, afficher le premier élément
                        toast.warn(error.response.data[0]);
                    } else if (typeof error.response.data === 'object') {
                        // S'il s'agit d'un objet, afficher la première valeur de la propriété
                        const keys = Object.keys(error.response.data);
                        if (keys.length > 0) {
                            toast.warn(error.response.data[keys[0]]);
                        }
                    } else if (typeof error.response.data === 'string') {
                        // Si c'est une chaîne de caractères, l'afficher directement
                        toast.warn(error.response.data);
                    } else {
                        // Autre type non pris en charge
                        toast.warn("Erreur du serveur")

                    }
                    // Afficher les erreurs au sein de votre composant ou effectuer une action en conséquence
                } else if (error.request) {
                    // La requête a été faite mais aucune réponse n'a été reçue
                    console.error('Pas de réponse du serveur :', error.request);
                } else {
                    // Une erreur s'est produite lors de la configuration de la requête
                    console.error('Erreur lors de la requête :', error.message);
                }
            })
        }

    };

    return (
        <>
            <div className={`bg-white  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
                <Link to='/Emprunter/visitor' className="ml-2 text-[18px] text-blue-500 hover:text-blue-800 underline">Retour</Link>

                <div class="my-5">
                    <div class="w-full flex flex-row items-center justify-between">
                        <p class="text-navy-700 shrink ml-2 ">
                            <div class="hover:text-navy-700 sm:text-2xl font-bold  ">
                                Ajouter un Visiteur
                            </div>
                        </p>
                    </div>
                </div>
                <div className="flex justify-center w-full  mb-5  ">
                    <form onSubmit={handleSubmit} className="border   rounded-2xl border-blue-100 m-3 p-1 sm:p-4 w-full sm:w-[60%]">
                        <div className="w-full ">
                            <div className=" transition-all flex w-[100%] justify-between  ">
                                <div class="w-full px-3 mb-2">
                                    <label
                                        class="block  tracking-wide text-grey-darker text-lg font-bold mb-2"
                                        for="grid-Title"
                                    >
                                        Nom & Prenom
                                    </label>
                                    <input
                                        class={`appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4 mb-3 ${animationClassNom} `}
                                        id="grid-Title"
                                        type="text"
                                        placeholder="Nom et Prenom"
                                        ref={elemetRefNom}
                                        value={nom}
                                        onInput={(e) => Setnom(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className=" mt-[10px] transition-all flex w-[100%] justify-between  ">
                                <div class="w-full px-3">
                                    <label
                                        class="block  tracking-wide text-grey-darker text-lg font-bold mb-2"
                                        for="grid-password"
                                    >
                                        Numéro de Téléphone
                                    </label>
                                    <input
                                        class={`appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4 mb-3 ${animationClassTel} `}
                                        id="grid-password"
                                        type="tel"
                                        placeholder="Numéro de Téléphone"
                                        value={tel}
                                        ref={elemetRefTel}
                                        onInput={(e) => SetTel(e.target.value)}

                                    />
                                </div>
                            </div>
                            <div class="w-full px-3 mb-6 md:mb-0">
                                <label
                                    class="block  tracking-wide text-grey-darker text-lg font-bold mb-2"
                                    for="grid-Title"
                                >
                                    Numéro CNI
                                </label>
                                <input
                                    class={`appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4 mb-3 ${animationClasscodeIndentite} `}
                                    id="grid-Title"
                                    type="text"
                                    placeholder="Numéro CNI"
                                    value={codeIndentite}
                                    ref={elemetRefcodeIndentite}
                                    onInput={(e) => SetcodeIndentite(e.target.value)}
                                />
                            </div>

                            <div className={`mt-[10px] transition-all flex  ${mobile1 ? 'flex-col' : ''} justify-between`}>
                                <div ref={elementPhotosAvant} class={`relative h-max mt-3 flex flex-col justify-center items-center bg-gray-100 p-2 text-gray-700  mr-2 ${animationClassPhotoAvantCNI}`}>

                                    {shoMenu && <div className="bg-[#00000083] absolute w-full h-full items-center px-2 flex justify-between top-0 left-0">
                                        <div onClick={OpenCamera} className="bg-blue-200 p-2 rounded-lg text-blue-800 cursor-pointer">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-camera-fill w-10 h-10" viewBox="0 0 16 16">
                                                <path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                                                <path d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1m9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0" />
                                            </svg>
                                        </div>
                                        {cameraActive &&
                                            <div className="w-full h-full fixed top-0 left-0 bg-[#000000fa] z-[200]">
                                                <div onClick={FermeCamera} className="bg-red-200 rounded-full cursor-pointer absolute p-2 right-2 top-2 ">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi h-6 w-6 text-red-600 bi-x-octagon-fill" viewBox="0 0 16 16">
                                                        <path d="M11.46.146A.5.5 0 0 0 11.107 0H4.893a.5.5 0 0 0-.353.146L.146 4.54A.5.5 0 0 0 0 4.893v6.214a.5.5 0 0 0 .146.353l4.394 4.394a.5.5 0 0 0 .353.146h6.214a.5.5 0 0 0 .353-.146l4.394-4.394a.5.5 0 0 0 .146-.353V4.893a.5.5 0 0 0-.146-.353zm-6.106 4.5L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708" />
                                                    </svg>
                                                </div>
                                                <div className="w-full h-full flex justify-center items-center">
                                                    <Webcam
                                                        audio={false}
                                                        ref={webcamRef}
                                                        width='60%'
                                                        screenshotFormat="image/png"
                                                    />
                                                    <button onClick={capture} className="absolute bottom-2 font-bold text-green-700 bg-green-300 px-3 py-2 rounded" >Prendre une photo</button>

                                                </div>
                                            </div>

                                        }

                                        <label htmlFor="CNIAvant" className="bg-blue-200 p-2 rounded-lg text-blue-800 cursor-pointer">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-image-fill w-9 h-9" viewBox="0 0 16 16">
                                                <path d="M.002 3a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-12a2 2 0 0 1-2-2zm1 9v1a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V9.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062zm5-6.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0" />
                                            </svg>
                                        </label>
                                        <div onClick={() => GetMenus(false)} className="bg-red-200 p-2 rounded-lg text-red-800 cursor-pointer">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x w-8 h-8" viewBox="0 0 16 16">
                                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                                            </svg>
                                        </div>
                                    </div>
                                    }
                                    <input accept='image/*' type='file' id="CNIAvant" hidden onChange={(e) => { const PhotosAvantCNI = e.target.files[0]; setcnii(PhotosAvantCNI); SetPhotoAvantCNI(URL.createObjectURL(PhotosAvantCNI)) }} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassPhotoAvantCNI}`} />
                                    <div onClick={() => GetMenus(true)} class={`px-2  w-full max-sm:text-center py-2 rounded cursor-pointer sm:text-center `}>
                                        Photo face avant du C.N.I
                                    </div>
                                    <div className={`  ${!PhotoAvantCNI ? 'w-[0em] h-[0em] opacity-0 overflow-hidden' : `${mobile1 ? 'w-full' : 'w-[12.5em] '} block h-[12.5em] opacity-100`}}   transition-all   mt-1 rounded  bg-white  border border-blue-500`}>
                                        {imageLoading && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                                        <img draggable='false' onLoad={() => {
                                            setTimeout(() => {
                                                SetImageLoading(false)
                                            }, 1000)
                                        }} src={PhotoAvantCNI} className="w-full h-full object-contain pointer-events-none" />
                                    </div>
                                </div>
                                <div ref={elementPhotosApres} class={`relative flex flex-col justify-center items-center h-max mt-3 bg-gray-100 text-gray-700 p-2  mr-2 ${animationClassPhotoAprestCNI}`}>

                                    {shoMenu1 && <div className="bg-[#00000083] absolute w-full h-full items-center px-2 flex justify-between top-0 left-0">
                                        <div onClick={OpenCamera1} className="bg-blue-200 p-2 rounded-lg text-blue-800 cursor-pointer">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-camera-fill w-10 h-10" viewBox="0 0 16 16">
                                                <path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                                                <path d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1m9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0" />
                                            </svg>
                                        </div>
                                        {cameraActive1 &&
                                            <div className="w-full h-full fixed top-0 left-0 bg-[#000000fa] z-[200]">
                                                <div onClick={FermeCamera1} className="bg-red-200 rounded-full cursor-pointer absolute p-2 right-2 top-2 ">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi h-6 w-6 text-red-600 bi-x-octagon-fill" viewBox="0 0 16 16">
                                                        <path d="M11.46.146A.5.5 0 0 0 11.107 0H4.893a.5.5 0 0 0-.353.146L.146 4.54A.5.5 0 0 0 0 4.893v6.214a.5.5 0 0 0 .146.353l4.394 4.394a.5.5 0 0 0 .353.146h6.214a.5.5 0 0 0 .353-.146l4.394-4.394a.5.5 0 0 0 .146-.353V4.893a.5.5 0 0 0-.146-.353zm-6.106 4.5L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708" />
                                                    </svg>
                                                </div>
                                                <div className="w-full h-full flex justify-center items-center">
                                                    <Webcam
                                                        audio={false}
                                                        ref={webcamRef1}
                                                        width='60%'
                                                        screenshotFormat="image/png"
                                                    />
                                                    <button onClick={capture1} className="absolute bottom-2 font-bold text-green-700 bg-green-300 px-3 py-2 rounded" >Prendre une photo</button>

                                                </div>
                                            </div>

                                        }

                                        <label htmlFor="CNIArrier" className="bg-blue-200 p-2 rounded-lg text-blue-800 cursor-pointer">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-image-fill w-9 h-9" viewBox="0 0 16 16">
                                                <path d="M.002 3a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-12a2 2 0 0 1-2-2zm1 9v1a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V9.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062zm5-6.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0" />
                                            </svg>
                                        </label>
                                        <div onClick={() => GetMenus1(false)} className="bg-red-200 p-2 rounded-lg text-red-800 cursor-pointer">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x w-8 h-8" viewBox="0 0 16 16">
                                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                                            </svg>
                                        </div>
                                    </div>
                                    }



                                    <input accept='image/*' type='file' id="CNIArrier" hidden onChange={(e) => { const PhotosApprestCNI = e.target.files[0]; setcnio(PhotosApprestCNI); SetPhotoAprestCNI(URL.createObjectURL(PhotosApprestCNI)) }} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500  ${animationClassPhotoAprestCNI}`} placeholder=" " />
                                    <div onClick={() => GetMenus1(true)} class={`  px-2 w-full max-sm:text-center py-2 rounded cursor-pointer `}>
                                        Photo face arrière du C.N.I
                                    </div>
                                    <div className={` ${!PhotoAprestCNI ? 'w-[0em] h-[0em] opacity-0 overflow-hidden' : `${mobile1 ? 'w-full' : 'w-[12.5em] '} block h-[12.5em] opacity-100`}} transition-all   mt-1 rounded  bg-white border border-blue-500`}>
                                        {imageLoading1 && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                                        <img draggable='false' onLoad={() => {
                                            setTimeout(() => {
                                                SetImageLoading1(false)
                                            }, 1000)
                                        }} src={PhotoAprestCNI} className="w-full h-full object-contain" />
                                    </div>
                                </div>
                            </div>

                            <div class="relative h-max mt-[10px]  w-full   mr-2 ">
                                <div class="w-full px-3">
                                    <label
                                        class="block  tracking-wide text-grey-darker text-lg font-bold mb-2"
                                        for="grid-last-name"
                                    >
                                        Adresse Complet
                                    </label>
                                    <textarea
                                        class={`appearance-none resize-none ${animationClasscodeAddress} h-[7em] block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4`}
                                        id="grid-last-name"
                                        type="text"
                                        placeholder="Adresse Complet"
                                        value={codeAddress}
                                        ref={elemetRefcodeAddress}
                                        onInput={(e) => SetcodeAddress(e.target.value)}
                                    ></textarea>
                                </div>
                            </div>

                        </div>

                        <div className="flex justify-end items-center mt-4">
                            {spinnerButton ? (
                                <>
                                    <div className="flex justify-end items-center mt-4 relative pointer-events-none opacity-80 bg-gray-900 rounded  cursor-pointer px-5 py-1 text-gray-600">
                                        <div className='absolute bg-transparent   pt-5 pl-10  w-full h-full flex justify-center items-center z-50'>
                                            <FadeLoader
                                                color="rgb(255, 255, 255)"
                                                height={10}
                                                margin={-9}
                                                radius={100}
                                                speedMultiplier={1}
                                                width={1}
                                            /></div>
                                        <input type="submit" id="send" value="Ajouter" class=" transition-all "></input>
                                        <i class="bi bi-send ml-2 "></i>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <label
                                        for="send"
                                        className=" cursor-pointer w-max  flex justify-end  bg-blue-600   p-2 rounded  text-white"
                                    >
                                        <input
                                            type="submit"
                                            id="send"
                                            value="Ajouter"
                                            className="cursor-pointer "
                                        ></input>
                                        <i class="bi bi-send ml-2 "></i>
                                    </label>

                                </>
                            )}
                        </div>

                    </form>
                </div>
                <FootterCopy />
            </div>

        </>
    );
}

export default AjouteVisiteurBiblio;
