import React from 'react'
import SlideNavBarsCantin from '../SlideNavBarsCantin/SlideNavBarsCantin'
import Vente from './Vente'
function VenteCompoent() {
    return (
        <div className='overflow-hidden pt-16 flex bg-white'>
            <SlideNavBarsCantin />
            <Vente />
        </div>
    )
}

export default VenteCompoent
