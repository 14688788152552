/* eslint-disable no-unused-vars */


/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable eqeqeq */
import React, { Component, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import FootterCopy from "../../FootterCopy/FootterCopy";
import { useReactToPrint } from "react-to-print";
import { FadeLoader } from "react-spinners";
import { ListdortoirsPrefetDisplin } from '../../../Data/Data'


function useSortedData(ListdortoirsPrefetDisplin, inputValue, sortedColumn, sortOrder) {
    const fildata = (data, inputValue) => {
        if (!inputValue.trim()) {
            return data;
        }
        return data.filter((item) => {
            if (
                item.nomDortoire.toLowerCase().includes(inputValue.toLowerCase())
            ) {
                return true;
            }
            return false;
        });
    };


    const sortData = (data, column, sortOrder) => {
        return data.slice().sort((a, b) => {
            const aValue = a[column];
            const bValue = b[column];

            if (aValue < bValue) return sortOrder === 'asc' ? -1 : 1;
            if (aValue > bValue) return sortOrder === 'asc' ? 1 : -1;
            return 0;
        });
    };

    const filteredData = fildata(ListdortoirsPrefetDisplin, inputValue);
    const sortedData = sortData(filteredData, sortedColumn, sortOrder);

    return sortedData;
}



function DortoireAffichangePrefetDisplin({ getSizeMobille }) {
    const [mobile1, Setmonile1] = useState(window.innerWidth < 924)
    const [mobile2, Setmonile2] = useState(window.innerWidth < 520)
    const [litNumber, SetlitNumber] = useState(false)
    const elementRefNumberLit = useRef(null)
    useEffect(() => {
        const hundlesSize = () => {
            Setmonile1(window.innerWidth < 924)
            Setmonile2(window.innerWidth < 520)
        }
        window.addEventListener('resize', hundlesSize)

        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])


    const [inputValue, setInputValue] = useState('');
    const [sortOrder, setSortOrder] = useState('asc');
    const [sortedColumn, setSortedColumn] = useState(null);

    const ValuesInput = (e) => {
        setInputValue(e.target.value);
    };




    const handleSort = (column) => {
        if (column === sortedColumn) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortOrder('asc');
            setSortedColumn(column);
        }
    };

    const newData = useSortedData(ListdortoirsPrefetDisplin, inputValue, sortedColumn, sortOrder);
    const [sortedData, setSortedData] = useState(ListdortoirsPrefetDisplin);

    useEffect(() => {
        setSortedData(newData);
    }, [ListdortoirsPrefetDisplin, inputValue, sortedColumn, sortOrder]);






    function highlightText(text, inputValue) {
        if (!inputValue) return text;

        const highlightedText = [];
        let currentIndex = 0;

        while (currentIndex < text.length) {
            const inputChar = inputValue.toLowerCase();
            const matchIndex = text.substr(currentIndex).toLowerCase().indexOf(inputChar);

            if (matchIndex !== -1) {

                highlightedText.push(text.substring(currentIndex, currentIndex + matchIndex));


                highlightedText.push(
                    <span className="text-orange-500" key={currentIndex + matchIndex}>
                        {text.substring(currentIndex + matchIndex, currentIndex + matchIndex + inputValue.length)}
                    </span>
                );

                currentIndex = currentIndex + matchIndex + inputValue.length;
            } else {
                highlightedText.push(text.substring(currentIndex));
                break;
            }
        }

        return highlightedText;
    }



    const [mobile11, SetMobile11] = useState(window.innerWidth < 501)

    useEffect(() => {
        const hundlesSize = () => {
            SetMobile11(window.innerWidth < 501)
        }
        window.addEventListener('resize', hundlesSize)
        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])

    const [IsPrinting, setIsPrinting] = useState(false);

    const [cahsPrintAll, SetPrintAll] = useState(false)
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        onBeforePrint: () => {
            setIsPrinting(true);
        },
        onAfterPrint: () => {
            setIsPrinting(false);
        },
        onPrintError: (error) => {
            console.error('Erreur d\'impression :', error);
        },
    });


    useEffect(() => {
        window.onafterprint = () => {
            setIsPrinting(false);
        };
        return () => {
            window.onafterprint = null;
        };
    }, []);

    return (
        <>
            <div className={`bg-white  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
                <Link to='/Dortoire' className="ml-2 text-[18px] text-blue-500 hover:text-blue-800 underline">Retour</Link>
                <div className="p-6 min-h-[90vh]">
                    <div class="flex flex-wrap items-center pr-4  mt-3 h-max w-full  justify-between">
                        <div className="flex items-center">
                            <div class="mx-2">
                                <div class="relative lg:w-64">
                                    <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                        <svg
                                            class="w-5 h-5 text-gray-500"
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fill-rule="evenodd"
                                                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                                clip-rule="evenodd"
                                            ></path>
                                        </svg>
                                    </div>
                                    <input
                                        Nom_Eleve="text"
                                        name="email"
                                        id="topbar-search"
                                        class="bg-white border border-gray-300 h-10 text-gray-900 sm:text-lg first-letter:uppercase rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full pl-10 .5"
                                        placeholder="Rechercher..."
                                        onInput={ValuesInput}
                                    />
                                </div>
                            </div>
                            <button class="mt-4 sm:mt-0 inline-flex items-start justify-start px-1 py-3 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg">
                                <p class="text-lg first-letter:uppercase font-semibold leading-none text-white">
                                    Ok
                                </p>
                            </button>
                        </div>
                    </div>
                    <div class="w-full my-4 ">
                        <div class="bg-white border border-fuchsia-300 rounded-2xl  p-4 ">
                            <div class={`b-4 flex items-center border-b-2 pb-3  relative justify-between ${mobile2 ? 'flex-col' : ''}`}>

                                {IsPrinting ? (
                                    <>
                                        <div className="w-max flex justify-center relative py-2 px-4">
                                            <div disabled onClick={() => { SetPrintAll(true); setTimeout(() => { handlePrint() }, 0); setTimeout(() => { SetPrintAll(false); }, 0); }} className="border border-blue-100 px-4 py-1 cursor-no-drop w-max opacity-50  bg-green-500 ml-2 text-green-800 transition-all  rounded-md ">Imprimer</div>
                                            <div disabled className='absolute cursor-no-drop  bg-transparent pt-2  pl-8  w-full h-full flex justify-center items-center z-50'>
                                                <FadeLoader
                                                    color="rgb(255, 255, 255)"
                                                    height={10}
                                                    margin={-9}
                                                    radius={100}
                                                    speedMultiplier={1}
                                                    width={1}
                                                />
                                            </div>
                                        </div>

                                    </>
                                ) : (
                                    <>
                                        <div className="w-max flex justify-end py-2 px-4">
                                            <div onClick={() => { SetPrintAll(true); setTimeout(() => { handlePrint() }, 0); setTimeout(() => { SetPrintAll(false); }, 0); }} className="border border-blue-100 px-4 py-1 w-max bg-green-200 ml-2 text-green-600 transition-all  rounded-md cursor-pointer">Imprimer</div>
                                        </div>
                                    </>)}


                                <div>
                                    <h3 class="sm:text-xl font-bold text-gray-900 mb-2">
                                        Affichage générale garçon
                                    </h3>
                                </div>
                            </div>
                            <div class="flex flex-col mt-4 ">
                                <div class="overflow-x-auto rounded-lg">
                                    <div class={`align-middle inline-block  min-w-full`}>
                                        <div className="w-full border  overflow-hidden">
                                            <div className={`flex ${mobile1 ? 'w-[70em]' : 'w-full'} justify-between border-b flex-col`}>
                                                {ListdortoirsPrefetDisplin.map((dormitory, dormitoryIndex) => (
                                                    <React.Fragment key={dormitoryIndex}>
                                                        {dormitoryIndex > 0 && <div className="border-b  w-full"></div>}
                                                        <div style={{ display: 'flex' }} className="w-full relative bg-white">
                                                            <div className="border-r relative  w-1/4">
                                                                {dormitoryIndex === 0 && <div className="bg-gray-50 p-2 text-center font-bold text-[18px]">Dortoire</div>}

                                                                <ul className="h-full relative list-none flex justify-center items-center ">
                                                                    <li key={`${dormitoryIndex}`} className="w-full">
                                                                        <div className="w-full absolute flex justify-center top-1">
                                                                            <Link to="/Dortoire/garcon/comparti" className="py-1 px-2 bg-fuchsia-300 rounded w-max text-fuchsia-700 cursor-pointer">Consulter {dormitory.nomDortoire}</Link>
                                                                        </div>
                                                                        <div className="text-center">{dormitory.nomDortoire}</div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div className="border-r w-full">
                                                                {dormitory.contenuDortoir.map((compartment, compartmentIndex) => (
                                                                    <React.Fragment key={`${dormitoryIndex}-${compartmentIndex}`}>
                                                                        {compartmentIndex > 0 && <div className="border-b  w-full"></div>}
                                                                        <div style={{ display: 'flex' }} className="w-full">
                                                                            <div className=" border-r w-1/4   ">
                                                                                {dormitoryIndex === 0 && compartmentIndex === 0 && <div className="bg-gray-50 p-2 text-center font-bold text-[18px]">Compartiment</div>}
                                                                                <ul className="h-full flex justify-center items-center">
                                                                                    <li key={`${dormitoryIndex}`}>
                                                                                        <div>{compartment.nomCompartiment}</div>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                            <div style={{ flex: 1 }} className="border-r w-full  ">
                                                                                <div>
                                                                                    {compartment.contenusCompartiment.map((bed, bedIndex) => (
                                                                                        <React.Fragment key={`${dormitoryIndex}-${compartmentIndex}-${bedIndex}`}>
                                                                                            {bedIndex > 0 && <div className="border-b  w-full"></div>}
                                                                                            <div style={{ display: 'flex' }} className="w-full">
                                                                                                <div style={{ flex: 1 }} className="border-r   w-1/4 ">
                                                                                                    {bedIndex === 0 && dormitoryIndex === 0 && compartmentIndex === 0 && <div className="bg-gray-50 p-2 text-center font-bold text-[18px]">Numéro Lit</div>}
                                                                                                    <ul className="bg-fuchs h-full flex justify-center items-center">
                                                                                                        <li key={`${dormitoryIndex}`}>
                                                                                                            <div>{bed.Numer_lit}</div>
                                                                                                        </li>
                                                                                                    </ul>
                                                                                                </div>
                                                                                                <div style={{ flex: 1 }}>
                                                                                                    {dormitoryIndex === 0 && compartmentIndex === 0 && bedIndex === 0 && <div className="bg-gray-50 p-2 text-center font-bold text-[18px]">Élèves</div>}
                                                                                                    <ul className="p-2">
                                                                                                        {bed.contenuelit.map((student, studentIndex) => (
                                                                                                            <div className="flex" key={`${dormitoryIndex}-${compartmentIndex}-${bedIndex}-${studentIndex}`}>
                                                                                                                <div> {studentIndex + 1}</div>.
                                                                                                                <div className="flex">
                                                                                                                    <div>{student.nom}</div>
                                                                                                                    <div className="ml-3">Classe : {student.classe}</div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        ))}
                                                                                                    </ul>
                                                                                                </div>
                                                                                            </div>
                                                                                        </React.Fragment>
                                                                                    ))}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </React.Fragment>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex  text-gray-600 mt-2 text-[10px]"><div>Année scolaire :</div> <div className="text-blue-700 font-semibold ml-1"> 2023-2024</div></div>
                    <div className="flex  text-gray-600 mt-1 text-[10px]"><div></div> <div className="text-gray-300 select-none font-semibold  ml-1"> Lycée d'exellence NGAGARA</div></div>

                </div>
                {cahsPrintAll && <PrintAll ref={componentRef} />}

                <FootterCopy />
            </div>

        </>
    );
}

export default DortoireAffichangePrefetDisplin;


class PrintAll extends Component {
    render() {
        const sortedData = ListdortoirsPrefetDisplin;
        return (
            <div class="w-full m-1 ">
                <div class="bg-white  p-4 ">
                    <div class="flex flex-col mt-4 ">
                        <div class="overflow-x-auto rounded-lg">
                            <div class={`align-middle inline-block  min-w-full`}>
                                <div className="w-full border  overflow-hidden">
                                    <div class="w-full text-center my-4 font-bold text-gray-900">
                                        Affichage générale garçon
                                    </div>
                                    <div className="flex w-full justify-between border-b flex-col">
                                        {sortedData.map((dormitory, dormitoryIndex) => (
                                            <React.Fragment key={dormitoryIndex}>
                                                {dormitoryIndex > 0 && <div className="border-b  w-full"></div>}
                                                <div style={{ display: 'flex' }} className="w-full bg-white">
                                                    <div className="border-r   w-1/4">
                                                        {dormitoryIndex === 0 && <div className="bg-gray-50 p-2 text-center font-bold text-[18px]">Dortoire</div>}
                                                        <ul className="bg-fuchs h-full flex justify-center items-center">
                                                            <li key={`${dormitoryIndex}`}>
                                                                <div>{dormitory.nomDortoire}</div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="border-r w-full">
                                                        {dormitory.contenuDortoir.map((compartment, compartmentIndex) => (
                                                            <React.Fragment key={`${dormitoryIndex}-${compartmentIndex}`}>
                                                                {compartmentIndex > 0 && <div className="border-b  w-full"></div>}
                                                                <div style={{ display: 'flex' }} className="w-full">
                                                                    <div className=" border-r w-1/4   ">
                                                                        {dormitoryIndex === 0 && compartmentIndex === 0 && <div className="bg-gray-50 p-2 text-center font-bold text-[18px]">Compartiment</div>}
                                                                        <ul className="h-full flex justify-center items-center">
                                                                            <li key={`${dormitoryIndex}`}>
                                                                                <div>{compartment.nomCompartiment}</div>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <div style={{ flex: 1 }} className="border-r w-full  ">
                                                                        <div>
                                                                            {compartment.contenusCompartiment.map((bed, bedIndex) => (
                                                                                <React.Fragment key={`${dormitoryIndex}-${compartmentIndex}-${bedIndex}`}>
                                                                                    {bedIndex > 0 && <div className="border-b  w-full"></div>}
                                                                                    <div style={{ display: 'flex' }} className="w-full">
                                                                                        <div style={{ flex: 1 }} className="border-r   w-1/4 ">
                                                                                            {bedIndex === 0 && dormitoryIndex === 0 && compartmentIndex === 0 && <div className="bg-gray-50 p-2 text-center font-bold text-[18px]">Numéro Lit</div>}
                                                                                            <ul className="bg-fuchs h-full flex justify-center items-center">
                                                                                                <li key={`${dormitoryIndex}`}>
                                                                                                    <div>{bed.Numer_lit}</div>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </div>
                                                                                        <div style={{ flex: 1 }}>
                                                                                            {dormitoryIndex === 0 && compartmentIndex === 0 && bedIndex === 0 && <div className="bg-gray-50 p-2 text-center font-bold text-[18px]">Élèves</div>}
                                                                                            <ul className="p-2">
                                                                                                {bed.contenuelit.map((student, studentIndex) => (
                                                                                                    <div className="flex" key={`${dormitoryIndex}-${compartmentIndex}-${bedIndex}-${studentIndex}`}>
                                                                                                        <div> {studentIndex + 1}</div>.
                                                                                                        <div className="flex">
                                                                                                            <div>{student.nom}</div>
                                                                                                            <div className="ml-3">Classe : {student.classe}</div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                ))}
                                                                                            </ul>
                                                                                        </div>
                                                                                    </div>
                                                                                </React.Fragment>
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </React.Fragment>
                                                        ))}
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex  text-gray-600 mt-2 text-[10px]"><div>Année scolaire :</div> <div className="text-blue-700 font-semibold ml-1"> 2023-2024</div></div>
                <div className="flex  text-gray-600 mt-1 text-[10px]"><div></div> <div className="text-gray-300 select-none font-semibold  ml-1"> Lycée d'exellence NGAGARA</div></div>
            </div>
        )
    }
}


