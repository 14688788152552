import React, { useState } from "react";
import DetailDossierPersComponent from "../../component/Secretariat/DossierPersonnel/DetailDossierPersComponent";
function DetailDossierPersPage({ getSizeMobille, getMenuMobille }) {
  return (
    <>
      <DetailDossierPersComponent getMenuMobille={getMenuMobille} getSizeMobille={getSizeMobille} />
    </>
  );
}

export default DetailDossierPersPage;
