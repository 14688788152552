import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import FootterCopy from '../../FootterCopy/FootterCopy'
import { API } from '../../../Api/API'
import { encryptData } from '../../../encryptionModule'
function ClasseChoixSecretaire({ getSizeMobille }) {
    const [mobile1, Setmonile1] = useState(window.innerWidth < 326)
    useEffect(() => {
        const hundlesSize = () => {
            Setmonile1(window.innerWidth < 326)
        }
        window.addEventListener('resize', hundlesSize)

        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])
    const [CountByClassAndSchoolyear, setCountByClassAndSchoolyear] = useState([])
    useEffect(() => {
        API.get('/student/CountByClassAndSchoolyear').then((resp) => {
            setCountByClassAndSchoolyear(resp.data)
        }).catch((err) => {
            console.log(err.message)
        })
    }, [])
    return (


        CountByClassAndSchoolyear ? (
            <div className={`bg-white  overflow-x-auto  h-[85vh] ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
                <div className="p-2 min-h-[90vh]">
                    <div className="sm:text-3xl text-xl font-bold text-gray-500 mb-4">Listes des classes</div>
                    <div class="flex items-center w-full flex-wrap px-4 justify-end">

                        <div class="flex-shrink-0">
                            <Link
                                to="/ClasseChoixSecretaire/AjouterEleve"
                                class="mt-4  inline-flex items-start justify-start p-2  bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle h-6 text-white w-6" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                </svg>
                            </Link>
                        </div>
                    </div>

                    <div class="my-4 w-full flex flex-wrap  gap-4">

                        {CountByClassAndSchoolyear.map((value) => {
                            return (
                                <Link to={`/ClasseChoixSecretaire/DossierEleve/${encryptData((value.schoolyearId).toString())}/${encryptData((value.classId).toString())}`} class="bg-white shadow rounded-lg hover:scale-105 transition-all cursor-pointer m-2 p-8 ">
                                    <div class="flex items-center">
                                        <div class="">
                                            <span class="text-2xl sm:text-3xl leading-none font-bold text-blue-900">
                                                {value.class}
                                            </span>
                                            <h3 class="text-xl font-normal w-full text-center text-gray-500">
                                                Nombre total  des élèves
                                            </h3>
                                        </div>
                                        <div class="w-9 h-9    mb-4 text-blue-900 font-bold transition duration-75"
                                        >
                                            <div class="w-9 h-9    mb-4 text-green-700 font-bold transition duration-75"
                                            >
                                                {value.totaleleves}
                                            </div>
                                        </div>

                                    </div>

                                    <div className={`flex justify-between ${mobile1 ? 'flex-col ' : ''} mt-10`}>
                                        <div class="flex items-center">
                                            <div class="">
                                                <span class="text-2xl sm:text-2xl leading-none font-bold text-blue-900">
                                                    {value.Filles}
                                                </span>
                                                <h3 class="text-xl font-normal text-gray-500">
                                                    Féminin
                                                </h3>
                                            </div>
                                            <div class="w-9 h-9    mb-4 text-green-700 font-bold transition duration-75"
                                            >
                                                {parseFloat((value.Filles * 100 / value.totaleleves).toFixed(1))}%
                                            </div>

                                        </div>
                                        <div class="flex items-center mt-2">
                                            <div class="">
                                                <span class="text-2xl sm:text-2xl leading-none font-bold text-blue-900">
                                                    {value.garcon}
                                                </span>
                                                <h3 class="text-xl font-normal text-gray-500">
                                                    Garcon
                                                </h3>
                                            </div>
                                            <div class="w-9 h-9    mb-4 text-blue-900 font-bold transition duration-75"
                                            >
                                                <div class="w-9 h-9    mb-4 text-green-700 font-bold transition duration-75"
                                                >
                                                    {parseFloat((value.garcon * 100 / value.totaleleves).toFixed(1))}%
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                    <div className='mt-5 font-serif text-gray-600'>Année scolaire :<span className='text-blue-900 tracking-widest'>{value.aneescholaire}</span> </div>
                                </Link>
                            )
                        })}




                    </div>
                </div>
                <FootterCopy />
            </div>
        ) : (
            <div className={`bg-white  overflow-x-auto  h-[85vh] ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
                <div className="p-2 min-h-[90vh]">
                    <div className="sm:text-3xl text-xl font-bold text-gray-500 mb-4">Listes des classes</div>
                    <div class="flex items-center w-full flex-wrap px-4 justify-end">
                        <div class="flex-shrink-0">
                            <Link
                                to="/ClasseChoixSecretaire/AjouterEleve"
                                class="mt-4  inline-flex items-start justify-start p-2  bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle h-6 text-white w-6" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                </svg>
                            </Link>
                        </div>
                    </div>

                    <div class="my-4 w-full flex flex-wrap  gap-4">
                        <div class="bg-white shadow rounded-lg hover:scale-105 transition-all cursor-pointer m-2 p-8 ">
                            <div className={`flex justify-between ${mobile1 ? 'flex-col ' : ''} mt-10`}>
                                Aucun class
                            </div>
                            <div className='mt-5 font-serif text-gray-600'>Année scolaire :<span className='text-blue-900 tracking-widest'>2023-204</span> </div>
                        </div>
                    </div>
                </div>
                <FootterCopy />
            </div>
        )





    )
}

export default ClasseChoixSecretaire
