import React from 'react'
import DetailDemandePrefetDisplin from './DetailDemandePrefetDisplin'
import SideBarsPrefetDisplin from '../SideBarsPrefetDisplin/SideBarsPrefetDisplin'
function DetailDemandePrefetDisplinComponent({ getSizeMobille, getMenuMobille }) {
    return (
        <div className='flex justify-between overflow-hidden bg-white'>
            <SideBarsPrefetDisplin getMenuMobille={getMenuMobille} />
            <DetailDemandePrefetDisplin getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default DetailDemandePrefetDisplinComponent
