import React from 'react'
import SildeEnseign from '../SildeEnseign/SildeEnseign'
import DemmandeEnse from './DemmandeEnse'
function DemmandeEnseComponent() {
  return (
    <div className=' bg-white flex pt-16 overflow-hidden'>
      <SildeEnseign />
      <DemmandeEnse />
    </div>
  )
}

export default DemmandeEnseComponent

