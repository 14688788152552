import React, { useEffect, useState } from "react";
import { useLocation, Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DELETE, CLEAR_CART } from "../../../Redux/Action/Action";
import FootterCopy from "../../FootterCopy/FootterCopy";
import { decryptData } from "../../../encryptionModule";
import { API } from "../../../Api/API";
import { toast } from "react-toastify";
function CartsEleve({ getSizeMobille }) {
  const navigate = useNavigate()
  const getData = useSelector((state) => state.CartsReducer.Carts);
  const { id } = useParams()
  const dispatch = useDispatch();
  function RemoveToCart(id) {
    dispatch(DELETE(id));
  }

  const [mobile1, Setmonile1] = useState(window.innerWidth < 349)

  const [mobile11, SetMobile11] = useState(window.innerWidth < 501)

  useEffect(() => {
    const hundlesSize = () => {
      Setmonile1(window.innerWidth < 349)
      SetMobile11(window.innerWidth < 501)
    }

    window.addEventListener('resize', hundlesSize)

    return () => {
      window.removeEventListener('resize', hundlesSize)
    }
  }, [])

  if (getData.length < 1) {
    navigate(`/Emprunter/eleve/Liste/Emprunt/${id}`)
  }

  const studentId = decryptData(id)
  const [Nom, SetNom] = useState('')
  const [Matricule, SetMatricule] = useState('')
  useEffect(() => {
    API.get(`/student/ParId/${studentId}`).then((res) => {
      const studentData = res.data;
      SetNom(studentData.nomprenom)
      SetMatricule(studentData.matricule)
    }).catch((err) => {
      console.log(err.message)
    })
  }, [id])


  const handleSubmit = (e) => {
    const exemplairesIdsNumeros = getData.map(exemplaire => ({
      id: exemplaire.id,
      nombrejours: exemplaire.jours
    }));
    const formData = {
      data: exemplairesIdsNumeros,
      studentId: studentId
    }
    API.post("/livres/AjoutEmpruntLivreEleves", formData)
      .then((res) => {
        // navigate(`/Emprunter/eleve/Liste/Emprunt/${id}`)
        dispatch(CLEAR_CART());
        toast.success('Enregistrement avec succes')
      })
      .catch((err) => {
        if (err.response) {
          if (Array.isArray(err.response.data)) {
            toast.warn(err.response.data[0])
          } else if (typeof err.response.data === 'object') {
            // Si c'est un objet, afficher la valeur (par exemple, en la convertissant en chaîne JSON)
            toast.warn(JSON.stringify(err.response.data))
          } else if (typeof err.response.data === 'string') {
            // Si c'est une chaîne de caractères, l'afficher directement
            toast.warn(err.response.data)
          } else {
            // Si c'est autre chose, afficher un message par défaut
            toast.warn('Erreur inattendue lors de la récupération des données de réponse')
          }
          return false;
        }
        console.log(err.message)
      })
      .finally(() => {
        console.log("")
      });
  }
  return (

    <div className={`bg-white  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
      <Link to={`/Emprunter/eleve/Liste/Emprunt/${id}`} className="ml-2 text-[18px] text-blue-500 hover:text-blue-800 underline">Retour</Link>

      <div className="sm:p-4 p-2 min-h-screen">
        <div class="my-5">
          <div class="w-full flex flex-row items-center justify-between">
            <div class="text-navy-700 shrink ">
              <div
                class="hover:text-navy-700 text-2xl font-bold "

              >
                Panier de   <div className="text-blue-400"> {Nom} :{Matricule}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="w-full my-4 grid grid-cols-1 ">
          <div class="bg-white  shadow rounded-lg p-4 sm:p-6 xl:p-8">
            <div class="flex flex-col space-y-3 mt-8">
              {getData.map((item, index) => {
                return (
                  <div class="flex flex-col rounded-lg border border-gray-400 bg-white shadow-lg  md:w-full md:flex-row">
                    <div class="flex flex-col justify-start w-full p-6">
                      <h5 class="mb-2 text-xl font-medium  ">
                        Numero: {item.numero}
                      </h5>
                      <p class="mb-4 text-xl text-neutral-600 ">
                        Titre: {item.titre}
                      </p>
                      <p class="mb-4 text-xl text-neutral-600 ">
                        Dedie: {item.dedie}
                      </p>
                      <p class="mb-4 text-xl text-neutral-600 ">
                        Class: {item.classe}
                      </p>
                      <p class="mb-4 text-xl text-neutral-600 ">
                        Livre recherche: {item.livrecherche}
                      </p>
                      <p class="mb-4 text-xl text-neutral-600 ">
                        Nombre de jours: {item.jours}
                      </p>
                      <div class=" mt-8 flex w-full items-center justify-end ">
                        <div class="flex flex-row flex-shrink-0 space-x-3">
                          <button
                            onClick={() => {
                              RemoveToCart(item.id);
                            }}
                            class="mt-4 sm:mt-0 inline-flex items-start justify-start px-6 py-3 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg"
                          >
                            <p class="text-lg font-medium leading-none text-white">
                              Enlever
                            </p>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div class=" mt-8 flex w-full items-center justify-end ">
              <div class="flex flex-row flex-shrink-0 space-x-3">
                <button onClick={handleSubmit} class="mt-4 sm:mt-0 inline-flex items-start justify-start px-6 py-3 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg">
                  <p class="text-lg font-medium leading-none text-white">
                    Emprunter Tous
                  </p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FootterCopy />
    </div>
  );
}

export default CartsEleve;
