/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable eqeqeq */
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import FootterCopy from "../../FootterCopy/FootterCopy";
import { compartimentsPretDisplin } from "../../../Data/Data";

function GarconCompartementDortoirePrefetDisplin({ getSizeMobille }) {
    const [mobile1, Setmonile1] = useState(window.innerWidth < 990)
    const [mobile2, Setmonile2] = useState(window.innerWidth < 380)

    useEffect(() => {
        const hundlesSize = () => {
            Setmonile1(window.innerWidth < 990)
            Setmonile2(window.innerWidth < 380)
        }
        window.addEventListener('resize', hundlesSize)
        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])



    const [mobile11, SetMobile11] = useState(window.innerWidth < 501)

    useEffect(() => {
        const hundlesSize = () => {
            SetMobile11(window.innerWidth < 501)
        }
        window.addEventListener('resize', hundlesSize)
        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])


    return (
        <>
            <div className={`bg-white  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
                <Link to='/Dortoire/garcon/comparti/Affich' className="ml-2 text-[18px] text-blue-500 hover:text-blue-800 underline">Retour</Link>
                <div className="sm:p-6 p-2 min-h-[90vh]">
                    <div class="w-full  ">
                        <div class="bg-white  relative overflow-hidden rounded-lg p-2 sm:p-4 ">

                            <div class="flex flex-col p-2 rounded-xl bg-gray-50 mt-2 sm:mt-8 h-max ">
                                <div className="border-white  pb-4 border-b-2">
                                    <h3 class="sm:text-xl w-full text-center font-bold text-gray-900 mb-2">
                                        Liste des compartiments garçon
                                    </h3>
                                </div>
                                <div class=" h-max flex flex-wrap max-sm:justify-center">
                                    {compartimentsPretDisplin.map((data, keys) => (
                                        <div key={keys} class="bg-white p-2 sm:p-8 m-2 rounded-lg  h-full w-full sm:w-max">
                                            <div class="sm:mb-4 mb-2 flex items-center">
                                                <div class="text-[20px] max-sm:text-sm font-semibold  block"> {mobile2 ? 'Nom' : 'Nom du compartiment'}:</div>
                                                <div class="text-[18px] max-sm:text-sm font-semibold text-green-500 ml-2 ">{data.nom_compartiment}</div>
                                            </div>
                                            {data.element.map((datas, index) => (
                                                <div key={index}>
                                                    <div class="sm:mb-4 mb-2 flex items-center">
                                                        <div class="text-[20px] max-sm:text-sm font-semibold  block">Nombre total des lits:</div>
                                                        <div>
                                                            <span class="text-[18px] max-sm:text-sm font-semibold text-green-500 ml-2 ">{datas.nombre_total_lit}</span>
                                                        </div>
                                                    </div><div class="mb-4 flex items-center">
                                                        <div class="text-[20px] max-sm:text-sm font-semibold  block">Nombre des élèves par lits :</div>
                                                        <div>
                                                            <span class="text-[18px] max-sm:text-sm font-semibold text-green-500 ml-2 ">{datas.nombre_Eleve_par_lit}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                            <div className="sm:mt-10 mt-2 flex justify-end">
                                                <Link to='/Dortoire/garcon/comparti/detail' className="bg-blue-200 max-sm:text-sm text-blue-700 mx-1 py-1 px-2 rounded">Detail</Link>
                                            </div>
                                        </div>
                                    ))}

                                </div>
                            </div>



                        </div>
                    </div>
                </div>
                <FootterCopy />
            </div>

        </>
    );
}

export default GarconCompartementDortoirePrefetDisplin;





