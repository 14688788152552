import React from "react";
import SideBarSecretaire from "../../SideBarSecretaire/SideBarSecretaire";
import ListeExpedier from "./ListeExpedier";
function ListeExpedierComponent() {
  return (
    <>
      {" "}
      <div class="flex overflow-hidden bg-white justify-between">
        <SideBarSecretaire />
        <ListeExpedier />
      </div>
    </>
  );
}

export default ListeExpedierComponent;
