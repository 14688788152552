import React, { useState } from "react";
import CourriersComponent from "../../component/Secretariat/Courriers/CourriersComponent";
function CourriersPage({ getSizeMobille, getMenuMobille }) {
  return (
    <>
      <CourriersComponent getMenuMobille={getMenuMobille} getSizeMobille={getSizeMobille} />
    </>
  );
}

export default CourriersPage;
