import React from "react";
import DepensesInfirmeierComponent from "../../component/Infirmerie/Depenses/DepensesInfirmeierComponent";
function DepensesInfirmeierPage({ getSizeMobille, getMenuMobille }) {
  return (
    <>
      <DepensesInfirmeierComponent getMenuMobille={getMenuMobille} getSizeMobille={getSizeMobille} />
    </>
  );
}

export default DepensesInfirmeierPage;
