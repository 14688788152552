import React from 'react'
import DetailDepensePrefetDisplin from './DetailDepensePrefetDisplin'
import SideBarsPrefetDisplin from '../SideBarsPrefetDisplin/SideBarsPrefetDisplin'
function DetailDepensePrefetDisplinComponent({ getSizeMobille, getMenuMobille }) {
    return (
        <div className='flex justify-between overflow-hidden bg-white'>
            <SideBarsPrefetDisplin getMenuMobille={getMenuMobille} />
            <DetailDepensePrefetDisplin getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default DetailDepensePrefetDisplinComponent
