import React from 'react'
import SideBarBibliote from '../SideBar/SideBarBibliote'
import RetourLivresEleves from './RetourLivresEleves'
function RetourLivresElevesComponent({SetResumeQui, LivreReturn, setLivreReturn, SetResume, SetEmpruntId, getSizeMobille, getMenuMobille }) {
    return (
        <div className='flex justify-between bg-white overflow-hidden'>
            <SideBarBibliote getMenuMobille={getMenuMobille} />
            <RetourLivresEleves SetResumeQui={SetResumeQui} LivreReturn={LivreReturn} setLivreReturn={setLivreReturn} SetEmpruntId={SetEmpruntId} SetResume={SetResume} getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default RetourLivresElevesComponent
