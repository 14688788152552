import React from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import FootterCopy from "../../FootterCopy/FootterCopy";

function DetteBibliothequeDetail({ getSizeMobille }) {
    const handleConfirmation = async () => {
        try {
            const result = await swal({
                title: "Confirmation",
                text: "Vous Voulez Supprimer ?",
                icon: "warning",
                buttons: ["No", "Oui"],
                dangerMode: true,
                className: "centered-buttons",
            });

            if (result) {

                console.log("Confirmed");
            } else {

            }
        } catch (error) {

            console.log("Error or dismissal:", error);
        }
    };
    return (
        <>
            <div className={`bg-white  overflow-y-auto  overflow-x-hidden h-[85vh] ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
                <Link to='/DetteBibli/ChoixClasseDetteBibli/ListDetteBiblio' className="ml-2 text-[18px] text-blue-500 hover:text-blue-800 underline">Retour</Link>
                <div className="p-6 ">
                    <div className="text-[25px] text-gray-700 font-bold">Les dossiers</div>

                    <div class="w-full sm:my-4 grid ">
                        <div class="bg-white  p-2 sm:p-6 xl:p-8">
                            <div class=" rounded-lg bg-white border flex items-center w-full sm:w-max p-4 justify-center flex-col ">

                                <div class="flex flex-col justify-start w-full sm:p-6">
                                    <p class="mb-4  text-xl text-neutral-600 ">
                                        Numéro : 12
                                    </p>
                                    <p class="mb-4  text-xl text-neutral-600 ">
                                        Nom & Prenom : Jean Marie Vianney
                                    </p>

                                    <p class="mb-4  text-xl text-neutral-600 ">
                                        Nombre des livres: 3 livre
                                    </p>
                                    <div className="w-full tracking-widest flex-wrap font-semibold text-gray-500 flex justify-around">
                                        <div className="m-3">
                                            <div>Titre :Jerusalem</div>
                                            <div>Numéro :T6790</div>
                                        </div>
                                        <div className="m-3">
                                            <div>Titre :Ahera</div>
                                            <div>Numéro :T6790</div>
                                        </div>
                                        <div className="m-3">
                                            <div>Titre :Muhira</div>
                                            <div>Numéro :T6790</div>
                                        </div>
                                    </div>
                                    <div class=" mt-8 flex items-center justify-end ">
                                        <div class="flex flex-row flex-shrink-0 space-x-3">

                                            <button
                                                onClick={handleConfirmation}
                                                class="mt-4 sm:mt-0 inline-flex items-start justify-start px-6 py-3 bg-red-700 hover:bg-red-600 focus:outline-none rounded-lg"
                                            >
                                                <p class="text-lg font-medium leading-none text-white">
                                                    Supprimer
                                                </p>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FootterCopy />
            </div>
        </>
    );
}

export default DetteBibliothequeDetail;
