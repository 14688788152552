import React from 'react'
import DetailCongePrefetDisplin from './DetailCongePrefetDisplin'
import SideBarsPrefetDisplin from '../SideBarsPrefetDisplin/SideBarsPrefetDisplin'
function DetailCongePrefetDisplinComponent() {
    return (
        <div className='flex justify-between overflow-hidden bg-white'>
            <SideBarsPrefetDisplin />
            <DetailCongePrefetDisplin />
        </div>
    )
}

export default DetailCongePrefetDisplinComponent
