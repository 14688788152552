import React from 'react'
import ChoixRapportPrefetDisplin from './ChoixRapportPrefetDisplin'
import SideBarsPrefetDisplin from '../SideBarsPrefetDisplin/SideBarsPrefetDisplin'
function ChoixRapportPrefetDisplinCompoenent({ getSizeMobille, getMenuMobille }) {
  return (
    <div className='flex justify-between overflow-hidden bg-white'>
      <SideBarsPrefetDisplin getMenuMobille={getMenuMobille} />
      <ChoixRapportPrefetDisplin getSizeMobille={getSizeMobille} />
    </div>
  )
}

export default ChoixRapportPrefetDisplinCompoenent
