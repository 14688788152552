import React from 'react'
import EnregistreNotes from './EnregistreNotes'
import SildeEnseign from '../SildeEnseign/SildeEnseign'
function EnregistreNotesComponent() {
  return (<>

    <div class="overflow-hidden flex justify-between">
      <SildeEnseign />
      <EnregistreNotes />
    </div>


  </>)
}

export default EnregistreNotesComponent
