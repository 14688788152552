import React, { useState } from "react";
import SideBar from "../SideBar/SideBarBibliote";
import EmprunterVisitor from "./EmprunterVisitor";
function EmprunterVisitorComponent({ getSizeMobille, getMenuMobille }) {
  return (
    <>
      
      <div class="overflow-hidden flex justify-between">
        <SideBar getMenuMobille={getMenuMobille} />
        <EmprunterVisitor getSizeMobille={getSizeMobille} />
      </div>
    </>
  );
}

export default EmprunterVisitorComponent;
