import React from "react";
import SideBarSecretaire from "../SideBarSecretaire/SideBarSecretaire";
import DossierEleveSecretaire from "./DossierEleveSecretaire";
function DossierEleveComponent({ getSizeMobille, getMenuMobille }) {
  return (
    <>
      {" "}
      <div class="overflow-hidden flex justify-between">
        <SideBarSecretaire getMenuMobille={getMenuMobille} />
        <DossierEleveSecretaire getSizeMobille={getSizeMobille} />
      </div>
    </>
  );
}

export default DossierEleveComponent;
