import React from 'react'
import ModiMaterielPrefetDisplin from './ModiMaterielPrefetDisplin'
import SideBarsPrefetDisplin from '../SideBarsPrefetDisplin/SideBarsPrefetDisplin'
function ModiMaterielPrefetDisplincomponent({ getSizeMobille, getMenuMobille }) {
    return (
        <div className='flex justify-between overflow-hidden bg-white'>
            <SideBarsPrefetDisplin getMenuMobille={getMenuMobille} />
            <ModiMaterielPrefetDisplin getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default ModiMaterielPrefetDisplincomponent
