import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FootterCopy from "../../FootterCopy/FootterCopy";
import { API } from "../../../Api/API";
import { encryptData } from "../../../encryptionModule";
function AjouteReponseListPersonne({ getSizeMobille }) {

    const [mobile11, SetMobile11] = useState(window.innerWidth < 501)

    const [mobile1, Setmonile1] = useState(window.innerWidth < 499)
    const [mobile2, Setmonile2] = useState(window.innerWidth < 752)
    const [mobile3, Setmonile3] = useState(window.innerWidth < 457)
    const [mobile4, Setmonile4] = useState(window.innerWidth < 318)
    useEffect(() => {
        const hundlesSize = () => {
            SetMobile11(window.innerWidth < 501)
            Setmonile1(window.innerWidth < 499)
            Setmonile2(window.innerWidth < 752)
            Setmonile3(window.innerWidth < 752)
            Setmonile4(window.innerWidth < 318)
        }

        window.addEventListener('resize', hundlesSize)

        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])



    useEffect(() => {
        const hundlesSize = () => {
            Setmonile1(window.innerWidth < 349)
            SetMobile11(window.innerWidth < 501)
        }

        window.addEventListener('resize', hundlesSize)

        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])

    const [searchTerm, setSearchTerm] = useState("");
    const [Staff, setStaff] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [totalElements, setTotalElements] = useState(0)




    const [rechercher, setRechercher] = useState(false)
    const [isrechercher, setisRechercher] = useState(0)
    function handleSearch() {
        setRechercher(true)
        setisRechercher(isrechercher + 1)
    }



    useEffect(() => {
        fetchStaff(currentPage, pageSize, searchTerm, rechercher);
    }, [currentPage, pageSize, isrechercher]);

    const fetchStaff = async (page, size, searchTerm, rechercher) => {
        try {
            let url = `/staff/allrefauxlexp?page=${page}&pageSize=${size}`;
            if (rechercher) {
                url = `/staff/allrefauxlexp/search?page=${page}&pageSize=${size}&searchTerm=${searchTerm}`;
            }
            const response = await API.get(url);
            setStaff(response.data.staff);
            setTotalPages(response.data.totalPages);
            setTotalElements(response.data.totalElements);

        } catch (error) {
            console.error('Erreur lors de la récupération des staff :', error.message);
        }
    };



    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePageSizeChange = (size) => {
        setPageSize(size);
        setCurrentPage(1);
    };



    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };


    const getRange = () => {
        const from = (currentPage - 1) * pageSize + 1;
        const to = Math.min(currentPage * pageSize, totalElements);
        return `${from} à ${to} Courriers sur ${totalElements}`;
    };
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        if (searchTerm.toString().trim().length === 1) {
            setRechercher(false);
            setisRechercher(isrechercher + 1);
        }
    };


    return (
        <>
            <div className={`bg-white  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
                <Link to='/DemandeReponse' className="ml-2 text-[18px] text-blue-500 hover:text-blue-800 underline">Retour</Link>
                <div className="p-4 flex justify-center ">
                    <div class="w-full flex flex-row items-center justify-between">
                        <p class="text-navy-700 shrink  ">
                            <p
                                class="hover:text-navy-700 text-gray-700 uppercase  sm:text-3xl font-bold"

                            >
                                Listes  des Personnels
                            </p>
                        </p>

                    </div>
                </div>

                <div className="flex w-full px-3    items-center justify-between flex-wrap">

                    <div className={`${mobile2 ? 'mb-5' : ''}`}>
                        <div class="">
                            <div class="">
                                <div class="">
                                    <div className=" flex items-center">
                                        <p className="sm:text-xl">Taille de la page :</p>
                                        <select
                                            value={pageSize}
                                            onChange={(e) => handlePageSizeChange(Number(e.target.value))}
                                            className="ml-2 p-2.5 cursor-pointer bg-white border border-gray-300 rounded"
                                        >
                                            <option value={1}>1</option>
                                            <option value={5}>5</option>
                                            <option value={10}>10</option>
                                            <option value={20}>20</option>
                                            <option value={50}>50</option>
                                            <option value={100}>100</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class={`sm:flex items-center ${mobile2 ? 'w-full ' : ''}  justify-end`}>

                        <div class="sm:flex items-center justify-end">
                            <div class="mx-2 z-10">
                                <div class="relative lg:w-64">
                                    <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                        <svg
                                            class="w-5 h-5 text-gray-500"
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fill-rule="evenodd"
                                                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                                clip-rule="evenodd"
                                            ></path>
                                        </svg>
                                    </div>
                                    <input
                                        type="text"
                                        name="search"
                                        value={searchTerm}
                                        onChange={handleSearchChange}
                                        id="topbar-search"
                                        class="bg-white border border-gray-300 text-gray-900 sm:text-lg rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full pl-10 p-2"
                                        placeholder="Rechercher Par votre choix..."
                                    />
                                </div>
                            </div>
                            <button
                                className="mt-4 sm:mt-0 inline-flex items-start justify-start px-6 py-3 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg"
                                onClick={handleSearch}
                            >
                                <p className="text-lg font-medium leading-none text-white">
                                    Rechercher
                                </p>
                            </button>
                        </div>

                    </div>


                </div>

                <div class="w-full my-4 grid grid-cols-1 ">
                    <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">


                        <div class="flex flex-col mt-8">
                            <div class="overflow-x-auto rounded-lg">
                                <div class="align-middle inline-block min-w-full">
                                    <div class="shadow overflow-hidden sm:rounded-lg">
                                        <table class="min-w-full divide-y divide-gray-200">
                                            <thead class="bg-gray-50">
                                                <tr>
                                                    <th
                                                        scope="col"
                                                        class="p-4 text-left text-lg font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        Nom & Prenom
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        class="p-4 text-left text-lg font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        Matricule
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        class="p-4 text-left text-lg font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        Role
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        class="p-4 text-left text-lg font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        Tel
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        class="p-4 text-left text-lg font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        Grade
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        class="p-4 text-left text-lg font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        Action
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody class="bg-white">
                                                {Staff.length > 0 ? Staff.map((l) => {
                                                    return (
                                                        <tr>
                                                            <td class="border-b border-gray-200 p-4 whitespace-nowrap text-lg font-normal text-gray-500">
                                                                {l.nomcomplet}
                                                            </td>

                                                            <td class="border-b border-gray-200 p-4 whitespace-nowrap text-lg font-semibold text-gray-900">
                                                                {l.matricule}
                                                            </td>
                                                            <td class="border-b border-gray-200 p-4 whitespace-nowrap text-lg font-semibold text-gray-900">
                                                                {l.role}
                                                            </td>
                                                            <td class="border-b border-gray-200 p-4 whitespace-nowrap text-lg font-normal text-gray-500">
                                                                {l.tel}
                                                            </td>
                                                            <td class="border-b border-gray-200 p-4 whitespace-nowrap text-lg font-normal text-gray-500">
                                                                {l.Grade}
                                                            </td>



                                                            <td class="border-b border-gray-200 p-4 whitespace-nowrap text-lg font-normal text-gray-500">
                                                                <Link
                                                                    to={`/DemandeReponse/AjouterListPerson/AjoutExpli/${encryptData((l.id).toString())}`}
                                                                    class="mt-4 sm:mt-0 inline-flex items-start justify-start px-6 py-3 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg"
                                                                >
                                                                    <p class="text-lg font-medium leading-none text-white">
                                                                        Ajouter
                                                                    </p>
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    )
                                                }) :
                                                    (
                                                        <tr>
                                                            <td colSpan={7} className="w-full">
                                                                <div className="bg-transparent sm:text-[25px] w-full h-[50vh] flex justify-center items-center">
                                                                    Désolé, aucune correspondance  pour   <span className='font-semibold ml-4 mr-4'>{rechercher && searchTerm ? searchTerm : "les personels actifs"}</span>{" "} trouvée
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                }

                                            </tbody>
                                        </table>
                                        {Staff.length > 0 && (
                                            <>
                                                {/* Pagination */}
                                                {/* Pagination controls */}
                                                <div className="mt-4 flex items-center justify-center">
                                                    <div className="flex items-center">
                                                        <button
                                                            disabled={currentPage === 1}
                                                            onClick={handlePreviousPage}
                                                            className="mx-1 px-3 py-1 rounded bg-gray-300 text-gray-700"
                                                        >
                                                            Précédent
                                                        </button>
                                                        {Array.from({ length: totalPages }, (_, index) => index + 1).map((pageNumber) => (
                                                            <button
                                                                key={pageNumber}
                                                                onClick={() => handlePageChange(pageNumber)}
                                                                className={`mx-1 px-3 py-1 rounded ${currentPage === pageNumber ? 'bg-gray-700 text-white' : 'bg-gray-300 text-gray-700'}`}
                                                            >
                                                                {pageNumber}
                                                            </button>
                                                        ))}
                                                        <button
                                                            disabled={currentPage === totalPages}
                                                            onClick={handleNextPage}
                                                            className="mx-1 px-3 py-1 rounded bg-gray-300 text-gray-700"
                                                        >
                                                            Suivant
                                                        </button>
                                                    </div>
                                                </div>

                                                <div className="mt-4 flex items-center justify-center">
                                                    <p className="text-gray-600">{getRange()}</p>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FootterCopy />
            </div>
        </>
    );
}

export default AjouteReponseListPersonne;
