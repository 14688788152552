import React, { useState } from "react";
import EmprunterComponent from "../../component/Bibliotheque/Emprunter/EmprunterComponent";
function EmprunterPage({ getSizeMobille, getMenuMobille }) {
  return (
    <>
      <EmprunterComponent getMenuMobille={getMenuMobille} getSizeMobille={getSizeMobille} />
    </>
  );
}

export default EmprunterPage;
