import React from 'react'
import CongeAnnuelDetailComponent from '../../component/Secretariat/CongeAnnuel/CongeAnnuelSecretDetailComponent'



function CongeAnnuelDetailPage({ getSizeMobille, getMenuMobille }) {
    return <CongeAnnuelDetailComponent getSizeMobille={getSizeMobille} getMenuMobille={getMenuMobille} />
}

export default CongeAnnuelDetailPage
