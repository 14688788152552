import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import FootterCopy from '../../FootterCopy/FootterCopy'
function ChoixClasseDetteBiblio({ getSizeMobille }) {
    const [mobile1, Setmonile1] = useState(window.innerWidth < 326)

    useEffect(() => {
        const hundlesSize = () => {
            Setmonile1(window.innerWidth < 326)

        }

        window.addEventListener('resize', hundlesSize)

        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])
    return (
        <div className={`bg-white  overflow-x-auto  h-[85vh] ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
            <div className="p-2 min-h-[90vh]">
                <div className="sm:text-3xl text-xl font-bold text-gray-500 mb-4">Listes des classes</div>
                <div class="sm:flex items-center justify-between">
                    <div class="flex flex-row items-center space-x-5">
                        <div class="mx-2">
                            <div class="relative lg:w-64">
                                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <svg
                                        class="w-5 h-5 text-gray-500"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                            clip-rule="evenodd"
                                        ></path>
                                    </svg>
                                </div>
                                <input
                                    type="text"
                                    name="email"
                                    id="topbar-search"
                                    class="bg-white border border-gray-300 text-gray-900 sm:text-lg rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full pl-10 p-2.5"
                                    placeholder="Rechercher..."
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="my-4 w-full flex flex-wrap  gap-4">
                    <Link to='/DetteBibli/ChoixClasseDetteBibli/ListDetteBiblio' class="bg-white shadow rounded-lg hover:scale-105 transition-all cursor-pointer m-2 p-8 ">
                        <div class="flex items-center">
                            <div class="">
                                <span class="text-2xl sm:text-3xl leading-none font-bold text-blue-900">
                                    Langue
                                </span>
                                <h3 class="text-xl font-normal w-full text-center text-gray-500">
                                    Nombre total  des élèves
                                </h3>
                            </div>
                            <div class="w-9 h-9    mb-4 text-blue-900 font-bold transition duration-75"
                            >
                                <div class="w-9 h-9    mb-4 text-green-700 font-bold transition duration-75"
                                >
                                    54
                                </div>
                            </div>

                        </div>

                        <div className={`flex justify-between ${mobile1 ? 'flex-col ' : ''} mt-10`}>
                            <div class="flex items-center">
                                <div class="">
                                    <span class="text-2xl sm:text-2xl leading-none font-bold text-blue-900">
                                        40
                                    </span>
                                    <h3 class="text-xl font-normal text-gray-500">
                                        Féminin
                                    </h3>
                                </div>
                                <div class="w-9 h-9    mb-4 text-green-700 font-bold transition duration-75"
                                >
                                    87,1%
                                </div>

                            </div>
                            <div class="flex items-center mt-2">
                                <div class="">
                                    <span class="text-2xl sm:text-2xl leading-none font-bold text-blue-900">
                                        14
                                    </span>
                                    <h3 class="text-xl font-normal text-gray-500">
                                        Garcon
                                    </h3>
                                </div>
                                <div class="w-9 h-9    mb-4 text-blue-900 font-bold transition duration-75"
                                >
                                    <div class="w-9 h-9    mb-4 text-green-700 font-bold transition duration-75"
                                    >
                                        12,9%
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className='mt-5 font-serif text-gray-600'>Année scolaire :<span className='text-blue-900 tracking-widest'>2023-204</span> </div>
                    </Link>

                    <Link to='/DetteBibli/ChoixClasseDetteBibli/ListDetteBiblio' class="bg-white shadow rounded-lg hover:scale-105 transition-all cursor-pointer m-2 p-8 ">
                        <div class="flex items-center">
                            <div class="">
                                <span class="text-2xl sm:text-3xl leading-none font-bold text-blue-900">
                                    Langue
                                </span>
                                <h3 class="text-xl font-normal w-full text-center text-gray-500">
                                    Nombre total  des élèves
                                </h3>
                            </div>
                            <div class="w-9 h-9    mb-4 text-blue-900 font-bold transition duration-75"
                            >
                                <div class="w-9 h-9    mb-4 text-green-700 font-bold transition duration-75"
                                >
                                    54
                                </div>
                            </div>

                        </div>

                        <div className={`flex justify-between ${mobile1 ? 'flex-col ' : ''} mt-10`}>
                            <div class="flex items-center">
                                <div class="">
                                    <span class="text-2xl sm:text-2xl leading-none font-bold text-blue-900">
                                        40
                                    </span>
                                    <h3 class="text-xl font-normal text-gray-500">
                                        Féminin
                                    </h3>
                                </div>
                                <div class="w-9 h-9    mb-4 text-green-700 font-bold transition duration-75"
                                >
                                    87,1%
                                </div>

                            </div>
                            <div class="flex items-center mt-2">
                                <div class="">
                                    <span class="text-2xl sm:text-2xl leading-none font-bold text-blue-900">
                                        14
                                    </span>
                                    <h3 class="text-xl font-normal text-gray-500">
                                        Garcon
                                    </h3>
                                </div>
                                <div class="w-9 h-9    mb-4 text-blue-900 font-bold transition duration-75"
                                >
                                    <div class="w-9 h-9    mb-4 text-green-700 font-bold transition duration-75"
                                    >
                                        12,9%
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className='mt-5 font-serif text-gray-600'>Année scolaire :<span className='text-blue-900 tracking-widest'>2023-204</span> </div>
                    </Link> <Link to='/DetteBibli/ChoixClasseDetteBibli/ListDetteBiblio' class="bg-white shadow rounded-lg hover:scale-105 transition-all cursor-pointer m-2 p-8 ">
                        <div class="flex items-center">
                            <div class="">
                                <span class="text-2xl sm:text-3xl leading-none font-bold text-blue-900">
                                    Langue
                                </span>
                                <h3 class="text-xl font-normal w-full text-center text-gray-500">
                                    Nombre total  des élèves
                                </h3>
                            </div>
                            <div class="w-9 h-9    mb-4 text-blue-900 font-bold transition duration-75"
                            >
                                <div class="w-9 h-9    mb-4 text-green-700 font-bold transition duration-75"
                                >
                                    54
                                </div>
                            </div>

                        </div>

                        <div className={`flex justify-between ${mobile1 ? 'flex-col ' : ''} mt-10`}>
                            <div class="flex items-center">
                                <div class="">
                                    <span class="text-2xl sm:text-2xl leading-none font-bold text-blue-900">
                                        40
                                    </span>
                                    <h3 class="text-xl font-normal text-gray-500">
                                        Féminin
                                    </h3>
                                </div>
                                <div class="w-9 h-9    mb-4 text-green-700 font-bold transition duration-75"
                                >
                                    87,1%
                                </div>

                            </div>
                            <div class="flex items-center mt-2">
                                <div class="">
                                    <span class="text-2xl sm:text-2xl leading-none font-bold text-blue-900">
                                        14
                                    </span>
                                    <h3 class="text-xl font-normal text-gray-500">
                                        Garcon
                                    </h3>
                                </div>
                                <div class="w-9 h-9    mb-4 text-blue-900 font-bold transition duration-75"
                                >
                                    <div class="w-9 h-9    mb-4 text-green-700 font-bold transition duration-75"
                                    >
                                        12,9%
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className='mt-5 font-serif text-gray-600'>Année scolaire :<span className='text-blue-900 tracking-widest'>2023-204</span> </div>
                    </Link>
                    <Link to='/DetteBibli/ChoixClasseDetteBibli/ListDetteBiblio' class="bg-white shadow rounded-lg hover:scale-105 transition-all cursor-pointer m-2 p-8 ">
                        <div class="flex items-center">
                            <div class="">
                                <span class="text-2xl sm:text-3xl leading-none font-bold text-blue-900">
                                    Langue
                                </span>
                                <h3 class="text-xl font-normal w-full text-center text-gray-500">
                                    Nombre total  des élèves
                                </h3>
                            </div>
                            <div class="w-9 h-9    mb-4 text-blue-900 font-bold transition duration-75"
                            >
                                <div class="w-9 h-9    mb-4 text-green-700 font-bold transition duration-75"
                                >
                                    54
                                </div>
                            </div>

                        </div>

                        <div className={`flex justify-between ${mobile1 ? 'flex-col ' : ''} mt-10`}>
                            <div class="flex items-center">
                                <div class="">
                                    <span class="text-2xl sm:text-2xl leading-none font-bold text-blue-900">
                                        40
                                    </span>
                                    <h3 class="text-xl font-normal text-gray-500">
                                        Féminin
                                    </h3>
                                </div>
                                <div class="w-9 h-9    mb-4 text-green-700 font-bold transition duration-75"
                                >
                                    87,1%
                                </div>

                            </div>
                            <div class="flex items-center mt-2">
                                <div class="">
                                    <span class="text-2xl sm:text-2xl leading-none font-bold text-blue-900">
                                        14
                                    </span>
                                    <h3 class="text-xl font-normal text-gray-500">
                                        Garcon
                                    </h3>
                                </div>
                                <div class="w-9 h-9    mb-4 text-blue-900 font-bold transition duration-75"
                                >
                                    <div class="w-9 h-9    mb-4 text-green-700 font-bold transition duration-75"
                                    >
                                        12,9%
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className='mt-5 font-serif text-gray-600'>Année scolaire :<span className='text-blue-900 tracking-widest'>2023-204</span> </div>
                    </Link>



                </div>
            </div>
            <FootterCopy />
        </div>




    )
}

export default ChoixClasseDetteBiblio
