import React from 'react'
import EncadrementHoraire from './EncadrementHoraire'
import SlideEncadrementNavBars from '../SlideEncadrementNavBars/SlideEncadrementNavBars'
function EncadrementHoraireComponent({ getSizeMobille, getMenuMobille }) {
    return (
        <div className='flex justify-between overflow-hidden bg-white'>
            <SlideEncadrementNavBars getMenuMobille={getMenuMobille} />
            <EncadrementHoraire getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default EncadrementHoraireComponent
