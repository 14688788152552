/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import FootterCopy from "../../FootterCopy/FootterCopy";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select"
import { FadeLoader } from 'react-spinners'
import { decryptData } from "../../../encryptionModule";
import { API } from "../../../Api/API";
function ModiMaterielPrefetDisplin({ getSizeMobille }) {
    const { id } = useParams()
    const matid = decryptData(id)
    const [mobile11, SetMobile11] = useState(window.innerWidth < 501)
    const navigate = useNavigate()
    useEffect(() => {
        const hundlesSize = () => {
            SetMobile11(window.innerWidth < 501)
        }
        window.addEventListener('resize', hundlesSize)
        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])

    const [mobile, SetMobile] = useState(window.innerWidth <= 640)
    const [boutLoading, setboutLoading] = useState(false)

    useEffect(() => {
        const hundleSize = () => {
            SetMobile(window.innerWidth <= 640)
        }
        window.addEventListener('resize', hundleSize)

        return () => {
            window.removeEventListener('resize', hundleSize)
        }
    }, [])

    const OptionMateriel = [
        { value: '', label: '' },
        { value: 'Lit', label: 'Lit' },
        { value: 'Matelas', label: 'Matelas' },
        { value: 'Tables', label: 'Tables' },
        { value: 'Bas pupitre', label: 'Bas pupitre' },
        { value: 'Balai', label: 'Balai' },
        { value: 'Lacrette', label: 'Lacrette' },
        { value: 'Houes', label: 'Houes' },
        { value: 'Coupe-coupe', label: 'Coupe-coupe' },
        { value: 'Télévision', label: 'Télévision' },
        { value: 'Radio', label: 'Radio' },
        { value: 'Bureau', label: 'Bureau' },
        { value: 'Chaise', label: 'Chaise' },
        { value: 'Armoire', label: 'Armoire' },
        { value: 'Cadenas', label: 'Cadenas' },
        { value: 'Étagère', label: 'Étagère' },
        { value: 'Moustiquaire', label: 'Moustiquaire' },
        { value: 'Ventilateur', label: 'Ventilateur' },
        { value: 'Lampe', label: 'Lampe' },
        { value: 'Poubelle', label: 'Poubelle' },
        { value: 'Brosse à dents', label: 'Brosse à dents' },
        { value: 'Papier hygiénique', label: 'Papier hygiénique' },
        { value: 'Serviette', label: 'Serviette' },
        { value: 'Savon', label: 'Savon' },
        { value: 'Seau', label: 'Seau' },
        { value: 'Miroir', label: 'Miroir' },
        { value: "Bouteille d'eau", label: "Bouteille d'eau" },
        { value: 'Sacoche', label: 'Sacoche' },
        { value: 'Oreiller', label: 'Oreiller' },
        { value: 'Couverture', label: 'Couverture' },
        { value: 'Cadenas', label: 'Cadenas' },
        { value: 'Casseroles', label: 'Casseroles' },
        { value: 'Poêles', label: 'Poêles' },
        { value: 'Assiettes', label: 'Assiettes' },
        { value: 'Couverts', label: 'Couverts' },
        { value: 'Verres', label: 'Verres' },
        { value: 'Bouilloire', label: 'Bouilloire' },
        { value: 'Réfrigérateur', label: 'Réfrigérateur' },
        { value: 'Machine à laver', label: 'Machine à laver' },
        { value: 'Ordinateur', label: 'Ordinateur' },
        { value: 'Imprimante', label: 'Imprimante' },
    ]
    const OptionTypeMateriel = [
        { value: '', label: '' },
        { value: 'Mobilier ', label: 'Mobilier ' },
        { value: 'Équipements de cuisine', label: 'Équipements de cuisine' },
        { value: 'Outils de nettoyage', label: 'Outils de nettoyage' },
        { value: 'Équipements électroniques', label: 'Équipements électroniques' },
        { value: 'Équipements de jardinage', label: 'Équipements de jardinage' },
        { value: 'Articles de literie', label: 'Articles de literie' },
        { value: 'Équipements de loisirs', label: 'Équipements de loisirs' },
        { value: 'Équipements de sécurité', label: 'Équipements de sécurité' },
        { value: 'Articles de décoration', label: 'Articles de décoration' },
    ]
    const hiddenSOptionMateriel = OptionMateriel.slice(1);
    const hiddenOptionTypeMateriel = OptionTypeMateriel.slice(1);

    const customStyles = {
        control: (provided) => ({
            ...provided,
            height: mobile ? 25 : 50,
            backgroundColor: "white",
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? "blue" : state.isFocused ? "lightblue" : "white",
            color: state.isSelected ? "white" : "black",
        }),
    };


    const [type, setType] = useState({ value: "", label: "Type" })
    const [nom, setNom] = useState({ value: "", label: "Nom" })
    const [nombredispo, setnombredispo] = useState('')
    const [nombrefonctionnel, setnombrefonctionnel] = useState('')
    const nomRef = useRef(null);
    const typeRef = useRef(null);
    const nombredispoRef = useRef(null);
    const nombrefonctionnelRef = useRef(null);

    useEffect(() => {
        API.get(`/materielinternant/findOneById/${matid}`).then((resp) => {
            const materiel = resp.data;
            if (materiel.type) {
                const correspondingtype = OptionTypeMateriel.find(
                    (acces) => acces.value == materiel.type
                );
                if (correspondingtype) {
                    setType(correspondingtype);
                }
            }
            if (materiel.nom) {
                const correspondingnom = OptionMateriel.find(
                    (acces) => acces.value == materiel.nom
                );
                if (correspondingnom) {
                    setNom(correspondingnom);
                }
            }
            setnombredispo(materiel.nombredispo)
            setnombrefonctionnel(materiel.nombrefonctionnel)
        }).catch((err) => {
            console.log(err.message)
        })
    }, [id])

    const handleNomChange = (selectedOption) => {
        setNom(selectedOption);
    };

    const handleTypeChange = (selectedOption) => {
        setType(selectedOption);
    };

    const handleNombredispoChange = (e) => {
        setnombredispo(e.target.value);
    };

    const handleNombrefonctionnelChange = (e) => {
        setnombrefonctionnel(e.target.value);
    };



    const highlightInvalidFields = () => {
        if (!nom.value) {
            toast.warn("Le nom du matériel est requis");
            nomRef.current.style.backgroundColor = 'lightcoral';
        }
        if (!type.value) {
            toast.warn("Le type du matériel est requis");
            typeRef.current.style.backgroundColor = 'lightcoral';

        }
        if (!nombredispo || isNaN(nombredispo)) {
            toast.warn("Le nombre disponible doit être un entier");
            nombredispoRef.current.style.backgroundColor = 'lightcoral';
        }

        if (!nombrefonctionnel || isNaN(nombrefonctionnel)) {
            toast.warn("Le nombre fonctionnel doit être un entier");
            nombrefonctionnelRef.current.style.backgroundColor = 'lightcoral';
        }

        setTimeout(() => {
            nombredispoRef.current.style.backgroundColor = '';
            nombrefonctionnelRef.current.style.backgroundColor = '';
            typeRef.current.style.backgroundColor = '';
            nomRef.current.style.backgroundColor = '';
        }, 5000);
    };
    const handleSubmit = (e) => {
        e.preventDefault();

        if (!nombrefonctionnel || isNaN(nombrefonctionnel) || !nombredispo || isNaN(nombredispo) || !nom.value || !type.value) {
            highlightInvalidFields();
            return;
        }

        setboutLoading(true);
        const data = {
            nom: nom.value,
            type: type.value,
            nombredispo: parseInt(nombredispo),
            nombrefonctionnel: parseInt(nombrefonctionnel)
        };
        API.put(`/materielinternant/updateOne/${matid}`, data)
            .then((response) => {
                toast.success("Modifications enregistrées avec succès !");
                setboutLoading(false);
                navigate(`/Materiel/detail/${id}`)
            })
            .catch((error) => {
                console.error("Erreur lors de la mise à jour :", error);
                toast.error("Erreur lors de la mise à jour. Veuillez réessayer !");
                setboutLoading(false);
            });
    };
  

    return (

        <div className={`bg-white  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
            <Link to={`/Materiel/detail/${id}`} className="ml-2 text-[18px] text-blue-500 hover:text-blue-800 underline">Retour</Link>
            <div className="sm:p-4 p-2 min-h-screen">
                <div class="my-5">
                    <div class="w-full flex flex-row items-center justify-between">
                        <p class="text-navy-700   ">
                            <a
                                class="hover:text-navy-700 text-2xl font-bold  "
                            >
                                Modification des matériels
                            </a>
                        </p>

                    </div>
                </div>
                <div className="w-full my-4 grid grid-cols-1">
                    <div className="w-full my-4 grid grid-cols-1">
                        <form onSubmit={handleSubmit} className="flex flex-col items-center">
                            <div className="rounded-lg p-2 border border-blue-500 md:w-[40em] w-[95%] sm:p-4">

                                <div className="justify_center rounded-xl w-full  p-1 sm:p-2 mb-10  border-[1px] flex flex-col">
                                    <div className="w-full">
                                        <div className="h-max w-full">
                                            <div className="px-3  w-full">
                                                <div className="w-full flex items_center justify-between">
                                                    <label className="block  text-xs mt-5 font-serif text-gray-700 first_letter:uppercase tracking_wide text-grey-darker sm:text-lg  mb-1">
                                                        Matériels
                                                    </label>                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mb-2">
                                        <div className="rounded-xl p-2 w-full">
                                            <div className="flex flex-col">
                                                <div className="rounded-lg w-full">
                                                    <div class="w-full mb-5">
                                                        <label class="block  tracking-wide text-gray-700 text-xs sm:text-lg  mb-1">
                                                            Nom du matériels
                                                        </label>
                                                        <div ref={nomRef} className={` rounded `}>
                                                            <Select

                                                                value={nom}
                                                                options={hiddenSOptionMateriel}
                                                                styles={customStyles}
                                                                onChange={handleNomChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="rounded-lg w-full">
                                                    <div class="w-full mb-5">
                                                        <label class="block  tracking-wide text-gray-700 text-xs sm:text-lg  mb-1">
                                                            Type du matériels
                                                        </label>
                                                        <div ref={typeRef} className={` rounded `}>
                                                            <Select

                                                                value={type}
                                                                options={hiddenOptionTypeMateriel}
                                                                styles={customStyles}
                                                                onChange={handleTypeChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="rounded-lg w-full">
                                                    <div class="w-full mb-5">
                                                        <label
                                                            class="block  tracking-wide text-grey-darker text-lg  mb-2"
                                                            for="grid-Title"
                                                        >
                                                            nombredispo disponible
                                                        </label>
                                                        <input
                                                            ref={nombredispoRef}
                                                            class="appearance-none block w-full bg-grey-lighter text-grey-darker border border-red rounded py-3 px-4 mb-3"
                                                            value={nombredispo}
                                                            onChange={handleNombredispoChange}
                                                            placeholder="nombredispo disponible..."
                                                        />
                                                    </div>
                                                </div>
                                                <div className="rounded-lg w-full">
                                                    <div class="w-full mb-5">
                                                        <label
                                                            class="block  tracking-wide text-grey-darker text-lg  mb-2"
                                                            for="grid-Title"
                                                        >
                                                            nombredispo fonctionnel
                                                        </label>
                                                        <input
                                                            ref={nombrefonctionnelRef}
                                                            class="appearance-none block w-full bg-grey-lighter text-grey-darker border border-red rounded py-3 px-4 mb-3"
                                                            value={nombrefonctionnel}
                                                            onChange={handleNombrefonctionnelChange}
                                                            placeholder="nombredispo disponible..."
                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className=" flex justify-end  md:w-[40em] w-[95%]">

                                {boutLoading ? (
                                    <>
                                        <label disabled className="cursor-no-drop w-max relative  mt-3 flex justify-center  items-center   bg-green-950    p-2 rounded  text-gray-400">
                                            <input type="submit" id="send" value='Modifier' className='pointer-events-none' />
                                            <i class="bi bi-send ml-2  pointer-events-none "></i>
                                            <div disabled className='absolute pointer-events-none  bg-transparent pt-4  pl-4  w-full h-full flex justify-center items-center z-50'>
                                                <FadeLoader
                                                    color="rgb(255, 255, 255)"
                                                    height={10}
                                                    margin={-9}
                                                    radius={100}
                                                    speedMultiplier={1}
                                                    width={1}
                                                />
                                            </div>
                                        </label>
                                    </>
                                ) : (<>
                                    <label for="send" className=" mt-3 cursor-pointer w-max  flex justify-end  bg-green-600   p-2 rounded  text-white">
                                        <input type="submit" id="send" value='Modifier' className='cursor-pointer'></input>
                                        <i class="bi bi-send ml-2 "></i>
                                    </label>
                                </>)}

                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <FootterCopy />
        </div>

    );
}


export default ModiMaterielPrefetDisplin;










