import React from 'react'
import AcceuilPrefetDisplin from './AcceuilPrefetDisplin'
import SideBarsPrefetDisplin from '../SideBarsPrefetDisplin/SideBarsPrefetDisplin'
function AcceuilPrefetDisplinComponent({ getSizeMobille, getMenuMobille }) {
    return (
        <div class="overflow-hidden bg-white flex justify-between">
            <SideBarsPrefetDisplin getMenuMobille={getMenuMobille} />
            <AcceuilPrefetDisplin getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default AcceuilPrefetDisplinComponent
