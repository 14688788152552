import React from 'react'
import AddFauteConsultSuiveurEncadrement from './AddFauteConsultSuiveurEncadrement'
import SlideEncadrementNavBars from '../SlideEncadrementNavBars/SlideEncadrementNavBars'
function AddFauteConsultSuiveurEncadrementComponent({ getSizeMobille, getMenuMobille }) {
    return (
        <div className='flex justify-between overflow-hidden bg-white'>
            <SlideEncadrementNavBars getMenuMobille={getMenuMobille} />
            <AddFauteConsultSuiveurEncadrement getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default AddFauteConsultSuiveurEncadrementComponent
