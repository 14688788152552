import React from 'react'
import CongeBibliotheDetail from './CongeBibliotheDetail'
import SideBarBibliote from '../SideBar/SideBarBibliote'
function CongeBibliotheDetailComponent() {
    return (
        <div className=' flex justify-between bg-white overflow-hidden'>
            <SideBarBibliote />
            <CongeBibliotheDetail />

        </div>
    )
}

export default CongeBibliotheDetailComponent
