import React, { useState } from "react";
import SlideNavInfermie from "../SlideNavInfermie/SlideNavInfermie";
import Consultation from "./Consultation";
function ConsultationComponent({ getSizeMobille, getMenuMobille }) {
  return (
    <>

      <div class="overflow-hidden flex justify-between">
        <SlideNavInfermie getMenuMobille={getMenuMobille} />
        <Consultation getSizeMobille={getSizeMobille} />
      </div>
    </>
  );
}

export default ConsultationComponent;
