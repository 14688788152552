import React from 'react'
import SlideParentNavBars from '../SlideParentNavBars/SlideParentNavBars'
import NoteEnf from './NoteEnf'
function NoteEnfComponent({ getMenuMobille, getSizeMobille }) {
    return (
        <div class="overflow-hidden flex justify-between">
            <SlideParentNavBars getMenuMobille={getMenuMobille} />
            <NoteEnf getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default NoteEnfComponent
