import React from 'react'
import InformaMaterielPrefetDisplin from './InformaMaterielPrefetDisplin'
import SideBarsPrefetDisplin from '../SideBarsPrefetDisplin/SideBarsPrefetDisplin'
function InformaMaterielPrefetDisplinComponent({ getSizeMobille, getMenuMobille }) {
    return (
        <div className='flex justify-between overflow-hidden bg-white'>
            <SideBarsPrefetDisplin getMenuMobille={getMenuMobille} />
            <InformaMaterielPrefetDisplin getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default InformaMaterielPrefetDisplinComponent
