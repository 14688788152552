import React from 'react'
import SideBarSecretaire from '../../SideBarSecretaire/SideBarSecretaire'
import ModifierRecus from './ModifierRecus'
function ModifierRecusComponent({ getSizeMobille, getMenuMobille }) {
  return (
    <div className='flex justify-between overflow-hidden bg-white'>
      <SideBarSecretaire getMenuMobille={getMenuMobille} />
      <ModifierRecus getSizeMobille={getSizeMobille} />
    </div>
  )
}

export default ModifierRecusComponent

