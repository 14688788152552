import React from "react";
import RetourLivresComponent from "../../component/Bibliotheque/RetourLivres/RetourLivresComponent";
function RetourLivresPage({ SetResumeQui, SetResume, getSizeMobille, getMenuMobille }) {
  return (
    <>
      <RetourLivresComponent SetResumeQui={SetResumeQui} SetResume={SetResume} getMenuMobille={getMenuMobille} getSizeMobille={getSizeMobille} />
    </>
  );
}

export default RetourLivresPage;
