import React, { useState } from "react";
import MedicamentComponent from "../../component/Infirmerie/Medicament/MedicamentComponent";
function MedicamentPage({ getSizeMobille, getMenuMobille }) {
  return (
    <>
      <MedicamentComponent getMenuMobille={getMenuMobille} getSizeMobille={getSizeMobille} />
    </>
  );
}

export default MedicamentPage;
