import React from 'react'
import ChoixClasseDetteBiblio from './ChoixClasseDetteBiblio'
import SideBarSecretaire from '../SideBarSecretaire/SideBarSecretaire'
function ChoixClasseDetteBiblioComponent({ getSizeMobille, getMenuMobille }) {
    return (
        <div className='flex justify-between overflow-hidden  '>
            <SideBarSecretaire getMenuMobille={getMenuMobille} />
            <ChoixClasseDetteBiblio getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default ChoixClasseDetteBiblioComponent
