import React from 'react'
import SideBarBibliote from '../../Bibliotheque/SideBar/SideBarBibliote'
import ResumesBibliotheque from './ResumesBibliotheque'
function ResumesBibliothequeComponent({ getSizeMobille, getMenuMobille }) {
    return (
        <div className='flex justify-between overflow-hidden bg-white'>
            <SideBarBibliote getMenuMobille={getMenuMobille} />
            <ResumesBibliotheque getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default ResumesBibliothequeComponent
