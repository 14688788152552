import React from "react";
import SlideNavInfermie from "../SlideNavInfermie/SlideNavInfermie";
import AjouterMedicament from "./AjouterMedicament";
function AjouterMedicamentComponent({ getSizeMobille, getMenuMobille }) {
  return (
    <>

      <div class="overflow-hidden flex justify-between">
        <SlideNavInfermie getMenuMobille={getMenuMobille} />
        <AjouterMedicament getSizeMobille={getSizeMobille} />
      </div>
    </>
  );
}

export default AjouterMedicamentComponent;
