/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable eqeqeq */
import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FootterCopy from "../../FootterCopy/FootterCopy";

function MaterielEncadrement({ getSizeMobille }) {
    const [mobile1, Setmonile1] = useState(window.innerWidth < 990)
    const [mobile2, Setmonile2] = useState(window.innerWidth < 520)
    useEffect(() => {
        const hundlesSize = () => {
            Setmonile1(window.innerWidth < 990)
            Setmonile2(window.innerWidth < 520)
        }
        window.addEventListener('resize', hundlesSize)

        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])

    const [mobile11, SetMobile11] = useState(window.innerWidth < 501)

    useEffect(() => {
        const hundlesSize = () => {
            SetMobile11(window.innerWidth < 501)
        }
        window.addEventListener('resize', hundlesSize)
        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])

    return (
        <>
            <div className={`bg-white  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
                <div className="p-6 min-h-[90vh]">

                    <div class={`b-4 flex items-center justify-between ${mobile2 ? 'flex-col' : ''}`}>
                        <div>
                            <h3 class="sm:text-xl font-bold text-gray-900 mb-2">
                                Votre choix
                            </h3>
                        </div>
                        <Link to='/Materiel/Ajouter' class=" font-bold text-gray-900  bg-blue-200 p-2 rounded-full cursor-pointer">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle-fill w-6 text-blue-700 h-6" viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z" />
                            </svg>
                        </Link>

                    </div>
                    <div class="my-4 w-full items-center flex flex-col gap-4">
                        <Link
                            to="/Materiel/EmpruntEleves"
                            class="bg-white border border-fuchsia-300 w-[17em] rounded-lg p-4 flex justify-center items-center cursor-pointer hover:bg-gray-100s hover:shadow-lg transition duration-300 ease-in-out hover:scale-105 transform"
                        >
                            <div class="flex items-center">
                                <div class="flex-shrink-0">
                                    <h3 class="text-xl font-normal text-gray-700">
                                        Emprunte aux élèves
                                    </h3>
                                </div>
                            </div>
                        </Link>
                        <Link
                            to="/Materiel/EmpruntPersonne"
                            class="bg-white border border-fuchsia-300 w-[17em] rounded-lg p-4 flex justify-center items-center cursor-pointer hover:bg-gray-100s hover:shadow-lg transition duration-300 ease-in-out hover:scale-105 transform"
                        >
                            <div class="flex items-center">
                                <div class="flex-shrink-0">
                                    <h3 class="text-xl font-normal text-gray-700">
                                        Emprunte aux personnes
                                    </h3>
                                </div>
                            </div>
                        </Link>
                        <Link to='/Materiel/RetourEleves'
                            class="bg-white border w-[17em] border-yellow-500 rounded-lg p-4 flex justify-center items-center cursor-pointer hover:bg-gray-100s hover:shadow-lg transition duration-300 ease-in-out hover:scale-105 transform"
                        >
                            <div class="flex items-center">
                                <div class="flex-shrink-0">
                                    <h3 class="text-xl font-normal text-gray-700">
                                        Retourne Eleve
                                    </h3>
                                </div>
                            </div>
                        </Link>
                        <Link to='/Materiel/RetourPerso'
                            class="bg-white border w-[17em] border-yellow-500 rounded-lg p-4 flex justify-center items-center cursor-pointer hover:bg-gray-100s hover:shadow-lg transition duration-300 ease-in-out hover:scale-105 transform"
                        >
                            <div class="flex items-center">
                                <div class="flex-shrink-0">
                                    <h3 class="text-xl font-normal text-gray-700">
                                        Retourne personnel
                                    </h3>
                                </div>
                            </div>
                        </Link>
                    </div>


                </div>
                <FootterCopy />
            </div>

        </>
    );
}

export default MaterielEncadrement;



