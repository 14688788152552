import React, { useState } from "react";
import ModifierExpedierComponent from "../../component/Secretariat/Courriers/Expedier/ModifierExpedierComponent";
function ModifierExpedierPage() {
  return (
    <>
      <ModifierExpedierComponent />
    </>
  );
}

export default ModifierExpedierPage;
