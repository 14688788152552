import React from 'react'
import CongePrefetDisplin from './CongePrefetDisplin'
import SideBarsPrefetDisplin from '../SideBarsPrefetDisplin/SideBarsPrefetDisplin'
function CongePrefetDisplinComponent({ getSizeMobille, getMenuMobille }) {
    return (
        <div className='flex justify-between overflow-hidden bg-white'>
            <SideBarsPrefetDisplin getMenuMobille={getMenuMobille} />
            <CongePrefetDisplin getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default CongePrefetDisplinComponent
