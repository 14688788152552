import React from 'react'
import RapportEncadreursAddRapport from './RapportEncadreursAddRapport'
import SlideEncadrementNavBars from '../SlideEncadrementNavBars/SlideEncadrementNavBars'
function RapportEncadreursAddRapportComponent({ getSizeMobille, getMenuMobille }) {
    return (
        <div className='flex justify-between overflow-hidden bg-white'>
            <SlideEncadrementNavBars getMenuMobille={getMenuMobille} />
            <RapportEncadreursAddRapport getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default RapportEncadreursAddRapportComponent
