import React, { useState } from "react";
import SideBarSecretaire from "../SideBarSecretaire/SideBarSecretaire";
import ModiferEleve from "./ModiferEleve";
function ModiferEleveComponent({ getMenuMobille, getSizeMobille }) {
  return (
    <>
      {" "}
      <div class="flex overflow-hidden justify-between">
        <SideBarSecretaire getMenuMobille={getMenuMobille} />
        <ModiferEleve getSizeMobille={getSizeMobille} />
      </div>
    </>
  );
}

export default ModiferEleveComponent;
