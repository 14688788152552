import React from 'react'
import DetailDepenseEncadreur from './DetailDepenseEncadreur'
import SlideEncadrementNavBars from '../SlideEncadrementNavBars/SlideEncadrementNavBars'
function DetailDepenseEncadreurComponent() {
    return (
        <div className='flex justify-between overflow-hidden bg-white'>
            <SlideEncadrementNavBars />
            <DetailDepenseEncadreur />
        </div>
    )
}

export default DetailDepenseEncadreurComponent
