/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { FadeLoader } from 'react-spinners'
import { Link, useLocation } from 'react-router-dom';
function SeconnectMArcheUser() {

    const [boutLoading, setboutLoading] = useState(false);

    const history = useNavigate();
    localStorage.removeItem("userRole");
    const handleClose = () => {
        toast.dismiss();
    };

    const { pathname } = useLocation()
    const [click, setclick] = useState(false)
    const [clickCommande, setclickCommande] = useState(false)
    const [clickSlideHidden, setclickSlideHidden] = useState(false)


    const emailRegex = /^[^ ]+@[^ ]+\.[a-z]{2,}$/i;
    const [emailValue, setEmailValue] = useState('')
    const [animationClassEmail, setAnimationClassEmail] = useState('');
    const elemetRefEmail = useRef(null)

    const PasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).*$/i;
    const [passwordValue, setPasswordValue] = useState('')
    const [animationClassPassword, setAnimationClassPassword] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false);
    const elemetRefPass = useRef(null)




    useEffect(() => {
        const windowClicked = () => {
            setclick(false);
            setclickSlideHidden(false)
        };

        window.addEventListener("click", windowClicked);

        return () => {
            window.removeEventListener("click", windowClicked);
        };
    }, []);




    const handleSubmit = (e) => {
        e.preventDefault()

        if (emailValue.trim() == '') {
            toast.warning("L'address email est obligatoire !!", {
                autoClose: 2000
            });
            setAnimationClassEmail('animate__animated animate__shakeX placeholder-shown:border-blue-500 text-blue-500 border-blue-700')
            setTimeout(() => {
                setAnimationClassEmail(' ')
            }, 3000)
            elemetRefEmail.current.focus();
            return
        } else if (!emailValue.trim().match(emailRegex)) {
            toast.error("L'address email est incorrect", {
                autoClose: 2000
            });
            setAnimationClassEmail('animate__animated animate__shakeX placeholder-shown:border-red-500 text-red-500 border-red-500')
            setTimeout(() => {
                setAnimationClassEmail(' ')
            }, 3000)
            elemetRefEmail.current.focus();

            return
        } else if (passwordValue.trim() == "") {
            toast.warning("Le mot de passe est obligatoire !!", {
                autoClose: 2000
            });
            setAnimationClassPassword('animate__animated animate__shakeX placeholder-shown:border-blue-700 text-blue-500 border-blue-700')
            setTimeout(() => {
                setAnimationClassPassword(' ')
            }, 3000)
            elemetRefPass.current.focus()

            return
        } else if (!passwordValue.trim().match(PasswordRegex)) {

            toast.error(`Le mot de passe doit contenir au moins
                         une lettre minuscule,lettre majuscule,un chiffre et un caractère spécial
                       `, {
                autoClose: 5000,
                position: "top-center",
                className: 'my-toast',

            });
            setAnimationClassPassword('animate__animated animate__shakeX placeholder-shown:border-red-500 text-red-500 border-red-500')
            setTimeout(() => {
                setAnimationClassPassword(' ')
            }, 5000)
            elemetRefPass.current.focus()
            return
        }
    }
    const [imageLoading, SetImageLoading] = useState(true)

    return (
        <>

            <div className={`fixed w-full h-full z-[90] top-0 left-0 flex justify-center overflow-hidden items-center ${'bg-[#808080a8]'} `}>
                <div className='w-full  h-full overflow-auto flex justify-center items-center p-4'>
                    <div className={` ${'bg-white'}  p-2  border shadow-2xl border-blue-600 w-[25em] rounded-3xl h-max`}>
                        {/* <div className="flex justify-end px-1 items-center">
                            <div onClick={(e) => { SetLoginPopUp(false); e.stopPropagation() }} className="bg-blue-500 rounded-full cursor-pointer w-[30px] h-[30px] flex justify-center items-center text-[22px] font-serif text-white"><i class="bi bi-x-circle-fill"></i></div>
                        </div> */}
                        <div className="w-full flex justify-center items-center">
                            <div className="h-[10em]  w-[10em] transition-all  relative overflow-hidden ml-3 rounded ">
                                {imageLoading && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                                <img draggable='false' onLoad={() => {
                                    setTimeout(() => {
                                        SetImageLoading(false)
                                    }, 1000)
                                }} src={`images/logo.png`} alt="" className='h-full w-full  object-cover object-center' />
                            </div>
                        </div>
                        <h2 className=' p-2 text-center  font-serif text-gray-400 '>Se connecter</h2>
                        <p className='text-[15px] text-gray-500 pl-3 text-center'>Si vous êtes déjà membre, connectez-vous facilement maintenant.</p>
                        <div className="p-2 my-2 rounded w-full  h-max">
                            <form onSubmit={handleSubmit}>
                                <div className="flex mb-6 flex-col ">
                                    <div className=" mt-[10px] transition-all  flex">
                                        <div class="relative h-11 w-full">
                                            <input value={emailValue} ref={elemetRefEmail}
                                                onInput={(e) => setEmailValue(e.target.value)} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassEmail}`} placeholder=" "
                                            />
                                            <label class="text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                                Email valide
                                            </label>
                                        </div>
                                    </div>

                                    <div className=" transition-all mt-5 flex  justify-between flexpass ">
                                        <div className={`flex items-center  w-full  relative gapps`}>
                                            <div class="relative h-11 w-full bloksInput mr-2 ">
                                                <input ref={elemetRefPass} onInput={(e) => { setPasswordValue(e.target.value) }} type={passwordVisible ? 'text' : 'password'} className={`peer   pr-[20px] h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500  ${animationClassPassword}`} placeholder=" " />
                                                <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                                    Mot de passe
                                                </label>
                                            </div>
                                            <div onClick={() => { setPasswordVisible(!passwordVisible) }} className=" cursor-pointer w-[20px] h-[20px] absolute rounded-md bg-black flex justify-center top-[20px] items-center right-2 ">
                                                <i class={`bi ${passwordVisible ? (
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="bi bi-eye text-white" viewBox="0 0 16 16">
                                                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                                                    </svg>) : (<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="bi bi-eye-slash text-white" viewBox="0 0 16 16">
                                                        <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                                                        <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                                                        <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" />
                                                    </svg>)
                                                    } absolute`}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex justify-end items-center relative">
                                    {boutLoading ? (
                                        <>
                                            <label className="  cursor-pointer w-max relative  flex justify-center  items-center   bg-blue-800 pointer-events-none   p-2 rounded  text-white">
                                                <input type="submit" id="send" value='Se connecter' className='cursor-pointer' />
                                                <i class="bi bi-send ml-2 "></i>
                                                <div className='absolute bg-transparent pt-4   w-full h-full flex justify-center items-center z-50'>
                                                    <FadeLoader
                                                        color="rgb(255, 255, 255)"
                                                        height={10}
                                                        margin={-9}
                                                        radius={100}
                                                        speedMultiplier={1}
                                                        width={1}
                                                    /></div>
                                            </label>
                                        </>
                                    ) : (<>
                                        <label for="send" className=" cursor-pointer w-max  flex justify-end  bg-blue-700   p-2 rounded  text-white">
                                            <input type="submit" id="send" value='Se connecter' className='cursor-pointer'></input>
                                            <i class="bi bi-send ml-2 "></i>
                                        </label>
                                    </>)}

                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </div>

        </>
    )
}

export default SeconnectMArcheUser;














