import React from 'react'
import SlideParentNavBars from '../SlideParentNavBars/SlideParentNavBars'
import ListeCoursParent from './ListeCoursParent'
function ListeCoursParentComponent({ getSizeMobille, getMenuMobille }) {
    return (
        <div class="overflow-hidden flex justify-between">
            <SlideParentNavBars getMenuMobille={getMenuMobille} />
            <ListeCoursParent getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default ListeCoursParentComponent
