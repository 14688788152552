import React, { useState } from "react";
import DemandeReponseComponent from "../../component/Secretariat/DemandeReponse/DemandeReponseComponent";
function DemandeReponsePage({ getSizeMobille, getMenuMobille }) {
  return (
    <>
      <DemandeReponseComponent getMenuMobille={getMenuMobille} getSizeMobille={getSizeMobille} />
    </>
  );
}

export default DemandeReponsePage;
