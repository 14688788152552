import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import FootterCopy from '../../FootterCopy/FootterCopy'

function RapportInfirmier({ getSizeMobille }) {

  const [mobile1, Setmonile1] = useState(window.innerWidth < 349)

  const [mobile11, SetMobile11] = useState(window.innerWidth < 501)

  useEffect(() => {
    const hundlesSize = () => {
      Setmonile1(window.innerWidth < 349)
      SetMobile11(window.innerWidth < 501)
    }

    window.addEventListener('resize', hundlesSize)

    return () => {
      window.removeEventListener('resize', hundlesSize)
    }
  }, [])


  return (

    <div className={`bg-white  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>

      <div className="sm:p-4 p-2 min-h-screen">
        <div className='text-2xl font-bold uppercase'>Rapport</div>
        <div class=" w-full  text-3xl  rounded-lg ">

          A recherche
        </div>
      </div>
      <FootterCopy />
    </div>
  )
}

export default RapportInfirmier
