import React, { useState } from "react";
import SideBar from "../SideBar/SideBarBibliote";
import EmprunterEleveListe from "./EmprunterEleveListe";
function EmprunterEleveListeComponent({ getSizeMobille, getMenuMobille }) {
  return (
    <>
      {" "}
      <div class="overflow-hidden flex justify-between">
        <SideBar getMenuMobille={getMenuMobille} />
        <EmprunterEleveListe getSizeMobille={getSizeMobille} />
      </div>
    </>
  );
}

export default EmprunterEleveListeComponent;
