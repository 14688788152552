import React from 'react'
import SideBarSecretaire from '../SideBarSecretaire/SideBarSecretaire'
import AjouteExpliChaquePerson from './AjouteExpliChaquePerson'
function AjouteExpliChaquePersonComponent({ getMenuMobille, getSizeMobille }) {
    return (
        <div className='flex justify-between overflow-hidden bg-white'>
            <SideBarSecretaire getMenuMobille={getMenuMobille} />
            <AjouteExpliChaquePerson getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default AjouteExpliChaquePersonComponent
