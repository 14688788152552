import React from "react";
import DossierEleveComponent from "../../component/Secretariat/DossierEleve/DossierEleveSecretaireComponent";
function DDossierEleveSecretairePage({ getSizeMobille, getMenuMobille }) {
  return (
    <>
      <DossierEleveComponent getMenuMobille={getMenuMobille} getSizeMobille={getSizeMobille} />
    </>
  );
}

export default DDossierEleveSecretairePage;
