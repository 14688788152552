import React from 'react'
import SideBarSecretaire from '../SideBarSecretaire/SideBarSecretaire';
import AjouteReponseListPersonne from './AjouteReponseListPersonne'


export default function AjouteReponseListPersonneComponent({ getSizeMobille, getMenuMobille }) {
    return (
        <>
            {" "}
            <div class="flex overflow-hidden bg-white justify-between">
                <SideBarSecretaire getMenuMobille={getMenuMobille} />
                <AjouteReponseListPersonne getSizeMobille={getSizeMobille} />
            </div>
        </>
    );
}


