/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import FootterCopy from '../../FootterCopy/FootterCopy'
function NoteEnf({ getSizeMobille }) {
    const [mobile1, Setmonile1] = useState(window.innerWidth < 621)

    const [mobile11, SetMobile11] = useState(window.innerWidth < 501)

    useEffect(() => {
        const hundlesSize = () => {
            Setmonile1(window.innerWidth < 621)
            SetMobile11(window.innerWidth < 501)
        }

        window.addEventListener('resize', hundlesSize)

        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])

    return (
        <>
            <div className={`bg-white  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>

                <div className="p-4 min-h-screen">
                    <div class="my-10 flex w-full items-center justify-center">
                        <h3 class="text-xl w-full text-center sm:text-2xl leading-none font-bold text-gray-500">
                            Quel  trimestre que vous-voulez choisir ?
                        </h3>
                    </div>

                    <div class="my-4 w-full items-center flex flex-col gap-4">
                        <Link
                            to="/NoteEnt/ListeCours"
                            class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 cursor-pointer hover:bg-gray-100s hover:shadow-lg transition duration-300 ease-in-out hover:scale-105 transform"
                        >
                            <div class="flex items-center">
                                <div class="flex-shrink-0">
                                    <h3 class="text-xl font-normal text-gray-700">I trimestre</h3>
                                </div>
                            </div>
                        </Link>
                        <Link
                            to="/EmprunterEnseignant"
                            class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 cursor-pointer hover:bg-gray-100s hover:shadow-lg transition duration-300 ease-in-out hover:scale-105 transform"
                        >
                            <div class="flex items-center">
                                <div class="flex-shrink-0">
                                    <h3 class="text-xl font-normal text-gray-700">
                                        II trimestre
                                    </h3>
                                </div>
                            </div>
                        </Link>
                        <Link
                            to="/EmprunterPersonnel"
                            class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 cursor-pointer hover:bg-gray-100s hover:shadow-lg transition duration-300 ease-in-out hover:scale-105 transform"
                        >
                            <div class="flex items-center">
                                <div class="flex-shrink-0">
                                    <h3 class="text-xl font-normal text-gray-700">
                                        III trimestre
                                    </h3>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
                <FootterCopy />
            </div>
        </>
    )
}

export default NoteEnf
