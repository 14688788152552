import React from 'react'
import ModifierNotesEleve from './ModifierNotesEleve'
import SildeEnseign from '../SildeEnseign/SildeEnseign'
function ModifierNotesEleveComponent() {
    return (
        <div className='bg-white flex pt-16 overflow-hidden'>
            <SildeEnseign />
            <ModifierNotesEleve />
        </div>
    )
}

export default ModifierNotesEleveComponent
