import React from 'react'
import Compte from './Compte'
import SlideNavBarsCantin from '../SlideNavBarsCantin/SlideNavBarsCantin'
function CompteComponent() {
    return (
        <div className='bg-white pt-10 flex overflow-hidden'>
            <SlideNavBarsCantin />
            <Compte />
        </div>
    )
}

export default CompteComponent
