import React, { useState } from "react";
import AjouterRecusComponent from "../../component/Secretariat/Courriers/Recus/AjouterRecusComponent";
function AjouterRecusPage({ getMenuMobille, getSizeMobille }) {
  return (
    <>
      <AjouterRecusComponent getSizeMobille={getSizeMobille} getMenuMobille={getMenuMobille} />
    </>
  );
}

export default AjouterRecusPage;
