import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import FootterCopy from "../../FootterCopy/FootterCopy";
import { API, STATIC_URL } from "../../../Api/API";
import { decryptData } from "../../../encryptionModule";
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
function DetailCongeEncadreur({ getSizeMobille }) {
    const [mobile1, Setmonile1] = useState(window.innerWidth < 349)
    const [mobile11, SetMobile11] = useState(window.innerWidth < 501)
    const { id } = useParams()
    const congeId = decryptData(id)
    useEffect(() => {
        const hundlesSize = () => {
            Setmonile1(window.innerWidth < 349)
            SetMobile11(window.innerWidth < 501)
        }

        window.addEventListener('resize', hundlesSize)

        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])
    const [conge, setConge] = useState({})
    useEffect(() => {
        API.get(`/staff/conges/${congeId}`).then((response) => {
            setConge(response.data)
        }).catch((err) => {
            console.log(err.message)
        })
    }, [id])


    function formaterDate(dateString) {
        const months = [
            "janvier", "février", "mars", "avril", "mai", "juin",
            "juillet", "août", "septembre", "octobre", "novembre", "décembre"
        ];

        const date = new Date(dateString);
        const jour = date.getDate();
        const mois = months[date.getMonth()];
        const annee = date.getFullYear();

        return `Le ${jour}, ${mois} ${annee}`;
    }




    return (
        <>
            <>
                <div className={`bg-white  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
                    <Link to='/Conge' className="ml-2 text-[18px] text-blue-500 hover:text-blue-800 underline">Retour</Link>
                    <div className="sm:p-6 ">
                        <div className="text-[25px] text-gray-700 font-bold">Les conges</div>

                        <div class="w-full my-2 grid grid-cols-1 ">
                            <div class="bg-white  rounded-lg p-2 sm:p-6 xl:p-8">
                                <div class="w-full  ">
                                    <div class="  shadow rounded-lg p-2 sm:p-6 xl:p-8">
                                        <div class="  rounded-lg    flex flex-col items-center justify-center  ">
                                            {conge && conge.staff && conge.staff.Profile && (
                                                <div className="max-w-[15em] max-h-[15em] rounded-xl overflow-hidden">
                                                    <img
                                                        src={`${STATIC_URL}/personnel/${conge.staff.Profile}`}
                                                        alt=" "
                                                        className="w-full h-full object-cover"
                                                    />
                                                </div>
                                            )}
                                            <div class="flex flex-col justify-start p-3">
                                                <p class="mb-4  text-xl text-neutral-600 ">
                                                    Nom & Prenom : {conge && conge.staff && conge.staff.nomcomplet}
                                                </p>
                                                <p class="mb-4  text-xl text-neutral-600 ">
                                                    Matricule : {conge && conge.staff && conge.staff.matricule}
                                                </p>
                                                <p class="mb-4  text-xl text-neutral-600 ">
                                                    Date Debut :{conge && formaterDate(conge.debutconge)}
                                                </p>
                                                <p class="mb-4  text-xl text-neutral-600 ">
                                                    Date Fin :{conge && formaterDate(conge.finconge)}
                                                </p>
                                                <p class="mb-4  text-xl text-neutral-600 ">
                                                    Type de Conge :{conge && conge.type}
                                                </p>
                                                <p class="mb-4  text-xl text-neutral-600 ">
                                                    Status du Conge :{conge && conge.Status}
                                                </p>
                                                <p class="text-lg text-neutral-600 ">
                                                    Date: {conge && formaterDate(conge.createdAt)}
                                                </p>
                                            </div>
                                            <div>
                                                <p className="underline font-bold text-xl">Description du congé:</p>
                                                <p className="text-gray-500 text-[1rem] ">
                                                    {conge && conge.Description}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <FootterCopy />
                </div>
            </>
        </>
    );
}

export default DetailCongeEncadreur;
