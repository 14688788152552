/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import FootterCopy from "../../FootterCopy/FootterCopy";
import { API, STATIC_URL } from "../../../Api/API";
import { decryptData, encryptData } from "../../../encryptionModule";
import { format } from 'date-fns';
import { fr, tr } from 'date-fns/locale';
import { toast } from "react-toastify";
import swal from 'sweetalert';
import "./sweetalert.css";
function ListeSuiveurDetailPrefetDisplin({ getSizeMobille }) {
    const { id, classId, schoolyearId } = useParams()
    const history = useNavigate()
    const [indexModifier, setIndexModifier] = useState(null)
    const [modifier, Getmodifier] = useState(false)
    const Getmodifiers = (value, index) => {
        setIndexModifier(index);
        Getmodifier(value)
    };

    const [mobile1, Setmonile1] = useState(window.innerWidth < 430)
    const [mobile11, SetMobile11] = useState(window.innerWidth < 501)

    useEffect(() => {

        const hundlesSize = () => {
            Setmonile1(window.innerWidth < 430)
            SetMobile11(window.innerWidth < 501)
        }
        window.addEventListener('resize', hundlesSize)

        return () => {
            window.removeEventListener('resize', hundlesSize)

        }
    }, [])


    const studentid = decryptData(id)
    const [oneStudent, setOneState] = useState({})
    const [dataFautes, setDataFautes] = useState([])
    const [reload, setreload] = useState(false)
    useEffect(() => {
        API.get(`/student/suiveurDetails/${studentid}`).then((res) => {
            setDataFautes(res.data.faults)
            setOneState(res.data)
        }).catch((err) => {
            console.log(err.message)
        })
    }, [studentid, reload])
    const formatDate = date => {
        return format(new Date(date), "'Le 'd, MMMM yyyy", { locale: fr });
    };

    let notesRetrenche = 0; // Initialisez notesRetrenche à 0
    dataFautes.forEach((e) => {
        notesRetrenche += e.PointRetranche; // Ajoutez e.PointRetranche à la somme
        const getRest = 60 - notesRetrenche; // Calcul du reste après soustraction
        e.reste = getRest; // Affectez la valeur du reste à la propriété "reste" de l'élément
    });
    const [PhotoClick, GetPhotoClick] = useState('')
    const [zoom, GetZoom] = useState(false)
    const [newData, setNewData] = useState('');
    const [idretr, setIdretr] = useState('');

    // Fonction pour envoyer les données modifiées à la base de données
    const sendDataToDatabase = () => {
        if (isNaN(newData) || !Number.isInteger(Number(newData))) {
            toast.warn("Les points doivent être un entier.");
            return;
        } else if (Number(newData) % 3 !== 0) {
            toast.warn("Les points doivent être divisibles par 3.");
            return;
        } else {
            const data = {
                point: newData,
                suiveurId: idretr

            }
            API.put("/student/direntModifierNoteRetranche", data).then((res) => {
                toast.success(res.data)
                // history(`/Suiveur/consult/${schoolyearId}/${classId}`)
                Getmodifier(false)
                setreload(!reload)
            }).catch((error) => {
                if (error.response) {
                    // Le serveur a répondu avec un code d'erreur (status code)
                    toast.warn(error.response.data[0])
                    // Afficher les erreurs au sein de votre composant ou effectuer une action en conséquence
                } else if (error.request) {
                    // La requête a été faite mais aucune réponse n'a été reçue
                    console.error('Pas de réponse du serveur :', error.request);
                } else {
                    // Une erreur s'est produite lors de la configuration de la requête
                    console.error('Erreur lors de la requête :', error.message);
                }
            })
        }




    };


    const handleDelete = async (noteretrId) => {
        try {
            const result = await swal({
                title: "Confirmation",
                text: "Voulez-vous supprimer ?",
                icon: "warning",
                buttons: ["Non", "Oui"],
                dangerMode: true,
                className: "centered-buttons",
            });

            if (result) {
                const res = await API.delete(`/student/direntdeleteNoteRetranche/${noteretrId}`);
                setreload(!reload)
                toast.success("Vous avez supprimé avec succès!");
            } else {
                console.log("Annulé");
            }
        } catch (error) {
            console.log("Erreur ou annulation :", error.message);
        }
    };



    return (
        <>

            <div className={`bg-white  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
                <Link to={`/Suiveur/consult/${schoolyearId}/${classId}`} className="ml-2 text-[18px] text-blue-500 hover:text-blue-800 underline">Retour</Link>
                <div className="sm:p-6 p-2 ">
                    <div className="sm:text-[25px] text-gray-700 font-bold">Detail</div>

                    <div class="w-full sm:my-2 grid grid-cols-1">
                        <div class=" w-full  ">
                            <div class="w-full my-2  ">
                                <div class=" rounded-lg p-2 sm:p-6 xl:p-8">
                                    <div class=" border border-fuchsia-300 rounded-lg  p-1 sm:p-3 flex flex-col items-start justify-center  ">
                                        <div className={` border border-gray-100 rounded-2xl ${mobile1 ? 'w-12 h-12' : 'w-28 h-28'} `}>
                                            <img src={`${STATIC_URL}/students/${oneStudent.Profile}`} className="w-full h-full object-contain" />
                                        </div>
                                        <div class="flex flex-col justify-start">
                                            <p class={`mb-4   text-neutral-600 ${mobile1 ? 'text-[12px]' : 'text-xl'} `}>
                                                Nom complet : {oneStudent.Nom_complet}
                                            </p>

                                            <p class={`mb-4   text-neutral-600 ${mobile1 ? 'text-[12px]' : 'text-xl'} `}>
                                                Matricule : {oneStudent.matricule}
                                            </p>
                                            <p class={`mb-4   text-neutral-600 ${mobile1 ? 'text-[12px]' : 'text-xl'} `}>
                                                Année-scolaire: {oneStudent.schoolyear}
                                            </p>
                                            <p class={`mb-4   text-neutral-600 ${mobile1 ? 'text-[12px]' : 'text-xl'} `}>
                                                Trimmestre: {oneStudent.Trimestre}
                                            </p>
                                        </div>
                                        <div className="w-full bg-gray-50 p-2 sm:p-4 rounded-2xl">
                                            <div className={`font-bold  w-full text-center border-b-2 border-white mb-5 pb-3 ${mobile1 ? 'text-[15px]' : 'text-xl'}`}>Les fautes qu'il comise</div>
                                            {dataFautes.map((data, index) => {
                                                return (
                                                    <div key={index} className="text-gray-500 mb-2 bg-white p-4 rounded-2xl">
                                                        <div className="font-bold underline mb-3">Faute N° {index + 1}</div>
                                                        <div>
                                                            <div className={`mb-2 font-semibold ${mobile1 ? 'text-[12px]' : ''} `}>Faute: <span className="font-normal">{data.FauteComise}</span></div>
                                                            <div className={`mb-2 font-semibold ${mobile1 ? 'text-[12px]' : ''} `}>Points retranche: <span className="font-normal">{data.PointRetranche}</span></div>
                                                            <div className={`mb-2 font-semibold ${mobile1 ? 'text-[12px]' : ''} `}>Points restant: <span className={`${data.reste < 50 ? 'text-red-500 underline font-bold italic' : 'text-green-600  font-bold'} `}> {(data.reste.toString())}/60 ~{(data.reste * 100 / 60).toFixed(0).toString()}%</span></div>
                                                            <div className={`mb-2 font-semibold ${mobile1 ? 'text-[12px]' : ''} `}>Date: <span className="font-normal">{data.date && formatDate(data.date)}</span></div>
                                                            <div className={`mb-2 font-semibold ${mobile1 ? 'text-[12px]' : ''} `}>Nom Encadreur: <span className="font-normal">{data.nomEncadreurs}</span></div>
                                                            <div className={`mb-2 font-semibold ${mobile1 ? 'text-[12px]' : ''} `}>
                                                                <div className="w-full cursor-pointer flex justify-center items-center h-[2.5em]">

                                                                    Signature:
                                                                    <div onClick={() => { GetPhotoClick(data.photos); GetZoom(true) }} className="w-10 h-10 border border-fuchsia-100 rounded-full">
                                                                        <img className="w-full h-full object-contain" src={`${STATIC_URL}/students/${data.photos}`} />

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={` flex relative ${mobile1 ? 'w-full' : 'w-[15em]'}`}>
                                                            <div onClick={(e) => { Getmodifiers(true, index); e.stopPropagation(); setIdretr(data.id); setNewData(data.PointRetranche) }} class="my-2  w-max cursor-pointer flex items-center justify-center px-2 py-1 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg">
                                                                <p class={`first-letter:uppercase text-white font-semibold ${mobile1 ? 'text-[12px]' : ''} `}>
                                                                    Modifier
                                                                </p>
                                                            </div>
                                                            {modifier && indexModifier === index && (
                                                                <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
                                                                    <div className="w-full max-w-lg bg-white rounded-lg overflow-hidden shadow-xl">
                                                                        <div className="flex justify-end p-4">
                                                                            <button onClick={() => { Getmodifier(false); setIndexModifier(index); }} className="text-red-500 hover:text-red-700 focus:outline-none">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                                                                </svg>
                                                                            </button>
                                                                        </div>
                                                                        <div className="p-4">
                                                                            <input
                                                                                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring focus:ring-blue-500"
                                                                                placeholder="Modifier les points ..."
                                                                                value={newData}
                                                                                onChange={(e) => setNewData(e.target.value)}
                                                                            />
                                                                        </div>
                                                                        <div className="flex justify-end p-4">
                                                                            <button onClick={sendDataToDatabase} className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 focus:outline-none focus:ring focus:ring-green-500">
                                                                                Enregistrer
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            <div class="my-2 bg-red-400 text-white ml-2 w-max cursor-pointer flex justify-center items-center px-2 py-1  focus:outline-none rounded-lg">
                                                                <button onClick={() => handleDelete(data.id)} class={`first-letter:uppercase text-white font-semibold ${mobile1 ? 'text-[12px]' : ''} `}>

                                                                    Supprimer
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`fixed  flex justify-center  items-center top-0 left-0 bg-[#00000044] z-[999] w-full transition-all delay-75 h-full ${zoom ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'}`}>
                        <div className={` bg-white rounded-2xl flex justify-center items-center transition-all duration-200 relative delay-100 ${zoom ? 'w-[99%] h-[99%] opacity-100 pointer-events-auto' : 'w-[99%] h-[0%] pointer-events-none opacity-0'}`}>
                            <div onClick={() => { GetZoom(false) }} className="absolute right-2 top-2 p-2 rounded-full bg-red-200">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-octagon h-5 w-5 text-red-600 cursor-pointer" viewBox="0 0 16 16">
                                    <path d="M4.54.146A.5.5 0 0 1 4.893 0h6.214a.5.5 0 0 1 .353.146l4.394 4.394a.5.5 0 0 1 .146.353v6.214a.5.5 0 0 1-.146.353l-4.394 4.394a.5.5 0 0 1-.353.146H4.893a.5.5 0 0 1-.353-.146L.146 11.46A.5.5 0 0 1 0 11.107V4.893a.5.5 0 0 1 .146-.353L4.54.146zM5.1 1 1 5.1v5.8L5.1 15h5.8l4.1-4.1V5.1L10.9 1z" />
                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                                </svg>
                            </div>
                            <img src={`${STATIC_URL}/students/${PhotoClick}`}
                                className="w-full h-full object-contain" />
                        </div>
                    </div>
                </div>
                <FootterCopy />
            </div>
        </>

    );
}

export default ListeSuiveurDetailPrefetDisplin;
