import React, { useState } from "react";
import DetailDossierComponent from "../../component/Secretariat/DossierEleve/DetailDossierEleveSecretaireComponent";
function DetailDossierPage({ getSizeMobille, getMenuMobille }) {
  return (
    <>
      <DetailDossierComponent getSizeMobille={getSizeMobille} getMenuMobille={getMenuMobille} />
    </>
  );
}

export default DetailDossierPage;
