import React from 'react'
import AddMaterielEncandreur from './AddMaterielEncandreur'
import SlideEncadrementNavBars from '../SlideEncadrementNavBars/SlideEncadrementNavBars'
function AddMaterielEncadreurCompoenent({ getSizeMobille, getMenuMobille }) {
    return (
        <div className='flex justify-between overflow-hidden bg-white'>
            <SlideEncadrementNavBars getMenuMobille={getMenuMobille} />
            <AddMaterielEncandreur getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default AddMaterielEncadreurCompoenent
