import React from 'react'
import AddDepenseEncadreur from './AddDepenseEncadreur'
import SlideEncadrementNavBars from '../SlideEncadrementNavBars/SlideEncadrementNavBars'
function AddDepenseEncadreurComponent() {
    return (
        <div className='flex justify-between overflow-hidden bg-white'>
            <SlideEncadrementNavBars />
            <AddDepenseEncadreur />
        </div>
    )
}

export default AddDepenseEncadreurComponent
