import React, { useState } from "react";
import AjouterExpedierComponent from "../../component/Secretariat/Courriers/Expedier/AjouterExpedierComponent";
function AjouterExpedierPage() {
  return (
    <>
      <AjouterExpedierComponent />
    </>
  );
}

export default AjouterExpedierPage;
