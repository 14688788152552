import React from 'react'
import SideBarBibliote from '../SideBar/SideBarBibliote'
import RetourLivresVisiteur from './RetourLivresVisiteur'
function RetourLivresVisiteurComponent({  SetResumeQui, SetEmpruntId, setLivreReturn, SetResume, LivreReturn, getSizeMobille, getMenuMobille }) {
    return (
        <div className='flex justify-between overflow-hidden bg-white'>
            <SideBarBibliote getMenuMobille={getMenuMobille} />
            <RetourLivresVisiteur SetResumeQui={SetResumeQui} SetEmpruntId={SetEmpruntId} LivreReturn={LivreReturn} setLivreReturn={setLivreReturn} SetResume={SetResume} getMenuMobille={getMenuMobille} getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default RetourLivresVisiteurComponent
