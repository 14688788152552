import React from 'react'
import DetailMaterielEncadrement from './DetailMaterielEncadrement'
import SlideEncadrementNavBars from '../SlideEncadrementNavBars/SlideEncadrementNavBars'
function DetailMaterielEncadrementComponent({ getSizeMobille, getMenuMobille }) {
    return (
        <div className='flex justify-between overflow-hidden bg-white'>
            <SlideEncadrementNavBars getMenuMobille={getMenuMobille} />
            <DetailMaterielEncadrement getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default DetailMaterielEncadrementComponent
