import React, { useState } from "react";
import AjouterEleveComponent from "../../component/Secretariat/DossierEleve/AjouterEleveComponent";
function AjouterElevePage({ getMenuMobille, getSizeMobille }) {
  return (
    <>
      <AjouterEleveComponent getSizeMobille={getSizeMobille} getMenuMobille={getMenuMobille} />
    </>
  );
}

export default AjouterElevePage;
