
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { Component, useEffect, useRef, useState } from "react";
import FootterCopy from "../../FootterCopy/FootterCopy";
import { Link } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { API } from "../../../Api/API";
import { encryptData } from "../../../encryptionModule";
import Swal from 'sweetalert2';
import { toast } from "react-toastify";
function HorairePrefetDisplin({ getSizeMobille }) {
    const [mobile, Setmonile] = useState(window.innerWidth < 1575)
    const [mobile2, Setmonile2] = useState(window.innerWidth < 842)
    const [mobile3, Setmonile3] = useState(window.innerWidth < 318)
    const [mobile4, Setmonile4] = useState(window.innerWidth < 600)
    const [mobile11, SetMobile11] = useState(window.innerWidth < 501)
    const [cahsPrintAll, SetPrintAll] = useState(false)

    const loadActiviesHoraires = () => {
        API.get('/HI/hinternecontentLastSchoolYear').then((res) => {
            setactiviesHoraires(res.data)
        }).catch((err) => {
            console.log(err.message)
        }
        )
    }
    const handleDelete = (id) => {
        Swal.fire({
            title: 'Êtes-vous sûr?',
            text: 'Vous ne pourrez pas revenir en arrière!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui, supprimer!',
            cancelButtonText: 'Annuler'
        }).then((result) => {
            if (result.isConfirmed) {
                API.delete(`/HI/hinternecontentById/${id}`)
                    .then(() => {
                        toast.success('L\'entrée a été supprimée avec succès.');
                        loadActiviesHoraires()
                    })
                    .catch(error => {
                        console.error('Erreur lors de la suppression de l\'entrée :', error.message);
                        Swal.console.warn('Une erreur s\'est produite lors de la suppression de l\'entrée.');
                    });
            }
        });
    };







    useEffect(() => {
        const hundlesSize = () => {
            Setmonile(window.innerWidth < 1575)
            Setmonile2(window.innerWidth < 842)
            Setmonile3(window.innerWidth < 318)
            Setmonile4(window.innerWidth < 600)
            SetMobile11(window.innerWidth < 501)
        }
        window.addEventListener('resize', hundlesSize)
        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])

    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    const [activiesHoraires, setactiviesHoraires] = useState([])

    useEffect(() => {
        API.get('/HI/hinternecontentLastSchoolYear').then((res) => {
            setactiviesHoraires(res.data)
        }).catch((err) => {
            console.log(err.message)
        }
        )
    }, [])



    return (

        <div className={`bg-white  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
            <div className="sm:p-4 p-1 min-h-screen">
                <div classNameName="sm:p-4 p-1 ">
                    <div className="sm:my-10 my-2">
                        <div className="w-full flex flex-row items-center justify-between">
                            <p className={`text-navy-700 ${mobile4 ? 'text-[18px]' : 'text-[25px]'}   text-gray-600`}>
                                <p className="hover:text-navy-700 font-bold  text-gray-600 font-serif"                                >
                                    Liste des horaire des activites
                                </p>
                            </p>
                        </div>
                        <div className="w-full justify-between sm:px-4 flex">
                            <div className="w-max flex justify-end py-2 px-4">
                                <div onClick={() => { SetPrintAll(true); setTimeout(() => { handlePrint() }, 0); setTimeout(() => { SetPrintAll(false); }, 0); }} className="border border-blue-100 px-4 py-1 w-max bg-green-200 ml-2 text-green-600 transition-all  rounded-md cursor-pointer">Imprime tous</div>
                            </div>
                            <Link to='/Horaire/Ajout' class=" p-2  flex justify-center items-center bg-blue-200 h-[3em] w-[3em] rounded-full">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle-fill h-7 w-7 text-blue-700 " viewBox="0 0 16 16">
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z" />
                                </svg>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className={`my-2 w-full  gap-2 p-1 sm:p-2 `}>
                    {activiesHoraires.map((data, posi) => {
                        return (
                            <div key={posi} className={`bg-white shadow-xl border w-full mt-10 border-fuchsia-400 rounded-lg p-4 `}>
                                <div className="flex   flex-col">
                                    <div className={`leading-none w-full text-left border-b-2 border-white pb-4 font-bold text-[#00000086] ${mobile4 ? 'text-[15px]' : 'text-[20px]   '}`}>
                                        {data.jour}
                                    </div>
                                    <div className={`text-[18px] flex justify-between  w-full text-center mt-4 font-normal text-gray-500 ${mobile3 ? 'flex-col' : ''}`}>
                                        <div className={`p-2 w-full border border-fuchsia-200 rounded-lg ${mobile3 ? 'mb-3' : ''} `}>
                                            <table className="w-full">
                                                <thead>
                                                    <tr className="border-b border-gray-200">
                                                        <th className={`text-left ${mobile4 ? 'text-[10px]' : ''} `}>Heures</th>
                                                        <th></th>
                                                        <th className={`text-left ${mobile4 ? 'text-[10px]' : ''}`}>Activites</th>
                                                        <th className={`text-right ${mobile4 ? 'text-[10px]' : ''}`}>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="text-gray-400">
                                                    {data.HeursActivites.map((datas, posi) => (
                                                        <tr key={posi} className="mt-3 text-left border-b border-gray-200">
                                                            <td className={`p-2 ${mobile4 ? 'text-[10px]' : ''}`}>{datas.plage}</td>
                                                            <td className="">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left-right text-fuchsia-600 mx-2" viewBox="0 0 16 16">
                                                                    <path fill-rule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5m14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5" />
                                                                </svg>
                                                            </td>
                                                            <td className={`p-2 ${mobile4 ? 'text-[10px]' : ''}`}>{datas.activites}</td>
                                                            <td className="w-full flex justify-end items-center mt-4">
                                                                <Link to={`/Horaire/Modifier/${data.jour}/${datas.id && encryptData((datas.id).toString())}`} className={`border border-blue-100  bg-blue-200 text-blue-600 transition-all  rounded-md cursor-pointer ${mobile4 ? 'px-1 py-1 text-[12px]' : 'px-4 py-1'} `}>
                                                                    Modifier
                                                                </Link>
                                                                <button onClick={() => handleDelete(datas.id)} className={`border border-blue-100  ml-1 bg-red-200 text-red-600 transition-all  rounded-md cursor-pointer ${mobile4 ? 'px-1 py-1 text-[12px]' : 'px-4 py-1'} `}>
                                                                    Supprimer
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>

                                    <div className={`flex mt-2 text-gray-600 ${mobile4 ? 'text-[10px]' : ''}`}><div>Année scolaire :</div> <div className="text-blue-700 font-semibold ml-1">{data.AA}</div></div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                {cahsPrintAll && <PrintAll ref={componentRef} activiesHoraires={activiesHoraires} />}

            </div>
            <FootterCopy />
        </div>


    );
}

export default HorairePrefetDisplin;


class PrintAll extends Component {
    render() {
        const { activiesHoraires } = this.props;
        return (
            <div className={`my-1 w-full  gap-2 p-2 `}>
                <div className="w-full text-center font-bold text-xl underline first-letter:uppercase">Horaire des activites à une semaine</div>
                {activiesHoraires.map((data, posi) => {
                    return (
                        <div key={posi} className={`bg-white   w-full mt-[2px] border-gray-100  p-2 `}>
                            <div className="flex   flex-col">
                                <div className={`leading-none w-full text-left text-[12px] border-b border-white  font-bold text-[#00000063]  '}`}>
                                    {data.jour}
                                </div>
                                <div className={` flex justify-between  w-full text-center mt-1 font-normal text-gray-500 }`}>
                                    <div className={`w-full border border-[#0000006e]`}>
                                        <table className="w-full">
                                            <thead className="">
                                                <tr className="border-b h-8 border-[#0000006e]">
                                                    <th className="text-left text-[10px] w-[10em] border-r pl-2 border-[#0000006e]">Heure</th>
                                                    <th className="text-left text-[10px] pl-2">Activites</th>

                                                </tr>
                                            </thead>
                                            <tbody className="text-gray-400">
                                                {data.HeursActivites.map((datas, posi) => (
                                                    <tr key={posi} className="mt-1 text-left border-b border-[#0000006e]">
                                                        <td className="px-2 text-[10px] border-r border-[#0000006e] w-[13em]">{datas.plage}</td>
                                                        <td className="px-2 text-[10px] border-r border-[#0000006e]">{datas.activites}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </div>
                    )
                })}
                <div className="flex  text-gray-600 mt-2 text-[10px]"><div>Année scolaire :</div> <div className="text-blue-700 font-semibold ml-1"> {activiesHoraires.length > 0 && activiesHoraires[0].AA}</div></div>
                <div className="flex  text-gray-600 mt-1 text-[10px]"><div></div> <div className="text-gray-300 select-none font-semibold  ml-1"> Lycée d'exellence NGAGARA</div></div>
            </div>
        )
    }
}