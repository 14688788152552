import React from 'react'
import AddEmpruntMaterielEncadrement from './AddEmpruntMaterielEncadrement'
import SlideEncadrementNavBars from '../SlideEncadrementNavBars/SlideEncadrementNavBars'
function AddEmpruntMaterielEncadrementComponent({ getSizeMobille, getMenuMobille }) {
    return (
        <div className='flex justify-between overflow-hidden bg-white'>
            <SlideEncadrementNavBars getMenuMobille={getMenuMobille} />
            <AddEmpruntMaterielEncadrement getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default AddEmpruntMaterielEncadrementComponent
