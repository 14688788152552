/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import FootterCopy from "../../FootterCopy/FootterCopy";
import "./sweetalert.css";
import { API, STATIC_URL } from "../../../Api/API";
import { decryptData, encryptData } from "../../../encryptionModule";
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

function DetailConsultSuiveurEncadrement({ getSizeMobille }) {
    const { id, classId, schoolyearId } = useParams()
    const [mobile1, Setmonile1] = useState(window.innerWidth < 990)
    const [mobile2, Setmonile2] = useState(window.innerWidth < 520)

    useEffect(() => {
        const hundlesSize = () => {
            Setmonile1(window.innerWidth < 990)
            Setmonile2(window.innerWidth < 520)
        }

        window.addEventListener('resize', hundlesSize)

        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])
    const studentid = decryptData(id)
    const [oneStudent, setOneState] = useState({})
    const [dataFautes, setDataFautes] = useState([])
    useEffect(() => {
        API.get(`/student/suiveurDetails/${studentid}`).then((res) => {
            setDataFautes(res.data.faults)
            setOneState(res.data)
        }).catch((err) => {
            console.log(err.message)
        })
    }, [studentid])
    const formatDate = date => {
        return format(new Date(date), "'Le 'd, MMMM yyyy", { locale: fr });
    };

    let notesRetrenche = 0; // Initialisez notesRetrenche à 0
    dataFautes.forEach((e) => {
        notesRetrenche += e.PointRetranche; // Ajoutez e.PointRetranche à la somme
        const getRest = 60 - notesRetrenche; // Calcul du reste après soustraction
        e.reste = getRest; // Affectez la valeur du reste à la propriété "reste" de l'élément
    });


    const [inputValue, setInputValue] = useState('');
    const [sortOrder, setSortOrder] = useState('asc');
    const [sortedColumn, setSortedColumn] = useState(null);

    const ValuesInput = (e) => {
        setInputValue(e.target.value);
    };



    const fildata = (data, inputValue) => {
        if (!inputValue.trim()) {
            return data;
        }
        return data.filter((item) => {
            if (
                item.FauteComise.toString().toLowerCase().includes(inputValue.toLowerCase()) ||
                item.PointRetranche.toString().toLowerCase().includes(inputValue.toLowerCase()) ||
                item.date.toString().toLowerCase().includes(inputValue.toLowerCase()) ||
                item.nomEncadreurs.toString().toLowerCase().includes(inputValue.toLowerCase()) ||
                item.reste.toString().toLowerCase().includes(inputValue.toLowerCase())

            ) {
                return true;
            }
            return false;
        });
    };



    const handleSort = (column) => {
        if (column === sortedColumn) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortOrder('asc');
            setSortedColumn(column);
        }
    };

    const sortData = (data, column, sortOrder) => {
        return data.slice().sort((a, b) => {
            const aValue = a[column];
            const bValue = b[column];

            if (aValue < bValue) return sortOrder === 'asc' ? -1 : 1;
            if (aValue > bValue) return sortOrder === 'asc' ? 1 : -1;
            return 0;
        });
    };

    const filteredData = fildata(dataFautes, inputValue);

    const sortedData = sortData(filteredData, sortedColumn, sortOrder);


    function highlightText(text, inputValue) {
        if (!inputValue) return text;

        const highlightedText = [];
        let currentIndex = 0;

        while (currentIndex < text.length) {
            const inputChar = inputValue.toLowerCase();
            const matchIndex = text.substr(currentIndex).toLowerCase().indexOf(inputChar);

            if (matchIndex !== -1) {

                highlightedText.push(text.substring(currentIndex, currentIndex + matchIndex));


                highlightedText.push(
                    <span className="text-orange-500" key={currentIndex + matchIndex}>
                        {text.substring(currentIndex + matchIndex, currentIndex + matchIndex + inputValue.length)}
                    </span>
                );

                currentIndex = currentIndex + matchIndex + inputValue.length;
            } else {
                highlightedText.push(text.substring(currentIndex));
                break;
            }
        }

        return highlightedText;
    }


    const [PhotoClick, GetPhotoClick] = useState('')
    const [zoom, GetZoom] = useState(false)
    const [mobile11, SetMobile11] = useState(window.innerWidth < 501)





    useEffect(() => {
        const hundlesSize = () => {
            SetMobile11(window.innerWidth < 501)
        }
        window.addEventListener('resize', hundlesSize)
        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])

    return (
        <>
            <div className={`bg-white  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
                <Link to={`/Suiveur/consult/${schoolyearId}/${classId}`} className="ml-2 text-[18px] text-blue-500 hover:text-blue-800 underline">Retour</Link>
                <div className="p-6 min-h-[90vh]">



                    <div class=" border border-fuchsia-300 rounded-lg  p-1 sm:p-3 flex flex-col items-start justify-center  ">
                        <div className={` border border-gray-100 rounded-2xl ${mobile1 ? 'w-12 h-12' : 'w-28 h-28'} `}>
                            <img
                                src={`${STATIC_URL}/students/${oneStudent.Profile}`}
                                className="w-full h-full object-contain" />
                        </div>
                        <div class="flex flex-col justify-start">
                            <p class={`mb-4   text-neutral-600 ${mobile1 ? 'text-[12px]' : 'text-xl'} `}>
                                Nom complet : {oneStudent.Nom_complet}
                            </p>

                            <p class={`mb-4   text-neutral-600 ${mobile1 ? 'text-[12px]' : 'text-xl'} `}>
                                Matricule : {oneStudent.matricule}
                            </p>
                            <p class={`mb-4   text-neutral-600 ${mobile1 ? 'text-[12px]' : 'text-xl'} `}>
                                Année-scolaire: {oneStudent.schoolyear}
                            </p>
                            <p class={`mb-4   text-neutral-600 ${mobile1 ? 'text-[12px]' : 'text-xl'} `}>
                                Trimmestre: {oneStudent.Trimestre}
                            </p>
                        </div>
                        <div class="sm:flex items-center pr-4  w-full h-max  justify-end">
                            <div class="mx-2">
                                <div class="relative lg:w-64">
                                    <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                        <svg
                                            class="w-5 h-5 text-gray-500"
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fill-rule="evenodd"
                                                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                                clip-rule="evenodd"
                                            ></path>
                                        </svg>
                                    </div>
                                    <input
                                        type="text"
                                        name="email"
                                        id="topbar-search"
                                        class="bg-white border border-gray-300 h-10 text-gray-900 sm:text-lg first-letter:uppercase rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full pl-10 .5"
                                        placeholder="Rechercher..."
                                        onInput={ValuesInput}
                                    />
                                </div>
                            </div>
                            <button class="mt-4 sm:mt-0 inline-flex items-start justify-start px-1 py-3 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg">
                                <p class="text-lg first-letter:uppercase font-semibold leading-none text-white">
                                    Ok
                                </p>
                            </button>
                        </div>
                        <div class="w-full my-4 ">
                            <div class="bg-white shadow rounded-lg p-4 ">
                                <div class={`b-4 flex items-center justify-between ${mobile2 ? 'flex-col' : ''}`}>
                                    <div>
                                        <h3 class="sm:text-xl font-bold text-gray-900 mb-2">
                                            Listes des fautes
                                        </h3>
                                    </div>

                                    <Link to={`/Suiveur/consult/Ajout/${id}/${schoolyearId}/${classId}`} class=" font-bold text-gray-900  bg-blue-200 p-2 rounded-full cursor-pointer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle-fill w-6 text-blue-700 h-6" viewBox="0 0 16 16">
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z" />
                                        </svg>
                                    </Link>
                                </div>

                                <div class="flex flex-col mt-8 ">
                                    <div class="overflow-x-auto rounded-lg">
                                        <div class={`align-middle inline-block  ${mobile1 ? 'min-w-[70em]' : 'min-w-full'}`}>
                                            <div class="shadow overflow-hidden sm:rounded-lg">
                                                <table class="min-w-full divide-y  divide-gray-200">
                                                    <thead class="bg-gray-50">
                                                        <tr>

                                                            <th onClick={() => handleSort('FauteComise')}
                                                                scope="col"
                                                                class=" py-4 px-2 font-semibold relative border-l text-left cursor-pointer text-lg first-letter:uppercase  text-gray-500  tracking-wider"
                                                            >
                                                                Faute
                                                                {sortedColumn === 'FauteComise' && <span className="pr-0 absolute right-2">{sortOrder === 'asc' ? '▲' : '▼'}</span>}
                                                            </th>

                                                            <th onClick={() => handleSort('PointRetranche')}
                                                                scope="col"
                                                                class=" py-4 w-[2em] px-2 border-l text-left cursor-pointer relative text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                                                            >
                                                                Retranche
                                                                {sortedColumn === 'PointRetranche' && <span className="pr-0 absolute right-2">{sortOrder === 'asc' ? '▲' : '▼'}</span>}
                                                            </th>

                                                            <th onClick={() => handleSort('date')}
                                                                scope="col"
                                                                class=" py-4 px-2 border-l text-left cursor-pointer relative text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                                                            >
                                                                Date
                                                                {sortedColumn === 'date' && <span className="pr-0 absolute right-2">{sortOrder === 'asc' ? '▲' : '▼'}</span>}

                                                            </th>
                                                            <th
                                                                scope="col"
                                                                class="  py-4 px-2 border-l flex-shrink-0   relative  text-left text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                                                            >
                                                                Photos
                                                            </th>
                                                            <th onClick={() => handleSort('nomEncadreurs')}
                                                                scope="col"
                                                                class="  py-4 px-2 border-l text-left cursor-pointer relative text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                                                            >
                                                                Encadreur
                                                                {sortedColumn === 'nomEncadreurs' && <span className="pr-0 absolute right-2">{sortOrder === 'asc' ? '▲' : '▼'}</span>}

                                                            </th>
                                                            <th onClick={() => handleSort('reste')}
                                                                scope="col"
                                                                class="   py-4 px-2 border-l text-left cursor-pointer relative text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                                                            >
                                                                Restant
                                                                {sortedColumn === 'reste' && <span className="pr-0 absolute right-2">{sortOrder === 'asc' ? '▲' : '▼'}</span>}

                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody class="bg-white">
                                                        {sortedData.map((data, index) => {

                                                            return (<tr key={index}>

                                                                <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase  text-gray-500">
                                                                    {highlightText(data.FauteComise.toString(), inputValue)}

                                                                </td>
                                                                <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase  text-gray-500">

                                                                    {highlightText(data.PointRetranche.toString(), inputValue)}
                                                                </td>
                                                                <td class="border-b border-gray-200  px-3   border-l whitespace-nowrap text-lg first-letter:uppercase  text-gray-500">

                                                                    {highlightText(formatDate(data.date), inputValue)}
                                                                </td>
                                                                <td class="border-b border-gray-200    border-l  ">
                                                                    <div className="w-full cursor-pointer flex justify-center items-center h-[2.5em]">
                                                                        <div onClick={() => { GetPhotoClick(data.photos); GetZoom(true) }} className="w-10 h-10 border border-fuchsia-100 rounded-full">
                                                                            <img className="w-full h-full object-contain" src={`${STATIC_URL}/students/${data.photos}`} />

                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase  text-gray-500">
                                                                    {highlightText(data.nomEncadreurs.toString(), inputValue)}
                                                                </td>
                                                                <td class={`border-b border-gray-200 relative  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase  text-gray-500 ${data.reste * 100 / 60 < 50 ? 'text-red-500 underline font-bold italic' : 'text-green-600  font-bold'} `}>
                                                                    {highlightText(data.reste.toString(), inputValue)}/60 ~{highlightText((data.reste * 100 / 60).toFixed(0).toString(), inputValue)}%
                                                                    {data.reste * 100 / 60 < 50 ?
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi absolute right-[2px] top-6 bi-hand-thumbs-down-fill" viewBox="0 0 16 16">
                                                                            <path d="M6.956 14.534c.065.936.952 1.659 1.908 1.42l.261-.065a1.378 1.378 0 0 0 1.012-.965c.22-.816.533-2.512.062-4.51.136.02.285.037.443.051.713.065 1.669.071 2.516-.211.518-.173.994-.68 1.2-1.272a1.896 1.896 0 0 0-.234-1.734c.058-.118.103-.242.138-.362.077-.27.113-.568.113-.856 0-.29-.036-.586-.113-.857a2.094 2.094 0 0 0-.16-.403c.169-.387.107-.82-.003-1.149a3.162 3.162 0 0 0-.488-.9c.054-.153.076-.313.076-.465a1.86 1.86 0 0 0-.253-.912C13.1.757 12.437.28 11.5.28H8c-.605 0-1.07.08-1.466.217a4.823 4.823 0 0 0-.97.485l-.048.029c-.504.308-.999.61-2.068.723C2.682 1.815 2 2.434 2 3.279v4c0 .851.685 1.433 1.357 1.616.849.232 1.574.787 2.132 1.41.56.626.914 1.28 1.039 1.638.199.575.356 1.54.428 2.591z" />
                                                                        </svg> :
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi absolute right-[2px] top-6 bi-hand-thumbs-up-fill" viewBox="0 0 16 16">
                                                                            <path d="M6.956 1.745C7.021.81 7.908.087 8.864.325l.261.066c.463.116.874.456 1.012.965.22.816.533 2.511.062 4.51a9.84 9.84 0 0 1 .443-.051c.713-.065 1.669-.072 2.516.21.518.173.994.681 1.2 1.273.184.532.16 1.162-.234 1.733.058.119.103.242.138.363.077.27.113.567.113.856 0 .289-.036.586-.113.856-.039.135-.09.273-.16.404.169.387.107.819-.003 1.148a3.163 3.163 0 0 1-.488.901c.054.152.076.312.076.465 0 .305-.089.625-.253.912C13.1 15.522 12.437 16 11.5 16H8c-.605 0-1.07-.081-1.466-.218a4.82 4.82 0 0 1-.97-.484l-.048-.03c-.504-.307-.999-.609-2.068-.722C2.682 14.464 2 13.846 2 13V9c0-.85.685-1.432 1.357-1.615.849-.232 1.574-.787 2.132-1.41.56-.627.914-1.28 1.039-1.639.199-.575.356-1.539.428-2.59z" />
                                                                        </svg>
                                                                    }
                                                                </td>
                                                            </tr>)
                                                        })}
                                                        {sortedData.length == 0 &&
                                                            <tr>
                                                                <td colSpan={7} className="h-20 w-full text-center text-2xl text-gray-400">
                                                                    {dataFautes.length === 0 ? (
                                                                        <div>
                                                                            Aucun élément correspondant aux fautes
                                                                        </div>

                                                                    ) : (<div>
                                                                        Aucun élément correspondant à <span className="font-bold">{inputValue}</span>
                                                                    </div>

                                                                    )}
                                                                </td>

                                                            </tr>}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className={`fixed  flex justify-center  items-center top-0 left-0 bg-[#00000044] z-[999] w-full transition-all delay-75 h-full ${zoom ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'}`}>
                        <div className={` bg-white rounded-2xl flex justify-center items-center transition-all duration-200 relative delay-100 ${zoom ? 'w-[99%] h-[99%] opacity-100 pointer-events-auto' : 'w-[99%] h-[0%] pointer-events-none opacity-0'}`}>
                            <div onClick={() => { GetZoom(false) }} className="absolute right-2 top-2 p-2 rounded-full bg-red-200">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-octagon h-5 w-5 text-red-600 cursor-pointer" viewBox="0 0 16 16">
                                    <path d="M4.54.146A.5.5 0 0 1 4.893 0h6.214a.5.5 0 0 1 .353.146l4.394 4.394a.5.5 0 0 1 .146.353v6.214a.5.5 0 0 1-.146.353l-4.394 4.394a.5.5 0 0 1-.353.146H4.893a.5.5 0 0 1-.353-.146L.146 11.46A.5.5 0 0 1 0 11.107V4.893a.5.5 0 0 1 .146-.353L4.54.146zM5.1 1 1 5.1v5.8L5.1 15h5.8l4.1-4.1V5.1L10.9 1z" />
                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                                </svg>
                            </div>
                            <img src={`${STATIC_URL}/students/${PhotoClick}`}
                                className="w-full h-full object-contain" />
                        </div>
                    </div>

                </div>
                <FootterCopy />
            </div >
        </>
    );
}

export default DetailConsultSuiveurEncadrement;
