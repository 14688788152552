/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import "./sweetalert.css";
import FootterCopy from "../../FootterCopy/FootterCopy";
import { API } from "../../../Api/API";
import { decryptData } from "../../../encryptionModule";
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
function DocClasseur({ getSizeMobille }) {

  const { id } = useParams()
  const classeurId = decryptData(id)
  const [students, getStudent] = useState([])
  const [courriers, getCourriers] = useState([])
  const [staffs, getstaffs] = useState([])
  const [demandeexprications, getdemandeexprications] = useState([])
  const [repdemandeexprications, getrepdemandeexprications] = useState([])
  const [noClasseur, getnoClasseur] = useState("")
  useEffect(() => {
    API.get(`/classeur/classeurstatsById/${classeurId}`).then((resp) => {
      getStudent(resp.data.students)
      getnoClasseur(resp.data.noClasseur)
      getCourriers(resp.data.courriers)
      getstaffs(resp.data.staffs)
      getdemandeexprications(resp.data.demandeexprications)
      getrepdemandeexprications(resp.data.repdemandeexprications)
    }).catch((err) => {
      console.log(err.message)
    })
  }, [id])
  const formatDate = date => {
    return format(new Date(date), "'Le 'd, MMMM yyyy", { locale: fr });
  };


  const [mobile1, Setmonile1] = useState(window.innerWidth < 808)

  useEffect(() => {
    const hundlesSize = () => {
      Setmonile1(window.innerWidth < 808)

    }

    window.addEventListener('resize', hundlesSize)

    return () => {
      window.removeEventListener('resize', hundlesSize)
    }
  }, [])



  const [inputValue, setInputValue] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortedColumn, setSortedColumn] = useState(null);

  const ValuesInput = (e) => {
    setInputValue(e.target.value);
  };


  const fildata = (data, inputValue) => {
    if (!inputValue.trim()) {
      return data;
    }
    return data.filter((item) => {
      if (
        item.nomprenom.toString().toLowerCase().includes(inputValue.toLowerCase()) ||
        item.matricule.toString().toLowerCase().includes(inputValue.toLowerCase()) ||
        item.createdAt.toString().toLowerCase().includes(inputValue.toLowerCase())

      ) {
        return true;
      }
      return false;
    });
  };



  const handleSort = (column) => {
    if (column === sortedColumn) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortOrder('asc');
      setSortedColumn(column);
    }
  };

  const sortData = (data, column, sortOrder) => {
    return data.slice().sort((a, b) => {
      const aValue = a[column];
      const bValue = b[column];

      if (aValue < bValue) return sortOrder === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortOrder === 'asc' ? 1 : -1;
      return 0;
    });
  };

  const filteredData = fildata(students, inputValue);

  const sortedData = sortData(filteredData, sortedColumn, sortOrder);


  function highlightText(text, inputValue) {
    if (!inputValue) return text;

    const highlightedText = [];
    let currentIndex = 0;

    while (currentIndex < text.length) {
      const inputChar = inputValue.toLowerCase();
      const matchIndex = text.substr(currentIndex).toLowerCase().indexOf(inputChar);

      if (matchIndex !== -1) {

        highlightedText.push(text.substring(currentIndex, currentIndex + matchIndex));


        highlightedText.push(
          <span className="text-orange-500" key={currentIndex + matchIndex}>
            {text.substring(currentIndex + matchIndex, currentIndex + matchIndex + inputValue.length)}
          </span>
        );

        currentIndex = currentIndex + matchIndex + inputValue.length;
      } else {
        highlightedText.push(text.substring(currentIndex));
        break;
      }
    }

    return highlightedText;
  }








  return (
    <>
      <div className={`bg-white  overflow-x-auto  h-[85vh] ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
        <Link to='/Classeur' className="ml-2 text-[18px] text-blue-500 hover:text-blue-800 underline">Retour</Link>
        <div className="p-6  min-h-[85vh]">
          <div class="my-5">
            <div class="w-full flex flex-row items-center justify-between">
              <p class="text-navy-700 shrink  ">
                <p
                  class="hover:text-navy-700 text-gray-700 uppercase sm:text-3xl font-bold "

                >
                  Classeur
                </p>
              </p>

            </div>
          </div>
          <div class="sm:flex items-center pr-4  h-max  justify-end">
            <div class="mx-2">
              <div class="relative lg:w-64">
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <svg
                    class="w-5 h-5 text-gray-500"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
                <input
                  type="text"
                  name="email"
                  id="topbar-search"
                  class="bg-white border border-gray-300 h-10 text-gray-900 sm:text-lg first-letter:uppercase rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full pl-10 .5"
                  placeholder="Rechercher..."
                  onInput={ValuesInput}
                />
              </div>
            </div>
          </div>
          <div class="w-full my-4 grid grid-cols-1 ">
            <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">
              <div class="mb-4 flex items-center justify-between">
                <div>
                  <h3 class="text-xl font-bold text-gray-900 mb-2">
                    Listes des classeurs
                  </h3>
                </div>
              </div>

              <div class="flex flex-col mt-8">
                <div class="overflow-x-auto rounded-lg">
                  <div class={`align-middle inline-block  ${mobile1 ? 'min-w-[45em]' : 'min-w-full'}`}>
                    <div class="shadow overflow-hidden sm:rounded-lg">


                      <div
                        class=" py-4 px-2 font-semibold relative border-l text-left  text-lg first-letter:uppercase  text-gray-500  tracking-wider"
                      >
                        N<sup className="text-[10px] underline">o</sup> du Classeur: {noClasseur && noClasseur}

                      </div>

                      {sortedData.length > 0 ? (
                        <div className="bg-gray-100 p-2 rounded-md m-2">
                          <div className="w-full flex justify-center items-center py-3 mb-2 border-b border-white pb-4">
                            <div className='text-xl font-semibold text-blue-800'>Lites des dossiers des eleves</div>
                          </div>
                          <table class="min-w-full divide-y  divide-gray-200">
                            <thead class="bg-gray-50">
                              <tr>
                                <th onClick={() => handleSort('nomprenom')}
                                  scope="col"
                                  class=" py-4 px-2 border-l text-left cursor-pointer relative text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                                >
                                  Nom & Prenom
                                  {sortedColumn === 'nomprenom' && <span className="pr-0 absolute right-2">{sortOrder === 'asc' ? '▲' : '▼'}</span>}

                                </th>

                                <th onClick={() => handleSort('matricule')}
                                  scope="col"
                                  class=" py-4 px-2 border-l text-left cursor-pointer relative text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                                >
                                  Matricule
                                  {sortedColumn === 'matricule' && <span className="pr-0 absolute right-2">{sortOrder === 'asc' ? '▲' : '▼'}</span>}

                                </th>
                                <th onClick={() => handleSort('createdAt')}
                                  scope="col"
                                  class="  py-4 px-2 border-l flex-shrink-0  cursor-pointer relative  text-left text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                                >
                                  Date
                                  {sortedColumn === 'createdAt' && <span className="pr-0 absolute right-2">{sortOrder === 'asc' ? '▲' : '▼'}</span>}

                                </th>
                              </tr>
                            </thead>
                            <tbody class="bg-white">
                              {sortedData.map((data, index) => {
                                return (<tr key={index}>
                                  <td class="border-b border-gray-200  p-2  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                    {highlightText(data.nomprenom.toString(), inputValue)}
                                  </td>

                                  <td class="border-b border-gray-200  p-2  border-l whitespace-nowrap text-lg first-letter:uppercase  text-gray-500">
                                    {highlightText(data.matricule.toString(), inputValue)}
                                  </td>
                                  <td class="border-b border-gray-200  p-2  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">

                                    {highlightText(formatDate(data.createdAt), inputValue)}
                                  </td>
                                </tr>
                                )
                              })}

                            </tbody>
                          </table>

                        </div>
                      ) : students.length > 0 ? (
                        <div className="bg-gray-100 p-2 rounded-md m-2">
                          <div className="w-full flex justify-center items-center py-3 mb-2 border-b border-white pb-4">
                            <div className='text-xl font-semibold text-blue-800'>Lites des dossiers des eleves</div>
                          </div>
                          <table class="min-w-full divide-y  divide-gray-200">
                            <thead class="bg-gray-50">
                              <tr>
                                <th onClick={() => handleSort('nomprenom')}
                                  scope="col"
                                  class=" py-4 px-2 border-l text-left cursor-pointer relative text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                                >
                                  Nom & Prenom
                                  {sortedColumn === 'nomprenom' && <span className="pr-0 absolute right-2">{sortOrder === 'asc' ? '▲' : '▼'}</span>}

                                </th>

                                <th onClick={() => handleSort('matricule')}
                                  scope="col"
                                  class=" py-4 px-2 border-l text-left cursor-pointer relative text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                                >
                                  Matricule
                                  {sortedColumn === 'matricule' && <span className="pr-0 absolute right-2">{sortOrder === 'asc' ? '▲' : '▼'}</span>}

                                </th>
                                <th onClick={() => handleSort('createdAt')}
                                  scope="col"
                                  class="  py-4 px-2 border-l flex-shrink-0  cursor-pointer relative  text-left text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                                >
                                  Date
                                  {sortedColumn === 'createdAt' && <span className="pr-0 absolute right-2">{sortOrder === 'asc' ? '▲' : '▼'}</span>}

                                </th>
                              </tr>
                            </thead>
                            <tbody class="bg-white">

                              <tr>
                                <td colSpan={7} className="h-20 w-full text-center text-2xl text-gray-400"> Aucun élément correspondant à <span className="font-bold">{inputValue}</span> </td>
                              </tr>
                            </tbody>
                          </table>

                        </div>
                      ) : null}





                      {courriers.length > 0 && (




                        <div className="bg-gray-100 p-2 rounded-md m-2">
                          <div className="w-full flex justify-center items-center py-3 mb-2 border-b border-white pb-4">
                            <div className='text-xl font-semibold text-blue-800'>Lites des courriers</div>
                          </div>
                          <table class="min-w-full divide-y  divide-gray-200">
                            <thead class="bg-gray-50">
                              <tr>
                                <th onClick={() => handleSort('Matricule')}
                                  scope="col"
                                  class=" py-4 px-2 border-l text-left cursor-pointer relative text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                                >
                                  Expediteur
                                  {sortedColumn === 'Matricule' && <span className="pr-0 absolute right-2">{sortOrder === 'asc' ? '▲' : '▼'}</span>}

                                </th>

                                <th onClick={() => handleSort('Matricule')}
                                  scope="col"
                                  class=" py-4 px-2 border-l text-left cursor-pointer relative text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                                >
                                  Destinateur
                                  {sortedColumn === 'Matricule' && <span className="pr-0 absolute right-2">{sortOrder === 'asc' ? '▲' : '▼'}</span>}

                                </th>
                                <th onClick={() => handleSort('date')}
                                  scope="col"
                                  class="  py-4 px-2 border-l flex-shrink-0  cursor-pointer relative  text-left text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                                >
                                  Date
                                  {sortedColumn === 'date' && <span className="pr-0 absolute right-2">{sortOrder === 'asc' ? '▲' : '▼'}</span>}

                                </th>
                              </tr>
                            </thead>
                            <tbody class="bg-white">
                              {courriers.map((data, index) => {
                                return (<tr key={index}>
                                  <td class="border-b border-gray-200  p-2  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                    {highlightText(data.expediteur.toString(), inputValue)}
                                  </td>

                                  <td class="border-b border-gray-200  p-2  border-l whitespace-nowrap text-lg first-letter:uppercase  text-gray-500">
                                    {highlightText(data.destinateur.toString(), inputValue)}
                                  </td>
                                  <td class="border-b border-gray-200  p-2  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">

                                    {highlightText(formatDate(data.createdAt), inputValue)}
                                  </td>
                                </tr>
                                )
                              })}
                              {courriers.length == 0 &&
                                <tr>
                                  <td colSpan={7} className="h-20 w-full text-center text-2xl text-gray-400"> Aucun élément correspondant à <span className="font-bold">{inputValue}</span> </td>
                                </tr>}
                            </tbody>
                          </table>

                        </div>






                      )}
                      {staffs.length > 0 && (


                        <div className="bg-gray-100 p-2 rounded-md m-2">
                          <div className="w-full flex justify-center items-center py-3 mb-2 border-b border-white pb-4">
                            <div className='text-xl font-semibold text-blue-800'>Lites des dossiers du personnel</div>
                          </div>
                          <table class="min-w-full divide-y  divide-gray-200">
                            <thead class="bg-gray-50">
                              <tr>
                                <th onClick={() => handleSort('Matricule')}
                                  scope="col"
                                  class=" py-4 px-2 border-l text-left cursor-pointer relative text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                                >
                                  Nom & Prenom
                                  {sortedColumn === 'Matricule' && <span className="pr-0 absolute right-2">{sortOrder === 'asc' ? '▲' : '▼'}</span>}

                                </th>

                                <th onClick={() => handleSort('Matricule')}
                                  scope="col"
                                  class=" py-4 px-2 border-l text-left cursor-pointer relative text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                                >
                                  Matricule
                                  {sortedColumn === 'Matricule' && <span className="pr-0 absolute right-2">{sortOrder === 'asc' ? '▲' : '▼'}</span>}

                                </th>
                                <th onClick={() => handleSort('date')}
                                  scope="col"
                                  class="  py-4 px-2 border-l flex-shrink-0  cursor-pointer relative  text-left text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                                >
                                  Date
                                  {sortedColumn === 'date' && <span className="pr-0 absolute right-2">{sortOrder === 'asc' ? '▲' : '▼'}</span>}

                                </th>
                              </tr>
                            </thead>
                            <tbody class="bg-white">
                              {staffs.map((data, index) => {
                                return (<tr key={index}>
                                  <td class="border-b border-gray-200  p-2  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                    {highlightText(data.nomcomplet.toString(), inputValue)}
                                  </td>

                                  <td class="border-b border-gray-200  p-2  border-l whitespace-nowrap text-lg first-letter:uppercase  text-gray-500">
                                    {highlightText(data.matricule.toString(), inputValue)}
                                  </td>
                                  <td class="border-b border-gray-200  p-2  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">

                                    {highlightText(formatDate(data.createdAt), inputValue)}
                                  </td>
                                </tr>
                                )
                              })}
                              {staffs.length == 0 &&
                                <tr>
                                  <td colSpan={7} className="h-20 w-full text-center text-2xl text-gray-400"> Aucun élément correspondant à <span className="font-bold">{inputValue}</span> </td>
                                </tr>}
                            </tbody>
                          </table>

                        </div>







                      )}
                      {demandeexprications.length > 0 && (

                        <div className="bg-gray-100 p-2 rounded-md m-2">
                          <div className="w-full flex justify-center items-center py-3 mb-2 border-b border-white pb-4">
                            <div className='text-xl font-semibold text-blue-800'>Lites des demandes d'explication</div>
                          </div>
                          <table class="min-w-full divide-y  divide-gray-200">
                            <thead class="bg-gray-50">
                              <tr>
                                <th onClick={() => handleSort('Matricule')}
                                  scope="col"
                                  class=" py-4 px-2 border-l text-left cursor-pointer relative text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                                >
                                  Nom & Prenom
                                  {sortedColumn === 'Matricule' && <span className="pr-0 absolute right-2">{sortOrder === 'asc' ? '▲' : '▼'}</span>}

                                </th>

                                <th onClick={() => handleSort('Matricule')}
                                  scope="col"
                                  class=" py-4 px-2 border-l text-left cursor-pointer relative text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                                >
                                  Matricule
                                  {sortedColumn === 'Matricule' && <span className="pr-0 absolute right-2">{sortOrder === 'asc' ? '▲' : '▼'}</span>}

                                </th>


                                <th onClick={() => handleSort('Matricule')}
                                  scope="col"
                                  class=" py-4 px-2 border-l text-left cursor-pointer relative text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                                >
                                  Status
                                  {sortedColumn === 'Matricule' && <span className="pr-0 absolute right-2">{sortOrder === 'asc' ? '▲' : '▼'}</span>}

                                </th>
                                <th onClick={() => handleSort('date')}
                                  scope="col"
                                  class="  py-4 px-2 border-l flex-shrink-0  cursor-pointer relative  text-left text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                                >
                                  Date du lettre
                                  {sortedColumn === 'date' && <span className="pr-0 absolute right-2">{sortOrder === 'asc' ? '▲' : '▼'}</span>}

                                </th>
                              </tr>
                            </thead>
                            <tbody class="bg-white">
                              {demandeexprications.map((data, index) => {
                                return (<tr key={index}>
                                  <td class="border-b border-gray-200  p-2  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                    {highlightText(data.staff.nomcomplet.toString(), inputValue)}
                                  </td>

                                  <td class="border-b border-gray-200  p-2  border-l whitespace-nowrap text-lg first-letter:uppercase  text-gray-500">
                                    {highlightText(data.staff.matricule.toString(), inputValue)}
                                  </td>
                                  <td class="border-b border-gray-200  p-2  border-l whitespace-nowrap text-lg first-letter:uppercase  text-gray-500">
                                    {highlightText(data.status.toString(), inputValue)}
                                  </td>
                                  <td class="border-b border-gray-200  p-2  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">

                                    {highlightText(formatDate(data.createdAt), inputValue)}
                                  </td>
                                </tr>
                                )
                              })}
                              {demandeexprications.length == 0 &&
                                <tr>
                                  <td colSpan={7} className="h-20 w-full text-center text-2xl text-gray-400"> Aucun élément correspondant à <span className="font-bold">{inputValue}</span> </td>
                                </tr>}
                            </tbody>
                          </table>

                        </div>








                      )}

                      {repdemandeexprications.length > 0 && (







                        <div className="bg-gray-100 p-2 rounded-md m-2">
                          <div className="w-full flex justify-center items-center py-3 mb-2 border-b border-white pb-4">
                            <div className='text-xl font-semibold text-blue-800'>Lites des reponses des demandes</div>
                          </div>
                          <table class="min-w-full divide-y  divide-gray-200">
                            <thead class="bg-gray-50">
                              <tr>
                                <th onClick={() => handleSort('Matricule')}
                                  scope="col"
                                  class=" py-4 px-2 border-l text-left cursor-pointer relative text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                                >
                                  Nom & Prenom
                                  {sortedColumn === 'Matricule' && <span className="pr-0 absolute right-2">{sortOrder === 'asc' ? '▲' : '▼'}</span>}

                                </th>

                                <th onClick={() => handleSort('Matricule')}
                                  scope="col"
                                  class=" py-4 px-2 border-l text-left cursor-pointer relative text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                                >
                                  Matricule
                                  {sortedColumn === 'Matricule' && <span className="pr-0 absolute right-2">{sortOrder === 'asc' ? '▲' : '▼'}</span>}

                                </th>


                                <th onClick={() => handleSort('Matricule')}
                                  scope="col"
                                  class=" py-4 px-2 border-l text-left cursor-pointer relative text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                                >
                                  Date du lettre
                                  {sortedColumn === 'Matricule' && <span className="pr-0 absolute right-2">{sortOrder === 'asc' ? '▲' : '▼'}</span>}

                                </th>
                                <th onClick={() => handleSort('date')}
                                  scope="col"
                                  class="  py-4 px-2 border-l flex-shrink-0  cursor-pointer relative  text-left text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                                >
                                  Date du Repondu
                                  {sortedColumn === 'date' && <span className="pr-0 absolute right-2">{sortOrder === 'asc' ? '▲' : '▼'}</span>}

                                </th>
                              </tr>
                            </thead>
                            <tbody class="bg-white">
                              {repdemandeexprications.map((data, index) => {
                                return (<tr key={index}>
                                  <td class="border-b border-gray-200  p-2  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                    {highlightText(data.demandeexprication.staff.nomcomplet.toString(), inputValue)}
                                  </td>

                                  <td class="border-b border-gray-200  p-2  border-l whitespace-nowrap text-lg first-letter:uppercase  text-gray-500">
                                    {highlightText(data.demandeexprication.staff.matricule.toString(), inputValue)}
                                  </td>
                                  <td class="border-b border-gray-200  p-2  border-l whitespace-nowrap text-lg first-letter:uppercase  text-gray-500">
                                    {highlightText(formatDate(data.demandeexprication.createdAt), inputValue)}
                                  </td>
                                  <td class="border-b border-gray-200  p-2  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">

                                    {highlightText(formatDate(data.createdAt), inputValue)}
                                  </td>
                                </tr>
                                )
                              })}
                              {repdemandeexprications.length == 0 &&
                                <tr>
                                  <td colSpan={7} className="h-20 w-full text-center text-2xl text-gray-400"> Aucun élément correspondant à <span className="font-bold">{inputValue}</span> </td>
                                </tr>}
                            </tbody>
                          </table>

                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FootterCopy />
      </div>
    </>
  );
}

export default DocClasseur;
