import React, { useState } from "react";
import SideBarSecretaire from "../SideBarSecretaire/SideBarSecretaire";

import DemandeReponse from "./DemandeReponse";
function DemandeReponseComponent({ getSizeMobille, getMenuMobille }) {
  return (
    <>
      {" "}
      <div class="flex overflow-hidden bg-white justify-between">
        <SideBarSecretaire getMenuMobille={getMenuMobille} />
        <DemandeReponse getSizeMobille={getSizeMobille} />
      </div>
    </>
  );
}

export default DemandeReponseComponent;
