import React from 'react'
import LaboratoireDistri from './LaboratoireDistri'
import SlideNavInfermie from '../SlideNavInfermie/SlideNavInfermie'
function LaboratoireDistriComponent({ getSizeMobille, getMenuMobille }) {
    return (
        <div className='flex justify-between overflow-hidden bg-white'>
            <SlideNavInfermie getMenuMobille={getMenuMobille} />
            <LaboratoireDistri getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default LaboratoireDistriComponent
