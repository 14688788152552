import React, { useState } from "react";
import EmprunterVisitorComponent from "../../component/Bibliotheque/Emprunter/EmprunterVisitorComponent";
function EmprunterVisitorPage({getSizeMobille,getMenuMobille}) {
  return (
    <>
      <EmprunterVisitorComponent getMenuMobille={getMenuMobille} getSizeMobille={getSizeMobille} />
    </>
  );
}

export default EmprunterVisitorPage;
