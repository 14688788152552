import React from 'react'
import LatrinePreftDisplin from './LatrinePreftDisplin'
import SideBarsPrefetDisplin from '../SideBarsPrefetDisplin/SideBarsPrefetDisplin'
function LatrinePreftDisplinComponent({ getMenuMobille, getSizeMobille }) {
    return (
        <div className='flex justify-between overflow-hidden bg-white'>
            <SideBarsPrefetDisplin getMenuMobille={getMenuMobille} />
            <LatrinePreftDisplin getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default LatrinePreftDisplinComponent
