/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable eqeqeq */
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import FootterCopy from "../../FootterCopy/FootterCopy";
function DetailMaterielEncadrement({ getSizeMobille }) {
    const [mobile1, Setmonile1] = useState(window.innerWidth < 990)
    const [mobile2, Setmonile2] = useState(window.innerWidth < 520)

    useEffect(() => {
        const hundlesSize = () => {
            Setmonile1(window.innerWidth < 990)
            Setmonile2(window.innerWidth < 520)
        }
        window.addEventListener('resize', hundlesSize)
        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])



    const [mobile11, SetMobile11] = useState(window.innerWidth < 501)

    useEffect(() => {
        const hundlesSize = () => {
            SetMobile11(window.innerWidth < 501)
        }
        window.addEventListener('resize', hundlesSize)
        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])

    return (
        <>
            <div className={`bg-white select-none  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
                <Link to='/Materiel/Emprunt' className="ml-2 text-[18px] text-blue-500 hover:text-blue-800 underline">Retour</Link>
                <div className="min-h-[90vh]">
                    <div className="sm:p-6 p-2">
                        <div class="w-full my-4 ">
                            <div class="bg-white border relative overflow-hidden rounded-lg sm:p-4 p-2 ">
                                <div class={`flex items-center justify-between ${mobile2 ? 'flex-col' : ''}`}>
                                    <div>
                                        <h3 class="sm:text-xl font-bold text-gray-900">
                                            Détails du matériel
                                        </h3>
                                    </div>
                                </div>
                                <div class="flex flex-col ">
                                    <div class="bg-gray-100  flex items-center justify-center">
                                        <div class="bg-white p-2 sm:p-8 rounded-lg  h-full w-full">
                                            <div class="mb-4 flex items-center">
                                                <label class="sm:text-[20px] text-[13px] font-semibold  block">Responsable:</label>
                                                <p class="sm:text-[18px] text-[11px] text-gray-600 ml-2 ">Camille Sanchez</p>
                                            </div>
                                            <div class="mb-4 flex items-center">
                                                <label class="sm:text-[20px] text-[13px] font-semibold  block">Classe:</label>
                                                <p class="sm:text-[18px] text-[11px] ml-2 ">8em</p>
                                            </div>
                                            <div class="mb-4 flex items-center">
                                                <label class="sm:text-[20px] text-[13px] font-semibold  block">Fonction:</label>
                                                <p class="sm:text-[18px] text-[11px] ml-2 ">Econome</p>
                                            </div>
                                            <div class="mb-4 flex items-center">
                                                <label class="sm:text-[20px] text-[13px] font-semibold  block">Nom du matériel:</label>
                                                <p class="sm:text-[18px] text-[11px] ml-2">Appareil photo</p>
                                            </div>

                                            <div class="mb-6 flex items-center">
                                                <label class="sm:text-[20px] text-[13px] font-semibold  block">Quantite emprunt:</label>
                                                <div>
                                                    <span class="text-[18px] font-semibold text-green-500 ml-2 ">2</span>
                                                </div>
                                            </div>
                                            <div className="mt-10">
                                                <Link to='/Materiel/Emprunt/Modifier' className="bg-blue-200 text-blue-700 mx-1 py-1 px-2 rounded">Modifier</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FootterCopy />
            </div>

        </>
    );
}

export default DetailMaterielEncadrement;





