import React from 'react'
import DemandeBiblioDetail from './DemandeBiblioDetail'
import SideBarBibliote from '../SideBar/SideBarBibliote'
function DemandeBiblioDetailComponent({ getSizeMobille, getMenuMobille }) {

    return (
        <div className='flex justify-between overflow-hidden bg-white'>
            <SideBarBibliote getMenuMobille={getMenuMobille} />
            <DemandeBiblioDetail getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default DemandeBiblioDetailComponent
