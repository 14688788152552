import React from 'react'
import RentreEncadrementTrimestre from './RentreEncadrementTrimestre'
import SlideEncadrementNavBars from '../SlideEncadrementNavBars/SlideEncadrementNavBars'
function RentreEncadrementComponentTrimestre({ getSizeMobille, getMenuMobille }) {
    return (
        <div className='flex justify-between overflow-hidden bg-white'>
            <SlideEncadrementNavBars getMenuMobille={getMenuMobille} />
            <RentreEncadrementTrimestre getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default RentreEncadrementComponentTrimestre
