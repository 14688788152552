import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import FootterCopy from "../../FootterCopy/FootterCopy";
import { API } from "../../../Api/API";
function Laboratoire({ getSizeMobille }) {


    const [mobile1, setMobile1] = useState(window.innerWidth < 621);
    const [mobile2, setMobile2] = useState(window.innerWidth < 684);
    const [mobile11, setMobile11] = useState(window.innerWidth < 501);

    useEffect(() => {
        const handleSize = () => {
            setMobile1(window.innerWidth < 621);
            setMobile2(window.innerWidth < 684);
            setMobile11(window.innerWidth < 501);
        }

        window.addEventListener('resize', handleSize);

        return () => {
            window.removeEventListener('resize', handleSize);
        }
    }, []);
    const [consultations, setConsultations] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");
    const [pageSize, setPageSize] = useState(10);
    const [pageInput, setPageInput] = useState("");



    useEffect(() => {
        fetchConsultations();
    }, [currentPage, pageSize]);

    const fetchConsultations = async () => {
        try {
            const response = await API.get("/INF/ConsultationEleves", {
                params: { search: searchTerm, page: currentPage, size: pageSize }
            });
            setConsultations(response.data.consultations);
            setTotalPages(response.data.totalPages);
        } catch (error) {
            console.error("Error fetching Consultation:", error);
        }
    };

    const handleSearch = () => {
        setCurrentPage(1);
        fetchConsultations();
    };
    const handlePageSizeChange = (e) => {
        setPageSize(parseInt(e.target.value));
        setCurrentPage(1);
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage((prevPage) => prevPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage((prevPage) => prevPage - 1);
        }
    };

    const handlePageClick = (page) => {
        setCurrentPage(page);
    };

    const handlePageInputChange = (e) => {
        setPageInput(e.target.value);
    };

    const handleGoToPage = () => {
        const pageNumber = parseInt(pageInput);
        if (pageNumber >= 1 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };
    const generatePageNumbers = () => {
        const maxPagesToShow = 5; // Maximum number of page buttons to show
        const pages = [];
        let startPage = 1;
        let endPage = totalPages;

        if (totalPages > maxPagesToShow) {
            const offset = Math.floor(maxPagesToShow / 2);
            startPage = currentPage - offset;
            endPage = currentPage + offset;

            if (startPage < 1) {
                startPage = 1;
                endPage = maxPagesToShow;
            } else if (endPage > totalPages) {
                endPage = totalPages;
                startPage = totalPages - maxPagesToShow + 1;
            }
        }

        // Ajout des points de suspension pour les premières pages
        if (startPage > 1) {
            pages.push(
                <button
                    key={1}
                    onClick={() => handlePageClick(1)}
                    className={`py-2 px-3 mx-1 bg-yellow-200 hover:bg-yellow-300 text-yellow-800 font-semibold rounded-lg`}
                >
                    {1}
                </button>
            );
            if (startPage > 2) {
                pages.push(<span key="dots1">...</span>);
            }
        }

        for (let i = startPage; i <= endPage; i++) {
            pages.push(
                <button
                    key={i}
                    onClick={() => handlePageClick(i)}
                    className={`py-2 px-3 mx-1    font-semibold rounded-lg ${currentPage === i ? "bg-yellow-700 text-white" : "hover:bg-yellow-300 bg-yellow-200 text-yellow-800"}`}
                >
                    {i}
                </button>
            );
        }

        // Ajout des points de suspension pour les dernières pages
        if (endPage < totalPages) {
            if (endPage < totalPages - 1) {
                pages.push(<span key="dots2">...</span>);
            }
            pages.push(
                <button
                    key={totalPages}
                    onClick={() => handlePageClick(totalPages)}
                    className={`py-2 px-3 mx-1 bg-yellow-200 hover:bg-yellow-300 text-yellow-800 font-semibold rounded-lg`}
                >
                    {totalPages}
                </button>
            );
        }

        return pages;
    };

    return (
        <>
            <div className={`bg-white overflow-y-auto overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'} ${getSizeMobille ? 'w-full' : 'w-[85vw]'}`}>
                <div className="md:p-6 p-2  min-h-[85vh]">
                    <div class="my-1">
                        <div class="w-full flex flex-row items-center justify-between">
                            <p class="text-navy-700 shrink  ">
                                <p class="hover:text-navy-700 text-gray-700 uppercase sm:text-3xl font-bold"                                >
                                    Laboratoire
                                </p>
                            </p>
                        </div>
                    </div>
                    <div class="sm:flex items-center pr-4  h-max justify-end">

                        <div class="mx-2">
                            <div class="relative lg:w-64">
                                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <svg
                                        class="w-5 h-5 text-gray-500"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                            clip-rule="evenodd"
                                        ></path>
                                    </svg>
                                </div>
                                <input
                                    type="text"
                                    name="searchTerm"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    id="topbar-search"
                                    class="bg-white border border-gray-300 h-10 text-gray-900 sm:text-lg first-letter:uppercase rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full pl-10 .5"
                                    placeholder="Rechercher..."
                                />
                            </div>
                        </div>
                        <button
                            onClick={handleSearch}
                            class="mt-4 sm:mt-0 inline-flex items-start justify-start px-1 py-3 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg"
                        >
                            <p class="text-lg first-letter:uppercase font-semibold leading-none text-white">
                                Rerchercher
                            </p>
                        </button>
                    </div>
                    <div class="w-full my-2 grid grid-cols-1 ">
                        <div class="bg-white  rounded-lg p-4 sm:p-6 xl:p-8">
                            <div class={`mb-2 flex items-center  justify-between`}>
                                <div className="w-full flex justify-center">
                                    <h3 class="text-xl font-bold text-gray-600 mb-2">
                                        Les patients attendent des médicaments
                                    </h3>
                                </div>
                            </div>
                            <div class="bg-gray-200 flex flex-col mb-5 w-full border border-gray-200 focus:border-cyan-600 rounded-xl">
                                <div class="rounded-lg   overflow-x-auto  flex md:justify-center">
                                    <div class={`bg-gray-200 p-2 rounded-xl sm:w-full `}>
                                        <div>
                                            <select
                                                value={pageSize}
                                                onChange={handlePageSizeChange}
                                                class="bg-white border border-gray-300 h-10 text-gray-900 sm:text-lg first-letter:uppercase rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block"
                                            >
                                                <option value={1}>1 par page</option>
                                                <option value={5}>5 par page</option>
                                                <option value={10}>10 par page</option>
                                                <option value={20}>20 par page</option>
                                            </select>
                                        </div>
                                        <div className="flex justify-center text-blue-700 text-[25px] font-bold">
                                            Elèves
                                        </div>
                                        <div className="h-[1px] bg-white w-full mb-3"></div>
                                        <div class={`overflow-hidden rounded-lg w-full ${mobile2 ? 'w-[41em]' : 'w-full'} `}>
                                            <table class="min-w-full   w-full">
                                                <thead class="bg-gray-50">
                                                    <tr>
                                                        <th
                                                            scope="col"
                                                            class=" py-4 px-2 border-l text-left cursor-pointer relative text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                                                        >
                                                            N° de consultation
                                                        </th>

                                                        <th
                                                            scope="col"
                                                            class=" py-4 px-2 border-l text-left cursor-pointer relative text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                                                        >
                                                            Matricule
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            class=" py-4 px-2 border-l text-left cursor-pointer relative text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                                                        >
                                                            Action
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody class="bg-white">
                                                    {consultations && consultations.length > 0 && consultations.map(consultation => (
                                                        <tr key={consultation.id}>
                                                            <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                                {consultation.id}
                                                            </td>
                                                            <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                                {consultation.matricule}
                                                            </td>
                                                            <td class="border-b border-gray-200  pt-2 px-3  border-l border-r whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                                <Link
                                                                    to="/Laboratoire/Destrubuer"
                                                                    class="my-2 sm:mt-0 inline-flex items-start justify-start px-3 py-2 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg"
                                                                >
                                                                    <p class="text-lg first-letter:uppercase font-semibold leading-none text-white">
                                                                        Traitement
                                                                    </p>
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex justify-between mt-4 flex-wrap">
                                    <button
                                        onClick={handlePrevPage}
                                        disabled={currentPage === 1}
                                        className="py-2 px-4 bg-yellow-300 hover:bg-yellow-400 text-yellow-800 font-semibold rounded-lg disabled:opacity-50 mb-2 sm:mb-0"
                                    >
                                        Précédent
                                    </button>
                                    <div className="flex flex-wrap">{generatePageNumbers()}</div>
                                    <button
                                        onClick={handleNextPage}
                                        disabled={currentPage === totalPages}
                                        className="py-2 px-4 bg-yellow-300 hover:bg-yellow-400 text-yellow-800 font-semibold rounded-lg disabled:opacity-50 mb-2 sm:mb-0"
                                    >
                                        Suivant
                                    </button>
                                </div>
                                <div class="flex items-center mt-4 ">
                                    <input
                                        type="number"
                                        min='1'
                                        max={totalPages}
                                        value={pageInput}
                                        onChange={handlePageInputChange}
                                        class="bg-white text-center border border-gray-300 h-10 text-gray-900 sm:text-lg first-letter:uppercase rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-20 mr-2"
                                        placeholder={`1 - ${totalPages}`}
                                    />
                                    <button
                                        onClick={handleGoToPage}
                                        class="py-2 px-4 bg-blue-700 hover:bg-blue-600 text-white font-semibold rounded-lg"
                                    >
                                        Aller
                                    </button>
                                </div>
                            </div>
                            <div class="flex flex-col mb-5 w-full">
                                <div class="rounded-lg   overflow-x-auto  flex md:justify-center">
                                    <div class={`bg-gray-200 p-2 rounded-xl sm:w-full `}>
                                        <div className="flex justify-center text-blue-700 text-[25px] font-bold">
                                            Personnel
                                        </div>
                                        <div className="h-[1px] bg-white w-full mb-3"></div>
                                        <div class={`overflow-hidden rounded-lg w-full ${mobile2 ? 'w-[41em]' : 'w-full'} `}>
                                            <table class="min-w-full   w-full">
                                                <thead class="bg-gray-50">
                                                    <tr>
                                                        <th
                                                            scope="col"
                                                            class=" py-4 px-2 border-l text-left cursor-pointer relative text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                                                        >
                                                            N° de consultation
                                                        </th>

                                                        <th
                                                            scope="col"
                                                            class=" py-4 px-2 border-l text-left cursor-pointer relative text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                                                        >
                                                            Matricule
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            class=" py-4 px-2 border-l text-left cursor-pointer relative text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                                                        >
                                                            Action
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody class="bg-white">
                                                    <tr>
                                                        <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                            8GF0
                                                        </td>
                                                        <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                            20/03790
                                                        </td>

                                                        <td class="border-b border-gray-200  pt-2 px-3  border-l border-r whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                            <Link
                                                                to="/Laboratoire/Destrubuer"
                                                                class="my-2 sm:mt-0 inline-flex items-start justify-start px-3 py-2 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg"
                                                            >
                                                                <p class="text-lg first-letter:uppercase font-semibold leading-none text-white">
                                                                    Traitement
                                                                </p>
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                            8GF0
                                                        </td>
                                                        <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                            20/03790
                                                        </td>

                                                        <td class="border-b border-gray-200  pt-2 px-3  border-l border-r whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                            <Link
                                                                to="/Laboratoire/Destrubuer"
                                                                class="my-2 sm:mt-0 inline-flex items-start justify-start px-3 py-2 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg"
                                                            >
                                                                <p class="text-lg first-letter:uppercase font-semibold leading-none text-white">
                                                                    Traitement
                                                                </p>
                                                            </Link>
                                                        </td>
                                                    </tr>  <tr>
                                                        <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                            8GF0
                                                        </td>
                                                        <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                            20/03790
                                                        </td>

                                                        <td class="border-b border-gray-200  pt-2 px-3  border-l border-r whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                            <Link
                                                                to="/Laboratoire/Destrubuer"
                                                                class="my-2 sm:mt-0 inline-flex items-start justify-start px-3 py-2 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg"
                                                            >
                                                                <p class="text-lg first-letter:uppercase font-semibold leading-none text-white">
                                                                    Traitement
                                                                </p>
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div><div class="flex flex-col mb-5 w-full">
                                <div class="rounded-lg   overflow-x-auto  w-full  flex md:justify-center">
                                    <div class={`bg-gray-200 p-2 rounded-xl sm:w-full `}>
                                        <div className="flex justify-center text-blue-700 text-[25px] font-bold">
                                            Particulière
                                        </div>
                                        <div className="h-[1px] bg-white w-full mb-3"></div>
                                        <div class={`overflow-hidden rounded-lg ${mobile2 ? 'w-[41em]' : 'w-full'} `}>
                                            <table class="min-w-full">
                                                <thead class="bg-gray-50">
                                                    <tr>
                                                        <th
                                                            scope="col"
                                                            class=" py-4 px-2 border-l text-left cursor-pointer relative text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                                                        >
                                                            N° de consultation
                                                        </th>

                                                        <th
                                                            scope="col"
                                                            class=" py-4 px-2 border-l text-left cursor-pointer relative text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                                                        >
                                                            Nom
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            class=" py-4 px-2 border-l text-left cursor-pointer relative text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                                                        >
                                                            Prénom
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            class=" py-4 px-2 border-l text-left cursor-pointer relative text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                                                        >
                                                            Action
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody class="bg-white">
                                                    <tr>
                                                        <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                            8GF0
                                                        </td>
                                                        <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                            KAMANA
                                                        </td>
                                                        <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                            Joselyne
                                                        </td>

                                                        <td class="border-b border-gray-200  pt-2 px-3  border-l border-r whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                            <Link
                                                                to="/Laboratoire/Destrubuer"
                                                                class="my-2 sm:mt-0 inline-flex items-start justify-start px-3 py-2 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg"
                                                            >
                                                                <p class="text-lg first-letter:uppercase font-semibold leading-none text-white">
                                                                    Traitement
                                                                </p>
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                            8GF0
                                                        </td>
                                                        <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                            MAMI
                                                        </td>
                                                        <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                            Joselyne
                                                        </td>
                                                        <td class="border-b border-gray-200  pt-2 px-3  border-l border-r whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                            <Link
                                                                to="/Laboratoire/Destrubuer"
                                                                class="my-2 sm:mt-0 inline-flex items-start justify-start px-3 py-2 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg"
                                                            >
                                                                <p class="text-lg first-letter:uppercase font-semibold leading-none text-white">
                                                                    Traitement
                                                                </p>
                                                            </Link>
                                                        </td>
                                                    </tr>  <tr>
                                                        <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                            8GF0
                                                        </td>
                                                        <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                            KEZA
                                                        </td>
                                                        <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                            Joselyne
                                                        </td>
                                                        <td class="border-b border-gray-200  pt-2 px-3  border-l border-r whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                            <Link
                                                                to="/Laboratoire/Destrubuer"
                                                                class="my-2 sm:mt-0 inline-flex items-start justify-start px-3 py-2 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg"
                                                            >
                                                                <p class="text-lg first-letter:uppercase font-semibold leading-none text-white">
                                                                    Traitement
                                                                </p>
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FootterCopy />
            </div >
        </>
    );
}

export default Laboratoire;
