import React from 'react'
import PrevisionPrefetDisplin from './PrevisionPrefetDisplin'
import SideBarsPrefetDisplin from '../SideBarsPrefetDisplin/SideBarsPrefetDisplin'
function PrevisionPrefetDisplinComponent() {
    return (
        <div className='flex justify-between overflow-hidden bg-white'>
            <SideBarsPrefetDisplin />
            <PrevisionPrefetDisplin />
        </div>
    )
}

export default PrevisionPrefetDisplinComponent
