/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import FootterCopy from '../../FootterCopy/FootterCopy'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { FadeLoader } from 'react-spinners'
import { decryptData } from '../../../encryptionModule'
import { API } from '../../../Api/API'

function ModifierRefectoirePrefetDisplin({ getSizeMobille }) {
    const [mobile11, SetMobile11] = useState(window.innerWidth < 501)

    useEffect(() => {
        const hundlesSize = () => {
            SetMobile11(window.innerWidth < 501)
        }
        window.addEventListener('resize', hundlesSize)
        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])
    const [mobile, SetMobile] = useState(window.innerWidth <= 640)
    const [boutLoading, setboutLoading] = useState(false)

    useEffect(() => {
        const hundleSize = () => {
            SetMobile(window.innerWidth <= 640)
        }
        window.addEventListener('resize', hundleSize)

        return () => {
            window.removeEventListener('resize', hundleSize)
        }
    }, [])

    const { id } = useParams()
    const RefId = decryptData(id)
    const [numero, setNumero] = useState('')
    const [Effectifs, setEffectifs] = useState('')
    const [table, setTable] = useState('')
    useEffect(() => {
        API.get(`/SM/getOneById/${RefId}`)
            .then((rep) => {
                setEffectifs(rep.data.effectstudent)
                setNumero(rep.data.numero)
                setTable(rep.data.table)
            })
            .catch((err) => { })
    }, [id])


    const numeroRef = useRef(null);
    const EffectifsRef = useRef(null);
    const tableRef = useRef(null);

    const highlightInvalidFields = () => {
        if (!validateInteger(numero)) {
            numeroRef.current.style.backgroundColor = 'lightcoral';
        }
        if (!validateInteger(Effectifs)) {
            EffectifsRef.current.style.backgroundColor = 'lightcoral';
        }
        if (!validateInteger(table)) {
            tableRef.current.style.backgroundColor = 'lightcoral';
        }

        setTimeout(() => {
            numeroRef.current.style.backgroundColor = '';
            EffectifsRef.current.style.backgroundColor = '';
            tableRef.current.style.backgroundColor = '';
        }, 5000);
    };

    const navigate = useNavigate()
    const validateInteger = (value) => {
        return /^\d+$/.test(value);
    };
    const handleSubmit = (e) => {
        e.preventDefault();

        if (!validateInteger(numero) || !validateInteger(Effectifs) || !validateInteger(table)) {
            toast.error('Tous les champs doivent être des entiers.');
            highlightInvalidFields();
            return;
        }

        const data = {
            numero: parseInt(numero),
            effectstudent: parseInt(Effectifs),
            table: parseInt(table)
        };

        setboutLoading(true);

        API.put(`/SM/update/${RefId}`, data)
            .then((response) => {
                toast.success('Les données ont été modifiées avec succès.');
                setboutLoading(false);
                navigate('/Refectoire')
            })
            .catch((error) => {
                if (error.response && error.response.data) {
                    toast.error(error.response.data.error);
                } else {
                    toast.error('Une erreur est survenue lors de la modification des données.');
                }
                setboutLoading(false);
            });


    };

    return (
        <div
            className={`bg-white  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'
                }   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}
        >
            <Link
                to="/Refectoire"
                className="ml-2 text-[18px] text-blue-500 hover:text-blue-800 underline"
            >
                Retour
            </Link>

            <div className="sm:p-4 p-2 min-h-screen">
                <div class="my-5">
                    <div class="w-full flex flex-row items-center justify-between">
                        <p class="text-navy-700   ">
                            <a class="hover:text-navy-700 text-2xl font-bold">
                                Modification le salle à manger
                            </a>
                        </p>
                    </div>
                </div>
                <div className="w-full my-4 grid grid-cols-1">
                    <div className="w-full my-4 grid grid-cols-1">
                        <form
                            onSubmit={handleSubmit}
                            className="flex flex-col items-center"
                        >
                            <div className="rounded-lg p-2 border border-blue-500 md:w-[40em] w-[95%] sm:p-4">
                                <div className="justify_center rounded-xl w-full  p-1 sm:p-2 mb-10  border-[1px] flex flex-col">
                                    <div className="mb-2">
                                        <div className="rounded-xl p-2 w-full">
                                            <div className="flex flex-col">
                                                <div className="rounded-lg w-full">
                                                    <div class="w-full mb-5">
                                                        <label
                                                            class="block  tracking-wide text-grey-darker text-lg  mb-2"
                                                            for="grid-Title"
                                                        >
                                                            Numéro de salle
                                                        </label>
                                                        <input
                                                            ref={numeroRef}
                                                            value={numero}
                                                            class="appearance-none block w-full bg-grey-lighter text-grey-darker border border-red rounded py-3 px-4 mb-3"
                                                            placeholder="Numéro de salle..."
                                                            onChange={(event) => setNumero(event.target.value)}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="rounded-lg w-full">
                                                    <div class="w-full mb-5">
                                                        <label
                                                            class="block  tracking-wide text-grey-darker text-lg  mb-2"
                                                            for="grid-Title"
                                                        >
                                                            Nombres des tables
                                                        </label>
                                                        <input
                                                            ref={tableRef}
                                                            value={table}
                                                            class="appearance-none block w-full bg-grey-lighter text-grey-darker border border-red rounded py-3 px-4 mb-3"
                                                            placeholder="Nombres des tables..."
                                                            onChange={(event) => setTable(event.target.value)}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="rounded-lg w-full">
                                                    <div class="w-full mb-5">
                                                        <label
                                                            class="block  tracking-wide text-grey-darker text-lg  mb-2"
                                                            for="grid-Title"
                                                        >
                                                            Effectifs par table
                                                        </label>
                                                        <input
                                                            ref={EffectifsRef}
                                                            value={Effectifs}
                                                            className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-red rounded py-3 px-4 mb-3"
                                                            translate="text"
                                                            placeholder="Effectifs par table..."
                                                            onChange={(event) => setEffectifs(event.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className=" flex justify-end  md:w-[40em] w-[95%]">
                                {boutLoading ? (
                                    <>
                                        <label
                                            disabled
                                            className="cursor-no-drop w-max relative  mt-3 flex justify-center  items-center   bg-green-950    p-2 rounded  text-gray-400"
                                        >
                                            <input
                                                numero_salle="submit"
                                                id="send"
                                                value="Soumettre"
                                                className="pointer-events-none"
                                            />
                                            <i class="bi bi-send ml-2  pointer-events-none "></i>
                                            <div
                                                disabled
                                                className="absolute pointer-events-none  bg-transparent pt-4  pl-4  w-full h-full flex justify-center items-center z-50"
                                            >
                                                <FadeLoader
                                                    color="rgb(255, 255, 255)"
                                                    height={10}
                                                    margin={-9}
                                                    radius={100}
                                                    speedMultiplier={1}
                                                    width={1}
                                                />
                                            </div>
                                        </label>
                                    </>
                                ) : (
                                    <>
                                        <label
                                            for="send"
                                            className=" mt-3 cursor-pointer w-max  flex justify-end  bg-green-600   p-2 rounded  text-white"
                                        >
                                            <input
                                                type="submit"
                                                id="send"
                                                value="Modifier"
                                                className="cursor-pointer"
                                            ></input>
                                            <i class="bi bi-send ml-2 "></i>
                                        </label>
                                    </>
                                )}
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <FootterCopy />
        </div>
    )
}

export default ModifierRefectoirePrefetDisplin
