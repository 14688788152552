import React, { useState } from "react";
import DetailRecusComponent from "../../component/Secretariat/Courriers/Recus/DetailRecusComponent";
function DetailRecusPage({ getSizeMobille, getMenuMobille }) {
  return (
    <>
      <DetailRecusComponent getMenuMobille={getMenuMobille} getSizeMobille={getSizeMobille} />
    </>
  );
}

export default DetailRecusPage;
