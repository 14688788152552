/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import FootterCopy from '../../FootterCopy/FootterCopy'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Select from 'react-select'
import { FadeLoader } from 'react-spinners'
import { API } from '../../../Api/API'

function AddMaterielPrefetDisplin({ getSizeMobille }) {
  const history = useNavigate()
  const [mobile11, SetMobile11] = useState(window.innerWidth < 501)
  useEffect(() => {
    const hundlesSize = () => {
      SetMobile11(window.innerWidth < 501)
    }
    window.addEventListener('resize', hundlesSize)
    return () => {
      window.removeEventListener('resize', hundlesSize)
    }
  }, [])

  const [mobile, SetMobile] = useState(window.innerWidth <= 640)
  const [eledemmade, GeteleDemmande] = useState([])
  const [boutLoading, setboutLoading] = useState(false)

  useEffect(() => {
    const hundleSize = () => {
      SetMobile(window.innerWidth <= 640)
    }
    window.addEventListener('resize', hundleSize)

    return () => {
      window.removeEventListener('resize', hundleSize)
    }
  }, [])

  const OptionMateriel = [
    { value: '', label: '' },
    { value: 'Lit', label: 'Lit' },
    { value: 'Matelas', label: 'Matelas' },
    { value: 'Tables', label: 'Tables' },
    { value: 'Bas pupitre', label: 'Bas pupitre' },
    { value: 'Balai', label: 'Balai' },
    { value: 'Lacrette', label: 'Lacrette' },
    { value: 'Houes', label: 'Houes' },
    { value: 'Coupe-coupe', label: 'Coupe-coupe' },
    { value: 'Télévision', label: 'Télévision' },
    { value: 'Radio', label: 'Radio' },
    { value: 'Bureau', label: 'Bureau' },
    { value: 'Chaise', label: 'Chaise' },
    { value: 'Armoire', label: 'Armoire' },
    { value: 'Cadenas', label: 'Cadenas' },
    { value: 'Étagère', label: 'Étagère' },
    { value: 'Moustiquaire', label: 'Moustiquaire' },
    { value: 'Ventilateur', label: 'Ventilateur' },
    { value: 'Lampe', label: 'Lampe' },
    { value: 'Poubelle', label: 'Poubelle' },
    { value: 'Brosse à dents', label: 'Brosse à dents' },
    { value: 'Papier hygiénique', label: 'Papier hygiénique' },
    { value: 'Serviette', label: 'Serviette' },
    { value: 'Savon', label: 'Savon' },
    { value: 'Seau', label: 'Seau' },
    { value: 'Miroir', label: 'Miroir' },
    { value: "Bouteille d'eau", label: "Bouteille d'eau" },
    { value: 'Sacoche', label: 'Sacoche' },
    { value: 'Oreiller', label: 'Oreiller' },
    { value: 'Couverture', label: 'Couverture' },
    { value: 'Cadenas', label: 'Cadenas' },
    { value: 'Casseroles', label: 'Casseroles' },
    { value: 'Poêles', label: 'Poêles' },
    { value: 'Assiettes', label: 'Assiettes' },
    { value: 'Couverts', label: 'Couverts' },
    { value: 'Verres', label: 'Verres' },
    { value: 'Bouilloire', label: 'Bouilloire' },
    { value: 'Réfrigérateur', label: 'Réfrigérateur' },
    { value: 'Machine à laver', label: 'Machine à laver' },
    { value: 'Ordinateur', label: 'Ordinateur' },
    { value: 'Imprimante', label: 'Imprimante' },
  ]
  const OptionTypeMateriel = [
    { value: '', label: '' },
    { value: 'Mobilier ', label: 'Mobilier ' },
    { value: 'Équipements de cuisine', label: 'Équipements de cuisine' },
    { value: 'Outils de nettoyage', label: 'Outils de nettoyage' },
    { value: 'Équipements électroniques', label: 'Équipements électroniques' },
    { value: 'Équipements de jardinage', label: 'Équipements de jardinage' },
    { value: 'Articles de literie', label: 'Articles de literie' },
    { value: 'Équipements de loisirs', label: 'Équipements de loisirs' },
    { value: 'Équipements de sécurité', label: 'Équipements de sécurité' },
    { value: 'Articles de décoration', label: 'Articles de décoration' },
  ]

  const hiddenSOptionMateriel = OptionMateriel.slice(1)
  const hiddenOptionTypeMateriel = OptionTypeMateriel.slice(1)

  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: mobile ? 25 : 50,
      backgroundColor: 'white',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? 'blue'
        : state.isFocused
        ? 'lightblue'
        : 'white',
      color: state.isSelected ? 'white' : 'black',
    }),
  }

  const [blocs, setBlocs] = useState([
    {
      nomMateriel: '',
      typesMateriel: '',
      materielDisponible: '',
      Materilefonctionel: '',
    },
  ])
  const addBloc = () => {
    setBlocs([
      ...blocs,
      {
        nomMateriel: '',
        typesMateriel: '',
        materielDisponible: '',
        Materilefonctionel: '',
      },
    ])
  }
  const removeBloc = (index) => {
    const newBlocs = [...blocs]
    newBlocs.splice(index, 1)
    setBlocs(newBlocs)
  }
  const inputRefs = useRef([])
  if (inputRefs.current.length !== blocs.length) {
    inputRefs.current = Array(blocs.length)
      .fill({})
      .map((_, index) => ({
        nomMateriel: React.createRef(null),
        typesMateriel: React.createRef(null),
        materielDisponible: React.createRef(null),
        Materilefonctionel: React.createRef(null),
      }))
  }

  const handleInputChange = (index, field, value) => {
    const newBlocs = [...blocs]
    newBlocs[index][field] = value
    setBlocs(newBlocs)
    if (value && inputRefs.current[index][field].current) {
      inputRefs.current[index][field].current.classList.remove(
        'animate__animated',
        'animate__shakeX',
        'border-2',
        'border-red-500',
      )
    }
  }

  const animateAndScrollToRef = (ref) => {
    if (ref && ref.current) {
      ref.current.classList.add(
        'animate__animated',
        'animate__shakeX',
        'border-2',
        'border-red-500',
        'outline-none',
      )
      setTimeout(() => {
        ref.current.classList.remove(
          'animate__animated',
          'animate__shakeX',
          'border-2',
          'border-red-500',
          'outline-none',
        )
      }, 5000)
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
      ref.current.focus()
    }
  }

  const validateBloc = (bloc, index) => {
    const requiredFields = [
      'nomMateriel',
      'typesMateriel',
      'materielDisponible',
      'Materilefonctionel',
    ]

    //Validation pour les champs vide
    for (const field of requiredFields) {
      if (!bloc[field]) {
        toast.warning(
          <div>
            Le{' '}
            <strong>
              {field == 'nomMateriel'
                ? 'nom du matériel '
                : field == 'typesMateriel'
                ? 'type du matériel'
                : field == 'materielDisponible'
                ? 'matériel disponible'
                : field == 'Materilefonctionel'
                ? 'matériel fonctionel'
                : null}
            </strong>{' '}
            est obligatoire à la matériel {index + 1}.
          </div>,
        )
        if (inputRefs.current[index][field].current) {
          inputRefs.current[index][field].current.classList.add(
            'animate__animated',
            'animate__shakeX',
            'border-2',
            'border-red-500',
            'outline-none',
          )
          setTimeout(() => {
            inputRefs.current[index][field].current.classList.remove(
              'animate__animated',
              'animate__shakeX',
              'border-2',
              'border-red-500',
              'outline-none',
            )
          }, 3000)
          inputRefs.current[index][field].current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          })
          inputRefs.current[index][field].current.focus()
          return false
        }
      }
    }
    const dispos =
      parseInt(bloc.materielDisponible) < parseInt(bloc.Materilefonctionel)
    if (dispos) {
      toast.warning(
        <div>
          Le nombre de matériels disponibles ne doit pas être inférieur au
          nombre de matériels fonctionnels.
        </div>,
      )
      animateAndScrollToRef(inputRefs.current[index].materielDisponible)
      return false
    }

    //Validation pour eviter de sousmettre deux block de meme information
    const isdepluque = blocs.some(
      (b, i) => i !== index && b.nomMateriel.value === bloc.nomMateriel.value,
    )
    if (isdepluque) {
      const duplicateBlockIndex = blocs.findIndex(
        (b, i) => i !== index && b.nomMateriel.value === bloc.nomMateriel.value,
      )
      const duplicateBlockNumber = toRoman(duplicateBlockIndex + 1)
      toast.warning(
        <div>
          Évitez la redondance, le matériel{' '}
          <span className="font-bold">{toRoman(index + 1)}</span> et le matériel{' '}
          <span className="font-bold">{duplicateBlockNumber}</span> ont la même
          nom de matériel.
        </div>,
      )
      animateAndScrollToRef(inputRefs.current[index].nomMateriel)
      return false
    }

    return true
  }

  function toRoman(num) {
    const romanNumerals = [
      'I',
      'II',
      'III',
      'IV',
      'V',
      'VI',
      'VII',
      'VIII',
      'IX',
      'X',
      'XI',
      'XII',
      'XIII',
      'XIV',
      'XV',
      'XVI',
      'XVII',
      'XVIII',
      'XIX',
      'XX',
      'XXI',
      'XXII',
      'XXIII',
      'XXIV',
      'XXV',
      'XXVI',
      'XXVII',
      'XXVIII',
      'XXIX',
      'XXX',
    ]
    return romanNumerals[num - 1] || num.toString()
  }

  const Datas = blocs.map((bloc) => ({
    nom: bloc.nomMateriel.value,
    type: bloc.typesMateriel.value,
    nombredispo: bloc.materielDisponible,
    nombrefonctionnel: bloc.Materilefonctionel,
  }))

  const handleSubmit = (e) => {
    e.preventDefault()
    for (let index = 0; index < blocs.length; index++) {
      if (!validateBloc(blocs[index], index)) {
        return false
      }
    }

    API.post('/materielinternant/add', Datas)
      .then((response) => {
        // Si la requête est réussie, afficher un toast de succès
        toast.success('Données envoyées avec succès')
        history('/Materiel')
      })
      .catch((error) => {
        if (error.response) {
          // S'il y a une réponse du serveur, gérer les erreurs
          const errorData = error.response.data
          console.log(errorData)
          if (Array.isArray(errorData)) {
            // Afficher chaque erreur dans un toast
            toast.error(errorData[0])
            return false
          } else {
            toast.error(errorData || 'Erreur serveur')
            return false
          }
        } else {
          toast.error('Erreur serveur 1')
        }
      })
  }

  return (
    <div
      className={`bg-white  overflow-y-auto  overflow-x-hidden ${
        mobile11 ? 'h-[90vh]' : 'h-[85vh]'
      }   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}
    >
      <Link
        to="/Materiel"
        className="ml-2 text-[18px] text-blue-500 hover:text-blue-800 underline"
      >
        Retour
      </Link>
      <div className="sm:p-4 p-2 min-h-screen">
        <div class="my-5">
          <div class="w-full flex flex-row items-center justify-between">
            <p class="text-navy-700   ">
              <a class="hover:text-navy-700 text-2xl font-bold  ">
                Enregistrement des matériels
              </a>
            </p>
          </div>
        </div>
        <div className="w-full my-4 grid grid-cols-1">
          <div className="w-full my-4 grid grid-cols-1">
            <form
              onSubmit={handleSubmit}
              className="flex flex-col items-center"
            >
              <div className="rounded-lg p-2 border border-blue-500 md:w-[40em] w-[95%] sm:p-4">
                {blocs.map((bloc, index) => (
                  <div
                    key={index}
                    className="justify_center rounded-xl w-full  p-1 sm:p-2 mb-10  border-[1px] flex flex-col"
                  >
                    <div className="w-full">
                      <div className="h-max w-full">
                        <div className="px-3  w-full">
                          <div className="w-full flex items_center justify-between">
                            <label className="block  text-xs mt-5 font-serif text-gray-700 first_letter:uppercase tracking_wide text-grey-darker sm:text-lg  mb-1">
                              {toRoman(index + 1)}. Matériels
                            </label>
                            {index > 0 && (
                              <button
                                montant="button"
                                className="text-red-500 px-1 max-sm:text-xs py-2"
                                onClick={() => removeBloc(index)}
                              >
                                Supprimer
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mb-2">
                      <div className="rounded-xl p-2 w-full">
                        <div className="flex flex-col">
                          <div className="rounded-lg w-full">
                            <div class="w-full mb-5">
                              <label class="block  tracking-wide text-gray-700 text-xs sm:text-lg  mb-1">
                                Nom du matériels
                              </label>
                              <div
                                ref={inputRefs.current[index].nomMateriel}
                                className={` rounded `}
                              >
                                <Select
                                  value={bloc.nomMateriel}
                                  options={hiddenSOptionMateriel}
                                  styles={customStyles}
                                  onChange={(option) =>
                                    handleInputChange(
                                      index,
                                      'nomMateriel',
                                      option,
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="rounded-lg w-full">
                            <div class="w-full mb-5">
                              <label class="block  tracking-wide text-gray-700 text-xs sm:text-lg  mb-1">
                                Type du matériels
                              </label>
                              <div
                                ref={inputRefs.current[index].typesMateriel}
                                className={` rounded `}
                              >
                                <Select
                                  value={bloc.typesMateriel}
                                  options={hiddenOptionTypeMateriel}
                                  styles={customStyles}
                                  onChange={(option1) =>
                                    handleInputChange(
                                      index,
                                      'typesMateriel',
                                      option1,
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="rounded-lg w-full">
                            <div class="w-full mb-5">
                              <label
                                class="block  tracking-wide text-grey-darker text-lg  mb-2"
                                for="grid-Title"
                              >
                                Nombre disponible
                              </label>
                              <input
                                ref={
                                  inputRefs.current[index].materielDisponible
                                }
                                class="appearance-none block w-full bg-grey-lighter text-grey-darker border border-red rounded py-3 px-4 mb-3"
                                value={bloc.materielDisponible}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    'materielDisponible',
                                    e.target.value,
                                  )
                                }
                                placeholder="Nombre disponible..."
                              />
                            </div>
                          </div>
                          <div className="rounded-lg w-full">
                            <div class="w-full mb-5">
                              <label
                                class="block  tracking-wide text-grey-darker text-lg  mb-2"
                                for="grid-Title"
                              >
                                Nombre fonctionnel
                              </label>
                              <input
                                ref={
                                  inputRefs.current[index].Materilefonctionel
                                }
                                class="appearance-none block w-full bg-grey-lighter text-grey-darker border border-red rounded py-3 px-4 mb-3"
                                value={bloc.Materilefonctionel}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    'Materilefonctionel',
                                    e.target.value,
                                  )
                                }
                                placeholder="Nombre disponible..."
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                <div className="w-full flex justify-end">
                  <button
                    montant="button"
                    className="bg-blue-500 hover-bg-blue-700 text-white py-1 px-2  max-sm:text-xs sm:py-2 sm:px-4 rounded"
                    onClick={addBloc}
                  >
                    Ajouter matériel
                  </button>
                </div>
              </div>

              <div className=" flex justify-end  md:w-[40em] w-[95%]">
                {boutLoading ? (
                  <>
                    <label
                      disabled
                      className="cursor-no-drop w-max relative  mt-3 flex justify-center  items-center   bg-green-950    p-2 rounded  text-gray-400"
                    >
                      <input
                        type="submit"
                        id="send"
                        value="Soumettre"
                        className="pointer-events-none"
                      />
                      <i class="bi bi-send ml-2  pointer-events-none "></i>
                      <div
                        disabled
                        className="absolute pointer-events-none  bg-transparent pt-4  pl-4  w-full h-full flex justify-center items-center z-50"
                      >
                        <FadeLoader
                          color="rgb(255, 255, 255)"
                          height={10}
                          margin={-9}
                          radius={100}
                          speedMultiplier={1}
                          width={1}
                        />
                      </div>
                    </label>
                  </>
                ) : (
                  <>
                    <label
                      for="send"
                      className=" mt-3 cursor-pointer w-max  flex justify-end  bg-green-600   p-2 rounded  text-white"
                    >
                      <input
                        type="submit"
                        id="send"
                        value="Soumettre"
                        className="cursor-pointer"
                      ></input>
                      <i class="bi bi-send ml-2 "></i>
                    </label>
                  </>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>

      <FootterCopy />
    </div>
  )
}

export default AddMaterielPrefetDisplin
