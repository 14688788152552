/* eslint-disable eqeqeq */
import React, { useEffect, useRef, useState } from "react";
import FootterCopy from "../../FootterCopy/FootterCopy";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { API } from "../../../Api/API";
import { decryptData } from "../../../encryptionModule";

function PrevisionInfirmierModifier({ getSizeMobile }) {  // Correction: Nom de la prop corrigé
    const history = useNavigate()
    const [mobile1, setMobile1] = useState(window.innerWidth < 349);
    const [mobile11, setMobile11] = useState(window.innerWidth < 501);
    const { id } = useParams();
    const prevId = decryptData(id);
    useEffect(() => {
        const handleSize = () => {
            setMobile1(window.innerWidth < 349);
            setMobile11(window.innerWidth < 501);
        };

        window.addEventListener('resize', handleSize);

        return () => {
            window.removeEventListener('resize', handleSize);
        };
    }, []);

    const [Libelle, setLibelle] = useState('');
    const [animationClassLibelle, setAnimationClassLibelle] = useState('');
    const elemetRefLibelle = useRef(null);

    const [Prix_unit, setPrix_unit] = useState('');
    const [animationClassPrix_unit, setAnimationClassPrix_unit] = useState('');
    const elemetRefPrix_unit = useRef(null);

    const [Quantite, setQuantite] = useState('');
    const [animationClassQuantite, setAnimationClassQuantite] = useState('');
    const elemetRefQuantite = useRef(null);

    const [Description, setDescription] = useState('');  // Correction: Nom de la variable corrigé
    const [animationClassDescription, setAnimationClassDescription] = useState('');  // Correction: Nom de la variable corrigé
    const elemetRefDescription = useRef(null);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (Libelle.trim() === '') {
            toast.warning('Le Libelle du livre est obligatoire', {
                autoClose: 2000,
            });
            setAnimationClassLibelle('animate__animated animate__shakeX border-2 border-red-500 ');
            setTimeout(() => {
                setAnimationClassLibelle(' ');
            }, 3000);
            elemetRefLibelle.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            elemetRefLibelle.current.focus();

            return false;
        } else if (String(Prix_unit).trim() === '') {
            toast.warning('Le prix unitaire est obligatoire', {
                autoClose: 2000,
            });
            setAnimationClassPrix_unit('animate__animated animate__shakeX border-2 border-red-500');
            setTimeout(() => {
                setAnimationClassPrix_unit(' ');
            }, 3000);
            elemetRefPrix_unit.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            elemetRefPrix_unit.current.focus();

            return false;
        }
        else if (String(Quantite).trim() === '') {
            toast.warning("La quantite est obligatoire", {
                autoClose: 2000,
            });
            setAnimationClassQuantite('animate__animated animate__shakeX  border-2 border-red-500');
            setTimeout(() => {
                setAnimationClassQuantite(' ');
            }, 3000);
            elemetRefQuantite.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            elemetRefQuantite.current.focus();
            return false;
        } else if (Description === '') {  // Correction: Nom de la variable corrigé
            toast.warning('La description est obligatoire?', {
                autoClose: 2000,
            });
            setAnimationClassDescription('animate__animated animate__shakeX border-2 border-red-500');  // Correction: Nom de la variable corrigé
            setTimeout(() => {
                setAnimationClassDescription(' ');
            }, 3000);
            elemetRefDescription.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            elemetRefDescription.current.focus();
            return false;
        }
        const formData = {
            libelle: Libelle,
            quantite: Quantite,
            montant: Prix_unit,
            description: Description,
            status: "Chez Pref Disp",
            staffId: 21
        }
        API.put(`/prev/Infirmier/${prevId}`, formData).then((res) => {
            toast.success(res.data)
            history(`/Prevision/Detail/${id}`)
        }).catch((error) => {
            if (error.response) {
                // Le serveur a répondu avec un code d'erreur (status code)
                if (Array.isArray(error.response.data)) {
                    // Si c'est un tableau, afficher le premier élément
                    toast.warn(error.response.data[0]);
                } else if (typeof error.response.data === 'object') {
                    // S'il s'agit d'un objet, afficher la première valeur de la propriété
                    const keys = Object.keys(error.response.data);
                    if (keys.length > 0) {
                        toast.warn(error.response.data[keys[0]]);
                    }
                } else if (typeof error.response.data === 'string') {
                    // Si c'est une chaîne de caractères, l'afficher directement
                    toast.warn(error.response.data);
                } else {
                    // Autre type non pris en charge
                    toast.warn("Erreur du serveur")

                }
                // Afficher les erreurs au sein de votre composant ou effectuer une action en conséquence
            } else if (error.request) {
                // La requête a été faite mais aucune réponse n'a été reçue
                console.error('Pas de réponse du serveur :', error.request);
            } else {
                // Une erreur s'est produite lors de la configuration de la requête
                console.error('Erreur lors de la requête :', error.message);
            }
        })

    };


    const [prev, setPrev] = useState({});
    useEffect(() => {
        API.get(`/prev/Infirmier/${prevId}`).then((rep) => {
            setLibelle(rep.data.libelle);
            setDescription(rep.data.description);  // Correction: Nom de la variable corrigé
            setQuantite(rep.data.quantite);
            setPrix_unit(rep.data.montant);
        }).catch((err) => {
            console.log(err.message);
        });
    }, [id]);

    return (
        <div className={`bg-white overflow-y-auto overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'} ${getSizeMobile ? 'w-full ' : 'w-[85vw]'}`}>
            <Link to='/Prevision' className="ml-2 text-[18px] text-blue-500 hover:text-blue-800 underline">Retour</Link>
            <div className="sm:p-4 p-2 min-h-screen">
                <div className="my-5">
                    <div className="w-full flex flex-row items-center justify-between">
                        <p className="text-navy-700 shrink">
                            <p
                                className="hover:text-navy-700 text-2xl font-bold"
                            >
                                Modifier un prévision
                            </p>
                        </p>
                    </div>
                </div>
                <div className="w-full my-4 grid grid-cols-1 ">
                    <div className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">
                        <form onSubmit={handleSubmit}>
                            <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col my-2">
                                <div className="-mx-3 md:flex mb-6">
                                    <div className="md:w-1/2 px-3 mb-6 md:mb-0">
                                        <label
                                            className="block uppercase tracking-wide text-grey-darker text-lg font-bold mb-2"
                                            htmlFor="grid-Title"
                                        >
                                            Libelle
                                        </label>
                                        <input
                                            className={`outline-none block w-full bg-grey-lighter border rounded py-3 px-4 mb-3 ${Libelle.trim() === '' ? animationClassLibelle : 'focus:border-2 focus:border-blue-700'}`}
                                            id="grid-Title"
                                            type="text"
                                            value={Libelle}
                                            placeholder="Libelle"
                                            ref={elemetRefLibelle}
                                            onInput={(e) => setLibelle(e.target.value)}
                                        />
                                    </div>
                                    <div className="md:w-1/2 px-3">
                                        <label
                                            className="block uppercase tracking-wide text-grey-darker text-lg font-bold mb-2"
                                            htmlFor="grid-last-name"
                                        >
                                            Prix unitaire
                                        </label>
                                        <input
                                            className={`outline-none block w-full bg-grey-lighter border rounded py-3 px-4 mb-3 ${String(Prix_unit).trim() === '' ? animationClassPrix_unit : 'focus:border-2 focus:border-blue-700'}`}
                                            id="grid-last-name"
                                            type="number"
                                            value={Prix_unit}
                                            min={1}
                                            ref={elemetRefPrix_unit}
                                            placeholder="Prix unitaire"
                                            onInput={(e) => setPrix_unit(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="-mx-3 md:flex mb-6">
                                    <div className="md:w-1/2 px-3">
                                        <label
                                            className="block uppercase tracking-wide text-grey-darker text-lg font-bold mb-2"
                                        >
                                            Quantite
                                        </label>
                                        <input
                                            className={`outline-none block w-full bg-grey-lighter border rounded py-3 px-4 mb-3 ${String(Quantite).trim() === '' ? animationClassQuantite : 'focus:border-2 focus:border-blue-700'}`}
                                            id="grid-password"
                                            type="number"
                                            value={Quantite}
                                            min="1"
                                            ref={elemetRefQuantite}
                                            placeholder="Quantite"
                                            onInput={(e) => setQuantite(e.target.value)}
                                        />
                                    </div>
                                    <div className="md:w-1/2 px-3">
                                        <label
                                            className="block uppercase tracking-wide text-grey-darker text-lg font-bold mb-2"
                                            htmlFor="grid-state"
                                        >
                                            Description
                                        </label>
                                        <textarea
                                            className={`outline-none block w-full bg-grey-lighter border rounded py-3 px-4 mb-3 ${Description.trim() === '' ? animationClassDescription : 'focus:border-2 focus:border-blue-700'}`}
                                            id="grid-password"
                                            value={Description}
                                            onInput={(e) => setDescription(e.target.value)}
                                            ref={elemetRefDescription}
                                            placeholder="Description"
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="mb-4 flex items-center justify-end">
                                    <div className="flex-shrink-0">
                                        <button type="submit" className="mt-4 sm:mt-0 inline-flex items-start justify-start px-6 py-3 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg">
                                            <p className="text-lg font-medium leading-none text-white">
                                                Modifier
                                            </p>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <FootterCopy />
        </div>
    );
}

export default PrevisionInfirmierModifier;
