/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import FootterCopy from "../../FootterCopy/FootterCopy";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select"
import { FadeLoader } from 'react-spinners'
import { decryptData } from "../../../encryptionModule";
import { API } from "../../../Api/API";

function ModifieRentreEncadrement({ getSizeMobille }) {
    const { idRenter, id, schoolyearId, classId } = useParams()
    const history = useNavigate()
    const [mobile11, SetMobile11] = useState(window.innerWidth < 501)
    useEffect(() => {
        const hundlesSize = () => {
            SetMobile11(window.innerWidth < 501)
        }
        window.addEventListener('resize', hundlesSize)
        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])

    const [mobile, SetMobile] = useState(window.innerWidth <= 640)
    const [boutLoading, setboutLoading] = useState(false)

    useEffect(() => {
        const hundleSize = () => {
            SetMobile(window.innerWidth <= 640)
        }
        window.addEventListener('resize', hundleSize)

        return () => {
            window.removeEventListener('resize', hundleSize)
        }
    }, [])


    const Optionmateriel_complet = [
        { value: '', label: '' },
        { value: 'Oui', label: 'Oui' },
        { value: 'Non', label: 'Non' }
    ]
    const hiddenOptionmateriel_complet = Optionmateriel_complet.slice(1);
    const customStyles = {
        control: (provided) => ({
            ...provided,
            height: mobile ? 25 : 50,
            backgroundColor: "white",
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? "blue" : state.isFocused ? "lightblue" : "white",
            color: state.isSelected ? "white" : "black",
        }),
    };

    const [materiel_complet, Setmateriel_complet] = useState('')
    const [animationClassmateriel_complet, setAnimationClassmateriel_complet] = useState('');
    const elemetRefmateriel_complet = useRef(null)


    const [date_d_arrive, Setdate_d_arrive] = useState('')
    const [animationClassdate_d_arrive, setAnimationClassdate_d_arrive] = useState('');
    const elemetRefdate_d_arrive = useRef(null)

    const [manque, Setmanque] = useState('')
    const [animationClassmanque, setAnimationClassmanque] = useState('');
    const elemetRefmanque = useRef(null)

    const staffId = 2
    const studentId = decryptData(id)
    const [nomprenom_student, setnomprenom_student] = useState("")
    const [matricule_student, setmatricule_student] = useState("")
    const [nom_trim, setnom_trimt] = useState("")
    const [nom_SchoolY, setnom_SchoolY] = useState("")
    const [trimestreId, settrimestreId] = useState("")
    useEffect(() => {
        API.get(`/student/acceillestudentOne/${idRenter}`)
            .then(response => {
                if (response.data) {
                    setnomprenom_student(response.data.nomprenom_student)
                    setmatricule_student(response.data.matricule_student)
                    setnom_SchoolY(response.data.nom_SchoolY)
                    setnom_trimt(response.data.nom_trim)
                    Setmanque(response.data.manque)
                    settrimestreId(response.data.trimestreId)
                    Setdate_d_arrive(new Date(response.data.date_d_arrive).toISOString().split('T')[0]);
                    Setmateriel_complet({ value: response.data.materiel_complet, label: response.data.materiel_complet });
                }

            })
            .catch(error => {
                if (error.response) {
                    const errors = error.response.data.errors;
                    console.log(errors[0]);
                } else {
                    console.log('Une erreur s\'est produite lors de la requête.');
                }
            });
    }, [idRenter])

    const handleMaterielCompletChange = (option) => {
        if (option.value == "Oui") {
            Setmanque("")
        }
        Setmateriel_complet(option);
    };

    const handleSubmit = (e) => {
        e.preventDefault()

        if (materiel_complet == '') {
            toast.warning("La materiel_complet est oblogatoire", {
                autoClose: 2000
            });
            setAnimationClassmateriel_complet('animate__animated animate__shakeX  border-2 border-red-500')
            setTimeout(() => {
                setAnimationClassmateriel_complet(' ')
            }, 3000)
            elemetRefmateriel_complet.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            elemetRefmateriel_complet.current.focus()
            return false
        }
        else if (materiel_complet.value == 'Non' && manque == "") {
            toast.warning('Les manquants sont obligatoire', {
                autoClose: 2000
            });
            setAnimationClassmanque('animate__animated animate__shakeX border-2 border-red-500')
            setTimeout(() => {
                setAnimationClassmanque(' ')
            }, 3000)
            elemetRefmanque.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            return false
        }
        else if (date_d_arrive == "") {
            toast.warning('La date_d_arrive est obligatoire', {
                autoClose: 2000
            });
            setAnimationClassdate_d_arrive('animate__animated animate__shakeX border-2 border-red-500')
            setTimeout(() => {
                setAnimationClassdate_d_arrive(' ')
            }, 3000)
            elemetRefdate_d_arrive.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            return false
        }

        const data = {
            manque: manque,
            date_d_arrive: date_d_arrive,
            materiel_complet: materiel_complet.value,
            studentId: studentId,
            staffId: staffId,
            trimestreId: trimestreId
        }

        API.put(`/student/acceuilStudents/${idRenter}`, data)
            .then(response => {
                // Afficher un message de succès
                toast.success('Données mises à jour avec succès');
                history(`/Rentre/Detial/${id}/${schoolyearId}/${classId}`)
            })
            .catch(error => {
                // Afficher les erreurs retournées par le backend avec Toastify
                if (error.response) {
                    // Si le backend a renvoyé une réponse avec des erreurs
                    const errors = error.response.data.errors;
                    toast.error(errors[0]);

                } else {
                    // Si une autre erreur s'est produite (ex: erreur de réseau)
                    toast.error('Une erreur s\'est produite lors de la requête.');
                }
            });
    }

    return (

        <div className={`bg-white  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
            <Link
                to={`/Rentre/Detial/${id}/${schoolyearId}/${classId}`}
                className="ml-2 text-[18px] text-blue-500 hover:text-blue-800 underline">Retour</Link>
            <div className="sm:p-4 p-2 min-h-screen">
                <div class="my-5">
                    <div class="w-full flex flex-row items-center justify-between">
                        <p class="text-navy-700   ">
                            <a class="hover:text-navy-700 ms:text-2xl font-bold">
                                Modification  de l'élève {nomprenom_student}: {matricule_student}
                            </a>
                        </p>
                        <p class="text-navy-700   ">
                            <a class="hover:text-navy-700 ms:text-2xl font-bold">
                                {nom_SchoolY}: {nom_trim}
                            </a>
                        </p>

                    </div>
                </div>
                <div className="w-full my-4 grid grid-cols-1">
                    <div className="w-full my-4 grid grid-cols-1">
                        <form onSubmit={handleSubmit} className="flex flex-col items-center">
                            <div className="rounded-lg p-2 border border-blue-500 md:w-[40em] w-[95%] sm:p-4">

                                <div className="justify_center rounded-xl w-full  p-1 sm:p-2 mb-10  border-[1px] flex flex-col">
                                    <div className="mb-2">
                                        <div className="rounded-xl p-2 w-full">
                                            <div className="flex flex-col">
                                                <div className="rounded-lg w-full">
                                                    <div class="w-full mb-5">
                                                        <label class="block  tracking-wide text-gray-700 text-xs sm:text-lg  mb-1">
                                                            Matériel complet
                                                        </label>
                                                        <div
                                                            ref={elemetRefmateriel_complet}
                                                            class={` outline-none block w-full bg-grey-lighter  border rounded py-2 px-2 sm:py-3 sm:px-4 mb-3 ${animationClassmateriel_complet} focus:border-2 focus:border-blue-700 `}
                                                        >
                                                            <Select

                                                                value={materiel_complet}
                                                                options={hiddenOptionmateriel_complet}
                                                                styles={customStyles}
                                                                onChange={handleMaterielCompletChange} />

                                                        </div>
                                                    </div>
                                                </div>
                                                {materiel_complet.value == 'Non' &&
                                                    <div className="rounded-lg w-full">
                                                        <div class="w-full mb-5">
                                                            <label class="block  tracking-wide text-gray-700 text-xs sm:text-lg  mb-1">
                                                                Ce qui manque
                                                            </label>
                                                            <div className={` rounded `}>
                                                                <textarea
                                                                    ref={elemetRefmanque}
                                                                    class={` outline-none h-[10em] resize-none block w-full bg-grey-lighter  border rounded p-2 ${animationClassmanque} sm:py-3 sm:px-4 mb-3  focus:border-blue-700 `}
                                                                    value={manque}
                                                                    placeholder=" Ce qui manque..."
                                                                    onChange={(option) => Setmanque(option.target.value)}
                                                                >
                                                                </textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="rounded-lg w-full">
                                                    <div class="w-full mb-5">
                                                        <label class="block  tracking-wide text-gray-700 text-xs sm:text-lg  mb-1">
                                                            Date d'arrivée
                                                        </label>
                                                        <div className={` rounded `}>
                                                            <input
                                                                className={`outline-none block w-full bg-grey-lighter border rounded py-2 px-2 sm:py-3 sm:px-4 mb-3 ${animationClassdate_d_arrive} focus:border-2 focus:border-blue-700`}
                                                                id="grid-Title"
                                                                type="date"
                                                                placeholder="Point à retirer"
                                                                ref={elemetRefdate_d_arrive}
                                                                value={date_d_arrive} // Assigner la valeur de la date ici
                                                                onChange={(e) => Setdate_d_arrive(e.target.value)}
                                                            />

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>

                            <div className=" flex justify-end  md:w-[40em] w-[95%]">

                                {boutLoading ? (
                                    <>
                                        <label disabled className="cursor-no-drop w-max relative  mt-3 flex justify-center  items-center   bg-green-950    p-2 rounded  text-gray-400">
                                            <input type="submit" id="send" value='Enregistre' className='pointer-events-none' />
                                            <i class="bi bi-send ml-2  pointer-events-none "></i>
                                            <div disabled className='absolute pointer-events-none  bg-transparent pt-4  pl-4  w-full h-full flex justify-center items-center z-50'>
                                                <FadeLoader
                                                    color="rgb(255, 255, 255)"
                                                    height={10}
                                                    margin={-9}
                                                    radius={100}
                                                    speedMultiplier={1}
                                                    width={1}
                                                />
                                            </div>
                                        </label>
                                    </>
                                ) : (<>
                                    <label for="send" className=" mt-3 cursor-pointer w-max  flex justify-end  bg-green-600   p-2 rounded  text-white">
                                        <input type="submit" id="send" value='Enregistrer' className='cursor-pointer'></input>
                                        <i class="bi bi-send ml-2 "></i>
                                    </label>
                                </>)}
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <FootterCopy />
        </div>

    );
}


export default ModifieRentreEncadrement;










