import React from 'react'
import HomeGarconDetailDortoirePrefetDisplin from './HomeGarconDetailDortoirePrefetDisplin'
import SideBarsPrefetDisplin from '../SideBarsPrefetDisplin/SideBarsPrefetDisplin'
function HomeGarconDetailDortoirePrefetDisplinComponent() {
    return (
        <div className='flex justify-between overflow-hidden bg-white'>
            <SideBarsPrefetDisplin />
            <HomeGarconDetailDortoirePrefetDisplin />
        </div>
    )
}

export default HomeGarconDetailDortoirePrefetDisplinComponent
