import React from 'react'
import DetailAchat from './DetailAchat'
import SlideNavBarsCantin from '../SlideNavBarsCantin/SlideNavBarsCantin'
function DetailAchatComponent() {
    return (
        <div className='bg-white overflow-hidden pt-16 flex'>
            <SlideNavBarsCantin />
            <DetailAchat />
        </div>
    )
}

export default DetailAchatComponent
