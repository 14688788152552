import React from 'react'
import SlideEncadrementNavBars from '../SlideEncadrementNavBars/SlideEncadrementNavBars'
import AjouteDemandeEncadreur from './AjouteDemandeEncadreur'
function AjouteDemandeEncadreurComponent({ getSizeMobille, getMenuMobille }) {
    return (
        <div className='flex justify-between bg-white overflow-hidden'>
            <SlideEncadrementNavBars getMenuMobille={getMenuMobille} />
            <AjouteDemandeEncadreur getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default AjouteDemandeEncadreurComponent
