import React, { useEffect, useState } from 'react';
import FootterCopy from '../../FootterCopy/FootterCopy';
function SituationEleve({ getSizeMobille }) {
    const [mobile1, Setmonile1] = useState(window.innerWidth < 349)

    const [mobile11, SetMobile11] = useState(window.innerWidth < 501)

    useEffect(() => {
        const hundlesSize = () => {
            Setmonile1(window.innerWidth < 349)
            SetMobile11(window.innerWidth < 501)
        }

        window.addEventListener('resize', hundlesSize)

        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])


    return (

        <div className={`bg-white  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>

            <div className="sm:p-4 p-2 min-h-screen">
                <header class="bg-blue-500 rounded p-2 sm:p-4">
                    <h1 class={`text-white  font-bold ${mobile1 ? 'text-center' : 'text-xl'}`}>Situation de l'enfant</h1>
                </header>
                <div class="container mx-auto p-2 sm:p-4">
                    <section class="bg-white  rounded-lg shadow-lg p-4 mb-4">
                        <h2 class={` font-bold mb-2  ${mobile1 ? 'text-center' : 'text-xl'}`}>Informations générales de l'élève</h2>
                        <p class="text-gray-700">Nom complet: BUKURU Alice</p>
                        <p class="text-gray-700">Classe: CM1</p>
                    </section>
                    <h2 className="text-xl font-semibold mb-2">Notes de l'enfant</h2>
                    <div className='w-full overflow-x-auto overflow-y-hidden'>
                        <div>
                            <table className="w-full border shadow-lg">
                                <thead>
                                    <tr className=" border-b">
                                        <th className="p-2 border-l">Matière</th>
                                        <th className="p-2 border-l">Note</th>
                                        <th className="p-2 border-l">Appréciation</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className='border-b'>
                                        <td className="p-2 border-l">Mathématiques</td>
                                        <td className="p-2 border-l">90</td>
                                        <td className="p-2 border-l">Très bien</td>
                                    </tr>
                                    <tr className='border-b'>
                                        <td className="p-2 border-l">Français</td>
                                        <td className="p-2 border-l">85</td>
                                        <td className="p-2 border-l">Bien</td>
                                    </tr>
                                    <tr className='border-b'>
                                        <td className="p-2 border-l">Histoire</td>
                                        <td className="p-2 border-l">78</td>
                                        <td className="p-2 border-l">Satisfaisant</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <section class="bg-white rounded-lg shadow-lg p-4 mb-4">
                    <h2 class="text-xl font-bold mb-2">Paiement</h2>
                    <p class="text-gray-700">Statut de paiement: <span className='text-red-600'>impayé X</span> </p>
                </section>
            </div>
            <FootterCopy />
        </div>

    );
}

export default SituationEleve;