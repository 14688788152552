/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FootterCopy from "../../FootterCopy/FootterCopy";
import { API } from "../../../Api/API";
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { encryptData } from "../../../encryptionModule";
function ParticulierConsultationListe({ getSizeMobille }) {
    const [mobile1, Setmonile1] = useState(window.innerWidth < 349)
    const [mobile11, SetMobile11] = useState(window.innerWidth < 501)
    useEffect(() => {
        const hundlesSize = () => {
            Setmonile1(window.innerWidth < 349)
            SetMobile11(window.innerWidth < 501)
        }

        window.addEventListener('resize', hundlesSize)

        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])

    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [totalElements, setTotalElements] = useState(0)

    const [rechercher, setRechercher] = useState(false)
    const [isrechercher, setisRechercher] = useState(0)
    function handleSearch() {
        setRechercher(true)
        setisRechercher(isrechercher + 1)
    }

    useEffect(() => {
        fetchStaff(currentPage, pageSize, searchTerm, rechercher);
    }, [currentPage, pageSize, isrechercher]);

    const fetchStaff = async (page, size, searchTerm, rechercher) => {
        try {
            let url = `/visitorlivre/all?page=${page}&pageSize=${size}`;
            if (rechercher) {
                url = `/visitorlivre/all/search?page=${page}&pageSize=${size}&searchTerm=${searchTerm}`;
            }
            const response = await API.get(url);
            setData(response.data.data);
            setTotalPages(response.data.totalPages);
            setTotalElements(response.data.totalItems);
        } catch (error) {
            console.error('Erreur lors de la récupération des livres :', error.message);
        }
    };
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePageSizeChange = (size) => {
        setPageSize(size);
        setCurrentPage(1);
    };



    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const formatDate = date => {
        return format(new Date(date), "'Le 'd, MMMM yyyy", { locale: fr });
    };

    const getRange = () => {
        const from = (currentPage - 1) * pageSize + 1;
        const to = Math.min(currentPage * pageSize, totalElements);
        return `${from} à ${to} livres sur ${totalElements}`;
    };
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        if (searchTerm.toString().trim().length === 1) {
            setRechercher(false);
            setisRechercher(isrechercher + 1);
        }
    };


    const [inputValue, setInputValue] = useState('');
    const [sortOrder, setSortOrder] = useState('asc');
    const [sortedColumn, setSortedColumn] = useState(null);

    const ValuesInput = (e) => {
        setInputValue(e.target.value);
    };


    const fildata = (data, inputValue) => {
        if (!inputValue.trim()) {
            return data;
        }
        return data.filter((item) => {
            if (
                item.nom_complet.toLowerCase().includes(inputValue.toLowerCase()) ||
                item.telephone.toString().toLowerCase().includes(inputValue.toLowerCase()) ||
                item.numero_CNI.toString().toLowerCase().includes(inputValue.toLowerCase())
            ) {
                return true;
            }
            return false;
        });
    };



    const handleSort = (column) => {
        if (column === sortedColumn) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortOrder('asc');
            setSortedColumn(column);
        }
    };

    const sortData = (data, column, sortOrder) => {
        return data.slice().sort((a, b) => {
            const aValue = a[column];
            const bValue = b[column];

            if (aValue < bValue) return sortOrder === 'asc' ? -1 : 1;
            if (aValue > bValue) return sortOrder === 'asc' ? 1 : -1;
            return 0;
        });
    };

    const filteredData = fildata(data, inputValue);

    const sortedData = sortData(filteredData, sortedColumn, sortOrder);


    function highlightText(text, inputValue) {
        if (!inputValue) return text;

        const highlightedText = [];
        let currentIndex = 0;

        while (currentIndex < text.length) {
            const inputChar = inputValue.toLowerCase();
            const matchIndex = text.substr(currentIndex).toLowerCase().indexOf(inputChar);

            if (matchIndex !== -1) {

                highlightedText.push(text.substring(currentIndex, currentIndex + matchIndex));


                highlightedText.push(
                    <span className="text-orange-500" key={currentIndex + matchIndex}>
                        {text.substring(currentIndex + matchIndex, currentIndex + matchIndex + inputValue.length)}
                    </span>
                );

                currentIndex = currentIndex + matchIndex + inputValue.length;
            } else {
                highlightedText.push(text.substring(currentIndex));
                break;
            }
        }

        return highlightedText;
    }


    const [mobile2, Setmonile2] = useState(window.innerWidth < 1008.9)






    return (

        <div className={`bg-white  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
            <Link to='/Consultation' className="ml-2 text-[18px] text-blue-500 hover:text-blue-800 underline">Retour</Link>

            <div className="sm:p-4 p-2 min-h-screen">
                <div class="my-5">
                    <div class="w-full flex flex-row items-center justify-between">


                        <p class="text-navy-700 shrink  ">
                            <p
                                class="hover:text-navy-700 text-2xl font-bold  "

                            >
                                Visiteur
                            </p>
                        </p>

                    </div>
                </div>
                <div class="sm:flex items-center pr-4  h-max  justify-end">

                    <div className={`${mobile2 ? 'mb-5' : ''}`}>
                        <div class="">
                            <div class="">
                                <div class="">
                                    <div className=" flex items-center">
                                        <p className="sm:text-xl">Taille de la page :</p>
                                        <select
                                            value={pageSize}
                                            onChange={(e) => handlePageSizeChange(Number(e.target.value))}
                                            className="ml-2 p-2.5 cursor-pointer bg-white border border-gray-300 rounded"
                                        >
                                            <option value={1}>1</option>
                                            <option value={5}>5</option>
                                            <option value={10}>10</option>
                                            <option value={20}>20</option>
                                            <option value={50}>50</option>
                                            <option value={100}>100</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mx-2">
                        <div class="relative lg:w-64">
                            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <svg
                                    class="w-5 h-5 text-gray-500"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                        clip-rule="evenodd"
                                    ></path>
                                </svg>
                            </div>
                            <input
                                type="text"
                                name="email"
                                id="topbar-search"
                                class="bg-white border border-gray-300 h-10 text-gray-900 sm:text-lg first-letter:uppercase rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full pl-10 .5"
                                placeholder="Rechercher..."
                                onInput={ValuesInput}
                            />
                        </div>
                    </div>
                    <button class="mt-4 sm:mt-0 inline-flex items-start justify-start px-1 py-3 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg">
                        <p class="text-lg first-letter:uppercase font-semibold leading-none text-white">
                            Rerchercher
                        </p>
                    </button>
                </div>

                <div class="w-full my-4 grid grid-cols-1   gap-4">
                    <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">
                        <div class="mb-4 flex items-center justify-between">
                            <div>
                                <h3 class="text-xl font-bold text-gray-900 mb-2">
                                    Listes des Visiteurs
                                </h3>
                            </div>
                            <Link to='/Consultation/ParticulierAjout' className="bg-blue-300 p-2 rounded-full">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle w-6 h-6 text-blue-800" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
                                </svg>
                            </Link>
                        </div>

                        <div class="flex flex-col mt-8">
                            <div class="overflow-x-auto rounded-lg">
                                <div class="align-middle inline-block min-w-full">
                                    <div class="shadow overflow-hidden sm:rounded-lg">
                                        <table class="min-w-full divide-y divide-gray-200">
                                            <thead class="bg-gray-50">
                                                <tr>
                                                    <th onClick={() => handleSort('nom_complet')}
                                                        scope="col"
                                                        class="  py-4 px-2 border-l text-left cursor-pointer relative text-lg first-letter:uppercase  text-gray-500  tracking-wider"

                                                    >
                                                        Nom complet  {sortedColumn === 'nom_complet' && <span className="pr-0 absolute right-2">{sortOrder === 'asc' ? '▲' : '▼'}</span>}

                                                    </th>
                                                    <th onClick={() => handleSort('telephone')}
                                                        scope="col"
                                                        class="  py-4 px-2 border-l text-left cursor-pointer relative text-lg first-letter:uppercase  text-gray-500  tracking-wider"

                                                    >
                                                        Téléphone  {sortedColumn === 'telephone' && <span className="pr-0 absolute right-2">{sortOrder === 'asc' ? '▲' : '▼'}</span>}

                                                    </th>
                                                    <th onClick={() => handleSort('numero_CNI')}
                                                        scope="col"
                                                        class="  py-4 px-2 border-l text-left cursor-pointer relative text-lg first-letter:uppercase  text-gray-500  tracking-wider"

                                                    >
                                                        Numéro de CNI  {sortedColumn === 'numero_CNI' && <span className="pr-0 absolute right-2">{sortOrder === 'asc' ? '▲' : '▼'}</span>}

                                                    </th>

                                                    <th
                                                        scope="col"
                                                        class="  py-4 px-2 w-0 border-l text-left cursor-pointer relative text-lg first-letter:uppercase  text-gray-500  tracking-wider"

                                                    >
                                                        Action
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody class="bg-white">

                                                {sortedData.map((data, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase  text-gray-500">
                                                                {highlightText(data.nom_complet, inputValue)}

                                                            </td>
                                                            <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase  text-gray-500">

                                                                {highlightText(data.telephone, inputValue)}
                                                            </td>
                                                            <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase  text-gray-500">

                                                                {highlightText(data.numero_CNI.toString(), inputValue)}
                                                            </td>
                                                            <td class="border-b border-gray-200  pt-2 px-3  border-l border-r whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                                <Link
                                                                    to={`/Emprunter/visitor/Livre/${encryptData((data.id).toString())}`}
                                                                    class="my-2 sm:mt-0 inline-flex items-start justify-start px-3 py-2 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg"
                                                                >
                                                                    <p class="text-lg font-semibold leading-none text-white">
                                                                        Emprunter
                                                                    </p>
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}

                                                {sortedData.length == 0 &&
                                                    <tr>
                                                        <td colSpan={3} className="h-20 w-full text-center text-2xl text-gray-400"> Aucun élément correspondant à <span className="font-bold">{searchTerm}</span> </td>
                                                    </tr>}

                                                {data.length > 0 && (
                                                    <>
                                                        {/* Pagination */}
                                                        {/* Pagination controls */}
                                                        <div className="mt-4 flex items-center justify-center">
                                                            <div className="flex items-center">
                                                                <button
                                                                    disabled={currentPage === 1}
                                                                    onClick={handlePreviousPage}
                                                                    className="mx-1 px-3 py-1 rounded bg-gray-300 text-gray-700"
                                                                >
                                                                    Précédent
                                                                </button>
                                                                {Array.from({ length: totalPages }, (_, index) => index + 1).map((pageNumber) => (
                                                                    <button
                                                                        key={pageNumber}
                                                                        onClick={() => handlePageChange(pageNumber)}
                                                                        className={`mx-1 px-3 py-1 rounded ${currentPage === pageNumber ? 'bg-gray-700 text-white' : 'bg-gray-300 text-gray-700'}`}
                                                                    >
                                                                        {pageNumber}
                                                                    </button>
                                                                ))}
                                                                <button
                                                                    disabled={currentPage === totalPages}
                                                                    onClick={handleNextPage}
                                                                    className="mx-1 px-3 py-1 rounded bg-gray-300 text-gray-700"
                                                                >
                                                                    Suivant
                                                                </button>
                                                            </div>
                                                        </div>

                                                        <div className="mt-4 flex items-center justify-center">
                                                            <p className="text-gray-600">{getRange()}</p>
                                                        </div>
                                                    </>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

            <FootterCopy />
        </div >

    );
}

export default ParticulierConsultationListe;
