import React from 'react'
import SlideNavInfermie from '../SlideNavInfermie/SlideNavInfermie'
import ParticulierConsultation from './ParticulierConsultation'
function ParticulierConsultationComponent({ getSizeMobille, getMenuMobille }) {
    return (
        <div className='flex justify-between overflow-hidden bg-white'>
            <SlideNavInfermie getMenuMobille={getMenuMobille} />
            <ParticulierConsultation getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default ParticulierConsultationComponent
