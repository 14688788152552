import React from 'react'
import EnregistreNotesChoixClass from './EnregistreNotesChoixClass'
import SildeEnseign from '../SildeEnseign/SildeEnseign'
function EnregistreNotesChoixClassComponent() {
  return (
    <div class="overflow-hidden flex justify-between">
      <SildeEnseign />
      <EnregistreNotesChoixClass />
    </div>
  )
}

export default EnregistreNotesChoixClassComponent
