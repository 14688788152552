import React, { useState } from "react";
import ModifierRecusComponent from "../../component/Secretariat/Courriers/Recus/ModifierRecusComponent";
function ModifierRecusPage({ getSizeMobille, getMenuMobille }) {
  return (
    <>
      <ModifierRecusComponent getMenuMobille={getMenuMobille} getSizeMobille={getSizeMobille} />
    </>
  );
}

export default ModifierRecusPage;
