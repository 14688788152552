import React from 'react'

function Compte() {
    return (
        <>
            <div
                id="main-content"
                class="h-full w-full bg-gray-50 relative overflow-y-auto lg:ml-64"
            >
                <main>
                    <div class="pt-6 px-4">

                        <div class="w-full my-4 grid grid-cols-1 ">
                            <div class="bg-white  w-full flex justify-center rounded-lg p-4 ">
                                <div class="rounded-lg bg-white border flex flex-col pt-4 items-center border-blue-600 w-max">
                                    <div class="w-[15em] h-[15em] border p-3">
                                        <img
                                            src="https://people.com/thmb/c-F0iN1vcXp69xbJ2c1JmPj1OdI=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc():focal(674x0:676x2)/a-dangerous-business-012723-1-2000-f2cbfa586fe5414ab1c15e608777f959.jpg"
                                            alt="product 1"
                                            class="object-contain h-full w-full rounded-2xl"
                                        />
                                    </div>
                                    <div class="flex flex-col justify-start p-6">
                                        <h5 class="mb-2 text-xl font-medium text-neutral-800 ">
                                            Nom complet : Telecom telesphore
                                        </h5>
                                        <p class="mb-4 text-xl text-neutral-600 ">
                                            Tel: 789378
                                        </p>
                                        <p class="mb-4 text-xl text-neutral-600 ">
                                            CIN: 30/28293
                                        </p>
                                        <p class="mb-4 text-xl text-neutral-600 ">
                                            Email: telecom@gmail.com
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </main>

            </div>
        </>
    )
}

export default Compte
