import React from 'react'
import SlideNavInfermie from '../SlideNavInfermie/SlideNavInfermie'
import CongeInfiermier from './CongeInfiermier'
function CongeInfiermierComponent({ getSizeMobille, getMenuMobille }) {
    return (
        <div className='flex justify-between overflow-hidden bg-white'>
            <SlideNavInfermie getMenuMobille={getMenuMobille} />
            <CongeInfiermier getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default CongeInfiermierComponent
