import React, { useState } from "react";
import SideBar from "../SideBarSecretaire/SideBarSecretaire";
import DocClasseur from "./DocClasseur";
function DocClasseurComponent({ getMenuMobille, getSizeMobille }) {
  return (
    <>
      {" "}
      <div class="flex overflow-hidden bg-white justify-center">
        <SideBar getMenuMobille={getMenuMobille} />
        <DocClasseur getSizeMobille={getSizeMobille} />
      </div>
    </>
  );
}

export default DocClasseurComponent;
