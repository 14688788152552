/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";

function NeuwPrevisionMatiersEns() {
    const [evaluations, setEvaluations] = useState([]);

    const addEvaluation = () => {
        setEvaluations([...evaluations, { cours: "", type: "", pondération: "" }]);
    };

    const handleEvaluationChange = (index, key, value) => {
        const updatedEvaluations = [...evaluations];
        updatedEvaluations[index][key] = value;
        setEvaluations(updatedEvaluations);
    };

    const saveEvaluations = () => {
        console.log("Evaluations:", evaluations);
    };

    function toRoman(num) {
        const romanNumerals = [
            "I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX", "X",
            "XI", "XII", "XIII", "XIV", "XV", "XVI", "XVII", "XVIII", "XIX", "XX",
            "XXI", "XXII", "XXIII", "XXIV", "XXV", "XXVI", "XXVII", "XXVIII", "XXIX", "XXX"
        ];
        return romanNumerals[num - 1] || num.toString();
    }

    return (
        <>
            <div
                id="main-content"
                class="h-full w-full bg-gray-50 relative overflow-y-auto lg:ml-64"
            >
                <main>
                    <div class="pt-6 px-4">
                        <div class="my-5">
                            <div class="w-full flex flex-row items-center justify-between">
                                <p class="text-navy-700 shrink  ">
                                    <a
                                        class="hover:text-navy-700 text-2xl font-bold first-letter:uppercase  text-gray-500"
                                        href="#"
                                    >
                                        Ajouter un nouveau évaluation
                                    </a>
                                </p>
                            </div>
                        </div>
                        <div class="w-full my-4 grid grid-cols-1 ">
                            <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">
                                <form>
                                    <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col my-2">
                                        {evaluations.map((evaluation, index) => (
                                            <div key={index}>
                                                <div className="md:w-1/2 px-3 mb-6">
                                                    <div className="border rounded-2xl w-max p-3">
                                                        <div className="h-max">
                                                            <div className="px-3 mb-10">
                                                                <div className="w-full flex items-center justify-between">
                                                                    <label
                                                                        className="block font-serif text-gray-700 first-letter:uppercase tracking-wide text-grey-darker text-lg font-bold mb-2"
                                                                    >
                                                                        {toRoman(index + 1)}  évaluation
                                                                    </label>
                                                                    <div
                                                                        className="w-8 h-8 bg-gray-100 mb-4 hover-bg-gray-200 p-1 rounded-xl cursor-pointer"
                                                                        onClick={() => setEvaluations(evaluations.filter((_, i) => i !== index))}
                                                                    >
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            fill="none"
                                                                            viewBox="0 0 24 24"
                                                                            strokeWidth="1.5"
                                                                            stroke="currentColor"
                                                                            className="w-6 h-6 mb-3 border border-red-600 rounded-full text-red-500"
                                                                        >
                                                                            <path
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                                d="M6 18L18 6M6 6l12 12"
                                                                            />
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                                <div className="bg-gray-50 rounded-xl p-2 w-max">
                                                                    <div className="flex">
                                                                        <div className="p-3 m-1 rounded-lg w-max">
                                                                            <div className="relative mt-2 mb-6">
                                                                                <label className="text-gray-400">Cours</label>
                                                                                <select
                                                                                    className="appearance-none block bg-white focus-border-2 focus-border-blue-700 w-[20em] bg-grey-lighter text-grey-darker border border-red rounded py-3 px-2 cursor-pointer mb-3"
                                                                                    onChange={(e) => handleEvaluationChange(index, "cours", e.target.value)}
                                                                                >
                                                                                    <option value="" hidden>
                                                                                        Cours
                                                                                    </option>
                                                                                    <option value="">Cours</option>
                                                                                    <option value="Mathématique">Mathématique</option>
                                                                                    <option value="Physique">Physique</option>
                                                                                    <option value="Biologie">Biologie</option>
                                                                                    <option value="Chimie">Chimie</option>
                                                                                    <option value="Science de la terre">Science de la terre</option>
                                                                                    <option value="Kiswahili">Kiswahili</option>
                                                                                    <option value="Kirundi">Kirundi</option>
                                                                                </select>
                                                                            </div>
                                                                            <div className="relative mt-2 mb-6">
                                                                                <label className="text-gray-400">Type d'évaluation</label>
                                                                                <select
                                                                                    className="appearance-none block bg-white focus-border-2 focus-border-blue-700 w-[20em] bg-grey-lighter text-grey-darker border border-red rounded py-3 px-2 cursor-pointer mb-3"
                                                                                    onChange={(e) => handleEvaluationChange(index, "type", e.target.value)}
                                                                                >
                                                                                    <option value="" hidden>
                                                                                        Type d'évaluation
                                                                                    </option>
                                                                                    <option value="" hidden>Type d'évaluation</option>
                                                                                    <option value="Examen">Examen</option>
                                                                                    <option value="Dévoirs">Dévoirs</option>
                                                                                    <option value="Inntérrogation">Inntérrogation</option>
                                                                                </select>
                                                                            </div>
                                                                            <div className="w-full mb-6">
                                                                                <label className="text-gray-400">Pondération</label>
                                                                                <input
                                                                                    className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-red rounded py-3 px-4 mb-3"
                                                                                    type="text"
                                                                                    onChange={(e) => handleEvaluationChange(index, "pondération", e.target.value)}
                                                                                    placeholder="Pondération.."
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        <div className="flex mt-10">
                                            <div className="bg-yellow-500 px-4 py-2 hover:bg-yellow-700 transition-all cursor-pointer rounded text-white" onClick={addEvaluation}>
                                                Ajouter une évaluation
                                            </div>
                                        </div>
                                    </div>
                                    <div class="my-4 flex items-center justify-end">
                                        <div class="flex-shrink-0">
                                            <div class="mt-4 sm:mt-0 inline-flex items-start justify-start px-6 py-3 bg-blue-700 hover-bg-blue-600 focus:outline-none rounded-lg" onClick={saveEvaluations}>
                                                <p class="text-lg font-medium leading-none text-white">
                                                    Sauvegarder
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
}

export default NeuwPrevisionMatiersEns;
