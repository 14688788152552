import React, { useEffect, useState } from 'react'
import FootterCopy from '../../FootterCopy/FootterCopy'
import { Link } from 'react-router-dom'

function EncadeurPrefetDisplin({ getSizeMobille }) {
    const [mobile1, Setmonile1] = useState(window.innerWidth < 349)
    const [mobile11, SetMobile11] = useState(window.innerWidth < 501)

    useEffect(() => {
        const hundlesSize = () => {
            Setmonile1(window.innerWidth < 349)
            SetMobile11(window.innerWidth < 501)
        }

        window.addEventListener('resize', hundlesSize)

        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])
    return (
        <div className={`bg-white  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>

            <div className="p-6  min-h-[85vh]">
                <div class="my-10 flex items-center justify-center">
                    <h3 class="text-xl sm:text-2xl text-center leading-none font-bold text-gray-900">
                        Votre choix
                    </h3>
                </div>

                <div class="my-4 w-full items-center flex flex-col gap-4">
                    <Link
                        to="/Encadreur/List"
                        class="bg-white border border-fuchsia-300 w-[15em] rounded-lg p-4 flex justify-center items-center cursor-pointer hover:bg-gray-100s hover:shadow-lg transition duration-300 ease-in-out hover:scale-105 transform"
                    >
                        <div class="flex items-center">
                            <div class="flex-shrink-0">
                                <h3 class="text-xl font-normal text-gray-700">
                                    Horaire
                                </h3>
                            </div>
                        </div>
                    </Link>
                    <Link to='/Encadreur/Rapport'
                        class="bg-white border w-[15em] border-fuchsia-300 rounded-lg p-4 flex justify-center items-center cursor-pointer hover:bg-gray-100s hover:shadow-lg transition duration-300 ease-in-out hover:scale-105 transform"
                    >
                        <div class="flex items-center">
                            <div class="flex-shrink-0">
                                <h3 class="text-xl font-normal text-gray-700">
                                    Rapport
                                </h3>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
            <FootterCopy />
        </div>
    )
}

export default EncadeurPrefetDisplin
