import React from 'react'
import ListDetteEconom from './ListDetteEconom'
import SideBarSecretaire from '../SideBarSecretaire/SideBarSecretaire'
function ListDetteEconomComponent({ getMenuMobille, getSizeMobille }) {
    return (
        <div className='flex justify-between overflow-hidden'>
            <SideBarSecretaire getMenuMobille={getMenuMobille} />
            <ListDetteEconom getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default ListDetteEconomComponent
