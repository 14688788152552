import React, { useState } from "react";
import CongeAnnuelComponent from "../../component/Secretariat/CongeAnnuel/CongeAnnuelComponent";
function CongeAnnuelPage({ getSizeMobille, getMenuMobille }) {
  return (
    <>
      <CongeAnnuelComponent getMenuMobille={getMenuMobille} getSizeMobille={getSizeMobille} />
    </>
  );
}

export default CongeAnnuelPage;
