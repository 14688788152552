
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import FootterCopy from "../../FootterCopy/FootterCopy";

function AcceuilPrefetDisplin({ getSizeMobille }) {
    const [mobile, Setmonile] = useState(window.innerWidth < 1575)
    const [mobile2, Setmonile2] = useState(window.innerWidth < 842)
    const [mobile3, Setmonile3] = useState(window.innerWidth < 497)
    const [mobile4, Setmonile4] = useState(window.innerWidth < 448)
    const [mobile11, SetMobile11] = useState(window.innerWidth < 501)

    useEffect(() => {
        const hundlesSize = () => {
            Setmonile(window.innerWidth < 1575)
            Setmonile2(window.innerWidth < 842)
            Setmonile3(window.innerWidth < 497)
            Setmonile4(window.innerWidth < 448)
            SetMobile11(window.innerWidth < 501)
        }
        window.addEventListener('resize', hundlesSize)
        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])




    return (

        <div className={`bg-white  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
            <div className="sm:p-4 p-2 min-h-screen">
                <div classNameName="sm:p-4 p-2 ">
                    <div className="sm:my-10 my-2">
                        <div className="w-full flex flex-row items-center justify-between">
                            <p className="text-navy-700 shrink text-[25px] sm:text-[33px]  text-gray-600">
                                <p
                                    className="hover:text-navy-700 font-bold  text-gray-600 font-serif"
                                >
                                    Accueil
                                </p>
                            </p>

                        </div>
                    </div>
                </div>
                <div className={`my-4 w-full grid  gap-2 p-2 sm:p-4  ${mobile3 ? 'grid-cols-1' : mobile2 ? 'grid-cols-2' : mobile ? 'grid-cols-3' : 'grid-cols-4'}`}>
                    <div className={`bg-white border border-fuchsia-400 rounded-lg p-4 sm:p-6 xl:p-8`}>
                        <div className="flex  flex-col">
                            <div className=" flex">
                                <span className="text-[20px]  sm:text-3xl leading-none font-bold text-gray-900">
                                    2,340
                                </span>
                                <div className="ml-5 w-0 flex items-center justify-end flex-1 text-blue-500 text-xl font-bold">
                                    100%
                                    <svg
                                        className="w-5 h-5"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            d="M5.293 7.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L6.707 7.707a1 1 0 01-1.414 0z"
                                            clip-rule="evenodd"
                                        ></path>
                                    </svg>
                                </div>
                            </div>
                            <h3 className="text-[18px] max-sm:w-full text-center mt-4 sm:text-xl font-normal text-gray-500">
                                Nombres des suivreurs
                            </h3>
                        </div>
                    </div>

                    <div className="bg-white border border-fuchsia-400 rounded-lg p-4 sm:p-6 xl:p-8">
                        <div className="flex  flex-col">
                            <div className=" flex">
                                <span className="text-[20px]  sm:text-3xl leading-none font-bold text-gray-900">
                                    2,340
                                </span>


                                <div className="ml-5 w-0 flex items-center justify-end flex-1 text-blue-500 text-xl font-bold">
                                    100%
                                    <svg
                                        className="w-5 h-5"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            d="M5.293 7.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L6.707 7.707a1 1 0 01-1.414 0z"
                                            clip-rule="evenodd"
                                        ></path>
                                    </svg>
                                </div>
                            </div>
                            <h3 className="text-[18px] max-sm:w-full text-center mt-4 sm:text-xl font-normal text-gray-500">
                                Nombre des encadreurs
                            </h3>
                        </div>
                    </div>

                    <div className="bg-white border border-fuchsia-400 rounded-lg p-4 sm:p-6 xl:p-8">
                        <div className="flex  flex-col">
                            <div className=" flex">
                                <span className="text-[20px]  sm:text-3xl leading-none font-bold text-gray-900">
                                    2,340
                                </span>


                                <div className="ml-5 w-0 flex items-center justify-end flex-1 text-blue-500 text-xl font-bold">
                                    100%
                                    <svg
                                        className="w-5 h-5"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            d="M5.293 7.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L6.707 7.707a1 1 0 01-1.414 0z"
                                            clip-rule="evenodd"
                                        ></path>
                                    </svg>
                                </div>
                            </div>
                            <h3 className="text-[18px] max-sm:w-full text-center mt-4 sm:text-xl font-normal text-gray-500">
                                Nombres des salles de classe
                            </h3>
                        </div>
                    </div>

                    <div className="bg-white border border-fuchsia-400 rounded-lg p-4 sm:p-6 xl:p-8">
                        <div className="flex  flex-col">
                            <div className=" flex">
                                <span className="text-[20px]  sm:text-3xl leading-none font-bold text-gray-900">
                                    2,340
                                </span>


                                <div className="ml-5 w-0 flex items-center justify-end flex-1 text-blue-500 text-xl font-bold">
                                    100%
                                    <svg
                                        className="w-5 h-5"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            d="M5.293 7.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L6.707 7.707a1 1 0 01-1.414 0z"
                                            clip-rule="evenodd"
                                        ></path>
                                    </svg>
                                </div>
                            </div>
                            <h3 className="text-[18px] max-sm:w-full text-center mt-4 sm:text-xl font-normal text-gray-500">
                                Nombres des salles à manger
                            </h3>
                        </div>
                    </div>

                    <div className="bg-white border border-fuchsia-400 rounded-lg p-4 sm:p-6 xl:p-8">
                        <div className="flex  flex-col">
                            <div className=" flex">
                                <span className="text-[20px]  sm:text-3xl leading-none font-bold text-gray-900">
                                    2,340
                                </span>
                                <div className="ml-5 w-0 flex items-center justify-end flex-1 text-blue-500 text-xl font-bold">
                                    100%
                                    <svg
                                        className="w-5 h-5"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            d="M5.293 7.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L6.707 7.707a1 1 0 01-1.414 0z"
                                            clip-rule="evenodd"
                                        ></path>
                                    </svg>
                                </div>
                            </div>
                            <h3 className="text-[18px] max-sm:w-full text-center mt-4 sm:text-xl font-normal text-gray-500">
                                Nombres des rappors
                            </h3>
                        </div>
                    </div>

                    <div className="bg-white border border-fuchsia-400 rounded-lg p-4 sm:p-6 xl:p-8">
                        <div className="flex  flex-col">
                            <div className=" flex">
                                <span className="text-[20px]  sm:text-3xl leading-none font-bold text-gray-900">
                                    2,340
                                </span>


                                <div className="ml-5 w-0 flex items-center justify-end flex-1 text-blue-500 text-xl font-bold">
                                    100%
                                    <svg
                                        className="w-5 h-5"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            d="M5.293 7.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L6.707 7.707a1 1 0 01-1.414 0z"
                                            clip-rule="evenodd"
                                        ></path>
                                    </svg>
                                </div>
                            </div>
                            <h3 className="text-[18px] max-sm:w-full text-center mt-4 sm:text-xl font-normal text-gray-500">
                                Nombres des prévisions
                            </h3>
                        </div>
                    </div>

                    <div className="bg-white border border-fuchsia-400 rounded-lg p-4 sm:p-6 xl:p-8">
                        <div className="flex  flex-col">
                            <div className=" flex">
                                <span className="text-[20px]  sm:text-3xl leading-none font-bold text-gray-900">
                                    2,340
                                </span>


                                <div className="ml-5 w-0 flex items-center justify-end flex-1 text-blue-500 text-xl font-bold">
                                    100%
                                    <svg
                                        className="w-5 h-5"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            d="M5.293 7.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L6.707 7.707a1 1 0 01-1.414 0z"
                                            clip-rule="evenodd"
                                        ></path>
                                    </svg>
                                </div>
                            </div>
                            <h3 className="text-[18px] max-sm:w-full text-center mt-4 sm:text-xl font-normal text-gray-500">
                                Nombres de s depenses
                            </h3>
                        </div>
                    </div>

                    <div className="bg-white border border-fuchsia-400 rounded-lg p-4 sm:p-6 xl:p-8">
                        <div className="flex  flex-col">
                            <div className=" flex">
                                <span className="text-[20px]  sm:text-3xl leading-none font-bold text-gray-900">
                                    2,340
                                </span>


                                <div className="ml-5 w-0 flex items-center justify-end flex-1 text-blue-500 text-xl font-bold">
                                    100%
                                    <svg
                                        className="w-5 h-5"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            d="M5.293 7.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L6.707 7.707a1 1 0 01-1.414 0z"
                                            clip-rule="evenodd"
                                        ></path>
                                    </svg>
                                </div>
                            </div>
                            <h3 className="text-[18px] max-sm:w-full text-center mt-4 sm:text-xl font-normal text-gray-500">
                                Nombres des représentants
                            </h3>
                        </div>
                    </div>
                    <div className="bg-white border border-fuchsia-400 rounded-lg p-4 sm:p-6 xl:p-8">
                        <div className="flex  flex-col">
                            <div className=" flex">
                                <span className="text-[20px]  sm:text-3xl leading-none font-bold text-gray-900">
                                    2,340
                                </span>


                                <div className="ml-5 w-0 flex items-center justify-end flex-1 text-blue-500 text-xl font-bold">
                                    100%
                                    <svg
                                        className="w-5 h-5"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            d="M5.293 7.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L6.707 7.707a1 1 0 01-1.414 0z"
                                            clip-rule="evenodd"
                                        ></path>
                                    </svg>
                                </div>
                            </div>
                            <h3 className="text-[18px] max-sm:w-full text-center mt-4 sm:text-xl font-normal text-gray-500">
                                Nombres des dortoires
                            </h3>
                        </div>
                    </div>

                    <div className="bg-white border border-fuchsia-400 rounded-lg p-4 sm:p-6 xl:p-8">
                        <div className="flex  flex-col">
                            <div className=" flex">
                                <span className="text-[20px]  sm:text-3xl leading-none font-bold text-gray-900">
                                    2,340
                                </span>


                                <div className="ml-5 w-0 flex items-center justify-end flex-1 text-blue-500 text-xl font-bold">
                                    100%
                                    <svg
                                        className="w-5 h-5"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            d="M5.293 7.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L6.707 7.707a1 1 0 01-1.414 0z"
                                            clip-rule="evenodd"
                                        ></path>
                                    </svg>
                                </div>
                            </div>
                            <h3 className="text-[18px] max-sm:w-full text-center mt-4 sm:text-xl font-normal text-gray-500">
                                Nombres des latrines
                            </h3>
                        </div>
                    </div>
                    <div className="bg-white border border-fuchsia-400 rounded-lg p-4 sm:p-6 xl:p-8">
                        <div className="flex  flex-col">
                            <div className=" flex">
                                <span className="text-[20px]  sm:text-3xl leading-none font-bold text-gray-900">
                                    2,340
                                </span>


                                <div className="ml-5 w-0 flex items-center justify-end flex-1 text-blue-500 text-xl font-bold">
                                    100%
                                    <svg
                                        className="w-5 h-5"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            d="M5.293 7.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L6.707 7.707a1 1 0 01-1.414 0z"
                                            clip-rule="evenodd"
                                        ></path>
                                    </svg>
                                </div>
                            </div>
                            <h3 className="text-[18px] max-sm:w-full text-center mt-4 sm:text-xl font-normal text-gray-500">
                                Nombres des résumes
                            </h3>
                        </div>
                    </div>
                </div>

                <div className={`p-2 flex ${mobile2 ? 'flex-col' : ''}`} >
                    <div className={`m-1 ${mobile2 ? 'w-[99%]' : 'w-[55%] '} `}>
                        <div className="bg-white border   border-fuchsia-400 rounded-lg p-1 sm:p-4">
                            <div className="flex border-b border-blue-500 pb-4  flex-col">
                                <div className=" flex">
                                    <span className="text-[20px]  sm:text-3xl leading-none font-bold text-gray-900">
                                        2,340
                                    </span>

                                    <div className="ml-5 w-0 flex items-center justify-end flex-1 text-blue-500 text-xl font-bold">
                                        100%
                                        <svg
                                            className="w-5 h-5"
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fill-rule="evenodd"
                                                d="M5.293 7.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L6.707 7.707a1 1 0 01-1.414 0z"
                                                clip-rule="evenodd"
                                            ></path>
                                        </svg>
                                    </div>
                                </div>
                                <h3 className="text-[18px] max-sm:w-full text-center mt-4 sm:text-xl font-normal text-gray-500">
                                    Nombres des élèves
                                </h3>
                            </div>

                            <div className="flex">
                                <div className="flex  flex-col border-r w-1/2 pr-2 border-blue-500">
                                    <div className="flex justify-between">
                                        <span className={` leading-none font-bold text-gray-900 ${mobile4 ? 'text-[12px]' : 'stext-xl'}`}>
                                            1,340
                                        </span>

                                        <div className={` ml-5 w-0 flex items-center justify-end flex-1 text-blue-500  font-bold ${mobile4 ? 'text-[12px]' : ''}`}>
                                            52%
                                            <svg
                                                className="w-5 h-5"
                                                fill="currentColor"
                                                viewBox="0 0 20 20"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M5.293 7.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L6.707 7.707a1 1 0 01-1.414 0z"
                                                    clip-rule="evenodd"
                                                ></path>
                                            </svg>
                                        </div>
                                    </div>
                                    <h3 className={` max-sm:w-full text-center mt-4 sm:text-xl font-normal text-gray-500 ${mobile4 ? 'text-[11px]' : 'text-[18px]'}`}>
                                        Malades
                                    </h3>
                                </div>

                                <div className="flex border-l border-r w-1/2  border-blue-500 pl-2 flex-col">
                                    <div className=" flex">
                                        <span className={` leading-none font-bold text-gray-900 ${mobile4 ? 'text-[12px]' : 'stext-xl'}`}>

                                            340
                                        </span>

                                        <div className={`ml-5 w-0 flex items-center justify-end flex-1 text-blue-500  font-bold ${mobile4 ? 'text-[12px]' : ''}`}>

                                            10%
                                            <svg
                                                className="w-5 h-5"
                                                fill="currentColor"
                                                viewBox="0 0 20 20"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M5.293 7.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L6.707 7.707a1 1 0 01-1.414 0z"
                                                    clip-rule="evenodd"
                                                ></path>
                                            </svg>
                                        </div>
                                    </div>
                                    <h3 className={` max-sm:w-full text-center mt-4 sm:text-xl font-normal text-gray-500 ${mobile4 ? 'text-[11px]' : 'text-[18px]'}`}>
                                        Hospitalisés
                                    </h3>
                                </div>

                                <div className="flex border-l w-1/2  border-blue-500 pl-2 flex-col">
                                    <div className=" flex">
                                        <span className={` leading-none font-bold text-gray-900 ${mobile4 ? 'text-[12px]' : 'stext-xl'}`}>

                                            940
                                        </span>

                                        <div className={`ml-5 w-0 flex items-center justify-end flex-1 text-blue-500  font-bold ${mobile4 ? 'text-[12px]' : ''}`}>

                                            38%
                                            <svg
                                                className="w-5 h-5"
                                                fill="currentColor"
                                                viewBox="0 0 20 20"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M5.293 7.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L6.707 7.707a1 1 0 01-1.414 0z"
                                                    clip-rule="evenodd"
                                                ></path>
                                            </svg>
                                        </div>
                                    </div>
                                    <h3 className={` max-sm:w-full text-center mt-4 sm:text-xl font-normal text-gray-500 ${mobile4 ? 'text-[11px]' : 'text-[18px]'}`}>
                                        Sains
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className={`m-1 ${mobile2 ? 'w-[99%]' : 'w-[45%]'} `}>
                        <div className="bg-white border border-fuchsia-400 rounded-lg p-4 ">
                            <div className="flex border-b border-blue-500 pb-4  flex-col">
                                <div className=" flex">
                                    <span className="text-[20px]  sm:text-2xl leading-none font-bold text-gray-900">
                                        2,340
                                    </span>

                                    <div className="ml-5 w-0 flex items-center justify-end flex-1 text-xl text-blue-500  font-bold">
                                        100%
                                        <svg
                                            className="w-5 h-5"
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fill-rule="evenodd"
                                                d="M5.293 7.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L6.707 7.707a1 1 0 01-1.414 0z"
                                                clip-rule="evenodd"
                                            ></path>
                                        </svg>
                                    </div>
                                </div>
                                <h3 className="text-[18px] max-sm:w-full text-center mt-4 sm:text-xl font-normal text-gray-500">
                                    Nombres des matériels
                                </h3>
                            </div>

                            <div className=" flex justify-between">
                                <div className="flex  flex-col border-r w-1/2 pr-2 border-blue-500">
                                    <div className=" flex">
                                        <span className={` leading-none font-bold text-gray-900 ${mobile4 ? 'text-[12px]' : 'stext-xl'}`}>

                                            1,340
                                        </span>

                                        <div className={`ml-5 w-0 flex items-center justify-end flex-1 text-blue-500  font-bold ${mobile4 ? 'text-[12px]' : ''}`}>

                                            52%
                                            <svg
                                                className="w-5 h-5"
                                                fill="currentColor"
                                                viewBox="0 0 20 20"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M5.293 7.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L6.707 7.707a1 1 0 01-1.414 0z"
                                                    clip-rule="evenodd"
                                                ></path>
                                            </svg>
                                        </div>
                                    </div>
                                    <h3 className={` max-sm:w-full text-center mt-4 sm:text-xl font-normal text-gray-500 ${mobile4 ? 'text-[11px]' : 'text-[18px]'}`}>
                                        Défectueux
                                    </h3>
                                </div>

                                <div className="flex border-l w-1/2  border-blue-500 pl-2 flex-col">
                                    <div className=" flex">
                                        <span className={` leading-none font-bold text-gray-900 ${mobile4 ? 'text-[12px]' : 'stext-xl'}`}>

                                            340
                                        </span>

                                        <div className={`ml-5 w-0 flex items-center justify-end flex-1 text-blue-500  font-bold ${mobile4 ? 'text-[12px]' : ''}`}>

                                            48%
                                            <svg
                                                className="w-5 h-5"
                                                fill="currentColor"
                                                viewBox="0 0 20 20"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M5.293 7.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L6.707 7.707a1 1 0 01-1.414 0z"
                                                    clip-rule="evenodd"
                                                ></path>
                                            </svg>
                                        </div>
                                    </div>
                                    <h3 className={` max-sm:w-full text-center mt-4 sm:text-xl font-normal text-gray-500 ${mobile4 ? 'text-[11px]' : 'text-[18px]'}`}>
                                        Fonctionnel
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FootterCopy />
        </div>


    );
}

export default AcceuilPrefetDisplin;