/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function NavBarsPrefetDisplin({ GetMobileSize, GetshowMenu }) {

    const [mobile, SetMobile] = useState(window.innerWidth < 1292)
    const [mobile1, SetMobile1] = useState(window.innerWidth < 501)

    const [mobile2, SetMobile2] = useState(window.innerWidth < 317)
    const [showMenu, SetshowMenu] = useState(false)
    useEffect(() => {

        const hundleSize = () => {
            SetMobile(window.innerWidth < 1292)
            SetMobile1(window.innerWidth < 501)
            SetMobile2(window.innerWidth < 317)
            SetshowMenu(false)
        }

        window.addEventListener('resize', hundleSize)

        return () => {
            window.removeEventListener('resize', hundleSize)

        }
    }, [])

    GetMobileSize(mobile)
    GetshowMenu(showMenu)

    window.onclick = (() => {
        SetshowMenu(false)
    })

    return (
        <>

            <div className={` bg-white  pr-2 border-b-2 border-blue-700 flex  justify-between items-center ${mobile1 ? 'h-[10vh]' : 'h-[15vh]'}`}>
                <div className="flex items-center h-full  w-max" >
                    {mobile && <div onClick={(e) => { SetshowMenu(!showMenu); e.stopPropagation() }} className={` cursor-pointer hover:bg-gray-100 px-2 py-1 ${mobile1 ? 'mr-1 ml-1' : 'mr-4 ml-2'}`}>
                        <svg
                            id="toggleSidebarMobileHamburger"
                            class="w-6 h-6"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                                clip-rule="evenodd"
                            ></path>
                        </svg>
                        <svg
                            id="toggleSidebarMobileClose"
                            class="w-6 h-6 hidden"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                clip-rule="evenodd"
                            ></path>
                        </svg>
                    </div>}

                    <Link to='/' className={`${mobile ? '' : 'ml-3'} ${mobile1 ? 'w-[50px] h-[50px]' : 'w-[90px] h-[90px]'}`} >
                        <img src="/images/logo.png" className="w-full h-full object-cover object-center" />
                    </Link>
                    <Link to='/' class={`flex-row font-bold ${mobile2 ? 'hidden' : 'flex '} ${mobile1 ? 'text-[18px]' : 'text-3xl'}  ml-2`}>
                        <span class="self-center whitespace-nowrap mb-2">L</span>
                        <span class="self-center whitespace-nowrap text-green-900 ">
                            E
                        </span>
                        <span class="self-center whitespace-nowrap mb-2 text-red-500">
                            N
                        </span>
                        <span class="self-center whitespace-nowrap text-red-500">
                            G
                        </span>
                        <span class="self-center whitespace-nowrap mb-2 text-red-500">
                            A
                        </span>
                    </Link>
                </div>

                <div className="flex  h-full w-max items-center">
                    <span class={` cursor-pointer bg-gray-200 border-blue-500 border p-2 mx-2 rounded-full flex items-center justify-center ${mobile1 ? 'h-9 w-9' : 'h-12 w-12'}`}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"

                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                            />
                        </svg>
                    </span>
                    <span class={` cursor-pointer bg-gray-200 border-blue-500 border p-2 mx-2 rounded-full flex items-center justify-center ${mobile1 ? 'h-9 w-9' : 'h-12 w-12'}`}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 h-6"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"
                            />
                        </svg>
                    </span>
                    <div className=" flex items-center cursor-pointer">
                        <span class={` cursor-pointer bg-gray-200 border-blue-500 border  rounded-full flex items-center justify-center ${mobile1 ? 'h-9 w-9' : 'h-12 w-12'}`}>
                            <img
                                class="h-full w-full rounded-full border-blue-500 border object-cover"
                                src="./images/sample.png"

                            />
                        </span>
                        <span class="hidden text-right mx-2  lg:block">
                            <span class="block text-lg font-medium ">
                                Thomas Anree
                            </span>
                            <span class="block text-lg font-medium text-gray-500 font-serif">Prefet de displine</span>
                        </span>
                        <svg
                            class="hidden fill-current text-gray-500 sm:block"
                            width="12"
                            height="8"
                            viewBox="0 0 12 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M0.410765 0.910734C0.736202 0.585297 1.26384 0.585297 1.58928 0.910734L6.00002 5.32148L10.4108 0.910734C10.7362 0.585297 11.2638 0.585297 11.5893 0.910734C11.9147 1.23617 11.9147 1.76381 11.5893 2.08924L6.58928 7.08924C6.26384 7.41468 5.7362 7.41468 5.41077 7.08924L0.410765 2.08924C0.0853277 1.76381 0.0853277 1.23617 0.410765 0.910734Z"
                                fill=""
                            ></path>
                        </svg>
                    </div>
                </div>
            </div>
        </>
    );
}

export default NavBarsPrefetDisplin;
