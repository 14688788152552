import React, { useState } from "react";
import ListeLivresComponent from "../../component/Bibliotheque/Emprunter/ListeLivresComponent";
function ListeLivresPage({ getSizeMobille, getMenuMobille }) {
  return (
    <>
      <ListeLivresComponent getMenuMobille={getMenuMobille} getSizeMobille={getSizeMobille} />
    </>
  );
}

export default ListeLivresPage;
