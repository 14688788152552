import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FootterCopy from "../../FootterCopy/FootterCopy";
function Emprunter({ getSizeMobille }) {

  const [mobile1, Setmonile1] = useState(window.innerWidth < 400)

  const [mobile11, SetMobile11] = useState(window.innerWidth < 501)

  useEffect(() => {
    const hundlesSize = () => {
      Setmonile1(window.innerWidth < 400)
      SetMobile11(window.innerWidth < 501)
    }

    window.addEventListener('resize', hundlesSize)

    return () => {
      window.removeEventListener('resize', hundlesSize)
    }
  }, [])


  return (

    <div className={`bg-white  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>   <div className="sm:p-4 p-2 min-h-screen">
      <div class="my-10 flex items-center justify-center">
        <h3 class="text-xl sm:text-2xl max-sm:text-center  tracking-widest  leading-none font-bold text-gray-900">
          Qui Voulez-vous Emprunter ?
        </h3>
      </div>

      <div class="my-4 w-full items-center flex flex-col gap-4">
        <Link
          to="/Emprunter/eleve"
          class={`bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 cursor-pointer hover:bg-gray-100s hover:shadow-lg transition duration-300 ease-in-out hover:scale-105 transform ${mobile1 ? 'w-[90%]' : ''}`}
        >
          <div class="flex items-center">
            <div class="flex-shrink-0">
              <h3 class="text-xl font-normal text-center text-gray-700">Eléve</h3>
            </div>
          </div>
        </Link>
        <Link
          to="/Emprunter/enseignant"
          class={`bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 cursor-pointer hover:bg-gray-100s hover:shadow-lg transition duration-300 ease-in-out hover:scale-105 transform ${mobile1 ? 'w-[90%]' : ''}`}
        >
          <div class="flex items-center">
            <div class="flex-shrink-0">
              <h3 class="text-xl font-normal text-gray-700">
                Enseignant
              </h3>
            </div>
          </div>
        </Link>
        <Link
          to="/Emprunter/personnel"
          class={`bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 cursor-pointer hover:bg-gray-100s hover:shadow-lg transition duration-300 ease-in-out hover:scale-105 transform ${mobile1 ? 'w-[90%]' : ''}`}
        >
          <div class="flex items-center">
            <div class="flex-shrink-0">
              <h3 class="text-xl font-normal text-gray-700">
                Autre Personel
              </h3>
            </div>
          </div>
        </Link>
        <Link
          to="/Emprunter/visitor"
          class={`bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 cursor-pointer hover:bg-gray-100s hover:shadow-lg transition duration-300 ease-in-out hover:scale-105 transform ${mobile1 ? 'w-[90%]' : ''}`}
        >
          <div class="flex items-center">
            <div class="flex-shrink-0">
              <h3 class="text-xl font-normal text-gray-700">Visiteur</h3>
            </div>
          </div>
        </Link>
      </div>
    </div>
      <FootterCopy />

    </div>

  );
}

export default Emprunter;
