
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import FootterCopy from "../../FootterCopy/FootterCopy";
import { Link } from "react-router-dom";
import { API } from "../../../Api/API";
import { encryptData } from "../../../encryptionModule";

function SuiveurPrefetDisplin({ getSizeMobille }) {
    const [mobile, Setmonile] = useState(window.innerWidth < 1575)
    const [mobile2, Setmonile2] = useState(window.innerWidth < 842)
    const [mobile3, Setmonile3] = useState(window.innerWidth < 318)
    const [mobile4, Setmonile4] = useState(window.innerWidth < 600)
    const [mobile11, SetMobile11] = useState(window.innerWidth < 501)

    useEffect(() => {
        const hundlesSize = () => {
            Setmonile(window.innerWidth < 1575)
            Setmonile2(window.innerWidth < 842)
            Setmonile3(window.innerWidth < 318)
            Setmonile4(window.innerWidth < 600)
            SetMobile11(window.innerWidth < 501)
        }
        window.addEventListener('resize', hundlesSize)
        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])

    const [CountByClassAndLasterSchoolYear, setCountByClassAndLasterSchoolYear] = useState([])
    useEffect(() => {
        API.get('/student/CountByClassAndLasterSchoolYear').then((resp) => {
            console.log(resp.data)
            setCountByClassAndLasterSchoolYear(resp.data)
        }).catch((err) => {
            console.log(err.message)
        })
    }, [])


    return (

        <div className={`bg-white  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
            <div className="sm:p-4 p-2 min-h-screen">
                <div classNameName="sm:p-4 p-2 ">
                    <div className="sm:my-10 my-2">
                        <div className="w-full flex flex-row items-center justify-between">
                            <p className="text-navy-700 shrink  text-[25px]  text-gray-600">
                                <p className="hover:text-navy-700 font-bold  text-gray-600 font-serif"                                >
                                    Liste des classes
                                </p>
                            </p>
                        </div>
                    </div>
                </div>
                <div className={`my-4 w-full grid  gap-2 p-2 sm:p-4  ${mobile4 ? 'grid-cols-1' : mobile2 ? 'grid-cols-2' : mobile ? 'grid-cols-3' : 'grid-cols-4'}`}>
                    {CountByClassAndLasterSchoolYear.map((value, posi) => {
                        return (
                            <div key={posi} className={`bg-white border border-fuchsia-400 rounded-lg p-4 `}>
                                <div className="flex   flex-col">
                                    <div className=" flex  flex-col">
                                        <span className={`leading-none font-bold text-gray-900 ${mobile3 ? 'text-[18px]' : 'sm:text-3xl text-[20px]   '}`}>
                                            {value.class}
                                        </span>
                                        <div className={` text-gray-500  font-bold mt-2 ${mobile3 ? 'text-[18px]' : 'text-xl'} `}>
                                            Nombres des élèves : <span>{value.totaleleves}</span>
                                        </div>
                                    </div>
                                    <div className={`text-[18px] flex justify-between  max-sm:w-full text-center mt-4 font-normal text-gray-500 ${mobile3 ? 'flex-col' : ''}`}>
                                        <div className={`p-2 bg-[#0000ff07] border border-fuchsia-100 rounded-lg ${mobile3 ? 'mb-3' : ''} `}>
                                            <div className={`font-bold ${mobile3 ? 'text-[15px]' : ''}`}>Garçons</div>
                                            <div className={`flex j ${mobile3 ? 'w-full justify-around' : 'w-24 justify-between'}`}>
                                                <div className={`font-semibold text-blue-500 ${mobile3 ? 'text-[12px]' : ''}`}>{value.garcon}</div>
                                                <div>~</div>
                                                <div className={`font-semibold text-blue-700 ${mobile3 ? 'text-[12px]' : ''}`}> {parseFloat((value.garcon * 100 / value.totaleleves).toFixed(1))}% </div>
                                            </div>
                                        </div>
                                        <div className={`p-2 bg-[#0000ff07] border border-fuchsia-100 rounded-lg ${mobile3 ? 'mb-3' : ''} `}>
                                            <div className={`font-bold ${mobile3 ? 'text-[15px]' : ''}`}>Fille</div>
                                            <div className={`flex j ${mobile3 ? 'w-full justify-around' : 'w-24 justify-between'}`}>
                                                <div className={`font-semibold text-blue-500 ${mobile3 ? 'text-[12px]' : ''}`}> {value.Filles}</div>
                                                <div>~</div>
                                                <div className={`font-semibold text-blue-700 ${mobile3 ? 'text-[12px]' : ''}`}>{parseFloat((value.Filles * 100 / value.totaleleves).toFixed(1))}%</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex mt-2 text-gray-600"><div>Année scolaire :</div> <div className="text-blue-700 font-semibold ml-1"> {value.aneescholaire}</div></div>
                                </div>
                                <div className="w-full flex justify-center items-center mt-4">
                                    <Link to={`/Suiveur/consult/${encryptData((value.schoolyearId).toString())}/${encryptData((value.classId).toString())}`}
                                        className="border border-blue-100 px-4 py-1 bg-blue-400 hover:bg-blue-600 transition-all text-white rounded-md cursor-pointer">
                                        Consulter
                                    </Link>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            <FootterCopy />
        </div>


    );
}

export default SuiveurPrefetDisplin;