import React from "react";
import AcceuilInfermiereComponent from "../../component/Infirmerie/AcceuilInfermiere/AcceuilInfermiereComponent";
function AccueilInfirmPage({ getSizeMobille, getMenuMobille }) {
  return (
    <>
      <AcceuilInfermiereComponent getMenuMobille={getMenuMobille} getSizeMobille={getSizeMobille} />
    </>
  );
}

export default AccueilInfirmPage;
