import React, { useState } from "react";
import SideBarSecretaire from "../../SideBarSecretaire/SideBarSecretaire";
import DetailExpedier from "./DetailRecus";
function DetailExpedierComponent({ getSizeMobille, getMenuMobille }) {
  return (
    <>
      {" "}
      <div class="flex overflow-hidden bg-white justify-between">
        <SideBarSecretaire getMenuMobille={getMenuMobille} />
        <DetailExpedier getSizeMobille={getSizeMobille} />
      </div>
    </>
  );
}

export default DetailExpedierComponent;
