/* eslint-disable no-use-before-define */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";

function AjouterDemmandeEnse() {
  const [url, SetUrl] = useState()


  return (
    <>
      <div
        id="main-content"
        class="h-full w-full bg-gray-50 relative overflow-y-auto lg:ml-64"
      >
        <main>
          <div class="pt-6 px-4">
            <div class="my-5">
              <div class="w-full flex flex-row items-center justify-between">
                <p class="text-navy-700 shrink first-letter:uppercase">
                  <a
                    class="hover:text-navy-700 text-2xl font-bold "

                  >
                    Nouvelle demande
                  </a>
                </p>
                <div class="h-6 w-[224px] pt-1">
                  <a
                    class="text-navy-700 text-lg font-normal hover:underline  "
                    href=" "
                  >
                    Pages
                    <span class="text-navy-700 hover:text-navy-700 mx-1 text-lg ">
                      /
                    </span>
                  </a>
                  <a
                    class="text-navy-700 text-lg font-normal  hover:underline  "

                  >
                    Ajouter
                  </a>
                </div>
              </div>
            </div>
            <div class="w-full my-4 grid grid-cols-1 ">
              <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">
                {/* <!-- component --> */}
                <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col my-2">
                  <div class="-mx-3 md:flex mb-6">
                    <div class="md:w-1/2 px-3 mb-6 md:mb-0">
                      <label
                        class="block uppercase tracking-wide text-grey-darker text-lg font-bold mb-2"
                        for="grid-Title"
                      >
                        Libellé
                      </label>
                      <input
                        class="appearance-none block w-full bg-grey-lighter text-grey-darker border border-red rounded py-3 px-4 mb-3"
                        id="grid-Title"
                        type="text"
                        placeholder="Libellé"
                      />
                    </div>
                    <div class="md:w-1/2 px-3">
                      <label
                        class="block uppercase tracking-wide text-grey-darker text-lg font-bold mb-2"
                        for="grid-last-name"
                      >
                        Montant(P.U)
                      </label>
                      <input
                        class="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4"
                        id="grid-last-name"
                        type="number"
                        placeholder="Montant(P.U)"
                      />
                    </div>
                  </div>
                  <div class="-mx-3 md:flex mb-6">
                    <div class="md:w-1/2 px-3">
                      <label
                        class="block uppercase tracking-wide text-grey-darker text-lg font-bold mb-2"
                        for="grid-password"
                      >
                        Quantite
                      </label>
                      <input
                        class="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4 mb-3"
                        id="grid-password"
                        type="number"
                        min="1"
                        placeholder=" Quantite.."
                      />
                    </div>

                  </div>

                  <div class="mb-4 flex items-center justify-end">
                    <div class="flex-shrink-0">
                      <button class="mt-4 sm:mt-0 inline-flex items-start justify-start px-6 py-3 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg">
                        <p class="text-lg font-medium leading-none text-white">
                          Demander
                        </p>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default AjouterDemmandeEnse;
