/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import FootterCopy from "../../FootterCopy/FootterCopy";
import { API } from "../../../Api/API";
import { decryptData, encryptData } from "../../../encryptionModule";

function DossierEleveSecretaire({ getSizeMobille }) {
  const [mobile1, Setmonile1] = useState(window.innerWidth < 990)
  const [mobile2, Setmonile2] = useState(window.innerWidth < 520)

  useEffect(() => {
    const hundlesSize = () => {
      Setmonile1(window.innerWidth < 990)
      Setmonile2(window.innerWidth < 520)
    }

    window.addEventListener('resize', hundlesSize)

    return () => {
      window.removeEventListener('resize', hundlesSize)
    }
  }, [])



  const { classId, schoolyearId } = useParams()
  const idClass = decryptData(classId)
  const idSchoolyear = decryptData(schoolyearId)
  const [student, setStudent] = useState([])
  const [classe, setClass] = useState({})
  const [as, setAS] = useState({})
  useEffect(() => {
    API.get(`/student/studentsInClass/${idClass}/${idSchoolyear}`).then((res) => {
      setStudent(res.data.students)
      setClass(res.data.classDetails)
      setAS(res.data.schoolyearDetails)
    }).catch((err) => {
      console.log(err.message)
    })
  }, [idClass, idSchoolyear])






  const [inputValue, setInputValue] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortedColumn, setSortedColumn] = useState(null);

  const ValuesInput = (e) => {
    setInputValue(e.target.value);
  };


  const fildata = (data, inputValue) => {
    if (!inputValue.trim()) {
      return data;
    }
    return data.filter((item) => {
      if (
        item.nomprenom.toLowerCase().includes(inputValue.toLowerCase()) ||
        item.matricule.toString().toLowerCase().includes(inputValue.toLowerCase()) ||
        item.nomP.toString().toLowerCase().includes(inputValue.toLowerCase()) ||
        item.nomM.toLowerCase().includes(inputValue.toLowerCase()) ||
        item.type_acces.toString().toLowerCase().includes(inputValue.toLowerCase()) ||
        item.sexe.toString().toLowerCase().includes(inputValue.toLowerCase())

      ) {
        return true;
      }
      return false;
    });
  };



  const handleSort = (column) => {
    if (column === sortedColumn) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortOrder('asc');
      setSortedColumn(column);
    }
  };

  const sortData = (data, column, sortOrder) => {
    return data.slice().sort((a, b) => {
      const aValue = a[column];
      const bValue = b[column];

      if (aValue < bValue) return sortOrder === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortOrder === 'asc' ? 1 : -1;
      return 0;
    });
  };

  const filteredData = fildata(student, inputValue);

  const sortedData = sortData(filteredData, sortedColumn, sortOrder);


  function highlightText(text, inputValue) {
    if (!inputValue) return text;

    const highlightedText = [];
    let currentIndex = 0;

    while (currentIndex < text.length) {
      const inputChar = inputValue.toLowerCase();
      const matchIndex = text.substr(currentIndex).toLowerCase().indexOf(inputChar);

      if (matchIndex !== -1) {

        highlightedText.push(text.substring(currentIndex, currentIndex + matchIndex));


        highlightedText.push(
          <span className="text-orange-500" key={currentIndex + matchIndex}>
            {text.substring(currentIndex + matchIndex, currentIndex + matchIndex + inputValue.length)}
          </span>
        );

        currentIndex = currentIndex + matchIndex + inputValue.length;
      } else {
        highlightedText.push(text.substring(currentIndex));
        break;
      }
    }

    return highlightedText;
  }







  return (
    <>
      <div className={`bg-white  overflow-x-auto  h-[85vh] ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
        <Link to='/ClasseChoixSecretaire' className="ml-2 text-[18px] text-blue-500 hover:text-blue-800 underline">Retour</Link>

        <div className="p-6 min-h-[90vh]">
          <div className="sm:text-[25px] max-sm:text-center text-gray-500 font-bold">{classe && classe.nom} ( {classe && classe.abreviation} ) {as && as.nom}</div>
          <div class="sm:flex items-center pr-4  h-max  justify-end">

            <div class="mx-2 mt-10">
              <div class="relative lg:w-64">
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <svg
                    class="w-5 h-5 text-gray-500"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
                <input
                  type="text"
                  name="email"
                  id="topbar-search"
                  class="bg-white border border-gray-300 h-10 text-gray-900 sm:text-lg first-letter:uppercase rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full pl-10 .5"
                  placeholder="Rechercher..."
                  onInput={ValuesInput}
                />
              </div>
            </div>
          </div>
          <div class="w-full my-4 grid grid-cols-1 ">
            <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">
              <div class={`b-4 flex items-center justify-between ${mobile2 ? 'flex-col' : ''}`}>
                <div>
                  <h3 class="sm:text-xl font-bold text-gray-900 mb-2">
                    Listes des  élèves
                  </h3>
                </div>

              </div>

              <div class="flex flex-col mt-8">
                <div class="overflow-x-auto rounded-lg">
                  <div class={`align-middle inline-block  ${mobile1 ? 'min-w-[60em]' : 'min-w-full'}`}>
                    <div class="shadow overflow-hidden sm:rounded-lg">





                      <table class="min-w-full divide-y  divide-gray-200">
                        <thead class="bg-gray-50">
                          <tr>
                            <th onClick={() => handleSort('nomprenom')}
                              scope="col"
                              class={`py-4 px-2 font-semibold relative border-l text-left cursor-pointer text-lg first-letter:uppercase    tracking-wider ${sortedColumn === 'nomprenom' ? 'text-blue-700' : 'text-gray-500'}`}>
                              Nom Complet
                              {sortedColumn === 'nomprenom' && <span className="pr-0 absolute right-2">{sortOrder === 'asc' ? '▲' : '▼'}</span>}
                            </th>



                            <th onClick={() => handleSort('matricule')}
                              scope="col"
                              class={`py-4 px-2 font-semibold relative border-l text-left cursor-pointer text-lg first-letter:uppercase    tracking-wider ${sortedColumn === 'matricule' ? 'text-blue-700' : 'text-gray-500'}`}>

                              matricule
                              {sortedColumn === 'matricule' && <span className="pr-0 absolute right-2">{sortOrder === 'asc' ? '▲' : '▼'}</span>}
                            </th>

                            <th onClick={() => handleSort('nomP')}
                              scope="col"
                              class={`py-4 px-2 font-semibold relative border-l text-left cursor-pointer text-lg first-letter:uppercase    tracking-wider ${sortedColumn === 'nomP' ? 'text-blue-700' : 'text-gray-500'}`}>

                              Nom Père
                              {sortedColumn === 'nomP' && <span className="pr-0 absolute right-2">{sortOrder === 'asc' ? '▲' : '▼'}</span>}

                            </th>
                            <th onClick={() => handleSort('nomM')}
                              scope="col"
                              class={`py-4 px-2 font-semibold relative border-l text-left cursor-pointer text-lg first-letter:uppercase    tracking-wider ${sortedColumn === 'nomM' ? 'text-blue-700' : 'text-gray-500'}`}>

                              Nom mère
                              {sortedColumn === 'nomM' && <span className="pr-0 absolute right-2">{sortOrder === 'asc' ? '▲' : '▼'}</span>}

                            </th>
                            <th onClick={() => handleSort('type_acces')}
                              scope="col"
                              class={`py-4 px-2 font-semibold relative border-l text-left cursor-pointer text-lg first-letter:uppercase    tracking-wider ${sortedColumn === 'type_acces' ? 'text-blue-700' : 'text-gray-500'}`}>

                              Interne/Externe
                              {sortedColumn === 'type_acces' && <span className="pr-0 absolute right-2">{sortOrder === 'asc' ? '▲' : '▼'}</span>}

                            </th>
                            <th onClick={() => handleSort('sexe')}
                              scope="col"
                              class={`py-4 px-2 font-semibold relative border-l text-left cursor-pointer text-lg first-letter:uppercase    tracking-wider ${sortedColumn === 'sexe' ? 'text-blue-700' : 'text-gray-500'}`}>

                              Genre
                              {sortedColumn === 'sexe' && <span className="pr-0  right-2">{sortOrder === 'asc' ? '▲' : '▼'}</span>}

                            </th>
                            <th
                              scope="col"
                              class=" py-4 px-2 border-l text-left cursor-pointer relative text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                            >
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody class="bg-white">
                          {sortedData.length > 0 && sortedData.map((data, index) => {
                            return (<tr key={index}>
                              <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                {highlightText(data.nomprenom, inputValue)}
                              </td>


                              <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase  text-gray-500">

                                {highlightText(data.matricule.toString(), inputValue)}
                              </td>
                              <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase  text-gray-500">

                                {highlightText(data.nomP.toString(), inputValue)}
                              </td>
                              <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase  text-gray-500">

                                {highlightText(data.nomM, inputValue)}
                              </td>
                              <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase  text-gray-500">

                                {highlightText(data.type_acces.toString(), inputValue)}
                              </td>
                              <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">

                                {highlightText(data.sexe.toString(), inputValue)}
                              </td>
                              <td class="border-b border-gray-200  pt-2 px-3  border-l border-r whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                <Link
                                  to={`/ClasseChoixSecretaire/DossierEleve/DetailDossier/${encryptData((data.id).toString())}/${schoolyearId}/${classId}`}
                                  class="my-2 sm:mt-0 inline-flex items-start justify-start px-3 py-2 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg"
                                >
                                  <p class="text-lg first-letter:uppercase font-semibold leading-none text-white">
                                    Details
                                  </p>
                                </Link>
                              </td>
                            </tr>)
                          })}

                          {sortedData.length == 0 &&
                            <tr>
                              <td colSpan={7} className="h-[50vh] w-full text-center text-2xl text-gray-400"> Aucun élément correspondant à <span className="font-bold">{inputValue}</span> </td>
                            </tr>}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FootterCopy />
      </div>

    </>
  );
}

export default DossierEleveSecretaire;
