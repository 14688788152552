import React from 'react'
import SideBarBibliote from '../SideBar/SideBarBibliote'
import CongeBibliothe from './CongeBibliothe'
function CongeBibliotheComponent({ getSizeMobille, getMenuMobille }) {
    return (
        <div className='flex justify-between overflow-hidden bg-white'>
            <SideBarBibliote getMenuMobille={getMenuMobille} />
            <CongeBibliothe getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default CongeBibliotheComponent
