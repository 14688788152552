/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable eqeqeq */
import React, { Component, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import FootterCopy from "../../FootterCopy/FootterCopy";
import { chambresList } from "../../../Data/Data";
import { useReactToPrint } from "react-to-print";
import { FadeLoader } from "react-spinners";
import { toast } from "react-toastify";


function useSortedData(chambresList, inputValue, sortedColumn, sortOrder) {
    const fildata = (data, inputValue) => {
        if (!inputValue.trim()) {
            return data;
        }
        return data.filter((item) => {
            if (
                item.Numero_Chambre.toLowerCase().includes(inputValue.toLowerCase())
            ) {
                return true;
            }
            return false;
        });
    };


    const sortData = (data, column, sortOrder) => {
        return data.slice().sort((a, b) => {
            const aValue = a[column];
            const bValue = b[column];

            if (aValue < bValue) return sortOrder === 'asc' ? -1 : 1;
            if (aValue > bValue) return sortOrder === 'asc' ? 1 : -1;
            return 0;
        });
    };

    const filteredData = fildata(chambresList, inputValue);
    const sortedData = sortData(filteredData, sortedColumn, sortOrder);

    return sortedData;
}



function HomeGarconDetailDortoirePrefetDisplin({ getSizeMobille }) {
    const [mobile1, Setmonile1] = useState(window.innerWidth < 990)
    const [mobile2, Setmonile2] = useState(window.innerWidth < 520)
    const [litNumber, SetlitNumber] = useState(false)
    const elementRefNumberLit = useRef(null)
    useEffect(() => {
        const hundlesSize = () => {
            Setmonile1(window.innerWidth < 990)
            Setmonile2(window.innerWidth < 520)
        }
        window.addEventListener('resize', hundlesSize)

        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])


    const [inputValue, setInputValue] = useState('');
    const [sortOrder, setSortOrder] = useState('asc');
    const [sortedColumn, setSortedColumn] = useState(null);

    const ValuesInput = (e) => {
        setInputValue(e.target.value);
    };




    const handleSort = (column) => {
        if (column === sortedColumn) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortOrder('asc');
            setSortedColumn(column);
        }
    };

    const newData = useSortedData(chambresList, inputValue, sortedColumn, sortOrder);
    const [sortedData, setSortedData] = useState(chambresList);

    useEffect(() => {
        setSortedData(newData);
    }, [chambresList, inputValue, sortedColumn, sortOrder]);






    function highlightText(text, inputValue) {
        if (!inputValue) return text;

        const highlightedText = [];
        let currentIndex = 0;

        while (currentIndex < text.length) {
            const inputChar = inputValue.toLowerCase();
            const matchIndex = text.substr(currentIndex).toLowerCase().indexOf(inputChar);

            if (matchIndex !== -1) {

                highlightedText.push(text.substring(currentIndex, currentIndex + matchIndex));


                highlightedText.push(
                    <span className="text-orange-500" key={currentIndex + matchIndex}>
                        {text.substring(currentIndex + matchIndex, currentIndex + matchIndex + inputValue.length)}
                    </span>
                );

                currentIndex = currentIndex + matchIndex + inputValue.length;
            } else {
                highlightedText.push(text.substring(currentIndex));
                break;
            }
        }

        return highlightedText;
    }



    const [mobile11, SetMobile11] = useState(window.innerWidth < 501)

    useEffect(() => {
        const hundlesSize = () => {
            SetMobile11(window.innerWidth < 501)
        }
        window.addEventListener('resize', hundlesSize)
        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])

    const [IsPrinting, setIsPrinting] = useState(false);

    const [cahsPrintAll, SetPrintAll] = useState(false)
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        onBeforePrint: () => {
            setIsPrinting(true);
        },
        onAfterPrint: () => {
            setIsPrinting(false);
        },
        onPrintError: (error) => {
            console.error('Erreur d\'impression :', error);
        },
    });


    useEffect(() => {
        window.onafterprint = () => {
            setIsPrinting(false);
        };
        return () => {
            window.onafterprint = null;
        };
    }, []);

    const [valueNumeroLit, GetValueNumeroLit] = useState('')

    const HundlesSubmit = (e) => {
        e.preventDefault()
        if (valueNumeroLit.trim() == '') {
            toast.warning('Le numéro de lit est obligatoire')
            elementRefNumberLit.current.focus()
            return false
        } else {
            toast.success('Ajout avec success')
            SetlitNumber(false)
        }

    }
    return (
        <>
            <div className={`bg-white  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
                <Link to='/Dortoire/garcon/comparti' className="ml-2 text-[18px] text-blue-500 hover:text-blue-800 underline">Retour</Link>
                <div className="p-6 min-h-[90vh]">
                    <div class="sm:flex items-center pr-4  h-max  justify-end">
                        <div class="mx-2">
                            <div class="relative lg:w-64">
                                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <svg
                                        class="w-5 h-5 text-gray-500"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                            clip-rule="evenodd"
                                        ></path>
                                    </svg>
                                </div>
                                <input
                                    Nom_Eleve="text"
                                    name="email"
                                    id="topbar-search"
                                    class="bg-white border border-gray-300 h-10 text-gray-900 sm:text-lg first-letter:uppercase rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full pl-10 .5"
                                    placeholder="Rechercher..."
                                    onInput={ValuesInput}
                                />
                            </div>
                        </div>
                        <button class="mt-4 sm:mt-0 inline-flex items-start justify-start px-1 py-3 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg">
                            <p class="text-lg first-letter:uppercase font-semibold leading-none text-white">
                                Rerchercher
                            </p>
                        </button>
                    </div>
                    <div class="w-full my-4 ">
                        <div class="bg-white shadow rounded-lg p-4 ">
                            <div class={`b-4 flex items-center relative justify-between ${mobile2 ? 'flex-col' : ''}`}>

                                {IsPrinting ? (
                                    <>
                                        <div className="w-max flex justify-center relative py-2 px-4">
                                            <div disabled onClick={() => { SetPrintAll(true); setTimeout(() => { handlePrint() }, 0); setTimeout(() => { SetPrintAll(false); }, 0); }} className="border border-blue-100 px-4 py-1 cursor-no-drop w-max opacity-50  bg-green-500 ml-2 text-green-800 transition-all  rounded-md ">Imprimer</div>
                                            <div disabled className='absolute cursor-no-drop  bg-transparent pt-2  pl-8  w-full h-full flex justify-center items-center z-50'>
                                                <FadeLoader
                                                    color="rgb(255, 255, 255)"
                                                    height={10}
                                                    margin={-9}
                                                    radius={100}
                                                    speedMultiplier={1}
                                                    width={1}
                                                />
                                            </div>
                                        </div>

                                    </>
                                ) : (
                                    <>
                                        <div className="w-max flex justify-end py-2 px-4">
                                            <div onClick={() => { SetPrintAll(true); setTimeout(() => { handlePrint() }, 0); setTimeout(() => { SetPrintAll(false); }, 0); }} className="border border-blue-100 px-4 py-1 w-max bg-green-200 ml-2 text-green-600 transition-all  rounded-md cursor-pointer">Imprimer</div>
                                        </div>
                                    </>)}


                                <div>
                                    <h3 class="sm:text-xl font-bold text-gray-900 mb-2">
                                        Listes des élèves par lit
                                    </h3>
                                </div>

                                {litNumber &&
                                    <div className="absolute bg-[#0000003f] w-[15em] z-50 top-0 h-full flex justify-center items-center     right-0">
                                        <div className="w-[95%] bg-white px-[4px] rounded h-[90%] flex items-center">
                                            <div onClick={() => { SetlitNumber(false) }} className="bg-red-500 rounded-full cursor-pointer flex justify-center items-center text-white m-[5px]">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                                                </svg>
                                            </div>
                                            <form onSubmit={HundlesSubmit} className="flex w-[90%]">
                                                <div className="border border-blue-300 w-[100%] h-8 rounded-md overflow-hidden">
                                                    <input ref={elementRefNumberLit} value={valueNumeroLit} onInput={(e) => GetValueNumeroLit(e.target.value)} className="outline-none w-full border-2 focus:border-blue-900  overflow-hidden  h-full sm:text-[12px] text-[10px] pl-2" placeholder="Numéro du lit ..." />
                                                </div>

                                                <button type="submit" className="bg-green-500 rounded-full cursor-pointer flex justify-center items-center text-white m-[5px]">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill w-5 h-5" viewBox="0 0 16 16">
                                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                                    </svg>
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                }

                                <div onClick={() => { SetlitNumber(true) }} class=" font-bold text-gray-900  bg-blue-200 p-2 rounded-full cursor-pointer">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle-fill w-6 text-blue-700 h-6" viewBox="0 0 16 16">
                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z" />
                                    </svg>
                                </div>
                            </div>
                            <div class="flex flex-col mt-8 ">
                                <div class="overflow-x-auto rounded-lg">
                                    <div class={`align-middle inline-block  min-w-full`}>
                                        <table className='w-full border border-gray-50 overflow-hidden'>
                                            <thead className="bg-gray-50">
                                                <tr className='border'>
                                                    <th rowSpan={2} onClick={() => handleSort('Numero_Chambre')} class=" py-4 px-2 font-semibold relative border-l text-left cursor-pointer text-lg first-letter:uppercase  text-gray-500  tracking-wider">Nom du lit {sortedColumn === 'Numero_Chambre' && <span className="pr-0 absolute right-2">{sortOrder === 'asc' ? '▲' : '▼'}</span>}</th>
                                                    <th colSpan={2} className=" py-2 px-2 font-semibold relative border-l text-center  text-lg first-letter:uppercase  text-gray-500  tracking-wider"> Elève</th>
                                                    <th class=" py-4 px-2 font-semibold relative border-l text-left  text-lg first-letter:uppercase  text-gray-500  tracking-wider" rowSpan={2} className='border-l'>Action</th>
                                                </tr>
                                                <tr className='border-b'>
                                                    <th class="px-2  py-2 font-semibold relative border-l text-left  text-lg first-letter:uppercase  text-gray-500  tracking-wider">Nom complet</th>
                                                    <th class="px-2 py-2 font-semibold relative border-l text-left  text-lg first-letter:uppercase  text-gray-500  tracking-wider">Classe</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {sortedData.map((data, index) => (
                                                    <tr key={index} className='border-b border-t text-lg  text-gray-600 border-gray-300 p-2'>
                                                        <td className='border-l pl-3  '>{highlightText(data.Numero_Chambre, inputValue)}</td>
                                                        <td className='border-l py-1'>
                                                            {data.eleves.map((datas, cmdIndex) => (
                                                                cmdIndex == 0 ? (<>
                                                                    <div className=' w-full h-full px-2' key={cmdIndex}>
                                                                        <span>{cmdIndex + 1}.</span><span>{datas.Nom_Eleve}</span>
                                                                    </div>
                                                                </>) : (<>
                                                                    <div className='border-t w-full h-full px-2' key={cmdIndex}>
                                                                        <span>{cmdIndex + 1}.</span><span>{datas.Nom_Eleve}</span>
                                                                    </div>
                                                                </>)
                                                            ))}

                                                        </td>
                                                        <td className='border-l py-1'>
                                                            {data.eleves.map((datas, cmdIndex) => (
                                                                cmdIndex == 0 ? (<>
                                                                    <div className='border-b  w-full h-full' key={cmdIndex}>
                                                                        <p className='px-2'>{datas.Classe}</p>
                                                                    </div>
                                                                </>) : (<>
                                                                    <div className='border-t w-full h-full' key={cmdIndex}>
                                                                        <p className='px-2'>{datas.Classe}</p>
                                                                    </div>
                                                                </>)
                                                            ))}


                                                        </td>
                                                        <td className='border-l pl-3'>
                                                            <button className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>
                                                                Modifier
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                                {sortedData.length == 0 &&
                                                    <tr>
                                                        <td colSpan={7} className="h-20 w-full text-center text-2xl text-gray-400"> Aucun élément correspondant à <span className="font-bold">{inputValue}</span> </td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex  text-gray-600 mt-2 text-[10px]"><div>Année scolaire :</div> <div className="text-blue-700 font-semibold ml-1"> 2023-2024</div></div>
                    <div className="flex  text-gray-600 mt-1 text-[10px]"><div></div> <div className="text-gray-300 select-none font-semibold  ml-1"> Lycée d'exellence NGAGARA</div></div>

                </div>
                {cahsPrintAll && <PrintAll ref={componentRef} />}
                <FootterCopy />
            </div>

        </>
    );
}

export default HomeGarconDetailDortoirePrefetDisplin;


class PrintAll extends Component {
    render() {
        const sortedData = chambresList;
        return (
            <div className={`my-1 w-full  gap-2 p-2 `}>
                <div class={`align-middle inline-block  min-w-full`}>
                    <div className="w-full text-center font-bold text-xl mb-5 underline first-letter:uppercase">Horaire de distribution des lits</div>
                    <table className='w-full border border-gray-50 overflow-hidden'>
                        <thead className="bg-gray-50">
                            <tr className='border'>
                                <th rowSpan={2} class=" py-4 px-2 font-semibold relative border-l text-left cursor-pointer text-lg first-letter:uppercase  text-gray-500  tracking-wider">Nom du lit</th>
                                <th colSpan={2} className=" py-2 px-2 font-semibold relative border-l text-center  text-lg first-letter:uppercase  text-gray-500  tracking-wider"> Elève</th>
                            </tr>
                            <tr className='border-b'>
                                <th class="px-2  py-2 font-semibold relative border-l text-left  text-lg first-letter:uppercase  text-gray-500  tracking-wider">Nom complet</th>
                                <th class="px-2 py-2 font-semibold relative border-l text-left  text-lg first-letter:uppercase  text-gray-500  tracking-wider">Classe</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sortedData.map((data, index) => (
                                <tr key={index} className='border-b border-t text-lg  text-gray-600 border-gray-300 p-2'>
                                    <td className='border-l pl-3  '>{data.Numero_Chambre}</td>
                                    <td className='border-l py-1'>
                                        {data.eleves.map((datas, cmdIndex) => (
                                            cmdIndex == 0 ? (<>
                                                <div className=' w-full h-full px-2' key={cmdIndex}>
                                                    <span>{cmdIndex + 1}.</span><span>{datas.Nom_Eleve}</span>
                                                </div>
                                            </>) : (<>
                                                <div className='border-t w-full h-full px-2' key={cmdIndex}>
                                                    <span>{cmdIndex + 1}.</span><span>{datas.Nom_Eleve}</span>
                                                </div>
                                            </>)
                                        ))}

                                    </td>
                                    <td className='border-l py-1'>
                                        {data.eleves.map((datas, cmdIndex) => (
                                            cmdIndex == 0 ? (<>
                                                <div className='border-b  w-full h-full' key={cmdIndex}>
                                                    <p className='px-2'>{datas.Classe}</p>
                                                </div>
                                            </>) : (<>
                                                <div className='border-t w-full h-full' key={cmdIndex}>
                                                    <p className='px-2'>{datas.Classe}</p>
                                                </div>
                                            </>)
                                        ))}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div className="flex  text-gray-600 mt-2 text-[10px]"><div>Année scolaire :</div> <div className="text-blue-700 font-semibold ml-1"> 2023-2024</div></div>
                <div className="flex  text-gray-600 mt-1 text-[10px]"><div></div> <div className="text-gray-300 select-none font-semibold  ml-1"> Lycée d'exellence NGAGARA</div></div>
            </div>
        )
    }
}