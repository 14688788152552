import React, { useState } from "react";
import AjouterDepensesInfirmeierComponent from "../../component/Infirmerie/Depenses/AjouterDepensesInfirmeierComponent";
function AjouterDepensesInfirmeierPage({ getSizeMobille, getMenuMobille }) {
  return (
    <>
      <AjouterDepensesInfirmeierComponent getMenuMobille={getMenuMobille} getSizeMobille={getSizeMobille} />
    </>
  );
}

export default AjouterDepensesInfirmeierPage;
