import React from 'react'
import AjouteVente from './AjouteVente'
import SlideNavBarsCantin from '../SlideNavBarsCantin/SlideNavBarsCantin'
function AjouteVenteComponent() {
    return (
        <div className='flex overflow-hidden pt-6 bg-white'>
            <SlideNavBarsCantin />
            <AjouteVente />
        </div>
    )
}

export default AjouteVenteComponent
