import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import "./sweetalert.css";
import FootterCopy from "../../FootterCopy/FootterCopy";
import { API, STATIC_URL } from "../../../Api/API";
import { decryptData, encryptData } from "../../../encryptionModule";
import { useLocation, Link, useNavigate, useParams } from "react-router-dom";
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { toast } from "react-toastify";
function DetailAccueil(getSizeMobille) {


  const history = useNavigate()
  const { id } = useParams()
  const StaffId = decryptData(id)
  const [Staff, setStaff] = useState({})
  useEffect(() => {
    API.get(`/staff/ParId/${StaffId}`).then((res) => {
      setStaff(res.data)
    }).catch((err) => {
      console.log(err.message)
    })
  }, [StaffId])
  const formatDate = date => {
    return format(new Date(date), "'Le 'd, MMMM yyyy", { locale: fr });
  };

  const handleConfirmation = async () => {
    try {
      const result = await swal({
        title: "Confirmation",
        text: ` Voulez-Vous   ${Staff.actif == 1 ? "désactiver" : "activer"} le personnel?`,
        icon: "warning",
        buttons: ["No", "Oui"],
        dangerMode: true,
        className: "centered-buttons",
      });
      if (result) {
        const changestatus = Staff.actif == 1 ? 0 : 1
        API.put(`/staff/changestatus/${StaffId}?status=${changestatus}`).then((res) => {
          toast.success(`Vous avez  ${Staff.actif == 1 ? "désactiver" : "activer"} avec succes le courrier!`)
          history('/DossierPersonnel')
        }).catch((err) => {
          toast.warn("Erreur de suppression du courrier")
        })

      } else {
        // User clicked "No" or closed the dialog
        console.log("Cancelled");
      }
    } catch (error) {
      // Handle error or dialog dismissal
      console.log("Error or dismissal:", error);
    }
  };


  const [mobile1, Setmonile1] = useState(window.innerWidth < 621)
  const [mobile2, Setmonile2] = useState(window.innerWidth < 752)
  const [mobile3, Setmonile3] = useState(window.innerWidth < 457)
  const [mobile4, Setmonile4] = useState(window.innerWidth < 318)

  useEffect(() => {
    const hundlesSize = () => {
      Setmonile1(window.innerWidth < 621)
      Setmonile2(window.innerWidth < 752)
      Setmonile3(window.innerWidth < 752)
      Setmonile4(window.innerWidth < 318)
    }

    window.addEventListener('resize', hundlesSize)

    return () => {
      window.removeEventListener('resize', hundlesSize)
    }
  }, [])


  const [aff, GetAffe] = useState(false)
  const [ident, GetIdent] = useState(false)
  const [cv, GetCv] = useState(false)
  const [diplom, Getdiplom] = useState(false)
  const [contra, Getcontra] = useState(false)
  return (

    Staff ? (<div className={`bg-white  overflow-x-auto  h-[85vh] ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
      <Link to='/DossierPersonnel' className="ml-2 text-[18px] text-blue-500 hover:text-blue-800 underline">Retour</Link>
      <div className="sm:p-6 ">
        <div className="text-[25px] text-gray-700 font-bold ml-2">Les dossiers</div>

        <div class="w-full my-2 sm:my-4 grid grid-cols-1 ">
          <div class="bg-white  rounded-lg p-2 sm:p-6 xl:p-8">
            <div class="w-full sm:my-4 grid grid-cols-1 ">
              <div class="bg-white  rounded-lg p-2 sm:p-6 xl:p-8">
                <div class=" rounded-lg bg-white border p-1 flex items-center justify-center  ">
                  <div class="flex flex-col  w-full items-center justify-start p-2 sm:p-6">
                    {Staff.Profile && (
                      <div className="max-w-[15em] max-h-[15em] rounded-xl overflow-hidden">
                        <img
                          src={`${STATIC_URL}/personnel/${Staff.Profile}`}
                          alt=" "
                          className="w-full h-full object-cover"
                        />
                      </div>
                    )}
                    <div className="flex w-full justify-around mt-10 flex-wrap">
                      <div>
                        <p class="mb-4  text-xl  font-bold underline text-gray-600 ">
                          Informations personnelles
                        </p>
                        <p class="mb-4  text-xl text-neutral-600 ">
                          Nom & Prenom : {Staff.nomcomplet}
                        </p>
                        <p class="mb-4  text-xl text-neutral-600 ">
                          Sexe :{Staff.sexe}
                        </p>
                        <p class="mb-4  text-xl text-neutral-600 ">
                          Tel : {Staff.tel}
                        </p>
                        <p class="mb-4  text-xl text-neutral-600 ">
                          Email : {Staff.Email}
                        </p>
                        <p class="mb-4  text-xl text-neutral-600 ">
                          Adresse : {Staff.Addres}
                        </p>

                      </div>
                      <div>
                        <p class="mb-4  text-xl  font-bold underline text-gray-600">
                          Informations professionnelles
                        </p>
                        <p class="mb-4  text-xl text-neutral-600 ">
                          Grade : {Staff.Grade}
                        </p>
                        <p class="mb-4  text-xl text-neutral-600 ">
                          Matricule : {Staff.matricule}{" "}
                        </p>

                        <p class="mb-4  text-xl text-neutral-600 ">
                          Role :{Staff.role}
                        </p>
                        <p class="mb-4  text-xl text-neutral-600 ">
                          Status : {Staff.actif == 1 ? "Actif" : "Non Actif"}
                        </p>
                        <p class="text-lg text-neutral-500 ">
                          Inscrit  {Staff.createdAt && formatDate(Staff.createdAt)}
                        </p>
                      </div>
                    </div>







                    <div class="flex flex-row items-center justify-center mt-20 mb-10 ">
                      {" "}
                      <p class="text-navy-700 shrink  ">
                        <p
                          class="hover:text-navy-700 text-gray-700 uppercase sm:text-xl font-bold "

                        >
                          Vos Documents Fournis
                        </p>
                      </p>
                    </div>
                    <div class=" border border-gray-100 rounded-xl p-3 flex gap-5 flex-wrap   w-full ">

                      <div onClick={() => { GetAffe(true) }} class="bg-gray-50 border cursor-pointer border-gray-100 sm:w-[17em] rounded-xl p-2 flex flex-col items-center justify-center">
                        <label
                          class="block uppercase tracking-wide text-grey-darker sm:text-lg font-bold mb-2"
                          for="grid-Title"
                        >
                          Staff d'Affectation
                        </label>

                        {Staff.affectation && (
                          <div className="max-w-[15em] max-h-[15em] rounded-xl overflow-hidden">
                            <img
                              src={`${STATIC_URL}/personnel/${Staff.affectation}`}
                              alt=" "
                              className="w-full h-full object-cover"
                            />
                          </div>
                        )}
                      </div>


                      <div onClick={() => { GetIdent(true) }} class="bg-gray-50 border cursor-pointer border-gray-100 sm:w-[17em] rounded-xl p-3 flex flex-col items-center justify-center">
                        <label
                          class="block uppercase tracking-wide text-grey-darker text-lg font-bold mb-2"
                          for="grid-Title"
                        >
                          Identité Complète
                        </label>

                        {Staff.idcomplete && (
                          <div className="max-w-[15em] max-h-[15em] rounded-xl overflow-hidden">
                            <img
                              src={`${STATIC_URL}/personnel/${Staff.idcomplete}`}
                              alt=" "
                              className="w-full h-full object-cover"
                            />
                          </div>
                        )}
                      </div>


                      <div onClick={() => { GetCv(true) }} class="bg-gray-50 border cursor-pointer border-gray-100 sm:w-[17em] rounded-xl p-3 flex flex-col items-center justify-center">
                        <label
                          class="block uppercase tracking-wide text-grey-darker text-lg font-bold mb-2"
                          for="grid-Title"
                        >
                          CV
                        </label>
                        {Staff.cv && (
                          <div className="max-w-[15em] max-h-[15em] rounded-xl overflow-hidden">
                            <img
                              src={`${STATIC_URL}/personnel/${Staff.cv}`}
                              alt=" "
                              className="w-full h-full object-cover"
                            />
                          </div>
                        )}
                      </div>


                      <div onClick={() => { Getdiplom(true) }} class="bg-gray-50 cursor-pointer border border-gray-100 sm:w-[17em] rounded-xl p-3 flex flex-col items-center justify-center">
                        <label
                          class="block uppercase tracking-wide text-grey-darker text-lg font-bold mb-2"
                          for="grid-Title"
                        >
                          Diplomes
                        </label>
                        {Staff.diplome && (
                          <div className="max-w-[15em] max-h-[15em] rounded-xl overflow-hidden">
                            <img
                              src={`${STATIC_URL}/personnel/${Staff.diplome}`}
                              alt=" "
                              className="w-full h-full object-cover"
                            />
                          </div>
                        )}
                      </div>
                      <div onClick={() => { Getcontra(true) }} class="bg-gray-50 border cursor-pointer border-gray-100 sm:w-[17em] rounded-xl p-3 flex flex-col items-center justify-center">
                        <label
                          class="block uppercase tracking-wide text-grey-darker text-lg font-bold mb-2"
                          for="grid-Title"
                        >
                          Contrat de performance
                        </label>
                        {Staff.contraperfo && (
                          <div className="max-w-[15em] max-h-[15em] rounded-xl overflow-hidden">
                            <img
                              src={`${STATIC_URL}/personnel/${Staff.contraperfo}`}
                              alt=" "
                              className="w-full h-full object-cover"
                            />
                          </div>
                        )}
                      </div>
                    </div>


                    <div class=" mt-8 flex items-center justify-end ">
                      <div class="flex flex-row flex-shrink-0 space-x-3">
                        {Staff.id && (
                          <Link
                            to={`/DossierPersonnel/ModifierPersonnel/${encryptData((Staff.id).toString())}`}
                            class="mt-4 sm:mt-0 inline-flex items-start justify-start px-6 py-3 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg"
                          >
                            <p class="text-lg font-medium leading-none text-white">
                              Modifier
                            </p>
                          </Link>
                        )}
                        <button
                          onClick={handleConfirmation}
                          class="mt-4 sm:mt-0 inline-flex items-start justify-start px-6 py-3 bg-red-700 hover:bg-red-600 focus:outline-none rounded-lg"
                        >
                          <p class="text-lg font-medium leading-none text-white">
                            {Staff.actif == 1 ? "Désactiver" : "Activer"}
                          </p>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          {aff &&
            <div className="fixed w-full h-full top-0 left-0 bg-[#0000008f] z-[900] flex justify-center pt-2">

              <div className="w-[95%] h-[95vh]  bg-white rounded-xl relative overflow-hidden">
                <div className="absolute z-[1000] flex justify-end w-full pr-3 pt-3">
                  <div onClick={(e) => { GetAffe(false); e.stopPropagation() }} className="bg-red-500 transition-all hover:bg-red-700 cursor-pointer text-white p-2 rounded-full"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                  </svg></div>
                </div>

                {Staff.affectation && (
                  <img
                    draggable='false'
                    src={`${STATIC_URL}/personnel/${Staff.affectation}`}
                    alt=" "
                    className="w-full h-full object-contain bg-white"
                  />

                )}

              </div>
            </div>
          }





          {ident &&
            <div className="fixed w-full h-full top-0 left-0 bg-[#0000008f] z-[900] flex justify-center pt-2">

              <div className="w-[95%] h-[95vh]  bg-white rounded-xl relative overflow-hidden">
                <div className="absolute z-[1000] flex justify-end w-full pr-3 pt-3">
                  <div onClick={(e) => { GetIdent(false); e.stopPropagation() }} className="bg-red-500 transition-all hover:bg-red-700 cursor-pointer text-white p-2 rounded-full"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                  </svg></div>
                </div>
                {Staff.idcomplete && (

                  <img
                    src={`${STATIC_URL}/personnel/${Staff.idcomplete}`}
                    alt=" "
                    className="w-full h-full object-contain"
                  />

                )}

              </div>
            </div>
          }


          {cv &&
            <div className="fixed w-full h-full top-0 left-0 bg-[#0000008f] z-[900] flex justify-center pt-2">

              <div className="w-[95%] h-[95vh]  bg-white rounded-xl relative overflow-hidden">
                <div className="absolute z-[1000] flex justify-end w-full pr-3 pt-3">
                  <div onClick={(e) => { GetCv(false); e.stopPropagation() }} className="bg-red-500 transition-all hover:bg-red-700 cursor-pointer text-white p-2 rounded-full"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                  </svg></div>
                </div>
                {Staff.cv && (
                  <img
                    src={`${STATIC_URL}/personnel/${Staff.cv}`}
                    alt=" "
                    className="w-full h-full object-contain"
                  />

                )}

              </div>
            </div>
          }

          {diplom &&
            <div className="fixed w-full h-full top-0 left-0 bg-[#0000008f] z-[900] flex justify-center pt-2">

              <div className="w-[95%] h-[95vh]  bg-white rounded-xl relative overflow-hidden">
                <div className="absolute z-[1000] flex justify-end w-full pr-3 pt-3">
                  <div onClick={(e) => { Getdiplom(false); e.stopPropagation() }} className="bg-red-500 transition-all hover:bg-red-700 cursor-pointer text-white p-2 rounded-full"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                  </svg></div>
                </div>
                {Staff.diplome && (
                  <img
                    src={`${STATIC_URL}/personnel/${Staff.diplome}`}
                    alt=" "
                    className="w-full h-full object-contain"
                  />

                )}
              </div>
            </div>
          }


          {contra &&
            <div className="fixed w-full h-full top-0 left-0 bg-[#0000008f] z-[900] flex justify-center pt-2">

              <div className="w-[95%] h-[95vh]  bg-white rounded-xl relative overflow-hidden">
                <div className="absolute z-[1000] flex justify-end w-full pr-3 pt-3">
                  <div onClick={(e) => { Getcontra(false); e.stopPropagation() }} className="bg-red-500 transition-all hover:bg-red-700 cursor-pointer text-white p-2 rounded-full"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                  </svg></div>
                </div>
                {Staff.contraperfo && (
                  <img
                    src={`${STATIC_URL}/personnel/${Staff.contraperfo}`}
                    alt=" "
                    className="w-full h-full object-contain"
                  />
                )}
              </div>
            </div>
          }
        </div>
      </div>
      <FootterCopy />
    </div >) : <>
      <div>Loading</div>
      <FootterCopy />
    </>


  );
}

export default DetailAccueil;
