/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ADD, DELETE, CLEAR_CART, REMOVE } from "../../../Redux/Action/Action";
import FootterCopy from "../../FootterCopy/FootterCopy";
import { InView } from 'react-intersection-observer';
import { FadeLoader } from 'react-spinners';
import { toast } from "react-toastify";
import { decryptData } from "../../../encryptionModule";
import { API } from "../../../Api/API";

function MaterielListeEncadrementEmpruntElevesAll({ getSizeMobille }) {
    const { id, classId, schoolyearId } = useParams()
    const navigate = useNavigate()
    const getData = useSelector((state) => state.CartsReducer.Carts);
    const [maxQuant, GetMaxQuant] = useState(false)
    const [maxQuantIndex, GetMaxQuantIndex] = useState(null)
    function MaxQuant(index, value) {
        GetMaxQuant(value)
        GetMaxQuantIndex(index)
    }
    const dispatch = useDispatch();
    function handleDelete(id) {
        dispatch(DELETE(id));
    }

    function send(e) {
        dispatch(ADD(e, 1));
    }

    function remove(e) {
        dispatch(REMOVE(e));

    }

    const [mobile11, SetMobile11] = useState(window.innerWidth < 501)
    const [mobile12, SetMobile12] = useState(window.innerWidth < 480)

    useEffect(() => {
        const hundlesSize = () => {

            SetMobile11(window.innerWidth < 501)
            SetMobile12(window.innerWidth < 480)
        }

        window.addEventListener('resize', hundlesSize)

        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])

    if (getData.length < 1) {
        navigate(`/Materiel/EmpruntListMateriel/${id}/${schoolyearId}/${classId}`)
    }

    const [visibleItems, setVisibleItems] = useState([]);


    useEffect(() => {
        const observerOptions = {
            threshold: 0.1,
        };

        const handleIntersection = (position) => {
            setVisibleItems((prev) => [...prev, position]);
        };


        const intersectionObserver = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const position = parseInt(entry.target.getAttribute('data-position'), 10);
                    handleIntersection(position);
                }
            });
        }, observerOptions);

        const elements = document.querySelectorAll('.blocks');

        elements.forEach((element) => {
            intersectionObserver.observe(element);
            return () => {
                intersectionObserver.unobserve(element);
            };
        });

        return () => {
            intersectionObserver.disconnect();
        };
    }, []);




    const studentId = decryptData(id)
    const [Nom, SetNom] = useState('')
    const [Matricule, SetMatricule] = useState('')
    useEffect(() => {
        API.get(`/student/ParId/${studentId}`).then((res) => {
            const studentData = res.data;
            SetNom(studentData.nomprenom)
            SetMatricule(studentData.matricule)
        }).catch((err) => {
            console.log(err.message)
        })
    }, [id])

    const handleSubmit = (e) => {
        const exemplairesIdsNumeros = getData.map(exemplaire => ({
            id: exemplaire.id,
            nombre: exemplaire.qty,
            nombrefonctionnel: exemplaire.nombrefonctionnel
        }));
        const formData = {
            data: exemplairesIdsNumeros,
            studentId: studentId
        }



        API.post("/materielinternant/AjoutEmpruntMaterielEleves", formData)
            .then((res) => {
                dispatch(CLEAR_CART());
                toast.success('Enregistrement avec succes')
            })
            .catch((err) => {
                if (err.response) {
                    if (Array.isArray(err.response.data)) {
                        toast.warn(err.response.data[0])
                    } else if (typeof err.response.data === 'object') {
                        // Si c'est un objet, afficher la valeur (par exemple, en la convertissant en chaîne JSON)
                        toast.warn(JSON.stringify(err.response.data))
                    } else if (typeof err.response.data === 'string') {
                        // Si c'est une chaîne de caractères, l'afficher directement
                        toast.warn(err.response.data)
                    } else {
                        // Si c'est autre chose, afficher un message par défaut
                        toast.warn('Erreur inattendue lors de la récupération des données de réponse')
                    }
                    return false;
                }
                console.log(err.message)
            })
            .finally(() => {
                console.log("")
            });


    }

    return (

        <div className={`bg-white  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
            <Link to={`/Materiel/EmpruntListMateriel/${id}/${schoolyearId}/${classId}`} className="ml-2 text-[18px] text-blue-500 hover:text-blue-800 underline">Retour</Link>
            <div className="sm:p-4 p-2 min-h-screen">
                <div class="my-5">
                    <div class="w-full flex flex-row items-center justify-between">
                        <p class="text-navy-700 shrink ">
                            <p
                                class="hover:text-navy-700 text-2xl font-bold"
                            >
                                Panier de   <div className="text-blue-400"> {Nom} :{Matricule}</div>
                            </p>
                        </p>
                    </div>
                </div>

                <div class="w-full my-4 grid grid-cols-1 ">
                    <div class="bg-white  shadow rounded-lg p-4 sm:p-6 xl:p-8">
                        <div class={`flex flex-wrap space-y-3 mt-8`}>


                            {getData.map((element, position) => (

                                <div key={position} data-position={position} className="w-max blocks m-[10px]  rounded-md border p-2 h-max">
                                    <div
                                        data-aos="fade-left"
                                        className={`  ${mobile12 ? 'flex-col' : ''}   bg-opacity-50  flex  justify-center items-center`}
                                    >

                                        <div className=" flex flex-col pl-4">
                                            <div className="m-1 w-full  text-gray-600 text-[15px]">Nom: <strong>{element.nom}</strong></div>
                                            <div className="w-full p-1 text-gray-600 text-[15px]">Type: <strong>{element.type}</strong></div>
                                            <div className="w-full p-1 text-gray-600 text-[15px]">Fonctionnel: <strong>{element.nombrefonctionnel}</strong></div>
                                            <div className="w-full flex flex-wrap p-1 text-gray-600 text-[15px]">
                                                <div>
                                                    Reglage:
                                                </div>
                                                <div className="ml-1">
                                                    <span onClick={() => { if (element.qty < 2) { MaxQuant(position, false); handleDelete(element.id); } else { MaxQuant(position, false);; remove(element); } }} className="py-[1.5px] px-2 bg-slate-50 border cursor-pointer hover:bg-blue-600 hover:text-white transition-all ">-</span><span className="w-6 h-6 bg-slate-50"><input value={element.qty} className="w-6 h-6  pl-1 pointer-events-none" /></span> <button disabled={maxQuant && maxQuantIndex == position} className={`py-[1.5px] px-2  hover:bg-blue-600 hover:text-white transition-all bg-slate-50 border ${maxQuant && maxQuantIndex == position ? 'opacity-50 cursor-no-drop bg-gray-400' : 'cursor-pointer'} `} onClick={() => { if (element.qty < element.nombrefonctionnel) { MaxQuant(position, false); send(element); } else { MaxQuant(position, true); toast.warning(<div>Nous avons la quantite maximal de <strong>{element.nombrefonctionnel}</strong> du matériel <strong>{element.nom}</strong>  </div>); } }}>+</button>
                                                </div>
                                            </div>
                                            <div class="flex w-full  items-center justify-end ">
                                                <div class="flex flex-row flex-shrink-0 space-x-3">
                                                    <button
                                                        onClick={() => handleDelete(element.id)}
                                                        class="mt-4"
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi cursor-pointer text-red-700 h-6 w-6 bi-trash3-fill" viewBox="0 0 16 16">
                                                            <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}

                        </div>
                        <div class=" mt-8 flex w-full items-center justify-end ">
                            <div class="flex flex-row flex-shrink-0 space-x-3">
                                <button onClick={handleSubmit} class="mt-4 sm:mt-0 inline-flex items-start justify-start px-6 py-3 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg">
                                    <p class="text-lg font-medium leading-none text-white">
                                        Emprunter Tous
                                    </p>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FootterCopy />
        </div >

    );
}

export default MaterielListeEncadrementEmpruntElevesAll;


