/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import FootterCopy from "../../FootterCopy/FootterCopy";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select";
import { API } from "../../../Api/API"

function PersonnelConsultation({ getSizeMobille }) {
    const navigate = useNavigate()
    const UnitOrigineOptions = [
        { value: "", label: "Origine" },
        { value: "AR", label: "AR" },
        { value: "HAR", label: "HAR" },
        { value: "HC", label: "HC" },
        { value: "HD", label: "HD" },
        { value: "HP", label: "HP" },
        { value: "HB", label: "HB" },
    ];

    const AncesCaseOptions = [
        { value: "", label: "Ancien cas" },
        { value: "Non", label: "Non" },
        { value: "Oui", label: "Oui" }

    ];

    const Contre_referenceOptions = [
        { value: "", label: "Contre reference" },
        { value: "Non", label: "Non" },
        { value: "Oui", label: "Oui" }

    ];

    const AlcoolOptions = [
        { value: "", label: "Alcool" },
        { value: "Non", label: "Non" },
        { value: "Oui", label: "Oui" }

    ];
    const TabacOptions = [
        { value: "", label: "Tabac" },
        { value: "Non", label: "Non" },
        { value: "Oui", label: "Oui" }

    ];

    const StatusOptions = [
        { value: "", label: "Status" },
        { value: "Non", label: "Non" },
        { value: "Oui", label: "Oui" }

    ];



    const hiddenSUnitOrigineOptions = UnitOrigineOptions.slice(1);
    const hiddenSAncesCaseOptions = AncesCaseOptions.slice(1);
    const hiddenContre_referenceOptions = Contre_referenceOptions.slice(1);
    const hiddenAlcoolOptions = AlcoolOptions.slice(1);
    const hiddenTabacOptions = TabacOptions.slice(1);
    const hiddenStatusOptions = StatusOptions.slice(1);

    const [selectedOption, setselectedOption] = useState(UnitOrigineOptions[0]);
    const [selectedOption1, setSelectedOption1] = useState(AncesCaseOptions[0]);
    const [selectedOption2, setSelectedOption2] = useState(Contre_referenceOptions[0]);
    const [selectedOption3, setSelectedOption3] = useState(AlcoolOptions[0]);
    const [selectedOption4, setSelectedOption4] = useState(TabacOptions[0]);
    const [selectedOption5, setSelectedOption5] = useState(StatusOptions[0]);


    const SelectUnitOrigine = (option) => {
        setselectedOption(option)
    }
    const SelectAncesCase = (option) => {
        setSelectedOption1(option)
    }

    const SelectContre_referenceOptions = (option) => {
        setSelectedOption2(option)
    }

    const SelectAlcool = (option) => {
        setSelectedOption3(option)
    }
    const SelectTabac = (option) => {
        setSelectedOption4(option)
    }

    const SelectStatus = (option) => {
        setSelectedOption5(option)
    }

    const customStyles = {
        control: (provided) => ({
            ...provided,
            height: 40,
            backgroundColor: "white",

        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? "blue" : state.isFocused ? "lightblue" : "white",
            color: state.isSelected ? "white" : "black",
        }),
    };

    const customStylesStatus = {
        control: (provided) => ({
            ...provided,
            height: 50,
            backgroundColor: "white",

        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? "blue" : state.isFocused ? "lightblue" : "white",
            color: state.isSelected ? "white" : "black",
        }),
    };

    const [mobile11, SetMobile11] = useState(window.innerWidth < 501)

    useEffect(() => {
        const hundlesSize = () => {
            SetMobile11(window.innerWidth < 501)
        }
        window.addEventListener('resize', hundlesSize)
        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])


    const [Matricule, SetMatricule] = useState('')
    const [animationClassMatricule, setAnimationClassMatricule] = useState('');
    const elemetRefMatricule = useRef(null)



    const [age, Setage] = useState('')
    const [animationClassage, setAnimationClassage] = useState('');
    const elemetRefage = useRef(null)



    const [Poids, SetPoids] = useState('')
    const [animationClassPoids, setAnimationClassPoids] = useState('');
    const elemetRefPoids = useRef(null)



    const [animationClassUnitOrigine, setanimationClassUnitOrigine] = useState('');
    const elemetRefUnitOrigine = useRef(null)


    const [animationClassAncienCas, setanimationClassAncienCas] = useState('');
    const elemetRefAncienCas = useRef(null)



    const [GoupeAge, SetGoupeAge] = useState('')
    const [animationClassGoupeAge, setAnimationClassGoupeAge] = useState('');
    const elemetRefGoupeAge = useRef(null)


    const [animationClassContre_reference, setanimationClassContre_reference] = useState('');
    const elemetRefContre_reference = useRef(null)

    const [animationClassAlcool, setanimationClassAlcool] = useState('');
    const elemetRefAlcool = useRef(null)

    const [animationClassTabac, setanimationClassTabac] = useState('');
    const elemetRefTabac = useRef(null)

    const [Paramettre, SetParamettre] = useState('')
    const [animationClassParamettre, setAnimationClassParamettre] = useState('');
    const elemetRefParamettre = useRef(null)

    const [fièvre, Setfièvre] = useState('')
    const [animationClassfièvre, setAnimationClassfièvre] = useState('');
    const elemetReffièvre = useRef(null)


    const [N_CNI, SetN_CNI] = useState('')
    const [animationClassN_CNI, setAnimationClassN_CNI] = useState('');
    const elemetRefN_CNI = useRef(null)

    const [CAM, SetCAM] = useState('')
    const [animationClassCAM, setAnimationClassCAM] = useState('');
    const elemetRefCAM = useRef(null)


    const [animationClassStatus, setAnimationClassStatus] = useState('');
    const elemetRefStatus = useRef(null)



    useEffect(() => {
        elemetRefParamettre.current.style.height = '7em';
        elemetRefParamettre.current.style.height = `${elemetRefParamettre.current.scrollHeight}px`;
        elemetRefParamettre.current.scrollTop = elemetRefParamettre.current.scrollHeight;
    }, [Paramettre]);


    const handleSubmit = (e) => {
        e.preventDefault()

        if (Matricule.trim() === '') {
            toast.warning('Le matricule  d\'un élève est  obligatoire', {
                autoClose: 2000
            });
            setAnimationClassMatricule('animate__animated animate__shakeX border-2 border-red-500 ')
            setTimeout(() => {
                setAnimationClassMatricule(' ')
            }, 3000)
            elemetRefMatricule.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            elemetRefMatricule.current.focus()
            return false
        }

        else if (age.trim() == '') {
            toast.warning('Age d\'un élève est obligatoire', {
                autoClose: 2000
            });
            setAnimationClassage('animate__animated animate__shakeX border-2 border-red-500')
            setTimeout(() => {
                setAnimationClassage(' ')
            }, 3000)
            elemetRefage.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            elemetRefage.current.focus()

            return false
        }
        else if (Poids.trim() == '') {
            toast.warning('Poids d\'un élève est obligatoire', {
                autoClose: 2000
            });
            setAnimationClassPoids('animate__animated animate__shakeX border-2 border-red-500')
            setTimeout(() => {
                setAnimationClassPoids(' ')
            }, 3000)
            elemetRefPoids.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            elemetRefPoids.current.focus()

            return false
        }

        else if (GoupeAge.trim() == '') {
            toast.warning('Goupe d\'age du médicament est obligatoire', {
                autoClose: 2000
            });

            setAnimationClassGoupeAge('animate__animated animate__shakeX border-2 border-red-500')
            setTimeout(() => {
                setAnimationClassGoupeAge(' ')
            }, 3000)
            elemetRefGoupeAge.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            elemetRefGoupeAge.current.focus()
            return false
        }
        else if (selectedOption.value == '') {
            toast.warning('Origine est obligatoire ', {
                autoClose: 2000
            });
            setanimationClassUnitOrigine('animate__animated animate__shakeX border-2 border-red-500')
            setTimeout(() => {
                setanimationClassUnitOrigine(' ')
            }, 3000)
            elemetRefUnitOrigine.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            return;
        }
        else if (selectedOption1.value == '') {
            toast.warning('Anciens cas est obligatoire ', {
                autoClose: 2000
            });
            setanimationClassAncienCas('animate__animated animate__shakeX border-2 border-red-500')
            setTimeout(() => {
                setanimationClassAncienCas(' ')
            }, 3000)
            elemetRefAncienCas.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            return;
        } else if (selectedOption2.value == '') {
            toast.warning('Contre reference cas est obligatoire ', {
                autoClose: 2000
            });
            setanimationClassContre_reference('animate__animated animate__shakeX border-2 border-red-500')
            setTimeout(() => {
                setanimationClassContre_reference(' ')
            }, 3000)
            elemetRefContre_reference.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            return;

        }
        else if (selectedOption3.value == '') {
            toast.warning('Pose le pation si: "Avez-vous consommé de l\'alcool récemment ?"', {
                autoClose: 2000
            });
            setanimationClassAlcool('animate__animated animate__shakeX border-2 border-red-500')
            setTimeout(() => {
                setanimationClassAlcool(' ')
            }, 3000)
            elemetRefAlcool.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            return;

        }
        else if (selectedOption4.value == '') {
            toast.warning('Pose le pation si: "Avez-vous consommé de le tabac récemment ?"', {
                autoClose: 2000
            });
            setanimationClassTabac('animate__animated animate__shakeX border-2 border-red-500')
            setTimeout(() => {
                setanimationClassTabac(' ')
            }, 3000)
            elemetRefTabac.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            return;

        }
        else if (Paramettre.trim() == '') {
            toast.warning('Paramettre que vous avez consulte', {
                autoClose: 2000
            });
            setAnimationClassParamettre('animate__animated animate__shakeX border-2 border-red-500')
            setTimeout(() => {
                setAnimationClassParamettre(' ')
            }, 3000)
            elemetRefParamettre.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            elemetRefParamettre.current.focus()

            return false
        }
        else if (fièvre.trim() == '') {
            toast.warning('fièvre', {
                autoClose: 2000
            });
            setAnimationClassfièvre('animate__animated animate__shakeX border-2 border-red-500')
            setTimeout(() => {
                setAnimationClassfièvre(' ')
            }, 3000)
            elemetReffièvre.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            elemetReffièvre.current.focus()

            return false
        }
        else if (N_CNI == '') {
            toast.warning("Numéro de CNI est nécessaire", {
                autoClose: 2000
            });
            setAnimationClassN_CNI('animate__animated animate__shakeX  border-2 border-red-500')
            setTimeout(() => {
                setAnimationClassN_CNI(' ')
            }, 3000)
            elemetRefN_CNI.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            return false
        }
        else if (selectedOption5.value == '') {
            toast.warning("Est-ce que vous avez besoin de vous rendre au laboratoire pour des analyses ou des tests ?", {
                autoClose: 2000
            });
            setAnimationClassStatus('animate__animated animate__shakeX  border-2 border-red-500')
            setTimeout(() => {
                setAnimationClassStatus(' ')
            }, 3000)
            elemetRefStatus.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            return false
        }
        if (selectedOption5.value == 'Oui') {
            if (CAM == '') {
                toast.warning("Numéro de CAM est nécessaire", {
                    autoClose: 2000
                });
                setAnimationClassCAM('animate__animated animate__shakeX  border-2 border-red-500')
                setTimeout(() => {
                    setAnimationClassCAM(' ')
                }, 3000)
                elemetRefCAM.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
                return false
            }
        }
        const data = {
            Matricule,
            poids: Poids,
            age: age,
            groupage: GoupeAge,
            newbyorgine: selectedOption.value,
            enciecas: selectedOption1.value,
            contrerefere: selectedOption2.value,
            afiseCam: selectedOption5.value,
            cam: CAM,
            nocni: N_CNI,
            firvre: fièvre,
            parametre: Paramettre,
            tabac: selectedOption4.value,
            alcoll: selectedOption3.value
        }
        API.post('/INF/consultationperso', data)
            .then((rep) => {
                // Traitement en cas de succès si nécessaire
                toast.success(rep.data)
                navigate('/Laboratoire')
            })
            .catch((err) => {
                if (err.response && err.response.data && err.response.data.error) {
                    const errorMessage = err.response.data.error;
                    toast.error(errorMessage);
                } else {
                    toast.error('Une erreur est survenue lors de la création de la consultation.');
                }
            });
    }

    return (

        <div className={`bg-white  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
            <Link to='/Consultation' className="ml-2 text-[18px] text-blue-500 hover:text-blue-800 underline">Retour</Link>
            <div className="sm:p-4 p-2 min-h-screen">
                <div class="my-3">
                    <div class="w-full flex flex-row items-center justify-between">
                        <p class="text-navy-700   ">
                            <p class="text-2xl font-bold">Consultation d'une personnel</p>
                        </p>
                    </div>
                </div>
                <div class="w-full my-4 grid grid-cols-1 ">
                    <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">
                        <form onSubmit={handleSubmit}>
                            <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col my-2">
                                <div class="-mx-3 md:flex mb-6  ">
                                    <div class={`md:w-1/2 px-3 mb-6 md:mb-0`}>
                                        <label
                                            class="block  tracking-wide  text-lg font-bold mb-2"
                                            for="grid-Title"
                                        >
                                            Matricule
                                        </label>

                                        <input
                                            class={` outline-none block w-full bg-grey-lighter  border rounded py-3 px-4 mb-3 ${Matricule.trim() === '' ? animationClassMatricule : 'focus:border-2 focus:border-blue-700'} `}
                                            type="text"
                                            ref={elemetRefMatricule} value={Matricule} onInput={(e) => SetMatricule(e.target.value)}
                                            placeholder="Matricule"
                                        />
                                    </div>
                                    <div class={`md:w-1/2 px-3 mb-6 md:mb-0`}>
                                        <label
                                            class="block  tracking-wide  text-lg font-bold mb-2"
                                            for="grid-last-name"
                                        >
                                            Age
                                        </label>
                                        <input
                                            ref={elemetRefage} value={age} onInput={(e) => Setage(e.target.value)}
                                            class={`appearance-none outline-none block w-full bg-grey-lighter  border rounded py-3 px-4  ${age.trim() === '' ? animationClassage : 'focus:border-2 focus:border-blue-700'}`}
                                            id="grid-last-name"
                                            type="text"
                                            placeholder="Age"
                                        />
                                    </div>
                                </div>
                                <div class={`-mx-3 md:flex mb-6`}>
                                    <div class={`md:w-1/2 px-3 mb-6 md:mb-0`}>
                                        <label
                                            class="block  tracking-wide  text-lg font-bold mb-2"
                                            for="grid-last-name"
                                        >
                                            Poids
                                        </label>
                                        <input
                                            ref={elemetRefPoids} value={Poids} onInput={(e) => SetPoids(e.target.value)}
                                            class={`appearance-none outline-none block w-full bg-grey-lighter  border rounded py-2 px-2  ${Poids.trim() === '' ? animationClassPoids : 'focus:border-2 focus:border-blue-700'}`}
                                            id="grid-last-name"
                                            type="text"
                                            placeholder="Poids"
                                        />
                                    </div>
                                    <div class={`md:w-1/2 px-3`}>
                                        <label
                                            class="block  tracking-wide  text-lg font-bold mb-2"
                                            for="grid-last-name"
                                        >
                                            Groupe d'age
                                        </label>
                                        <input
                                            ref={elemetRefGoupeAge} value={GoupeAge} onInput={(e) => SetGoupeAge(e.target.value)}
                                            class={`appearance-none outline-none block w-full bg-grey-lighter  border rounded py-2 px-2  ${GoupeAge.trim() === '' ? animationClassGoupeAge : 'focus:border-2 focus:border-blue-700'}`}
                                            type="text"
                                            placeholder="Groupe d'age"
                                        />
                                    </div>

                                    <div class="md:w-1/2 px-2">
                                        <label class="block  tracking-wide text-gray-700 text-lg font-bold mb-2">
                                            Origine
                                        </label>
                                        <div ref={elemetRefUnitOrigine} className={` rounded  ${selectedOption.value == '' ? animationClassUnitOrigine : 'focus:border-2 focus:border-blue-700'}`}>
                                            <Select
                                                value={selectedOption}
                                                options={hiddenSUnitOrigineOptions}
                                                styles={customStyles}
                                                onChange={SelectUnitOrigine}
                                            />
                                        </div>
                                    </div>

                                    <div class="md:w-1/2 px-2">
                                        <label class="block  tracking-wide text-gray-700 text-lg font-bold mb-2">
                                            Ancien cas
                                        </label>
                                        <div ref={elemetRefAncienCas} className={` rounded  ${selectedOption1.value == '' ? animationClassAncienCas : 'focus:border-2 focus:border-blue-700'}`}>
                                            <Select
                                                value={selectedOption1}
                                                options={hiddenSAncesCaseOptions}
                                                styles={customStyles}
                                                onChange={SelectAncesCase}
                                            />
                                        </div>

                                    </div>

                                </div>





                                <div class={`-mx-3 md:flex mb-6`}>
                                    <div class="md:w-1/2 px-2">
                                        <label class="block  tracking-wide text-gray-700 text-lg font-bold mb-2">
                                            Contre reference
                                        </label>
                                        <div ref={elemetRefContre_reference} className={` rounded  ${selectedOption2.value == '' ? animationClassContre_reference : 'focus:border-2 focus:border-blue-700'}`}>
                                            <Select
                                                value={selectedOption2}
                                                options={hiddenContre_referenceOptions}
                                                styles={customStyles}
                                                onChange={SelectContre_referenceOptions}
                                            />
                                        </div>

                                    </div>


                                    <div class="md:w-1/2 px-2">
                                        <label class="block  tracking-wide text-gray-700 text-lg font-bold mb-2">
                                            Alcool
                                        </label>
                                        <div ref={elemetRefAlcool} className={` rounded  ${selectedOption3.value == '' ? animationClassAlcool : 'focus:border-2 focus:border-blue-700'}`}>
                                            <Select
                                                value={selectedOption3}
                                                options={hiddenAlcoolOptions}
                                                styles={customStyles}
                                                onChange={SelectAlcool}
                                            />
                                        </div>

                                    </div>

                                    <div class="md:w-1/2 px-2">
                                        <label class="block  tracking-wide text-gray-700 text-lg font-bold mb-2">
                                            Tabac
                                        </label>
                                        <div ref={elemetRefTabac} className={` rounded  ${selectedOption4.value == '' ? animationClassTabac : 'focus:border-2 focus:border-blue-700'}`}>
                                            <Select
                                                value={selectedOption4}
                                                options={hiddenTabacOptions}
                                                styles={customStyles}
                                                onChange={SelectTabac}
                                            />
                                        </div>

                                    </div>
                                </div>

                                <div class={`w-full  mb-6`}>
                                    <div class={`w-full`}>
                                        <label
                                            class="block  tracking-wide  text-lg font-bold mb-2"
                                            for="grid-last-name"
                                        >
                                            Paramettre
                                        </label>
                                        <textarea
                                            ref={elemetRefParamettre}
                                            value={Paramettre}
                                            onInput={(e) => SetParamettre(e.target.value)}
                                            className={`appearance-none resize-none outline-none block w-full bg-grey-lighter border rounded p-2 ${Paramettre.trim() === "" ? animationClassParamettre : "focus:border-2 focus:border-blue-700"
                                                }`}
                                            id="grid-last-name"
                                            rows={1}
                                            placeholder="Paramettre.."
                                        ></textarea>
                                    </div>
                                </div>


                                <div class={`-mx-3 md:flex mb-6`}>
                                    <div class={`md:w-1/2 px-3`}>
                                        <label
                                            class="block  tracking-wide  text-lg font-bold mb-2"
                                            for="grid-last-name"
                                        >
                                            Fièvre
                                        </label>
                                        <input
                                            ref={elemetReffièvre} value={fièvre} onInput={(e) => Setfièvre(e.target.value)}
                                            class={`appearance-none outline-none block w-full bg-grey-lighter  border rounded py-3 px-4  ${fièvre.trim() === '' ? animationClassfièvre : 'focus:border-2 focus:border-blue-700'}`}
                                            id="grid-last-name"
                                            type="text"
                                            placeholder="fièvre  du médicament"
                                        />
                                    </div>

                                    <div class={`md:w-1/2 px-3`}>
                                        <label
                                            class="block  tracking-wide  text-lg font-bold mt-2"
                                            for="grid-last-name"
                                        >
                                            Numéro de CNI
                                        </label>
                                        <input
                                            ref={elemetRefN_CNI} value={N_CNI} onInput={(e) => SetN_CNI(e.target.value)}
                                            class={`appearance-none outline-none block w-full bg-grey-lighter  border rounded py-3 px-4  ${N_CNI == '' ? animationClassN_CNI : 'focus:border-2 focus:border-blue-700'}`}
                                            type="text"
                                            placeholder="Numéro de CNI.."
                                        />
                                    </div>

                                </div>

                                <div class={`-mx-3 md:flex mb-6`}>

                                    <div class="md:w-1/2 px-2">
                                        <label class="block  tracking-wide text-gray-700 text-lg font-bold mb-2">
                                            Avez-vous CAM/Mutuel
                                        </label>
                                        <div ref={elemetRefStatus} className={` rounded  ${selectedOption5.value == '' ? animationClassStatus : 'focus:border-2 focus:border-blue-700'}`}>
                                            <Select
                                                value={selectedOption5}
                                                options={hiddenStatusOptions}
                                                styles={customStylesStatus}
                                                onChange={SelectStatus}
                                            />
                                        </div>

                                    </div>
                                    {selectedOption5.value == 'Oui' && (
                                        <div class={`md:w-1/2 px-3`}>
                                            <label
                                                class="block  tracking-wide  text-lg font-bold mb-2"
                                                for="grid-last-name"
                                            >
                                                Numéro de CAM/Mutuel
                                            </label>
                                            <input
                                                ref={elemetRefCAM} value={CAM} onInput={(e) => SetCAM(e.target.value)}
                                                class={`appearance-none outline-none block w-full bg-grey-lighter  border rounded py-3 px-4  ${CAM.trim() === '' ? animationClassCAM : 'focus:border-2 focus:border-blue-700'}`}
                                                id="grid-last-name"
                                                type="text"
                                                placeholder="Numéro de CAM"
                                            />
                                        </div>)}



                                </div>

                                <div class="mb-4 flex items-center justify-end">
                                    <div class="">
                                        <button class="mt-4 sm:mt-0 inline-flex items-start justify-start px-2 sm:px-6 py-3 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg">
                                            <p class="text-lg font-medium leading-none text-white">
                                                Enregistre
                                            </p>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <FootterCopy />
        </div>

    );
}


export default PersonnelConsultation;










