import React from "react";
import SideBarSecretaire from "../SideBarSecretaire/SideBarSecretaire";
import AjouterCongeAnnuelSecret from "./AjouterCongeAnnuelSecret";
function AjouterCongeAnnuelSecretComponent({ getSizeMobille, getMenuMobille }) {
  return (
    <>
      <div class="flex overflow-hidden bg-white justify-between">
        <SideBarSecretaire getMenuMobille={getMenuMobille} />
        <AjouterCongeAnnuelSecret getSizeMobille={getSizeMobille} />
      </div>
    </>
  );
}

export default AjouterCongeAnnuelSecretComponent;
