import React from "react";
import ConsultationComponent from "../../component/Infirmerie/Consultation/ConsultationComponent";
function ConsultationPage({ getSizeMobille, getMenuMobille }) {
  return (
    <>
      <ConsultationComponent getMenuMobille={getMenuMobille} getSizeMobille={getSizeMobille} />
    </>
  );
}

export default ConsultationPage;
