import React from 'react'
import MaterielDetailPrefetDisplin from './MaterielDetailPrefetDisplin'
import SideBarsPrefetDisplin from '../SideBarsPrefetDisplin/SideBarsPrefetDisplin'
function MaterielDetailPrefetDisplinComponent({ getSizeMobille, getMenuMobille }) {
    return (
        <div className='flex justify-between overflow-hidden bg-white'>
            <SideBarsPrefetDisplin getMenuMobille={getMenuMobille} />
            <MaterielDetailPrefetDisplin getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default MaterielDetailPrefetDisplinComponent
