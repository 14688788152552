/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FootterCopy from "../../FootterCopy/FootterCopy";
import { hopitalEleves } from "../../../Data/Data";
function HostpotalisePrefetDisplin({ getSizeMobille }) {
    const [mobile1, Setmonile1] = useState(window.innerWidth < 990)
    const [mobile2, Setmonile2] = useState(window.innerWidth < 520)

    useEffect(() => {
        const hundlesSize = () => {
            Setmonile1(window.innerWidth < 990)
            Setmonile2(window.innerWidth < 520)
        }
        window.addEventListener('resize', hundlesSize)

        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])



    hopitalEleves.forEach((e) => {
        const dateAller = new Date(e.date_Aller);
        const dateRetour = new Date(e.date_retour);
        const differenceMs = dateRetour - dateAller;
        const differenceJours = Math.floor(differenceMs / (1000 * 60 * 60 * 24));

        if (differenceJours > 30) {
            const annees = Math.floor(differenceJours / 365);
            const moisRestants = Math.floor((differenceJours % 365) / 30);
            const joursRestants = (differenceJours % 365) % 30;
            e.dureeMalade = `${annees > 0 ? annees + " an(s)," : ""} ${moisRestants > 0 ? moisRestants + ' mois' : ''} avec ${joursRestants > 0 ? joursRestants + ' jours' : ''} `;
        } else if (differenceJours > 12) {
            const mois = Math.floor(differenceJours / 30);
            const joursRestants = differenceJours % 30;
            e.dureeMalade = `${mois > 0 ? mois + " mois avec" : ""} ${joursRestants > 0 ? joursRestants + " jours" : ""}`;
        } else {
            e.dureeMalade = `${differenceJours > 0 ? differenceJours + ' jours' : ''} `;
        }
    });

    console.log('hopitalEleves', hopitalEleves)


    const [inputValue, setInputValue] = useState('');
    const [sortOrder, setSortOrder] = useState('asc');
    const [sortedColumn, setSortedColumn] = useState(null);

    const ValuesInput = (e) => {
        setInputValue(e.target.value);
    };



    const fildata = (data, inputValue) => {
        if (!inputValue.trim()) {
            return data;
        }
        return data.filter((item) => {
            if (
                item.nom_Complet.toLowerCase().includes(inputValue.toLowerCase()) ||
                item.Classe.toString().toLowerCase().includes(inputValue.toLowerCase()) ||
                item.Nom_Accompagner.toString().toLowerCase().includes(inputValue.toLowerCase()) ||
                item.Numero_tel_Accompagner.toString().toLowerCase().includes(inputValue.toLowerCase()) ||
                item.date.toString().toLowerCase().includes(inputValue.toLowerCase()) ||
                item.date_Aller.toString().toLowerCase().includes(inputValue.toLowerCase()) ||
                item.date_retour.toString().toLowerCase().includes(inputValue.toLowerCase()) ||
                item.dureeMalade.toString().toLowerCase().includes(inputValue.toLowerCase())
            ) {
                return true;
            }
            return false;
        });
    };



    const handleSort = (column) => {
        if (column === sortedColumn) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortOrder('asc');
            setSortedColumn(column);
        }
    };

    const sortData = (data, column, sortOrder) => {
        return data.slice().sort((a, b) => {
            const aValue = a[column];
            const bValue = b[column];

            if (aValue < bValue) return sortOrder === 'asc' ? -1 : 1;
            if (aValue > bValue) return sortOrder === 'asc' ? 1 : -1;
            return 0;
        });
    };

    const filteredData = fildata(hopitalEleves, inputValue);

    const sortedData = sortData(filteredData, sortedColumn, sortOrder);


    function highlightText(text, inputValue) {
        if (!inputValue) return text;

        const highlightedText = [];
        let currentIndex = 0;

        while (currentIndex < text.length) {
            const inputChar = inputValue.toLowerCase();
            const matchIndex = text.substr(currentIndex).toLowerCase().indexOf(inputChar);

            if (matchIndex !== -1) {

                highlightedText.push(text.substring(currentIndex, currentIndex + matchIndex));


                highlightedText.push(
                    <span className="text-orange-500" key={currentIndex + matchIndex}>
                        {text.substring(currentIndex + matchIndex, currentIndex + matchIndex + inputValue.length)}
                    </span>
                );

                currentIndex = currentIndex + matchIndex + inputValue.length;
            } else {
                highlightedText.push(text.substring(currentIndex));
                break;
            }
        }

        return highlightedText;
    }



    const [mobile11, SetMobile11] = useState(window.innerWidth < 501)

    useEffect(() => {
        const hundlesSize = () => {
            SetMobile11(window.innerWidth < 501)
        }
        window.addEventListener('resize', hundlesSize)
        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])

    return (
        <>
            <div className={`bg-white  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
                <div className="p-6 min-h-[90vh]">
                    <div class="sm:flex items-center pr-4  h-max  justify-end">
                        <div class="mx-2">
                            <div class="relative lg:w-64">
                                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <svg
                                        class="w-5 h-5 text-gray-500"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                            clip-rule="evenodd"
                                        ></path>
                                    </svg>
                                </div>
                                <input
                                    Classe="text"
                                    name="email"
                                    id="topbar-search"
                                    class="bg-white border border-gray-300 h-10 text-gray-900 sm:text-lg first-letter:uppercase rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full pl-10 .5"
                                    placeholder="Rechercher..."
                                    onInput={ValuesInput}
                                />
                            </div>
                        </div>
                        <button class="mt-4 sm:mt-0 inline-flex items-start justify-start px-1 py-3 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg">
                            <p class="text-lg first-letter:uppercase font-semibold leading-none text-white">
                                Rerchercher
                            </p>
                        </button>
                    </div>
                    <div class="w-full my-4 ">
                        <div class="bg-white shadow rounded-lg p-4 ">
                            <div class={`b-4 flex items-center justify-between ${mobile2 ? 'flex-col' : ''}`}>
                                <div>
                                    <h3 class="sm:text-xl font-bold text-gray-900 mb-2">
                                        Liste des élèves hospitalisés
                                    </h3>
                                </div>
                            </div>
                            <div class="flex flex-col mt-8 ">
                                <div class="overflow-x-auto rounded-lg">
                                    <div class={`align-middle inline-block min-w-[90em]`}>
                                        <div class="shadow overflow-hidden sm:rounded-lg">
                                            <table class="min-w-full divide-y  divide-gray-200">
                                                <thead class="bg-gray-50">
                                                    <tr>
                                                        <th onClick={() => handleSort('nom_Complet')}
                                                            scope="col"
                                                            class=" py-4 px-2 font-semibold relative border-l text-left cursor-pointer text-lg first-letter:uppercase  text-gray-500  tracking-wider"
                                                        >
                                                            Nom d'élèves
                                                            {sortedColumn === 'nom_Complet' && <span className="pr-0 absolute right-2">{sortOrder === 'asc' ? '▲' : '▼'}</span>}
                                                        </th>
                                                        <th onClick={() => handleSort('Classe')}
                                                            scope="col"
                                                            class=" py-4 px-2 w-[7em] font-semibold relative border-l text-left cursor-pointer text-lg first-letter:uppercase  text-gray-500  tracking-wider"
                                                        >
                                                            Classe
                                                            {sortedColumn === 'Classe' && <span className="pr-0 absolute right-2">{sortOrder === 'asc' ? '▲' : '▼'}</span>}
                                                        </th>

                                                        <th onClick={() => handleSort('Nom_Accompagner')}
                                                            scope="col"
                                                            class=" py-4  px-2 border-l text-left cursor-pointer relative text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                                                        >
                                                            Nom d'accompagne
                                                            {sortedColumn === 'Nom_Accompagner' && <span className="pr-0 absolute right-2">{sortOrder === 'asc' ? '▲' : '▼'}</span>}
                                                        </th>
                                                        <th onClick={() => handleSort('Numero_tel_Accompagner')}
                                                            scope="col"
                                                            class=" py-4  px-2 border-l text-left cursor-pointer relative text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                                                        >
                                                            Numero d'accompagne
                                                            {sortedColumn === 'Numero_tel_Accompagner' && <span className="pr-0 absolute right-2">{sortOrder === 'asc' ? '▲' : '▼'}</span>}
                                                        </th>
                                                        <th onClick={() => handleSort('date')}
                                                            scope="col"
                                                            class=" py-4  px-2 border-l text-left cursor-pointer relative text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                                                        >
                                                            Date
                                                            {sortedColumn === 'date' && <span className="pr-0 absolute right-2">{sortOrder === 'asc' ? '▲' : '▼'}</span>}
                                                        </th>

                                                        <th onClick={() => handleSort('date_Aller')}
                                                            scope="col"
                                                            class=" py-4  px-2 border-l text-left cursor-pointer relative text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                                                        >
                                                            Date d'aller
                                                            {sortedColumn === 'date_Aller' && <span className="pr-0 absolute right-2">{sortOrder === 'asc' ? '▲' : '▼'}</span>}
                                                        </th>
                                                        <th onClick={() => handleSort('date_retour')}
                                                            scope="col"
                                                            class=" py-4  px-2 border-l text-left cursor-pointer relative text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                                                        >
                                                            Date de retour
                                                            {sortedColumn === 'date_retour' && <span className="pr-0 absolute right-2">{sortOrder === 'asc' ? '▲' : '▼'}</span>}
                                                        </th>

                                                        <th
                                                            scope="col"
                                                            class=" py-4  px-2 border-l text-left  relative text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                                                        >
                                                            Temps de malade
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody class="bg-white">
                                                    {sortedData.map((data, index) => {
                                                        return (<tr key={index}>
                                                            <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                                {highlightText(data.nom_Complet, inputValue)}
                                                            </td>

                                                            <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase  text-gray-500">
                                                                {highlightText(data.Classe.toString(), inputValue)}
                                                            </td>
                                                            <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase  text-gray-500">
                                                                {highlightText(data.Nom_Accompagner.toString(), inputValue)}
                                                            </td>
                                                            <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase  text-gray-500">
                                                                {highlightText(data.Numero_tel_Accompagner.toString(), inputValue)}
                                                            </td>
                                                            <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase  text-gray-500">
                                                                {highlightText(data.date.toString(), inputValue)}
                                                            </td>

                                                            <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase  text-gray-500">
                                                                {highlightText(data.date_Aller.toString(), inputValue)}
                                                            </td>

                                                            <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase  text-gray-500">
                                                                {highlightText(data.date_retour.toString(), inputValue)}
                                                            </td>
                                                            <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase  text-gray-500">
                                                                {highlightText(data.dureeMalade.toString(), inputValue)}
                                                            </td>

                                                        </tr>)
                                                    })}
                                                    {sortedData.length == 0 &&
                                                        <tr>
                                                            <td colSpan={8} className="h-20 w-full text-center text-2xl text-gray-400"> Aucun élément correspondant à <span className="font-bold">{inputValue}</span> </td>
                                                        </tr>}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FootterCopy />
            </div>

        </>
    );
}

export default HostpotalisePrefetDisplin;





