import React from "react";
import SlideNavInfermie from "../SlideNavInfermie/SlideNavInfermie";
import AjouterDepensesInfirmeier from "./AjouterDepensesInfirmeier";
function AjouterDepensesInfirmeierComponent({ getSizeMobille, getMenuMobille }) {
  return (
    <>
      <div class="overflow-hidden flex justify-between">
        <SlideNavInfermie getMenuMobille={getMenuMobille} />
        <AjouterDepensesInfirmeier getSizeMobille={getSizeMobille} />
      </div>
    </>
  );
}

export default AjouterDepensesInfirmeierComponent;
