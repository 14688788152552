import React from 'react'
import MaterielListeEncadrementEmpruntPersonneAll from './MaterielListeEncadrementEmpruntPersonneAll'
import SlideEncadrementNavBars from '../SlideEncadrementNavBars/SlideEncadrementNavBars'
function MaterielListeEncadrementEmpruntPersonneAllComponent({ getSizeMobille, getMenuMobille }) {
    return (
        <div className='flex justify-between overflow-hidden bg-white'>

            <SlideEncadrementNavBars getMenuMobille={getMenuMobille} />
            <MaterielListeEncadrementEmpruntPersonneAll getSizeMobille={getSizeMobille} />

        </div>
    )
}

export default MaterielListeEncadrementEmpruntPersonneAllComponent
