import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select"
import { API } from "../../../Api/API";

function AjoutHorairePrefetDisplin({ getSizeMobille }) {

    const OptionJours = [
        { value: '', label: '' },
        { value: 'Lundi', label: 'Lundi' },
        { value: 'Mardi', label: 'Mardi' },
        { value: 'Mercredi', label: 'Mercredi' },
        { value: 'Jeudi', label: 'Jeudi' },
        { value: 'Vendredi', label: 'Vendredi' },
        { value: 'Samedi', label: 'Samedi' },
        { value: 'Dimanche', label: 'Dimanche' }
    ]

    const hiddenSOptionJours = OptionJours.slice(1);

    const customStyles = {
        control: (provided) => ({
            ...provided,
            height: mobile ? 25 : 50,
            backgroundColor: "white",
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? "blue" : state.isFocused ? "lightblue" : "white",
            color: state.isSelected ? "white" : "black",
        }),
    };


    const [mobile11, SetMobile11] = useState(window.innerWidth < 501);

    useEffect(() => {
        const hundlesSize = () => {
            SetMobile11(window.innerWidth < 501);
        };
        window.addEventListener('resize', hundlesSize);
        return () => {
            window.removeEventListener('resize', hundlesSize);
        };
    }, []);

    const [mobile, SetMobile] = useState(window.innerWidth <= 640);
    const [boutLoading, setboutLoading] = useState(false);

    useEffect(() => {
        const hundleSize = () => {
            SetMobile(window.innerWidth <= 640);
        };
        window.addEventListener('resize', hundleSize);
        return () => {
            window.removeEventListener('resize', hundleSize);
        };
    }, []);




    const [blocs, setBlocs] = useState([
        { jours: '', HeursActivites: [{ plage_heures: '', activite: '' }] },
    ]);


    const addBloc = () => {
        setBlocs([...blocs, { jours: '', HeursActivites: [{ plage_heures: '', activite: '' }] }]);
        if (inputRefs.current[blocs.length] && inputRefs.current[blocs.length].HeursActivites.length === 0) {
            inputRefs.current[blocs.length].HeursActivites.push({
                plage_heures: React.createRef(null),
                activite: React.createRef(null)
            });
        }
    };


    const removeBloc = (index) => {
        const newBlocs = [...blocs];
        newBlocs.splice(index, 1);
        setBlocs(newBlocs);
    };

    const inputRefs = useRef([]);
    if (inputRefs.current.length !== blocs.length) {
        inputRefs.current = Array(blocs.length).fill({}).map((_, index) => ({
            jours: React.createRef(null),
            HeursActivites: Array(blocs[index].HeursActivites.length).fill({}).map((__, tableIndex) => ({
                plage_heures: React.createRef(null),
                activite: React.createRef(null),
            })),
        }));
    }





    const addHeursActivites = (index) => {
        const newBlocs = [...blocs];
        newBlocs[index].HeursActivites.push({ plage_heures: '', activite: '' });
        setBlocs(newBlocs);
        if (inputRefs.current[index].HeursActivites.length !== newBlocs[index].HeursActivites.length) {
            inputRefs.current[index].HeursActivites.push({
                plage_heures: React.createRef(null),
                activite: React.createRef(null),
            });
        }
    };


    const removeHeursActivites = (blocIndex, tableIndex) => {
        const newBlocs = [...blocs];
        newBlocs[blocIndex].HeursActivites.splice(tableIndex, 1);
        inputRefs.current[blocIndex].HeursActivites.splice(tableIndex, 1);
        setBlocs(newBlocs);
    };




    const handleInputChange = (index, field, option) => {
        const newBlocs = [...blocs];
        newBlocs[index][field] = option;
        setBlocs(newBlocs);
        if (option && inputRefs.current[index][field].current) {
            inputRefs.current[index][field].current.classList.remove('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500');
        }
    };


    const handleTableInputChange = (blocIndex, tableIndex, field, value) => {
        const newBlocs = [...blocs];
        newBlocs[blocIndex].HeursActivites[tableIndex][field] = value;
        setBlocs(newBlocs);
    };




    const animateAndScrollToRef = (ref) => {
        if (ref && ref.current) {
            ref.current.classList.add('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500', 'outline-none',);
            setTimeout(() => {
                ref.current.classList.remove('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500', 'outline-none',);
            }, 5000);
            ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            ref.current.focus();
        }
    };


    const validateBloc = (bloc, index) => {
        const requiredFields = ['jours'];

        //Validation pour les champs vide
        for (const field of requiredFields) {
            if (!bloc[field]) {
                toast.warning(<div>Le <strong>{field === 'jours' ? 'jours ' : null}</strong> est obligatoire à l'horaire {index + 1}.</div>);
                if (inputRefs.current[index][field].current) {
                    inputRefs.current[index][field].current.classList.add('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500', 'outline-none',);
                    setTimeout(() => {
                        inputRefs.current[index][field].current.classList.remove('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500', 'outline-none',);
                    }, 5000);
                    inputRefs.current[index][field].current.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    inputRefs.current[index][field].current.focus();
                    return false;
                }
            }
        }
        const isdepluque = blocs.some((b, i) => i !== index && b.jours === bloc.jours);
        if (isdepluque) {
            const duplicateBlockIndex = blocs.findIndex((b, i) => i !== index && b.jours === bloc.jours);
            const duplicateBlockNumber = toRoman(duplicateBlockIndex + 1);
            toast.warning(
                <div>
                    Vous avez  utilisé le jours <span className="font-bold">{bloc.jours}</span> à la block <span className="font-bold">{toRoman(index + 1)}</span> et à la block <span className="font-bold">{duplicateBlockNumber}</span>. Evitez-le
                </div>
            );
            animateAndScrollToRef(inputRefs.current[index].nomMateriel);
            return false;
        }
        return true;
    };

    const validateTable = (table, blocIndex, tableIndex) => {
        const requiredFields = ['plage_heures', 'activite'];
        for (const field of requiredFields) {
            if (!table[field]) {
                toast.warning(<div>Le<strong>{field === 'plage_heures' ? 'plage d\'heure' : field === 'activite' ? 'activite de cet heures' : null}</strong> est obligatoire à l'horaire {blocIndex + 1}, table {tableIndex + 1}.</div>);
                if (inputRefs.current[blocIndex].HeursActivites[tableIndex][field].current) {
                    inputRefs.current[blocIndex].HeursActivites[tableIndex][field].current.classList.add('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500', 'outline-none',);
                    setTimeout(() => {
                        inputRefs.current[blocIndex].HeursActivites[tableIndex][field].current.classList.remove('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500', 'outline-none',);
                    }, 5000);
                    inputRefs.current[blocIndex].HeursActivites[tableIndex][field].current.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    inputRefs.current[blocIndex].HeursActivites[tableIndex][field].current.focus();
                    return false;
                }
            }
        }

        // Validation de la plage d'heure unique à l'intérieur du même bloc HeursActivites
        const duplicateIndex = blocs[blocIndex].HeursActivites.findIndex((t, i) => i !== tableIndex && t.plage_heures === table.plage_heures);
        if (duplicateIndex !== -1) {
            const duplicateTableNumber = toRoman(duplicateIndex + 1);
            toast.warning(
                <div>
                    Vous avez utilisé la plage d'heure <span className="font-bold">{table.plage_heures}</span> à la table <span className="font-bold">{toRoman(tableIndex + 1)}</span> et à la table <span className="font-bold">{duplicateTableNumber}</span> dans le même bloc. Évitez-la.
                </div>
            );

            animateAndScrollToRef(inputRefs.current[blocIndex].HeursActivites[tableIndex].plage_heures);
            return false;
        }


        return true;
    };





    const navigate = useNavigate()
    function toRoman(num) {
        const romanNumerals = [
            "I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX", "X",
            "XI", "XII", "XIII", "XIV", "XV", "XVI", "XVII", "XVIII", "XIX", "XX",
            "XXI", "XXII", "XXIII", "XXIV", "XXV", "XXVI", "XXVII", "XXVIII", "XXIX", "XXX"
        ];
        return romanNumerals[num - 1] || num.toString();
    }


    const Datas = blocs.map((bloc, blocIndex) => ({
        jours: bloc.jours,
        HeursActivites: bloc.HeursActivites.map((e) => ({
            time: e.plage_heures,
            activite: e.activite
        })),
    }));

    const handleSubmit = (e) => {
        e.preventDefault();

        for (let index = 0; index < blocs.length; index++) {
            if (!validateBloc(blocs[index], index)) {
                return;
            }
            for (let tableIndex = 0; tableIndex < blocs[index].HeursActivites.length; tableIndex++) {
                if (!validateTable(blocs[index].HeursActivites[tableIndex], index, tableIndex)) {
                    return;
                }
            }
        }



        API.post('/HI/hinternecontent', Datas).then((resp) => {
            toast.success(resp.data)
            navigate('/Horaire')
        }).catch((err) => {
            console.log(err.message)
        })
    };




    return (
        <div className={`bg-white  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
            <Link to='/Horaire' className="ml-2 text-[18px] text-blue-500 hover:text-blue-800 underline">Retour</Link>
            <div className="sm:p-4 p-2 min-h-screen">
                <div className="my-5">
                    <div className="w-full flex flex-row items-center justify-between">
                        <p className="text-navy-700">
                            <p>Enregistrement des horaires</p>
                        </p>
                    </div>
                </div>
                <div className="w-full my-4 ">
                    <div className="w-full my-4 grid ">
                        <form onSubmit={handleSubmit} className="flex flex-col items-center">
                            <div className="rounded-lg p-2 border border-blue-500 w-[95%]  sm:w-[40em]  sm:p-4">
                                {blocs.map((bloc, index) => (
                                    <div key={index} className="justify_center rounded-xl w-full p-1 sm:p-2 mb-10 border border-green-600 flex flex-col">
                                        <div className="w-full">
                                            <div className="h-max w-full">
                                                <div className="px-3 w-full">
                                                    <div className="w-full flex items_center justify-between">
                                                        <label className="block text-xs mt-5  font-[700] text-gray-700 first_letter:uppercase tracking_wide text-grey-darker sm:text-lg mb-1">
                                                            {toRoman(index + 1)}. Block
                                                        </label>
                                                        {index > 0 && (
                                                            <button
                                                                montant="button"
                                                                className="text-red-500 px-1 max-sm:text-xs py-2"
                                                                onClick={() => removeBloc(index)}
                                                            >
                                                                Supprimer le jour
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mb-2">
                                            <div className="rounded-xl p-2 w-full">
                                                <div className="flex flex-col">
                                                    <div className="rounded-lg w-full">
                                                        <div class="w-full mb-5">
                                                            <label class="block  tracking-wide text-gray-700 text-xs sm:text-lg  mb-1">
                                                                Jours
                                                            </label>
                                                            <div ref={inputRefs.current[index].jours} className={` rounded `}>
                                                                <Select
                                                                    value={{ value: bloc.jours, label: bloc.jours }}
                                                                    options={hiddenSOptionJours}
                                                                    styles={customStyles}
                                                                    onChange={(option) => handleInputChange(index, "jours", option.value)}
                                                                />

                                                            </div>
                                                        </div>
                                                    </div>

                                                    {bloc.HeursActivites.map((table, tableIndex) => (
                                                        <div key={tableIndex} className="my-4">
                                                            <div className="left-5 relative mb-3">{toRoman(tableIndex + 1)}. Activite du jour <span className="pl-2 font-bold">{bloc.jours}</span> </div>
                                                            <div className="rounded-lg p-2 border border-orange-500   w-full sm:p-4">
                                                                <div className="rounded-xl p-2 w-full">
                                                                    <div className="flex flex-col">
                                                                        <div className="rounded-lg w-full">
                                                                            <div className="w-full mb-5">
                                                                                <label
                                                                                    className="block tracking-wide text-grey-darker text-lg mb-2"
                                                                                    htmlFor={`plage_heures_${index}_${tableIndex}`}
                                                                                >
                                                                                    Plage d'heure
                                                                                </label>
                                                                                <input
                                                                                    ref={inputRefs.current[index].HeursActivites[tableIndex].plage_heures}
                                                                                    className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-red rounded py-3 px-4 mb-3"
                                                                                    value={table.plage_heures}
                                                                                    onChange={(e) => handleTableInputChange(index, tableIndex, 'plage_heures', e.target.value)}
                                                                                    placeholder="10h15 à 16h45"
                                                                                    id={`plage_heures_${index}_${tableIndex}`}
                                                                                />
                                                                            </div>
                                                                        </div>


                                                                        <div className="rounded-lg w-full">
                                                                            <div class="w-full mb-5">
                                                                                <label
                                                                                    class="block  tracking-wide text-grey-darker text-lg  mb-2"
                                                                                    htmlFor={`plage_heures_${index}_${tableIndex}`}
                                                                                >                                                                                Activite prévis
                                                                                </label>
                                                                                <textarea
                                                                                    id={`plage_heures_${index}_${tableIndex}`}
                                                                                    ref={inputRefs.current[index].HeursActivites[tableIndex].activite}
                                                                                    className="appearance-none h-[10em] outline-none focus:border-2 focus:border-blue-700 resize-none block w-full bg-grey-lighter text-grey-darker border border-red rounded py-3 px-4 mb-3"
                                                                                    value={table.activite}
                                                                                    onChange={(e) => handleTableInputChange(index, tableIndex, 'activite', e.target.value)}
                                                                                    placeholder="Activite prévis....."
                                                                                ></textarea>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>


                                                                {bloc.HeursActivites.length > 1 && (
                                                                    <div className="flex justify-end">
                                                                        <button
                                                                            montant="button"
                                                                            className="text-red-500 px-1 max-sm:text-xs py-2"
                                                                            onClick={() => removeHeursActivites(index, tableIndex)}
                                                                        >
                                                                            Supprimer l'activite
                                                                        </button>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    ))}

                                                    <div className="flex justify-end">
                                                        <button
                                                            montant="button"
                                                            className="bg-orange-500 hover-bg-blue-700 text-white py-1 px-2 max-sm:text-xs sm:py-2 sm:px-4 rounded"
                                                            onClick={() => addHeursActivites(index)}
                                                        >
                                                            Autre activite
                                                        </button>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <div className="w-full flex justify-end">
                                    <button
                                        montant="button"
                                        className="bg-green-500 hover-bg-blue-700 text-white py-1 px-2 max-sm:text-xs sm:py-2 sm:px-4 rounded"
                                        onClick={addBloc}
                                    >
                                        Autre jour
                                    </button>
                                </div>

                            </div>

                            <div className="flex justify-end md:w-[40em] w-[95%]">
                                {boutLoading ? (
                                    <></>
                                ) : (
                                    <label htmlFor="send" className="mt-3 cursor-pointer w-max flex justify-end bg-blue-600 p-2 rounded text-white">
                                        <input type="submit" id="send" value='Soumettre' className='cursor-pointer'></input>
                                        <i className="bi bi-send ml-2 "></i>
                                    </label>
                                )}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default AjoutHorairePrefetDisplin;