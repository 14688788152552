import React, { useState } from "react";
import SideBarSecretaire from "../SideBarSecretaire/SideBarSecretaire";
import Classeur from "./Classeur";
function ClasseurComponent({ getSizeMobille, getMenuMobille }) {
  return (
    <>
      {" "}
      <div class="flex overflow-hidden bg-white justify-between">
        <SideBarSecretaire getMenuMobille={getMenuMobille} />
        <Classeur getSizeMobille={getSizeMobille} />
      </div>
    </>
  );
}

export default ClasseurComponent;
