import React from 'react'
import DetailRefectoirePrefetDisplin from './DetailRefectoirePrefetDisplin'
import SideBarsPrefetDisplin from '../SideBarsPrefetDisplin/SideBarsPrefetDisplin'
function DetailRefectoirePrefetDisplinComponent({ getSizeMobille, getMenuMobille }) {
    return (
        <div className='flex justify-between overflow-hidden bg-white'>
            <SideBarsPrefetDisplin getMenuMobille={getMenuMobille} />
            <DetailRefectoirePrefetDisplin getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default DetailRefectoirePrefetDisplinComponent
