import React from 'react'
import RetournMaterielEncadrement from './RetournMaterielEncadrement'
import SlideEncadrementNavBars from '../SlideEncadrementNavBars/SlideEncadrementNavBars'
function RetournMaterielEncadrementComponent() {
    return (
        <div className='flex justify-between bg-white overflow-hidden'>
            <SlideEncadrementNavBars />
            <RetournMaterielEncadrement />
        </div>
    )
}

export default RetournMaterielEncadrementComponent
