import React from "react";
import AjouterCongeAnnuelSecretComponent from "../../component/Secretariat/CongeAnnuel/AjouterCongeAnnuelSecretComponent";
function AjouterCongeAnnuelSecretPage({ getSizeMobille, getMenuMobille }) {
  return (
    <>
      <AjouterCongeAnnuelSecretComponent getMenuMobille={getMenuMobille} getSizeMobille={getSizeMobille} />
    </>
  );
}

export default AjouterCongeAnnuelSecretPage;
