import React, { Component, useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts';
import { InformationsElevesEtablissements } from '../../../Data/Data';
import FootterCopy from '../../FootterCopy/FootterCopy';
import { Link } from 'react-router-dom';
function RapporDisplintPrefetDisplin({ getSizeMobille }) {
    const [mobile11, SetMobile11] = useState(window.innerWidth < 501)
    const [mobile12, SetMobile12] = useState(window.innerWidth < 1222)
    useEffect(() => {
        const hundlesSize = () => {
            SetMobile11(window.innerWidth < 501)
            SetMobile12(window.innerWidth < 1222)
        }
        window.addEventListener('resize', hundlesSize)
        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])

    InformationsElevesEtablissements.forEach((e) => {
        e.pourcentage = (e.Nombres_Eleves_fautes * 100 / e.Nombres_total_eleves_classe).toFixed(0)
    })

    InformationsElevesEtablissements.sort((a, b) => b.pourcentage - a.pourcentage);

    return (
        <div className={`bg-white flex flex-col items-center  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
            <div className='w-full flex justify-start'>
                <Link to='/Statistique' className="ml-2 text-left text-[18px] text-blue-500 hover:text-blue-800 underline">Retour</Link>
            </div>
            <div className='flex flex-col m-2 w-[98%] bg-gray-50 p-1 rounded'>
                <div className='flex justify-center items-center border-b border-white pb-3 font-bold sm:text-xl max-sm:text-center'>La statistique disciplinaire dans une classe</div>
                <div className='w-full overflow-y-auto'>
                    <div className={`${mobile12 ? 'min-w-[70em]' : 'w-full'}`}>
                        <ApexChart />
                    </div>
                </div>
            </div>

            <div className='flex flex-col w-[98%] mt-10  bg-slate-50'>
                <div className='flex justify-center items-center border-b border-white pt-3 pb-3 font-bold sm:text-2xl max-sm:text-center'>La statistique disciplinaire</div>
                {InformationsElevesEtablissements.map((data, index) => (
                    <div key={index} className={`bg-white p-2 mx-2 my-3 border  border-fuchsia-300 rounded-lg`}>
                        <div className={`text-lg font-semibold ml-3 `}>{data.Nom_classe}</div>
                        <div className='flex items-center w-full'>
                            <div className={` flex items-center w-[95%]`}>
                                <div className={`h-2  ${data.pourcentage > 50 ? 'bg-[#8000002f]' : 'bg-[#0080002f]'} w-full relative overflow-hidden  ml-3  rounded-full`}>
                                    <div className={`bg-[green] ${data.pourcentage > 50 ? 'bg-[red]' : 'bg-[green]'}  rounded-full absolute   h-full`} style={{ width: `${data.pourcentage}%` }}></div>
                                </div>
                            </div>
                            <div className={`${data.pourcentage > 50 ? 'text-red-800 ' : 'text-green-800 '}  font-bold ml-3 `}>{data.pourcentage}%</div>
                        </div>
                        <div className={`text-lg ml-3 text-gray-500 `}> {data.Nombres_Eleves_fautes} élèves indisplines</div>
                    </div>
                ))}
            </div>
            <FootterCopy />
        </div>
    )
}

export default RapporDisplintPrefetDisplin

class ApexChart extends Component {
    constructor(props) {
        super(props);
        this.state = {

            series: [{
                name: 'Les élèves indisciplines',
                data: InformationsElevesEtablissements.map(classe => classe.Nombres_Eleves_fautes)
            }, {
                name: 'Les élèves conformes',
                data: InformationsElevesEtablissements.map(classe => (classe.Nombres_total_eleves_classe - classe.Nombres_Eleves_fautes))
            }, {
                name: 'Nombre total des élèves',
                data: InformationsElevesEtablissements.map(classe => classe.Nombres_total_eleves_classe)
            }],
            options: {
                chart: {
                    type: 'bar',
                    height: 50
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        dataLabels: {
                            position: 'center',
                        },
                        columnWidth: '27em',
                        endingShape: 'rounded'
                    }
                },
                dataLabels: {
                    enabled: true,
                    style: {
                        fontSize: '12px',
                        colors: ['#fff']
                    }
                },
                stroke: {
                    show: true,
                    width: 1,
                    colors: ['#fff']
                },
                xaxis: {
                    categories: InformationsElevesEtablissements.map(classe => classe.Nom_classe),
                },
                yaxis: {
                    title: {
                        text: 'Nombres d\'élèves avec fautes'
                    }
                },
                fill: {
                    opacity: 1
                },
                tooltip: {
                    shared: true,
                    intersect: false,
                    y: {
                        formatter: function (val, { dataPointIndex, w }) {
                            const data = w.config.series[0].data;
                            const data2 = w.config.series[2].data;
                            const correspondingData = data[dataPointIndex];
                            const correspondingData2 = data2[dataPointIndex]
                            if (correspondingData) {
                                const percentage = (val * 100) / correspondingData2;
                                return val + ' correspond aux ' + percentage.toFixed(0) + '%';
                            }
                        }
                    }
                }

            },


        };
    }

    render() {
        return (

            <div>
                <ReactApexChart options={this.state.options} series={this.state.series} type="bar" height={500} />
            </div>
        );
    }
}

