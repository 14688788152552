import React, { useState } from "react";
import LivresComponent from "../../component/Bibliotheque/Livres/LivresComponent";
function LivresPage({ getSizeMobille, getMenuMobille }) {
  return (
    <>
      <LivresComponent getMenuMobille={getMenuMobille} getSizeMobille={getSizeMobille} />
    </>
  );
}

export default LivresPage;
