import React from 'react'
import DepensePrefetDisplin from './DepensePrefetDisplin'
import SideBarsPrefetDisplin from '../SideBarsPrefetDisplin/SideBarsPrefetDisplin'
function DepensePrefetDisplinCompoent({ getSizeMobille, getMenuMobille }) {
    return (
        <div className='flex justify-between overflow-hidden bg-white'>
            <SideBarsPrefetDisplin getMenuMobille={getMenuMobille} />
            <DepensePrefetDisplin getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default DepensePrefetDisplinCompoent
