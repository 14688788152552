import React from 'react'
import SideBarsPrefetDisplin from '../SideBarsPrefetDisplin/SideBarsPrefetDisplin'
import RapportEncaderPrefet from './RapportEncaderPrefet'
function RapportEncaderPrefetComponent({ getSizeMobille, getMenuMobille }) {
    return (
        <div className='flex justify-between  overflow-hidden bg-white'>
            <SideBarsPrefetDisplin getMenuMobille={getMenuMobille} />
            <RapportEncaderPrefet getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default RapportEncaderPrefetComponent
