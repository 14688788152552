import React, { useEffect, useState } from 'react'
import FootterCopy from '../../FootterCopy/FootterCopy'
import { API } from '../../../Api/API'
import axios from "axios"
import { toast } from 'react-toastify'
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
function PresenceInfiemier({ getSizeMobille }) {
    const [mobile1, Setmonile1] = useState(window.innerWidth < 349)
    const [mobile11, SetMobile11] = useState(window.innerWidth < 501)
    useEffect(() => {
        const hundlesSize = () => {
            Setmonile1(window.innerWidth < 349)
            SetMobile11(window.innerWidth < 501)
        }

        window.addEventListener('resize', hundlesSize)

        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])

    const staffId = 2
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [locationInfo, setLocationInfo] = useState(null);
    const [loading, setLoading] = useState(true);
    const [changeStatus, hendeleChangeStatus] = useState(false);
    const [isPresentInToDay, setIsPresentInToDay] = useState("")
    const [isPresentInToDayId, setIsPresentInToDayId] = useState("")
    useEffect(() => {
        // Fonction pour obtenir la géolocalisation
        const getLocation = () => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(async (position) => {
                    const roundedLatitude = position.coords.latitude.toFixed(7);
                    const roundedLongitude = position.coords.longitude.toFixed(7);
                    // Mettre à jour les états
                    setLatitude(roundedLatitude);
                    setLongitude(roundedLongitude);

                    // Utiliser les coordonnées pour effectuer un géocodage inversé
                    const location = await reverseGeocode(roundedLatitude, roundedLongitude);
                    setLocationInfo(location);
                }, (error) => {
                    console.error("Erreur de géolocalisation :", error);
                });
            } else {
                console.error("La géolocalisation n'est pas prise en charge par ce navigateur.");
            }
        };

        getLocation();
    }, [latitude, longitude]);

    const reverseGeocode = async (latitude, longitude) => {
        try {
            const response = await axios.get(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`);

            if (response.data && response.data.display_name) {
                const location = response.data.display_name;
                // console.log(`Lieu trouvé : ${location}`);
                return location;
            } else {
                console.error('Impossible de trouver le lieu.');
                return null;
            }
        } catch (error) {
            console.error('Erreur lors de la requête de géocodage inversé :', error.message);
            return null;
        }
    };



    const handlePresentIn = async () => {
        try {
            API.post('/staff/Presence', {
                staffId: staffId,
                latitude: latitude,
                longitude: longitude,
                locationInfo: locationInfo,
            }).then(response => {
                hendeleChangeStatus(!changeStatus)
                toast.success(response.data)
            }).catch((error) => {
                console.log("Err")
            })
        } catch (error) {
            console.error('Erreur lors de l\'envoi des données à l\'API :', error.message);
        }
    };

    const handlePresentOut = async () => {
        try {
            API.put('/staff/PresenceOut', {
                locationInfo: locationInfo,
                isPresentInToDayId: isPresentInToDayId,
            }).then(response => {
                hendeleChangeStatus(!changeStatus)
                setIsPresentInToDay("PresentOut")
                toast.success(response.data)
            }).catch((error) => {
                console.log("Err")
            })
        } catch (error) {
            console.error('Erreur lors de l\'envoi des données à l\'API :', error.message);
        }

    };
    useEffect(() => {
        API.get(`/staff/PresentInToDay/${staffId}`).then(response => {
            if (response.data) {
                setIsPresentInToDayId(response.data.pId)
                setIsPresentInToDay(response.data.pContent)
            }
        }).catch((error) => {
            console.log("Err", error.message)
        })
    }, [staffId, changeStatus])




    /**
     * Tous les presences
     */

    const [staffAllPresences, setstaffAllPresences] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [totalElements, setTotalElements] = useState(0)


    useEffect(() => {
        fetchstaffAllPresences(currentPage, pageSize);
    }, [currentPage, pageSize, changeStatus]);

    const fetchstaffAllPresences = async (page, size) => {
        try {
            let url = `/staff/AllPresences/${staffId}?page=${page}&pageSize=${size}`;
            const response = await API.get(url);
            setstaffAllPresences(response.data.AllPresences);
            setTotalPages(response.data.totalPages);
            setTotalElements(response.data.totalElements);

        } catch (error) {
            console.error('Erreur lors de la récupération des staffPresences :', error.message);
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePageSizeChange = (size) => {
        setPageSize(size);
        setCurrentPage(1);
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const formatDate = date => {
        return format(new Date(date), "'Le 'd, MMMM yyyy", { locale: fr });
    };

    const getRange = () => {
        const from = (currentPage - 1) * pageSize + 1;
        const to = Math.min(currentPage * pageSize, totalElements);
        return `${from} à ${to} presences sur ${totalElements}`;
    };


    const [mobile2, Setmonile2] = useState(window.innerWidth < 1008.9)

    return (
        <div className={`bg-white  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>

            <div className="p-6  min-h-[85vh]">
                <div class="my-10 flex items-center justify-center">
                    <h3 class="text-xl sm:text-2xl text-center leading-none font-bold text-gray-900">
                        Votre signalisation
                    </h3>
                </div>

                <div class="my-4 w-full items-center flex flex-col gap-4">

                    <div className="max-w-sm mx-auto mt-8 p-4 bg-white rounded-lg shadow-md">
                        {latitude && longitude ? (
                            <div>
                                <p className="text-lg font-semibold mb-2">Coordonnées Géographiques</p>
                                <p>Latitude: {latitude}</p>
                                <p>Longitude: {longitude}</p>
                                {locationInfo && <p>Emplacement: {locationInfo}</p>}
                            </div>
                        ) : (
                            <div className="flex items-center justify-center">
                                {loading ? (
                                    <svg
                                        className="animate-spin h-5 w-5 mr-3 text-blue-500"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V1.862A10 10 0 003.138 14.762l1.416 1.415A8 8 0 014 12z"
                                        ></path>
                                    </svg>
                                ) : null}
                                <p>{loading ? 'En cours de chargement...' : 'Échec de la récupération des données.'}</p>
                            </div>
                        )}
                    </div>

                    {locationInfo && (
                        isPresentInToDay == "PresentIn" ? (<button
                            onClick={handlePresentOut}
                            className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 cursor-pointer hover:bg-gray-100 hover:shadow-lg transition duration-300 ease-in-out hover:scale-105 transform"
                        >
                            <div className="flex items-center">
                                <div className="flex-shrink-0">
                                    <h3 className="text-xl font-normal text-gray-700">Signale que vous partez</h3>
                                </div>
                            </div>
                        </button>) : isPresentInToDay == "PresentOut" ? "" : (<button
                            onClick={handlePresentIn}
                            className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 cursor-pointer hover:bg-gray-100 hover:shadow-lg transition duration-300 ease-in-out hover:scale-105 transform"
                        >
                            <div className="flex items-center">
                                <div className="flex-shrink-0">
                                    <h3 className="text-xl font-normal text-gray-700">Signale que vous arrivez</h3>
                                </div>
                            </div>
                        </button>)
                    )}

                </div>
            </div>


            <div className={`${mobile2 ? 'mb-5' : ''}`}>
                <div class="">
                    <div class="">
                        <div class="">
                            <div className=" flex items-center">
                                <p className="sm:text-xl">Taille de la page :</p>
                                <select
                                    value={pageSize}
                                    onChange={(e) => handlePageSizeChange(Number(e.target.value))}
                                    className="ml-2 p-2.5 cursor-pointer bg-white border border-gray-300 rounded"
                                >
                                    <option value={1}>1</option>
                                    <option value={5}>5</option>
                                    <option value={10}>10</option>
                                    <option value={20}>20</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class={`sm:flex items-center ${mobile2 ? 'w-full ' : ''}  justify-end`}>
            </div>
            <div class="flex flex-col mt-8">
                <div class="overflow-x-auto rounded-lg">
                    <div class="align-middle inline-block min-w-full">
                        <div class="shadow overflow-hidden sm:rounded-lg">
                            <table class="min-w-full divide-y divide-gray-200">
                                <thead class="bg-gray-50">
                                    <tr>

                                        <th
                                            scope="col"
                                            class="p-4 text-left text-lg font-medium text-gray-500 uppercase tracking-wider"
                                        >
                                            entré
                                        </th>
                                        <th
                                            scope="col"
                                            class="p-4 text-left text-lg font-medium text-gray-500 uppercase tracking-wider"
                                        >
                                            sorti
                                        </th>
                                        <th
                                            scope="col"
                                            class="p-4 text-left text-lg font-medium text-gray-500 uppercase tracking-wider"
                                        >
                                            endroit d' entré
                                        </th>
                                        <th
                                            scope="col"
                                            class="p-4 text-left text-lg font-medium text-gray-500 uppercase tracking-wider"
                                        >
                                            endroit de sorti
                                        </th>
                                        <th
                                            scope="col"
                                            class="p-4 text-left text-lg font-medium text-gray-500 uppercase tracking-wider"
                                        >
                                            longitude
                                        </th>
                                        <th
                                            scope="col"
                                            class="p-4 text-left text-lg font-medium text-gray-500 uppercase tracking-wider"
                                        >
                                            latitude
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="bg-white">
                                    {staffAllPresences && staffAllPresences.length > 0 ? staffAllPresences.map((l) => {
                                        return (
                                            <tr>

                                                <td class="border-b border-gray-200 p-4 whitespace-nowrap text-lg font-normal text-gray-500">
                                                    {l.inat && formatDate(l.inat)}
                                                </td>
                                                <td class="border-b border-gray-200 p-4 whitespace-nowrap text-lg font-normal text-gray-500">
                                                    {l.outat ? formatDate(l.outat) : '-'}
                                                </td>

                                                <td class="border-b border-gray-200 p-4 whitespace-nowrap text-lg font-semibold text-gray-900">
                                                    {l.Endroitin}
                                                </td>
                                                <td class="border-b border-gray-200 p-4 whitespace-nowrap text-lg font-semibold text-gray-900">
                                                    {l.Endroitout ? l.Endroitout : '-'}
                                                </td>
                                                <td class="border-b border-gray-200 p-4 whitespace-nowrap text-lg font-normal text-gray-500">
                                                    {l.longitude}
                                                </td>
                                                <td class="border-b border-gray-200 p-4 whitespace-nowrap text-lg font-normal text-gray-500">
                                                    {l.latitude}
                                                </td>
                                            </tr>
                                        )
                                    }) :
                                        (
                                            <tr>
                                                <td colSpan={7} className="w-full">
                                                    <div className="bg-transparent sm:text-[25px] w-full h-[50vh] flex justify-center items-center">
                                                        Désolé, aucune correspondance  pour   <span className='font-semibold ml-4 mr-4'>les presences</span>{" "} trouvée
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    }

                                </tbody>
                            </table>
                            {staffAllPresences && staffAllPresences.length > 0 && (
                                <>
                                    {/* Pagination */}
                                    {/* Pagination controls */}
                                    <div className="mt-4 flex items-center justify-center">
                                        <div className="flex items-center">
                                            <button
                                                disabled={currentPage === 1}
                                                onClick={handlePreviousPage}
                                                className="mx-1 px-3 py-1 rounded bg-gray-300 text-gray-700"
                                            >
                                                Précédent
                                            </button>
                                            {Array.from({ length: totalPages }, (_, index) => index + 1).map((pageNumber) => (
                                                <button
                                                    key={pageNumber}
                                                    onClick={() => handlePageChange(pageNumber)}
                                                    className={`mx-1 px-3 py-1 rounded ${currentPage === pageNumber ? 'bg-gray-700 text-white' : 'bg-gray-300 text-gray-700'}`}
                                                >
                                                    {pageNumber}
                                                </button>
                                            ))}
                                            <button
                                                disabled={currentPage === totalPages}
                                                onClick={handleNextPage}
                                                className="mx-1 px-3 py-1 rounded bg-gray-300 text-gray-700"
                                            >
                                                Suivant
                                            </button>
                                        </div>
                                    </div>

                                    <div className="mt-4 flex items-center justify-center">
                                        <p className="text-gray-600">{getRange()}</p>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>


            <FootterCopy />
        </div>
    )
}

export default PresenceInfiemier
