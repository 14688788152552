/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import "apexcharts/dist/apexcharts.css";
import FootterCopy from "../../FootterCopy/FootterCopy";

function AcceuilInfermiere({ getSizeMobille }) {
    const totalMedicaments = 500;
    const medicamentsExpire = 355;
    const medicamentsConsomme = 100;
    const medicamentsRestants = totalMedicaments - medicamentsExpire - medicamentsConsomme;

    const pourcentageExpire = (medicamentsExpire / totalMedicaments) * 100;
    const pourcentageConsomme = (medicamentsConsomme / totalMedicaments) * 100;
    const pourcentageRestants = (medicamentsRestants / totalMedicaments) * 100;

    const options = {
        chart: {
            type: 'bar',
            height: 350
        },
        series: [
            {
                name: 'Consommés',
                data: [medicamentsConsomme],
                color: '#006E1B'

            },
            {
                name: 'Expirés',
                data: [medicamentsExpire],
                color: '#DA0000'
            },

            {
                name: 'Restants',
                data: [medicamentsRestants],
                color: '#0323A4'

            }
        ],
        xaxis: {
            categories: ['']
        }
    };

    const [mobile1, setMobile1] = useState(window.innerWidth < 621);
    const [mobile2, setMobile2] = useState(window.innerWidth < 844);
    const [mobile11, setMobile11] = useState(window.innerWidth < 501);

    useEffect(() => {
        const handleSize = () => {
            setMobile1(window.innerWidth < 621);
            setMobile2(window.innerWidth < 844);
            setMobile11(window.innerWidth < 501);
        }

        window.addEventListener('resize', handleSize);

        return () => {
            window.removeEventListener('resize', handleSize);
        }
    }, []);

    return (
        <div className={`bg-white overflow-y-auto overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'} ${getSizeMobille ? 'w-full' : 'w-[85vw]'}`}>
            <div className="sm:p-4 p-1 min-h-screen">
                <div className="text-3xl font-bold text-gray-500">Bienvenue</div>
                <div className="my-4 w-full bg-slate-100 rounded-xl p-2 gap-4">
                    <h1 className="text-2xl w-full text-center text-blue-800 font-semibold mb-4">Statistiques des soins</h1>
                    <div className="h-[1px] bg-white my-2 w-full"></div>
                    <div className="bg-white w-full rounded-lg p-8">
                        <div className="gap-4 w-full flex flex-wrap justify-around">
                            <div className="bg-blue-500 text-white rounded-lg p-4 w-[17em]">
                                <h2 className="text-2xl font-bold mb-2">42</h2>
                                <p className="text-gray-200">Nombre d'élèves soignés</p>
                            </div>

                            <div className="bg-green-500 text-white rounded-lg p-4 w-[17em]">
                                <h2 className="text-2xl font-bold mb-2">15</h2>
                                <p className="text-gray-200">Nombre de personnes soignées</p>
                            </div>

                            <div className="bg-yellow-500 text-white rounded-lg p-4 w-[17em]">
                                <h2 className="text-2xl font-bold mb-2">8</h2>
                                <p className="text-gray-200">Nombre de particuliers soignés</p>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="my-4 w-full bg-slate-100 rounded-xl p-2 gap-4">
                    <h1 class="text-2xl w-full  text-center text-blue-800 font-semibold  mb-4">Statistiques des médicaments</h1>
                    <div className="h-[1px] bg-white my-2 w-full"></div>
                    <div class={`bg-white  w-full justify-around flex rounded-lg p-2 sm:p-8 ${mobile2 ? ' flex-col' : ''}`}>
                        <div className={`rounded-lg p-8 border ${mobile2 ? 'w-full mb-10' : 'w-[45%]  '} `}>
                            <div class="flex w-full  flex-col items-end mb-10">
                                <div className="flex">
                                    <div class="text-gray-600 font-bold mr-1">Médicaments totaux :</div>
                                    <div class="text-gray-500">{totalMedicaments}</div>
                                </div>
                                <div class="text-gray-500">Mardi, le 12/09/2023</div>
                            </div>
                            <div class=" gap-4 w-full flex flex-wrap justify-around ">

                                <div class="w-full  mt-6">
                                    <div class="flex items-center justify-between mb-2">
                                        <div class="text-gray-500">Médicaments consommés</div>
                                        <div class="text-gray-700">{medicamentsConsomme}</div>
                                    </div>
                                    <div class="bg-green-200 h-3 rounded-full">
                                        <div class={`bg-green-700 h-3 rounded-full`} style={{ width: `${pourcentageConsomme}%` }}></div>
                                    </div>
                                    <div class="flex items-center justify-between mt-2">
                                        <div class="text-gray-500">Pourcentage consommé</div>
                                        <div class="text-gray-700">{pourcentageConsomme.toFixed(2)}%</div>
                                    </div>
                                </div>



                                <div class="w-full mt-6">
                                    <div class="flex items-center justify-between mb-2">
                                        <div class="text-gray-500">Médicaments expirés</div>
                                        <div class="text-gray-700">{medicamentsExpire}</div>
                                    </div>
                                    <div class="bg-red-200 h-3 rounded-full">
                                        <div class={`bg-red-700 h-3 rounded-full`} style={{ width: `${pourcentageExpire}%` }}></div>
                                    </div>
                                    <div class="flex items-center justify-between mt-2">
                                        <div class="text-gray-500">Pourcentage expiré</div>
                                        <div class="text-gray-700">{pourcentageExpire.toFixed(2)}%</div>
                                    </div>
                                </div>




                                <div class="w-full mt-6">
                                    <div class="flex items-center justify-between mb-2">
                                        <div class="text-gray-500">Médicaments restants</div>
                                        <div class="text-gray-700">{medicamentsRestants}</div>
                                    </div>
                                    <div class="bg-blue-200 h-3 rounded-full">
                                        <div class={`bg-blue-900 h-3 rounded-full`} style={{ width: `${pourcentageRestants}%` }}></div>
                                    </div>
                                    <div class="flex items-center justify-between mt-2">
                                        <div class="text-gray-500">Pourcentage restant</div>
                                        <div class="text-gray-700">{pourcentageRestants.toFixed(2)}%</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`rounded-lg p-8 border ${mobile2 ? 'w-full mb-10' : 'w-[45%]  '} `}>
                            <Chart
                                options={options}
                                series={options.series}
                                type="bar"

                                height={500}
                                width='100%'
                            />
                        </div>
                    </div>
                </div>
            </div>
            <FootterCopy />
        </div>
    );
}

export default AcceuilInfermiere;
