import React from 'react'
import SlideParentNavBars from '../SlideParentNavBars/SlideParentNavBars'
import ListeEvaluationAllParent from './ListeEvaluationAllParent'
function ListeEvaluationAllParentComponent({ getSizeMobille, getMenuMobille }) {
    return (
        <div class="overflow-hidden flex justify-between object-cover">
            <SlideParentNavBars getMenuMobille={getMenuMobille} />
            <ListeEvaluationAllParent getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default ListeEvaluationAllParentComponent
