import React, { useState } from "react";
import CartsEleveComponent from "../../component/Bibliotheque/Emprunter/CartsEleveComponent";
function CartsElevePage({ getSizeMobille, getMenuMobille }) {
  return (
    <>
      <CartsEleveComponent getMenuMobille={getMenuMobille} getSizeMobille={getSizeMobille} />
    </>
  );
}

export default CartsElevePage;
