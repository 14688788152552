import React from 'react'
import AjouteAchat from './AjouteAchat'
import SlideNavBarsCantin from '../SlideNavBarsCantin/SlideNavBarsCantin'
function AjouteAchatComponent() {
    return (
        <div className='flex overflow-hidden pt-6 bg-white'>
            <SlideNavBarsCantin />
            <AjouteAchat />
        </div>
    )
}

export default AjouteAchatComponent
