import React, { useState } from "react";
import SideBarSecretaire from "../SideBarSecretaire/SideBarSecretaire";
import AjouterPersonnel from "./AjouterPersonnel";
function AjouterPersonnelComponent({ getMenuMobille, getSizeMobille }) {
  return (
    <>
      {" "}
      <div class="flex overflow-hidden bg-white justify-between">
        <SideBarSecretaire getMenuMobille={getMenuMobille} />
        <AjouterPersonnel getSizeMobille={getSizeMobille} />
      </div>
    </>
  );
}

export default AjouterPersonnelComponent;
