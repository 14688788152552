import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import FootterCopy from "../../FootterCopy/FootterCopy";
import swal from "sweetalert";
import { decryptData } from "../../../encryptionModule";
import { API } from "../../../Api/API";
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { toast } from "react-toastify";
function MedicamDetail({ getSizeMobille }) {
    const { id } = useParams()
    const medId = decryptData(id)
    const navigate = useNavigate()
    const handleConfirmation = async () => {
        try {
            const result = await swal({
                title: "Confirmation",
                text: "Vous Voulez Supprimer ?",
                icon: "warning",
                buttons: ["No", "Oui"],
                dangerMode: true,
                className: "centered-buttons",
            });

            if (result) {
                API.delete(`/INF/deleteOneMedicamentById/${medId}`).then((rep) => {
                    toast.success(rep.data)
                    navigate('/Medicament')
                }).catch((err) => {
                    console.log(err.message)
                })
            }
        } catch (error) {
            // Handle error or dialog dismissal
            console.log("Error or dismissal:", error);
        }
    };
    const [data, setData] = useState({})
    useEffect(() => {
        API.get(`/INF/getOneMedicamentById/${medId}`).then((rep) => {
            setData(rep.data)
        }).catch((err) => {
            console.log(err.message)
        })
    }, [id])
    const formatDate = date => {
        return format(new Date(date), "'Le 'd, MMMM yyyy", { locale: fr });
    };
    return (
        <>
            <div className={`bg-white  overflow-x-auto  h-[85vh] ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
                <Link to='/Medicament' className="ml-2 text-[18px] text-blue-500 hover:text-blue-800 underline">Retour</Link>
                <div className="sm:p-6 p-2  min-h-[85vh]">
                    <div class="my-3">
                        <div class="w-full flex flex-row items-center justify-between">
                            <p class="text-navy-700 shrink  ">
                                <p
                                    class="hover:text-navy-700 text-gray-700 uppercase text-3xl font-bold "

                                >
                                    Detail
                                </p>
                            </p>

                        </div>
                    </div>
                    <div class="w-full my-4 grid grid-cols-1 ">
                        <div class="rounded-lg p-2 sm:p-6 xl:p-8">
                            <div class=" rounded-lg bg-white border flex items-center justify-start">
                                <div class="flex flex-col justify-start p-2">
                                    <p class="mb-4  text-xl ">
                                        Nom du médicament : {data && data.nom}
                                    </p>
                                    <p class="mb-4  text-xl ">
                                        Unité de comptage :{data && data.unitecomptage}
                                    </p>

                                    <p class="mb-4  text-xl ">
                                        Dosage : {data && data.dosage}
                                    </p>

                                    <p class="mb-4  text-xl ">
                                        Quantité : {data && data.quantite}
                                    </p>
                                    <p class="mb-4  text-xl ">
                                        Source : {data && data.origine}
                                    </p>
                                    <p class="text-lg text-neutral-900 ">
                                        Date d'expiration : {data && data.dateexp && formatDate(data.dateexp)}
                                    </p>
                                    {data && (
                                        <div class=" mt-8 flex items-center justify-end ">
                                            <div class="flex flex-row flex-shrink-0 space-x-3">
                                                <Link
                                                    to={`/Medicament/Modifier/${id}`}
                                                    class="mt-4 sm:mt-0 inline-flex items-start justify-start px-6 py-3 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg"
                                                >
                                                    <p class="text-lg font-medium leading-none text-white">
                                                        Modifier
                                                    </p>
                                                </Link>
                                                <button
                                                    onClick={handleConfirmation}
                                                    class="mt-4 sm:mt-0 inline-flex items-start justify-start px-6 py-3 bg-red-700 hover:bg-red-600 focus:outline-none rounded-lg"
                                                >
                                                    <p class="text-lg font-medium leading-none text-white">
                                                        Supprimer
                                                    </p>
                                                </button>
                                            </div>
                                        </div>
                                    )}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FootterCopy />
            </div>
        </>
    );
}

export default MedicamDetail;
