import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FootterCopy from "../../FootterCopy/FootterCopy";
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { API } from "../../../Api/API";
function RapportEncaderPrefet({ getSizeMobille }) {
    const [mobile1, Setmonile1] = useState(window.innerWidth < 990)
    const [mobile2, Setmonile2] = useState(window.innerWidth < 520)

    useEffect(() => {
        const hundlesSize = () => {
            Setmonile1(window.innerWidth < 990)
            Setmonile2(window.innerWidth < 520)
        }

        window.addEventListener('resize', hundlesSize)

        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])



    const [searchTerm, setSearchTerm] = useState("");
    const [rapports, setrapports] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [totalElements, setTotalElements] = useState(0)


    const [etat, setEtat] = useState(1)
    const handleEtatChange = (etat) => {
        setEtat(etat);
    };
    const [rechercher, setRechercher] = useState(false)
    const [isrechercher, setisRechercher] = useState(0)
    function handleSearch() {
        setRechercher(true)
        setisRechercher(isrechercher + 1)
    }



    useEffect(() => {
        fetchrapports(etat, currentPage, pageSize, searchTerm, rechercher);
    }, [currentPage, pageSize, etat, isrechercher]);

    const fetchrapports = async (etat, page, size, searchTerm, rechercher) => {
        try {
            let url = `/HI/AllrapportsGarde?page=${page}&pageSize=${size}`;
            if (rechercher) {
                url = `/HI/AllrapportsGarde?page=${page}&pageSize=${size}&searchTerm=${searchTerm}`;
            }
            const response = await API.get(url);
            const { data, totalPages, totalItems } = response.data;
            setrapports(data);
            setTotalPages(totalPages);
            setTotalElements(totalItems);
        } catch (error) {
            console.error('Erreur lors de la récupération des rapports :', error.message);
        }
    };


    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePageSizeChange = (size) => {
        setPageSize(size);
        setCurrentPage(1);
    };



    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlerapportsiousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const formatDate = date => {
        return format(new Date(date), "'Le 'd, MMMM yyyy", { locale: fr });
    };

    const getRange = () => {
        const from = (currentPage - 1) * pageSize + 1;
        const to = Math.min(currentPage * pageSize, totalElements);
        return `${from} à ${to} rapports sur ${totalElements}`;
    };



    const [inputValue, setInputValue] = useState('');
    const [sortOrder, setSortOrder] = useState('asc');
    const [sortedColumn, setSortedColumn] = useState(null);

    const ValuesInput = (e) => {
        setInputValue(e.target.value);
    };



    const fildata = (data, inputValue) => {
        if (!inputValue.trim()) {
            return data;
        }
        return data.filter((item) => {
            if (
                item.nomComplet.toLowerCase().includes(inputValue.toLowerCase()) ||
                item.matricule.toLowerCase().includes(inputValue.toLowerCase()) ||
                item.date.toString().toLowerCase().includes(inputValue.toLowerCase()) ||
                item.heure.toString().toLowerCase().includes(inputValue.toLowerCase()) ||
                item.description.toString().toLowerCase().includes(inputValue.toLowerCase())
            ) {
                return true;
            }
            return false;
        });
    };



    const handleSort = (column) => {
        if (column === sortedColumn) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortOrder('asc');
            setSortedColumn(column);
        }
    };

    const sortData = (data, column, sortOrder) => {
        return data.slice().sort((a, b) => {
            const aValue = a[column];
            const bValue = b[column];
            if (aValue < bValue) return sortOrder === 'asc' ? -1 : 1;
            if (aValue > bValue) return sortOrder === 'asc' ? 1 : -1;
            return 0;
        });
    };

    const filteredData = fildata(rapports, inputValue);

    const sortedData = sortData(filteredData, sortedColumn, sortOrder);


    function highlightText(text, inputValue) {
        if (!inputValue) return text;

        const highlightedText = [];
        let currentIndex = 0;

        while (currentIndex < text.length) {
            const inputChar = inputValue.toLowerCase();
            const matchIndex = text.substr(currentIndex).toLowerCase().indexOf(inputChar);

            if (matchIndex !== -1) {

                highlightedText.push(text.substring(currentIndex, currentIndex + matchIndex));


                highlightedText.push(
                    <span className="text-orange-500" key={currentIndex + matchIndex}>
                        {text.substring(currentIndex + matchIndex, currentIndex + matchIndex + inputValue.length)}
                    </span>
                );

                currentIndex = currentIndex + matchIndex + inputValue.length;
            } else {
                highlightedText.push(text.substring(currentIndex));
                break;
            }
        }

        return highlightedText;
    }


    const [PhotoClick, GetPhotoClick] = useState('')
    const [zoom, GetZoom] = useState(false)
    const [mobile11, SetMobile11] = useState(window.innerWidth < 501)





    useEffect(() => {
        const hundlesSize = () => {
            SetMobile11(window.innerWidth < 501)
        }
        window.addEventListener('resize', hundlesSize)
        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])


    return (
        <>
            <div className={`bg-white  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
                <Link to='/Encadreur' className="ml-2 text-[18px] text-blue-500 hover:text-blue-800 underline">Retour</Link>
                <div className="p-6 min-h-[90vh]">
                    <div class="sm:flex items-center pr-4  h-max  justify-end">
                        <div class="mx-2">
                            <div class="relative lg:w-64">
                                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <svg
                                        class="w-5 h-5 text-gray-500"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                            clip-rule="evenodd"
                                        ></path>
                                    </svg>
                                </div>
                                <input
                                    type="text"
                                    name="email"
                                    id="topbar-search"
                                    class="bg-white border border-gray-300 h-10 text-gray-900 sm:text-lg first-letter:uppercase rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full pl-10 .5"
                                    placeholder="Rechercher..."
                                    onInput={ValuesInput}
                                />
                            </div>
                        </div>
                        <button class="mt-4 sm:mt-0 inline-flex items-start justify-start px-1 py-3 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg">
                            <p class="text-lg first-letter:uppercase font-semibold leading-none text-white">
                                Rerchercher
                            </p>
                        </button>
                    </div>
                    <div class="w-full my-4 ">
                        <div class="bg-white shadow rounded-lg p-4 ">
                            <div class={`b-4 flex items-center justify-between ${mobile2 ? 'flex-col' : ''}`}>
                                <div>
                                    <h3 class="sm:text-xl font-bold text-gray-900 mb-2">
                                        Listes des encadreur
                                    </h3>
                                </div>
                            </div>
                            <div class="flex flex-col mt-8 ">
                                <div class="overflow-x-auto rounded-lg">
                                    <div class={`align-middle inline-block  ${mobile1 ? 'min-w-[70em]' : 'min-w-full'}`}>
                                        <div class="shadow overflow-hidden sm:rounded-lg">
                                            <table class="min-w-full divide-y  divide-gray-200">
                                                <thead class="bg-gray-50">
                                                    <tr>
                                                        <th onClick={() => handleSort('nomComplet')}
                                                            scope="col"
                                                            class=" py-4 px-2 font-semibold relative border-l text-left cursor-pointer text-lg first-letter:uppercase  text-gray-500  tracking-wider"
                                                        >
                                                            Nom complet
                                                            {sortedColumn === 'nomComplet' && <span className="pr-0 absolute right-2">{sortOrder === 'asc' ? '▲' : '▼'}</span>}
                                                        </th>
                                                        <th onClick={() => handleSort('matricule')}
                                                            scope="col"
                                                            class=" py-4 px-2 font-semibold relative border-l text-left cursor-pointer text-lg first-letter:uppercase  text-gray-500  tracking-wider"
                                                        >
                                                            Matricule
                                                            {sortedColumn === 'matricule' && <span className="pr-0 absolute right-2">{sortOrder === 'asc' ? '▲' : '▼'}</span>}
                                                        </th>
                                                        <th onClick={() => handleSort('date')}
                                                            scope="col"
                                                            class=" py-4 px-2 font-semibold relative border-l text-left cursor-pointer text-lg first-letter:uppercase  text-gray-500  tracking-wider"
                                                        >
                                                            Date
                                                            {sortedColumn === 'date' && <span className="pr-0 absolute right-2">{sortOrder === 'asc' ? '▲' : '▼'}</span>}
                                                        </th>

                                                        <th onClick={() => handleSort('heure')}
                                                            scope="col"
                                                            class=" py-4 w-[2em] px-2 border-l text-left cursor-pointer relative text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                                                        >
                                                            Heure
                                                            {sortedColumn === 'heure' && <span className="pr-0 absolute right-2">{sortOrder === 'asc' ? '▲' : '▼'}</span>}
                                                        </th>


                                                        <th onClick={() => handleSort('description')}
                                                            scope="col"
                                                            class=" py-4 w-[2em] px-2 border-l text-left cursor-pointer relative text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                                                        >
                                                            Déscription
                                                            {sortedColumn === 'description' && <span className="pr-0 absolute right-2">{sortOrder === 'asc' ? '▲' : '▼'}</span>}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody class="bg-white">
                                                    {sortedData.map((data, index) => {
                                                        return (<tr key={index}>
                                                            <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                                {highlightText(data.nomComplet, inputValue)}
                                                            </td>
                                                            <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                                {highlightText(data.matricule, inputValue)}
                                                            </td>

                                                            <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase  text-gray-500">
                                                                {highlightText(data.date.toString(), inputValue)}
                                                            </td>
                                                            <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase  text-gray-500">

                                                                {highlightText(data.heure.toString(), inputValue)}
                                                            </td>
                                                            <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase  text-gray-500">
                                                                {highlightText(data.description.toString(), inputValue)}
                                                            </td>
                                                        </tr>)
                                                    })}
                                                    {sortedData.length == 0 &&
                                                        <tr>
                                                            <td colSpan={7} className="h-20 w-full text-center text-2xl text-gray-400"> Aucun élément correspondant à <span className="font-bold">{inputValue}</span> </td>
                                                        </tr>}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FootterCopy />

                <div className={`fixed  flex justify-center  items-center top-0 left-0 bg-[#00000044] z-[999] w-full transition-all delay-75 h-full ${zoom ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'}`}>
                    <div className={` bg-white rounded-2xl flex justify-center items-center transition-all duration-200 relative delay-100 ${zoom ? 'w-[99%] h-[99%] opacity-100 pointer-events-auto' : 'w-[99%] h-[0%] pointer-events-none opacity-0'}`}>
                        <div onClick={() => { GetZoom(false) }} className="absolute right-2 top-2 p-2 rounded-full bg-red-200">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-octagon h-5 w-5 text-red-600 cursor-pointer" viewBox="0 0 16 16">
                                <path d="M4.54.146A.5.5 0 0 1 4.893 0h6.214a.5.5 0 0 1 .353.146l4.394 4.394a.5.5 0 0 1 .146.353v6.214a.5.5 0 0 1-.146.353l-4.394 4.394a.5.5 0 0 1-.353.146H4.893a.5.5 0 0 1-.353-.146L.146 11.46A.5.5 0 0 1 0 11.107V4.893a.5.5 0 0 1 .146-.353L4.54.146zM5.1 1 1 5.1v5.8L5.1 15h5.8l4.1-4.1V5.1L10.9 1z" />
                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                            </svg>
                        </div>
                        <img src={`../images/${PhotoClick}`} className="w-full h-full object-contain" />
                    </div>
                </div>

            </div>

        </>
    );
}

export default RapportEncaderPrefet;
