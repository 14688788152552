/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import FootterCopy from "../../FootterCopy/FootterCopy";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select"
import { FadeLoader } from 'react-spinners'
import { API } from "../../../Api/API";
import { decryptData } from "../../../encryptionModule";

function ModeHoraireEncaPreftDisplin({ getSizeMobille }) {
    const { id } = useParams()
    const history = useNavigate()
    const [mobile11, SetMobile11] = useState(window.innerWidth < 501)

    useEffect(() => {
        const hundlesSize = () => {
            SetMobile11(window.innerWidth < 501)
        }
        window.addEventListener('resize', hundlesSize)
        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])



    const [mobile, SetMobile] = useState(window.innerWidth <= 640)
    const [eledemmade, GeteleDemmande] = useState([])
    const [boutLoading, setboutLoading] = useState(false)

    useEffect(() => {
        const hundleSize = () => {
            SetMobile(window.innerWidth <= 640)
        }
        window.addEventListener('resize', hundleSize)

        return () => {
            window.removeEventListener('resize', hundleSize)
        }
    }, [])


    const OptionEncadreur = [];
    const [isloading, setloading] = useState(true);


    API.get('/staff/getAllEncadreur').then(response => {
        const encadreurs = response.data;
        setloading(false)
        encadreurs.forEach(encadreur => {
            const value = `${(encadreur.id).toString()}`;
            const label = `${encadreur.nomcomplet}: ${encadreur.matricule}`;
            OptionEncadreur.push({ value, label });
        });

    }).catch(error => {
        setloading(false)
        console.error(error);
    });







    const OptionHeure = [
        { value: '', label: 'Heure de travail' },
        { value: '8h à 14h', label: '8h à 14h' },
        { value: '14h à 17h30', label: '14h à 17h30' },
        { value: '17h30 à 8h du matin', label: '17h30 à 8h du matin' }
    ]

    const hiddenOptionHeure = OptionHeure.slice(1);


    const customStyles = {
        control: (provided) => ({
            ...provided,
            height: mobile ? 25 : 50,
            backgroundColor: "white",
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? "blue" : state.isFocused ? "lightblue" : "white",
            color: state.isSelected ? "white" : "black",
        }),
    };
    const [date, setDate] = useState('');
    const [selectedEncadreur, setSelectedEncadreur] = useState({ value: "", label: "Encandeur" });
    const [selectedTime, setselectedTime] = useState(OptionHeure[0]);

    useEffect(() => {
        setloading(true)
        API.get(`/HI/gardehById/${decryptData(id)}`).then(response => {
            const gardeh = response.data;
            setDate(gardeh.date.substring(0, 10))


            if (gardeh.staffId) {
                const correspondingEncadreur = OptionEncadreur.find(
                    (Encadreur) => Encadreur.value == gardeh.staffId.toString()
                );
                if (correspondingEncadreur) {
                    setSelectedEncadreur(correspondingEncadreur);
                }
            }
            if (gardeh.time) {
                const correspondingtime = OptionHeure.find(
                    (acces) => acces.value == gardeh.time
                );
                if (correspondingtime) {
                    setselectedTime(correspondingtime);
                }
            }
            setloading(false)

        }).catch(error => {
            setloading(false)
            console.error(error);
        });
    }, [id])






    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(selectedTime, selectedEncadreur)
        const Datas = {
            staffId: selectedEncadreur.value,
            date: date,
            time: selectedTime.value,
        }


        API.put(`/HI/updateHoraireaugarde/${decryptData(id)}`, Datas)
            .then(response => {
                toast.success('Données envoyées avec succès');
                history("/Encadreur/List")
            })
            .catch(error => {
                if (error.response) {

                    const errorData = error.response.data;
                    console.log(errorData)
                    if (Array.isArray(errorData)) {

                        toast.error(errorData[0]);
                        return false;
                    } else {
                        toast.error(errorData || 'Erreur serveur');
                        return false;
                    }
                } else {
                    toast.error('Erreur serveur');
                }
            });

    };


    return (
        !isloading && (
            <div className={`bg-white  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
                <Link to='/Encadreur/List' className="ml-2 text-[18px] text-blue-500 hover:text-blue-800 underline">Retour</Link>

                <div className="sm:p-4 p-2 min-h-screen">
                    <div class="my-5">
                        <div class="w-full flex flex-row items-center justify-between">
                            <p class="text-navy-700   ">
                                <a
                                    class="hover:text-navy-700 text-2xl font-bold  "
                                >
                                    Enregistrement des horaires
                                </a>
                            </p>

                        </div>
                    </div>
                    <div className="w-full my-4 grid grid-cols-1">
                        <div className="w-full my-4 grid grid-cols-1">
                            <form onSubmit={handleSubmit} className="flex flex-col items-center">
                                <div className="rounded-lg p-2 border border-blue-500 md:w-[40em] w-[95%] sm:p-4">

                                    <div className="justify_center rounded-xl w-full  p-1 sm:p-2 mb-10  border-[1px] flex flex-col">
                                        <div className="w-full">
                                            <div className="h-max w-full">
                                                <div className="px-3  w-full">
                                                    <div className="w-full flex items_center justify-between">
                                                        <label className="block  text-xs mt-5 font-serif text-gray-700 first_letter:uppercase tracking_wide text-grey-darker sm:text-lg  mb-1">
                                                            Horaire
                                                        </label>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mb-2">
                                            <div className="rounded-xl p-2 w-full">
                                                <div className="flex flex-col">
                                                    <div className="rounded-lg w-full">
                                                        <div class="w-full mb-5">
                                                            <label class="block  tracking-wide text-gray-700 text-xs sm:text-lg  mb-1">
                                                                Nom d'encadreur
                                                            </label>
                                                            <div className={` rounded `}>
                                                                <Select
                                                                    value={selectedEncadreur}
                                                                    options={OptionEncadreur}
                                                                    styles={customStyles}
                                                                    onChange={(option) => { setSelectedEncadreur(option); }} />
                                                            </div>



                                                        </div>
                                                    </div>
                                                    <div className="rounded-lg w-full">
                                                        <div class="w-full mb-5">
                                                            <label class="block  tracking-wide text-gray-700 text-xs sm:text-lg  mb-1">
                                                                Date de travail
                                                            </label>

                                                            <div className={` rounded border  py-3 pl-3 `}>
                                                                <input
                                                                    type="date"
                                                                    value={date}
                                                                    className="outline-none border-none"
                                                                    onChange={(option1) => setDate(option1.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="rounded-lg w-full">
                                                        <div class="w-full mb-5">
                                                            <label class="block  tracking-wide text-gray-700 text-xs sm:text-lg  mb-1">
                                                                Heure de travail
                                                            </label>
                                                            <div className={` rounded `}>
                                                                <Select
                                                                    value={selectedTime}
                                                                    options={hiddenOptionHeure}
                                                                    styles={customStyles}
                                                                    onChange={(option2) => setselectedTime(option2)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                                <div className=" flex justify-end  md:w-[40em] w-[95%]">

                                    {boutLoading ? (
                                        <>
                                            <label disabled className="cursor-no-drop w-max relative  mt-3 flex justify-center  items-center   bg-green-950    p-2 rounded  text-gray-400">
                                                <input type="submit" id="send" value='Soumettre' className='pointer-events-none' />
                                                <i class="bi bi-send ml-2  pointer-events-none "></i>
                                                <div disabled className='absolute pointer-events-none  bg-transparent pt-4  pl-4  w-full h-full flex justify-center items-center z-50'>
                                                    <FadeLoader
                                                        color="rgb(255, 255, 255)"
                                                        height={10}
                                                        margin={-9}
                                                        radius={100}
                                                        speedMultiplier={1}
                                                        width={1}
                                                    />
                                                </div>
                                            </label>
                                        </>
                                    ) : (<>
                                        <label for="send" className=" mt-3 cursor-pointer w-max  flex justify-end  bg-green-600   p-2 rounded  text-white">
                                            <input type="submit" id="send" value='Soumettre' className='cursor-pointer'></input>
                                            <i class="bi bi-send ml-2 "></i>
                                        </label>
                                    </>)}

                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <FootterCopy />
            </div>)

    );
}


export default ModeHoraireEncaPreftDisplin;










