import React from 'react'
import SlideEncadrementNavBars from '../SlideEncadrementNavBars/SlideEncadrementNavBars'
import ModifieEmpruntMaterielEncadrement from './ModifieEmpruntMaterielEncadrement'
function ModifieEmpruntMaterielEncadrementComponent({ getSizeMobille, getMenuMobille }) {
    return (
        <div className='flex justify-between overflow-hidden bg-white'>
            <SlideEncadrementNavBars getMenuMobille={getMenuMobille} />
            <ModifieEmpruntMaterielEncadrement getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default ModifieEmpruntMaterielEncadrementComponent
