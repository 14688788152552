import React from "react";
import SildeEnseign from "../SildeEnseign/SildeEnseign";
import AjouterDepenseEns from "./AjouterDepenseEns";
function AjouterDepenseEnsComponent() {
  return (
    <>

      <div class="overflow-hidden flex justify-between">
        <SildeEnseign />
        <AjouterDepenseEns />
      </div>
    </>
  );
}

export default AjouterDepenseEnsComponent;
