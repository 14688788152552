import React from 'react'
import ModifierPrevisionPrefetDisplin from './ModifierPrevisionPrefetDisplin'
import SideBarsPrefetDisplin from '../SideBarsPrefetDisplin/SideBarsPrefetDisplin'
function ModifierPrevisionPrefetDisplinComponent({ getSizeMobille, getMenuMobille }) {
  return (
    <div className='flex justify-between overflow-hidden bg-white'>
      <SideBarsPrefetDisplin getMenuMobille={getMenuMobille} />
      <ModifierPrevisionPrefetDisplin getSizeMobille={getSizeMobille} />
    </div>
  )
}

export default ModifierPrevisionPrefetDisplinComponent
