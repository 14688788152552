/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';
import { toast } from 'react-toastify';
import Select from "react-select"
import { Link } from 'react-router-dom';
import FootterCopy from '../../FootterCopy/FootterCopy';


const DestrubuerTraitementInfirmnie = ({ getSizeMobille }) => {
    const MedicOptions = [
        { value: "", label: "Sélectionner un médicament" },
        { value: "Analgésiques", label: "Analgésiques" },
        { value: "Antibiotiques", label: "Antibiotiques" },
        { value: "Antidépresseurs", label: "Antidépresseurs" },
        { value: "Antihistaminiques", label: "Antihistaminiques" },
        { value: "Antihypertenseurs", label: "Antihistaminiques" },
        { value: "Anticoagulants", label: "Anticoagulants" },
        { value: "Antidiabétiques", label: "Antidiabétiques" },
        { value: "Antiacides", label: "Antiacides" },
        { value: "Anti-inflammatoires", label: "Anti-inflammatoires" },
        { value: "Anticonvulsivants", label: "Anticonvulsivants" },
    ]

    const PetitOptions = [
        { value: "", label: "Petit chirurgie" },
        { value: "Non", label: "Non" },
        { value: "Oui", label: "Oui" },
    ];
    const RéféréOptions = [
        { value: "", label: "Référé" },
        { value: "Non", label: "Non" },
        { value: "Oui", label: "Oui" },
    ];

    const [Motif, SetMotif] = useState('')
    const [animationClassMotif, setAnimationClassMotif] = useState('');
    const elemetRefMotif = useRef(null)


    const hiddenSMedicOptions = MedicOptions.slice(1);
    const hiddenRéféréOptions = RéféréOptions.slice(1);
    const hiddenPetitOptions = PetitOptions.slice(1);

    const [selectedOption3, setSelectedOption3] = useState(PetitOptions[0]);
    const [selectedOption4, setSelectedOption4] = useState(RéféréOptions[0]);

    const SelectPetit = (option) => {
        setSelectedOption3(option)
    }
    const SelectRéféré = (option) => {
        setSelectedOption4(option)
    }

    const customStyles = {
        control: (provided) => ({
            ...provided,
            height: 50,
            backgroundColor: "white",
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? "blue" : state.isFocused ? "lightblue" : "white",
            color: state.isSelected ? "white" : "black",
        }),
    };


    const [mobile1, setMobile1] = useState(window.innerWidth < 621);
    const [mobile2, setMobile2] = useState(window.innerWidth < 844);
    const [mobile11, setMobile11] = useState(window.innerWidth < 501);

    useEffect(() => {
        const handleSize = () => {
            setMobile1(window.innerWidth < 621);
            setMobile2(window.innerWidth < 844);
            setMobile11(window.innerWidth < 501);
        };

        window.addEventListener("resize", handleSize);

        return () => {
            window.removeEventListener("resize", handleSize);
        };
    }, []);



    const [animationClassPetit, setanimationClassPetit] = useState('');
    const elemetRefPetit = useRef(null);

    const [animationClassRéféré, setanimationClassRéféré] = useState('');
    const elemetRefRéféré = useRef(null);


    const [Moyen, SetMoyen] = useState('')
    const [animationClassMoyen, setAnimationClassMoyen] = useState('');
    const elemetRefMoyen = useRef(null)



    const [TypesPayemnt, SetTypesPayemnt] = useState('')
    const [animationClassTypesPayemnt, setAnimationClassTypesPayemnt] = useState('');
    const elemetRefTypesPayemnt = useRef(null)

    const [montant, Setmontant] = useState('')
    const [animationClassmontant, setAnimationClassmontant] = useState('');
    const elemetmontant = useRef(null)



    useEffect(() => {
        if (elemetRefMotif.current) {
            elemetRefMotif.current.style.height = '7em';
            elemetRefMotif.current.style.height = `${elemetRefMotif.current.scrollHeight}px`;
            elemetRefMotif.current.scrollTop = elemetRefMotif.current.scrollHeight;
        }
    }, [Motif]);


    useEffect(() => {
        if (elemetRefMotif.current) {
            elemetRefMotif.current.style.height = '7em';
            elemetRefMotif.current.style.height = `${elemetRefMotif.current.scrollHeight}px`;
            elemetRefMotif.current.scrollTop = elemetRefMotif.current.scrollHeight;
        }
    }, [Motif]);


    const [blocs, setBlocs] = useState([
        { medicament: '', type: '', duree: '', jours: '', Quantite: '' }
    ]);
    const addBloc = () => {
        setBlocs([...blocs, { medicament: '', type: '', duree: '', jours: '', Quantite: '' }]);
    };
    const removeBloc = (index) => {
        const newBlocs = [...blocs];
        newBlocs.splice(index, 1);
        setBlocs(newBlocs);
    };
    const inputRefs = useRef([]);
    if (inputRefs.current.length !== blocs.length) {
        inputRefs.current = Array(blocs.length).fill({}).map((_, index) => ({
            medicament: React.createRef(null),
            type: React.createRef(null),
            duree: React.createRef(null),
            jours: React.createRef(null),
            Quantite: React.createRef(null),
        }));
    }

    const handleInputChange = (index, field, value) => {
        const newBlocs = [...blocs];
        newBlocs[index][field] = value;
        setBlocs(newBlocs);
        if (value && inputRefs.current[index][field].current) {
            inputRefs.current[index][field].current.classList.remove('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500');
        }
    };

    const validateBloc = (bloc, index) => {
        const requiredFields = ['medicament', 'type', 'duree', 'jours', 'Quantite'];

        for (const field of requiredFields) {
            if (!bloc[field]) {
                toast.warning(<div>Le <strong>{field == 'medicament' ? 'nom du medicament' : field == 'type' ? 'type du medicament' : field == 'duree' ? 'durée de consomation du medicament' : field == 'jours' ? 'jour de consomation du medicament' : field == 'Quantite' ? 'quantite' : null}</strong> est obligatoire dans le bloc {index + 1}.</div>);
                if (inputRefs.current[index][field].current) {
                    inputRefs.current[index][field].current.classList.add('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500', 'outline-none');
                    setTimeout(() => {
                        inputRefs.current[index][field].current.classList.remove('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500', 'outline-none');
                    }, 3000);
                    inputRefs.current[index][field].current.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    inputRefs.current[index][field].current.focus();
                }
                return false;
            }
        }

        return true;
    };

    function toRoman(num) {
        const romanNumerals = [
            "I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX", "X",
            "XI", "XII", "XIII", "XIV", "XV", "XVI", "XVII", "XVIII", "XIX", "XX",
            "XXI", "XXII", "XXIII", "XXIV", "XXV", "XXVI", "XXVII", "XXVIII", "XXIX", "XXX"
        ];
        return romanNumerals[num - 1] || num.toString();
    }

    const selectedMedicaments = {
        medicaments: blocs.map((bloc) => ({
            medicament: bloc.medicament.value,
            type: bloc.type,
            duree: bloc.duree,
            jours: bloc.jours,
            Quantite: bloc.Quantite,
        })),
        Petit_chirurgie: selectedOption3.value,
        Référé: selectedOption4.value,
        ...(selectedOption4.value === 'Oui' ? { Moyen: Moyen } : null),
        ...(selectedOption4.value === 'Oui' ? { Motif: Motif } : null),
        Type_payement: TypesPayemnt,
        Montant_payement: montant,
    };


    const handleSubmit = (e) => {
        e.preventDefault();

        for (let index = 0; index < blocs.length; index++) {
            if (!validateBloc(blocs[index], index)) {
                return;
            }
        }
        if (selectedMedicaments.Petit_chirurgie.trim() == '') {
            toast.warning('Précisez si un patien est passe au petit chirurgie', {
                autoClose: 2000
            });
            setanimationClassPetit('animate__animated animate__shakeX border-2 border-red-500')
            setTimeout(() => {
                setanimationClassPetit('focus:border-2 focus:border-blue-700')
            }, 3000)
            elemetRefPetit.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            elemetRefPetit.current.focus()

            return
        }
        else if (selectedMedicaments.Référé.trim() == '') {
            toast.warning('Précisez s\'il y a une référance', {
                autoClose: 2000
            });
            setanimationClassRéféré('animate__animated animate__shakeX border-2 border-red-500')
            setTimeout(() => {
                setanimationClassRéféré('focus:border-2 focus:border-blue-700')
            }, 3000)
            elemetRefRéféré.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            elemetRefRéféré.current.focus()
            return
        } else if (selectedMedicaments.Référé == 'Oui') {
            if (Moyen.trim() == '') {
                toast.warning('Moyen', {
                    autoClose: 2000
                });
                setAnimationClassMoyen('animate__animated animate__shakeX border-2 border-red-500')
                setTimeout(() => {
                    setAnimationClassMoyen(' ')
                }, 3000)
                elemetRefMoyen.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
                elemetRefMoyen.current.focus()
                return false
            } else if (Motif.trim() == '') {
                toast.warning('Motif', {
                    autoClose: 2000
                });
                setAnimationClassMotif('animate__animated animate__shakeX border-2 border-red-500')
                setTimeout(() => {
                    setAnimationClassMotif(' ')
                }, 3000)
                elemetRefMotif.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
                elemetRefMotif.current.focus()

            }
        }
        else if (selectedMedicaments.Type_payement.trim() == '') {
            toast.warning('Précise le type payement', {
                autoClose: 2000
            });
            setAnimationClassTypesPayemnt('animate__animated animate__shakeX border-2 border-red-500')
            setTimeout(() => {
                setAnimationClassTypesPayemnt(' ')
            }, 3000)
            elemetRefTypesPayemnt.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            elemetRefTypesPayemnt.current.focus()
        }
        else if (selectedMedicaments.Montant_payement.trim() == '') {
            toast.warning('Précise le montant payé', {
                autoClose: 2000
            });
            setAnimationClassmontant('animate__animated animate__shakeX border-2 border-red-500')
            setTimeout(() => {
                setAnimationClassmontant(' ')
            }, 3000)
            elemetmontant.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            elemetmontant.current.focus()
        }
        console.log('selectedMedicaments', selectedMedicaments)
    };



    return (
        <div className={`bg-white overflow-y-auto overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'} ${getSizeMobille ? 'w-full' : 'w-[85vw]'}`}>
            <Link to='/Traitement' className="ml-2 text-[18px] text-blue-500 hover:text-blue-800 underline">Retour</Link>
            <div className="sm:p-4 p-1 min-h-screen">
                <div className="my-5">
                    <div className="w-full flex flex-row items_center justify-between">
                        <p className="text-navy-700 shrink">
                            <p className="hover-text-navy-700 text-2xl font-bold first_letter:uppercase text-gray-500">
                                Ordonnance
                            </p>
                        </p>
                    </div>
                </div>
                <div className="w-full my-4 grid grid-cols-1">
                    <div className="w-full my-4 grid grid-cols-1">
                        <form onSubmit={handleSubmit} className="flex flex-col items-center">
                            <div className="rounded-lg p-4 border border-blue-500 md:w-[40em] w-[95%] sm:p-4">
                                {blocs.map((bloc, index) => (
                                    <div key={index} className="justify_center rounded-xl w-full  p-2 mb-10  border-[1px] flex flex-col">
                                        <div className="w-full">
                                            <div className="h-max w-full">
                                                <div className="px-3  w-full">
                                                    <div className="w-full flex items_center justify-between">
                                                        <label className="block font-serif text-gray-700 first_letter:uppercase tracking_wide text-grey-darker text-lg  mb-1">
                                                            {toRoman(index + 1)}. catégorie
                                                        </label>
                                                        {index > 0 && (
                                                            <button
                                                                type="button"
                                                                className="text-red-500 px-1 py-2"
                                                                onClick={() => removeBloc(index)}
                                                            >
                                                                Supprimer
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="mb-2">
                                            <div className="rounded-xl p-2 w-full">
                                                <div className="flex flex-col">
                                                    <div className="rounded-lg w-full">
                                                        <div class="w-full mb-5">
                                                            <label class="block  tracking-wide text-gray-700 text-lg  mb-1">
                                                                Nom du médicament
                                                            </label>
                                                            <div ref={inputRefs.current[index].medicament} className={` rounded `}>
                                                                <Select
                                                                    value={bloc.medicament}
                                                                    options={hiddenSMedicOptions}
                                                                    styles={customStyles}
                                                                    onChange={(option) => handleInputChange(index, "medicament", option)} />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="w-full mb-5">
                                                        <label class="block  tracking-wide text-gray-700 text-lg  mb-1">Type du médicament</label>
                                                        <input
                                                            type="text"
                                                            className={` outline-none block w-full bg-grey-lighter  border rounded py-3 px-4 mb-3  `}
                                                            placeholder="Type du médicament"
                                                            value={bloc.type}
                                                            onChange={(e) => handleInputChange(index, 'type', e.target.value)}
                                                            ref={inputRefs.current[index].type}
                                                        />
                                                    </div>
                                                    <div className="mb-5 w-full">
                                                        <label class="block  tracking-wide text-gray-700 text-lg  mb-1">Durée</label>
                                                        <input
                                                            type="text"
                                                            value={bloc.duree}
                                                            onChange={(e) => handleInputChange(index, 'duree', e.target.value)}
                                                            ref={inputRefs.current[index].duree}
                                                            className={` outline-none block w-full bg-grey-lighter  border rounded py-3 px-4 mb-3  `}
                                                            placeholder='Durée de consomation du medicament' />
                                                    </div>
                                                    <div className="mb-5 w-full">
                                                        <label class="block  tracking-wide text-gray-700 text-lg  mb-1">Jours</label>
                                                        <input
                                                            type="text"
                                                            value={bloc.jours}
                                                            onChange={(e) => handleInputChange(index, 'jours', e.target.value)}
                                                            ref={inputRefs.current[index].jours}
                                                            className={` outline-none block w-full bg-grey-lighter  border rounded py-3 px-4 mb-3  `}
                                                            placeholder='Jour de consomation du medicament' />
                                                    </div>

                                                    <div className="mb-5">
                                                        <label class="block  tracking-wide text-gray-700 text-lg  mb-1">Quantite</label>
                                                        <input
                                                            type="text"
                                                            value={bloc.Quantite}
                                                            onChange={(e) => handleInputChange(index, 'Quantite', e.target.value)}
                                                            ref={inputRefs.current[index].Quantite}
                                                            className={` outline-none block w-full bg-grey-lighter  border rounded py-3 px-4 mb-3  `}

                                                            placeholder='Jour de consomation du medicament' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <div className="w-full flex justify-end">
                                    <button type="button" className="bg-blue-500 hover-bg-blue-700 text-white  py-2 px-4 rounded" onClick={addBloc}>
                                        Ajouter médicament
                                    </button>
                                </div>




                                <div className="w-full">
                                    <div className="h-max w-full">
                                        <div className="px-3 mb-5 w-full">
                                            <div class={`-mx-3 md:flex mb-6`}>
                                                <div class="md:w-1/2 px-2">
                                                    <label class="block  tracking-wide text-gray-700 text-lg  mb-1">
                                                        Petit chirurgie
                                                    </label>
                                                    <div ref={elemetRefPetit} className={` rounded  ${selectedOption3.value == '' ? animationClassPetit : 'focus-border-2 focus-border-blue-700'}`}>
                                                        <Select
                                                            value={selectedOption3}
                                                            options={hiddenPetitOptions}
                                                            styles={customStyles}
                                                            onChange={SelectPetit}
                                                        />
                                                    </div>
                                                </div>

                                                <div class="md:w-1/2 px-2">
                                                    <label class="block  tracking-wide text-gray-700 text-lg  mb-1">
                                                        Référé
                                                    </label>
                                                    <div ref={elemetRefRéféré} className={` rounded  ${selectedOption4.value == '' ? animationClassRéféré : 'focus-border-2 focus-border-blue-700'}`}>
                                                        <Select
                                                            value={selectedOption4}
                                                            options={hiddenRéféréOptions}
                                                            styles={customStyles}
                                                            onChange={SelectRéféré}

                                                        />

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {selectedOption4.value == 'Oui' &&
                                    <div class="w-full">
                                        <div class="w-full mt-3 px-3">
                                            <label
                                                class="block text-gray-700  tracking-wide  text-lg  mb-1"
                                                for="grid-last-name"
                                            >
                                                Moyen de référence
                                            </label>
                                            <input
                                                ref={elemetRefMoyen}
                                                class={` block w-full outline-none  border  rounded py-3 px-4 ${animationClassMoyen}`}
                                                id="grid-last-name"
                                                type="text"
                                                onInput={(e) => SetMoyen(e.target.value)}
                                                placeholder=" Moyen de référence"
                                            />
                                        </div>
                                        <div class="w-full mt-5 px-3">
                                            <div class={`w-full`}>
                                                <label
                                                    class="block  tracking-wide text-gray-700    text-lg  mb-1"
                                                    for="grid-last-name"
                                                >
                                                    Motif
                                                </label>
                                                <textarea
                                                    ref={elemetRefMotif}
                                                    value={Motif}
                                                    onInput={(e) => SetMotif(e.target.value)}
                                                    className={`appearance-none resize-none outline-none  h-[10em] block w-full bg-grey-lighter border rounded p-2 ${Motif.trim() === "" ? animationClassMotif : "focus:border-2 focus:border-blue-700"
                                                        }`}
                                                    id="grid-last-name"
                                                    rows={1}
                                                    placeholder="Motif.."
                                                ></textarea>
                                            </div>
                                        </div>
                                    </div>}

                                <div className="w-full mt-5">
                                    <div className="h-max w-full">
                                        <div className="px-3 mb-5 w-full">
                                            <div class={`-mx-3 md:flex mb-6`}>
                                                <div className="w-full">
                                                    <label class="block  tracking-wide text-gray-700 text-lg  mb-1">Type de payement</label>
                                                    <input
                                                        ref={elemetRefTypesPayemnt}
                                                        value={TypesPayemnt}
                                                        onInput={(e) => SetTypesPayemnt(e.target.value)}
                                                        className={`appearance-none resize-none outline-none   block w-full bg-grey-lighter border rounded py-3 px-4 ${TypesPayemnt.trim() === "" ? animationClassTypesPayemnt : "focus:border-2 focus:border-blue-700"
                                                            }`} placeholder="Type de payement" />
                                                </div>
                                                <div className="w-full md:ml-4">
                                                    <label class="block  tracking-wide text-gray-700 text-lg  mb-1">Montant payé</label>
                                                    <input
                                                        ref={elemetmontant}
                                                        value={montant}
                                                        onInput={(e) => Setmontant(e.target.value)}
                                                        className={`appearance-none resize-none outline-none   block w-full bg-grey-lighter border rounded py-3 px-4 ${montant.trim() === "" ? animationClassmontant : "focus:border-2 focus:border-blue-700"
                                                            }`} placeholder="Montant payé" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className=" flex justify-end  md:w-[40em] w-[95%]">
                                <button type="submit" className="bg-green-500 hover-bg-green-700 text-white  py-2 px-4 rounded mt-4">
                                    Soumettre
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <FootterCopy />
        </div>

    );
};

export default DestrubuerTraitementInfirmnie;






