import React from 'react'
import SildeEnseign from '../SildeEnseign/SildeEnseign'
import CoursEns from './CoursEns'
function CoursEnsComponent() {
    return (
        <div className='overflow-hidden pt-10 bg-white flex'>
            <SildeEnseign />
            <CoursEns />
        </div>
    )
}

export default CoursEnsComponent
