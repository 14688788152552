import React from 'react'

function DepenseArgentDetail() {
  return (
    <div>
      DepenseArgent
    </div>
  )
}

export default DepenseArgentDetail
