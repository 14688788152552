import React from 'react'
import SlideParentNavBars from '../SlideParentNavBars/SlideParentNavBars'
import NoteAllElev from './NoteAllElev'
function NoteAllElevComponent({ getSizeMobille, getMenuMobille }) {
    return (
        <div class="overflow-hidden flex justify-between">
            <SlideParentNavBars getMenuMobille={getMenuMobille} />
            <NoteAllElev getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default NoteAllElevComponent
