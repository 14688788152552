/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import FootterCopy from "../../FootterCopy/FootterCopy";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FadeLoader } from 'react-spinners'
import { API } from "../../../Api/API";

function RapportEncadreursAddRapport({ getSizeMobille }) {
    const [mobile11, SetMobile11] = useState(window.innerWidth < 501)
    const history = useNavigate()
    useEffect(() => {
        const hundlesSize = () => {
            SetMobile11(window.innerWidth < 501)
        }
        window.addEventListener('resize', hundlesSize)
        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])

    const [mobile, SetMobile] = useState(window.innerWidth <= 640)
    const [eledemmade, GeteleDemmande] = useState([])
    const [boutLoading, setboutLoading] = useState(false)
    const staffId = 21
    useEffect(() => {
        const hundleSize = () => {
            SetMobile(window.innerWidth <= 640)
        }
        window.addEventListener('resize', hundleSize)

        return () => {
            window.removeEventListener('resize', hundleSize)
        }
    }, [])

    const [blocs, setBlocs] = useState([
        { Plage_heure: '', date: '', description: '' }
    ]);
    const addBloc = () => {
        setBlocs([...blocs, { Plage_heure: '', date: '', description: '' }]);
    };
    const removeBloc = (index) => {
        const newBlocs = [...blocs];
        newBlocs.splice(index, 1);
        setBlocs(newBlocs);
    };
    const inputRefs = useRef([]);
    if (inputRefs.current.length !== blocs.length) {
        inputRefs.current = Array(blocs.length).fill({}).map((_, index) => ({
            Plage_heure: React.createRef(null),
            date: React.createRef(null),
            description: React.createRef(null),
        }));
    }

    const handleInputChange = (index, field, value) => {
        const newBlocs = [...blocs];
        newBlocs[index][field] = value;
        setBlocs(newBlocs);
        if (value && inputRefs.current[index][field].current) {
            inputRefs.current[index][field].current.classList.remove('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500');
        }
    };





    const validateBloc = (bloc, index) => {
        const requiredFields = ['Plage_heure', 'date', 'description'];

        //Validation pour les champs vide
        for (const field of requiredFields) {
            if (!bloc[field]) {
                toast.warning(<div>Le <strong>{field == 'Plage_heure' ? 'plage d\'heure' : field == 'date' ? 'date' : field == 'description' ? 'déscription' : null}</strong> est obligatoire à la rapport {index + 1}.</div>);
                if (inputRefs.current[index][field].current) {
                    inputRefs.current[index][field].current.classList.add('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500', 'outline-none');
                    setTimeout(() => {
                        inputRefs.current[index][field].current.classList.remove('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500', 'outline-none');
                    }, 3000);
                    inputRefs.current[index][field].current.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    inputRefs.current[index][field].current.focus();
                    return false;
                }
            }
        }

        return true;
    };



    function toRoman(num) {
        const romanNumerals = [
            "I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX", "X",
            "XI", "XII", "XIII", "XIV", "XV", "XVI", "XVII", "XVIII", "XIX", "XX",
            "XXI", "XXII", "XXIII", "XXIV", "XXV", "XXVI", "XXVII", "XXVIII", "XXIX", "XXX"
        ];
        return romanNumerals[num - 1] || num.toString();
    }


    const Datas = blocs.map((bloc) => ({
        time: bloc.Plage_heure,
        date: bloc.date,
        description: bloc.description
    }))


    const handleSubmit = (e) => {
        e.preventDefault();
        for (let index = 0; index < blocs.length; index++) {
            if (!validateBloc(blocs[index], index)) {
                return false;
            }
        }


        API.post(`/HI/addRapportaugarde/${staffId}`, Datas)
            .then(response => {
                // Si la requête est réussie, afficher un toast de succès
                toast.success('Données envoyées avec succès');
                history("/Rapport")
            })
            .catch(error => {
                if (error.response) {
                    // S'il y a une réponse du serveur, gérer les erreurs
                    const errorData = error.response.data;
                    console.log(errorData)
                    if (Array.isArray(errorData)) {
                        // Afficher chaque erreur dans un toast
                        toast.error(errorData[0]);
                        return false;
                    } else {
                        toast.error(errorData || 'Erreur serveur');
                        return false;
                    }
                } else {
                    toast.error('Erreur serveur');
                }
            });
    };



    return (

        <div className={`bg-white  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
            <Link to='/Rapport' className="ml-2 text-[18px] text-blue-500 hover:text-blue-800 underline">Retour</Link>
            <div className="sm:p-4 p-2 min-h-screen">
                <div class="my-5">
                    <div class="w-full flex flex-row items-center justify-between">
                        <p class="text-navy-700   ">
                            <div
                                class="text-2xl font-bold  "
                            >
                                Enregistrement des rapports
                            </div>
                        </p>

                    </div>
                </div>

                <div className="w-full my-4 grid grid-cols-1">
                    <div className="w-full my-4 grid grid-cols-1">
                        <form onSubmit={handleSubmit} className="flex flex-col items-center">
                            <div className="rounded-lg p-2 border border-blue-500 md:w-[40em] w-[95%] sm:p-4">
                                {blocs.map((bloc, index) => (
                                    <div key={index} className="justify_center rounded-xl w-full  p-1 sm:p-2 mb-10  border-[1px] flex flex-col">
                                        <div className="w-full">
                                            <div className="h-max w-full">
                                                <div className="px-3  w-full">
                                                    <div className="w-full flex items_center justify-between">
                                                        <label className="block  mt-5 font-serif text-gray-700 first_letter:uppercase tracking_wide text-grey-darker text-lg  mb-1">
                                                            {toRoman(index + 1)}. Rapport
                                                        </label>
                                                        {index > 0 && (
                                                            <button
                                                                montant="button"
                                                                className="text-red-500 px-1 max-sm:text-xs py-2"
                                                                onClick={() => removeBloc(index)}
                                                            >
                                                                Supprimer
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mb-2">
                                            <div className="rounded-xl p-2 w-full">
                                                <div className="flex flex-col">
                                                    <div className="rounded-lg w-full">
                                                        <div class="w-full mb-5">
                                                            <label
                                                                class="block  tracking-wide text-grey-darker text-lg  mb-2"
                                                                for="grid-Title"
                                                            >
                                                                Plage d'heure
                                                            </label>
                                                            <input
                                                                ref={inputRefs.current[index].Plage_heure}
                                                                class="appearance-none block w-full bg-grey-lighter text-grey-darker border border-red rounded py-3 px-4 mb-3"
                                                                value={bloc.Plage_heure}
                                                                placeholder='09h00 à 13h15'
                                                                onChange={(e) => handleInputChange(index, "Plage_heure", e.target.value)}

                                                            />
                                                        </div>
                                                    </div>



                                                    <div className="rounded-lg w-full">
                                                        <div class="w-full mb-5">
                                                            <label
                                                                class="block  tracking-wide text-grey-darker text-lg  mb-2"
                                                                for="grid-Title"
                                                            >
                                                                Date
                                                            </label>
                                                            <input
                                                                type="date"
                                                                ref={inputRefs.current[index].date}
                                                                class="appearance-none block w-full bg-grey-lighter text-grey-darker border border-red rounded py-3 px-4 mb-3"
                                                                value={bloc.date}
                                                                onChange={(e) => handleInputChange(index, "date", e.target.value)}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="rounded-lg w-full">
                                                        <div class="w-full mb-5">
                                                            <label
                                                                class="block  tracking-wide text-grey-darker text-lg  mb-2"
                                                                for="grid-Title"
                                                            >
                                                                Description
                                                            </label>
                                                            <textarea
                                                                ref={inputRefs.current[index].description}
                                                                class="appearance-none block h-[10em] resize-none w-full bg-grey-lighter text-grey-darker border border-red rounded py-3 px-4 mb-3"
                                                                value={bloc.description}
                                                                onChange={(e) => handleInputChange(index, "description", e.target.value)}
                                                                placeholder="description..."
                                                            >

                                                            </textarea>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <div className="w-full flex justify-end">
                                    <button montant="button" className="bg-blue-500 hover-bg-blue-700 text-white py-1 px-2  max-sm:text-xs sm:py-2 sm:px-4 rounded" onClick={addBloc}>
                                        Ajouter Rapport
                                    </button>
                                </div>
                            </div>

                            <div className=" flex justify-end  md:w-[40em] w-[95%]">

                                {boutLoading ? (
                                    <>
                                        <label disabled className="cursor-no-drop w-max relative  mt-3 flex justify-center  items-center   bg-green-950    p-2 rounded  text-gray-400">
                                            <input type="submit" id="send" value='Soumettre' className='pointer-events-none' />
                                            <i class="bi bi-send ml-2  pointer-events-none "></i>
                                            <div disabled className='absolute pointer-events-none  bg-transparent pt-4  pl-4  w-full h-full flex justify-center items-center z-50'>
                                                <FadeLoader
                                                    color="rgb(255, 255, 255)"
                                                    height={10}
                                                    margin={-9}
                                                    radius={100}
                                                    speedMultiplier={1}
                                                    width={1}
                                                />
                                            </div>
                                        </label>
                                    </>
                                ) : (<>
                                    <label for="send" className=" mt-3 cursor-pointer w-max  flex justify-end  bg-green-600   p-2 rounded  text-white">
                                        <input type="submit" id="send" value='Soumettre' className='cursor-pointer'></input>
                                        <i class="bi bi-send ml-2 "></i>
                                    </label>
                                </>)}

                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <FootterCopy />
        </div>

    );
}


export default RapportEncadreursAddRapport;










