import React, { useState } from "react";
import DocClasseurComponent from "../../component/Secretariat/Classeurs/DocClasseurComponent";
function DocClasseurPage({ getMenuMobille, getSizeMobille }) {
  return (
    <>
      <DocClasseurComponent getSizeMobille={getSizeMobille} getMenuMobille={getMenuMobille} />
    </>
  );
}

export default DocClasseurPage;
