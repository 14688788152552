import React, { useEffect, useRef, useState } from "react";
import FootterCopy from "../../FootterCopy/FootterCopy";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Webcam from 'react-webcam';
import { API } from "../../../Api/API";
import { decryptData } from "../../../encryptionModule";
function AddFauteConsultSuiveurEncadrement({ getSizeMobille }) {
    const { id, classId, schoolyearId } = useParams()
    const history = useNavigate()
    const [mobile1, Setmonile1] = useState(window.innerWidth < 349)
    const [mobile11, SetMobile11] = useState(window.innerWidth < 501)

    useEffect(() => {
        const hundlesSize = () => {
            Setmonile1(window.innerWidth < 349)
            SetMobile11(window.innerWidth < 501)
        }

        window.addEventListener('resize', hundlesSize)

        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])

    const webcamRef = useRef(null);




    const [points_retrait, Setpoints_retrait] = useState('')
    const [animationClasspoints_retrait, setAnimationClasspoints_retrait] = useState('');
    const elemetRefpoints_retrait = useRef(null)


    const [faute_comise, Setfaute_comise] = useState('')
    const [animationClassfaute_comise, setAnimationClassfaute_comise] = useState('');
    const elemetReffaute_comise = useRef(null)

    const [SignaturePhotos, SetSignaturePhotos] = useState(null)
    const [animationClassSignaturePhotos, setanimationClassSignaturePhotos] = useState('');
    const elemetRefSignaturePhotos = useRef(null)

    const [cameraActive, setCameraActive] = useState(false);

    const capture = () => {
        const imageSrc = webcamRef.current.getScreenshot();
        SetSignaturePhotos(imageSrc);
        setCameraActive(false);
    };

    const OpenCamera = () => {
        SetSignaturePhotos(null);
        setCameraActive(true);
    };

    const FermeCamera = () => {
        SetSignaturePhotos(null);
        setCameraActive(false);
    }
    // Convertit une URI de données en fichier
    const dataURLtoFile = (dataurl, filename) => {
        const arr = dataurl.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    };
    const staffId = 2
    const studentId = decryptData(id)
    const handleSubmit = (e) => {
        e.preventDefault()
        const pointsRetrait = parseInt(points_retrait, 10);

        if (isNaN(pointsRetrait) || pointsRetrait < 3 || pointsRetrait > 60 || pointsRetrait % 3 !== 0) {
            toast.warning('Le points retrait  doit être un nombre entier entre 3 et 60, divisible par 3', {
                autoClose: 2000
            });
            setAnimationClasspoints_retrait('animate__animated animate__shakeX border-2 border-red-500 ');
            setTimeout(() => {
                setAnimationClasspoints_retrait(' ');
            }, 3000);
            elemetRefpoints_retrait.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            elemetRefpoints_retrait.current.focus();
            return false;
        }
        else if (faute_comise == '') {
            toast.warning("La faute_comise est oblogatoire", {
                autoClose: 2000
            });
            setAnimationClassfaute_comise('animate__animated animate__shakeX  border-2 border-red-500')
            setTimeout(() => {
                setAnimationClassfaute_comise(' ')
            }, 3000)
            elemetReffaute_comise.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            elemetReffaute_comise.current.focus()
            return false
        } else if (SignaturePhotos == null) {
            toast.warning('Photos qui symbolise le signature est obligatoire', {
                autoClose: 2000
            });
            setanimationClassSignaturePhotos('animate__animated animate__shakeX border-2 border-red-500')
            setTimeout(() => {
                setanimationClassSignaturePhotos(' ')
            }, 3000)
            elemetRefSignaturePhotos.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }

        const formdata = new FormData()
        formdata.append('faute', faute_comise)
        formdata.append('signature', dataURLtoFile(SignaturePhotos, 'photo1.png'));
        formdata.append('point', points_retrait)
        formdata.append('staffId', staffId)
        formdata.append('studentId', studentId)
        API.post('/student/insertionSuiveur', formdata)
            .then(response => {
                // Afficher un message de succès
                toast.success('Données insérées avec succès');
                history(`/Suiveur/consult/Detail/${id}/${schoolyearId}/${classId}`)
            })
            .catch(error => {
                // Si une erreur se produit
                console.error('Erreur lors de la requête:', error);
                // Afficher les erreurs retournées par le backend avec Toastify
                if (error.response) {
                    // Si le backend a renvoyé une réponse avec des erreurs
                    const errors = error.response.data.errors;
                    errors.forEach(errorMessage => {
                        toast.error(errorMessage);
                    });
                } else {
                    // Si une autre erreur s'est produite (ex: erreur de réseau)
                    toast.error('Une erreur s\'est produite lors de la requête.');
                }
            });
    }



    return (

        <div className={`bg-white  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
            <Link to={`/Suiveur/consult/Detail/${id}/${schoolyearId}/${classId}`}
                className="ml-2 text-[18px] text-blue-500 hover:text-blue-800 underline">Retour</Link>
            <div className="sm:p-4 p-2 min-h-screen">
                <div class="my-5">
                    <div class="w-full flex flex-row items-center justify-between">
                        <p class="text-navy-700 shrink  ">
                            <p
                                class="hover:text-navy-700  font-bold  "

                            >
                                Ajouter une faute
                            </p>
                        </p>

                    </div>
                </div>
                <div class="w-full my-4 grid grid-cols-1 ">
                    <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">
                        <form onSubmit={handleSubmit}>
                            <div class="bg-white  sm:px-8 p-2 sm:pt-6 sm:pb-8 mb-4 flex flex-col my-2">

                                <div class="px-3 w-full mb-6 md:mb-0">
                                    <label
                                        class="block  tracking-wide text-grey-darker font-bold mb-2"
                                        for="grid-Title"
                                    >
                                        Point  à retrait
                                    </label>
                                    <input
                                        class={` outline-none block w-full bg-grey-lighter  border rounded py-2 px-2 sm:py-3 sm:px-4 mb-3 ${animationClasspoints_retrait} focus:border-2 focus:border-blue-700 `}
                                        id="grid-Title"
                                        type="text"
                                        placeholder="Point  à retrait "
                                        ref={elemetRefpoints_retrait}
                                        onInput={(e) => Setpoints_retrait(e.target.value)}
                                    />
                                </div>
                                <div class="w-full px-3">
                                    <label
                                        class="block  tracking-wide text-grey-darker font-bold mb-2"

                                    >
                                        Faute comise
                                    </label>
                                    <textarea
                                        class={` ${animationClassfaute_comise} outline-none resize-none min-h-[30vh] block w-full bg-grey-lighter   rounded py-2 px-2 sm:py-3 sm:px-4 mb-3 border-2 focus:border-blue-700 `}
                                        ref={elemetReffaute_comise}
                                        placeholder="Faute comise"
                                        onInput={(e) => Setfaute_comise(e.target.value)}
                                    ></textarea>
                                </div>
                                <div class="md:w-1/2 px-3 relative">
                                    <label
                                        ref={elemetRefSignaturePhotos}
                                        onClick={OpenCamera}
                                        class={` cursor-pointer bg-white ${animationClassSignaturePhotos} text-gray-500 outline-none block w-full    rounded py-2 px-2 sm:py-3 sm:px-4 mb-3 border `}
                                    >
                                        Photos de signature
                                    </label>
                                </div>

                                {cameraActive &&
                                    <div className="w-full h-full fixed top-0 left-0 bg-[#000000fa] z-[200]">
                                        <div onClick={FermeCamera} className="bg-red-200 rounded-full cursor-pointer absolute p-2 right-2 top-2 ">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi h-6 w-6 text-red-600 bi-x-octagon-fill" viewBox="0 0 16 16">
                                                <path d="M11.46.146A.5.5 0 0 0 11.107 0H4.893a.5.5 0 0 0-.353.146L.146 4.54A.5.5 0 0 0 0 4.893v6.214a.5.5 0 0 0 .146.353l4.394 4.394a.5.5 0 0 0 .353.146h6.214a.5.5 0 0 0 .353-.146l4.394-4.394a.5.5 0 0 0 .146-.353V4.893a.5.5 0 0 0-.146-.353zm-6.106 4.5L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708" />
                                            </svg>
                                        </div>
                                        <div className="w-full h-full flex justify-center items-center">
                                            <Webcam
                                                audio={false}
                                                ref={webcamRef}
                                                width='60%'
                                                screenshotFormat="image/png"
                                            />
                                            <button onClick={capture} className="absolute bottom-2 font-bold text-green-700 bg-green-300 px-3 py-2 rounded" >Prendre une photo</button>

                                        </div>
                                    </div>

                                }



                                {SignaturePhotos !== null &&
                                    <div className="w-full h-max flex justify-center  mb-4">
                                        <div className="sm:w-[50%] w-full rounded-2xl border overflow-hidden">
                                            <img src={SignaturePhotos} className="w-full h-full object-contain" />
                                        </div>
                                    </div>}
                                <div class="mb-4 flex items-center justify-end">
                                    <div class="flex-shrink-0">
                                        <button type="submit" class="mt-4 sm:mt-0 inline-flex items-start justify-start px-3 sm:px-6 py-3 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg">
                                            <p class="font-medium leading-none text-white">
                                                Ajouter
                                            </p>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <FootterCopy />
        </div>

    );
}

export default AddFauteConsultSuiveurEncadrement;



