/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import Swal from "sweetalert";
import FootterCopy from "../../FootterCopy/FootterCopy";
import { Link } from "react-router-dom";
import { API } from "../../../Api/API";
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { toast } from "react-toastify";
function RetournMaterielEncadrementPerso({ getSizeMobille }) {
    const [mobile1, Setmonile1] = useState(window.innerWidth < 873)
    const [mobile11, SetMobile11] = useState(window.innerWidth < 501)

    const [materileEmprunt, setmaterileEmprunt] = useState([])
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [totalElements, setTotalElements] = useState(0)
    const [rechercher, setRechercher] = useState(false)
    const [isrechercher, setisRechercher] = useState(0)
    function handleSearch() {
        setRechercher(true)
        setisRechercher(isrechercher + 1)
    }
    useEffect(() => {
        fetchStaff(currentPage, pageSize, searchTerm, rechercher);
    }, [currentPage, pageSize, isrechercher]);

    const fetchStaff = async (page, size, searchTerm, rechercher) => {
        try {
            let url = `/materielinternant/EmpruntMaterielPerso?page=${page}&pageSize=${size}`;
            if (rechercher) {
                url = `/materielinternant/EmpruntMaterielPerso/search?page=${page}&pageSize=${size}&searchTerm=${searchTerm}`;
            }
            const response = await API.get(url);
            setmaterileEmprunt(response.data.emprunts);
            setTotalPages(response.data.totalPages);
            setTotalElements(response.data.totalItems);
        } catch (error) {
            console.error('Erreur lors de la récupération des materiels :', error.message);
        }
    };


    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePageSizeChange = (size) => {
        setPageSize(size);
        setCurrentPage(1);
    };



    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const formatDate = date => {
        return format(new Date(date), "'Le 'd, MMMM yyyy", { locale: fr });
    };

    const getRange = () => {
        const from = (currentPage - 1) * pageSize + 1;
        const to = Math.min(currentPage * pageSize, totalElements);
        return `${from} à ${to} materiels sur ${totalElements}`;
    };
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        if (searchTerm.toString().trim().length === 1) {
            setRechercher(false);
            setisRechercher(isrechercher + 1);
        }
    };
    useEffect(() => {
        const hundlesSize = () => {
            Setmonile1(window.innerWidth < 873)
            SetMobile11(window.innerWidth < 501)
        }

        window.addEventListener('resize', hundlesSize)

        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])

    const [indexModifier, setIndexModifier] = useState(null)
    const [modifier, Getmodifier] = useState(false)
    const Getmodifiers = (value, index) => {
        setIndexModifier(index);
        Getmodifier(value)
    };
    const [empruntId, setempruntId] = useState(0)


    const [inputValue, setInputValue] = useState('');
    const [sortOrder, setSortOrder] = useState('asc');
    const [sortedColumn, setSortedColumn] = useState(null);

    const ValuesInput = (e) => {
        setInputValue(e.target.value);
    };


    const fildata = (data, inputValue) => {
        if (!inputValue.trim()) {
            return data;
        }
        return data.filter((item) => {
            if (
                item.responsable.toLowerCase().includes(inputValue.toLowerCase()) ||
                item.nom_materiel.toLowerCase().includes(inputValue.toLowerCase()) ||
                item.Matricule.toLowerCase().includes(inputValue.toLowerCase()) ||
                item.quantite_Emprunt.toString().toLowerCase().includes(inputValue.toLowerCase()) ||
                item.date.toString().toLowerCase().includes(inputValue.toLowerCase())
            ) {
                return true;
            }
            return false;
        });
    };



    const handleSort = (column) => {
        if (column === sortedColumn) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortOrder('asc');
            setSortedColumn(column);
        }
    };

    const sortData = (data, column, sortOrder) => {
        return data.slice().sort((a, b) => {
            const aValue = a[column];
            const bValue = b[column];

            if (aValue < bValue) return sortOrder === 'asc' ? -1 : 1;
            if (aValue > bValue) return sortOrder === 'asc' ? 1 : -1;
            return 0;
        });
    };

    const filteredData = fildata(materileEmprunt, inputValue);

    const sortedData = sortData(filteredData, sortedColumn, sortOrder);


    function highlightText(text, inputValue) {
        if (!inputValue) return text;
        const highlightedText = [];
        let currentIndex = 0;
        while (currentIndex < text.length) {
            const inputChar = inputValue.toLowerCase();
            const matchIndex = text.substr(currentIndex).toLowerCase().indexOf(inputChar);

            if (matchIndex !== -1) {

                highlightedText.push(text.substring(currentIndex, currentIndex + matchIndex));


                highlightedText.push(
                    <span className="text-orange-500" key={currentIndex + matchIndex}>
                        {text.substring(currentIndex + matchIndex, currentIndex + matchIndex + inputValue.length)}
                    </span>
                );

                currentIndex = currentIndex + matchIndex + inputValue.length;
            } else {
                highlightedText.push(text.substring(currentIndex));
                break;
            }
        }

        return highlightedText;
    }

    const [values, GetValues] = useState('')
    const [classeValidation, SetClasseValidation] = useState(false)

    const hundleSubmit = (id) => {
        Getmodifier(false)
        Swal({
            title: "Remise du Materiel",
            text: "Avez-vous fait la Remise du Materiel ?? ",
            icon: "info",
            buttons: {
                confirm: "Oui",
                cancel: "Non",
            },
            customClass: {
                icon: "my-swal-icon",
            },
        }).then((value) => {
            API.put(`/materielinternant/remisPerso/${id}/${values}`)
                .then((res) => {
                    setisRechercher(isrechercher + 1);
                    toast.success("La remise du matériel a été enregistrée avec succès.");
                })
                .catch((err) => {
                    if (err.response && err.response.data && err.response.data.error) {
                        toast.error(`Erreur : ${err.response.data.error}`);
                    } else {
                        toast.error("Une erreur s'est produite lors de l'enregistrement de la remise du matériel.");
                    }
                    console.log("err", err.message);
                });

        });
    };


    return (
        <div className={`bg-white  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
            <div className="sm:p-4 p-2 min-h-screen">
                <div class="sm:flex items-center pr-4  h-max  justify-end">
                    <div class="mx-2">
                        <div class="relative lg:w-64">
                            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <svg
                                    class="w-5 h-5 text-gray-500"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                        clip-rule="evenodd"
                                    ></path>
                                </svg>
                            </div>
                            <input
                                type="text"
                                name="email"
                                id="topbar-search"
                                class="bg-white border border-gray-300 h-10 text-gray-900 sm:text-lg first-letter:uppercase rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full pl-10 .5"
                                placeholder="Rechercher..."
                                onInput={ValuesInput}
                            />
                        </div>
                    </div>
                    <button class="mt-4 sm:mt-0 inline-flex items-start justify-start px-1 py-3 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg">
                        <p class="text-lg first-letter:uppercase font-semibold leading-none text-white">
                            Ok
                        </p>
                    </button>
                </div>
                <div class="w-full my-4 grid grid-cols-1 ">
                    <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">
                        <div class="mb-4 flex items-center justify-between">
                            <div>
                                <h3 class="sm:text-xl font-bold text-gray-900 mb-2">
                                    Personnel |  Listes des matériels à retournez
                                </h3>
                            </div>

                        </div>

                        <div class="flex flex-col mt-8">
                            <div class="overflow-x-auto rounded-lg">
                                <div class={`${mobile1 ? 'w-[55em]' : ' min-w-full '} `}>
                                    <div class="shadow overflow-hidden sm:rounded-lg">
                                        <table class="min-w-full divide-y divide-gray-200">
                                            <thead class="bg-gray-50">
                                                <tr>
                                                    <th onClick={() => handleSort('responsable')}
                                                        scope="col"
                                                        class=" py-4 px-2 font-semibold relative border-l text-left cursor-pointer text-lg first-letter:uppercase  text-gray-500  tracking-wider"
                                                    >
                                                        Responsable  {sortedColumn === 'responsable' && <span className="pr-0 absolute right-2">{sortOrder === 'asc' ? '▲' : '▼'}</span>}

                                                    </th>
                                                    <th onClick={() => handleSort('Matricule')}
                                                        scope="col"
                                                        class=" py-4 px-2 font-semibold relative border-l text-left cursor-pointer text-lg first-letter:uppercase  text-gray-500  tracking-wider"
                                                    >
                                                        Matricule  {sortedColumn === 'Matricule' && <span className="pr-0 absolute right-2">{sortOrder === 'asc' ? '▲' : '▼'}</span>}

                                                    </th>
                                                    <th onClick={() => handleSort('nom_materiel')}
                                                        scope="col"
                                                        class=" py-4 px-2 font-semibold relative border-l text-left cursor-pointer text-lg first-letter:uppercase  text-gray-500  tracking-wider"
                                                    >
                                                        Nom matéreil  {sortedColumn === 'nom_materiel' && <span className="pr-0 absolute right-2">{sortOrder === 'asc' ? '▲' : '▼'}</span>}

                                                    </th>
                                                    <th onClick={() => handleSort('quantite_Emprunt')}
                                                        scope="col"
                                                        class=" py-4 px-2 font-semibold relative border-l text-left cursor-pointer text-lg first-letter:uppercase  text-gray-500  tracking-wider"
                                                    >
                                                        Quantite  {sortedColumn === 'quantite_Emprunt' && <span className="pr-0 absolute right-2">{sortOrder === 'asc' ? '▲' : '▼'}</span>}

                                                    </th>
                                                    <th onClick={() => handleSort('date')}
                                                        scope="col"
                                                        class=" py-4 px-2 font-semibold relative border-l text-left cursor-pointer text-lg first-letter:uppercase  text-gray-500  tracking-wider"
                                                    >
                                                        Date d'emprunt  {sortedColumn === 'date' && <span className="pr-0 absolute right-2">{sortOrder === 'asc' ? '▲' : '▼'}</span>}

                                                    </th>
                                                    <th class="p-4 text-left text-lg font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        Action
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody class="bg-white">
                                                {sortedData.map((data, index) => {
                                                    return (<tr key={index}>
                                                        <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                            {highlightText(data.responsable, inputValue)}
                                                        </td>
                                                        <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                            {highlightText(data.Matricule, inputValue)}
                                                        </td>
                                                        <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                            {highlightText(data.nom_materiel, inputValue)}
                                                        </td>
                                                        <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase  text-gray-500">
                                                            {highlightText(data.quantite_Emprunt.toString(), inputValue)}
                                                        </td>
                                                        <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase  text-gray-500">

                                                            {data.date && highlightText(formatDate(data.date), inputValue)}
                                                        </td>
                                                        <td class="border-b border-gray-200 overflow-hidden  relative pt-2 px-3  border-l border-r whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                            {modifier && indexModifier === index && (
                                                                <div className="absolute bg-[#0000003f] z-50 top-0 h-full flex justify-center items-center   w-full  right-0">
                                                                    <div className="bg-transparent h-full flex justify-center items-center  w-full ">
                                                                        <div className="w-[95%] bg-white  rounded h-[80%] flex items-center">
                                                                            <div onClick={() => { Getmodifier(false); setIndexModifier(index); }} className="bg-red-500 rounded-full cursor-pointer flex justify-center items-center text-white m-[5px]">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                                                                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                                                                                </svg>
                                                                            </div>
                                                                            <div className={`border ${classeValidation ? 'border-red-600' : 'border-blue-300 '} w-[90%] h-[90%] rounded-md overflow-hidden`}>
                                                                                <input value={values} onInput={(e) => GetValues(e.target.value)} className="outline-none w-full  h-full text-[15px] pl-2" placeholder="Quantite..." />
                                                                            </div>
                                                                            <button onClick={() => hundleSubmit(empruntId)} className="  rounded-full cursor-pointer flex justify-center items-center  m-[5px]">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi text-green-500 bi-send w-5 h-5" viewBox="0 0 16 16">
                                                                                    <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z" />
                                                                                </svg>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            <div onClick={(e) => { Getmodifiers(true, index); e.stopPropagation(); setempruntId(data.id) }}
                                                                class="my-2 cursor-pointer sm:mt-0 inline-flex items-start justify-start px-3 py-2 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg"
                                                            >
                                                                <p class="text-lg first-letter:uppercase font-semibold leading-none text-white">
                                                                    Retourner
                                                                </p>
                                                            </div>
                                                        </td>
                                                    </tr>)
                                                })}

                                                {sortedData.length == 0 &&
                                                    <tr>
                                                        <td colSpan={7} className="h-20 w-full text-center text-2xl text-gray-400"> Aucun élément correspondant à <span className="font-bold">{inputValue}</span> </td>
                                                    </tr>}
                                            </tbody>
                                        </table>
                                        {sortedData.length > 0 && (
                                            <>
                                                {/* Pagination */}
                                                {/* Pagination controls */}
                                                <div className="mt-4 flex items-center justify-center">
                                                    <div className="flex items-center">
                                                        <button
                                                            disabled={currentPage === 1}
                                                            onClick={handlePreviousPage}
                                                            className="mx-1 px-3 py-1 rounded bg-gray-300 text-gray-700"
                                                        >
                                                            Précédent
                                                        </button>
                                                        {Array.from({ length: totalPages }, (_, index) => index + 1).map((pageNumber) => (
                                                            <button
                                                                key={pageNumber}
                                                                onClick={() => handlePageChange(pageNumber)}
                                                                className={`mx-1 px-3 py-1 rounded ${currentPage === pageNumber ? 'bg-gray-700 text-white' : 'bg-gray-300 text-gray-700'}`}
                                                            >
                                                                {pageNumber}
                                                            </button>
                                                        ))}
                                                        <button
                                                            disabled={currentPage === totalPages}
                                                            onClick={handleNextPage}
                                                            className="mx-1 px-3 py-1 rounded bg-gray-300 text-gray-700"
                                                        >
                                                            Suivant
                                                        </button>
                                                    </div>
                                                </div>

                                                <div className="mt-4 flex items-center justify-center">
                                                    <p className="text-gray-600">{getRange()}</p>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FootterCopy />

        </div>

    );
}

export default RetournMaterielEncadrementPerso;
