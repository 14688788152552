import React from 'react'
import PersonnelLivres from './PersonnelLivres'
import SideBarBibliote from '../SideBar/SideBarBibliote'
function PersonnelLivresComponent() {
    return (
        <div className='flex justify-between overflow-hidden bg-white'>
            <SideBarBibliote />
            <PersonnelLivres />
        </div>
    )
}

export default PersonnelLivresComponent
