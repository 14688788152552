import React from 'react'
import AddCongePrefetDisplin from './AddCongePrefetDisplin'
import SideBarsPrefetDisplin from '../SideBarsPrefetDisplin/SideBarsPrefetDisplin'
function AddCongePrefetDisplinComponent({ getSizeMobille, getMenuMobille }) {
    return (
        <div className='flex justify-between overflow-hidden bg-white'>
            <SideBarsPrefetDisplin getMenuMobille={getMenuMobille} />
            <AddCongePrefetDisplin getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default AddCongePrefetDisplinComponent
