import React from "react";
import RapportInfirmier from "./RapportInfirmier";

import SlideNavInfermie from "../SlideNavInfermie/SlideNavInfermie";
function RapportInfirmierComponent({ getSizeMobille, getMenuMobille }) {
  return (
    <>
      <div class="overflow-hidden flex justify-between">
        <SlideNavInfermie getMenuMobille={getMenuMobille} />
        <RapportInfirmier getSizeMobille={getSizeMobille} />
      </div>
    </>
  );
}

export default RapportInfirmierComponent;
