import React, { useEffect, useRef, useState } from "react";
import FootterCopy from "../../FootterCopy/FootterCopy";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Select from "react-select";
import { FadeLoader } from 'react-spinners';
import { API } from "../../../Api/API";

function ModifieLatrinePreftDisplin({ getSizeMobille }) {
    const { nombre, qui } = useParams();
    const [mobile11, SetMobile11] = useState(window.innerWidth < 501);
    const navigate = useNavigate()
    useEffect(() => {
        const hundlesSize = () => {
            SetMobile11(window.innerWidth < 501);
        };
        window.addEventListener('resize', hundlesSize);
        return () => {
            window.removeEventListener('resize', hundlesSize);
        };
    }, []);

    const [mobile, SetMobile] = useState(window.innerWidth <= 640);
    const [numberOfLatrines, setNumberOfLatrines] = useState(nombre);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const hundleSize = () => {
            SetMobile(window.innerWidth <= 640);
        };
        window.addEventListener('resize', hundleSize);
        return () => {
            window.removeEventListener('resize', hundleSize);
        };
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            // Préparer les données à envoyer
            const dataToSend = {
                numberOfLatrines: numberOfLatrines,
                fieldToUpdate: getFieldToUpdate(qui)
            };
            const response = await API.post('/LT/modifierLatrine', dataToSend);
            toast.success(response.data);
            navigate('/Latrines')
        } catch (error) {

            toast.error("Une erreur s'est produite lors de la modification des données");
        } finally {
            setIsLoading(false);
        }
    };

    const handleNumberOfLatrinesChange = (e) => {
        setNumberOfLatrines(e.target.value);
    };

    const getFieldToUpdate = (qui) => {
        switch (qui) {
            case 'garçons':
                return 'garconstudent';
            case 'filles':
                return 'fillestudent';
            case 'autorites':
                return 'personnelle';
            default:
                return '';
        }
    };

    return (
        <div className={`bg-white  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
            <Link to='/Latrines' className="ml-2 text-[18px] text-blue-500 hover:text-blue-800 underline">Retour</Link>
            <div className="sm:p-4 p-2 min-h-screen">
                <div class="my-5">
                    <div class="w-full flex flex-row items-center justify-between">
                        <p class="text-navy-700   ">
                            <a
                                class="hover:text-navy-700 text-2xl font-bold  "
                            >
                                Modifier la latrine
                            </a>
                        </p>

                    </div>
                </div>
                <div className="w-full my-4 grid grid-cols-1">
                    <div className="w-full my-4 grid grid-cols-1">
                        <form onSubmit={handleSubmit} className="flex flex-col items-center">
                            <div className="rounded-lg p-2 border border-blue-500 md:w-[40em] w-[95%] sm:p-4">
                                <div className="justify_center rounded-xl w-full  p-1 sm:p-2 mb-10  border-[1px] flex flex-col">
                                    <div className="w-full">
                                        <div className="h-max w-full">
                                            <div className="px-3  w-full">
                                                <div className="w-full flex items_center justify-between">
                                                    <label className="block  text-xs mt-5 font-serif text-gray-700 first_letter:uppercase tracking_wide text-grey-darker sm:text-lg  mb-1">
                                                        Latrine pour les {qui}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-2">
                                        <div className="rounded-xl p-2 w-full">
                                            <div className="flex flex-col">
                                                <div className="rounded-lg w-full">
                                                    <div className="w-full mb-5">
                                                        <label className="block tracking-wide text-grey-darker text-lg mb-2">
                                                            Nombres des latrines
                                                        </label>
                                                        <input
                                                            className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-red rounded py-3 px-4 mb-3"
                                                            value={numberOfLatrines}
                                                            onChange={handleNumberOfLatrinesChange}
                                                            placeholder="Nombres des latrines..."
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className=" flex justify-end  md:w-[40em] w-[95%]">
                                {isLoading ? (
                                    <>
                                        <label disabled className="cursor-no-drop w-max relative  mt-3 flex justify-center  items-center   bg-green-950    p-2 rounded  text-gray-400">
                                            <input type="submit" id="send" value='Modifier' className='pointer-events-none' />
                                            <i class="bi bi-send ml-2  pointer-events-none "></i>
                                            <div disabled className='absolute pointer-events-none  bg-transparent pt-4  pl-4  w-full h-full flex justify-center items-center z-50'>
                                                <FadeLoader
                                                    color="rgb(255, 255, 255)"
                                                    height={10}
                                                    margin={-9}
                                                    radius={100}
                                                    speedMultiplier={1}
                                                    width={1}
                                                />
                                            </div>
                                        </label>
                                    </>
                                ) : (
                                    <>
                                        <label for="send" className=" mt-3 cursor-pointer w-max  flex justify-end  bg-green-600   p-2 rounded  text-white">
                                            <input type="submit" id="send" value='Modifier' className='cursor-pointer'></input>
                                            <i class="bi bi-send ml-2 "></i>
                                        </label>
                                    </>
                                )}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <FootterCopy />
        </div>
    );
}

export default ModifieLatrinePreftDisplin;
