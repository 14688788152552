import React, { useState } from "react";
import SideBarSecretaire from "../../SideBarSecretaire/SideBarSecretaire";
import AjouterExpedier from "./AjouterExpedier";
function AjouterExpedierComponent() {
  return (
    <>

      <div class="flex overflow-hidden bg-white justify-between">
        <SideBarSecretaire />
        <AjouterExpedier />
      </div>
    </>
  );
}

export default AjouterExpedierComponent;
