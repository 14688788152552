import React from "react";
import SlideNavInfermie from "../SlideNavInfermie/SlideNavInfermie";
import DepensesInfirmeier from "./DepensesInfirmeier";
function DepensesInfirmeierComponent({ getSizeMobille, getMenuMobille }) {
  return (
    <>
      <div class="overflow-hidden flex justify-between">
        <SlideNavInfermie getMenuMobille={getMenuMobille} />
        <DepensesInfirmeier getSizeMobille={getSizeMobille} />
      </div>
    </>
  );
}

export default DepensesInfirmeierComponent;
