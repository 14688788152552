/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import FootterCopy from "../../FootterCopy/FootterCopy";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select";
import { decryptData } from "../../../encryptionModule";
import { API } from "../../../Api/API";


function MedicamentModifier({ getSizeMobille }) {
    const { id } = useParams()
    const medId = decryptData(id)
    const navigate = useNavigate()
    const UnitComptageOptions = [
        { value: "", label: "Unite de comptage" },
        { value: "CP", label: "CP" },
        { value: "ml", label: "ml" },
        { value: "autre", label: "autre" }
    ];



    const hiddenSUnitComptageOptions = UnitComptageOptions.slice(1);
    const [selectedOption4, setSelectedOption4] = useState(UnitComptageOptions[0]);


    const SelectUnitComptage = (option) => {
        setSelectedOption4(option)
    }


    const customStyles = {
        control: (provided) => ({
            ...provided,
            height: 50,
            backgroundColor: "white",

        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? "blue" : state.isFocused ? "lightblue" : "white",
            color: state.isSelected ? "white" : "black",
        }),
    };



    const [mobile11, SetMobile11] = useState(window.innerWidth < 501)

    useEffect(() => {
        const hundlesSize = () => {

            SetMobile11(window.innerWidth < 501)
        }

        window.addEventListener('resize', hundlesSize)

        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])


    const [nomMedic, SetnomMedic] = useState('')
    const [animationClassnomMedic, setAnimationClassnomMedic] = useState('');
    const elemetRefnomMedic = useRef(null)

    const [animationClassUnitComptage, setanimationClassUnitComptage] = useState('');
    const elemetRefUnitComptage = useRef(null)


    const [dosage, Setdosage] = useState('')
    const [animationClassdosage, setAnimationClassdosage] = useState('');
    const elemetRefdosage = useRef(null)


    const [Quantite, SetQuantite] = useState('')
    const [animationClassQuantite, setAnimationClassQuantite] = useState('');
    const elemetRefQuantite = useRef(null)

    const [source, SetSource] = useState('')
    const [animationClasssource, setAnimationClasssource] = useState('');
    const elemetRefsource = useRef(null)


    const [dateExpiration, SetdateExpiration] = useState('')
    const [animationClassdateExpiration, setAnimationClassdateExpiration] = useState('');
    const elemetRefdateExpiration = useRef(null)

    useEffect(() => {
        API.get(`/INF/getOneMedicamentById/${medId}`).then((rep) => {
            const data = rep.data;
            SetdateExpiration(data.dateexp.substring(0, 10))
            SetSource(data.origine)
            SetQuantite(data.quantite)
            Setdosage(data.dosage)
            SetnomMedic(data.nom)
            if (data.unitecomptage) {
                const correspondingUC = UnitComptageOptions.find(
                    (UC) => UC.value == data.unitecomptage
                );
                if (correspondingUC) {
                    setSelectedOption4(correspondingUC);
                }
            }
        }).catch((err) => {
            console.log(err.message)
        })
    }, [id])

    const handleSubmit = (e) => {
        e.preventDefault()

        if (nomMedic.trim() === '') {
            toast.warning('Le nom médicament du livre est  obligatoire', {
                autoClose: 2000
            });
            setAnimationClassnomMedic('animate__animated animate__shakeX border-2 border-red-500 ')
            setTimeout(() => {
                setAnimationClassnomMedic(' ')
            }, 3000)
            elemetRefnomMedic.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            elemetRefnomMedic.current.focus()
            return false
        }

        else if (selectedOption4.value == '') {
            toast.warning('Le Unité comptage est obligatoire ', {
                autoClose: 2000
            });
            setanimationClassUnitComptage('animate__animated animate__shakeX border-2 border-red-500')
            setTimeout(() => {
                setanimationClassUnitComptage(' ')
            }, 3000)
            elemetRefUnitComptage.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            return;

        }

        else if (dosage.trim() == '') {
            toast.warning('Dosage du livre est obligatoire', {
                autoClose: 2000
            });
            setAnimationClassdosage('animate__animated animate__shakeX border-2 border-red-500')
            setTimeout(() => {
                setAnimationClassdosage(' ')
            }, 3000)
            elemetRefdosage.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            elemetRefdosage.current.focus()

            return false
        }

        else if (Quantite.toString().trim() === '') {
            toast.warning('La quantité du médicament est obligatoire', {
                autoClose: 2000
            });
            setAnimationClassQuantite('animate__animated animate__shakeX border-2 border-red-500')
            setTimeout(() => {
                setAnimationClassQuantite('')
            }, 3000);
            elemetRefQuantite.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            elemetRefQuantite.current.focus();
            return false;
        }


        else if (source.trim() == '') {
            toast.warning('Source du médicament est obligatoire', {
                autoClose: 2000
            });
            setAnimationClasssource('animate__animated animate__shakeX border-2 border-red-500')
            setTimeout(() => {
                setAnimationClasssource(' ')
            }, 3000)
            elemetRefsource.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            elemetRefsource.current.focus()

            return false
        }

        else if (dateExpiration == '') {
            toast.warning("Date d'expiration est nécessaire", {
                autoClose: 2000
            });
            setAnimationClassdateExpiration('animate__animated animate__shakeX  border-2 border-red-500')
            setTimeout(() => {
                setAnimationClassdateExpiration(' ')
            }, 3000)
            elemetRefdateExpiration.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            return false
        }
        const data = {
            nom: nomMedic,
            unitecomptage: selectedOption4.value,
            dosage: dosage,
            quantite: Quantite,
            origine: source,
            dateexp: dateExpiration
        }

        API.put(`/INF/modifierOneMedicamentById/${medId}`, data)
            .then((response) => {
                toast.success(response.data);
                navigate(`/Medicament/Detail/${id}`)
            })
            .catch((error) => {

                if (error.response) {

                    console.log(error.response.data);
                    toast.error(error.response.data.error);
                } else if (error.request) {

                    console.log(error.request);
                    toast.error('Aucune réponse du serveur.');
                } else {
                    console.log('Error', error.message);
                    toast.error(error.message);
                }
            });

    }

    return (

        <div className={`bg-white  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
            <Link to={`/Medicament/Detail/${id}`} className="ml-2 text-[18px] text-blue-500 hover:text-blue-800 underline">Retour</Link>
            <div className="sm:p-4 p-2 min-h-screen">
                <div class="my-5">
                    <div class="w-full flex flex-row items-center justify-between">
                        <p class="text-navy-700   ">
                            <a
                                class="text-2xl font-bold  "
                            >
                                Modifier un médicament
                            </a>
                        </p>

                    </div>
                </div>
                <div class="w-full my-4 grid grid-cols-1 ">
                    <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">
                        <form onSubmit={handleSubmit}>
                            <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col my-2">
                                <div class="-mx-3 md:flex mb-6  ">
                                    <div class={`md:w-1/2 px-3 mb-6 md:mb-0`}>
                                        <label
                                            class="block uppercase tracking-wide  text-lg font-bold mb-2"
                                            for="grid-Title"
                                        >
                                            Nom du medicament
                                        </label>

                                        <input
                                            class={` outline-none block w-full bg-grey-lighter  border rounded py-3 px-4 mb-3 ${nomMedic.trim() === '' ? animationClassnomMedic : 'focus:border-2 focus:border-blue-700'} `}
                                            type="text"
                                            ref={elemetRefnomMedic} value={nomMedic} onInput={(e) => SetnomMedic(e.target.value)}
                                            placeholder="Nom du médicament"
                                        />
                                    </div>
                                    <div class="md:w-1/2 px-3">
                                        <label
                                            class="block uppercase tracking-wide text-gray-700 text-lg font-bold mb-2"
                                            for="grid-state"
                                        >
                                            Unité comptage
                                        </label>
                                        <div ref={elemetRefUnitComptage} className={` rounded  ${selectedOption4.value == '' ? animationClassUnitComptage : 'focus:border-2 focus:border-blue-700'} `}
                                        >
                                            <Select
                                                value={selectedOption4}
                                                options={hiddenSUnitComptageOptions}
                                                styles={customStyles}
                                                onChange={SelectUnitComptage}
                                            />
                                        </div>

                                    </div>










                                </div>

                                <div class={`-mx-3 md:flex mb-6`}>
                                    <div class={`md:w-1/2 px-3 mb-6 md:mb-0`}>
                                        <label
                                            class="block uppercase tracking-wide  text-lg font-bold mb-2"
                                            for="grid-last-name"
                                        >
                                            Dosage
                                        </label>
                                        <input
                                            ref={elemetRefdosage} value={dosage} onInput={(e) => Setdosage(e.target.value)}
                                            class={`appearance-none outline-none block w-full bg-grey-lighter  border rounded py-3 px-4  ${dosage.trim() === '' ? animationClassdosage : 'focus:border-2 focus:border-blue-700'}`}
                                            id="grid-last-name"
                                            type="text"
                                            placeholder="Dosage"
                                        />
                                    </div>

                                    <div class={`md:w-1/2 px-3`}>
                                        <label
                                            class="block uppercase tracking-wide  text-lg font-bold mb-2"
                                            for="grid-last-name"
                                        >
                                            Quantite
                                        </label>
                                        <input
                                            ref={elemetRefQuantite} value={Quantite} onInput={(e) => SetQuantite(e.target.value)}
                                            class={`appearance-none outline-none block w-full bg-grey-lighter  border rounded py-3 px-4  ${Quantite == '' ? animationClassQuantite : 'focus:border-2 focus:border-blue-700'}`}
                                            id="grid-last-name"
                                            type="text"
                                            placeholder="Quantite  du médicament"
                                        />
                                    </div>


                                </div>

                                <div class={`-mx-3 md:flex mb-6`}>
                                    <div class={`md:w-1/2 px-3`}>
                                        <label
                                            class="block uppercase tracking-wide  text-lg font-bold mb-2"
                                            for="grid-last-name"
                                        >
                                            Source
                                        </label>
                                        <input
                                            ref={elemetRefsource} value={source} onInput={(e) => SetSource(e.target.value)}
                                            class={`appearance-none outline-none block w-full bg-grey-lighter  border rounded py-3 px-4  ${source.trim() === '' ? animationClasssource : 'focus:border-2 focus:border-blue-700'}`}
                                            id="grid-last-name"
                                            type="text"
                                            placeholder="Source  du médicament"
                                        />
                                    </div>

                                    <div class={`md:w-1/2 px-3 max-md:mt-10`}>
                                        <label
                                            class="block uppercase tracking-wide  text-lg font-bold mb-2"
                                            for="grid-last-name"
                                        >
                                            Date d'expiration
                                        </label>
                                        <input
                                            ref={elemetRefdateExpiration} value={dateExpiration} onInput={(e) => SetdateExpiration(e.target.value)}
                                            class={`appearance-none outline-none block w-full bg-grey-lighter  border rounded py-3 px-4  ${dateExpiration == '' ? animationClassdateExpiration : 'focus:border-2 focus:border-blue-700'}`}
                                            type="date"
                                        />
                                    </div>

                                </div>

                                <div class="mb-4 flex items-center justify-end">
                                    <div class="">
                                        <button class="mt-4 sm:mt-0 inline-flex items-start justify-start px-2 sm:px-6 py-3 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg">
                                            <p class="text-lg font-medium leading-none text-white">
                                                Modifier
                                            </p>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <FootterCopy />
        </div>

    );
}


export default MedicamentModifier;










