import React from 'react'
import SideBarsPrefetDisplin from '../SideBarsPrefetDisplin/SideBarsPrefetDisplin'
import PresencePrefetDisplin from './PresencePrefetDisplin'
function PresencePrefetDisplinComponent({ getSizeMobille, getMenuMobille }) {
    return (
        <div class="overflow-hidden bg-white flex justify-between">
            <SideBarsPrefetDisplin getMenuMobille={getMenuMobille} />
            <PresencePrefetDisplin getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default PresencePrefetDisplinComponent
