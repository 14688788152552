/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import FootterCopy from '../../FootterCopy/FootterCopy'

function ListeCoursAllParent({ getSizeMobille }) {
    const [mobile1, Setmonile1] = useState(window.innerWidth < 621)

    const [mobile11, SetMobile11] = useState(window.innerWidth < 501)

    useEffect(() => {
        const hundlesSize = () => {
            Setmonile1(window.innerWidth < 621)
            SetMobile11(window.innerWidth < 501)
        }

        window.addEventListener('resize', hundlesSize)

        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])


    return (

        <div className={`bg-white  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
            <Link to='/NoteAllElev' className="ml-2 text-[18px] text-blue-500 hover:text-blue-800 underline">Retour</Link>

            <div className="p-4 min-h-screen">
                <div class="sm:my-10 my-4 flex items-center justify-center">
                    <h3 class="text-xl max-sm:text-center sm:text-2xl leading-none font-bold text-gray-500">
                        Quel  Cours que vous-voulez consulter ?
                    </h3>
                </div>

                <div class="sm:flex items-center justify-between">
                    <div class="flex flex-row items-center space-x-5">
                        <div class="mx-2">
                            <div class="relative lg:w-64">
                                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <svg
                                        class="w-5 h-5 text-gray-500"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                            clip-rule="evenodd"
                                        ></path>
                                    </svg>
                                </div>
                                <input
                                    type="text"
                                    name="email"
                                    id="topbar-search"
                                    class="bg-white border border-gray-300 text-gray-900 sm:text-lg rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full pl-10 p-2.5"
                                    placeholder="Rechercher..."
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className=' text-gray-400 w-full text-end text-[20px] pr-[10px] font-serif'>I trimestre</div>
                <div class="my-4 w-full items-center flex flex-wrap gap-4">
                    <Link
                        to="/NoteAllElev/ListeCoursAll/ListeEvaluationAll"
                        class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 cursor-pointer hover:bg-gray-100s hover:shadow-lg transition duration-300 ease-in-out hover:scale-105 transform"
                    >
                        <div class="flex items-center">
                            <div class="flex-shrink-0">
                                <h3 class="text-xl font-normal text-gray-700">Science de la terre</h3>
                            </div>
                        </div>
                    </Link>
                    <Link
                        to="/#"
                        class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 cursor-pointer hover:bg-gray-100s hover:shadow-lg transition duration-300 ease-in-out hover:scale-105 transform"
                    >
                        <div class="flex items-center">
                            <div class="flex-shrink-0">
                                <h3 class="text-xl font-normal text-gray-700">Biologie</h3>
                            </div>
                        </div>
                    </Link>
                    <Link
                        to="/#"
                        class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 cursor-pointer hover:bg-gray-100s hover:shadow-lg transition duration-300 ease-in-out hover:scale-105 transform"
                    >
                        <div class="flex items-center">
                            <div class="flex-shrink-0">
                                <h3 class="text-xl font-normal text-gray-700">Mathématique</h3>
                            </div>
                        </div>
                    </Link>

                    <Link
                        to="/#"
                        class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 cursor-pointer hover:bg-gray-100s hover:shadow-lg transition duration-300 ease-in-out hover:scale-105 transform"
                    >
                        <div class="flex items-center">
                            <div class="flex-shrink-0">
                                <h3 class="text-xl font-normal text-gray-700">Physique</h3>
                            </div>
                        </div>
                    </Link>


                    <Link
                        to="/#"
                        class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 cursor-pointer hover:bg-gray-100s hover:shadow-lg transition duration-300 ease-in-out hover:scale-105 transform"
                    >
                        <div class="flex items-center">
                            <div class="flex-shrink-0">
                                <h3 class="text-xl font-normal text-gray-700">Kirundi</h3>
                            </div>
                        </div>
                    </Link>
                    <Link
                        to="/#"
                        class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 cursor-pointer hover:bg-gray-100s hover:shadow-lg transition duration-300 ease-in-out hover:scale-105 transform"
                    >
                        <div class="flex items-center">
                            <div class="flex-shrink-0">
                                <h3 class="text-xl font-normal text-gray-700">Francais</h3>
                            </div>
                        </div>
                    </Link>
                    <Link
                        to="/#"
                        class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 cursor-pointer hover:bg-gray-100s hover:shadow-lg transition duration-300 ease-in-out hover:scale-105 transform"
                    >
                        <div class="flex items-center">
                            <div class="flex-shrink-0">
                                <h3 class="text-xl font-normal text-gray-700">Kiswahili</h3>
                            </div>
                        </div>
                    </Link>
                    <Link
                        to="/#"
                        class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 cursor-pointer hover:bg-gray-100s hover:shadow-lg transition duration-300 ease-in-out hover:scale-105 transform"
                    >
                        <div class="flex items-center">
                            <div class="flex-shrink-0">
                                <h3 class="text-xl font-normal text-gray-700">Religion</h3>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
            <FootterCopy />
        </div>
    )

}

export default ListeCoursAllParent
