import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FootterCopy from "../../FootterCopy/FootterCopy";
function ListDetteEconom({ getSizeMobille }) {

    return (
        <>
            <div className={`bg-white  overflow-y-auto  overflow-x-hidden  h-[85vh] ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
                <Link to='/DetteEconome' className="ml-2 text-[18px] text-blue-500 hover:text-blue-800 underline">Retour</Link>

                <div className="p-6 min-h-[90vh] ">
                    <div className="sm:text-[25px] text-gray-700 font-bold">Les listes des dettes économe</div>
                    <div class="sm:flex items-center justify-end">

                        <div class="mx-2 z-10">
                            <div class="relative lg:w-64">
                                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <svg
                                        class="w-5 h-5 text-gray-500"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                            clip-rule="evenodd"
                                        ></path>
                                    </svg>
                                </div>
                                <input
                                    type="text"
                                    name="email"
                                    id="topbar-search"
                                    class="bg-white border border-gray-300 text-gray-900 sm:text-lg rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full pl-10 p-2.5"
                                    placeholder="Rechercher..."
                                />
                            </div>
                        </div>
                        <button class="mt-4 sm:mt-0 inline-flex items-start justify-start px-6 py-3 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg">
                            <p class="text-lg font-medium leading-none text-white">
                                Rerchercher
                            </p>
                        </button>
                    </div>
                    <div class="w-full my-4 grid grid-cols-1 ">
                        <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">

                            <div class="flex flex-col mt-8">
                                <div class="overflow-x-auto rounded-lg">
                                    <div class="align-middle inline-block max-sm:w-[35em] sm:min-w-full">
                                        <div class="shadow overflow-hidden sm:rounded-lg">
                                            <table class="min-w-full divide-y divide-gray-200">
                                                <thead class="bg-gray-50">
                                                    <tr>
                                                        <th
                                                            scope="col"
                                                            class="p-4 text-left text-lg  text-gray-500 font-bold tracking-wider"
                                                        >
                                                            Numéro
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            class="p-4 text-left text-lg  text-gray-500 font-bold tracking-wider"
                                                        >
                                                            Nom complet
                                                        </th>

                                                        <th
                                                            scope="col"
                                                            class="p-4 text-left text-lg  text-gray-500 font-bold tracking-wider"
                                                        >
                                                            Frais
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            class="p-4 text-left text-lg  text-gray-500 font-bold tracking-wider"
                                                        >
                                                            Action
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody class="bg-white">
                                                    <tr>
                                                        <td class="border-b border-gray-200 p-4 whitespace-nowrap text-lg font-normal text-gray-500">
                                                            2
                                                        </td>
                                                        <td class="border-b border-gray-200 p-4 whitespace-nowrap text-lg font-normal text-gray-500">
                                                            SIMBARE Pierre
                                                        </td>

                                                        <td class="border-b border-gray-200 p-4 whitespace-nowrap text-lg font-normal text-gray-500">
                                                            2 000 000
                                                        </td>

                                                        <td class="border-b border-gray-200 p-4 whitespace-nowrap text-lg font-normal text-gray-500">
                                                            <Link
                                                                to="/DetteEconome/ListDetteEconom/Detail"
                                                                class="mt-4 sm:mt-0 inline-flex items-start justify-start px-6 py-3 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg"
                                                            >
                                                                <p class="text-lg  leading-none text-white">
                                                                    Details
                                                                </p>
                                                            </Link>
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FootterCopy />
            </div>

        </>
    );
}

export default ListDetteEconom;
