import React from 'react'
import PresenceBlio from './PresenceBlio'
import SideBarBibliote from '../SideBar/SideBarBibliote'
function PresenceBlioComponent({ getSizeMobille, getMenuMobille }) {
    return (
        <div className='flex justify-between bg-white overflow-hidden'>
            <SideBarBibliote getMenuMobille={getMenuMobille} />
            <PresenceBlio getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default PresenceBlioComponent
