import React from 'react'
import ListDetteBiblio from './ListDetteBiblio'
import SideBarSecretaire from '../SideBarSecretaire/SideBarSecretaire'
function ListDetteBiblioComponent({ getSizeMobille, getMenuMobille }) {
    return (
        <div className='flex justify-between overflow-hidden'>
            <SideBarSecretaire getMenuMobille={getMenuMobille} />
            <ListDetteBiblio getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default ListDetteBiblioComponent
