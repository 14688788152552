import React, { useState } from "react";
import RegistresComponent from "../../component/Secretariat/Registres/RegistresComponent";
function RegistresPage({ getMenuMobille, getSizeMobille }) {
  return (
    <>
      <RegistresComponent getSizeMobille={getSizeMobille} getMenuMobille={getMenuMobille} />
    </>
  );
}

export default RegistresPage;
