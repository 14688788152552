/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from "react";
import Chart from "react-apexcharts";
import "apexcharts/dist/apexcharts.css";

function AcceuilEnseing() {
    const options = {
        chart: {
            id: "line",
        },
        xaxis: {
            categories: [
                "I Sc",
                "II Sc",
                "SSH",
                "Lang",
                "IT",
                "IM",
                "Course 7",
                "Course 8",
                "Course 9",
                "Course 10",
                "Course 11",
                "Course 12",
                "Course 13",
                "Course 14",
                "Course 15",
                "Course 16",
                "Course 17",
                "Course 18",
                "Course 19",
                "Course 20"
            ],
        },
    };

    const series = [
        {
            name: "Series 1",
            data: [30, 40, 5, 50, 90, 0, 25, 35, 42, 55, 80, 3, 28, 38, 7, 52, 51, 9, 61, 33]
        },
    ];


    return (
        <>
            {" "}
            <div
                id="main-content"
                class="h-full w-full bg-gray-50 relative overflow-y-auto lg:ml-64"
            >
                <main>
                    <div class="pt-6 px-4">

                        <div class="w-full grid grid-cols-1 gap-4">
                            <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 2xl:col-span-2">
                                <div class="flex items-center justify-between mb-4">
                                    <div class="flex-shrink-0">
                                        <h3 class="text-xl  leading-none font-bold text-gray-900">
                                            Evolution des réussite dans votre cours
                                        </h3>
                                    </div>

                                </div>
                                <div id="main-chart" style={{ "min-height": "435px" }}>
                                    <Chart
                                        options={options}
                                        series={series}
                                        type="bar"
                                        height={500}
                                    />
                                </div>
                            </div>

                        </div>

                        <div class="grid grid-cols-1 2xl:grid-cols-2 xl:gap-4 my-4">
                            <div class="bg-white hidden shadow rounded-lg mb-4 p-4 sm:p-6 h-full">
                                <div class="flex items-center justify-between mb-4">
                                    <h3 class="text-xl font-bold leading-none text-gray-900">
                                        Latest Customers
                                    </h3>
                                    <a
                                        href="#"
                                        class="text-lg font-medium text-cyan-600 hover:bg-gray-100 rounded-lg inline-flex items-center p-2"
                                    >
                                        View all
                                    </a>
                                </div>
                                <div class="flow-root">
                                    <ul role="list" class="divide-y divide-gray-200">
                                        <li class="py-3 sm:py-4">
                                            <div class="flex items-center space-x-4">
                                                <div class="flex-shrink-0">
                                                    <img
                                                        class="h-8 w-8 rounded-full"
                                                        src="#images/users/neil-sims.png"
                                                        alt="Neil image"
                                                    />
                                                </div>
                                                <div class="flex-1 min-w-0">
                                                    <p class="text-lg font-medium text-gray-900 truncate">
                                                        Neil Sims
                                                    </p>
                                                    <p class="text-lg text-gray-500 truncate">
                                                        email@windster.com
                                                    </p>
                                                </div>
                                                <div class="inline-flex items-center text-xl font-semibold text-gray-900">
                                                    $320
                                                </div>
                                            </div>
                                        </li>
                                        <li class="py-3 sm:py-4">
                                            <div class="flex items-center space-x-4">
                                                <div class="flex-shrink-0">
                                                    <img
                                                        class="h-8 w-8 rounded-full"
                                                        src="#images/users/bonnie-green.png"
                                                        alt="Neil image"
                                                    />
                                                </div>
                                                <div class="flex-1 min-w-0">
                                                    <p class="text-lg font-medium text-gray-900 truncate">
                                                        Bonnie Green
                                                    </p>
                                                    <p class="text-lg text-gray-500 truncate">
                                                        email@windster.com
                                                    </p>
                                                </div>
                                                <div class="inline-flex items-center text-xl font-semibold text-gray-900">
                                                    $3467
                                                </div>
                                            </div>
                                        </li>
                                        <li class="py-3 sm:py-4">
                                            <div class="flex items-center space-x-4">
                                                <div class="flex-shrink-0">
                                                    <img
                                                        class="h-8 w-8 rounded-full"
                                                        src="#images/users/michael-gough.png"
                                                        alt="Neil image"
                                                    />
                                                </div>
                                                <div class="flex-1 min-w-0">
                                                    <p class="text-lg font-medium text-gray-900 truncate">
                                                        Michael Gough
                                                    </p>
                                                    <p class="text-lg text-gray-500 truncate">
                                                        email@windster.com
                                                    </p>
                                                </div>
                                                <div class="inline-flex items-center text-xl font-semibold text-gray-900">
                                                    $67
                                                </div>
                                            </div>
                                        </li>
                                        <li class="py-3 sm:py-4">
                                            <div class="flex items-center space-x-4">
                                                <div class="flex-shrink-0">
                                                    <img
                                                        class="h-8 w-8 rounded-full"
                                                        src="#images/users/thomas-lean.png"
                                                        alt="Neil image"
                                                    />
                                                </div>
                                                <div class="flex-1 min-w-0">
                                                    <p class="text-lg font-medium text-gray-900 truncate">
                                                        Thomes Lean
                                                    </p>
                                                    <p class="text-lg text-gray-500 truncate">
                                                        email@windster.com
                                                    </p>
                                                </div>
                                                <div class="inline-flex items-center text-xl font-semibold text-gray-900">
                                                    $2367
                                                </div>
                                            </div>
                                        </li>
                                        <li class="pt-3 sm:pt-4 pb-0">
                                            <div class="flex items-center space-x-4">
                                                <div class="flex-shrink-0">
                                                    <img
                                                        class="h-8 w-8 rounded-full"
                                                        src="#images/users/lana-byrd.png"
                                                        alt="Neil image"
                                                    />
                                                </div>
                                                <div class="flex-1 min-w-0">
                                                    <p class="text-lg font-medium text-gray-900 truncate">
                                                        Lana Byrd
                                                    </p>
                                                    <p class="text-lg text-gray-500 truncate">
                                                        email@windster.com
                                                    </p>
                                                </div>
                                                <div class="inline-flex items-center text-xl font-semibold text-gray-900">
                                                    $367
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">
                                <h3 class="text-xl leading-none font-bold text-gray-900 mb-10">
                                    Réussite classe
                                </h3>
                                <div class="block w-full overflow-x-auto">
                                    <table class="items-center w-full bg-transparent border-collapse">
                                        <thead>
                                            <tr>
                                                <th class="px-4 bg-gray-50 text-gray-700 align-middle py-3 text-lg font-semibold text-left uppercase border-l-0 border-r-0 whitespace-nowrap">
                                                    Classe
                                                </th>
                                                <th class="px-4 bg-gray-50 text-gray-700 align-middle py-3 text-lg font-semibold text-left uppercase border-l-0 border-r-0 whitespace-nowrap">
                                                    Cours
                                                </th>
                                                <th class="px-4 bg-gray-50 text-gray-700 align-middle py-3 text-lg font-semibold text-left uppercase border-l-0 border-r-0 whitespace-nowrap">
                                                    Elève
                                                </th>
                                                <th class="px-4 bg-gray-50 text-gray-700 align-middle py-3 text-lg font-semibold text-left uppercase border-l-0 border-r-0 whitespace-nowrap min-w-140-px"></th>
                                            </tr>
                                        </thead>
                                        <tbody class="divide-y divide-gray-100">
                                            <tr class="text-gray-500">
                                                <th class="border-t-0 px-4 align-middle text-lg font-normal whitespace-nowrap p-4 text-left">
                                                    IT
                                                </th>
                                                <td class="border-t-0 px-4 align-middle text-lg font-medium text-gray-900 whitespace-nowrap p-4">
                                                    Entrepreneuriat
                                                </td>
                                                <td class="border-t-0 px-4 align-middle text-lg font-medium text-gray-900 whitespace-nowrap p-4">
                                                    5,649
                                                </td>
                                                <td class="border-t-0 px-4 align-middle text-lg whitespace-nowrap p-4">
                                                    <div class="flex items-center">
                                                        <span class="mr-2 text-lg font-medium">30%</span>
                                                        <div class="relative w-full">
                                                            <div class="w-full bg-gray-200 rounded-sm h-2">
                                                                <div
                                                                    class="bg-cyan-600 h-2 rounded-sm"
                                                                    style={{ width: "30%" }}
                                                                ></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr class="text-gray-500">
                                                <th class="border-t-0 px-4 align-middle text-lg font-normal whitespace-nowrap p-4 text-left">
                                                    Pédagogie
                                                </th>
                                                <td class="border-t-0 px-4 align-middle text-lg font-medium text-gray-900 whitespace-nowrap p-4">
                                                    Physique
                                                </td>
                                                <td class="border-t-0 px-4 align-middle text-lg font-medium text-gray-900 whitespace-nowrap p-4">
                                                    30
                                                </td>
                                                <td class="border-t-0 px-4 align-middle text-lg whitespace-nowrap p-4">
                                                    <div class="flex items-center">
                                                        <span class="mr-2 text-lg font-medium">24%</span>
                                                        <div class="relative w-full">
                                                            <div class="w-full bg-gray-200 rounded-sm h-2">
                                                                <div
                                                                    class="bg-orange-300 h-2 rounded-sm"
                                                                    style={{ width: "24%" }}
                                                                ></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr class="text-gray-500">
                                                <th class="border-t-0 px-4 align-middle text-lg font-normal whitespace-nowrap p-4 text-left">
                                                    Pédagogie
                                                </th>
                                                <td class="border-t-0 px-4 align-middle text-lg font-medium text-gray-900 whitespace-nowrap p-4">
                                                    Physique
                                                </td>
                                                <td class="border-t-0 px-4 align-middle text-lg font-medium text-gray-900 whitespace-nowrap p-4">
                                                    30
                                                </td>
                                                <td class="border-t-0 px-4 align-middle text-lg whitespace-nowrap p-4">
                                                    <div class="flex items-center">
                                                        <span class="mr-2 text-lg font-medium">18%</span>
                                                        <div class="relative w-full">
                                                            <div class="w-full bg-gray-200 rounded-sm h-2">
                                                                <div
                                                                    class="bg-teal-400 h-2 rounded-sm"
                                                                    style={{ width: "18%" }}
                                                                ></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr class="text-gray-500">
                                                <th class="border-t-0 px-4 align-middle text-lg font-normal whitespace-nowrap p-4 text-left">
                                                    Pédagogie
                                                </th>
                                                <td class="border-t-0 px-4 align-middle text-lg font-medium text-gray-900 whitespace-nowrap p-4">
                                                    Physique
                                                </td>
                                                <td class="border-t-0 px-4 align-middle text-lg font-medium text-gray-900 whitespace-nowrap p-4">
                                                    30
                                                </td>
                                                <td class="border-t-0 px-4 align-middle text-lg whitespace-nowrap p-4">
                                                    <div class="flex items-center">
                                                        <span class="mr-2 text-lg font-medium">12%</span>
                                                        <div class="relative w-full">
                                                            <div class="w-full bg-gray-200 rounded-sm h-2">
                                                                <div
                                                                    class="bg-pink-600 h-2 rounded-sm"
                                                                    style={{ width: " 12%" }}
                                                                ></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr class="text-gray-500">
                                                <th class="border-t-0 px-4 align-middle text-lg font-normal whitespace-nowrap p-4 text-left">
                                                    Pédagogie
                                                </th>
                                                <td class="border-t-0 px-4 align-middle text-lg font-medium text-gray-900 whitespace-nowrap p-4">
                                                    Physique
                                                </td>
                                                <td class="border-t-0 px-4 align-middle text-lg font-medium text-gray-900 whitespace-nowrap p-4">
                                                    30
                                                </td>
                                                <td class="border-t-0 px-4 align-middle text-lg whitespace-nowrap p-4">
                                                    <div class="flex items-center">
                                                        <span class="mr-2 text-lg font-medium">9%</span>
                                                        <div class="relative w-full">
                                                            <div class="w-full bg-gray-200 rounded-sm h-2">
                                                                <div
                                                                    class="bg-indigo-600 h-2 rounded-sm"
                                                                    style={{ width: "9%" }}
                                                                ></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr class="text-gray-500">
                                                <th class="border-t-0 px-4 align-middle text-lg font-normal whitespace-nowrap p-4 text-left">
                                                    Pédagogie
                                                </th>
                                                <td class="border-t-0 px-4 align-middle text-lg font-medium text-gray-900 whitespace-nowrap p-4">
                                                    Physique
                                                </td>
                                                <td class="border-t-0 px-4 align-middle text-lg font-medium text-gray-900 whitespace-nowrap p-4">
                                                    30
                                                </td>
                                                <td class="border-t-0 align-middle text-lg whitespace-nowrap p-4 pb-0">
                                                    <div class="flex items-center">
                                                        <span class="mr-2 text-lg font-medium">7%</span>
                                                        <div class="relative w-full">
                                                            <div class="w-full bg-gray-200 rounded-sm h-2">
                                                                <div
                                                                    class="bg-purple-500 h-2 rounded-sm"
                                                                    style={{ width: "7%" }}
                                                                ></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>

            </div>{" "}
        </>
    );
}

export default AcceuilEnseing;
