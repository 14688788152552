/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
function SlideParentNavBars({ getMenuMobille }) {
    const { pathname } = useLocation();
    let AcceuilCantine = /^\/AcceuilCantine.*$/;
    let NoteEnt = /^\/NoteEnt.*$/;
    let NoteAllElev = /^\/NoteAllElev.*$/;

    const [mobile, SetMobile] = useState(window.innerWidth < 1292)
    const [mobile11, SetMobile11] = useState(window.innerWidth < 501)
    useEffect(() => {
        const hundleSize = () => {
            SetMobile(window.innerWidth < 1292)
            SetMobile11(window.innerWidth < 501)
        }

        window.addEventListener('resize', hundleSize)

        return () => window.removeEventListener('resize', hundleSize)
    }, [])



    return (
        <>
            <div className={`bg-white   border-r-2 border-blue-800 w-[10vw]  overflow-y-auto overflow-x-hidden min-w-[13em] ${mobile ? 'fixed  z-[100]' : ''} ${getMenuMobille ? 'left-0' : '-left-full'} transition-all duration-300 ${mobile11 ? 'top-[10vh] h-[90vh]' : 'top-[15vh] h-[85vh]'} `}>
                <div className="p-1 mb-5 border-b border-white">
                    <div class="flex-1 flex flex-col bg-white pt-5 pb-4 overflow-y-auto">
                        <div class="flex-1 px-3  divide-y space-y-1">
                            <ul class="space-y-2 pb-2">
                                {AcceuilCantine.test(pathname) || pathname === "/" ? (
                                    <Link to="/">
                                        <li>
                                            <div class="text-xl font-normal rounded-lg bg-blue-500 text-white  flex items-center p-2 group">
                                                <svg
                                                    class="w-6 h-6 text-white transition duration-75"
                                                    fill="currentColor"
                                                    viewBox="0 0 20 20"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path>
                                                    <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path>
                                                </svg>

                                                <span class="ml-3 flex-1 whitespace-nowrap">
                                                    Accueil
                                                </span>

                                            </div>
                                        </li>
                                    </Link>
                                ) : (
                                    <Link to="/">
                                        <li>
                                            <div class="text-xl  font-normal rounded-lg hover:text-gray-400 transition-all flex items-center p-2 group">
                                                <svg
                                                    class="w-6 h-6 "
                                                    fill="currentColor"
                                                    viewBox="0 0 20 20"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path>
                                                    <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path>
                                                </svg>

                                                <span class="ml-3 flex-1 whitespace-nowrap">
                                                    Accueil
                                                </span>

                                            </div>
                                        </li>
                                    </Link>
                                )}

                                {NoteEnt.test(pathname) ? (
                                    <Link to="/NoteEnt">
                                        <li>
                                            <div

                                                class="text-xl font-normal rounded-lg bg-blue-500 text-white  flex items-center p-2 group"
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-diagram-3 w-6 h-6" viewBox="0 0 16 16">
                                                    <path fill-rule="evenodd" d="M6 3.5A1.5 1.5 0 0 1 7.5 2h1A1.5 1.5 0 0 1 10 3.5v1A1.5 1.5 0 0 1 8.5 6v1H14a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0v-1A.5.5 0 0 1 2 7h5.5V6A1.5 1.5 0 0 1 6 4.5v-1zM8.5 5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1zM0 11.5A1.5 1.5 0 0 1 1.5 10h1A1.5 1.5 0 0 1 4 11.5v1A1.5 1.5 0 0 1 2.5 14h-1A1.5 1.5 0 0 1 0 12.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zm4.5.5A1.5 1.5 0 0 1 7.5 10h1a1.5 1.5 0 0 1 1.5 1.5v1A1.5 1.5 0 0 1 8.5 14h-1A1.5 1.5 0 0 1 6 12.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zm4.5.5a1.5 1.5 0 0 1 1.5-1.5h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1z" />
                                                </svg>


                                                <span class="ml-3 flex-1 whitespace-nowrap">
                                                    Mon enfant
                                                </span>

                                            </div>
                                        </li>
                                    </Link>
                                ) : (
                                    <Link to="/NoteEnt">
                                        <li>
                                            <div

                                                class="text-xl  font-normal rounded-lg hover:text-gray-400 transition-all flex items-center p-2 group"
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-diagram-3 w-6 h-6" viewBox="0 0 16 16">
                                                    <path fill-rule="evenodd" d="M6 3.5A1.5 1.5 0 0 1 7.5 2h1A1.5 1.5 0 0 1 10 3.5v1A1.5 1.5 0 0 1 8.5 6v1H14a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0v-1A.5.5 0 0 1 2 7h5.5V6A1.5 1.5 0 0 1 6 4.5v-1zM8.5 5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1zM0 11.5A1.5 1.5 0 0 1 1.5 10h1A1.5 1.5 0 0 1 4 11.5v1A1.5 1.5 0 0 1 2.5 14h-1A1.5 1.5 0 0 1 0 12.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zm4.5.5A1.5 1.5 0 0 1 7.5 10h1a1.5 1.5 0 0 1 1.5 1.5v1A1.5 1.5 0 0 1 8.5 14h-1A1.5 1.5 0 0 1 6 12.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zm4.5.5a1.5 1.5 0 0 1 1.5-1.5h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1z" />
                                                </svg>

                                                <span class="ml-3 flex-1 whitespace-nowrap">
                                                    Mon enfant
                                                </span>

                                            </div>
                                        </li>
                                    </Link>
                                )}


                                {pathname == '/Notedispline' ? (
                                    <Link to="/Notedispline">
                                        <li>
                                            <div class="text-xl font-normal rounded-lg bg-blue-500 text-white hover:bg-blue-400 flex items-center p-2 group">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-suit-diamond-fill w-6 h-6" viewBox="0 0 16 16">
                                                    <path d="M2.45 7.4 7.2 1.067a1 1 0 0 1 1.6 0L13.55 7.4a1 1 0 0 1 0 1.2L8.8 14.933a1 1 0 0 1-1.6 0L2.45 8.6a1 1 0 0 1 0-1.2z" />
                                                </svg>

                                                <span class="ml-3 flex-1 whitespace-nowrap">
                                                    Displine
                                                </span>

                                            </div>
                                        </li>
                                    </Link>
                                ) : (
                                    <Link to="/Notedispline">
                                        <li>
                                            <div class="text-xl  font-normal rounded-lg hover:text-gray-400 transition-all flex items-center p-2 group">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-suit-diamond-fill w-6 h-6" viewBox="0 0 16 16">
                                                    <path d="M2.45 7.4 7.2 1.067a1 1 0 0 1 1.6 0L13.55 7.4a1 1 0 0 1 0 1.2L8.8 14.933a1 1 0 0 1-1.6 0L2.45 8.6a1 1 0 0 1 0-1.2z" />
                                                </svg>

                                                <span class="ml-3 flex-1 whitespace-nowrap">
                                                    Displine
                                                </span>

                                            </div>
                                        </li>
                                    </Link>
                                )}

                                {NoteAllElev.test(pathname) ? (
                                    <Link to="/NoteAllElev">
                                        <li>
                                            <div

                                                class="text-xl font-normal rounded-lg bg-blue-500 text-white  flex items-center p-2 group"
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-collection-fill w-6 h-6" viewBox="0 0 16 16">
                                                    <path d="M0 13a1.5 1.5 0 0 0 1.5 1.5h13A1.5 1.5 0 0 0 16 13V6a1.5 1.5 0 0 0-1.5-1.5h-13A1.5 1.5 0 0 0 0 6v7zM2 3a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 0-1h-11A.5.5 0 0 0 2 3zm2-2a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 0-1h-7A.5.5 0 0 0 4 1z" />
                                                </svg>

                                                <span class="ml-3 flex-1 whitespace-nowrap">
                                                    Notes
                                                </span>

                                            </div>
                                        </li>
                                    </Link>
                                ) : (
                                    <Link to="/NoteAllElev">
                                        <li>
                                            <div

                                                class="text-xl  font-normal rounded-lg hover:text-gray-400 transition-all flex items-center p-2 group"
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-collection-fill w-6 h-6" viewBox="0 0 16 16">
                                                    <path d="M0 13a1.5 1.5 0 0 0 1.5 1.5h13A1.5 1.5 0 0 0 16 13V6a1.5 1.5 0 0 0-1.5-1.5h-13A1.5 1.5 0 0 0 0 6v7zM2 3a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 0-1h-11A.5.5 0 0 0 2 3zm2-2a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 0-1h-7A.5.5 0 0 0 4 1z" />
                                                </svg>


                                                <span class="ml-3 flex-1 whitespace-nowrap">
                                                    Notes
                                                </span>

                                            </div>
                                        </li>
                                    </Link>
                                )}

                            </ul>
                            <a href="mailto:btr.dev@burundientempsreel.com" className=" cursor-pointer hover:text-gray-600 py-2 mb-4  flex pl-5 items-center">
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi mr-3 h-6 w-6 bi-question-diamond-fill" viewBox="0 0 16 16">
                                        <path d="M9.05.435c-.58-.58-1.52-.58-2.1 0L.436 6.95c-.58.58-.58 1.519 0 2.098l6.516 6.516c.58.58 1.519.58 2.098 0l6.516-6.516c.58-.58.58-1.519 0-2.098L9.05.435zM5.495 6.033a.237.237 0 0 1-.24-.247C5.35 4.091 6.737 3.5 8.005 3.5c1.396 0 2.672.73 2.672 2.24 0 1.08-.635 1.594-1.244 2.057-.737.559-1.01.768-1.01 1.486v.105a.25.25 0 0 1-.25.25h-.81a.25.25 0 0 1-.25-.246l-.004-.217c-.038-.927.495-1.498 1.168-1.987.59-.444.965-.736.965-1.371 0-.825-.628-1.168-1.314-1.168-.803 0-1.253.478-1.342 1.134-.018.137-.128.25-.266.25h-.825zm2.325 6.443c-.584 0-1.009-.394-1.009-.927 0-.552.425-.94 1.01-.94.609 0 1.028.388 1.028.94 0 .533-.42.927-1.029.927z" />
                                    </svg>
                                </div>
                                <div>
                                    Aide
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default SlideParentNavBars
