import React, { useState } from "react";
import SideBarBibliote from "../SideBar/SideBarBibliote";
import Livres from "./Livres";
function LivresComponent({ getSizeMobille, getMenuMobille }) {
  return (
    <>
      {" "}
      <div class="overflow-hidden flex justify-between">
        <SideBarBibliote getMenuMobille={getMenuMobille} />
        <Livres getSizeMobille={getSizeMobille} />
      </div>
    </>
  );
}

export default LivresComponent;
