import React from 'react'
import DepenseEncadreur from './DepenseEncadreur'
import SlideEncadrementNavBars from '../SlideEncadrementNavBars/SlideEncadrementNavBars'
function DepenseEncadreurComponent() {
    return (
        <div className='flex justify-between overflow-hidden bg-white'>
            <SlideEncadrementNavBars />
            <DepenseEncadreur />
        </div>
    )
}

export default DepenseEncadreurComponent
