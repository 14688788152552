import React, { useState } from "react";
import PrevisionComponent from "../../component/Bibliotheque/Prevision/PrevisionComponent";
function PrevisionPage({ getSizeMobille, getMenuMobille }) {
  return (
    <>
      <PrevisionComponent getMenuMobille={getMenuMobille} getSizeMobille={getSizeMobille} />
    </>
  );
}

export default PrevisionPage;
