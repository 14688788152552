
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useRef, useState } from "react";
import FootterCopy from "../../FootterCopy/FootterCopy";
import { activiesHoraires } from "../../../Data/Data";
import { Link } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
function EncadrementHoraire({ getSizeMobille }) {
    const [mobile, Setmonile] = useState(window.innerWidth < 1575)
    const [mobile2, Setmonile2] = useState(window.innerWidth < 842)
    const [mobile3, Setmonile3] = useState(window.innerWidth < 318)
    const [mobile4, Setmonile4] = useState(window.innerWidth < 600)
    const [mobile11, SetMobile11] = useState(window.innerWidth < 501)

    useEffect(() => {
        const hundlesSize = () => {
            Setmonile(window.innerWidth < 1575)
            Setmonile2(window.innerWidth < 842)
            Setmonile3(window.innerWidth < 318)
            Setmonile4(window.innerWidth < 600)
            SetMobile11(window.innerWidth < 501)
        }
        window.addEventListener('resize', hundlesSize)
        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])

    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });


    return (

        <div className={`bg-white  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
            <div className="sm:p-4 p-1 min-h-screen">
                <div classNameName="sm:p-4 p-1 ">
                    <div className="my-2">
                        <div className="w-full flex flex-row items-center justify-between">
                            <p className={`text-navy-700 ${mobile4 ? 'text-[18px]' : 'text-[25px]'}   text-gray-600`}>
                                <p className="hover:text-navy-700 font-bold max-sm:text-center text-gray-600 font-serif"                                >
                                    Liste des horaire des activites
                                </p>
                            </p>
                        </div>
                    </div>
                </div>
                <div className={`my-2 w-full  gap-2 p-1 sm:p-2 `}>
                    {activiesHoraires.map((data, posi) => {
                        return (
                            <div key={posi} className={`bg-white shadow-xl border w-full mt-10 border-fuchsia-400 rounded-lg p-4 `}>
                                <div className="flex   flex-col">
                                    <div className={`leading-none w-full text-left border-b-2 border-white pb-4 font-bold text-[#00000086] ${mobile4 ? 'text-[15px]' : 'text-[20px]   '}`}>
                                        {data.jour},Le {data.date}
                                    </div>
                                    <div className={`text-[18px] flex justify-between  w-full text-center mt-4 font-normal text-gray-500 ${mobile3 ? 'flex-col' : ''}`}>
                                        <div className={`p-2 w-full border border-fuchsia-200 rounded-lg ${mobile3 ? 'mb-3' : ''} `}>
                                            <table className="w-full">
                                                <thead>
                                                    <tr className="border-b border-gray-200">
                                                        <th className={`text-left ${mobile4 ? 'text-[10px]' : ''} `}>Heures</th>
                                                        <th></th>
                                                        <th className={`text-left ${mobile4 ? 'text-[10px]' : ''}`}>Activites prévues</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="text-gray-400">
                                                    {data.heures.map((datas, posi) => (
                                                        <tr key={posi} className="mt-3 text-left border-b border-gray-200">
                                                            <td className={`p-2 ${mobile4 ? 'text-[10px]' : ''}`}>{datas.plage}</td>
                                                            <td className="">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left-right text-fuchsia-600 mx-2" viewBox="0 0 16 16">
                                                                    <path fill-rule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5m14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5" />
                                                                </svg>
                                                            </td>
                                                            <td className={`p-2 ${mobile4 ? 'text-[10px]' : ''}`}>{datas.activites}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>

                                    <div className={`flex mt-2 text-gray-600 ${mobile4 ? 'text-[10px]' : ''}`}><div>Année scolaire :</div> <div className="text-blue-700 font-semibold ml-1"> 2023-2024</div></div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            <FootterCopy />
        </div>


    );
}

export default EncadrementHoraire;

