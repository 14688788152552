import React from "react";
import SideBar from "../SideBarSecretaire/SideBarSecretaire";
import AjouterReponseSecretaire from "./AjouterReponseSecretaire";
function AjouterReponseSecretaireComponent({ getSizeMobille, getMenuMobille }) {
  return (
    <>
      <div class="overflow-hidden flex justify-between">
        <SideBar getMenuMobille={getMenuMobille} />
        <AjouterReponseSecretaire getSizeMobille={getSizeMobille} />
      </div>
    </>
  );
}

export default AjouterReponseSecretaireComponent;
