/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import FootterCopy from '../../FootterCopy/FootterCopy'
import { Link } from 'react-router-dom'


function ListeEvaluationAllParent({ getSizeMobille }) {
    const [mobile1, Setmonile1] = useState(window.innerWidth < 621)

    const [mobile11, SetMobile11] = useState(window.innerWidth < 501)

    useEffect(() => {
        const hundlesSize = () => {
            Setmonile1(window.innerWidth < 621)
            SetMobile11(window.innerWidth < 501)
        }

        window.addEventListener('resize', hundlesSize)

        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])


    return (

        <div className={`bg-white  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
            <Link to='/NoteAllElev/ListeCoursAll' className="ml-2 text-[18px] text-blue-500 hover:text-blue-800 underline">Retour</Link>

            <div className="sm:p-4 min-h-screen">
                <div class="sm:my-10 my-4">
                    <div class="w-full flex flex-row items-center justify-between">
                        <p class="text-navy-700 shrink  ">
                            <a
                                class="hover:text-navy-700 pl-3 sm:text-2xl text-[20px] font-bold "

                            >
                                Les pointes
                            </a>
                        </p>

                    </div>
                </div>

                <div class="w-full my-4 grid grid-cols-1 ">
                    <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">
                        <div class="mb-4 flex items-center justify-between">
                            <div>
                                <h3 class="sm:text-xl font-bold text-[18px] w-full max-sm:text-center text-gray-600 mb-2">
                                    Listes des pointes du I trimestre 8<sup className='text-sm underline'>em</sup> année
                                </h3>
                            </div>

                        </div>

                        <div class="flex flex-col mt-8">
                            <div class="overflow-x-auto rounded-lg">
                                <div class="align-middle inline-block min-w-full">
                                    <div class="shadow overflow-hidden sm:rounded-lg">
                                        <table class="min-w-full divide-y divide-gray-200">
                                            <thead class="bg-gray-50">
                                                <tr>
                                                    <th
                                                        scope="col"
                                                        class=" text-left pt-4  relative overflow-hidden font-semibold text-gray-500 border-r border-gray-200 "
                                                    >
                                                        <div className='rotate-[20deg] w-full  text-end'>Type d'évaluation</div>
                                                        <div className='rotate-[20deg] w-full h-[1px] bg-gray-700'></div>
                                                        <div className='rotate-[5deg] '>Nom d'élève</div>

                                                    </th>
                                                    <th
                                                        scope="col"
                                                        class=" text-left p-4 text-lg relative overflow-hidden font-semibold text-gray-500 border-r border-gray-200 "
                                                    >
                                                        Devoir1

                                                    </th>
                                                    <th
                                                        scope="col"
                                                        class="p-4 text-left text-lg font-semibold text-gray-500 border-r border-gray-200 tracking-wider"
                                                    >
                                                        Devoir 2

                                                    </th>
                                                    <th
                                                        scope="col"
                                                        class="p-4 text-left text-lg font-semibold text-gray-500 border-r border-gray-200 tracking-wider"
                                                    >
                                                        Devoir 3
                                                    </th>

                                                    <th
                                                        scope="col"
                                                        class="p-4 text-left text-lg font-semibold text-gray-500 border-r border-gray-200 tracking-wider"
                                                    >
                                                        Interr 1
                                                    </th>


                                                    <th
                                                        scope="col"
                                                        class="p-4 text-left text-lg font-semibold text-gray-500 border-r border-gray-200 tracking-wider"
                                                    >
                                                        Interr 2
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        class="p-4 text-left text-lg font-semibold text-gray-500 border-r border-gray-200 tracking-wider"
                                                    >
                                                        Examen
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody class="bg-white">

                                                <tr className='border-b'>
                                                    <td class="border-l border-gray-200 p-4 whitespace-nowrap text-lg font-normal text-gray-500">
                                                        MINANI Jean
                                                    </td>
                                                    <td class="border-l border-gray-200 p-4 whitespace-nowrap text-lg font-normal text-gray-500">
                                                        10/20 points
                                                    </td>

                                                    <td class="border-l border-gray-200 p-4 whitespace-nowrap text-lg  text-gray-500">
                                                        05/20 points
                                                    </td>
                                                    <td class="border-l border-gray-200 p-4 whitespace-nowrap text-lg  text-gray-500">
                                                        7/20 points
                                                    </td>
                                                    <td class="border-l border-gray-200 p-4 whitespace-nowrap text-lg  text-gray-500">
                                                        19/20 points
                                                    </td>

                                                    <td class="border-l border-gray-200 p-4 whitespace-nowrap text-lg font-normal text-gray-500">
                                                        0/20 points
                                                    </td>
                                                    <td class="border-l border-gray-200 p-4 whitespace-nowrap text-lg font-normal text-gray-500">
                                                        21.8/45 points
                                                    </td>

                                                </tr>
                                                <tr className='border-b'>
                                                    <td class="border-l border-gray-200 p-4 whitespace-nowrap text-lg font-normal text-gray-500">
                                                        MINANI Jean
                                                    </td>
                                                    <td class="border-l border-gray-200 p-4 whitespace-nowrap text-lg font-normal text-gray-500">
                                                        10/20 points
                                                    </td>

                                                    <td class="border-l border-gray-200 p-4 whitespace-nowrap text-lg  text-gray-500">
                                                        05/20 points
                                                    </td>
                                                    <td class="border-l border-gray-200 p-4 whitespace-nowrap text-lg  text-gray-500">
                                                        7/20 points
                                                    </td>
                                                    <td class="border-l border-gray-200 p-4 whitespace-nowrap text-lg  text-gray-500">
                                                        19/20 points
                                                    </td>

                                                    <td class="border-l border-gray-200 p-4 whitespace-nowrap text-lg font-normal text-gray-500">
                                                        0/20 points
                                                    </td>
                                                    <td class="border-l border-gray-200 p-4 whitespace-nowrap text-lg font-normal text-gray-500">
                                                        21.8/45 points
                                                    </td>

                                                </tr>  <tr className='border-b'>
                                                    <td class="border-l border-gray-200 p-4 whitespace-nowrap text-lg font-normal text-gray-500">
                                                        MINANI Jean
                                                    </td>
                                                    <td class="border-l border-gray-200 p-4 whitespace-nowrap text-lg font-normal text-gray-500">
                                                        10/20 points
                                                    </td>

                                                    <td class="border-l border-gray-200 p-4 whitespace-nowrap text-lg  text-gray-500">
                                                        05/20 points
                                                    </td>
                                                    <td class="border-l border-gray-200 p-4 whitespace-nowrap text-lg  text-gray-500">
                                                        7/20 points
                                                    </td>
                                                    <td class="border-l border-gray-200 p-4 whitespace-nowrap text-lg  text-gray-500">
                                                        19/20 points
                                                    </td>

                                                    <td class="border-l border-gray-200 p-4 whitespace-nowrap text-lg font-normal text-gray-500">
                                                        0/20 points
                                                    </td>
                                                    <td class="border-l border-gray-200 p-4 whitespace-nowrap text-lg font-normal text-gray-500">
                                                        21.8/45 points
                                                    </td>

                                                </tr>  <tr className='border-b'>
                                                    <td class="border-l border-gray-200 p-4 whitespace-nowrap text-lg font-normal text-gray-500">
                                                        MINANI Jean
                                                    </td>
                                                    <td class="border-l border-gray-200 p-4 whitespace-nowrap text-lg font-normal text-gray-500">
                                                        10/20 points
                                                    </td>

                                                    <td class="border-l border-gray-200 p-4 whitespace-nowrap text-lg  text-gray-500">
                                                        05/20 points
                                                    </td>
                                                    <td class="border-l border-gray-200 p-4 whitespace-nowrap text-lg  text-gray-500">
                                                        7/20 points
                                                    </td>
                                                    <td class="border-l border-gray-200 p-4 whitespace-nowrap text-lg  text-gray-500">
                                                        19/20 points
                                                    </td>

                                                    <td class="border-l border-gray-200 p-4 whitespace-nowrap text-lg font-normal text-gray-500">
                                                        0/20 points
                                                    </td>
                                                    <td class="border-l border-gray-200 p-4 whitespace-nowrap text-lg font-normal text-gray-500">
                                                        21.8/45 points
                                                    </td>

                                                </tr>  <tr className='border-b'>
                                                    <td class="border-l border-gray-200 p-4 whitespace-nowrap text-lg font-normal text-gray-500">
                                                        MINANI Jean
                                                    </td>
                                                    <td class="border-l border-gray-200 p-4 whitespace-nowrap text-lg font-normal text-gray-500">
                                                        10/20 points
                                                    </td>

                                                    <td class="border-l border-gray-200 p-4 whitespace-nowrap text-lg  text-gray-500">
                                                        05/20 points
                                                    </td>
                                                    <td class="border-l border-gray-200 p-4 whitespace-nowrap text-lg  text-gray-500">
                                                        7/20 points
                                                    </td>
                                                    <td class="border-l border-gray-200 p-4 whitespace-nowrap text-lg  text-gray-500">
                                                        19/20 points
                                                    </td>

                                                    <td class="border-l border-gray-200 p-4 whitespace-nowrap text-lg font-normal text-gray-500">
                                                        0/20 points
                                                    </td>
                                                    <td class="border-l border-gray-200 p-4 whitespace-nowrap text-lg font-normal text-gray-500">
                                                        21.8/45 points
                                                    </td>

                                                </tr>  <tr className='border-b'>
                                                    <td class="border-l border-gray-200 p-4 whitespace-nowrap text-lg font-normal text-gray-500">
                                                        MINANI Jean
                                                    </td>
                                                    <td class="border-l border-gray-200 p-4 whitespace-nowrap text-lg font-normal text-gray-500">
                                                        10/20 points
                                                    </td>

                                                    <td class="border-l border-gray-200 p-4 whitespace-nowrap text-lg  text-gray-500">
                                                        05/20 points
                                                    </td>
                                                    <td class="border-l border-gray-200 p-4 whitespace-nowrap text-lg  text-gray-500">
                                                        7/20 points
                                                    </td>
                                                    <td class="border-l border-gray-200 p-4 whitespace-nowrap text-lg  text-gray-500">
                                                        19/20 points
                                                    </td>

                                                    <td class="border-l border-gray-200 p-4 whitespace-nowrap text-lg font-normal text-gray-500">
                                                        0/20 points
                                                    </td>
                                                    <td class="border-l border-gray-200 p-4 whitespace-nowrap text-lg font-normal text-gray-500">
                                                        21.8/45 points
                                                    </td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <FootterCopy></FootterCopy>
        </div>

    )
}

export default ListeEvaluationAllParent
