/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import FootterCopy from '../../FootterCopy/FootterCopy'
import { decryptData } from '../../../encryptionModule'
import { API } from '../../../Api/API'
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
function ResumesBibliothequeDetail({ getSizeMobille }) {
    const { id, num } = useParams()
    const [mobile1, Setmonile1] = useState(window.innerWidth < 349)
    const [zoom, SetZoom] = useState(false)
    const [mobile11, SetMobile11] = useState(window.innerWidth < 501)

    useEffect(() => {
        const hundlesSize = () => {
            Setmonile1(window.innerWidth < 349)
            SetMobile11(window.innerWidth < 501)
        }

        window.addEventListener('resize', hundlesSize)

        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])

    const resId = decryptData(id)

    const [res, setRes] = useState({})
    useEffect(() => {
        API.get(`/livres/1resume/${resId}/${num}`).then((rep) => {
            console.log(rep.data)
            setRes(rep.data)
        }).catch((err) => {
            console.log(err.message)
        })
    }, [id])
    const formatDate = date => {
        return format(new Date(date), "'Le 'd, MMMM yyyy", { locale: fr });
    };
    return (

        <div className={`bg-white  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
            <Link to='/Resumes' className="ml-2 text-[18px] text-blue-500 hover:text-blue-800 underline">Retour</Link>

            <div className="sm:p-4 p-2 min-h-screen">
                <div className='text-2xl font-bold uppercase'>Détail</div>
                <div class=" w-full   rounded-lg ">

                    <div class="flex  justify-center space-y-3 gap-4 mt-8">

                        <div class="flex  rounded-lg border border-gray-400 bg-white shadow-lg  w-[90%]">
                            <div class="flex flex-col justify-start w-full p-6">
                                <h5 class="mb-2 text-xl font-medium  ">
                                    Nom complet: {res.Nom_Emprunteur}
                                </h5>
                                <p class="mb-4 text-xl text-neutral-600 ">
                                    Fontion: {res.Fonction_Emprunteur}
                                </p>
                                <p class="mb-4 text-xl text-neutral-600 ">
                                    Titre du livre: {res.Titre_du_livre}
                                </p>
                                <p class="mb-4 text-xl text-neutral-600 ">
                                    Numero du livre: {res.Numéro_du_livre}
                                </p>

                                <p class="mb-4 text-xl text-neutral-600 ">
                                    Numero du résume: {res.Numéro_du_résumé}
                                </p>
                                <p class="mb-4 text-xl text-neutral-600 ">
                                    Date <span className='max-sm:hidden'>d'enregistrement</span>:{res.Date_d_enregistrement && formatDate(res.Date_d_enregistrement)}
                                </p>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <FootterCopy />
        </div>
    )
}

export default ResumesBibliothequeDetail
