/* eslint-disable no-fallthrough */

const UnitialState = {
  Carts: [],
};
export const CartsReducer = (State = UnitialState, action) => {
  switch (action.type) {
    case "Add_Cart":
      const { items } = action.payload;
      const itemesIndex = State.Carts.findIndex((item) => item.id === items.id);

      if (itemesIndex >= 0) {
        State.Carts[itemesIndex].qty += 1;
      } else {
        const temp = { ...items, qty: 1 };
        return {
          ...State,
          Carts: [...State.Carts, temp],
        };
      }


    case "Remove_Cart":
      const data = State.Carts.filter((el) => el.id !== action.payload);
      return {
        ...State,
        Carts: data,
      };

    case "Remove_One":
      const itemes_On_Index = State.Carts.findIndex(
        (ev) => ev.id === action.payload.id
      );

      if (State.Carts[itemes_On_Index].qty >= 1) {
        State.Carts[itemes_On_Index].qty--;
        return {
          ...State,
          Carts: [...State.Carts],
        };
      } else if (State.Carts[itemes_On_Index].qty === 0) {
        const data = State.Carts.filter((el) => el.id !== action.payload);

        return {
          ...State,
          Carts: [...State.Carts, data],
        };
      }


    case "Clear_Cart":
      return {
        ...State,
        Carts: [],
      };

    default:
      return State;

  }

};




















