import React from 'react'
import RetourLivresAutrePerson from './RetourLivresAutrePerson'
import SideBarBibliote from '../SideBar/SideBarBibliote'
function RetourLivresAutrePersonComponent({SetResumeQui, SetEmpruntId, LivreReturn, setLivreReturn,SetResume, getSizeMobille, getMenuMobille }) {
    return (
        <div className='flex justify-between overflow-hidden bg-white'>
            <SideBarBibliote getMenuMobille={getMenuMobille} />
            <RetourLivresAutrePerson SetResumeQui={SetResumeQui} SetEmpruntId={SetEmpruntId} LivreReturn={LivreReturn} setLivreReturn={setLivreReturn} getSizeMobille={getSizeMobille} SetResume={SetResume} />
        </div>
    )
}

export default RetourLivresAutrePersonComponent
