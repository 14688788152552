import React from 'react'
import SideBarSecretaire from '../SideBarSecretaire/SideBarSecretaire'
import DetteEconomDetail from './DetteEconomDetail'
function DetteEconomDetailComponent({ getSizeMobille, getMenuMobille }) {
    return (
        <div className='flex justify-between overflow-hidden'>
            <SideBarSecretaire getMenuMobille={getMenuMobille} />
            <DetteEconomDetail getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default DetteEconomDetailComponent
