import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const getYears = () => {
    const currentYear = new Date().getFullYear();
    const startYear = 1910;
    const years = [];

    for (let year = currentYear; year >= startYear; year--) {
        years.push(year);
    }
    return years;
};

function DetteBibliotheque({ getSizeMobille }) {
    const [searchText, setSearchText] = useState('');
    const years = getYears();

    const filteredYears = years.filter((year) => {
        const yearRange = `${year}-${year + 1}`;
        return yearRange.includes(searchText);
    });

    return (
        <div className={`bg-white  overflow-x-auto  h-[85vh] ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
            <div className="p-8 w-full">
                <h1 className="text-2xl font-semibold mb-4">Années Scolaires</h1>
                <input
                    type="text"
                    placeholder="Rechercher une année scolaire"
                    className="p-2 border w-[15em] rounded mb-4"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                />
                {filteredYears.length === 0 ? (
                    <p className='text-[25px] w-full text-center text-gray-500'>Aucune année scolaire correspondante trouvée.</p>
                ) : (

                    <ul>
                        {filteredYears.map((year) => (
                            <li
                                key={year}
                                className="border-b p-2 flex justify-between items-center"
                            >
                                <span>{year}-{year + 1}</span>
                                <Link to='/DetteBibli/ChoixClasseDetteBibli' className="bg-blue-500 text-white px-2 py-1 rounded">
                                    Voir Dossiers
                                </Link>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
}

export default DetteBibliotheque;
