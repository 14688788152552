/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import Chart from "react-apexcharts";
import "apexcharts/dist/apexcharts.css";
import FootterCopy from '../../FootterCopy/FootterCopy';
import { Link } from 'react-router-dom';
function ListeEvaluationParent({ getSizeMobille }) {
    const options = {
        chart: {
            id: "basic-bar",
        },
        xaxis: {
            categories: [
                "Devoir1",
                "Devoir2",
                "Devoir3",
                "Intérogation1",
                "Intérogation1",
                "Intérogation1",
                "Examen",
            ],
        },
    };

    const series = [
        {
            name: "Point obtenues",
            data: [45, 40, 36, 30, 20, 16, 12],
        },
    ]
    const [mobile1, Setmonile1] = useState(window.innerWidth < 621)

    const [mobile11, SetMobile11] = useState(window.innerWidth < 501)

    useEffect(() => {
        const hundlesSize = () => {
            Setmonile1(window.innerWidth < 621)
            SetMobile11(window.innerWidth < 501)
        }

        window.addEventListener('resize', hundlesSize)

        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])


    return (

        <div className={`bg-white  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
            <Link to='/NoteEnt/ListeCours' className="ml-2 text-[18px] text-blue-500 hover:text-blue-800 underline">Retour</Link>

            <div className="p-4   ">
                <div class="sm:my-10 my-4 ">
                    <div class="w-full flex flex-row items-center justify-between">
                        <p class="text-navy-700 shrink  ">
                            <p
                                class="hover:text-navy-700 text-[20px] sm:text-2xl font-bold "

                            >                                Les pointes
                            </p>
                        </p>

                    </div>
                </div>

                <div class="w-full my-4 grid grid-cols-1 ">
                    <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">
                        <div class="mb-4 flex items-center justify-between">
                            <div className='w-full'>
                                <h3 class="sm:text-xl text-[19px] w-full text-center font-bold text-gray-600 mb-2">
                                    Listes des pointes du I trimestre 8<sup className='text-sm underline'>em</sup> année
                                </h3>
                            </div>

                        </div>

                        <div class="flex flex-col mt-8">
                            <div class="overflow-x-auto rounded-lg">
                                <div class="align-middle inline-block min-w-full">
                                    <div class="shadow overflow-hidden sm:rounded-lg">
                                        <table class="min-w-full divide-y divide-gray-200">
                                            <thead class="bg-gray-50">
                                                <tr>
                                                    <th
                                                        scope="col"
                                                        class=" text-left p-4 text-lg relative overflow-hidden font-semibold text-gray-500 border-r border-gray-200 "
                                                    >
                                                        Devoir1

                                                    </th>
                                                    <th
                                                        scope="col"
                                                        class="p-4 text-left text-lg font-semibold text-gray-500 border-r border-gray-200 tracking-wider"
                                                    >
                                                        Devoir 2

                                                    </th>
                                                    <th
                                                        scope="col"
                                                        class="p-4 text-left text-lg font-semibold text-gray-500 border-r border-gray-200 tracking-wider"
                                                    >
                                                        Devoir 3
                                                    </th>

                                                    <th
                                                        scope="col"
                                                        class="p-4 text-left text-lg font-semibold text-gray-500 border-r border-gray-200 tracking-wider"
                                                    >
                                                        Interr 1
                                                    </th>


                                                    <th
                                                        scope="col"
                                                        class="p-4 text-left text-lg font-semibold text-gray-500 border-r border-gray-200 tracking-wider"
                                                    >
                                                        Interr 2
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        class="p-4 text-left text-lg font-semibold text-gray-500 border-r border-gray-200 tracking-wider"
                                                    >
                                                        Examen
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody class="bg-white">

                                                <tr className='border-b'>
                                                    <td class="border-l border-gray-200 p-4 whitespace-nowrap text-lg font-normal text-gray-500">
                                                        10/20 points
                                                    </td>

                                                    <td class="border-l border-gray-200 p-4 whitespace-nowrap text-lg  text-gray-500">
                                                        05/20 points
                                                    </td>
                                                    <td class="border-l border-gray-200 p-4 whitespace-nowrap text-lg  text-gray-500">
                                                        7/20 points
                                                    </td>
                                                    <td class="border-l border-gray-200 p-4 whitespace-nowrap text-lg  text-gray-500">
                                                        19/20 points
                                                    </td>

                                                    <td class="border-l border-gray-200 p-4 whitespace-nowrap text-lg font-normal text-gray-500">
                                                        0/20 points
                                                    </td>
                                                    <td class="border-l border-gray-200 p-4 whitespace-nowrap text-lg font-normal text-gray-500">
                                                        21.8/45 points
                                                    </td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <>

                <div

                    class="h-full w-full bg-gray-50 relative overflow-y-auto "
                >
                    <div class="px-4  pt-2">
                        <div class="w-full grid grid-cols-1 gap-4">
                            <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 2xl:col-span-2">
                                <div class="flex items-center justify-between mb-4">
                                    <div class="sm:flex-shrink-0">
                                        <h3 class="text-xl max-sm:text-center text-gray-600 sm:text-2xl leading-none font-bold ">
                                            Evolution de  pointes du I trimestre

                                        </h3>
                                    </div>

                                </div>
                                <div id="main-chart" style={{ "min-height": "435px" }}>
                                    <Chart
                                        options={options}
                                        series={series}
                                        type="line"
                                        height={500}
                                    />
                                </div>
                            </div>

                        </div>


                    </div>


                </div>{" "}
            </>
            <FootterCopy />
        </div>

    )
}

export default ListeEvaluationParent
