import React, { useState } from "react";
import ClasseurComponent from "../../component/Secretariat/Classeurs/ClasseurComponent";
function ClasseurPage({ getSizeMobille, getMenuMobille }) {
  return (
    <>
      <ClasseurComponent getSizeMobille={getSizeMobille} getMenuMobille={getMenuMobille} />
    </>
  );
}

export default ClasseurPage;
