import React, { useState } from "react";
import DetailLivreComponent from "../../component/Bibliotheque/DetailLivre/DetailLivreComponent";
function DetailLivrePage({ SetExemple,exempalire, getSizeMobille, getMenuMobille,LivreId,SetLivreId }) {
  return (
    <>
      <DetailLivreComponent exempalire={exempalire} SetExemple={SetExemple} getMenuMobille={getMenuMobille} getSizeMobille={getSizeMobille} LivreId={LivreId} SetLivreId={SetLivreId} />
    </>
  );
}

export default DetailLivrePage;
