/* eslint-disable eqeqeq */

import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
function SlideNavInfermie({ getMenuMobille }) {
  const { pathname } = useLocation();
  let Accueil = /^\/Accueil.*$/;
  let Medicament = /^\/Medicament.*$/;
  let Expires = /^\/Expires.*$/;
  let Consultation = /^\/Consultation.*$/;
  let Laboratoire = /^\/Laboratoire.*$/;
  let Traitement = /^\/Traitement.*$/;
  let Patient = /^\/Patient.*$/;
  let Harite = /^\/Harite.*$/;
  let DepensesInf = /^\/Depense.*$/;
  let PrevisionInf = /^\/Prevision.*$/;
  let Conge = /^\/Conge.*$/;


  const [mobile, SetMobile] = useState(window.innerWidth < 1292)
  const [mobile11, SetMobile11] = useState(window.innerWidth < 501)
  useEffect(() => {
    const hundleSize = () => {
      SetMobile(window.innerWidth < 1292)
      SetMobile11(window.innerWidth < 501)
    }

    window.addEventListener('resize', hundleSize)

    return () => window.removeEventListener('resize', hundleSize)
  }, [])


  return (
    <>
      <div className={`bg-white   border-r-2 border-blue-800 w-[10vw]  overflow-y-auto overflow-x-hidden min-w-[13em] ${mobile ? 'fixed  z-[100]' : ''} ${getMenuMobille ? 'left-0' : '-left-full'} transition-all duration-300 ${mobile11 ? 'top-[10vh] h-[90vh]' : 'top-[15vh] h-[85vh]'} `}>
        <div className="p-1 mb-5 border-b border-white">
          <div class="flex-1 flex flex-col bg-white pt-5 pb-4 overflow-y-auto">
            <div class="flex-1 px-1  divide-y space-y-1">
              <ul class="space-y-2 pb-2">
                {Accueil.test(pathname) || pathname === "/" ? (
                  <Link to="/">
                    <li>
                      <div class="text-xl font-normal rounded bg-blue-700 text-white  flex items-center p-2 group">
                        <svg
                          class="w-6 h-6 text-white transition duration-75"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path>
                          <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path>
                        </svg>

                        <span class="ml-3 flex-1 whitespace-nowrap">
                          Accueil
                        </span>

                      </div>
                    </li>
                  </Link>
                ) : (
                  <Link to="/">
                    <li>
                      <div class="text-xl  font-normal rounded hover:text-gray-400 flex items-center p-2 group">
                        <svg
                          class="w-6 h-6  transition duration-75"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path>
                          <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path>
                        </svg>

                        <span class="ml-3 flex-1 whitespace-nowrap">
                          Accueil
                        </span>

                      </div>
                    </li>
                  </Link>
                )}


                {pathname == "/Presence" ? (
                  <Link to="/">
                    <li>
                      <div class="text-xl font-normal rounded bg-blue-700 text-white  flex items-center p-2 group">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-arms-up h-6 w-6" viewBox="0 0 16 16">
                          <path d="M8 3a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z" />
                          <path d="m5.93 6.704-.846 8.451a.768.768 0 0 0 1.523.203l.81-4.865a.59.59 0 0 1 1.165 0l.81 4.865a.768.768 0 0 0 1.523-.203l-.845-8.451A1.492 1.492 0 0 1 10.5 5.5L13 2.284a.796.796 0 0 0-1.239-.998L9.634 3.84a.72.72 0 0 1-.33.235c-.23.074-.665.176-1.304.176-.64 0-1.074-.102-1.305-.176a.72.72 0 0 1-.329-.235L4.239 1.286a.796.796 0 0 0-1.24.998l2.5 3.216c.317.316.475.758.43 1.204Z" />
                        </svg>

                        <span class="ml-3 flex-1 whitespace-nowrap">
                          Presence
                        </span>

                      </div>
                    </li>
                  </Link>
                ) : (
                  <Link to="/Presence">
                    <li>
                      <div class="text-xl  font-normal rounded hover:text-gray-400 flex items-center p-2 group">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-arms-up h-6 w-6" viewBox="0 0 16 16">
                          <path d="M8 3a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z" />
                          <path d="m5.93 6.704-.846 8.451a.768.768 0 0 0 1.523.203l.81-4.865a.59.59 0 0 1 1.165 0l.81 4.865a.768.768 0 0 0 1.523-.203l-.845-8.451A1.492 1.492 0 0 1 10.5 5.5L13 2.284a.796.796 0 0 0-1.239-.998L9.634 3.84a.72.72 0 0 1-.33.235c-.23.074-.665.176-1.304.176-.64 0-1.074-.102-1.305-.176a.72.72 0 0 1-.329-.235L4.239 1.286a.796.796 0 0 0-1.24.998l2.5 3.216c.317.316.475.758.43 1.204Z" />
                        </svg>
                        <span class="ml-3 flex-1 whitespace-nowrap">
                          Presence
                        </span>

                      </div>
                    </li>
                  </Link>
                )}


                {Medicament.test(pathname) ? (
                  <Link to="/Medicament">
                    <li>
                      <div
                        target="_blank"
                        class="text-xl font-normal rounded bg-blue-700 text-white  flex items-center p-2 group"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6 text-white flex-shrink-0  transition duration-75"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25M9 16.5v.75m3-3v3M15 12v5.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                          />
                        </svg>

                        <span class="ml-3 flex-1 whitespace-nowrap">
                          Medicament
                        </span>

                      </div>
                    </li>
                  </Link>
                ) : (
                  <Link to="/Medicament">
                    <li>
                      <div
                        target="_blank"
                        class="text-xl  font-normal rounded hover:text-gray-400 flex items-center p-2 group"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6  flex-shrink-0  transition duration-75"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25M9 16.5v.75m3-3v3M15 12v5.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                          />
                        </svg>



                        <span class="ml-3 flex-1 whitespace-nowrap">
                          Medicament
                        </span>

                      </div>
                    </li>
                  </Link>
                )}
                {Expires.test(pathname) ? (
                  <Link to="/Expires">
                    <li>
                      <div class="text-xl font-normal rounded bg-blue-700 text-white  flex items-center p-2 group">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-capsule-pill h-6 w-6" viewBox="0 0 16 16">
                          <path d="M11.02 5.364a3 3 0 0 0-4.242-4.243L1.121 6.778a3 3 0 1 0 4.243 4.243l5.657-5.657Zm-6.413-.657 2.878-2.879a2 2 0 1 1 2.829 2.829L7.435 7.536 4.607 4.707ZM12 8a4 4 0 1 1 0 8 4 4 0 0 1 0-8Zm-.5 1.042a3 3 0 0 0 0 5.917V9.042Zm1 5.917a3 3 0 0 0 0-5.917v5.917Z" />
                        </svg>

                        <span class="ml-3 flex-1 whitespace-nowrap">
                          Expire
                        </span>

                      </div>
                    </li>
                  </Link>
                ) : (
                  <Link to="/Expires">
                    <li>
                      <div class="text-xl  font-normal rounded hover:text-gray-400 flex items-center p-2 group">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-capsule-pill h-6 w-6" viewBox="0 0 16 16">
                          <path d="M11.02 5.364a3 3 0 0 0-4.242-4.243L1.121 6.778a3 3 0 1 0 4.243 4.243l5.657-5.657Zm-6.413-.657 2.878-2.879a2 2 0 1 1 2.829 2.829L7.435 7.536 4.607 4.707ZM12 8a4 4 0 1 1 0 8 4 4 0 0 1 0-8Zm-.5 1.042a3 3 0 0 0 0 5.917V9.042Zm1 5.917a3 3 0 0 0 0-5.917v5.917Z" />
                        </svg>

                        <span class="ml-3 flex-1 whitespace-nowrap">
                          Expire
                        </span>

                      </div>
                    </li>
                  </Link>
                )}

                {Consultation.test(pathname) ? (
                  <Link to="/Consultation">
                    <li>
                      <div class="text-xl font-normal rounded bg-blue-700 text-white  flex items-center p-2 group">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6 text-white flex-shrink-0 transition duration-75"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25"
                          />
                        </svg>

                        <span class="ml-3 flex-1 whitespace-nowrap">
                          Consultation
                        </span>

                      </div>
                    </li>
                  </Link>
                ) : (
                  <Link to="/Consultation">
                    <li>
                      <div class="text-xl  font-normal rounded hover:text-gray-400 flex items-center p-2 group">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6  flex-shrink-0 transition duration-75"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25"
                          />
                        </svg>

                        <span class="ml-3 flex-1 whitespace-nowrap">
                          Consultation
                        </span>

                      </div>
                    </li>
                  </Link>
                )}
                {Laboratoire.test(pathname) ? (
                  <Link to="/Laboratoire">
                    <li>
                      <div class="text-xl font-normal rounded bg-blue-700 text-white  flex items-center p-2 group">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-virus h-6 w-6" viewBox="0 0 16 16">
                          <path d="M8 0a1 1 0 0 1 1 1v1.402c0 .511.677.693.933.25l.7-1.214a1 1 0 0 1 1.733 1l-.701 1.214c-.256.443.24.939.683.683l1.214-.701a1 1 0 0 1 1 1.732l-1.214.701c-.443.256-.262.933.25.933H15a1 1 0 1 1 0 2h-1.402c-.512 0-.693.677-.25.933l1.214.701a1 1 0 1 1-1 1.732l-1.214-.7c-.443-.257-.939.24-.683.682l.701 1.214a1 1 0 1 1-1.732 1l-.701-1.214c-.256-.443-.933-.262-.933.25V15a1 1 0 1 1-2 0v-1.402c0-.512-.677-.693-.933-.25l-.701 1.214a1 1 0 0 1-1.732-1l.7-1.214c.257-.443-.24-.939-.682-.683l-1.214.701a1 1 0 1 1-1-1.732l1.214-.701c.443-.256.261-.933-.25-.933H1a1 1 0 1 1 0-2h1.402c.511 0 .693-.677.25-.933l-1.214-.701a1 1 0 1 1 1-1.732l1.214.701c.443.256.939-.24.683-.683l-.701-1.214a1 1 0 0 1 1.732-1l.701 1.214c.256.443.933.261.933-.25V1a1 1 0 0 1 1-1Zm2 5a1 1 0 1 0-2 0 1 1 0 0 0 2 0ZM6 7a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm1 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm5-3a1 1 0 1 0-2 0 1 1 0 0 0 2 0Z" />
                        </svg>

                        <span class="ml-3 flex-1 whitespace-nowrap">
                          Laboratoire
                        </span>

                      </div>
                    </li>
                  </Link>
                ) : (
                  <Link to="/Laboratoire">
                    <li>
                      <div class="text-xl  font-normal rounded hover:text-gray-400 flex items-center p-2 group">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-virus h-6 w-6" viewBox="0 0 16 16">
                          <path d="M8 0a1 1 0 0 1 1 1v1.402c0 .511.677.693.933.25l.7-1.214a1 1 0 0 1 1.733 1l-.701 1.214c-.256.443.24.939.683.683l1.214-.701a1 1 0 0 1 1 1.732l-1.214.701c-.443.256-.262.933.25.933H15a1 1 0 1 1 0 2h-1.402c-.512 0-.693.677-.25.933l1.214.701a1 1 0 1 1-1 1.732l-1.214-.7c-.443-.257-.939.24-.683.682l.701 1.214a1 1 0 1 1-1.732 1l-.701-1.214c-.256-.443-.933-.262-.933.25V15a1 1 0 1 1-2 0v-1.402c0-.512-.677-.693-.933-.25l-.701 1.214a1 1 0 0 1-1.732-1l.7-1.214c.257-.443-.24-.939-.682-.683l-1.214.701a1 1 0 1 1-1-1.732l1.214-.701c.443-.256.261-.933-.25-.933H1a1 1 0 1 1 0-2h1.402c.511 0 .693-.677.25-.933l-1.214-.701a1 1 0 1 1 1-1.732l1.214.701c.443.256.939-.24.683-.683l-.701-1.214a1 1 0 0 1 1.732-1l.701 1.214c.256.443.933.261.933-.25V1a1 1 0 0 1 1-1Zm2 5a1 1 0 1 0-2 0 1 1 0 0 0 2 0ZM6 7a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm1 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm5-3a1 1 0 1 0-2 0 1 1 0 0 0 2 0Z" />
                        </svg>

                        <span class="ml-3 flex-1 whitespace-nowrap">
                          Laboratoire
                        </span>

                      </div>
                    </li>
                  </Link>
                )}
                {Traitement.test(pathname) ? (
                  <Link to="/Traitement">
                    <li>
                      <div class="text-xl font-normal rounded bg-blue-700 text-white  flex items-center p-2 group">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bandaid-fill h-6 w-6" viewBox="0 0 16 16">
                          <path d="m2.68 7.676 6.49-6.504a4 4 0 0 1 5.66 5.653l-1.477 1.529-5.006 5.006-1.523 1.472a4 4 0 0 1-5.653-5.66l.001-.002 1.505-1.492.001-.002Zm5.71-2.858a.5.5 0 1 0-.708.707.5.5 0 0 0 .707-.707ZM6.974 6.939a.5.5 0 1 0-.707-.707.5.5 0 0 0 .707.707ZM5.56 8.354a.5.5 0 1 0-.707-.708.5.5 0 0 0 .707.708Zm2.828 2.828a.5.5 0 1 0-.707-.707.5.5 0 0 0 .707.707Zm1.414-2.121a.5.5 0 1 0-.707.707.5.5 0 0 0 .707-.707Zm1.414-.707a.5.5 0 1 0-.706-.708.5.5 0 0 0 .707.708Zm-4.242.707a.5.5 0 1 0-.707.707.5.5 0 0 0 .707-.707Zm1.414-.707a.5.5 0 1 0-.707-.708.5.5 0 0 0 .707.708Zm1.414-2.122a.5.5 0 1 0-.707.707.5.5 0 0 0 .707-.707ZM8.646 3.354l4 4 .708-.708-4-4-.708.708Zm-1.292 9.292-4-4-.708.708 4 4 .708-.708Z" />
                        </svg>

                        <span class="ml-3 flex-1 whitespace-nowrap">
                          Traitement
                        </span>

                      </div>
                    </li>
                  </Link>
                ) : (
                  <Link to="/Traitement">
                    <li>
                      <div class="text-xl  font-normal rounded hover:text-gray-400 flex items-center p-2 group">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bandaid-fill h-6 w-6" viewBox="0 0 16 16">
                          <path d="m2.68 7.676 6.49-6.504a4 4 0 0 1 5.66 5.653l-1.477 1.529-5.006 5.006-1.523 1.472a4 4 0 0 1-5.653-5.66l.001-.002 1.505-1.492.001-.002Zm5.71-2.858a.5.5 0 1 0-.708.707.5.5 0 0 0 .707-.707ZM6.974 6.939a.5.5 0 1 0-.707-.707.5.5 0 0 0 .707.707ZM5.56 8.354a.5.5 0 1 0-.707-.708.5.5 0 0 0 .707.708Zm2.828 2.828a.5.5 0 1 0-.707-.707.5.5 0 0 0 .707.707Zm1.414-2.121a.5.5 0 1 0-.707.707.5.5 0 0 0 .707-.707Zm1.414-.707a.5.5 0 1 0-.706-.708.5.5 0 0 0 .707.708Zm-4.242.707a.5.5 0 1 0-.707.707.5.5 0 0 0 .707-.707Zm1.414-.707a.5.5 0 1 0-.707-.708.5.5 0 0 0 .707.708Zm1.414-2.122a.5.5 0 1 0-.707.707.5.5 0 0 0 .707-.707ZM8.646 3.354l4 4 .708-.708-4-4-.708.708Zm-1.292 9.292-4-4-.708.708 4 4 .708-.708Z" />
                        </svg>

                        <span class="ml-3 flex-1 whitespace-nowrap">
                          Traitement
                        </span>

                      </div>
                    </li>
                  </Link>
                )}

                {Patient.test(pathname) ? (
                  <Link to="/Patient">
                    <li>
                      <div class="text-xl font-normal rounded bg-blue-700 text-white  flex items-center p-2 group">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-emoji-tear h-6 w-6" viewBox="0 0 16 16">
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14Zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16Z" />
                          <path d="M6.831 11.43A3.1 3.1 0 0 1 8 11.196c.916 0 1.607.408 2.25.826.212.138.424-.069.282-.277-.564-.83-1.558-2.049-2.532-2.049-.53 0-1.066.361-1.536.824.083.179.162.36.232.535.045.115.092.241.135.373ZM6 11.333C6 12.253 5.328 13 4.5 13S3 12.254 3 11.333c0-.706.882-2.29 1.294-2.99a.238.238 0 0 1 .412 0c.412.7 1.294 2.284 1.294 2.99ZM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5Zm4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5Zm-1.5-3A.5.5 0 0 1 10 3c1.162 0 2.35.584 2.947 1.776a.5.5 0 1 1-.894.448C11.649 4.416 10.838 4 10 4a.5.5 0 0 1-.5-.5ZM7 3.5a.5.5 0 0 0-.5-.5c-1.162 0-2.35.584-2.947 1.776a.5.5 0 1 0 .894.448C4.851 4.416 5.662 4 6.5 4a.5.5 0 0 0 .5-.5Z" />
                        </svg>

                        <span class="ml-3 flex-1 whitespace-nowrap">
                          Patient
                        </span>

                      </div>
                    </li>
                  </Link>
                ) : (
                  <Link to="/Patient">
                    <li>
                      <div class="text-xl  font-normal rounded hover:text-gray-400 flex items-center p-2 group">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-emoji-tear h-6 w-6" viewBox="0 0 16 16">
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14Zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16Z" />
                          <path d="M6.831 11.43A3.1 3.1 0 0 1 8 11.196c.916 0 1.607.408 2.25.826.212.138.424-.069.282-.277-.564-.83-1.558-2.049-2.532-2.049-.53 0-1.066.361-1.536.824.083.179.162.36.232.535.045.115.092.241.135.373ZM6 11.333C6 12.253 5.328 13 4.5 13S3 12.254 3 11.333c0-.706.882-2.29 1.294-2.99a.238.238 0 0 1 .412 0c.412.7 1.294 2.284 1.294 2.99ZM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5Zm4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5Zm-1.5-3A.5.5 0 0 1 10 3c1.162 0 2.35.584 2.947 1.776a.5.5 0 1 1-.894.448C11.649 4.416 10.838 4 10 4a.5.5 0 0 1-.5-.5ZM7 3.5a.5.5 0 0 0-.5-.5c-1.162 0-2.35.584-2.947 1.776a.5.5 0 1 0 .894.448C4.851 4.416 5.662 4 6.5 4a.5.5 0 0 0 .5-.5Z" />
                        </svg>

                        <span class="ml-3 flex-1 whitespace-nowrap">
                          Patient
                        </span>

                      </div>
                    </li>
                  </Link>
                )}
                {Harite.test(pathname) ? (
                  <Link to="/Harite">
                    <li>
                      <div class="text-xl font-normal rounded bg-blue-700 text-white  flex items-center p-2 group">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-emoji-kiss-fill w-6 h-6" viewBox="0 0 16 16">
                          <path fill-rule="evenodd" d="M16 8a8 8 0 1 0-2.697 5.99c-.972-.665-1.632-1.356-1.99-2.062-.388-.766-.419-1.561-.075-2.23.496-.97 1.73-1.466 2.762-1.05.65-.262 1.38-.162 1.957.19Q16 8.425 16 8M7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5m1.512 3.647c-.347.08-.737.198-1.107.319a.5.5 0 1 1-.31-.95c.38-.125.802-.254 1.192-.343.37-.086.78-.153 1.103-.108.16.022.394.085.561.286.188.226.187.497.131.705a1.9 1.9 0 0 1-.31.593q-.115.16-.275.343.16.186.276.347c.142.197.256.397.31.595.055.208.056.479-.132.706-.168.2-.404.262-.563.284-.323.043-.733-.027-1.102-.113a15 15 0 0 1-1.191-.345.5.5 0 1 1 .31-.95c.371.12.761.24 1.109.321q.264.062.446.084a6 6 0 0 0-.502-.584.5.5 0 0 1 .002-.695 5.5 5.5 0 0 0 .5-.577 5 5 0 0 0-.448.082m.766-.086-.006-.002zm.002 1.867-.005.001.006-.002Zm.157-4.685a.5.5 0 0 1-.874-.486A1.93 1.93 0 0 1 10.25 5.75c.73 0 1.356.412 1.687 1.007a.5.5 0 1 1-.874.486.93.93 0 0 0-.813-.493.93.93 0 0 0-.813.493M14 9.828c1.11-1.14 3.884.856 0 3.422-3.884-2.566-1.11-4.562 0-3.421Z" />
                        </svg>

                        <span class="ml-3 flex-1 whitespace-nowrap">
                          Harite
                        </span>

                      </div>
                    </li>
                  </Link>
                ) : (
                  <Link to="/Harite">
                    <li>
                      <div class="text-xl  font-normal rounded hover:text-gray-400 flex items-center p-2 group">
                        <svg xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-emoji-kiss-fill w-6 h-6"
                          viewBox="0 0 16 16">
                          <path fill-rule="evenodd" d="M16 8a8 8 0 1 0-2.697 5.99c-.972-.665-1.632-1.356-1.99-2.062-.388-.766-.419-1.561-.075-2.23.496-.97 1.73-1.466 2.762-1.05.65-.262 1.38-.162 1.957.19Q16 8.425 16 8M7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5m1.512 3.647c-.347.08-.737.198-1.107.319a.5.5 0 1 1-.31-.95c.38-.125.802-.254 1.192-.343.37-.086.78-.153 1.103-.108.16.022.394.085.561.286.188.226.187.497.131.705a1.9 1.9 0 0 1-.31.593q-.115.16-.275.343.16.186.276.347c.142.197.256.397.31.595.055.208.056.479-.132.706-.168.2-.404.262-.563.284-.323.043-.733-.027-1.102-.113a15 15 0 0 1-1.191-.345.5.5 0 1 1 .31-.95c.371.12.761.24 1.109.321q.264.062.446.084a6 6 0 0 0-.502-.584.5.5 0 0 1 .002-.695 5.5 5.5 0 0 0 .5-.577 5 5 0 0 0-.448.082m.766-.086-.006-.002zm.002 1.867-.005.001.006-.002Zm.157-4.685a.5.5 0 0 1-.874-.486A1.93 1.93 0 0 1 10.25 5.75c.73 0 1.356.412 1.687 1.007a.5.5 0 1 1-.874.486.93.93 0 0 0-.813-.493.93.93 0 0 0-.813.493M14 9.828c1.11-1.14 3.884.856 0 3.422-3.884-2.566-1.11-4.562 0-3.421Z" />
                        </svg>

                        <span class="ml-3 flex-1 whitespace-nowrap">
                          Harite
                        </span>

                      </div>
                    </li>
                  </Link>
                )}
                {DepensesInf.test(pathname) ? (
                  <Link to="/Depense">
                    <li>
                      <div class="text-xl font-normal rounded bg-blue-700 text-white  flex items-center p-2 group">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6  flex-shrink-0 transition duration-75"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m6.75 12l-3-3m0 0l-3 3m3-3v6m-1.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                          />
                        </svg>

                        <span class="ml-3 flex-1 whitespace-nowrap">
                          Depenses
                        </span>

                      </div>
                    </li>
                  </Link>
                ) : (
                  <Link to="/Depense">
                    <li>
                      <a
                        href="#e-commerce/products/"
                        class="text-xl  font-normal rounded hover:text-gray-400 flex items-center p-2 group"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6 flex-shrink-0 transition duration-75"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m6.75 12l-3-3m0 0l-3 3m3-3v6m-1.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                          />
                        </svg>

                        <span class="ml-3 flex-1 whitespace-nowrap">
                          Depenses
                        </span>

                      </a>
                    </li>
                  </Link>
                )}





                {PrevisionInf.test(pathname) ? (
                  <Link to="/Prevision">
                    <li>
                      <div class="text-xl font-normal rounded bg-blue-700 text-white  flex items-center p-2 group">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="w-6 h-6 text-white flex-shrink-0 transition duration-75"
                          fill="currentColor"
                          viewBox="0 0 16 16"
                        >
                          <path d="M7.752.066a.5.5 0 0 1 .496 0l3.75 2.143a.5.5 0 0 1 .252.434v3.995l3.498 2A.5.5 0 0 1 16 9.07v4.286a.5.5 0 0 1-.252.434l-3.75 2.143a.5.5 0 0 1-.496 0l-3.502-2-3.502 2.001a.5.5 0 0 1-.496 0l-3.75-2.143A.5.5 0 0 1 0 13.357V9.071a.5.5 0 0 1 .252-.434L3.75 6.638V2.643a.5.5 0 0 1 .252-.434L7.752.066ZM4.25 7.504 1.508 9.071l2.742 1.567 2.742-1.567L4.25 7.504ZM7.5 9.933l-2.75 1.571v3.134l2.75-1.571V9.933Zm1 3.134 2.75 1.571v-3.134L8.5 9.933v3.134Zm.508-3.996 2.742 1.567 2.742-1.567-2.742-1.567-2.742 1.567Zm2.242-2.433V3.504L8.5 5.076V8.21l2.75-1.572ZM7.5 8.21V5.076L4.75 3.504v3.134L7.5 8.21ZM5.258 2.643 8 4.21l2.742-1.567L8 1.076 5.258 2.643ZM15 9.933l-2.75 1.571v3.134L15 13.067V9.933ZM3.75 14.638v-3.134L1 9.933v3.134l2.75 1.571Z" />
                        </svg>

                        <span class="ml-3 flex-1 whitespace-nowrap">
                          Prévision
                        </span>

                      </div>
                    </li>
                  </Link>
                ) : (
                  <Link to="/Prevision">
                    <li>
                      <div class="text-xl  font-normal rounded hover:text-gray-400 flex items-center p-2 group">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="w-6 h-6  flex-shrink-0 transition duration-75"
                          fill="currentColor"
                          viewBox="0 0 16 16"
                        >
                          <path d="M7.752.066a.5.5 0 0 1 .496 0l3.75 2.143a.5.5 0 0 1 .252.434v3.995l3.498 2A.5.5 0 0 1 16 9.07v4.286a.5.5 0 0 1-.252.434l-3.75 2.143a.5.5 0 0 1-.496 0l-3.502-2-3.502 2.001a.5.5 0 0 1-.496 0l-3.75-2.143A.5.5 0 0 1 0 13.357V9.071a.5.5 0 0 1 .252-.434L3.75 6.638V2.643a.5.5 0 0 1 .252-.434L7.752.066ZM4.25 7.504 1.508 9.071l2.742 1.567 2.742-1.567L4.25 7.504ZM7.5 9.933l-2.75 1.571v3.134l2.75-1.571V9.933Zm1 3.134 2.75 1.571v-3.134L8.5 9.933v3.134Zm.508-3.996 2.742 1.567 2.742-1.567-2.742-1.567-2.742 1.567Zm2.242-2.433V3.504L8.5 5.076V8.21l2.75-1.572ZM7.5 8.21V5.076L4.75 3.504v3.134L7.5 8.21ZM5.258 2.643 8 4.21l2.742-1.567L8 1.076 5.258 2.643ZM15 9.933l-2.75 1.571v3.134L15 13.067V9.933ZM3.75 14.638v-3.134L1 9.933v3.134l2.75 1.571Z" />
                        </svg>

                        <span class="ml-3 flex-1 whitespace-nowrap">
                          Prévision
                        </span>

                      </div>
                    </li>
                  </Link>
                )}
                {Conge.test(pathname) ? (
                  <Link to="/Conge">
                    <li>
                      <div class="text-xl font-normal rounded bg-blue-700 text-white  flex items-center p-2 group">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-raised-hand h-6 w-6" viewBox="0 0 16 16">
                          <path d="M6 6.207v9.043a.75.75 0 0 0 1.5 0V10.5a.5.5 0 0 1 1 0v4.75a.75.75 0 0 0 1.5 0v-8.5a.25.25 0 1 1 .5 0v2.5a.75.75 0 0 0 1.5 0V6.5a3 3 0 0 0-3-3H6.236a.998.998 0 0 1-.447-.106l-.33-.165A.83.83 0 0 1 5 2.488V.75a.75.75 0 0 0-1.5 0v2.083c0 .715.404 1.37 1.044 1.689L5.5 5c.32.32.5.754.5 1.207Z" />
                          <path d="M8 3a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z" />
                        </svg>
                        <span class="ml-3 flex-1 whitespace-nowrap">
                          Conge
                        </span>

                      </div>
                    </li>
                  </Link>
                ) : (
                  <Link to="/Conge">
                    <li>
                      <div class="text-xl  font-normal rounded hover:text-gray-400 flex items-center p-2 group">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-raised-hand h-6 w-6" viewBox="0 0 16 16">
                          <path d="M6 6.207v9.043a.75.75 0 0 0 1.5 0V10.5a.5.5 0 0 1 1 0v4.75a.75.75 0 0 0 1.5 0v-8.5a.25.25 0 1 1 .5 0v2.5a.75.75 0 0 0 1.5 0V6.5a3 3 0 0 0-3-3H6.236a.998.998 0 0 1-.447-.106l-.33-.165A.83.83 0 0 1 5 2.488V.75a.75.75 0 0 0-1.5 0v2.083c0 .715.404 1.37 1.044 1.689L5.5 5c.32.32.5.754.5 1.207Z" />
                          <path d="M8 3a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z" />
                        </svg>
                        <span class="ml-3 flex-1 whitespace-nowrap">
                          Conge
                        </span>
                      </div>
                    </li>
                  </Link>
                )}
              </ul>



              <a href="mailto:btr.dev@burundientempsreel.com" className=" cursor-pointer hover:text-gray-600 py-2 mb-4  flex pl-5 items-center">
                <div><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi mr-3 h-6 w-6 bi-question-diamond-fill" viewBox="0 0 16 16">
                  <path d="M9.05.435c-.58-.58-1.52-.58-2.1 0L.436 6.95c-.58.58-.58 1.519 0 2.098l6.516 6.516c.58.58 1.519.58 2.098 0l6.516-6.516c.58-.58.58-1.519 0-2.098L9.05.435zM5.495 6.033a.237.237 0 0 1-.24-.247C5.35 4.091 6.737 3.5 8.005 3.5c1.396 0 2.672.73 2.672 2.24 0 1.08-.635 1.594-1.244 2.057-.737.559-1.01.768-1.01 1.486v.105a.25.25 0 0 1-.25.25h-.81a.25.25 0 0 1-.25-.246l-.004-.217c-.038-.927.495-1.498 1.168-1.987.59-.444.965-.736.965-1.371 0-.825-.628-1.168-1.314-1.168-.803 0-1.253.478-1.342 1.134-.018.137-.128.25-.266.25h-.825zm2.325 6.443c-.584 0-1.009-.394-1.009-.927 0-.552.425-.94 1.01-.94.609 0 1.028.388 1.028.94 0 .533-.42.927-1.029.927z" />
                </svg></div>
                <div>
                  Aide
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SlideNavInfermie;

