import React from "react";
import { Link } from "react-router-dom";
import FootterCopy from "../../FootterCopy/FootterCopy";
function Emprunter({ getSizeMobille }) {
  return (
    <>
      <div className={`bg-white  overflow-x-auto  h-[85vh] ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>

        <div className="p-6  min-h-[85vh]">
          <div class="my-10 flex items-center justify-center">
            <h3 class="text-xl sm:text-2xl text-center leading-none font-bold text-gray-900">
              Quelles types de Courriers ?
            </h3>
          </div>

          <div class="my-4 w-full items-center flex flex-col gap-4">
            <Link
              to="/Courriers/ListeRecus"
              class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 cursor-pointer hover:bg-gray-100s hover:shadow-lg transition duration-300 ease-in-out hover:scale-105 transform"
            >
              <div class="flex items-center">
                <div class="flex-shrink-0">
                  <h3 class="text-xl font-normal text-gray-700">

                    Lettres a Recus
                  </h3>
                </div>
              </div>
            </Link>
            <Link
              to="/Courriers/ListeExpedier"
              class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 cursor-pointer hover:bg-gray-100s hover:shadow-lg transition duration-300 ease-in-out hover:scale-105 transform"
            >
              <div class="flex items-center">
                <div class="flex-shrink-0">
                  <h3 class="text-xl font-normal text-gray-700">
                    Lettres a Expediés
                  </h3>
                </div>
              </div>
            </Link>
          </div>
        </div>

        <FootterCopy />
      </div>
    </>
  );
}

export default Emprunter;
