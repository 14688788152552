/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import FootterCopy from "../../FootterCopy/FootterCopy";
import { decryptData } from "../../../encryptionModule";
import { API } from "../../../Api/API";
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import swal from "sweetalert";
import "./sweetalert.css";
import { toast } from "react-toastify";
function InformaInforMaterielPrefetDisplin({ getSizeMobille }) {
    const [mobile1, Setmonile1] = useState(window.innerWidth < 990)
    const [mobile2, Setmonile2] = useState(window.innerWidth < 520)
    const navigate = useNavigate()
    const { id } = useParams()
    const matId = decryptData(id)
    const [data, setData] = useState({});

    useEffect(() => {
        API.get(`/materielinternant/LesempruntsetlesNotFonctionnel/${matId}`).then((response) => {
            setData(response.data);
        }).catch((err) => {
            console.log(err.message);
        })
    }, [id])




    useEffect(() => {
        const hundlesSize = () => {
            Setmonile1(window.innerWidth < 990)
            Setmonile2(window.innerWidth < 520)
        }
        window.addEventListener('resize', hundlesSize)

        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])

    const [mobile11, SetMobile11] = useState(window.innerWidth < 501)

    useEffect(() => {
        const hundlesSize = () => {
            SetMobile11(window.innerWidth < 501)
        }
        window.addEventListener('resize', hundlesSize)
        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])

    const formatDate = date => {
        return format(new Date(date), "'Le 'd, MMMM yyyy", { locale: fr });
    };

    const [values, GetValues] = useState('')
    const [modifier, Getmodifier] = useState(false)
    const Getmodifiers = (value) => {
        Getmodifier(value)
    };
    const [empruntId, setempruntId] = useState(0)
    const [modifierP, GetmodifierP] = useState(false)
    const GetmodifiersP = (value) => {
        GetmodifierP(value)
    };
    const handleConfirmation = async (materielId) => {
        Getmodifier(false)
        try {
            const result = await swal({
                title: "Confirmation",
                text: "L'eleve a-t-il remis le matériel ?",
                icon: "warning",
                buttons: ["No", "Oui"],
                dangerMode: true,
                className: "centered-buttons",
            });

            if (result) {
                API.put(`/materielinternant/remisEleves/${materielId}/${values}`).then((res) => {
                    toast.success("la remise des matériels internes a été faite avec succès")
                    navigate(`/Materiel/detail/${id}`)
                }).catch((err) => {
                    toast.warn("Erreur de la remise des matériel")
                })
            } else {
                console.log("Cancelled");
            }
        } catch (error) {
            console.log("Error or dismissal:", error);
        }
    };
    const handleConfirmationP = async (materielId) => {
        GetmodifierP(false)
        try {
            const result = await swal({
                title: "Confirmation",
                text: " Le personnel a-t-il remis le matériel ?",
                icon: "warning",
                buttons: ["No", "Oui"],
                dangerMode: true,
                className: "centered-buttons",
            });

            if (result) {
                API.put(`/materielinternant/remisPerso/${materielId}/${values}`).then((res) => {
                    toast.success("la remise des matériels internes a été faite avec succès")
                    navigate(`/Materiel/detail/${id}`)
                }).catch((err) => {
                    toast.warn("Erreur de la remise des matériel")
                })
            } else {
                console.log("Cancelled");
            }
        } catch (error) {
            console.log("Error or dismissal:", error);
        }
    };
    const [classeValidation, SetClasseValidation] = useState(false)
    return (
        <>

            <div className={`bg-white  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
                <Link to={`/Materiel/detail/${id}`} className="ml-2 text-[18px] text-blue-500 hover:text-blue-800 underline">Retour</Link>
                <div className="p-6 min-h-[90vh]">
                    <div class="sm:flex items-center pr-4  h-max  justify-end">
                        <div class="mx-2">
                            <div class="relative lg:w-64">
                                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <svg
                                        class="w-5 h-5 text-gray-500"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                            clip-rule="evenodd"
                                        ></path>
                                    </svg>
                                </div>
                                <input
                                    classe="text"
                                    name="email"
                                    id="topbar-search"
                                    class="bg-white border border-gray-300 h-10 text-gray-900 sm:text-lg first-letter:uppercase rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full pl-10 .5"
                                    placeholder="Rechercher..."

                                />
                            </div>
                        </div>
                        <button class="mt-4 sm:mt-0 inline-flex items-start justify-start px-1 py-3 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg">
                            <p class="text-lg first-letter:uppercase font-semibold leading-none text-white">
                                Rerchercher
                            </p>
                        </button>
                    </div>


                    {data && (
                        <div class="w-full my-4 ">
                            <div class="bg-white shadow rounded-lg p-4 ">
                                <div class={`b-4 flex items-center justify-between ${mobile2 ? 'flex-col' : ''}`}>
                                    <div>
                                        <h3 class="sm:text-xl font-bold text-gray-900 mb-2">
                                            Emprunts des élèves
                                        </h3>
                                    </div>

                                </div>
                                {data.totalEmpruntsMatStudent && data.totalEmpruntsMatStudent.length > 0 ? (
                                    <div class="flex flex-col mt-8 ">
                                        <div class="overflow-x-auto rounded-lg">
                                            <div class={`align-middle inline-block  ${mobile1 ? 'min-w-[70em]' : 'min-w-full'}`}>
                                                <div class="shadow overflow-hidden sm:rounded-lg">
                                                    <table class="min-w-full divide-y  divide-gray-200">
                                                        <thead class="bg-gray-50">
                                                            <tr>
                                                                <th
                                                                    scope="col"
                                                                    class=" py-4 px-2 font-semibold relative border-l text-left cursor-pointer text-lg first-letter:uppercase  text-gray-500  tracking-wider"
                                                                >
                                                                    Nom et prénom

                                                                </th>
                                                                <th
                                                                    scope="col"
                                                                    class=" py-4 px-2 font-semibold relative border-l text-left cursor-pointer text-lg first-letter:uppercase  text-gray-500  tracking-wider"
                                                                >
                                                                    Matricule

                                                                </th>

                                                                <th
                                                                    scope="col"
                                                                    class=" py-4  px-2 border-l text-left cursor-pointer relative text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                                                                >
                                                                    Nombre emprunt

                                                                </th>
                                                                <th
                                                                    scope="col"
                                                                    class=" py-4  px-2 border-l text-left cursor-pointer relative text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                                                                >
                                                                    Date

                                                                </th>
                                                                <th
                                                                    scope="col"
                                                                    class=" w-[5em] overflow-hidden py-4 px-1 border-l text-center  relative text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                                                                >
                                                                    Action
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody class="bg-white">
                                                            {data.totalEmpruntsMatStudent.map((emprunt, index) => {
                                                                return (<tr key={index}>
                                                                    <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                                        {emprunt.student.nomprenom}
                                                                    </td>

                                                                    <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase  text-gray-500">
                                                                        {emprunt.student.matricule}
                                                                    </td>
                                                                    <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase  text-gray-500">
                                                                        {emprunt.nombre}
                                                                    </td>
                                                                    <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase  text-gray-500">
                                                                        {emprunt.createdAt && formatDate(emprunt.createdAt)}
                                                                    </td>
                                                                    <td class="border-b cursor-pointer relative  border-gray-200 overflow-hidden flex  pt-2 px-3  border-l border-r whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                                        {modifier && (
                                                                            <div className="absolute bg-[#0000003f] z-50 top-0 h-full flex justify-center items-center w-full right-0">
                                                                                <div className="bg-transparent h-full flex justify-center items-center w-full ">
                                                                                    <div className="w-[100%] bg-white rounded h-[100%] flex items-center">
                                                                                        <div onClick={() => { Getmodifier(false) }} className="bg-red-500 rounded-full cursor-pointer flex justify-center items-center text-white m-[5px]">
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                                                                                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                                                                                            </svg>
                                                                                        </div>
                                                                                        <div className={`border ${classeValidation ? 'border-red-600' : 'border-blue-300 '} w-[90%] h-[90%] rounded-md overflow-hidden`}>
                                                                                            <input value={values} onInput={(e) => GetValues(e.target.value)} className="outline-none w-full h-full text-[12px] pl-2" placeholder="Quantite..." />
                                                                                        </div>
                                                                                        <button onClick={() => handleConfirmation(empruntId)} className="rounded-full cursor-pointer flex justify-center items-center m-[5px]">
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi text-green-500 bi-send w-5 h-5" viewBox="0 0 16 16">
                                                                                                <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z" />
                                                                                            </svg>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )}

                                                                        <div onClick={(e) => { Getmodifiers(true); e.stopPropagation(); setempruntId(emprunt.id) }} class="my-2  flex items-center justify-center px-2 py-1 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg">
                                                                            <p class="first-letter:uppercase text-white font-semibold ">
                                                                                Retourne
                                                                            </p>
                                                                        </div>
                                                                    </td>
                                                                </tr>)
                                                            })}

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        Aucun emprunt aux élèves
                                    </div>
                                )}

                            </div>
                            <div class="bg-white shadow rounded-lg p-4 ">
                                <div class={`b-4 flex items-center justify-between ${mobile2 ? 'flex-col' : ''}`}>
                                    <div>
                                        <h3 class="sm:text-xl font-bold text-gray-900 mb-2">
                                            Emprunts des personnel
                                        </h3>
                                    </div>

                                </div>
                                {data.totalEmpruntsMatAutre && data.totalEmpruntsMatAutre.length > 0 ? (
                                    <div class="flex flex-col mt-8 ">
                                        <div class="overflow-x-auto rounded-lg">
                                            <div class={`align-middle inline-block  ${mobile1 ? 'min-w-[70em]' : 'min-w-full'}`}>
                                                <div class="shadow overflow-hidden sm:rounded-lg">
                                                    <table class="min-w-full divide-y  divide-gray-200">
                                                        <thead class="bg-gray-50">
                                                            <tr>
                                                                <th
                                                                    scope="col"
                                                                    class=" py-4 px-2 font-semibold relative border-l text-left cursor-pointer text-lg first-letter:uppercase  text-gray-500  tracking-wider"
                                                                >
                                                                    Nom et prénom

                                                                </th>
                                                                <th
                                                                    scope="col"
                                                                    class=" py-4 px-2 font-semibold relative border-l text-left cursor-pointer text-lg first-letter:uppercase  text-gray-500  tracking-wider"
                                                                >
                                                                    Matricule

                                                                </th>
                                                                <th
                                                                    scope="col"
                                                                    class=" py-4 px-2 font-semibold relative border-l text-left cursor-pointer text-lg first-letter:uppercase  text-gray-500  tracking-wider"
                                                                >
                                                                    Rôle

                                                                </th>

                                                                <th
                                                                    scope="col"
                                                                    class=" py-4  px-2 border-l text-left cursor-pointer relative text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                                                                >
                                                                    Nombre emprunt

                                                                </th>
                                                                <th
                                                                    scope="col"
                                                                    class=" py-4  px-2 border-l text-left cursor-pointer relative text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                                                                >
                                                                    Date

                                                                </th>
                                                                <th
                                                                    scope="col"
                                                                    class=" w-[5em] overflow-hidden py-4 px-1 border-l text-center  relative text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                                                                >
                                                                    Action
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody class="bg-white">
                                                            {data.totalEmpruntsMatAutre.map((emprunt, index) => {
                                                                return (<tr key={index}>
                                                                    <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                                        {emprunt.staff.nomcomplet}
                                                                    </td>

                                                                    <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase  text-gray-500">
                                                                        {emprunt.staff.matricule}
                                                                    </td>
                                                                    <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase  text-gray-500">
                                                                        {emprunt.staff.role}
                                                                    </td>
                                                                    <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase  text-gray-500">
                                                                        {emprunt.nombre}
                                                                    </td>
                                                                    <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase  text-gray-500">

                                                                        {emprunt.createdAt && formatDate(emprunt.createdAt)}
                                                                    </td>
                                                                    <td class="border-b cursor-pointer  relative  border-gray-200 overflow-hidden flex  pt-2 px-3  border-l border-r whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                                        {modifierP && (
                                                                            <div className="absolute bg-[#0000003f] z-50 top-0 h-full flex justify-center items-center w-full right-0">
                                                                                <div className="bg-transparent h-full flex justify-center items-center w-full ">
                                                                                    <div className="w-[100%] bg-white rounded h-[100%] flex items-center">
                                                                                        <div onClick={() => { GetmodifierP(false) }} className="bg-red-500 rounded-full cursor-pointer flex justify-center items-center text-white m-[5px]">
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                                                                                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                                                                                            </svg>
                                                                                        </div>
                                                                                        <div className={`border ${classeValidation ? 'border-red-600' : 'border-blue-300 '} w-[90%] h-[90%] rounded-md overflow-hidden`}>
                                                                                            <input value={values} onInput={(e) => GetValues(e.target.value)} className="outline-none w-full h-full text-[12px] pl-2" placeholder="Quantite..." />
                                                                                        </div>
                                                                                        <button onClick={() => handleConfirmationP(empruntId)} className="rounded-full cursor-pointer flex justify-center items-center m-[5px]">
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi text-green-500 bi-send w-5 h-5" viewBox="0 0 16 16">
                                                                                                <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z" />
                                                                                            </svg>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )}

                                                                        <div onClick={(e) => { GetmodifiersP(true); e.stopPropagation(); setempruntId(emprunt.id) }} class="my-2 cursor-pointer flex items-center justify-center px-2 py-1 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg">
                                                                            <p class="first-letter:uppercase text-white font-semibold ">
                                                                                Retourne
                                                                            </p>
                                                                        </div>
                                                                    </td>
                                                                </tr>)
                                                            })}

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        Aucun emprunt aux personnel
                                    </div>
                                )}

                            </div>
                            <div class="bg-white shadow rounded-lg p-4 ">
                                <div class={`b-4 flex items-center justify-between ${mobile2 ? 'flex-col' : ''}`}>
                                    <div>
                                        <h3 class="sm:text-xl font-bold text-gray-900 mb-2">
                                            Matériels non fonctionnels
                                        </h3>
                                    </div>

                                </div>
                                {data.totalMatNotFonctionnel && data.totalMatNotFonctionnel.length > 0 ? (
                                    <div class="flex flex-col mt-8 ">
                                        <div class="overflow-x-auto rounded-lg">
                                            <div class={`align-middle inline-block  ${mobile1 ? 'min-w-[70em]' : 'min-w-full'}`}>
                                                <div class="shadow overflow-hidden sm:rounded-lg">
                                                    <table class="min-w-full divide-y  divide-gray-200">
                                                        <thead class="bg-gray-50">
                                                            <tr>
                                                                <th
                                                                    scope="col"
                                                                    class=" py-4 px-2 font-semibold relative border-l text-left cursor-pointer text-lg first-letter:uppercase  text-gray-500  tracking-wider"
                                                                >
                                                                    Nombre

                                                                </th>



                                                                <th
                                                                    scope="col"
                                                                    class=" py-4  px-2 border-l text-left cursor-pointer relative text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                                                                >
                                                                    Description

                                                                </th>
                                                                <th
                                                                    scope="col"
                                                                    class=" py-4  px-2 border-l text-left cursor-pointer relative text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                                                                >
                                                                    Date

                                                                </th>

                                                            </tr>
                                                        </thead>
                                                        <tbody class="bg-white">
                                                            {data.totalMatNotFonctionnel.map((materiel, index) => {
                                                                return (<tr key={index}>
                                                                    <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                                        {materiel.nombre}
                                                                    </td>

                                                                    <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase  text-gray-500">
                                                                        {materiel.description}
                                                                    </td>
                                                                    <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase  text-gray-500">

                                                                        {materiel.createdAt && formatDate(materiel.createdAt)}
                                                                    </td>
                                                                </tr>)
                                                            })}

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        Aucun Matériel non fonctionnel
                                    </div>
                                )}

                            </div>
                        </div>
                    )

                    }
                </div>
                <FootterCopy />
            </div>

        </>
    );
}

export default InformaInforMaterielPrefetDisplin;





