import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import FootterCopy from "../../FootterCopy/FootterCopy";
import Select from "react-select";
import { decryptData, encryptData } from "../../../encryptionModule";
import { STATIC_URL, API } from "../../../Api/API";

function ModifierPersonnel({ getSizeMobille }) {

  const history = useNavigate()
  const { id } = useParams()
  const StaffId = decryptData(id)
  const RoleOptions = [
    { value: "", label: "Role" },
    { value: "Directeur", label: "Directeur" },
    { value: "Préfet des études", label: "Préfet des études" },
    { value: "Préfet de discipline", label: "Préfet de discipline" },
    { value: "Econome", label: "Econome" },
    { value: "Aide économe", label: "Aide économe" },
    { value: "Bibliothécaire", label: "Bibliothécaire" },
    { value: "Secrétaire", label: "Secrétaire" },
    { value: "Enseignant", label: "Enseignant" },
    { value: "infirmier", label: "infirmier" },
    { value: "Encadreur", label: "Encadreur" }
  ];

  const Sexeoptions = [
    { value: "", label: "Sexe" },
    { value: "M", label: "Masculin" },
    { value: "F", label: "Feminin" },
  ];

  const Gradeptions = [
    { value: "", label: "Grade" },
    { value: "Dr", label: "Docteur" },
    { value: "Mr", label: "Maitre" },
    { value: "L", label: "Licencier" },
    { value: "Bac", label: "Bachelier" },
    { value: "H.G", label: "Humanite générale" },
  ];


  const [Classeursoptions, setClasseursoptions] = useState([]);
  const [classeurloading, setClasseurloading] = useState(0)
  useEffect(() => {
    API.get("/classeur/all")
      .then((response) => {
        // Transformation des données pour correspondre à la structure attendue
        const classeursFromAPI = response.data.map(classeur => ({
          value: classeur.id.toString(), // Conversion en chaîne si nécessaire
          label: classeur.noClasseur
        }));
        // Mettre à jour l'état avec les nouvelles données
        setClasseursoptions([selectedOption3, ...classeursFromAPI]);
        setClasseurloading(classeurloading + 1)
      })

      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  const hiddenSexeoptions = Sexeoptions.slice(1);
  const hiddenSClasseursoptions = Classeursoptions.slice(1);
  const hiddenSRoleOptions = RoleOptions.slice(1);
  const hiddenGrade = RoleOptions.slice(1);

  const [selectedOption3, setSelectedOption3] = useState({ value: "", label: "Classeur" });
  const [selectedOption2, setSelectedOption2] = useState(Sexeoptions[0]);
  const [selectedOption4, setSelectedOption4] = useState(RoleOptions[0]);


  const [Grade, setSelectedGrade] = useState(Gradeptions[0]);
  const [animationGrade, setanimationGrade] = useState('');
  const hiddenGradeoptions = Gradeptions.slice(1);
  const elemetRefGrade = useRef(null)



  const selectedGrade = (options) => {
    setSelectedGrade(options)
  }

  const SelectSexe = (options) => {
    setSelectedOption2(options)
  }

  const SelectClasseur = (option) => {
    setSelectedOption3(option)
  }

  const SelectRole = (option) => {
    setSelectedOption4(option)
  }
  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: 50,
      backgroundColor: "white",

    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "blue" : state.isFocused ? "lightblue" : "white",
      color: state.isSelected ? "white" : "black",
    }),
  };




  const [Nom, SetNom] = useState('')
  const [animationClassNom, setAnimationClassNom] = useState('');
  const elemetRefNom = useRef(null)

  const [Email, SetEmail] = useState('')
  const [animationClassEmail, setAnimationClassEmail] = useState('');
  const elemetRefEmail = useRef(null)

  const [Addres, SetAddres] = useState('')
  const [animationClassAddres, setAnimationClassAddres] = useState('');
  const elemetRefAddres = useRef(null)


  const [Matricule, SetMatricule] = useState('')
  const [animationClassMatricule, setAnimationClassMatricule] = useState('');
  const elemetRefMatricule = useRef(null)

  const [animationClassRole, setanimationClassRole] = useState('');
  const elemetRefRole = useRef(null)

  const [animationClassSexe, setanimationClassSexe] = useState('');
  const elemetRefSexe = useRef(null)

  const [Telephone, SetTelephone] = useState('')
  const [animationClassgetTelephone, setanimationClassgetTelephone] = useState('');
  const elemetRefTelephone = useRef(null)


  const [animationClassClasseurs, setanimationClassCpassport] = useState('');
  const elemetRefClasseurs = useRef(null)

  const [affectaion, SetAffecation] = useState(null)
  const [animationClassaffectaion, setanimationClassaffectaion] = useState('');
  const elementRefAffectation = useRef(null)


  const [Profile, SetProfile] = useState(null)
  const [animationClassProfile, setanimationClassProfile] = useState('');
  const elementRefProfile = useRef(null)


  const [indetiteComplete, SetIndetiteComplete] = useState(null)
  const [animationClassidentite, setanimationClassIdentite] = useState('');
  const elementRefidentit = useRef(null)


  const [cv, Setcv] = useState(null)
  const [animationClassCv, setanimationClassCv] = useState('');
  const elementRefcv = useRef(null)


  const [diplome, SetDiplome] = useState(null)
  const [animationClassdiplome, setanimationClassdiplome] = useState('');
  const elementRefDiplome = useRef(null)


  const [contrat, SetContra] = useState(null)
  const [animationClasscontrat, setanimationClasscontrat] = useState('');
  const elementRefContrant = useRef(null)

  const isValidEmail = (email) => {
    const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    return email.trim() !== '' && emailRegex.test(email.trim());
  };
  const handleSubmit = (e) => {
    e.preventDefault()
    if (Nom.trim() === '') {
      toast.warning('Le Nom  est  obligatoire', {
        autoClose: 2000
      });
      setAnimationClassNom('animate__animated animate__shakeX border-2 border-red-500 ')
      setTimeout(() => {
        setAnimationClassNom(' ')
      }, 3000)
      elemetRefNom.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      elemetRefNom.current.focus()

      return false
    }
    else if (Matricule.trim() == '') {
      toast.warning("Matricule est obligatoire", {
        autoClose: 2000
      });
      setAnimationClassMatricule('animate__animated animate__shakeX  border-2 border-red-500')
      setTimeout(() => {
        setAnimationClassMatricule(' ')
      }, 3000)
      elemetRefMatricule.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      elemetRefMatricule.current.focus()
      return false
    }
    else if (selectedOption4.value == '') {
      toast.warning('Le role est obligatoire ', {
        autoClose: 2000
      });
      setanimationClassRole('animate__animated animate__shakeX border-2 border-red-500')
      setTimeout(() => {
        setanimationClassRole(' ')
      }, 3000)
      elemetRefRole.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      return;

    }

    else if (selectedOption2.value == '') {
      toast.warning('Genre est obligatoire ', {
        autoClose: 2000
      });
      setanimationClassSexe('animate__animated animate__shakeX border-2 border-red-500')
      setTimeout(() => {
        setanimationClassSexe(' ')
      }, 3000)
      elemetRefSexe.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      return;

    }
    else if (Grade.value == '') {
      toast.warning('Le Grade est obligatoire', {
        autoClose: 2000
      });
      setanimationGrade('animate__animated animate__shakeX border-2 border-red-500')
      setTimeout(() => {
        setanimationGrade(' ')
      }, 3000)
      elemetRefGrade.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      return;
    }
    else if (!isValidEmail(Email)) {
      toast.warning('L\'Email est obligatoire et doit être au format valide', { autoClose: 2000 });

      setAnimationClassEmail('animate__animated animate__shakeX border-2 border-red-500')
      setTimeout(() => {
        setAnimationClassEmail(' ')
      }, 3000)
      elemetRefEmail.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      elemetRefEmail.current.focus()
      return;

    }

    else if (Telephone.trim() == '') {
      toast.warning('Le numéro de téléphone est obligatoire ', {
        autoClose: 2000
      });
      setanimationClassgetTelephone('animate__animated animate__shakeX border-2 border-red-500')
      setTimeout(() => {
        setanimationClassgetTelephone(' ')
      }, 3000)
      elemetRefTelephone.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      elemetRefTelephone.current.focus()
      return;

    }
    else if (Addres.trim() == '') {
      toast.warning('L\' Adresse  est obligatoire ', {
        autoClose: 2000
      });
      setAnimationClassAddres('animate__animated animate__shakeX border-2 border-red-500')
      setTimeout(() => {
        setAnimationClassAddres(' ')
      }, 3000)
      elemetRefAddres.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      elemetRefAddres.current.focus()
      return;
    }
    else if (selectedOption3.value == '') {
      toast.warning('Le classeurs est obligatoire', {
        autoClose: 2000
      });
      setanimationClassCpassport('animate__animated animate__shakeX border-2 border-red-500')
      setTimeout(() => {
        setanimationClassCpassport(' ')
      }, 3000)
      elemetRefClasseurs.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      return;
    }
    const formdata = new FormData()
    formdata.append("nomcomplet", Nom)
    formdata.append("sexe", selectedOption2.value)
    formdata.append("role", selectedOption4.value)
    formdata.append("matricule", Matricule)
    formdata.append("tel", Telephone)
    formdata.append("Grade", Grade.value)
    formdata.append("classeurId", selectedOption3.value)
    formdata.append("Addres", Addres)
    formdata.append("Email", Email)

    if (affectaion !== null) {
      formdata.append("affectation", affectaion)
    }
    if (indetiteComplete !== null) {
      formdata.append("idcomplete", indetiteComplete)
    }
    if (cv !== null) {
      formdata.append("cv", cv)
    }
    if (diplome !== null) {
      formdata.append("diplome", diplome)
    }
    if (contrat !== null) {
      formdata.append("contraperfo", contrat)
    }
    if (Profile !== null) {
      formdata.append("Profile", Profile)
    }



    API.put(`/staff/ParId/${StaffId}`, formdata, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then((res) => {
      toast.success(res.data)
      history("/DossierPersonnel")
    }).catch((error) => {
      if (error.response) {
        // Le serveur a répondu avec un code d'erreur (status code)
        if (Array.isArray(error.response.data)) {
          // Si c'est un tableau, afficher le premier élément
          toast.warn(error.response.data[0]);
        } else if (typeof error.response.data === 'object') {
          // S'il s'agit d'un objet, afficher la première valeur de la propriété
          const keys = Object.keys(error.response.data);
          if (keys.length > 0) {
            toast.warn(error.response.data[keys[0]]);
          }
        } else if (typeof error.response.data === 'string') {
          // Si c'est une chaîne de caractères, l'afficher directement
          toast.warn(error.response.data);
        } else {
          // Autre type non pris en charge
          toast.warn("Erreur du serveur")

        }

        // Afficher les erreurs au sein de votre composant ou effectuer une action en conséquence
      } else if (error.request) {
        // La requête a été faite mais aucune réponse n'a été reçue
        console.error('Pas de réponse du serveur :', error.request);
      } else {
        // Une erreur s'est produite lors de la configuration de la requête
        console.error('Erreur lors de la requête :', error.message);
      }
    })

  }

  const [Staff, setStaff] = useState({})
  useEffect(() => {
    API.get(`/staff/ParId/${StaffId}`).then((res) => {
      const staffData = res.data;
      setStaff(staffData)
      if (staffData.classeurId) {
        const correspondingClasseur = Classeursoptions.find(
          (classeur) => classeur.value == staffData.classeurId.toString()
        );

        if (correspondingClasseur) {
          setSelectedOption3(correspondingClasseur);
        }
      }
      setSelectedOption2(Sexeoptions.find(option => option.value === staffData.sexe));
      setSelectedOption4(RoleOptions.find(option => option.value === staffData.role));
      setSelectedGrade(Gradeptions.find(option => option.value === staffData.Grade));
      SetTelephone(staffData.tel);
      SetAddres(staffData.Addres);
      SetNom(staffData.nomcomplet)
      SetMatricule(staffData.matricule)
      SetEmail(staffData.Email)
    }).catch((err) => {
      console.log(err.message)
    })
  }, [StaffId, classeurloading])

  return (
    <>
      <div className={`bg-white  overflow-x-auto  h-[85vh] ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
        <Link to={`/DossierPersonnel/DossierPers/${id}`} className="ml-2 text-[18px] text-blue-500 hover:text-blue-800 underline">Retour</Link>

        <div className=" flex justify-center flex-col ">

          <div
            id="main-content"
            class="h-full w-full bg-gray-50 p-4 relative"
          >

            <div class="pt-6 sm:px-4 px-1 w-full">
              <div class=" w-full">
                <div class="w-full flex flex-row items-center justify-between">
                  <p
                    class="hover:text-navy-700 text-gray-700 uppercase sm:text-3xl font-bold "

                  >
                    Modifier un Personnel

                  </p>

                </div>
              </div>


              <div class="w-full my-2 grid grid-cols-1 ">
                <div class="bg-white  rounded-lg p-2 sm:p-6 xl:p-8">
                  <form onSubmit={handleSubmit}>
                    <div class="bg-white shadow rounded px-3 sm:px-8 pt-6 pb-8 mb-4 flex flex-col my-2">
                      <div class="-mx-3 md:flex mb-6">
                        <div class="md:w-1/2 sm:px-3 px-1 mb-6 md:mb-0">
                          <label
                            class="block uppercase tracking-wide text-gray-700 text-lg font-bold mb-2"
                            for="grid-Title"
                          >
                            Nom Complet
                          </label>
                          <input
                            class={` outline-none block w-full bg-grey-lighter  border rounded py-3 px-4 mb-3 ${Nom.trim() === '' ? animationClassNom : 'focus:border-2 focus:border-blue-700'} `}

                            ref={elemetRefNom} value={Nom} onInput={(e) => SetNom(e.target.value)}
                            placeholder="Nom Complet"

                          />

                        </div>
                        <div class="md:w-1/2 px-3">
                          <label
                            class="block uppercase tracking-wide text-gray-700 text-lg font-bold mb-2"
                            for="grid-last-name"
                          >
                            Matricule
                          </label>
                          <input
                            class={` outline-none block w-full bg-grey-lighter  border rounded py-3 px-4 mb-3 ${Matricule.trim() === '' ? animationClassMatricule : 'focus:border-2 focus:border-blue-700'} `}
                            type="text"
                            ref={elemetRefMatricule} value={Matricule} onInput={(e) => SetMatricule(e.target.value)}
                            placeholder="Matricule"
                          />

                        </div>
                      </div>
                      <div class="-mx-3 md:flex mb-6">
                        <div class="md:w-1/2 sm:px-3 px-1 mb-6 md:mb-0">
                          <label
                            class="block uppercase tracking-wide text-gray-700 text-lg font-bold mb-2"
                            for="grid-state"
                          >
                            Role
                          </label>
                          <div ref={elemetRefRole} className={` rounded  ${selectedOption4.value == '' ? animationClassRole : 'focus:border-2 focus:border-blue-700'} `}
                          >
                            <Select
                              value={selectedOption4}
                              options={hiddenSRoleOptions}
                              styles={customStyles}
                              onChange={SelectRole}
                            />
                          </div>

                        </div>
                        <div class="md:w-1/2 sm:px-3 px-1 mb-6 md:mb-0">
                          <label
                            class="block uppercase tracking-wide text-gray-700 text-lg font-bold mb-2"
                            for="grid-state"
                          >
                            Sexe
                          </label>
                          <div ref={elemetRefSexe} className={` rounded  ${selectedOption2.value == '' ? animationClassSexe : 'focus:border-2 focus:border-blue-700'} `}
                          >
                            <Select
                              value={selectedOption2}
                              options={hiddenSexeoptions}
                              styles={customStyles}
                              onChange={SelectSexe}
                            />
                          </div>

                        </div>
                        <div class="md:w-1/2 sm:px-3 px-1 mb-6 md:mb-0">
                          <label
                            class="block uppercase tracking-wide text-gray-700 text-lg font-bold mb-2"
                            for="grid-state"
                          >
                            Grade
                          </label>
                          <div ref={elemetRefGrade} className={` rounded  ${Grade.value == '' ? animationGrade : 'focus:border-2 focus:border-blue-700'} `}
                          >
                            <Select
                              value={Grade}
                              options={hiddenGradeoptions}
                              styles={customStyles}
                              onChange={selectedGrade}

                            />
                          </div>

                        </div>
                      </div>
                      <div class="-mx-3  md:flex mb-10">
                        <div class="md:w-1/2 px-3">
                          <label
                            class="block uppercase tracking-wide text-grey-darker text-lg font-bold mb-2"
                            for="grid-last-name"
                          >
                            E-mail
                          </label>
                          <input
                            class={` outline-none block w-full bg-grey-lighter  border rounded py-3 px-4 mb-3 ${Email.trim() === '' ? animationClassEmail : 'focus:border-2 focus:border-blue-700'} `}
                            type="text"
                            ref={elemetRefEmail} value={Email} onInput={(e) => SetEmail(e.target.value)}

                            placeholder="E-mail"
                            name="Email"
                          />

                        </div>
                        <div class="md:w-1/2 px-3">
                          <label
                            class="block uppercase tracking-wide text-grey-darker text-lg font-bold mb-2"
                            for="grid-last-name"
                          >
                            Numéro de téléphone
                          </label>
                          <input
                            class={` outline-none block w-full bg-grey-lighter  border rounded py-3 px-4 mb-3 ${Telephone.trim() === '' ? animationClassgetTelephone : 'focus:border-2 focus:border-blue-700'} `}
                            type="text"
                            ref={elemetRefTelephone} value={Telephone} onInput={(e) => SetTelephone(e.target.value)}

                            placeholder="Telephone"
                            name="telephone"
                          />

                        </div>
                      </div>
                      <div class="-mx-3  md:flex mb-10">
                        <div class="md:w-1/2 px-3">
                          <label
                            class="block uppercase tracking-wide text-grey-darker text-lg font-bold mb-2"
                            for="grid-last-name"
                          >
                            Adresse complet
                          </label>
                          <input
                            class={` outline-none block w-full bg-grey-lighter  border rounded py-3 px-4 mb-3 ${Addres.trim() === '' ? animationClassAddres : 'focus:border-2 focus:border-blue-700'} `}
                            type="text"
                            ref={elemetRefAddres} value={Addres} onInput={(e) => SetAddres(e.target.value)}

                            placeholder="Kiriri, Vugizo, Mukaza, Mairie"
                            name="Addres"
                          />

                        </div>
                        <div class="md:w-1/2 px-3">
                          <label
                            class="block uppercase tracking-wide text-gray-700 text-lg font-bold mb-2"
                            for="grid-state"
                          >
                            Classeurs
                          </label>
                          <div ref={elemetRefClasseurs} className={` rounded  ${selectedOption3.value == '' ? animationClassClasseurs : 'focus:border-2 focus:border-blue-700'} `}
                          >
                            <Select
                              value={selectedOption3}
                              options={hiddenSClasseursoptions}
                              styles={customStyles}
                              onChange={SelectClasseur}

                            />
                          </div>

                        </div>
                      </div>
                      <div class="flex flex-row items-center justify-center mb-10 ">
                        {" "}
                        <p class="text-navy-700 shrink ">
                          <p
                            class="hover:text-navy-700 text-gray-700 uppercase sm:text-3xl font-bold"

                          >
                            Completer Vos Documents{" "}
                          </p>
                        </p>
                      </div>

                      <div class="-mx-3 md:flex mb-6">
                        <div class="md:w-1/2 px-3 mb-6 md:mb-0 ">
                          <div class="bg-gray-50 border border-gray-100 sm:w-[18em] rounded-xl p-3 flex flex-col items-center justify-center">
                            <label
                              class="block uppercase tracking-wide text-grey-darker sm:text-lg font-bold mb-2"
                              for="grid-Title"
                            >
                              Photo de Profile
                            </label>
                            <div className="mb-4">
                              <div class=" flex w-full items-center  ">
                                <label
                                  ref={elementRefProfile}
                                  class={`cursor-pointer rounded-2xl bg-blue-500 px-4 py-1 font-normal text-white tracking-wide  hover:bg-blue-600 ${animationClassProfile}`}
                                  htmlFor="videoFile-0-0"
                                >
                                  Choix image
                                </label>
                                <input
                                  type="file"
                                  id="videoFile-0-0"
                                  hidden
                                  accept="image/*"
                                  onChange={(e) => SetProfile(e.target.files[0])}
                                />
                              </div>
                            </div>
                            {Profile ? (
                              <div className="max-w-[15em] bg-white rounded-2xl overflow-hidden border max-h-[15em]">
                                <img src={URL.createObjectURL(Profile)} className="w-full h-full object-contain bg-white" />
                              </div>
                            ) : Staff.Profile && (
                              <div className="max-w-[15em] bg-white rounded-2xl overflow-hidden border max-h-[15em]">
                                <img src={`${STATIC_URL}/personnel/${Staff.Profile}`} className="w-full h-full object-contain bg-white" />
                              </div>
                            )}

                          </div>
                        </div>
                        <div class="md:w-1/2 px-3 mb-6 md:mb-0">
                          <div class="bg-gray-50 border border-gray-100 sm:w-[18em] rounded-xl p-3 flex flex-col items-center justify-center">
                            <label
                              class="block uppercase tracking-wide text-grey-darker text-lg font-bold mb-2"
                              for="grid-Title"
                            >
                              Identité Complète
                            </label>
                            <div className="mb-4">
                              <div class=" flex w-[px] items-center  ">
                                <label
                                  title="Add video"
                                  class={`cursor-pointer rounded-2xl bg-blue-500 px-4 py-1 font-normal text-white tracking-wide  hover:bg-blue-600 ${animationClassidentite}`}
                                  htmlFor="identite"
                                  ref={elementRefidentit}
                                >
                                  Choix image
                                </label>
                                <input
                                  type="file"
                                  id="identite"
                                  accept="image/*"
                                  hidden
                                  onChange={(e) => SetIndetiteComplete(e.target.files[0])}

                                />
                              </div>
                            </div>
                            {indetiteComplete ? (
                              <div className="max-w-[15em] bg-white rounded-2xl overflow-hidden border max-h-[15em]">
                                <img src={URL.createObjectURL(indetiteComplete)} className="w-full h-full object-contain bg-white" />
                              </div>
                            ) : Staff.idcomplete && (
                              <div className="max-w-[15em] bg-white rounded-2xl overflow-hidden border max-h-[15em]">
                                <img src={`${STATIC_URL}/personnel/${Staff.idcomplete}`} className="w-full h-full object-contain bg-white" />
                              </div>
                            )}

                          </div>
                        </div>
                      </div>
                      <div class="-mx-3 md:flex mb-6">
                        <div class="md:w-1/2 px-3 mb-6 md:mb-0">
                          <div class="bg-gray-50 border border-gray-100 sm:w-[18em] rounded-xl p-3 flex flex-col items-center justify-center">
                            <label
                              class="block uppercase tracking-wide text-grey-darker text-lg font-bold mb-2"
                              for="grid-Title"
                            >
                              CV
                            </label>
                            <div className="mb-4">
                              <div class=" flex w-[px] items-center  ">
                                <label
                                  ref={elementRefcv}
                                  title="Add video"
                                  class={`cursor-pointer rounded-2xl bg-blue-500 px-4 py-1 font-normal text-white tracking-wide  hover:bg-blue-600 ${animationClassCv}`}
                                  htmlFor="cv"
                                >
                                  Choix image
                                </label>
                                <input
                                  type="file"
                                  id="cv"
                                  hidden
                                  onChange={(e) => Setcv(e.target.files[0])}

                                  accept="image/*"
                                />
                              </div>
                            </div>

                            {cv ? (
                              <div className="max-w-[15em] bg-white rounded-2xl overflow-hidden border max-h-[15em]">
                                <img src={URL.createObjectURL(cv)} className="w-full h-full object-contain bg-white" />
                              </div>
                            ) : Staff.cv && (
                              <div className="max-w-[15em] bg-white rounded-2xl overflow-hidden border max-h-[15em]">
                                <img src={`${STATIC_URL}/personnel/${Staff.cv}`} className="w-full h-full object-contain bg-white" />
                              </div>
                            )}


                          </div>
                        </div>
                        <div class="md:w-1/2 px-3 mb-6 md:mb-0">
                          <div class="bg-gray-50 border border-gray-100 sm:w-[18em] rounded-xl p-3 flex flex-col items-center justify-center">
                            <label
                              class="block uppercase tracking-wide text-grey-darker text-lg font-bold mb-2"
                              for="grid-Title"
                            >
                              Diplomes
                            </label>
                            <div className="mb-4">
                              <div class=" flex w-[px] items-center  ">
                                <label
                                  ref={elementRefDiplome}
                                  title="Add video"
                                  class={`cursor-pointer rounded-2xl bg-blue-500 px-4 py-1 font-normal text-white tracking-wide  hover:bg-blue-600 ${animationClassdiplome}`}
                                  htmlFor="diplome"
                                >
                                  Choix image
                                </label>
                                <input
                                  type="file"
                                  accept="image/*"
                                  hidden
                                  id="diplome"
                                  onChange={(e) => SetDiplome(e.target.files[0])}

                                />
                              </div>
                            </div>
                            {diplome ? (
                              <div className="max-w-[15em] bg-white rounded-2xl overflow-hidden border max-h-[15em]">
                                <img src={URL.createObjectURL(diplome)} className="w-full h-full object-contain bg-white" />
                              </div>
                            ) : Staff.diplome && (
                              <div className="max-w-[15em] bg-white rounded-2xl overflow-hidden border max-h-[15em]">
                                <img src={`${STATIC_URL}/personnel/${Staff.diplome}`} className="w-full h-full object-contain bg-white" />
                              </div>
                            )}



                          </div>
                        </div>
                      </div>
                      <div class="-mx-3 md:flex mb-6">
                        <div class="md:w-1/2 px-3 mb-6 md:mb-0">
                          <div class="bg-gray-50 border border-gray-100 sm:w-[18em] rounded-xl p-3 flex flex-col items-center justify-center">
                            <label
                              class="block uppercase tracking-wide text-grey-darker text-lg font-bold mb-2"
                              for="grid-Title"
                            >
                              Contrat de performance
                            </label>
                            <div className="mb-4">
                              <div class=" flex w-[px] items-center  ">
                                <label ref={elementRefContrant}
                                  title="Add video"
                                  class={`cursor-pointer rounded-2xl bg-blue-500 px-4 py-1 font-normal text-white tracking-wide  hover:bg-blue-600 ${animationClasscontrat}`}
                                  htmlFor="contrant"
                                >
                                  Choix image
                                </label>
                                <input
                                  type="file"
                                  id="contrant"
                                  accept="image/*"
                                  hidden
                                  onChange={(e) => SetContra(e.target.files[0])}

                                />
                              </div>
                            </div>
                            {contrat ? (
                              <div className="max-w-[15em] bg-white rounded-2xl overflow-hidden border max-h-[15em]">
                                <img src={URL.createObjectURL(contrat)} className="w-full h-full object-contain bg-white" />
                              </div>
                            ) : Staff.contraperfo && (
                              <div className="max-w-[15em] bg-white rounded-2xl overflow-hidden border max-h-[15em]">
                                <img src={`${STATIC_URL}/personnel/${Staff.contraperfo}`} className="w-full h-full object-contain bg-white" />
                              </div>
                            )}
                          </div>
                        </div>
                        <div class="md:w-1/2 px-3 mb-6 md:mb-0 ">
                          <div class="bg-gray-50 border border-gray-100 sm:w-[18em] rounded-xl p-3 flex flex-col items-center justify-center">
                            <label
                              class="block uppercase tracking-wide text-grey-darker sm:text-lg font-bold mb-2"
                              for="grid-Title"
                            >
                              Lettre d'Affectation
                            </label>
                            <div className="mb-4">
                              <div class=" flex w-full items-center  ">
                                <label
                                  ref={elementRefAffectation}
                                  class={`cursor-pointer rounded-2xl bg-blue-500 px-4 py-1 font-normal text-white tracking-wide  hover:bg-blue-600 ${animationClassaffectaion}`}
                                  htmlFor="videoFile-0-40"
                                >
                                  Choix image
                                </label>
                                <input
                                  type="file"
                                  id="videoFile-0-40"
                                  hidden
                                  accept="image/*"
                                  onChange={(e) => SetAffecation(e.target.files[0])}
                                />
                              </div>
                            </div>
                            {affectaion ? (
                              <div className="max-w-[15em] bg-white rounded-2xl overflow-hidden border max-h-[15em]">
                                <img src={URL.createObjectURL(affectaion)} className="w-full h-full object-contain bg-white" />
                              </div>
                            ) : Staff.affectation && (
                              <div className="max-w-[15em] bg-white rounded-2xl overflow-hidden border max-h-[15em]">
                                <img src={`${STATIC_URL}/personnel/${Staff.affectation}`} className="w-full h-full object-contain bg-white" />
                              </div>
                            )}



                          </div>
                        </div>
                      </div>

                      <div class="mb-4 flex items-center justify-end">
                        <div class="flex-shrink-0">
                          <button
                            type="submit"
                            class="mt-4 sm:mt-0 text-lg font-medium leading-none text-white inline-flex items-start justify-start px-6 py-3 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg"
                          >
                            Modifier
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>

                </div>
              </div>

            </div>

          </div>

          <FootterCopy />
        </div>
      </div>
    </>
  );
}

export default ModifierPersonnel;


