/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import FootterCopy from "../../FootterCopy/FootterCopy";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { decryptData } from "../../../encryptionModule";
import { API } from "../../../Api/API";
import Select from "react-select";
function ModifierBiblio({ getSizeMobille }) {
    const History = useNavigate()
    const [mobile11, SetMobile11] = useState(window.innerWidth < 501)
    const { id } = useParams()
    useEffect(() => {
        const hundlesSize = () => {
            SetMobile11(window.innerWidth < 501)
        }
        window.addEventListener('resize', hundlesSize)
        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])


    const [titre, Settitre] = useState('')
    const [animationClasstitre, setAnimationClasstitre] = useState('');
    const elemetReftitre = useRef(null)

    const [source, SetSource] = useState('')
    const [animationClasssource, setAnimationClasssource] = useState('');
    const elemetRefsource = useRef(null)

    const [livreCherche, SetlivreCherche] = useState('')
    const [animationClasslivreCherche, setAnimationClasslivreCherche] = useState('');
    const elemetRefCherch = useRef(null)


    const [getDedie, SetgetDedie] = useState('')
    const [animationClassgetDedie, setanimationClassgetDedie] = useState('');
    const elemetRefdedie = useRef(null)

    const [anne, SetAnne] = useState('')
    const [animationClassgetAnne, setanimationClassgetAnne] = useState('');
    const elemetRefanne = useRef(null)

    //start class options
    const [Classoptions, SetClassoptions] = useState([])
    const [selectedOption1, setSelectedOption1] = useState({ value: "Tous", label: "Classe" });
    const hiddenClassoptions = Classoptions.slice(1);
    const [animationClassClasse, setanimationClassClasse] = useState('');
    const elemetRefClasse = useRef(null)
    const customStyles = {
        control: (provided) => ({
            ...provided,
            height: 50,
            backgroundColor: "white",

        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? "blue" : state.isFocused ? "lightblue" : "white",
            color: state.isSelected ? "white" : "black",
        }),
    };
    const [classesLoding, HendleclassLoding] = useState(0)
    useEffect(() => {
        API.get("/classes")
            .then((response) => {
                // Transformation des données pour correspondre à la structure attendue
                const classesFromAPI = response.data.map(classe => ({
                    value: classe.abreviation,
                    label: classe.abreviation
                }));
                // Mettre à jour l'état avec les nouvelles données
                SetClassoptions([selectedOption1, ...classesFromAPI]);
                HendleclassLoding(classesLoding + 1)
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, []);
    const SelectClasse = (options) => {
        setSelectedOption1(options)
    }

    const livreId = decryptData(id)
    useEffect(() => {
        API.get(`/livres/getoneLivre/${livreId}`)
            .then((response) => {
                // console.log(response.data)
                Settitre(response.data.titre)
                SetSource(response.data.source)
                SetlivreCherche(response.data.livrecherche)
                SetgetDedie(response.data.dedie)
                if (response.data.classe) {
                    const correspondingacces = Classoptions.find(
                        (acces) => acces.value == response.data.classe
                    );
                    if (correspondingacces) {
                        setSelectedOption1(correspondingacces);
                    }
                }
            }).catch((err) => {
                console.log(err.message);
            });
    }, [classesLoding, id]);






    const handleSubmit = (e) => {
        e.preventDefault()
        if (titre.trim() === '') {
            toast.warning('Le titre du livre est  obligatoire', {
                autoClose: 2000
            });
            setAnimationClasstitre('animate__animated animate__shakeX border-2 border-red-500 ')
            setTimeout(() => {
                setAnimationClasstitre(' ')
            }, 3000)
            elemetReftitre.current.focus()

            return false
        } else if (source.trim() == '') {
            toast.warning('Source du livre est obligatoire', {
                autoClose: 2000
            });
            setAnimationClasssource('animate__animated animate__shakeX border-2 border-red-500')
            setTimeout(() => {
                setAnimationClasssource(' ')
            }, 3000)
            elemetRefsource.current.focus()

            return false
        }
        else if (livreCherche == '') {
            toast.warning("Reponds si le livre est recherche ou pas ", {
                autoClose: 2000
            });
            setAnimationClasslivreCherche('animate__animated animate__shakeX  border-2 border-red-500')
            setTimeout(() => {
                setAnimationClasslivreCherche(' ')
            }, 3000)
            elemetRefCherch.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            return false
        }
        else if (getDedie == '') {
            toast.warning('Livre est dediée avec qui ?', {
                autoClose: 2000
            });
            setanimationClassgetDedie('animate__animated animate__shakeX border-2 border-red-500')
            setTimeout(() => {
                setanimationClassgetDedie(' ')
            }, 3000)
            elemetRefsource.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            return;
        }
        if (getDedie !== 'Tous') {
            if (selectedOption1.value === 'Tous') {
                toast.warning(`A quel classe `, {
                    autoClose: 2000
                });
                setanimationClassClasse('animate__animated animate__shakeX border-2 border-red-500')
                setTimeout(() => {
                    setanimationClassClasse(' ')
                }, 3000)
                elemetRefClasse.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
                return;
            }

        }



        const formData = {
            titre: titre,
            source: source,
            dedie: getDedie,
            classe: selectedOption1.value,
            livrecherche: livreCherche,
        }

        API.put(`/livres/BayId/${livreId}`, formData)
            .then((res) => {
                toast.success('La modification du livre a été effectuée avec succès.')
                History(`/Livres/DetailLivre/${id}`)
            })
            .catch((err) => {
                if (err.response) {
                    if (Array.isArray(err.response.data)) {
                        toast.warn(err.response.data[0])
                    } else if (typeof err.response.data === 'object') {
                        // Si c'est un objet, afficher la valeur (par exemple, en la convertissant en chaîne JSON)
                        toast.warn(JSON.stringify(err.response.data))
                    } else if (typeof err.response.data === 'string') {
                        // Si c'est une chaîne de caractères, l'afficher directement
                        toast.warn(err.response.data)
                    } else {
                        // Si c'est autre chose, afficher un message par défaut
                        toast.warn('Erreur inattendue lors de la récupération des données de réponse')
                    }
                    return false;
                }
                console.log(err.message)
            })
            .finally(() => {
                console.log("")
            });
    }


    return (

        <div className={`bg-white  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
            <Link to={`/Livres/DetailLivre/${id}`} className="ml-2 text-[18px] text-blue-500 hover:text-blue-800 underline">Retour</Link>

            <div className="sm:p-4 p-2 min-h-screen">
                <div class="my-5">
                    <div class="w-full flex flex-row items-center justify-between">
                        <p class="text-navy-700   ">
                            <a
                                class="hover:text-navy-700 text-2xl font-bold  "

                            >
                                Modifier un Livre
                            </a>
                        </p>

                    </div>
                </div>
                <div class="w-full my-4 grid grid-cols-1 ">
                    <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">
                        <form onSubmit={handleSubmit}>
                            <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col my-2">
                                <div class="-mx-3 md:flex mb-6  ">
                                    <div class={`md:w-1/2 px-3 mb-6 md:mb-0`}>
                                        <label
                                            class="block uppercase tracking-wide  text-lg font-bold mb-2"
                                            for="grid-Title"
                                        >
                                            Titre
                                        </label>

                                        <input
                                            class={` outline-none block w-full bg-grey-lighter  border rounded py-3 px-4 mb-3 ${titre.trim() === '' ? animationClasstitre : 'focus:border-2 focus:border-blue-700'} `}
                                            type="text"
                                            ref={elemetReftitre} value={titre} onInput={(e) => Settitre(e.target.value)}
                                            placeholder="Title du Livre"
                                        />
                                    </div>
                                    <div class={`md:w-1/2 px-3`}>
                                        <label
                                            class="block uppercase tracking-wide  text-lg font-bold mb-2"
                                            for="grid-last-name"
                                        >
                                            Source
                                        </label>
                                        <input
                                            ref={elemetRefsource} value={source} onInput={(e) => SetSource(e.target.value)}
                                            class={`appearance-none outline-none block w-full bg-grey-lighter  border rounded py-3 px-4  ${source.trim() === '' ? animationClasssource : 'focus:border-2 focus:border-blue-700'}`}
                                            id="grid-last-name"
                                            type="text"
                                            placeholder="Source du Livre"
                                        />
                                    </div>
                                    <div class={`md:w-1/2 px-3 mb-6 md:mb-0`}>
                                        <label
                                            class="block uppercase tracking-wide  text-lg font-bold mb-2"
                                            for="grid-last-name"
                                        >
                                            Livre recherche
                                        </label>
                                        <select
                                            class={`block appearance-none w-full bg-white cursor-pointer border text-gray-600   py-3 px-4 pr-8 rounded  ${animationClasslivreCherche}`}
                                            onChange={(e) => { SetlivreCherche(e.target.value) }}
                                            ref={elemetRefsource}
                                            value={livreCherche}
                                        >
                                            <option value='' hidden >Livre recherche</option>
                                            <option value='Oui'>Oui</option>
                                            <option value='Non'>Non</option>
                                        </select>

                                    </div>
                                </div>

                                <div class={`-mx-3 md:flex mb-6`}>

                                    <div class={` px-3 ${getDedie === 'Tous' || getDedie == '' ? 'w-64' : 'md:w-1/2'}`}>
                                        <label
                                            class="block uppercase tracking-wide  text-lg font-bold mb-2"
                                            for="grid-state"
                                        >
                                            Dediée Aux
                                        </label>
                                        <div class="relative flex flex-row space-x-3">
                                            <select
                                                className={`block appearance-none cursor-pointer w-full bg-white border text-gray-600 py-3 px-4 pr-8 rounded ${animationClassgetDedie}`}
                                                id="grid-state"
                                                ref={elemetRefdedie}
                                                onChange={(e) => { SetgetDedie(e.target.value) }}
                                                value={getDedie} // Assurez-vous que la valeur sélectionnée est contrôlée par l'état
                                            >
                                                <option value='' hidden> Dediée Aux</option>
                                                <option value='Enseignant'>Enseignant</option>
                                                <option value='Elèves'>Elèves</option>
                                                <option value='Tous'>Tous</option>
                                            </select>
                                            {
                                                getDedie === 'Tous' || getDedie == '' ?
                                                    null : (
                                                        <>
                                                            <div class="md:w-1/2 px-3">
                                                                <label
                                                                    class="block uppercase tracking-wide text-gray-700 text-lg font-bold mb-2"
                                                                    for="grid-password"
                                                                >
                                                                    Classe
                                                                </label>

                                                                <div ref={elemetRefClasse} className={` rounded  ${selectedOption1.value == '' ? animationClassClasse : 'focus:border-2 focus:border-blue-700'} `}
                                                                >
                                                                    <Select
                                                                        value={selectedOption1}
                                                                        options={hiddenClassoptions}
                                                                        styles={customStyles}
                                                                        onChange={SelectClasse}
                                                                    />
                                                                </div>

                                                            </div>
                                                        </>)
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div class="mb-4 flex items-center justify-end">
                                    <div class="">
                                        <button class="mt-4 sm:mt-0 inline-flex items-start justify-start px-2 sm:px-6 py-3 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg">
                                            <p class="text-lg font-medium leading-none text-white">
                                                Modifier
                                            </p>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <FootterCopy />
        </div>

    );
}


export default ModifierBiblio;










