/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import FootterCopy from "../../FootterCopy/FootterCopy";
import { API } from "../../../Api/API";

function AccueilSecretaire({ getSizeMobille, getDivScroll }) {
  const [mobile1, Setmonile1] = useState(window.innerWidth < 621)

  const [mobile11, SetMobile11] = useState(window.innerWidth < 501)

  useEffect(() => {
    const hundlesSize = () => {
      Setmonile1(window.innerWidth < 621)
      SetMobile11(window.innerWidth < 501)
    }

    window.addEventListener('resize', hundlesSize)

    return () => {
      window.removeEventListener('resize', hundlesSize)
    }
  }, [])

  const [classeurs, getclasseurs] = useState(0);
  const [students, getstudents] = useState(0);
  const [staffs, getstaffs] = useState(0);
  const [staffsConge, getstaffsConge] = useState(0);
  const [exp, getsExp] = useState(0);
  const [courriers, getcourriers] = useState(0);

  useEffect(() => {
    API.get('/classeur/Countall')
      .then((resp) => {
        getclasseurs(resp.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);
  useEffect(() => {
    API.get('/student/CountallStudent')
      .then((resp) => {
        getstudents(resp.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  useEffect(() => {
    API.get('/staff/CountallStaff')
      .then((resp) => {
        getstaffs(resp.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);
  useEffect(() => {
    API.get('/courriers/Countallcourriers')
      .then((resp) => {
        getcourriers(resp.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);
  useEffect(() => {
    API.get('/staff/CountallExpl')
      .then((resp) => {
        getsExp(resp.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);
  useEffect(() => {
    API.get('/staff/Countallstaffconge')
      .then((resp) => {
        getstaffsConge(resp.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);



  return (
    <div className={`bg-white  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>

      <div className="p-4 min-h-screen">
        <div className="text-3xl font-bold text-gray-500">Bienvenue</div>
        <div class="my-4 w-full flex flex-wrap  gap-4">
          <div class={`bg-white shadow rounded-lg p-8 ${mobile1 ? 'w-full' : 'w-[26em]'}`}>
            <div class="flex items-center w-full">
              <div >
                <div className=" w-full flex justify-between">
                  <span class="text-2xl sm:text-3xl leading-none font-bold text-blue-900">
                    {classeurs}
                  </span>


                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-9 h-9  f  mb-4 text-blue-900 font-bold transition duration-75"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12"
                    />
                  </svg>
                </div>

                <h3 class={` font-normal  text-gray-500 ${mobile1 ? 'text-[17px] w-full text-center' : 'text-xl'}`}>
                  Nombre total   des classeurs
                </h3>
              </div>

            </div>
          </div>

          <div class={`bg-white shadow rounded-lg p-8 ${mobile1 ? 'w-full' : 'w-[26em]'}`}>
            <div class="flex items-center w-full">
              <div >
                <div className=" w-full flex justify-between">
                  <span class="text-2xl sm:text-3xl leading-none font-bold text-blue-900">
                    {students}
                  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-9 h-9  f  mb-4 text-blue-900 font-bold transition duration-75"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25"
                    />
                  </svg>
                </div>

                <h3 class={` font-normal  text-gray-500 ${mobile1 ? 'text-[17px] w-full text-center' : 'text-xl'}`}>
                  Nombre total  des dossiers des élèves
                </h3>
              </div>

            </div>
          </div>

          <div class={`bg-white shadow rounded-lg p-8 ${mobile1 ? 'w-full' : 'w-[26em]'}`}>
            <div class="flex items-center w-full">
              <div >
                <div className=" w-full flex justify-between">
                  <span class="text-2xl sm:text-3xl leading-none font-bold text-blue-900">
                    {staffs}
                  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-9 h-9 text-blue-900  flex-shrink-0 transition duration-75"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m6.75 12l-3-3m0 0l-3 3m3-3v6m-1.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                    />
                  </svg>
                </div>

                <h3 class={` font-normal  text-gray-500 ${mobile1 ? 'text-[17px] w-full text-center' : 'text-xl'}`}>
                  Nombre total  des dossiers des personnels
                </h3>
              </div>

            </div>
          </div>


          <div class={`bg-white shadow rounded-lg p-8 ${mobile1 ? 'w-full' : 'w-[26em]'}`}>
            <div class="flex items-center w-full">
              <div >
                <div className=" w-full flex justify-between">
                  <span class="text-2xl sm:text-3xl leading-none font-bold text-blue-900">
                    {staffsConge}
                  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-9 h-9 text-blue-900 te flex-shrink-0  transition duration-75"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25M9 16.5v.75m3-3v3M15 12v5.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                    />
                  </svg>
                </div>

                <h3 class={` font-normal  text-gray-500 ${mobile1 ? 'text-[17px] w-full text-center' : 'text-xl'}`}>
                  Nombre total  des conges
                </h3>
              </div>

            </div>
          </div>

          <div class={`bg-white shadow rounded-lg p-8 ${mobile1 ? 'w-full' : 'w-[26em]'}`}>
            <div class="flex items-center w-full">
              <div >
                <div className=" w-full flex justify-between">
                  <span class="text-2xl sm:text-3xl leading-none font-bold text-blue-900">
                   {exp}
                  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-9 h-9 text-blue-900 flex-shrink-0  transition duration-75"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z"
                    />
                  </svg>
                </div>

                <h3 class={` font-normal  text-gray-500 ${mobile1 ? 'text-[17px] w-full text-center' : 'text-xl'}`}>
                  Nombre total  des  Explications/Reponses
                </h3>
              </div>

            </div>
          </div>


          <div class={`bg-white shadow rounded-lg p-8 ${mobile1 ? 'w-full' : 'w-[26em]'}`}>
            <div class="flex items-center w-full">
              <div >
                <div className=" w-full flex justify-between">
                  <span class="text-2xl sm:text-3xl leading-none font-bold text-blue-900">
                   {courriers}
                  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-9 h-9 text-blue-900 flex-shrink-0 transition duration-75"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7.752.066a.5.5 0 0 1 .496 0l3.75 2.143a.5.5 0 0 1 .252.434v3.995l3.498 2A.5.5 0 0 1 16 9.07v4.286a.5.5 0 0 1-.252.434l-3.75 2.143a.5.5 0 0 1-.496 0l-3.502-2-3.502 2.001a.5.5 0 0 1-.496 0l-3.75-2.143A.5.5 0 0 1 0 13.357V9.071a.5.5 0 0 1 .252-.434L3.75 6.638V2.643a.5.5 0 0 1 .252-.434L7.752.066ZM4.25 7.504 1.508 9.071l2.742 1.567 2.742-1.567L4.25 7.504ZM7.5 9.933l-2.75 1.571v3.134l2.75-1.571V9.933Zm1 3.134 2.75 1.571v-3.134L8.5 9.933v3.134Zm.508-3.996 2.742 1.567 2.742-1.567-2.742-1.567-2.742 1.567Zm2.242-2.433V3.504L8.5 5.076V8.21l2.75-1.572ZM7.5 8.21V5.076L4.75 3.504v3.134L7.5 8.21ZM5.258 2.643 8 4.21l2.742-1.567L8 1.076 5.258 2.643ZM15 9.933l-2.75 1.571v3.134L15 13.067V9.933ZM3.75 14.638v-3.134L1 9.933v3.134l2.75 1.571Z" />
                  </svg>
                </div>

                <h3 class={` font-normal  text-gray-500 ${mobile1 ? 'text-[17px] w-full text-center' : 'text-xl'}`}>
                  Nombre total  des  courriers
                </h3>
              </div>

            </div>
          </div>




          <div class={`bg-white shadow rounded-lg p-8 ${mobile1 ? 'w-full' : 'w-[26em]'}`}>
            <div class="flex items-center w-full">
              <div >
                <div className=" w-full flex justify-between">
                  <span class="text-2xl sm:text-3xl leading-none font-bold text-blue-900">
                    2,340
                  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-9 h-9 text-blue-900 flex-shrink-0 transition duration-75"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12"
                    />
                  </svg>
                </div>

                <h3 class={` font-normal  text-gray-500 ${mobile1 ? 'text-[17px] w-full text-center' : 'text-xl'}`}>
                  Nombre total  des  Registres
                </h3>
              </div>

            </div>
          </div>




          <div class={`bg-white shadow rounded-lg p-8 ${mobile1 ? 'w-full' : 'w-[26em]'}`}>
            <div class="flex items-center w-full">
              <div >
                <div className=" w-full flex justify-between">
                  <span class="text-2xl sm:text-3xl leading-none font-bold text-blue-900">
                    2,340
                  </span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi h-9 text-blue-900 w-9 bi-credit-card-2-front-fill" viewBox="0 0 16 16">
                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2.5 1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-2zm0 3a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm3 0a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm3 0a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm3 0a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z" />
                  </svg>
                </div>

                <h3 class={` font-normal  text-gray-500 ${mobile1 ? 'text-[17px] w-full text-center' : 'text-xl'}`}>
                  Nombre total  des dettes bibliotheques
                </h3>
              </div>

            </div>
          </div>

          <div class={`bg-white shadow rounded-lg p-8 ${mobile1 ? 'w-full' : 'w-[26em]'}`}>
            <div class="flex items-center w-full">
              <div >
                <div className=" w-full flex justify-between">
                  <span class="text-2xl sm:text-3xl leading-none font-bold text-blue-900">
                    2,340
                  </span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-spreadsheet w-9 h-9 text-blue-900" viewBox="0 0 16 16">
                    <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V9H3V2a1 1 0 0 1 1-1h5.5v2zM3 12v-2h2v2H3zm0 1h2v2H4a1 1 0 0 1-1-1v-1zm3 2v-2h3v2H6zm4 0v-2h3v1a1 1 0 0 1-1 1h-2zm3-3h-3v-2h3v2zm-7 0v-2h3v2H6z" />
                  </svg>
                </div>

                <h3 class={` font-normal  text-gray-500 ${mobile1 ? 'text-[17px] w-full text-center' : 'text-xl'}`}>
                  Nombre total  des dettes economats
                </h3>
              </div>

            </div>
          </div>

        </div>

      </div>

      <FootterCopy />
    </div>
  );
}

export default AccueilSecretaire;
