import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import FootterCopy from '../../FootterCopy/FootterCopy'
import { format } from 'date-fns'
import { fr } from 'date-fns/locale'
import { API } from '../../../Api/API'
function RapportEncadreurs({ getSizeMobille }) {
  const [mobile, Setmonile] = useState(window.innerWidth < 1575)
  const [mobile2, Setmonile2] = useState(window.innerWidth < 842)
  const [mobile3, Setmonile3] = useState(window.innerWidth < 318)
  const [mobile4, Setmonile4] = useState(window.innerWidth < 600)
  const [mobile11, SetMobile11] = useState(window.innerWidth < 501)

  useEffect(() => {
    const handleResize = () => {
      Setmonile(window.innerWidth < 1575)
      Setmonile2(window.innerWidth < 842)
      Setmonile3(window.innerWidth < 318)
      Setmonile4(window.innerWidth < 600)
      SetMobile11(window.innerWidth < 501)
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  const staffId = 21

  const [searchTerm, setSearchTerm] = useState('')
  const [rapports, setrapports] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [pageSize, setPageSize] = useState(20)
  const [totalElements, setTotalElements] = useState(0)

  const [etat, setEtat] = useState(1)
  const handleEtatChange = (etat) => {
    setEtat(etat)
  }
  const [rechercher, setRechercher] = useState(false)
  const [isrechercher, setisRechercher] = useState(0)
  function handleSearch() {
    setRechercher(true)
    setisRechercher(isrechercher + 1)
  }

  useEffect(() => {
    fetchrapports(etat, currentPage, pageSize, searchTerm, rechercher)
  }, [currentPage, pageSize, etat, isrechercher])

  const fetchrapports = async (etat, page, size, searchTerm, rechercher) => {
    try {
      let url = `/HI/rapportsGarde/${staffId}?page=${page}&pageSize=${size}`
      if (rechercher) {
        url = `/HI/rapportsGarde/${staffId}?page=${page}&pageSize=${size}&searchTerm=${searchTerm}`
      }
      const response = await API.get(url)
      const { data, totalPages, totalItems } = response.data
      setrapports(data)
      setTotalPages(totalPages)
      setTotalElements(totalItems)
    } catch (error) {
      console.error(
        'Erreur lors de la récupération des rapports :',
        error.message,
      )
    }
  }

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const handlePageSizeChange = (size) => {
    setPageSize(size)
    setCurrentPage(1)
  }

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1)
    }
  }

  const handlerapportsiousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  const formatDate = (date) => {
    return format(new Date(date), "'Le 'd, MMMM yyyy", { locale: fr })
  }

  const getRange = () => {
    const from = (currentPage - 1) * pageSize + 1
    const to = Math.min(currentPage * pageSize, totalElements)
    return `${from} à ${to} rapports sur ${totalElements}`
  }

  return (
    <div
      className={`bg-white overflow-y-auto overflow-x-hidden ${
        mobile11 ? 'h-[90vh]' : 'h-[85vh]'
      } ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}
    >
      <div className="sm:p-4 p-1 min-h-screen">
        <div className="sm:p-4 p-1">
          <div className="">
            <div className="w-full flex flex-row items-center justify-between">
              <p
                className={`text-navy-700 ${
                  mobile4 ? 'text-[18px]' : 'text-[25px]'
                } text-gray-600`}
              >
                <p className="hover:text-navy-700 font-bold max-sm:text-center text-gray-600 font-serif">
                  Rapport
                </p>
              </p>
              <Link
                to="/Rapport/ajout"
                class="p-2 flex justify-center items-center bg-blue-200 h-[3em] w-[3em] rounded-full"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-plus-circle-fill h-7 w-7 text-blue-700 "
                  viewBox="0 0 16 16"
                >
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z" />
                </svg>
              </Link>
            </div>
          </div>
        </div>
        <div className={`${mobile2 ? 'mb-5' : ''}`}>
          <div class="">
            <div class="">
              <div class="">
                <div className=" flex items-center">
                  <p className="sm:text-xl">Taille de la page :</p>
                  <select
                    value={pageSize}
                    onChange={(e) =>
                      handlePageSizeChange(Number(e.target.value))
                    }
                    className="ml-2 p-2.5 cursor-pointer bg-white border border-gray-300 rounded"
                  >
                    <option value={1}>1</option>
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`my-2 w-full gap-2 p-1 sm:p-2`}>
          <div
            className={`bg-white shadow-xl border w-full mb-10 border-fuchsia-400 rounded-lg p-4 `}
          >
            <div className="flex flex-col">
              <div
                className={`text-[18px] flex justify-between  w-full text-center mt-4 font-normal text-gray-500 ${
                  mobile3 ? 'flex-col' : ''
                }`}
              >
                <div
                  className={`w-full border border-fuchsia-200 rounded-lg ${
                    mobile3 ? 'mb-3' : ''
                  }`}
                >
                  <table className="w-full p-2">
                    <thead className="">
                      <tr className="border-b border-gray-200 p-2">
                        <th
                          className={`text-left p-2 border-r ${
                            mobile4 ? 'text-[10px]' : ''
                          }`}
                        >
                          Date
                        </th>
                        <th
                          className={`text-left p-2 border-r ${
                            mobile4 ? 'text-[10px]' : ''
                          }`}
                        >
                          Heures
                        </th>
                        <th
                          className={`text-left p-2 border-r ${
                            mobile4 ? 'text-[10px]' : ''
                          }`}
                        >
                          Description
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-gray-400">
                      {rapports &&
                        rapports.length > 0 &&
                        rapports.map((rapport, index) => (
                          <tr
                            key={index}
                            className={`mt-3 text-left border-b border-gray-200 ${
                              index === rapports.length - 1 ? '' : 'border-b-0'
                            }`}
                          >
                            <td
                              className={`p-2 border-r ${
                                mobile4 ? 'text-[10px]' : ''
                              }`}
                            >
                              {rapport.date && formatDate(rapport.date)}
                            </td>
                            <td
                              className={`p-2 border-r ${
                                mobile4 ? 'text-[10px]' : ''
                              }`}
                            >
                              {rapport.time}
                            </td>
                            <td
                              className={`p-2 border-r ${
                                mobile4 ? 'text-[10px]' : ''
                              }`}
                            >
                              {rapport.description}
                            </td>
                          </tr>
                        ))}
                    </tbody>

                    {rapports && rapports.length > 0 && (
                      <>
                        {/* Pagination */}
                        {/* Pagination controls */}
                        <div className="mt-4 flex items-center justify-center">
                          <div className="flex items-center">
                            <button
                              disabled={currentPage === 1}
                              onClick={handlerapportsiousPage}
                              className="mx-1 px-3 py-1 rounded bg-gray-300 text-gray-700"
                            >
                              Précédent
                            </button>
                            {Array.from(
                              { length: totalPages },
                              (_, index) => index + 1,
                            ).map((pageNumber) => (
                              <button
                                key={pageNumber}
                                onClick={() => handlePageChange(pageNumber)}
                                className={`mx-1 px-3 py-1 rounded ${
                                  currentPage === pageNumber
                                    ? 'bg-gray-700 text-white'
                                    : 'bg-gray-300 text-gray-700'
                                }`}
                              >
                                {pageNumber}
                              </button>
                            ))}
                            <button
                              disabled={currentPage === totalPages}
                              onClick={handleNextPage}
                              className="mx-1 px-3 py-1 rounded bg-gray-300 text-gray-700"
                            >
                              Suivant
                            </button>
                          </div>
                        </div>

                        <div className="mt-4 flex items-center justify-center">
                          <p className="text-gray-600">{getRange()}</p>
                        </div>
                      </>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FootterCopy />
    </div>
  )
}

export default RapportEncadreurs
