import React from 'react'
import SideBarBibliote from '../SideBar/SideBarBibliote'
import PrevisioModifier from './PrevisioModifier'
function PrevisioModifierComponent({ getSizeMobille, getMenuMobille }) {
    return (
        <div className='flex justify-between overflow-hidden bg-white'>
            <SideBarBibliote getMenuMobille={getMenuMobille} />
            <PrevisioModifier getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default PrevisioModifierComponent
