import React, { useState } from "react";
import ListeRecusComponent from "../../component/Secretariat/Courriers/Recus/ListeRecusComponent";
function ListeRecusPage({getMenuMobille,getSizeMobille}) {
  return (
    <>
      <ListeRecusComponent getSizeMobille={getSizeMobille} getMenuMobille={getMenuMobille} />
    </>
  );
}

export default ListeRecusPage;
