import React from 'react'
import SideBarsPrefetDisplin from '../SideBarsPrefetDisplin/SideBarsPrefetDisplin'
import HorairePrefetDisplin from './HorairePrefetDisplin'
function HorairePrefetDisplinComponent({ getSizeMobille, getMenuMobille }) {
    return (
        <div className='flex justify-between overflow-hidden bg-white'>
            <SideBarsPrefetDisplin getMenuMobille={getMenuMobille} />
            <HorairePrefetDisplin getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default HorairePrefetDisplinComponent
