import React from 'react'
import SlideNavInfermie from '../SlideNavInfermie/SlideNavInfermie'
import PrevisionInfirmierModifier from './PrevisionInfirmierModifier'
function PrevisionInfirmierModifierComponent() {
    return (
        <div className='flex justify-between overflow-hidden bg-white'>
            <SlideNavInfermie />
            <PrevisionInfirmierModifier />
        </div>
    )
}

export default PrevisionInfirmierModifierComponent
