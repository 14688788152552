import React, { useState } from "react";
import SlideNavInfermie from "../SlideNavInfermie/SlideNavInfermie";
import Medicament from "./Medicament";
function MedicamentComponent({ getSizeMobille, getMenuMobille }) {
  return (
    <>

      <div class="overflow-hidden flex justify-between">
        <SlideNavInfermie getMenuMobille={getMenuMobille} />
        <Medicament getSizeMobille={getSizeMobille} />
      </div>
    </>
  );
}

export default MedicamentComponent;
