import React, { useState } from "react";
import SideBar from "../SideBar/SideBarBibliote";
import CartsEleve from "./CartsEleve";
function CartsEleveComponent({ getSizeMobille, getMenuMobille }) {
  return (
    <>
      {" "}
      <div class="overflow-hidden flex justify-between">
        <SideBar getMenuMobille={getMenuMobille} />
        <CartsEleve getSizeMobille={getSizeMobille} />
      </div>
    </>
  );
}

export default CartsEleveComponent;
