
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import FootterCopy from "../../FootterCopy/FootterCopy";
import { Link } from "react-router-dom";
import { refectoireDetailData } from "../../../Data/Data";
import { API } from "../../../Api/API";

function DetailRefectoirePrefetDisplin({ getSizeMobille }) {
    const [mobile, Setmonile] = useState(window.innerWidth < 1575)
    const [mobile2, Setmonile2] = useState(window.innerWidth < 842)
    const [mobile3, Setmonile3] = useState(window.innerWidth < 318)
    const [mobile4, Setmonile4] = useState(window.innerWidth < 600)
    const [mobile11, SetMobile11] = useState(window.innerWidth < 501)

    useEffect(() => {
        const hundlesSize = () => {
            Setmonile(window.innerWidth < 1575)
            Setmonile2(window.innerWidth < 842)
            Setmonile3(window.innerWidth < 318)
            Setmonile4(window.innerWidth < 600)
            SetMobile11(window.innerWidth < 501)
        }
        window.addEventListener('resize', hundlesSize)
        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])


    const [repartition, setRepation] = useState([])
    useEffect(() => {
        API.get('/SM/repartition').then((res) => {
            //console.log(res.data)
            setRepation(res.data)
        }).catch((err) => {
            console.log(err.message)
        })
    }, [])


    return (

        <div className={`bg-white  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
            <Link to='/Refectoire' className="ml-2 text-[18px] text-blue-500 hover:text-blue-800 underline">Retour</Link>
            <div className="sm:p-4 p-2 min-h-screen">
                <div classNameName="sm:p-4 p-2 ">
                    <div className="sm:my-10 my-2">
                        <div className="w-full flex flex-row items-center justify-between">
                            <p className="text-navy-700 shrink   text-gray-600">
                                <p className={`hover:text-navy-700 font-bold  text-gray-600 font-serif ${mobile3 ? 'text-[18px] ' : 'text-[25px] '}`}                               >
                                    Liste des élèves par table
                                </p>
                            </p>
                        </div>
                    </div>
                </div>
                {repartition && repartition.length > 0 && repartition.map((rep) => {
                    return (
                        <>
                            <ul class="space-y-4 text-left text-gray-500 dark:text-gray-400">
                                <li class="flex items-center space-x-3 rtl:space-x-reverse">
                                    <svg class="flex-shrink-0 w-3.5 h-3.5 text-green-500 dark:text-green-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5" />
                                    </svg>
                                    <span>Numero de salle: <span class="font-semibold text-gray-900 dark:text-white">{rep.salleNumero}</span></span>
                                </li>
                                <li class="flex items-center space-x-3 rtl:space-x-reverse">
                                    <svg class="flex-shrink-0 w-3.5 h-3.5 text-green-500 dark:text-green-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5" />
                                    </svg>
                                    <span>Nombre de Table: <span class="font-semibold text-gray-900 dark:text-white">{rep.salleTable} </span></span>
                                </li>
                                <li class="flex items-center space-x-3 rtl:space-x-reverse">
                                    <svg class="flex-shrink-0 w-3.5 h-3.5 text-green-500 dark:text-green-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5" />
                                    </svg>
                                    <span>Effectif par table: <span class="font-semibold text-gray-900 dark:text-white">{rep.salleEffectifiParTable}</span></span>
                                </li>
                            </ul>


                            <div className={`my-4 w-full grid  gap-2 p-2 sm:p-4  ${mobile4 ? 'grid-cols-1' : mobile2 ? 'grid-cols-2' : mobile ? 'grid-cols-3' : 'grid-cols-4'}`}>
                                {rep && rep.tableContent && rep.tableContent.length > 0 && rep.tableContent.map((data, posi) => {
                                    return (
                                        <div key={posi} className={`bg-white border border-fuchsia-400 rounded-lg p-4 `}>
                                            <div className="flex   flex-col">
                                                <div className=" flex  flex-col">
                                                    <div className={` text-blue-700  font-semibold my-2 ${mobile3 ? 'text-[13px]' : 'text-[16px]'} `}>
                                                        Nombres des élèves : <span>{data.length}</span>
                                                    </div>
                                                </div>
                                                {data.length > 0 && data.map((datas, key) => {
                                                    return (
                                                        <div key={key} className={`text-[18px] flex justify-between mt-4  w-full text-center font-normal text-gray-500 ${mobile3 ? 'flex-col' : ''}`}>
                                                            <div className={`p-2 relative  w-full border-t-2 border-blue-300  ${mobile3 ? 'py-3' : ''} `}>
                                                                <div className="w-8 h-8 absolute left-[45%] -top-[19%] bg-blue-300 text-blue-600 flex justify-center items-center rounded-full">
                                                                    <div>{key + 1}</div>
                                                                </div>
                                                                <div className={`flex w-full items-start flex-col mt-3 ${mobile3 ? 'text-[13px] ' : 'text-[16px]'}`}>
                                                                    <div><span className="font-semibold">Nom: </span> {datas.etudiant}</div>
                                                                    <div><span className="font-semibold">Classe: </span> {datas.classe}</div>
                                                                    <div><span className="font-semibold">Genre: </span> {datas.genre}</div>
                                                                    <div><span className="font-semibold">Tour: </span> {datas.tour}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                                <div className={`flex mt-2  w-full border-t-2 border-fuchsia-100 text-gray-600 ${mobile3 ? 'text-[10px]' : ''} `}><div>Année scolaire :</div> <div className="text-blue-700 font-semibold ml-1"> {rep.lastSchoolYear}</div></div>
                                            </div>

                                        </div>
                                    )
                                })}
                            </div>
                        </>
                    )
                })}



            </div>
            <FootterCopy />
        </div>


    );
}

export default DetailRefectoirePrefetDisplin;