import React from 'react'
import SideBarsPrefetDisplin from '../SideBarsPrefetDisplin/SideBarsPrefetDisplin'
import ModSalleClassePrefetDisplin from './ModSalleClassePrefetDisplin'
function ModSalleClassePrefetDisplinComponent({ getSizeMobille, getMenuMobille }) {
    return (
        <div className='flex justify-between bg-white overflow-hidden'>
            <SideBarsPrefetDisplin getMenuMobille={getMenuMobille} />
            <ModSalleClassePrefetDisplin getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default ModSalleClassePrefetDisplinComponent
