import React, { useState } from "react";
import SideBarSecretaire from "../../SideBarSecretaire/SideBarSecretaire";
import DetailExpedier from "./DetailExpedier";
function DetailExpedierComponent() {
  return (
    <>
      {" "}
      <div class="flex overflow-hidden bg-white justify-center">
        <SideBarSecretaire />
        <DetailExpedier />
      </div>
    </>
  );
}

export default DetailExpedierComponent;
