import React from 'react'
import MaterielListeEncadrementEmpruntPersonne from './MaterielListeEncadrementEmpruntPersonne'
import SlideEncadrementNavBars from '../SlideEncadrementNavBars/SlideEncadrementNavBars'
function MaterielListeEncadrementEmpruntPersonneCompomenent({ getSizeMobille, getMenuMobille }) {
    return (
        <div className='flex justify-between overflow-hidden bg-white'>
            <SlideEncadrementNavBars getMenuMobille={getMenuMobille} />
            <MaterielListeEncadrementEmpruntPersonne getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default MaterielListeEncadrementEmpruntPersonneCompomenent
