import React from 'react'
import SideBarSecretaire from '../SideBarSecretaire/SideBarSecretaire'
import DemandeReponseDetail from './DemandeReponseDetail'
function DemandeReponseDetailComponent({ getSizeMobille, getMenuMobille }) {
    return (
        <div className='flex justify-between overflow-hidden bg-white'>
            <SideBarSecretaire getMenuMobille={getMenuMobille} />
            <DemandeReponseDetail getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default DemandeReponseDetailComponent
