import React from 'react'
import AddHoraireEncaPreftDisplin from './AddHoraireEncaPreftDisplin'
import SideBarsPrefetDisplin from '../SideBarsPrefetDisplin/SideBarsPrefetDisplin'
function AddHoraireEncaPreftDisplinCompoenent({ getSizeMobille, getMenuMobille }) {
    return (
        <div className='justify-between flex overflow-hidden bg-white'>
            <SideBarsPrefetDisplin getMenuMobille={getMenuMobille} />
            <AddHoraireEncaPreftDisplin getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default AddHoraireEncaPreftDisplinCompoenent
