import React from 'react'
import SlideEncadrementNavBars from '../SlideEncadrementNavBars/SlideEncadrementNavBars'
import Previsiondetail from './Previsiondetail'
function PrevisiondetailComponen({ getSizeMobille, getMenuMobille }) {
    return (
        <div className='flex justify-between overflow-hidden bg-white'>
            <SlideEncadrementNavBars getMenuMobille={getMenuMobille} />
            <Previsiondetail getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default PrevisiondetailComponen
