import React from 'react'
import MaterielEncadrementEmpruntPersonne from './MaterielEncadrementEmpruntPersonne'
import SlideEncadrementNavBars from '../SlideEncadrementNavBars/SlideEncadrementNavBars'
function MaterielEncadrementEmpruntPersonneComponent({ getSizeMobille, getMenuMobille }) {
    return (
        <div className='flex justify-between overflow-hidden bg-white'>
            <SlideEncadrementNavBars getMenuMobille={getMenuMobille} />
            <MaterielEncadrementEmpruntPersonne getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default MaterielEncadrementEmpruntPersonneComponent
