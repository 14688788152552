import React from "react";
import AjouterReponseSecretaireComponent from "../../component/Secretariat/DemandeReponse/AjouterReponseSecretaireComponent";

function AjouterReponseSecretairePage({ getSizeMobille, getMenuMobille }) {
  return (
    <>
      <AjouterReponseSecretaireComponent getMenuMobille={getMenuMobille} getSizeMobille={getSizeMobille} />
    </>
  );
}

export default AjouterReponseSecretairePage;
