/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable eqeqeq */
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FadeLoader } from 'react-spinners'
import FootterCopy from "../../FootterCopy/FootterCopy";
import { toast } from "react-toastify";
import { decryptData } from "../../../encryptionModule";
import { API } from "../../../Api/API";
import "./sweetalert.css";
import swal from 'sweetalert';
function MaterielDetailPrefetDisplin({ getSizeMobille }) {
    const { id } = useParams()
    const matId = decryptData(id)
    const [mobile1, Setmonile1] = useState(window.innerWidth < 990)
    const [mobile2, Setmonile2] = useState(window.innerWidth < 520)
    const navigate = useNavigate()
    const [MaterielPrefet, setMaterielPrefet] = useState({})
    useEffect(() => {
        API.get(`/materielinternant/total-emprunts/${matId}`).then((res) => {
            setMaterielPrefet(res.data)
        }).catch((err) => {
            console.log(err.message);
        })
    }, [matId])
    useEffect(() => {
        const hundlesSize = () => {
            Setmonile1(window.innerWidth < 990)
            Setmonile2(window.innerWidth < 520)
        }
        window.addEventListener('resize', hundlesSize)
        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])


    const [mobile11, SetMobile11] = useState(window.innerWidth < 501)

    useEffect(() => {
        const hundlesSize = () => {
            SetMobile11(window.innerWidth < 501)
        }
        window.addEventListener('resize', hundlesSize)
        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])

    const [formulaire, GetFormulaire] = useState(false);
    const [formboutMotifLoading, setforMotivemboutLoading] = useState(false);


    const [no_fonction, setno_fonction] = useState("");
    const [animationClassno_fonction, setAnimationClassno_fonction] = useState("");
    const no_fonctionRef = useRef(null);

    const [textarea, setTextarea] = useState("");
    const [animationClassTextarea, setAnimationClassTextarea] = useState("");
    const textaresRef = useRef(null);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (no_fonction.trim() === "") {
            toast.warning("Ce nombres est obligatoire", {
                autoClose: 2000,
            });
            setAnimationClassno_fonction(
                "animate__animated animate__shakeX placeholder-shown:border-red-500"
            );
            setTimeout(() => {
                setAnimationClassno_fonction("");
            }, 3000);
            no_fonctionRef.current.focus();
            return;
        }
        if (textarea.trim() === "") {
            toast.warning("Le motif est nécessaire", {
                autoClose: 2000,
            });
            setAnimationClassTextarea(
                "animate__animated animate__shakeX placeholder-shown:border-red-500"
            );
            setTimeout(() => {
                setAnimationClassTextarea("");
            }, 3000);
            textaresRef.current.focus();
            return;
        }
        const data = {
            nombre: no_fonction,
            description: textarea,
            materielinternantId: matId
        }
        API.post('/materielinternant/matnotfonctionnel', data).then((resp) => {
            toast.success('L\'entrée a été ajoutée avec succès.', {
                autoClose: 5000,
            });
            GetFormulaire(false)
            setno_fonction('')
            setTextarea('')
        }).catch((err) => {
            console.error(err); // Affichez l'erreur complète pour un meilleur débogage
            if (err.response && err.response.data && err.response.data) {
                toast.error(err.response.data, {
                    autoClose: 5000,
                });
            } else {
                toast.error('Une erreur est survenue lors de l\'ajout de l\'entrée.', {
                    autoClose: 5000,
                });
            }
        });
    }

    const handleDelete = async (noteretrId) => {
        try {
            const result = await swal({
                title: "Confirmation",
                text: "Voulez-vous supprimer ?",
                icon: "warning",
                buttons: ["Non", "Oui"],
                dangerMode: true,
                className: "centered-buttons",
            });

            if (result) {
                const res = await API.delete(`/materielinternant/deleteOne/${noteretrId}`);
                toast.success("Vous avez supprimé avec succès!");
                navigate('/Materiel')
            } else {
                console.log("Annulé");
            }
        } catch (error) {
            toast.warn("Une erreu est survenue, il faut tester si on a emprenter une materiel ou il y des cas de manquant!");
            console.log("Erreur ou annulation :", error.message);
        }
    };


    return (
        <>
            <div className={`bg-white select-none  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
                <Link to='/Materiel' className="ml-2 text-[18px] text-blue-500 hover:text-blue-800 underline">Retour</Link>
                <div className="p-6 min-h-[90vh]">
                    <div class="w-full my-4 ">
                        <div class="bg-white border relative overflow-hidden rounded-lg p-4 ">
                            <div class={`flex items-center justify-between ${mobile2 ? 'flex-col' : ''}`}>
                                <div>
                                    <h3 class="sm:text-xl font-bold text-gray-900">
                                        Détails du matériel
                                    </h3>
                                </div>
                            </div>
                            <div class="flex flex-col ">
                                <div class="bg-gray-100 h-screen flex items-center justify-center">
                                    <div class="bg-white p-8 rounded-lg  h-full w-full">
                                        <div class="mb-4 flex items-center">
                                            <label class="text-[20px] font-semibold  block">Nom du matériel:</label>
                                            <p class="text-[18px] text-gray-600 ml-2 ">{MaterielPrefet.nom_materiel}</p>
                                        </div>

                                        <div class="mb-4 flex items-center">
                                            <label class="text-[20px] font-semibold  block">Type:</label>
                                            <p class="text-[18px] text-gray-600 ml-2">{MaterielPrefet.type}</p>
                                        </div>

                                        <div class="mb-6 flex items-center">
                                            <label class="text-[20px] font-semibold  block">Total:</label>
                                            <div>
                                                <span class="text-[18px] font-semibold text-green-500 ml-2 ">{MaterielPrefet.nombre_total}</span>
                                                <span className="text-[25px] ml-2">≒</span>
                                                <span class="text-[18px] font-semibold text-fuchsia-600 ml-2 ">{(MaterielPrefet.nombre_total * 100 / MaterielPrefet.nombre_total).toFixed(2)}%</span>
                                            </div>
                                        </div>

                                        <div class="mb-6">
                                            <div className="flex items-center">
                                                <label class="text-[20px] font-semibold  block">Disponible:</label>
                                                <div>
                                                    <span class="text-[18px] font-semibold text-green-500 ml-2 ">{MaterielPrefet.disponible}</span>
                                                    <span className="text-[25px] ml-2">≒</span>
                                                    <span class="text-[18px] font-semibold text-fuchsia-700 ml-2 ">{(MaterielPrefet.disponible * 100 / MaterielPrefet.nombre_total).toFixed(2)}%</span>
                                                </div>
                                            </div>
                                            <div className="text-gray-400 text-[13px]"> par rapport au nombre total</div>
                                        </div>

                                        <div class="mb-6">
                                            <div className="flex items-center">
                                                <label class="text-[20px] font-semibold  block">Manquant:</label>
                                                <div>
                                                    <span class="text-[18px] font-semibold text-green-500 ml-2 ">{MaterielPrefet.manquant}</span>
                                                    <span className="text-[25px] ml-2">≒</span>
                                                    <span class="text-[18px] font-semibold text-fuchsia-700 ml-2 ">{(MaterielPrefet.manquant * 100 / MaterielPrefet.nombre_total).toFixed(2)}%</span>
                                                </div>
                                            </div>
                                            <div className="text-gray-400 text-[13px]"> par rapport au nombre total</div>
                                        </div>
                                        <div class="mb-6">
                                            <div className="flex items-center">
                                                <label class="text-[20px] font-semibold  block">Fonctionnel:</label>
                                                <div>
                                                    <span class="text-[18px] font-semibold text-green-500 ml-2 ">{MaterielPrefet.fonctionnel}</span>
                                                    <span className="text-[25px] ml-2">≒</span>
                                                    <span class="text-[18px] font-semibold text-fuchsia-700 ml-2 ">{(MaterielPrefet.fonctionnel * 100 / MaterielPrefet.disponible).toFixed(2)}%</span>
                                                </div>
                                            </div>
                                            <div className="text-gray-400 text-[13px]"> par rapport au nombre disponible</div>
                                        </div>
                                        <div class="mb-6">
                                            <div className="flex items-center">
                                                <label class="text-[20px] font-semibold  block">Non fonctionel:</label>
                                                <div>
                                                    <span class="text-[18px] font-semibold text-green-500 ml-2 ">{MaterielPrefet.no_fonctionnel}</span>
                                                    <span className="text-[25px] ml-2">≒</span>
                                                    <span class="text-[18px] font-semibold text-fuchsia-700 ml-2 ">{(MaterielPrefet.no_fonctionnel * 100 / MaterielPrefet.disponible).toFixed(2)}%</span>
                                                </div>
                                            </div>
                                            <div className="text-gray-400 text-[13px]"> par rapport au nombre disponible</div>
                                        </div>
                                        <div class="mb-6">
                                            <div className="flex items-center">
                                                <label class="text-[20px] font-semibold  block">Emprunte:</label>
                                                <div>
                                                    <span class="text-[18px] font-semibold text-green-500 ml-2 ">{MaterielPrefet.nombre_emprunt}</span>
                                                    <span className="text-[25px] ml-2">≒</span>
                                                    <span class="text-[18px] font-semibold text-fuchsia-700 ml-2 ">{(MaterielPrefet.nombre_emprunt * 100 / MaterielPrefet.fonctionnel).toFixed(2)}%</span>
                                                </div>
                                            </div>
                                            <div className="text-gray-400 text-[13px]"> par rapport au nombre fonctionnel</div>
                                        </div>
                                        <div className="mt-10">
                                            <Link to={`/Materiel/Modifier/${id}`} className="bg-blue-200 text-blue-700 mx-1 py-1 px-2 rounded">Modifier</Link>
                                            <button onClick={() => handleDelete(matId)} className="bg-red-200 text-red-700 mx-1 py-1 px-2 rounded">Supprimer</button>
                                            <Link to={`/Materiel/detail/Info/${id}`} className="bg-yellow-200 text-yellow-700 mx-1 py-1 px-2 rounded">Information</Link>
                                            <button onClick={() => { GetFormulaire(true) }} className="bg-fuchsia-200 text-fuchsia-700 mx-1 py-1 px-2 rounded">Desactive</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={`w-full absolute  flex justify-center p-2 top-0 left-0 h-full bg-[#000000c2]  z-[900] ${formulaire ? ' transition-all opacity-100 pointer-events-auto delay-100' : 'opacity-0 pointer-events-none'}`}>
                                <form onSubmit={handleSubmit} className={`w-[99%]  bottom-2 absolute flex justify-center items-center p-4 rounded-2xl  bg-white ${formulaire ? 'h-max transition-all opacity-100 pointer-events-auto delay-150' : 'h-0 opacity-0 pointer-events-none'} `}>
                                    <div className="relative w-full flex-col items-center flex justify-center ">
                                        <div className="w-full my-2 flex justify-end">
                                            <div onClick={() => { GetFormulaire(false) }} className="bg-red-200 p-2 rounded-full cursor-pointer">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-octagon-fill text-red-700" viewBox="0 0 16 16">
                                                    <path d="M11.46.146A.5.5 0 0 0 11.107 0H4.893a.5.5 0 0 0-.353.146L.146 4.54A.5.5 0 0 0 0 4.893v6.214a.5.5 0 0 0 .146.353l4.394 4.394a.5.5 0 0 0 .353.146h6.214a.5.5 0 0 0 .353-.146l4.394-4.394a.5.5 0 0 0 .146-.353V4.893a.5.5 0 0 0-.146-.353L11.46.146zm-6.106 4.5L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708z" />
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="mb-6 w-max bg-slate-100 p-2  pt-5 rounded-xl ">
                                            <div className="transition-all">
                                                <div className="relative  w-full flex-col flex  mr-2">
                                                    <label className="text-gray-600 font-semibold mb-2">
                                                        Nombre des matériel non fonctionnels
                                                    </label>
                                                    <input
                                                        ref={no_fonctionRef}
                                                        value={no_fonction}
                                                        onInput={(e) => setno_fonction(e.target.value)}
                                                        placeholder="Nombre des matériel non fonctionnels"
                                                        className={`py-3 px-2 border-blue-300 outline-none rounded-md border-2 focus:border-blue-700 ${animationClassno_fonction}`}
                                                    />

                                                </div>

                                                <div className="relative mt-4 text-gray-700 flex flex-col h-full w-full">
                                                    <label
                                                    >
                                                        Motif
                                                    </label>
                                                    <textarea
                                                        ref={textaresRef}
                                                        value={textarea}
                                                        onChange={(e) => {
                                                            setTextarea(e.target.value);
                                                        }}
                                                        placeholder="Motif "
                                                        className={`min-h-[12em] resize-none text-black border-blue-300 overflow-y-auto min-w-[25em] py-2 px-2 outline-none rounded-md border-2 focus:border-blue-700 ${animationClassTextarea}`}
                                                    />

                                                </div>

                                                <div className="flex justify-end items-center mt-[20px]">

                                                    {formboutMotifLoading ? (
                                                        <>
                                                            <div className="flex justify-end items-center  relative pointer-events-none opacity-80 bg-gray-900 rounded  cursor-pointer px-5 py-1 text-gray-600">
                                                                <div className='absolute bg-transparent   pt-5 pl-10  w-full h-full flex justify-center items-center z-50'>
                                                                    <FadeLoader
                                                                        color="rgb(255, 255, 255)"
                                                                        height={10}
                                                                        margin={-9}
                                                                        radius={100}
                                                                        speedMultiplier={1}
                                                                        width={1}
                                                                    /></div>

                                                                <input type="submit" id="send" value="Envoyer" class=" transition-all "></input>
                                                                <i class="bi bi-send ml-2 "></i>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>

                                                            <label htmlFor="send" className="flex bg-blue-600 rounded cursor-pointer px-2 py-1 text-white">
                                                                <input type="submit" id="send" value="Envoyer" className="cursor-pointer" />
                                                            </label>
                                                        </>
                                                    )}

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </form>
                            </div>

                        </div>
                    </div>
                </div>
                <FootterCopy />
            </div>

        </>
    );
}

export default MaterielDetailPrefetDisplin;





