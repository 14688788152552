import React from "react";
import SlideNavInfermie from "../SlideNavInfermie/SlideNavInfermie";
import MedicamentsExpires from "./MedicamentsExpires";
function MedicamentsExpiresComponent({ getSizeMobille, getMenuMobille }) {
  return (
    <>
      <div class="overflow-hidden bg-white flex justify-between">
        <SlideNavInfermie getMenuMobille={getMenuMobille} />
        <MedicamentsExpires getSizeMobille={getSizeMobille} />
      </div>
    </>
  );
}

export default MedicamentsExpiresComponent;
