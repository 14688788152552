/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { classeurs } from "../../../Data/Data";
import FootterCopy from "../../FootterCopy/FootterCopy";
import { API } from "../../../Api/API";
import { encryptData } from '../../../encryptionModule'
const initialValues = {
  numero: "",
};

const validationSchema = Yup.object().shape({
  numero: Yup.string().required("completer le numero"),
});

function Classeur({ getSizeMobille }) {
  const [nouveau, setnouveau] = useState(false);
  const onSubmit = (values, { setFieldValue, setSubmitting }) => {
    API
      .post("/classeur/add", values)
      .then((response) => {
        toast.success("Classeur Ajouté Avec Success", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          onClick: handleClose,
        });
        setnouveau(false)
      })
      .catch((error) => {
        // Handle error
        if (error.response) {
          // Le serveur a renvoyé une réponse avec un code d'erreur
          const errorMessage = error.response.data.error;
          toast.error(errorMessage, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            onClick: handleClose, // Utilisation de la référence à la fonction handleClose
          }); // Affiche le message d'erreur dans le toast
        } else if (error.request) {
          // La requête a été faite mais aucune réponse n'a été reçue
          console.log(error.request);
        } else {
          // Une erreur s'est produite lors de la configuration de la requête
          console.log("Error", error.message);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleClose = () => {
    toast.dismiss();
  };

  const [mobile1, Setmonile1] = useState(window.innerWidth < 621)
  const [mobile2, Setmonile2] = useState(window.innerWidth < 752)
  const [mobile3, Setmonile3] = useState(window.innerWidth < 457)
  const [mobile11, SetMobile11] = useState(window.innerWidth < 501)
  const [mobile4, Setmonile4] = useState(window.innerWidth < 318)
  useEffect(() => {
    const hundlesSize = () => {
      Setmonile1(window.innerWidth < 621)
      Setmonile2(window.innerWidth < 752)
      Setmonile3(window.innerWidth < 752)
      Setmonile4(window.innerWidth < 318)
      SetMobile11(window.innerWidth < 501)
    }

    window.addEventListener('resize', hundlesSize)

    return () => {
      window.removeEventListener('resize', hundlesSize)
    }
  }, [])






  const [inputValue, setInputValue] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortedColumn, setSortedColumn] = useState(null);

  const ValuesInput = (e) => {
    setInputValue(e.target.value);
  };


  const fildata = (data, inputValue) => {
    if (!inputValue.trim()) {
      return data;
    }
    return data.filter((item) => {
      if (
        item.N_Classeur.toString().toLowerCase().includes(inputValue.toLowerCase()) ||
        item.Quantite.toString().toLowerCase().includes(inputValue.toLowerCase()) ||
        item.Date_d_enregistrement.toString().toLowerCase().includes(inputValue.toLowerCase())

      ) {
        return true;
      }
      return false;
    });
  };



  const handleSort = (column) => {
    if (column === sortedColumn) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortOrder('asc');
      setSortedColumn(column);
    }
  };

  const sortData = (data, column, sortOrder) => {
    return data.slice().sort((a, b) => {
      const aValue = a[column];
      const bValue = b[column];

      if (aValue < bValue) return sortOrder === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortOrder === 'asc' ? 1 : -1;
      return 0;
    });
  };

  const filteredData = fildata(classeurs, inputValue);

  const sortedData = sortData(filteredData, sortedColumn, sortOrder);


  function highlightText(text, inputValue) {
    if (!inputValue) return text;

    const highlightedText = [];
    let currentIndex = 0;

    while (currentIndex < text.length) {
      const inputChar = inputValue.toLowerCase();
      const matchIndex = text.substr(currentIndex).toLowerCase().indexOf(inputChar);

      if (matchIndex !== -1) {

        highlightedText.push(text.substring(currentIndex, currentIndex + matchIndex));


        highlightedText.push(
          <span className="text-orange-500" key={currentIndex + matchIndex}>
            {text.substring(currentIndex + matchIndex, currentIndex + matchIndex + inputValue.length)}
          </span>
        );

        currentIndex = currentIndex + matchIndex + inputValue.length;
      } else {
        highlightedText.push(text.substring(currentIndex));
        break;
      }
    }

    return highlightedText;
  }

  const [statistics, setStatistics] = useState([]);

  useEffect(() => {
    API.get('/classeur/classeurstats')
      .then((resp) => {
        setStatistics(resp.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);



  return (
    <>
      <div className={`bg-white  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
        <div className="p-6  min-h-[85vh] w-full">
          <div class="my-5">
            <div class="w-full flex flex-row items-center justify-between">
              <p class="text-navy-700 shrink  ">
                <p
                  class={`hover:text-navy-700 text-gray-700 uppercase ${mobile3 ? '' : 'text-3xl'} font-bold`}

                >
                  LISTES DES CLASSEURS
                </p>
              </p>

            </div>
          </div>
          <div class="w-full my-4 grid grid-cols-1 ">
            <div class="bg-white shadow rounded-lg p-4 sm:p-6">
              <div class={`mb-2 flex items-center justify-between ${mobile2 && nouveau ? 'flex-col' : ''} ${mobile3 ? 'flex-col' : ''} `}>
                <div className="w-full">
                  <h3 class="text-xl font-bold text-gray-900 mb-2">
                    Listes des classeurs
                  </h3>
                </div>
                <div class="flex-shrink-0 flex flex-row space-x-2 ">
                  {nouveau ? (
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={onSubmit}
                    >
                      {({ isSubmitting, setFieldValue, values }) => (
                        <Form className="flex flex-col gap-4">
                          <div class={` space-x-2 bg-gray-200 rounded-xl  p-2 items-start justify-star flex flex-rowt ${mobile3 ? 'flex-col items-center' : 'flex-row'} `}>
                            <div class="relative lg:w-64">
                              <Field
                                type="text"
                                name="numero"
                                class="bg-white border h-10 border-gray-300 text-gray-900 sm:text-lg rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full  p-2.5"
                                placeholder="Numero du classeur ..."
                              />
                              <ErrorMessage
                                name="numero"
                                component="div"
                                className="ml-3 text-red-500"
                              />
                            </div>
                            <div className={`flex ${mobile4 ? 'flex-col w-full' : ''}`}>
                              <button
                                type="submit"
                                class="m-1 h-10 sm:mt-0 inline-flex items-start justify-start px-6 py-3 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg"
                              >
                                <p class="text-lg font-medium leading-none w-full text-center text-white">
                                  {isSubmitting ? "creation..." : "creer"}
                                </p>
                              </button>
                              <button
                                onClick={() => {
                                  setnouveau(!nouveau);
                                }}
                                class="m-1 h-10 sm:mt-0   inline-flex items-start justify-start px-6 py-3 bg-red-700 hover:bg-red-600 focus:outline-none rounded-lg"
                              >
                                <p class="text-lg font-medium text-center  w-full leading-none text-white">
                                  annuler
                                </p>
                              </button>
                            </div>
                          </div>{" "}
                        </Form>
                      )}
                    </Formik>
                  ) : (
                    <button
                      onClick={() => {
                        setnouveau(!nouveau);
                      }}
                      class={`mt-4 sm:mt-0 inline-flex items-start justify-start px-6 py-3 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg ${mobile3 ? 'w-full' : ''}`}
                    >
                      <p class={` font-medium w-full  leading-none text-white ${mobile1 ? '' : 'text-lg'}`}>
                        Nouveau Classeur
                      </p>
                    </button>
                  )}
                </div>
              </div>

              <div class="flex flex-col mt-4">
                <div class="overflow-x-auto rounded-lg">
                  <div class={`align-middle inline-block ${mobile1 ? 'min-w-[40em]' : 'min-w-full'} `}>
                    <div class=" overflow-hidden flex flex-wrap sm:rounded-lg">

                      {statistics.length > 0 ? (
                        statistics.map((stat, index) => (
                          <Link to={`/Classeur/DocClasseur/${encryptData((stat.id).toString())}`}>
                            <div
                              key={index}
                              className="bg-white border hover:scale-[1.02] border-gray-300 rounded-lg transition-all cursor-pointer my-5 mx-2 p-6 w-[max]"
                            >
                              <h3 className="sm:text-3xl text-lg font-bold mb-2 text-blue-700"> Classeur n° {stat.noClasseur}</h3>
                              <div className="border-b border-gray-200 pb-3">
                                <div>
                                  <div className="text-purple-700 text-2xl font-black">{stat.total}</div>
                                </div>
                                <div className="mb-4 flex items-center">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-short w-7 h-7" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8" />
                                  </svg>
                                  <div className="font-semibold">Total des dossier de classeur {stat.noClasseur}</div>
                                </div>
                              </div>



                              <div className=" flex flex-col  mt-7">
                                <div className="mb-4 border-b-2  border-dashed  border-gray-200  m-1">
                                  <div className="flex w-full justify-between">
                                    <div className="text-purple-700 sm:text-xl">{stat.student}</div>
                                    <div className="text-purple-700 sm:text-xl">{(stat.student * 100 / stat.total).toFixed(1)}%</div>
                                  </div>
                                  <div className="font-semibold">Dossier des élèves</div>
                                </div>
                                <div className="mb-4 border-b-2  border-dashed  border-gray-200  m-1">
                                  <div className="flex w-full justify-between">
                                    <div className="text-purple-700 sm:text-xl">{stat.courriers}</div>
                                    <div className="text-purple-700 sm:text-xl">{(stat.courriers * 100 / stat.total).toFixed(1)}%</div>
                                  </div>
                                  <div className="font-semibold">Dossier des Courriers</div>
                                </div>
                              </div>


                              <div className="mb-4 border-b-2  border-dashed  border-gray-200  m-1">
                                <div className="flex w-full justify-between">
                                  <div className="text-purple-700 sm:text-xl">{stat.staff}</div>
                                  <div className="text-purple-700 sm:text-xl">{(stat.staff * 100 / stat.total).toFixed(1)}%</div>
                                </div>
                                <div className="font-semibold">Dopssier personnel</div>
                              </div>





                              <div className="mb-4 border-b-2  border-dashed  border-gray-200  m-1">
                                <div className="flex w-full justify-between">
                                  <div className="text-purple-700 sm:text-xl">{stat.demandeexprication}</div>
                                  <div className="text-purple-700 sm:text-xl">{(stat.demandeexprication * 100 / stat.total).toFixed(1)}%</div>
                                </div>
                                <div className="font-semibold">Demande d'explication</div>
                              </div>

                              <div className="mb-4 border-b-2  border-dashed  border-gray-200  m-1">
                                <div className="flex w-full justify-between">
                                  <div className="text-purple-700 sm:text-xl">{stat.repdemandeexprication}</div>
                                  <div className="text-purple-700 sm:text-xl">{(stat.repdemandeexprication * 100 / stat.total).toFixed(1)}%</div>
                                </div>
                                <div className="font-semibold">Reponse de demande</div>
                              </div>
                            </div>

                          </Link>
                        ))
                      ) : (
                        <div className="bg-white shadow rounded-lg hover:scale-105 transition-all cursor-pointer m-2 p-8 w-full" style={{ backgroundColor: "white", boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1)", transform: "scale(1)", transition: "all 0.3s", cursor: "pointer", margin: "2px", padding: "8px", width: "100%" }}>
                          <div className={`flex justify-between ${mobile1 ? 'flex-col ' : ''} mt-10`} style={{ display: "flex", justifyContent: "space-between", marginTop: "2.5rem" }}>
                            Aucun classeur disponible
                          </div>
                          <div className="mt-5 font-serif text-gray-600" style={{ marginTop: "1.25rem", fontFamily: "serif", color: "rgb(153, 153, 153)" }}>
                            Année scolaire :<span className="text-blue-900 tracking-widest" style={{ color: "#1a4caf", letterSpacing: "widest" }}>2023-204</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FootterCopy />
      </div >
    </>
  );
}

export default Classeur;
