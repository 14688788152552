import React, { useEffect, useState } from "react";
import { useLocation, Link, useNavigate, useParams } from "react-router-dom";
import { ADD } from "../../../Redux/Action/Action";
import { useDispatch, useSelector } from "react-redux";
import FootterCopy from "../../FootterCopy/FootterCopy";
import { API } from "../../../Api/API";
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { toast } from "react-toastify";
function ListeLivres({ getSizeMobille }) {

  const getData = useSelector((state) => state.CartsReducer.Carts);
  const dispatch = useDispatch();
  function AddToCart(item) {
    dispatch(ADD(item));
  }

  const [mobile1, Setmonile1] = useState(window.innerWidth < 349)
  const [mobile11, SetMobile11] = useState(window.innerWidth < 501)
  const [mobile2, Setmonile2] = useState(window.innerWidth < 1008.9)
  useEffect(() => {
    const hundlesSize = () => {
      Setmonile1(window.innerWidth < 349)
      SetMobile11(window.innerWidth < 501)
    }

    window.addEventListener('resize', hundlesSize)

    return () => {
      window.removeEventListener('resize', hundlesSize)
    }
  }, [])

  const { id } = useParams()

  const [searchTerm, setSearchTerm] = useState("");
  const [Livres, setLivres] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [totalElements, setTotalElements] = useState(0)


  const [etat, setEtat] = useState(1)
  const handleEtatChange = (etat) => {
    setEtat(etat);
  };
  const [rechercher, setRechercher] = useState(false)
  const [isrechercher, setisRechercher] = useState(0)
  function handleSearch() {
    setRechercher(true)
    setisRechercher(isrechercher + 1)
  }



  useEffect(() => {
    fetchLivres(etat, currentPage, pageSize, searchTerm, rechercher);
  }, [currentPage, pageSize, etat, isrechercher]);

  const fetchLivres = async (etat, page, size, searchTerm, rechercher) => {
    try {
      let url = `/livres/allToEmprunt?page=${page}&pageSize=${size}&dedie=Elèves`;
      if (rechercher) {
        url = `/livres/allToEmprunt/search?page=${page}&pageSize=${size}&dedie=Elèves&searchTerm=${searchTerm}`;
      }
      const response = await API.get(url);
      setLivres(response.data.exemplaires);
      setTotalPages(response.data.totalPages);
      setTotalElements(response.data.totalItems);

    } catch (error) {
      console.error('Erreur lors de la récupération des livres :', error.message);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (size) => {
    setPageSize(size);
    setCurrentPage(1);
  };



  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const formatDate = date => {
    return format(new Date(date), "'Le 'd, MMMM yyyy", { locale: fr });
  };

  const getRange = () => {
    const from = (currentPage - 1) * pageSize + 1;
    const to = Math.min(currentPage * pageSize, totalElements);
    return `${from} à ${to} Courriers sur ${totalElements}`;
  };
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    if (searchTerm.toString().trim().length === 1) {
      setRechercher(false);
      setisRechercher(isrechercher + 1);
    }
  };

  const [values, setValues] = useState(90);

  const [indexShowInput, setIndexShowInput] = useState(null)
  const [showInpu, SetshowInpu] = useState(false)
  const GetSetshowInpu = (value, index) => {
    setIndexShowInput(index);
    SetshowInpu(value)
  };

  const hundlesSubmit = (item) => {
    return (e) => {
      e.preventDefault();
      if (values == '') {
        toast.warning('Nombre obligatoire');
      } else {
        const newItem = { ...item, jours: parseInt(values) };
        AddToCart(newItem)
        console.log('Nouvel objet avec jours:', newItem);
        setValues('90');
        SetshowInpu(false)
      }
    };
  };

  const GetValuesInput = (value) => {
    setValues(value);
  };
  return (

    <div className={`bg-white  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
      <Link to="/Emprunter/eleve/Liste" className="ml-2 text-[18px] text-blue-500 hover:text-blue-800 underline">Retour</Link>

      <div className="sm:p-4 p-2 min-h-screen">
        <div class="my-5">
          <div class="w-full flex flex-row items-center justify-between">
            <div className={`${mobile2 ? 'mb-5' : ''}`}>
              <div class="">
                <div class="">
                  <div class="">
                    <div className=" flex items-center">
                      <p className="sm:text-xl">Taille de la page :</p>
                      <select
                        value={pageSize}
                        onChange={(e) => handlePageSizeChange(Number(e.target.value))}
                        className="ml-2 p-2.5 cursor-pointer bg-white border border-gray-300 rounded"
                      >
                        <option value={1}>1</option>
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p class="text-navy-700 shrink  ">
              <p
                class="hover:text-navy-700 text-2xl font-bold"

              >
                Livres
              </p>
            </p>

            <Link
              to={`/Emprunter/eleve/Liste/Emprunt/Carts/${id}`}
              class={`relative inline-flex items-center p-3 text-lg font-medium text-center text-white bg-blue-500 rounded-lg ${getData.length < 1 ? 'pointer-events-none opacity-50' : 'pointer-events-auto opacity-100'} `}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6 text-white"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
                />
              </svg>

              <div class={`absolute inline-flex items-center justify-center w-6 h-6 text-lg font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -right-2 dark:border-gray-900 `}>
                {getData ? getData.length : "0"}
              </div>
            </Link>
            <div class="h-6 w-[224px] pt-1">


            </div>
          </div>
        </div>
        <div class={`sm:flex items-center ${mobile2 ? 'w-full ' : ''}  justify-end`}>
          <div class="sm:flex items-center justify-end">
            <div class="mx-2 z-10">
              <div class="relative lg:w-64">
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <svg
                    class="w-5 h-5 text-gray-500"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
                <input
                  type="text"
                  name="search"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  id="topbar-search"
                  class="bg-white border border-gray-300 text-gray-900 sm:text-lg rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full pl-10 p-2"
                  placeholder="Livre N° ..."
                />
              </div>
            </div>
            <button
              className="mt-4 sm:mt-0 inline-flex items-start justify-start px-6 py-3 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg"
              onClick={handleSearch}
            >
              <p className="text-lg font-medium leading-none text-white">
                Rechercher
              </p>
            </button>
          </div>

        </div>
        <div class="w-full   gap-4">
          <div class="bg-white w-full  shadow rounded-lg ">
            <div class="flex  flex-wrap space-y-3 gap-4 mt-8">
              {Livres.length > 0 ? Livres.map((item, index) => {
                return (
                  <div class="flex  rounded-lg border border-gray-400 bg-white shadow-lg  w-max">
                    <div class="flex flex-col justify-start w-full p-6">
                      <h5 class="mb-2 text-xl font-medium  ">
                        Numero: {item.numero}
                      </h5>
                      <p class="mb-4 text-xl text-neutral-600 ">
                        Titre: {item.titre}
                      </p>
                      <p class="mb-4 text-xl text-neutral-600 ">
                        Dedie: {item.dedie}
                      </p>
                      <p class="mb-4 text-xl text-neutral-600 ">
                        Class: {item.classe}
                      </p>
                      <p class="mb-4 text-xl text-neutral-600 ">
                        Livre recherche: {item.livrecherche}
                      </p>
                      <div class=" mt-8 flex w-full relative overflow-hidden  items-center justify-center ">
                        <div class="flex flex-row flex-shrink-0 space-x-3">
                          <div class="mt-4 sm:mt-0 inline-flex items-start  justify-start px-6 py-3 bg-blue-700 hover:bg-blue-600 focus:outline-none overflow-hidden rounded-lg"
                          >
                            {showInpu && (item.livrecherche == 'Oui' || item.livrecherche == 'oui') && indexShowInput === index &&
                              (
                                <div className="absolute bg-[#0000003f] z-50 top-0 h-full flex justify-center items-center   w-full  right-0">
                                  <form onSubmit={hundlesSubmit(item)} className="w-[95%] bg-white px-[3px] rounded h-[90%] flex items-center" >
                                    <div onClick={(e) => { GetSetshowInpu(false, index); e.stopPropagation() }} className="bg-red-100 text-red-800 p-1 mx-1 rounded-full cursor-pointer">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                                      </svg>
                                    </div>
                                    <div className=" border border-blue-300 w-[90%] h-[90%] rounded-md overflow-hidden">
                                      <input type="number" value={values} onInput={(e) => GetValuesInput(e.target.value)} min='1' placeholder="Nombre de jour...." className="outline-none w-full  h-full sm:text-[12px] text-[10px] pl-2" />
                                    </div>
                                    <button type="submit" className={`bg-green-300 text-green-800 p-1 ${values == '' ? 'pointer-events-none opacity-50' : 'opacity-100 pointer-events-auto cursor-pointer'} mx-1 rounded-full `}>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-send-check-fill" viewBox="0 0 16 16">
                                        <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 1.59 2.498C8 14 8 13 8 12.5a4.5 4.5 0 0 1 5.026-4.47zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471z" />
                                        <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0m-1.993-1.679a.5.5 0 0 0-.686.172l-1.17 1.95-.547-.547a.5.5 0 0 0-.708.708l.774.773a.75.75 0 0 0 1.174-.144l1.335-2.226a.5.5 0 0 0-.172-.686" />
                                      </svg>
                                    </button>
                                  </form>
                                </div>
                              )
                            }




                            {item.livrecherche == 'non' ?
                              <button onClick={(e) => { AddToCart({ ...item, jours: 90 }); e.stopPropagation() }} class="text-lg font-medium leading-none text-white">
                                Emprunter
                              </button>
                              : <button onClick={(e) => { hundlesSubmit(item); GetSetshowInpu(true, index); e.stopPropagation() }} class="text-lg font-medium leading-none text-white">
                                Emprunter
                              </button>}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }) : (

                <div class="w-full">
                  <div class="bg-transparent sm:text-[25px] w-full h-[50vh] flex justify-center items-cente">

                    Désolé, aucune correspondance  pour   <span className='font-semibold ml-4 mr-4'>{rechercher && searchTerm ? `le livre n° ${searchTerm}` : "les livres des eleves"}</span>{" "} trouvée

                  </div>
                </div>

              )}
            </div>
          </div>
        </div>
      </div>
      <FootterCopy />
    </div >

  );
}

export default ListeLivres;
