import React from 'react'
import EvaluationDetail from './EvaluationDetail'
import SildeEnseign from '../SildeEnseign/SildeEnseign'
function EvaluationDetailComponent() {
    return (
        <div className='overflow-hidden flex  bg-white pt-16'>
            <SildeEnseign />
            <EvaluationDetail />
        </div>
    )
}

export default EvaluationDetailComponent
