import React from 'react'
import SideBarBibliote from '../SideBar/SideBarBibliote'
import EmprunterEnseignantLivres from './EmprunterEnseignantLivres'
function EmprunterEnseignantLivresComponent({ getMenuMobille, getSizeMobille }) {
    return (
        <div className='flex justify-between overflow-hidden bg-white'>
            <SideBarBibliote getMenuMobille={getMenuMobille} />
            <EmprunterEnseignantLivres getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default EmprunterEnseignantLivresComponent
