import React from 'react'
import EmpruntVisitorCarts from './EmpruntVisitorCarts'
import SideBarBibliote from '../SideBar/SideBarBibliote'
function EmpruntVisitorCartsComponent() {
    return (
        <div className='flex justify-between overflow-hidden bg-white'>
            <SideBarBibliote />
            <EmpruntVisitorCarts />
        </div>
    )
}

export default EmpruntVisitorCartsComponent
