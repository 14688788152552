import React from 'react'
import SideBarSecretaire from '../SideBarSecretaire/SideBarSecretaire'
import DetteBibliothequeDetail from './DetteBibliothequeDetail'
function DetteBibliothequeDetailComponent({ getSizeMobille, getMenuMobille }) {
    return (
        <div className='flex justify-between overflow-hidden'>
            <SideBarSecretaire getMenuMobille={getMenuMobille} />
            <DetteBibliothequeDetail getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default DetteBibliothequeDetailComponent
