import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import FootterCopy from "../../FootterCopy/FootterCopy";
import { API } from "../../../Api/API";
import { decryptData, encryptData } from "../../../encryptionModule";
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
function Medicament({ getSizeMobille }) {
  const [mobile1, Setmonile1] = useState(window.innerWidth < 1141);
  const [mobile11, SetMobile11] = useState(window.innerWidth < 501);
  const [searchTerm, setSearchTerm] = useState("");
  const [medicaments, setMedicaments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [pageInput, setPageInput] = useState("");

  useEffect(() => {
    const hundlesSize = () => {
      Setmonile1(window.innerWidth < 1141);
      SetMobile11(window.innerWidth < 501);
    };

    window.addEventListener("resize", hundlesSize);

    return () => {
      window.removeEventListener("resize", hundlesSize);
    };
  }, []);

  useEffect(() => {
    fetchMedicaments();
  }, [currentPage, pageSize]);

  const fetchMedicaments = async () => {
    try {
      const response = await API.get("/INF/AllMedicament", {
        params: { search: searchTerm, page: currentPage, size: pageSize }
      });
      setMedicaments(response.data.entries);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching medicaments:", error);
    }
  };

  const handleSearch = () => {
    setCurrentPage(1);
    fetchMedicaments();
  };
  const handlePageSizeChange = (e) => {
    setPageSize(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  const handlePageInputChange = (e) => {
    setPageInput(e.target.value);
  };

  const handleGoToPage = () => {
    const pageNumber = parseInt(pageInput);
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };
  const generatePageNumbers = () => {
    const maxPagesToShow = 5; // Maximum number of page buttons to show
    const pages = [];
    let startPage = 1;
    let endPage = totalPages;

    if (totalPages > maxPagesToShow) {
      const offset = Math.floor(maxPagesToShow / 2);
      startPage = currentPage - offset;
      endPage = currentPage + offset;

      if (startPage < 1) {
        startPage = 1;
        endPage = maxPagesToShow;
      } else if (endPage > totalPages) {
        endPage = totalPages;
        startPage = totalPages - maxPagesToShow + 1;
      }
    }

    // Ajout des points de suspension pour les premières pages
    if (startPage > 1) {
      pages.push(
        <button
          key={1}
          onClick={() => handlePageClick(1)}
          className={`py-2 px-3 mx-1 bg-gray-200 hover:bg-gray-300 text-gray-800 font-semibold rounded-lg`}
        >
          {1}
        </button>
      );
      if (startPage > 2) {
        pages.push(<span key="dots1">...</span>);
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => handlePageClick(i)}
          className={`py-2 px-3 mx-1    font-semibold rounded-lg ${currentPage === i ? "bg-gray-700 text-white" : "hover:bg-gray-300 bg-gray-200 text-gray-800"}`}
        >
          {i}
        </button>
      );
    }

    // Ajout des points de suspension pour les dernières pages
    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pages.push(<span key="dots2">...</span>);
      }
      pages.push(
        <button
          key={totalPages}
          onClick={() => handlePageClick(totalPages)}
          className={`py-2 px-3 mx-1 bg-gray-200 hover:bg-gray-300 text-gray-800 font-semibold rounded-lg`}
        >
          {totalPages}
        </button>
      );
    }

    return pages;
  };

  const formatDate = date => {
    return format(new Date(date), "'Le 'd, MMMM yyyy", { locale: fr });
  };
  return (
    <div
      className={`bg-white  overflow-y-auto  overflow-x-hidden ${mobile11 ? "h-[90vh]" : "h-[85vh]"
        }   ${getSizeMobille ? "w-full " : "w-[85vw]"}`}
    >
      <div className="sm:p-4 p-2 min-h-screen">
        <div class="sm:my-10 my-2">
          <div class="w-full flex flex-row items-center justify-between">
            <p class="text-navy-700 shrink  ">
              <p class="text-2xl font-bold">Médicament</p>
            </p>
            <div>
              <select
                value={pageSize}
                onChange={handlePageSizeChange}
                class="bg-white border border-gray-300 h-10 text-gray-900 sm:text-lg first-letter:uppercase rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block"
              >
                <option value={1}>1 par page</option>
                <option value={5}>5 par page</option>
                <option value={10}>10 par page</option>
                <option value={20}>20 par page</option>
              </select>
            </div>
          </div>
        </div>
        <div class="sm:flex items-center pr-4  h-max justify-end">
          <div class="mx-2">
            <div class="relative lg:w-64">
              <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <svg
                  class="w-5 h-5 text-gray-500"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
              <input
                type="text"
                name="searchTerm"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                id="topbar-search"
                class="bg-white border border-gray-300 h-10 text-gray-900 sm:text-lg first-letter:uppercase rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full pl-10 .5"
                placeholder="Rechercher..."
              />
            </div>
          </div>
          <button
            onClick={handleSearch}
            class="mt-4 sm:mt-0 inline-flex items-start justify-start px-1 py-3 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg"
          >
            <p class="text-lg first-letter:uppercase font-semibold leading-none text-white">
              Rerchercher
            </p>
          </button>
        </div>
        <div class="w-full my-4 grid grid-cols-1 ">
          <div class="bg-white shadow rounded-lg p-2">
            <div class="mb-4 flex items-center  justify-between flex-wrap">
              <div>
                <h3 class="text-xl p-4 font-bold text-gray-900 mb-2">
                  Listes des médicaments
                </h3>
              </div>
              <div class="flex-shrink-0">
                <Link
                  to="/Medicament/AjouterMedicament"
                  class="mt-4 sm:mt-0 inline-flex items-c justify-start px-2 py-2 text-white font-bold bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg"
                >
                  Ajouter un médicament
                </Link>
              </div>
            </div>

            <div class="flex flex-col mt-8">
              <div class="overflow-x-auto rounded-lg">
                <div
                  class={`align-middle inline-block  ${mobile1 ? "w-[70em]" : "min-w-full"
                    }`}
                >
                  <div class="shadow overflow-hidden sm:rounded-lg">
                    <table class="min-w-full divide-y divide-gray-200">
                      <thead class="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            class=" py-4 px-2 font-semibold relative border-l text-left  text-lg first-letter:uppercase  text-gray-500  tracking-wider"
                          >
                            Nom du médicament
                          </th>
                          <th
                            scope="col"
                            class=" py-4 px-2 font-semibold relative border-l text-left  text-lg first-letter:uppercase  text-gray-500  tracking-wider"
                          >
                            Unité de comptage
                          </th>
                          <th
                            scope="col"
                            class=" py-4 px-2 font-semibold relative border-l text-left  text-lg first-letter:uppercase  text-gray-500  tracking-wider"
                          >
                            Dosage
                          </th>
                          <th
                            scope="col"
                            class=" py-4 px-2 font-semibold relative border-l text-left  text-lg first-letter:uppercase  text-gray-500  tracking-wider"
                          >
                            Quantité
                          </th>
                          <th
                            scope="col"
                            class=" py-4 px-2 font-semibold relative border-l text-left  text-lg first-letter:uppercase  text-gray-500  tracking-wider"
                          >
                            Date d'expiration
                          </th>
                          <th
                            scope="col"
                            class=" py-4 px-2 font-semibold relative border-l text-left  text-lg first-letter:uppercase  text-gray-500  tracking-wider"
                          >
                            Source
                          </th>
                          <th
                            scope="col"
                            class=" py-4 px-2 font-semibold relative border-l text-left  text-lg first-letter:uppercase  text-gray-500  tracking-wider"
                          >
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody class="bg-white">
                        {medicaments.map((medicament) => (
                          <tr key={medicament.id}>
                            <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                              {medicament.nom}
                            </td>
                            <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                              {medicament.unitecomptage}
                            </td>
                            <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                              {medicament.dosage}
                            </td>
                            <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                              {medicament.quantite}
                            </td>
                            <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                              {formatDate(medicament.dateexp)}
                            </td>
                            <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                              {medicament.origine}
                            </td>
                            <td class="border-b border-gray-200  pt-2 px-3  border-l border-r whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                              <Link
                                to={`/Medicament/Detail/${encryptData((medicament.id).toString())}`}
                                class="my-2 sm:mt-0 inline-flex items-start justify-start px-3 py-2 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg"
                              >
                                <p class="text-lg first-letter:uppercase font-semibold leading-none text-white">
                                  Details
                                </p>
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="flex justify-between mt-4 flex-wrap">
                <button
                  onClick={handlePrevPage}
                  disabled={currentPage === 1}
                  className="py-2 px-4 bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold rounded-lg disabled:opacity-50 mb-2 sm:mb-0"
                >
                  Précédent
                </button>
                <div className="flex flex-wrap">{generatePageNumbers()}</div>
                <button
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                  className="py-2 px-4 bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold rounded-lg disabled:opacity-50 mb-2 sm:mb-0"
                >
                  Suivant
                </button>
              </div>

              <div class="flex items-center mt-4 ">
                <input
                  type="number"
                  min='1'
                  max={totalPages}
                  value={pageInput}
                  onChange={handlePageInputChange}
                  class="bg-white text-center border border-gray-300 h-10 text-gray-900 sm:text-lg first-letter:uppercase rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-20 mr-2"
                  placeholder={`1 - ${totalPages}`}
                />
                <button
                  onClick={handleGoToPage}
                  class="py-2 px-4 bg-blue-700 hover:bg-blue-600 text-white font-semibold rounded-lg"
                >
                  Aller
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FootterCopy />
    </div>
  );
}

export default Medicament;
