/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'

function FootterCopy() {


    const [mobile1, Setmonile1] = useState(window.innerWidth < 474)
    useEffect(() => {
        const hundlesSize = () => {
            Setmonile1(window.innerWidth < 474)
        }

        window.addEventListener('resize', hundlesSize)

        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])
    return (
        <div className={`copy p-4 bg-[rgba(10,1,67,0.93)]  w-full text-center justify-center items-center  text-white ${mobile1 ? 'flex flex-col' : 'inline-flex '}`}>
            <div>CopyRight ,developpée par la sociéte</div>
            <div className="w-[40px]  p-1 h-[40px] mx-2  border-4  border-double border-blue-600 rounded-full overflow-hidden">
                <img src='https://speedreal.excellencengagaraschool.com/uploads/logos/btr.png' />
            </div>
            <div>Burundi en Temps Réel</div>
        </div>

    )
}

export default FootterCopy
