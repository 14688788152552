import React from 'react'
import ResumesBibliothequeDetail from './ResumesBibliothequeDetail'
import SideBarBibliote from '../../Bibliotheque/SideBar/SideBarBibliote'
function ResumesBibliothequeDetailComponent({ getSizeMobille, getMenuMobille }) {
    return (
        <div className='flex justify-between overflow-hidden bg-white'>
            <SideBarBibliote getMenuMobille={getMenuMobille} />
            <ResumesBibliothequeDetail getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default ResumesBibliothequeDetailComponent
