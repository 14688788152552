import React, { useState } from "react";
import SideBarSecretaire from "../SideBarSecretaire/SideBarSecretaire";
import Courriers from "./Courriers";
function CourriersComponent({ getMenuMobille, getSizeMobille }) {
  return (
    <>
      {" "}
      <div class="flex overflow-hidden bg-white justify-between">
        <SideBarSecretaire getMenuMobille={getMenuMobille} />
        <Courriers getSizeMobille={getSizeMobille} />
      </div>
    </>
  );
}

export default CourriersComponent;
