import React, { useState } from "react";
import FootterCopy from "../../FootterCopy/FootterCopy";
function Registres({ getSizeMobille }) {
  return (
    <>
      <div className={`bg-white  overflow-x-auto  h-[85vh] ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
        <div className="p-2 flex justify-center flex-col ">
          <div class="my-10 flex items-center justify-center">
            <h3 class="text-xl sm:text-2xl leading-none font-bold text-gray-900">
              Quelles types de Registres ?
            </h3>
          </div>

          <div class="my-4 w-full items-center flex flex-col gap-4">
            <div
              to="/RegistrePoints"
              class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 cursor-pointer hover:bg-gray-100 hover:shadow-lg transition duration-300 ease-in-out hover:scale-105 transform"
            >
              <div class="flex items-center">
                <div class="">
                  <h3 class="text-xl font-normal text-center text-gray-700">
                    {" "}
                    Registre des Points/Trim
                  </h3>
                </div>
              </div>
            </div>
            <div
              to="/AttestPersonnel"
              class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 cursor-pointer hover:bg-gray-100s hover:shadow-lg transition duration-300 ease-in-out hover:scale-105 transform"
            >
              <div class="flex items-center">
                <div class="">
                  <h3 class="text-xl font-normal text-center text-gray-700">
                    Registres des Attestations Personnels
                  </h3>
                </div>
              </div>
            </div>
            <div
              to="/AttstEleve"
              class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 cursor-pointer hover:bg-gray-100s hover:shadow-lg transition duration-300 ease-in-out hover:scale-105 transform"
            >
              <div class="flex items-center">
                <div class="">
                  <h3 class="text-xl font-normal text-center text-gray-700">
                    Registres des Attestations des Eleves
                  </h3>
                </div>
              </div>
            </div>
            <div
              to="/PresenceEnseignant"
              class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 cursor-pointer hover:bg-gray-100s hover:shadow-lg transition duration-300 ease-in-out hover:scale-105 transform"
            >
              <div class="flex items-center">
                <div class="">
                  <h3 class="text-xl font-normal text-center text-gray-700">
                    Registres de Presence Des Enseignants
                  </h3>
                </div>
              </div>
            </div>
            <div
              to="/PresenceAdmin"
              class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 cursor-pointer hover:bg-gray-100s hover:shadow-lg transition duration-300 ease-in-out hover:scale-105 transform"
            >
              <div class="flex items-center">
                <div class="">
                  <h3 class="text-xl font-normal text-center text-gray-700">
                    Registres de Presences Des Administratifs
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FootterCopy />
      </div>
    </>
  );
}

export default Registres;
