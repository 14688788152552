import React, { useState } from "react";
import SideBar from "../SideBar/SideBarBibliote";
import AjouterDepenseBiblio from "./AjouterDepenseBiblio";
function AjouterDepenseBiblioComponent({getSizeMobille,getMenuMobille}) {
  return (
    <>
      {" "}
      <div class="overflow-hidden flex justify-between">
        <SideBar getMenuMobille={getMenuMobille} />
        <AjouterDepenseBiblio getSizeMobille={getSizeMobille} />
      </div>
    </>
  );
}

export default AjouterDepenseBiblioComponent;
