/* eslint-disable eqeqeq */
import React, { useEffect, useRef, useState } from "react";
import FootterCopy from "../../FootterCopy/FootterCopy";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

function ModifierPrevisionPrefetDisplin({ getSizeMobille }) {

    const [mobile1, Setmonile1] = useState(window.innerWidth < 349)
    const [mobile11, SetMobile11] = useState(window.innerWidth < 501)

    useEffect(() => {
        const hundlesSize = () => {
            Setmonile1(window.innerWidth < 349)
            SetMobile11(window.innerWidth < 501)
        }

        window.addEventListener('resize', hundlesSize)

        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])





    const [Libelle, SetLibelle] = useState('')
    const [animationClassLibelle, setAnimationClassLibelle] = useState('');
    const elemetRefLibelle = useRef(null)





    const [Prix_unit, SetPrix_unit] = useState('')
    const [animationClassPrix_unit, setAnimationClassPrix_unit] = useState('');
    const elemetRefPrix_unit = useRef(null)

    const [Quantite, SetQuantite] = useState('')
    const [animationClassQuantite, setAnimationClassQuantite] = useState('');
    const elemetRefQuantite = useRef(null)


    const [Déscription, SetDéscription] = useState('')
    const [animationClassDéscription, setanimationClassDéscription] = useState('');
    const elemetRefDéscription = useRef(null)



    const handleSubmit = (e) => {
        e.preventDefault()
        if (Libelle.trim() === '') {
            toast.warning('Le Libelle du livre est  obligatoire', {
                autoClose: 2000
            });
            setAnimationClassLibelle('animate__animated animate__shakeX border-2 border-red-500 ')
            setTimeout(() => {
                setAnimationClassLibelle(' ')
            }, 3000)
            elemetRefLibelle.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            elemetRefLibelle.current.focus()

            return false
        } else if (Prix_unit.trim() == '') {
            toast.warning('Le prix unitaire est obligatoire', {
                autoClose: 2000
            });
            setAnimationClassPrix_unit('animate__animated animate__shakeX border-2 border-red-500')
            setTimeout(() => {
                setAnimationClassPrix_unit(' ')
            }, 3000)
            elemetRefPrix_unit.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            elemetRefPrix_unit.current.focus()

            return false
        }
        else if (Quantite == '') {
            toast.warning("La quantite est oblogatoire", {
                autoClose: 2000
            });
            setAnimationClassQuantite('animate__animated animate__shakeX  border-2 border-red-500')
            setTimeout(() => {
                setAnimationClassQuantite(' ')
            }, 3000)
            elemetRefQuantite.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            elemetRefQuantite.current.focus()
            return false
        }

        else if (Déscription == '') {
            toast.warning('La description est obligatoire?', {
                autoClose: 2000
            });
            setanimationClassDéscription('animate__animated animate__shakeX border-2 border-red-500')
            setTimeout(() => {
                setanimationClassDéscription(' ')
            }, 3000)
            elemetRefDéscription.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            elemetRefDéscription.current.focus()
            return;
        }
    }


    return (

        <div className={`bg-white  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
            <Link to='/Prevision/Detail' className="ml-2 text-[18px] text-blue-500 hover:text-blue-800 underline">Retour</Link>
            <div className="sm:p-4 p-2 min-h-screen">
                <div class="my-5">
                    <div class="w-full flex flex-row items-center justify-between">
                        <p class="text-navy-700 shrink  ">
                            <p
                                class="hover:text-navy-700  font-bold  "

                            >
                                Modifier un prévision
                            </p>
                        </p>

                    </div>
                </div>
                <div class="w-full my-4 grid grid-cols-1 ">
                    <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">

                        <form onSubmit={handleSubmit}>
                            <div class="bg-white rounded sm:px-8 p-2 sm:pt-6 sm:pb-8 mb-4 flex flex-col my-2">
                                <div class="-mx-3 md:flex sm:mb-6">
                                    <div class="md:w-1/2 px-3 mb-6 md:mb-0">
                                        <label
                                            class="block  tracking-wide text-grey-darker  font-bold mb-2"
                                            for="grid-Title"
                                        >
                                            Libelle
                                        </label>
                                        <input
                                            class={` outline-none block w-full bg-grey-lighter  border rounded p-2  sm:py-3 sm:px-4 mb-3 ${Libelle.trim() === '' ? animationClassLibelle : 'focus:border-2 focus:border-blue-700'} `}
                                            id="grid-Title"
                                            type="text"
                                            placeholder="Libelle"
                                            ref={elemetRefLibelle}
                                            onInput={(e) => SetLibelle(e.target.value)}
                                        />
                                    </div>
                                    <div class="md:w-1/2 px-3">
                                        <label
                                            class="block  tracking-wide text-grey-darker  font-bold mb-2"
                                            for="grid-last-name"
                                        >
                                            Prix unitaire
                                        </label>
                                        <input
                                            class={` outline-none block w-full bg-grey-lighter  border rounded p-2  sm:py-3 sm:px-4 mb-3 ${Prix_unit.trim() === '' ? animationClassPrix_unit : 'focus:border-2 focus:border-blue-700'} `}
                                            id="grid-last-name"
                                            type="number"
                                            min={1}
                                            ref={elemetRefPrix_unit}
                                            placeholder="Prix unitaire"
                                            onInput={(e) => SetPrix_unit(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div class="-mx-3 md:flex mb-6">
                                    <div class="md:w-1/2 px-3">
                                        <label
                                            class="block  tracking-wide text-grey-darker  font-bold mb-2"

                                        >
                                            Quantite
                                        </label>
                                        <input
                                            class={` outline-none block w-full bg-grey-lighter  border rounded p-2  sm:py-3 sm:px-4 mb-3 ${Quantite.trim() === '' ? animationClassQuantite : 'focus:border-2 focus:border-blue-700'} `}
                                            id="grid-password"
                                            type="number"
                                            min="1"
                                            ref={elemetRefQuantite}
                                            placeholder="Qunatite"

                                            onInput={(e) => SetQuantite(e.target.value)}
                                        />
                                    </div>
                                    <div class="md:w-1/2 px-3">
                                        <label
                                            class="block  tracking-wide text-grey-darker  font-bold mb-2"
                                            for="grid-state"
                                        >
                                            Déscription
                                        </label>
                                        <textarea
                                            class={` outline-none block w-full bg-grey-lighter  border rounded p-2  sm:py-3 sm:px-4 mb-3 ${Déscription.trim() === '' ? animationClassDéscription : 'focus:border-2 focus:border-blue-700'} `}
                                            id="grid-password"
                                            onInput={(e) => SetDéscription(e.target.value)}
                                            ref={elemetRefDéscription}
                                            placeholder="Déscription"
                                        ></textarea>
                                    </div>
                                </div>

                                <div class="mb-4 flex items-center justify-end">
                                    <div class="flex-shrink-0">
                                        <button type="submit" class="mt-4 sm:mt-0 inline-flex items-start justify-start px-6 py-3 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg">
                                            <p class=" font-medium leading-none text-white">
                                                Modifier
                                            </p>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <FootterCopy />
        </div>

    );
}

export default ModifierPrevisionPrefetDisplin;
