import React from 'react'
import SlideParentNavBars from '../SlideParentNavBars/SlideParentNavBars'
import ListeCoursAllParent from './ListeCoursAllParent'
function ListeCoursAllParentComponent({ getSizeMobille, getMenuMobille }) {
    return (
        <div class="overflow-hidden flex justify-between">
            <SlideParentNavBars getMenuMobille={getMenuMobille} />
            <ListeCoursAllParent getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default ListeCoursAllParentComponent
