import React, { useState } from "react";
import SideBarBibliote from "../SideBar/SideBarBibliote";
import DetailLivre from "./DetailLivre";
function DetailLivreComponent({ exempalire,SetExemple, getSizeMobille, getMenuMobille, LivreId, SetLivreId }) {
  return (
    <>
      {" "}
      <div class="overflow-hidden flex justify-between">
        <SideBarBibliote getMenuMobille={getMenuMobille} />
        <DetailLivre exempalire={exempalire} SetExemple={SetExemple} getSizeMobille={getSizeMobille} LivreId={LivreId} SetLivreId={SetLivreId} />
      </div>
    </>
  );
}

export default DetailLivreComponent;
