import React, { useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { API } from '../../../Api/API';
function ResumeBiblio({ resumeQui, SetResume, empruntId, LivreReturn, setLivreReturn }) {
    const [resumes, SetResumes] = useState('')
    const [animationClassresumes, setAnimationClassResumes] = useState('');
    const elemetRefResume = useRef(null)

    const hundleSubmit = (e) => {
        e.preventDefault()
        if (resumes.trim() === '') {
            toast.warning('Le résume est  obligatoire', {
                autoClose: 2000
            });
            setAnimationClassResumes('animate__animated animate__shakeX border-2 border-red-500 ')
            setTimeout(() => {
                setAnimationClassResumes(' ')
            }, 3000)
            elemetRefResume.current.focus()
            return
        }
        else {
            if (resumeQui == "eleves") {
                API.put(`/livres/remisAvecResumerEleves/${empruntId}/${resumes}`).then((res) => {
                    toast.success("La remise du livre a été enregistrée avec succès.")
                    SetResume(false)
                    setLivreReturn((prevLivreReturn) =>
                        prevLivreReturn.filter((livre) => livre.id !== empruntId)
                    );
                }).catch((err) => {
                    toast.warn("La remise du livre n'a pas été enregistrée avec succès.");
                    console.log("err", err.message)
                })
            } else if (resumeQui == "perso") {
                API.put(`/livres/remisAvecResumerPerso/${empruntId}/${resumes}`).then((res) => {
                    toast.success("La remise du livre a été enregistrée avec succès.")
                    SetResume(false)
                    setLivreReturn((prevLivreReturn) =>
                        prevLivreReturn.filter((livre) => livre.id !== empruntId)
                    );
                }).catch((err) => {
                    toast.warn("La remise du livre n'a pas été enregistrée avec succès.");
                    console.log("err", err.message)
                })
            } else if (resumeQui == "autres") {
                API.put(`/livres/remisAvecResumerVisiteur/${empruntId}/${resumes}`).then((res) => {
                    toast.success("La remise du livre a été enregistrée avec succès.")
                    SetResume(false)
                    setLivreReturn((prevLivreReturn) =>
                        prevLivreReturn.filter((livre) => livre.id !== empruntId)
                    );
                }).catch((err) => {
                    toast.warn("La remise du livre n'a pas été enregistrée avec succès.");
                    console.log("err", err.message)
                })
            }

        }
    }


    return (
        <div className='w-full h-full top-0 left-0  fixed bg-[#00000067] z-50 flex justify-center items-center'>
            <div className='w-[20em] h-max bg-white rounded-2xl p-3'>
                <div className='w-full flex justify-end'>
                    <svg onClick={() => { SetResume(false) }} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x-circle cursor-pointer text-red-400 hover:text-red-700" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                </div>
                <form onSubmit={hundleSubmit}>
                    <div class="w-full mt-3 px-3">
                        <label
                            class="block uppercase tracking-wide text-grey-darker text-lg font-bold mb-2"
                            for="grid-last-name"
                        >
                            Numéro de résume
                        </label>
                        <input
                            ref={elemetRefResume}
                            class={`appearance-none outline-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4 ${animationClassresumes}`}
                            id="grid-last-name"
                            type="text"
                            onInput={(e) => SetResumes(e.target.value)}
                            placeholder=" Numéro de résume"
                        />
                    </div>
                    <div class="my-4 flex items-center justify-end">
                        <div class="">
                            <button type='submit' class="mt-4 sm:mt-0 inline-flex items-start justify-start px-2 sm:px-6 py-3 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg">
                                <p class="text-lg font-medium leading-none text-white">
                                    Ajouter
                                </p>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ResumeBiblio
