import React from "react";
import SideBarSecretaire from "../SideBarSecretaire/SideBarSecretaire";
import AccueilSecretaire from "./AccueilSecretaire";
function AccueilSecretaireComponent({ getSizeMobille, getMenuMobille, getDivScroll }) {

  return (
    <div class="overflow-hidden flex justify-between">
      <SideBarSecretaire getMenuMobille={getMenuMobille} />
      <AccueilSecretaire getSizeMobille={getSizeMobille} getDivScroll={getDivScroll} />
    </div>
  );
}

export default AccueilSecretaireComponent;
