/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import FootterCopy from "../../FootterCopy/FootterCopy";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select"
import { FadeLoader } from 'react-spinners'

function AddEmpruntMaterielEncadrement({ getSizeMobille }) {
    const [mobile11, SetMobile11] = useState(window.innerWidth < 501)
    useEffect(() => {
        const hundlesSize = () => {
            SetMobile11(window.innerWidth < 501)
        }
        window.addEventListener('resize', hundlesSize)
        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])

    const [mobile, SetMobile] = useState(window.innerWidth <= 640)
    const [boutLoading, setboutLoading] = useState(false)

    useEffect(() => {
        const hundleSize = () => {
            SetMobile(window.innerWidth <= 640)
        }
        window.addEventListener('resize', hundleSize)

        return () => {
            window.removeEventListener('resize', hundleSize)
        }
    }, [])

    const OptionMateriel = [
        { value: '', label: '' },
        { value: 'lit', label: 'Lit' },
        { value: 'materats', label: 'Matelas' },
        { value: 'tables', label: 'Tables' },
        { value: 'bas_pupitre', label: 'Bas pupitre' },
        { value: 'balai', label: 'Balai' },
        { value: 'lacrette', label: 'Lacrette' },
        { value: 'houes', label: 'Houes' },
        { value: 'coupe_coupe', label: 'Coupe-coupe' },
        { value: 'television', label: 'Télévision' },
        { value: 'radio', label: 'Radio' }
    ]

    const OptionEmpruntMateriel = [
        { value: '', label: '' },
        { value: 'eleve', label: 'Elève' },
        { value: 'classe', label: 'Classe' },
        { value: 'Autre', label: 'Autre' }
    ]

    const hiddenSOptionMateriel = OptionMateriel.slice(1);
    const hiddenOptionEmpruntMateriel = OptionEmpruntMateriel.slice(1);

    const customStyles = {
        control: (provided) => ({
            ...provided,
            height: mobile ? 25 : 50,
            backgroundColor: "white",
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? "blue" : state.isFocused ? "lightblue" : "white",
            color: state.isSelected ? "white" : "black",
        }),
    };

    const [blocs, setBlocs] = useState([
        { nomMateriel: '', responsableMateriel: '', classe: '', nomResponsable: '', fonction: '', quantite: '' }
    ]);
    const addBloc = () => {
        setBlocs([...blocs, { nomMateriel: '', responsableMateriel: '', classe: '', nomResponsable: '', fonction: '', quantite: '' }]);
    };
    const removeBloc = (index) => {
        const newBlocs = [...blocs];
        newBlocs.splice(index, 1);
        setBlocs(newBlocs);
    };
    const inputRefs = useRef([]);
    if (inputRefs.current.length !== blocs.length) {
        inputRefs.current = Array(blocs.length).fill({}).map((_, index) => ({
            nomMateriel: React.createRef(null),
            responsableMateriel: React.createRef(null),
            classe: React.createRef(null),
            nomResponsable: React.createRef(null),
            fonction: React.createRef(null),
            quantite: React.createRef(null),
        }));
    }

    const handleInputChange = (index, field, value) => {
        const newBlocs = [...blocs];
        newBlocs[index][field] = value;
        setBlocs(newBlocs);
        if (value && inputRefs.current[index][field].current) {
            inputRefs.current[index][field].current.classList.remove('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500');
        }
    };


    const animateAndScrollToRef = (ref) => {
        if (ref && ref.current) {
            ref.current.classList.add('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500', 'outline-none');
            setTimeout(() => {
                ref.current.classList.remove('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500', 'outline-none');
            }, 5000);
            ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            ref.current.focus();
        }
    };

    const validateBloc = (bloc, index) => {
        if (bloc.nomMateriel === '') {
            toast.warning(<div key="1">Le nom du <strong>matériel</strong> est obligatoire !!</div>)
            if (inputRefs.current[index].nomMateriel.current) {
                inputRefs.current[index].nomMateriel.current.classList.add('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500', 'outline-none');
                setTimeout(() => {
                    inputRefs.current[index].nomMateriel.current.classList.remove('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500', 'outline-none');
                }, 3000);
                inputRefs.current[index].nomMateriel.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
                inputRefs.current[index].nomMateriel.current.focus();
                return false;
            }
        } else if (bloc.responsableMateriel === '') {
            toast.warning(<div>Le responsable du matériel <strong>{[bloc.nomMateriel][0].label}</strong> est obligatoire !! </div>)
            if (inputRefs.current[index].responsableMateriel.current) {
                inputRefs.current[index].responsableMateriel.current.classList.add('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500', 'outline-none');
                setTimeout(() => {
                    inputRefs.current[index].responsableMateriel.current.classList.remove('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500', 'outline-none');
                }, 3000);
                inputRefs.current[index].responsableMateriel.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
                inputRefs.current[index].responsableMateriel.current.focus();
                return false;
            }

        } else if (bloc.responsableMateriel.value == 'eleve' && bloc.nomResponsable == '') {
            toast.warning(<div>Le nom responsable du matériel <strong>{[bloc.nomMateriel][0].label}</strong> est obligatoire !! </div>)
            if (inputRefs.current[index].nomResponsable.current) {
                inputRefs.current[index].nomResponsable.current.classList.add('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500', 'outline-none');
                setTimeout(() => {
                    inputRefs.current[index].nomResponsable.current.classList.remove('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500', 'outline-none');
                }, 3000);
                inputRefs.current[index].nomResponsable.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
                inputRefs.current[index].nomResponsable.current.focus();
                return false;
            }

        } else if (bloc.responsableMateriel.value == 'eleve' && bloc.classe == '') {
            toast.warning(<div>La classe de responsable <strong>{bloc.nomResponsable}</strong>  du matériel <strong>{[bloc.nomMateriel][0].label}</strong> est obligatoire !! </div>)
            if (inputRefs.current[index].classe.current) {
                inputRefs.current[index].classe.current.classList.add('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500', 'outline-none');
                setTimeout(() => {
                    inputRefs.current[index].classe.current.classList.remove('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500', 'outline-none');
                }, 3000);
                inputRefs.current[index].classe.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
                inputRefs.current[index].classe.current.focus();
                return false;
            }

        } else if (bloc.responsableMateriel.value == 'classe' && bloc.nomResponsable == '') {
            toast.warning(<div>Le nom responsable du matériel <strong>{[bloc.nomMateriel][0].label}</strong> est obligatoire !! </div>)
            if (inputRefs.current[index].nomResponsable.current) {
                inputRefs.current[index].nomResponsable.current.classList.add('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500', 'outline-none');
                setTimeout(() => {
                    inputRefs.current[index].nomResponsable.current.classList.remove('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500', 'outline-none');
                }, 3000);
                inputRefs.current[index].nomResponsable.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
                inputRefs.current[index].nomResponsable.current.focus();
                return false;
            }

        } else if (bloc.responsableMateriel.value == 'classe' && bloc.nomResponsable == '') {
            toast.warning(<div>Le nom responsable du matériel <strong>{[bloc.nomMateriel][0].label}</strong> est obligatoire !! </div>)
            if (inputRefs.current[index].nomResponsable.current) {
                inputRefs.current[index].nomResponsable.current.classList.add('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500', 'outline-none');
                setTimeout(() => {
                    inputRefs.current[index].nomResponsable.current.classList.remove('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500', 'outline-none');
                }, 3000);
                inputRefs.current[index].nomResponsable.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
                inputRefs.current[index].nomResponsable.current.focus();
                return false;
            }

        } else if (bloc.responsableMateriel.value == 'Autre' && bloc.nomResponsable == '') {
            toast.warning(<div>Le nom responsable du matériel <strong>{[bloc.nomMateriel][0].label}</strong> est obligatoire !! </div>)
            if (inputRefs.current[index].nomResponsable.current) {
                inputRefs.current[index].nomResponsable.current.classList.add('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500', 'outline-none');
                setTimeout(() => {
                    inputRefs.current[index].nomResponsable.current.classList.remove('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500', 'outline-none');
                }, 3000);
                inputRefs.current[index].nomResponsable.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
                inputRefs.current[index].nomResponsable.current.focus();
                return false;
            }

        } else if (bloc.responsableMateriel.value == 'Autre' && bloc.fonction == '') {
            toast.warning(<div>La fonction de <strong>{bloc.nomResponsable}</strong> est obligatoire !! </div>)
            if (inputRefs.current[index].fonction.current) {
                inputRefs.current[index].fonction.current.classList.add('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500', 'outline-none');
                setTimeout(() => {
                    inputRefs.current[index].fonction.current.classList.remove('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500', 'outline-none');
                }, 3000);
                inputRefs.current[index].fonction.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
                inputRefs.current[index].fonction.current.focus();
                return false;
            }

        }
        else if (bloc.quantite == '') {
            toast.warning(<div>La quantite  du matériel <strong>{[bloc.nomMateriel][0].label}</strong> est obligatoire !! </div>)
            if (inputRefs.current[index].quantite.current) {
                inputRefs.current[index].quantite.current.classList.add('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500', 'outline-none');
                setTimeout(() => {
                    inputRefs.current[index].quantite.current.classList.remove('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500', 'outline-none');
                }, 3000);
                inputRefs.current[index].quantite.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
                inputRefs.current[index].quantite.current.focus();
                return false;
            }

        }


        // Validation pour éviter de soumettre deux blocs de même information
        const isDuplique = blocs.some((b, i) => i !== index && b.nomMateriel.value === bloc.nomMateriel.value && b.nomResponsable === bloc.nomResponsable);
        if (isDuplique) {
            const duplicateBlockIndex = blocs.findIndex((b, i) => i !== index && b.nomMateriel.value === bloc.nomMateriel.value && b.nomResponsable === bloc.nomResponsable);
            const duplicateBlockNumber = toRoman(duplicateBlockIndex + 1);
            toast.warning(
                <div key="1">
                    Évitez la redondance, le nom <span className="font-bold">{bloc.nomResponsable}</span> de la bloc <span className="font-bold">{toRoman(index + 1)}</span> et la <span className="font-bold">{duplicateBlockNumber}</span> ont le même nom de matériel.
                </div>
            );
            animateAndScrollToRef(inputRefs.current[index].nomMateriel);
            return false;
        }

        return true;
    };


    function toRoman(num) {
        const romanNumerals = [
            "I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX", "X",
            "XI", "XII", "XIII", "XIV", "XV", "XVI", "XVII", "XVIII", "XIX", "XX",
            "XXI", "XXII", "XXIII", "XXIV", "XXV", "XXVI", "XXVII", "XXVIII", "XXIX", "XXX"
        ];
        return romanNumerals[num - 1] || num.toString();
    }

    const Datas = blocs.map((bloc) => {
        const data = {
            nomMateriel: bloc.nomMateriel.value,
            responsableMateriel: bloc.responsableMateriel.value,
            nomResponsable: bloc.nomResponsable,
            quantite: bloc.quantite,
        };

        if (bloc.responsableMateriel.value === 'eleve') {
            data.classe = bloc.classe;
        } else if (bloc.responsableMateriel.value === 'Autre') {
            data.fonction = bloc.fonction;
        }

        return data;
    });




    const handleSubmit = (e) => {
        e.preventDefault();
        for (let index = 0; index < blocs.length; index++) {
            if (!validateBloc(blocs[index], index)) {

                return false;
            }
        }

        toast.success(
            <div>
                Enregistrement avec succèss
            </div>
        );
        console.log('Datas', Datas)
    };


    return (

        <div className={`bg-white  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
            <Link to='/Materiel/Emprunt' className="ml-2 text-[18px] text-blue-500 hover:text-blue-800 underline">Retour</Link>
            <div className="sm:p-4 p-2 min-h-screen">
                <div class="my-5">
                    <div class="w-full flex flex-row items-center justify-between">
                        <p class="text-navy-700   ">
                            <a
                                class="hover:text-navy-700 text-2xl font-bold  "
                            >
                                Emprunte le matériel
                            </a>
                        </p>

                    </div>
                </div>
                <div className="w-full my-4 grid grid-cols-1">
                    <div className="w-full my-4 grid grid-cols-1">
                        <form onSubmit={handleSubmit} className="flex flex-col items-center">
                            <div className="rounded-lg p-2 border border-blue-500 md:w-[40em] w-[95%] sm:p-4">
                                {blocs.map((bloc, index) => (
                                    <div key={index} className="justify_center rounded-xl w-full  p-1 sm:p-2 mb-10  border-[1px] flex flex-col">
                                        <div className="w-full">
                                            <div className="h-max w-full">
                                                <div className="px-3  w-full">
                                                    <div className="w-full flex items_center justify-between">
                                                        <label className="block  text-xs mt-5 font-serif text-gray-700 first_letter:uppercase tracking_wide text-grey-darker sm:text-lg  mb-1">
                                                            {toRoman(index + 1)}. Matériels
                                                        </label>
                                                        {index > 0 && (
                                                            <button
                                                                montant="button"
                                                                className="text-red-500 px-1 max-sm:text-xs py-2"
                                                                onClick={() => removeBloc(index)}
                                                            >
                                                                Supprimer
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mb-2">
                                            <div className="rounded-xl p-2 w-full">
                                                <div className="flex flex-col">
                                                    <div className="rounded-lg w-full">
                                                        <div class="w-full mb-5">
                                                            <label class="block  tracking-wide text-gray-700 text-xs sm:text-lg  mb-1">
                                                                Nom du matériels
                                                            </label>
                                                            <div ref={inputRefs.current[index].nomMateriel} className={` rounded `}>
                                                                <Select
                                                                    value={bloc.nomMateriel}
                                                                    options={hiddenSOptionMateriel}
                                                                    styles={customStyles}
                                                                    onChange={(option) => handleInputChange(index, "nomMateriel", option)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="rounded-lg w-full">
                                                        <div class="w-full mb-5">
                                                            <label class="block  tracking-wide text-gray-700 text-xs sm:text-lg  mb-1">
                                                                Emprunt le matériel à
                                                            </label>
                                                            <div ref={inputRefs.current[index].responsableMateriel} className={` rounded `}>
                                                                <Select
                                                                    value={bloc.responsableMateriel}
                                                                    options={hiddenOptionEmpruntMateriel}
                                                                    styles={customStyles}
                                                                    onChange={(option1) => handleInputChange(index, "responsableMateriel", option1)} />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {(bloc.responsableMateriel.value == 'eleve' || bloc.responsableMateriel.value == 'Autre' || bloc.responsableMateriel.value == 'classe') &&
                                                        <div className="rounded-lg w-full">
                                                            <div class="w-full mb-5">
                                                                <label
                                                                    class="block  tracking-wide text-gray-700 text-xs sm:text-lg  mb-1"
                                                                    for="grid-Title"
                                                                >
                                                                    Nom responsable
                                                                </label>
                                                                <input
                                                                    ref={inputRefs.current[index].nomResponsable}
                                                                    class="appearance-none block w-full bg-grey-lighter text-grey-darker border border-red rounded px-2 py-2 sm:py-3 sm:px-4 mb-3"
                                                                    value={bloc.nomResponsable}
                                                                    onChange={(e) => handleInputChange(index, "nomResponsable", e.target.value)}
                                                                    placeholder="Nom responsable..."
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                    {(bloc.responsableMateriel.value == 'eleve' || bloc.responsableMateriel.value == 'classe') &&
                                                        <div className="rounded-lg w-full">
                                                            <div class="w-full mb-5">
                                                                <label
                                                                    class="block  tracking-wide text-gray-700 text-xs sm:text-lg  mb-1"
                                                                    for="grid-Title"
                                                                >
                                                                    Classe
                                                                </label>
                                                                <input
                                                                    ref={inputRefs.current[index].classe}
                                                                    class="appearance-none block w-full bg-grey-lighter text-grey-darker border border-red rounded px-2 py-2 sm:py-3 sm:px-4 mb-3"
                                                                    value={bloc.classe}
                                                                    onChange={(e) => handleInputChange(index, "classe", e.target.value)}
                                                                    placeholder="Classe..."
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                    {bloc.responsableMateriel.value == 'Autre' &&
                                                        <div className="rounded-lg w-full">
                                                            <div class="w-full mb-5">
                                                                <label
                                                                    class="block  tracking-wide text-gray-700 text-xs sm:text-lg  mb-1"
                                                                    for="grid-Title"
                                                                >
                                                                    Fonction
                                                                </label>
                                                                <input
                                                                    ref={inputRefs.current[index].fonction}
                                                                    class="appearance-none block w-full bg-grey-lighter text-grey-darker border border-red rounded px-2 py-2 sm:py-3 sm:px-4 mb-3"
                                                                    value={bloc.fonction}
                                                                    onChange={(e) => handleInputChange(index, "fonction", e.target.value)}
                                                                    placeholder="Fonction..."
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className="rounded-lg w-full">
                                                        <div class="w-full mb-5">
                                                            <label
                                                                class="block  tracking-wide text-gray-700 text-xs sm:text-lg  mb-1"
                                                                for="grid-Title"
                                                            >
                                                                Quantite
                                                            </label>
                                                            <input
                                                                ref={inputRefs.current[index].quantite}
                                                                class="appearance-none block w-full bg-grey-lighter text-grey-darker border border-red rounded px-2 py-2 sm:py-3 sm:px-4 mb-3"
                                                                value={bloc.quantite}
                                                                onChange={(e) => handleInputChange(index, "quantite", e.target.value)}
                                                                placeholder="quantite..."
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <div className="w-full flex justify-end">
                                    <button montant="button" className="bg-blue-500 hover-bg-blue-700 text-white py-1 px-2  max-sm:text-xs sm:py-2 sm:px-4 rounded" onClick={addBloc}>
                                        Ajouter matériel
                                    </button>
                                </div>
                            </div>

                            <div className=" flex justify-end  md:w-[40em] w-[95%]">

                                {boutLoading ? (
                                    <>
                                        <label disabled className="cursor-no-drop w-max relative  mt-3 flex justify-center  items-center   bg-green-950    p-2 rounded  text-gray-400">
                                            <input type="submit" id="send" value='Soumettre' className='pointer-events-none' />
                                            <i class="bi bi-send ml-2  pointer-events-none "></i>
                                            <div disabled className='absolute pointer-events-none  bg-transparent pt-4  pl-4  w-full h-full flex justify-center items-center z-50'>
                                                <FadeLoader
                                                    color="rgb(255, 255, 255)"
                                                    height={10}
                                                    margin={-9}
                                                    radius={100}
                                                    speedMultiplier={1}
                                                    width={1}
                                                />
                                            </div>
                                        </label>
                                    </>
                                ) : (<>
                                    <label for="send" className=" mt-3 cursor-pointer w-max  flex justify-end  bg-green-600   p-2 rounded  text-white">
                                        <input type="submit" id="send" value='Soumettre' className='cursor-pointer'></input>
                                        <i class="bi bi-send ml-2 "></i>
                                    </label>
                                </>)}

                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <FootterCopy />
        </div>

    );
}


export default AddEmpruntMaterielEncadrement;










