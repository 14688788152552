import React from 'react'
import ChoixClasseEconom from './ChoixClasseEconom'
import SideBarSecretaire from '../SideBarSecretaire/SideBarSecretaire'
function ChoixClasseEconomComponent({ getSizeMobille, getMenuMobille }) {
    return (
        <div className='flex justify-between overflow-hidden  '>
            <SideBarSecretaire getMenuMobille={getMenuMobille} />
            <ChoixClasseEconom getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default ChoixClasseEconomComponent
