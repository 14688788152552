import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import FootterCopy from '../../FootterCopy/FootterCopy'
import { decryptData, encryptData } from '../../../encryptionModule'
import { API, STATIC_URL } from '../../../Api/API'
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
function DetailDepensePrefetDisplin({ getSizeMobille }) {

    const [mobile1, Setmonile1] = useState(window.innerWidth < 349)
    const [zoom, SetZoom] = useState(false)
    const [mobile11, SetMobile11] = useState(window.innerWidth < 501)

    useEffect(() => {
        const hundlesSize = () => {
            Setmonile1(window.innerWidth < 349)
            SetMobile11(window.innerWidth < 501)
        }

        window.addEventListener('resize', hundlesSize)

        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])
    const { id } = useParams()
    const depId = decryptData(id)
    const [dep, setPrev] = useState({})
    useEffect(() => {
        API.get(`/dep/depPrefDiscip/${depId}`).then((rep) => {
            setPrev(rep.data)
        }).catch((err) => {
            console.log(err.message)
        })
    }, [id])
    const formatDate = date => {
        return format(new Date(date), "'Le 'd, MMMM yyyy", { locale: fr });
    };
    return (

        <div className={`bg-white  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
            <Link to='/Depenses' className="ml-2 text-[18px] text-blue-500 hover:text-blue-800 underline">Retour</Link>

            <div className="sm:p-4 p-2 min-h-screen">
                <div className='text-2xl font-bold uppercase'>Détail</div>
                <div class=" w-full   rounded-lg ">

                    <div class="flex  justify-center space-y-3 gap-4 mt-8">

                        <div class="flex  rounded-lg border border-gray-400 bg-white shadow-lg  w-[90%]">
                            <div class="flex flex-col justify-start w-full p-6">


                                <h5 class="mb-2 text-xl font-medium  ">
                                    Libelle:  {dep && dep.libelle}
                                </h5>
                                <p class="mb-4 text-xl text-neutral-600 ">
                                    Quantite: {dep && dep.quantite}
                                </p>
                                <p class="mb-4 text-xl text-neutral-600 ">
                                    Prix unitaire:  {dep && dep.montant}
                                </p>
                                <p class="mb-4 text-xl text-neutral-600 ">
                                    Facturié:  {dep && dep.facturie}
                                </p>
                                {dep && dep.facturie == "Oui" && (
                                    <div onClick={() => { SetZoom(true) }} className='w-[10em] border rounded-2xl overflow-hidden h-[10em]'>
                                        <img src={`${STATIC_URL}/factures/${dep.facture}`} />
                                    </div>
                                )}


                                {zoom && <div className='w-full h-full fixed flex justify-center items-center top-0 left-0 bg-[#0000008a]'>
                                    <div className='w-[95%] h-[95vh] relative bg-white rounded-2xl overflow-hidden'>
                                        <div className='absolute flex justify-end w-full'>
                                            <div onClick={() => { SetZoom(false) }} className='w-[40px] cursor-pointer h-[40px] flex justify-center items-center bg-red-500'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-x-circle text-white" viewBox="0 0 16 16">
                                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                </svg>
                                            </div>
                                        </div>
                                        <div className='w-[99%] h-[95vh] bg-white'>
                                            <img src={`${STATIC_URL}/factures/${dep.facture}`} className='w-full h-full object-contain' />
                                        </div>
                                    </div>
                                </div>}
                                <p class="mb-4 text-xl text-neutral-600 ">
                                    Date: {dep && dep.createdAt && formatDate(dep.createdAt)}
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <FootterCopy />
        </div>
    )
}

export default DetailDepensePrefetDisplin
