import React from 'react'
import DetailVente from './DetailVente'
import SlideNavBarsCantin from '../SlideNavBarsCantin/SlideNavBarsCantin'
function DetailVenteComponent() {
    return (
        <div className='bg-white overflow-hidden pt-16 flex'>
            <SlideNavBarsCantin />
            <DetailVente />
        </div>
    )
}

export default DetailVenteComponent
