/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable eqeqeq */
import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import FootterCopy from "../../FootterCopy/FootterCopy";
import { API } from "../../../Api/API";
import { decryptData } from "../../../encryptionModule";
function DetailRentreEncadrement({ getSizeMobille }) {
    const { id, schoolyearId, classId } = useParams()
    const [mobile1, Setmonile1] = useState(window.innerWidth < 990)
    const [mobile2, Setmonile2] = useState(window.innerWidth < 520)

    useEffect(() => {
        const hundlesSize = () => {
            Setmonile1(window.innerWidth < 990)
            Setmonile2(window.innerWidth < 520)
        }
        window.addEventListener('resize', hundlesSize)
        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])



    const [mobile11, SetMobile11] = useState(window.innerWidth < 501)

    useEffect(() => {
        const hundlesSize = () => {
            SetMobile11(window.innerWidth < 501)
        }
        window.addEventListener('resize', hundlesSize)
        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])
    const studentId = decryptData(id)
    const [student, setStudent] = useState({})
    useEffect(() => {
        API.get(`/student/acceillestudentDetails/${studentId}`).then((resp) => {
            setStudent(resp.data)
            console.log(resp.data)
        }).catch((err) => {
            console.log(err.message)
        })
    }, [])


    const [Nom, SetNom] = useState('')
    const [Matricule, SetMatricule] = useState('')
    useEffect(() => {
        API.get(`/student/ParId/${studentId}`).then((res) => {
            const studentData = res.data;
            SetNom(studentData.nomprenom)
            SetMatricule(studentData.matricule)
        }).catch((err) => {
            console.log(err.message)
        })
    }, [id])
    return (
        <>
            <div className={`bg-white select-none  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
                {student && student.aceuil && student.aceuil.length > 0 ? (<>
                    <Link to={`/Rentre/listEleves/${schoolyearId}/${classId}`} className="ml-2 text-[18px] text-blue-500 hover:text-blue-800 underline">Retour</Link>
                    <div className="min-h-[90vh]">
                        <div className="sm:p-6 p-2">
                            <div class="w-full my-4 ">
                                <div class="bg-white border relative overflow-hidden rounded-lg sm:p-4 p-2 ">
                                    <div class={`flex items-center justify-between ${mobile2 ? 'flex-col' : ''}`}>
                                        <div>
                                            <h3 class="sm:text-xl font-bold text-gray-900">
                                                Détails d'un élève
                                            </h3>
                                        </div>
                                    </div>
                                    <div class="flex flex-col ">
                                        <div class="bg-gray-100  flex items-center justify-center">
                                            <div class="bg-white p-2 sm:p-8 rounded-lg  h-full w-full">
                                                <div class="mb-4 flex items-center">
                                                    <label class="sm:text-[20px] text-[13px] font-semibold  block">Nom complet:</label>
                                                    <p class="sm:text-[18px] text-[11px] text-gray-600 ml-2 ">{student.Nom_student}</p>
                                                </div>
                                                <div class="mb-4 flex items-center">
                                                    <label class="sm:text-[20px] text-[13px] font-semibold  block">Matricule:</label>
                                                    <p class="sm:text-[18px] text-[11px] text-gray-600 ml-2 ">{student.Matricule_student}</p>
                                                </div>
                                                {student.aceuil.map((value) => {
                                                    return (
                                                        <div className="bg-white border border-fuchsia-400 rounded-lg p-4">
                                                            <div class="mb-4 flex items-center">
                                                                <label class="sm:text-[20px] text-[13px] font-semibold  block">A/S:</label>
                                                                <p class="sm:text-[18px] text-[11px] ml-2"> {value.A_S}</p>
                                                            </div>
                                                            <div class="mb-4 flex items-center">
                                                                <label class="sm:text-[20px] text-[13px] font-semibold  block">Trimestre:</label>
                                                                <p class="sm:text-[18px] text-[11px] ml-2"> {value.Nom_trimestre}</p>
                                                            </div>
                                                            <div class="mb-4 flex items-center">
                                                                <label class="sm:text-[20px] text-[13px] font-semibold  block">Nom Encadreur:</label>
                                                                <p class="sm:text-[18px] text-[11px] ml-2"> {value.Nom_staff}</p>
                                                            </div>
                                                            <div class="mb-4 flex items-center">
                                                                <label class="sm:text-[20px] text-[13px] font-semibold  block">Matériel complet:</label>
                                                                <p class="sm:text-[18px] text-[11px] ml-2 ">
                                                                    {value.materiel_complet}
                                                                </p>
                                                            </div>
                                                            {value.materiel_complet == "Non" && (
                                                                <div class="mb-4 flex max-sm:flex-col sm:items-start">
                                                                    <label class="sm:text-[20px] text-[13px] font-semibold  block">Quel:</label>
                                                                    <p class="sm:text-[15px] text-[11px] ml-2 ">
                                                                        {value.manque}
                                                                    </p>
                                                                </div>
                                                            )}

                                                            <div class="mb-4 flex items-center">
                                                                <label class="sm:text-[20px] text-[13px] font-semibold  block">Date d'arrive:</label>
                                                                <p class="sm:text-[18px] text-[11px] ml-2"> {value.date_arrive}</p>
                                                            </div>

                                                            <div className="mt-10">
                                                                <Link to={`/Rentre/Detial/Modifier/${value.id}/${id}/${schoolyearId}/${classId}`} className="bg-blue-200 text-blue-700 mx-1 py-1 px-2 rounded">Modifier</Link>
                                                            </div>
                                                        </div>
                                                    )
                                                })}


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>) : (
                    <div className="min-h-[90vh]">
                        <div className="sm:p-6 p-2">
                            <div class="w-full my-4 ">
                                <div class="bg-white border relative overflow-hidden rounded-lg sm:p-4 p-2 ">
                                    <div class={`flex items-center justify-between ${mobile2 ? 'flex-col' : ''}`}>
                                        <div>
                                            <h3 class="sm:text-xl font-bold text-gray-900">
                                                Pas de  Détails d'un élève  <div className="text-blue-400"> {Nom} :{Matricule}</div> dans cette trimestre
                                            </h3>
                                        </div>
                                    </div>
                                    <Link to={`/Rentre/listEleves/${schoolyearId}/${classId}`} className="inline-block mt-4 px-4 py-2 text-sm font-medium leading-5 text-center text-white uppercase transition-colors duration-150 bg-blue-500 border border-transparent rounded-lg shadow-md focus:outline-none focus:border-blue-700 focus:shadow-outline-blue hover:bg-blue-600">Retour</Link>
                                </div>
                            </div>
                        </div>
                    </div>

                )}


                <FootterCopy />
            </div>

        </>
    );
}

export default DetailRentreEncadrement;





