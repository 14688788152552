import React from 'react'
import SideBarBibliote from '../SideBar/SideBarBibliote'
import Previsiondetail from './Previsiondetail'
function PrevisiondetailComponen({ getSizeMobille, getMenuMobille }) {
    return (
        <div className='flex justify-between overflow-hidden bg-white'>
            <SideBarBibliote getMenuMobille={getMenuMobille} />
            <Previsiondetail getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default PrevisiondetailComponen
