import React from 'react'
import Evaluation from './Evaluation'
import SildeEnseign from '../SildeEnseign/SildeEnseign'
function EvaluationComponent() {
    return (
        <div className='overflow-hidden bg-white pt-16 flex'>
            <SildeEnseign />
            <Evaluation />
        </div>
    )
}

export default EvaluationComponent
