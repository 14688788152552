import React, { useEffect, useState } from "react";
import { useLocation, Link, useNavigate, useParams } from "react-router-dom";
import FootterCopy from "../../../FootterCopy/FootterCopy";
import swal from "sweetalert";
import "./sweetalert.css";
import { API } from "../../../../Api/API";
import { decryptData, encryptData } from "../../../../encryptionModule";
import { toast } from "react-toastify";
function DetailExpedier() {
  const history = useNavigate()
  const { id } = useParams()
  const lettreId = decryptData(id)
  const handleConfirmation = async () => {
    try {
      const result = await swal({
        title: "Confirmation",
        text: "Vous Voulez Supprimer ?",
        icon: "warning",
        buttons: ["No", "Oui"],
        dangerMode: true,
        className: "centered-buttons",
      });

      if (result) {
        API.delete(`/courriers/${lettreId}`).then((res) => {
          toast.success("Vous avez supprimer avec succes le courrier!")
          history('/Courriers/ListeExpedier')
        }).catch((err) => {
          toast.warn("Erreur de suppression du courrier")
        })
      } else {
        console.log("Cancelled");
      }
    } catch (error) {
      console.log("Error or dismissal:", error);
    }
  };
  const [lettre, setLettre] = useState({})
  useEffect(() => {
    API.get(`/courriers/ById/${lettreId}`).then((res) => {
      setLettre(res.data)
    }).catch((err) => {
      console.log(err.message)
    })
  }, [lettreId])

  function formatDate(dateString) {
    const months = [
      "janvier", "février", "mars", "avril", "mai", "juin",
      "juillet", "août", "septembre", "octobre", "novembre", "décembre"
    ];
    const date = new Date(dateString);
    const day = date.getUTCDate();
    const month = months[date.getUTCMonth()];
    const year = date.getUTCFullYear();
    return `Le ${day}, ${month} ${year}`;
  }
  return (
    <>
      <div className="bg-white w-[85vw] overflow-x-auto  h-[85vh]">
        <Link to='/Courriers/ListeExpedier' className="ml-2 text-[18px] text-blue-500 hover:text-blue-800 underline">Retour</Link>
        <div className="p-6  min-h-[85vh]">
          <div class="my-5">
            <div class="w-full flex flex-row items-center justify-between">
              <p class="text-navy-700 shrink  ">
                <p
                  class="hover:text-navy-700 text-gray-700 uppercase text-3xl font-bold "

                >
                  Detail
                </p>
              </p>

            </div>
          </div>
          {lettre && (
            <>
              <div class="w-full my-4 grid grid-cols-1 ">
                <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">
                  <div class=" rounded-lg bg-white border flex items-center justify-center">


                    <div class="flex flex-col justify-start p-6">
                      <p class="mb-4  text-xl ">
                        N° de Ref :  {lettre.noref}
                      </p>
                      <p class="mb-4  text-xl ">
                        Nom du destinataire :  {lettre.destinateur}
                      </p>

                      <p class="mb-4  text-xl ">
                        Date de la Lettre : {formatDate(lettre.lettredate)}
                      </p>

                      <p class="mb-4  text-xl ">
                        Date d'Expedition :{formatDate(lettre.lettrerecu)}
                      </p>

                      <p class="mb-4  text-xl ">
                        Status: {lettre.status}
                      </p>

                      <p class="mb-4 text-xl text-neutral-600 flex flex-col ">
                        <span class="pb-3 mb-3 border-b border-gray-300 w-1/2">
                          {" "}
                          Resume de la lettre
                        </span>
                        <span>
                          {lettre.resune}

                        </span>
                      </p>
                      <p class="text-lg text-neutral-900 ">
                        Ajouté {formatDate(lettre.createdAt)}

                      </p>
                      <div class=" mt-8 flex items-center justify-end ">
                        <div class="flex flex-row flex-shrink-0 space-x-3">
                          <Link
                            to={`/Courriers/ListeExpedier/DetailExpedier/ModifierExpedier/${encryptData(lettreId).toString()}`}
                            class="mt-4 sm:mt-0 inline-flex items-start justify-start px-6 py-3 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg"
                          >
                            <p class="text-lg font-medium leading-none text-white">
                              Modifier
                            </p>
                          </Link>
                          <button
                            onClick={handleConfirmation}
                            class="mt-4 sm:mt-0 inline-flex items-start justify-start px-6 py-3 bg-red-700 hover:bg-red-600 focus:outline-none rounded-lg"
                          >
                            <p class="text-lg font-medium leading-none text-white">
                              Supprimer
                            </p>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <FootterCopy />
      </div>
    </>
  );
}

export default DetailExpedier;


