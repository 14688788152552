import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import FootterCopy from "../../FootterCopy/FootterCopy";
function Patient({ getSizeMobille }) {

    const [selected, GetSelector] = useState('Elèves')

    const [mobile1, setMobile1] = useState(window.innerWidth < 621);
    const [mobile2, setMobile2] = useState(window.innerWidth < 684);
    const [mobile11, setMobile11] = useState(window.innerWidth < 501);

    useEffect(() => {
        const handleSize = () => {
            setMobile1(window.innerWidth < 621);
            setMobile2(window.innerWidth < 684);
            setMobile11(window.innerWidth < 501);
        }

        window.addEventListener('resize', handleSize);

        return () => {
            window.removeEventListener('resize', handleSize);
        }
    }, []);

    return (
        <>
            <div className={`bg-white overflow-y-auto overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'} ${getSizeMobille ? 'w-full' : 'w-[85vw]'}`}>
                <div className="md:p-6 p-2  min-h-[85vh]">
                    <div class="my-1">
                        <div class="w-full flex flex-row items-center justify-between">
                            <p class="text-navy-700 shrink  ">
                                <p class="hover:text-navy-700 text-gray-700 uppercase sm:text-3xl font-bold"                                >
                                    Patient
                                </p>
                            </p>
                        </div>
                    </div>
                    <div class="sm:flex items-center pr-4  h-max justify-end">

                        <div class="mx-2">
                            <div class="relative lg:w-64">
                                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <svg
                                        class="w-5 h-5 text-gray-500"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                            clip-rule="evenodd"
                                        ></path>
                                    </svg>
                                </div>
                                <input
                                    type="text"
                                    name="email"
                                    id="topbar-search"
                                    class="bg-white border border-gray-300 h-10 text-gray-900 sm:text-lg first-letter:uppercase rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full pl-10 .5"
                                    placeholder="Rechercher..."

                                />
                            </div>
                        </div>
                        <button class="mt-4 sm:mt-0 inline-flex items-start justify-start px-1 py-2 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg">
                            <p class="text-lg first-letter:uppercase font-semibold leading-none text-white">
                                Rerchercher
                            </p>
                        </button>
                    </div>
                    <div className="flex justify-end  mt-3 w-full">
                        <div className="bg-gray-100 p-1 rounded-lg overflow-hidden sm:mr-10">
                            <select onChange={(e) => { GetSelector(e.target.value) }} className="p-2  bg-white cursor-pointer">
                                <option value='Elèves'>Elèves</option>
                                <option value='Personnel'>Personnel</option>
                                <option value='Particulière'>Particulière</option>
                            </select>
                        </div>
                    </div>
                    <div class="w-full my-2 grid grid-cols-1 ">
                        <div class="bg-white  rounded-lg p-4 sm:p-6 xl:p-8">
                            <div class={`mb-2 flex items-center  justify-between`}>
                                <div className="w-full flex justify-center">
                                    <h3 class="text-xl font-bold text-gray-600 mb-2">
                                        Liste des patients
                                    </h3>
                                </div>
                            </div>
                            {selected == 'Elèves' && <div class="flex flex-col mb-5 w-full">
                                <div class="rounded-lg   overflow-x-auto  flex md:justify-center">
                                    <div class={`bg-gray-200 p-2 rounded-xl sm:w-full `}>
                                        <div className="flex justify-center text-blue-700 text-[25px] font-bold">
                                            Elèves
                                        </div>
                                        <div className="h-[1px] bg-white w-full mb-3"></div>
                                        <div class={`overflow-hidden rounded-lg w-full ${mobile2 ? 'w-[41em]' : 'w-full'} `}>
                                            <table class="min-w-full   w-full">
                                                <thead class="bg-gray-50">
                                                    <tr>
                                                        <th
                                                            scope="col"
                                                            class=" py-4 px-2 border-l text-left cursor-pointer relative text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                                                        >
                                                            N° de consultation
                                                        </th>

                                                        <th
                                                            scope="col"
                                                            class=" py-4 px-2 border-l text-left cursor-pointer relative text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                                                        >
                                                            Matricule
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            class=" py-4 px-2 border-l text-left cursor-pointer relative text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                                                        >
                                                            Action
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody class="bg-white">
                                                    <tr>
                                                        <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                            8GF0
                                                        </td>
                                                        <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                            20/03790
                                                        </td>

                                                        <td class="border-b border-gray-200  pt-2 px-3  border-l border-r whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                            <Link
                                                                to="/Patient/Detail"
                                                                class="my-2 sm:mt-0 inline-flex items-start justify-start px-3 py-2 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg"
                                                            >
                                                                <p class="text-lg first-letter:uppercase font-semibold leading-none text-white">
                                                                    Detail
                                                                </p>
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                            8GF0
                                                        </td>
                                                        <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                            20/03790
                                                        </td>

                                                        <td class="border-b border-gray-200  pt-2 px-3  border-l border-r whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                            <Link
                                                                to="/Patient/Detail"
                                                                class="my-2 sm:mt-0 inline-flex items-start justify-start px-3 py-2 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg"
                                                            >
                                                                <p class="text-lg first-letter:uppercase font-semibold leading-none text-white">
                                                                    Detail
                                                                </p>
                                                            </Link>
                                                        </td>
                                                    </tr>  <tr>
                                                        <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                            8GF0
                                                        </td>
                                                        <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                            20/03790
                                                        </td>

                                                        <td class="border-b border-gray-200  pt-2 px-3  border-l border-r whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                            <Link
                                                                to="/Patient/Detail"
                                                                class="my-2 sm:mt-0 inline-flex items-start justify-start px-3 py-2 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg"
                                                            >
                                                                <p class="text-lg first-letter:uppercase font-semibold leading-none text-white">
                                                                    Detail
                                                                </p>
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>}

                            {selected == 'Personnel' && <div class="flex flex-col mb-5 w-full">
                                <div class="rounded-lg   overflow-x-auto  flex md:justify-center">
                                    <div class={`bg-gray-200 p-2 rounded-xl sm:w-full `}>
                                        <div className="flex justify-center text-blue-700 text-[25px] font-bold">
                                            Personnel
                                        </div>
                                        <div className="h-[1px] bg-white w-full mb-3"></div>
                                        <div class={`overflow-hidden rounded-lg w-full ${mobile2 ? 'w-[41em]' : 'w-full'} `}>
                                            <table class="min-w-full   w-full">
                                                <thead class="bg-gray-50">
                                                    <tr>
                                                        <th
                                                            scope="col"
                                                            class=" py-4 px-2 border-l text-left cursor-pointer relative text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                                                        >
                                                            N° de consultation
                                                        </th>

                                                        <th
                                                            scope="col"
                                                            class=" py-4 px-2 border-l text-left cursor-pointer relative text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                                                        >
                                                            Matricule
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            class=" py-4 px-2 border-l text-left cursor-pointer relative text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                                                        >
                                                            Action
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody class="bg-white">
                                                    <tr>
                                                        <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                            8GF0
                                                        </td>
                                                        <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                            20/03790
                                                        </td>

                                                        <td class="border-b border-gray-200  pt-2 px-3  border-l border-r whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                            <Link
                                                                to="/Patient/Detail"
                                                                class="my-2 sm:mt-0 inline-flex items-start justify-start px-3 py-2 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg"
                                                            >
                                                                <p class="text-lg first-letter:uppercase font-semibold leading-none text-white">
                                                                    Detail
                                                                </p>
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                            8GF0
                                                        </td>
                                                        <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                            20/03790
                                                        </td>

                                                        <td class="border-b border-gray-200  pt-2 px-3  border-l border-r whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                            <Link
                                                                to="/Patient/Detail"
                                                                class="my-2 sm:mt-0 inline-flex items-start justify-start px-3 py-2 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg"
                                                            >
                                                                <p class="text-lg first-letter:uppercase font-semibold leading-none text-white">
                                                                    Detail
                                                                </p>
                                                            </Link>
                                                        </td>
                                                    </tr>  <tr>
                                                        <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                            8GF0
                                                        </td>
                                                        <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                            20/03790
                                                        </td>

                                                        <td class="border-b border-gray-200  pt-2 px-3  border-l border-r whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                            <Link
                                                                to="/Patient/Detail"
                                                                class="my-2 sm:mt-0 inline-flex items-start justify-start px-3 py-2 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg"
                                                            >
                                                                <p class="text-lg first-letter:uppercase font-semibold leading-none text-white">
                                                                    Detail
                                                                </p>
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {selected == 'Particulière' && <div class="flex flex-col mb-5 w-full">
                                <div class="rounded-lg   overflow-x-auto  w-full  flex md:justify-center">
                                    <div class={`bg-gray-200 p-2 rounded-xl sm:w-full `}>
                                        <div className="flex justify-center text-blue-700 text-[25px] font-bold">
                                            Particulière
                                        </div>
                                        <div className="h-[1px] bg-white w-full mb-3"></div>
                                        <div class={`overflow-hidden rounded-lg ${mobile2 ? 'w-[41em]' : 'w-full'} `}>
                                            <table class="min-w-full">
                                                <thead class="bg-gray-50">
                                                    <tr>
                                                        <th
                                                            scope="col"
                                                            class=" py-4 px-2 border-l text-left cursor-pointer relative text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                                                        >
                                                            N° de consultation
                                                        </th>

                                                        <th
                                                            scope="col"
                                                            class=" py-4 px-2 border-l text-left cursor-pointer relative text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                                                        >
                                                            Nom
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            class=" py-4 px-2 border-l text-left cursor-pointer relative text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                                                        >
                                                            Prénom
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            class=" py-4 px-2 border-l text-left cursor-pointer relative text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                                                        >
                                                            Action
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody class="bg-white">
                                                    <tr>
                                                        <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                            8GF0
                                                        </td>
                                                        <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                            KAMANA
                                                        </td>
                                                        <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                            Joselyne
                                                        </td>

                                                        <td class="border-b border-gray-200  pt-2 px-3  border-l border-r whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                            <Link
                                                                to="/Patient/Detail"
                                                                class="my-2 sm:mt-0 inline-flex items-start justify-start px-3 py-2 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg"
                                                            >
                                                                <p class="text-lg first-letter:uppercase font-semibold leading-none text-white">
                                                                    Detail
                                                                </p>
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                            8GF0
                                                        </td>
                                                        <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                            MAMI
                                                        </td>
                                                        <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                            Joselyne
                                                        </td>
                                                        <td class="border-b border-gray-200  pt-2 px-3  border-l border-r whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                            <Link
                                                                to="/Patient/Detail"
                                                                class="my-2 sm:mt-0 inline-flex items-start justify-start px-3 py-2 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg"
                                                            >
                                                                <p class="text-lg first-letter:uppercase font-semibold leading-none text-white">
                                                                    Detail
                                                                </p>
                                                            </Link>
                                                        </td>
                                                    </tr>  <tr>
                                                        <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                            8GF0
                                                        </td>
                                                        <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                            KEZA
                                                        </td>
                                                        <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                            Joselyne
                                                        </td>
                                                        <td class="border-b border-gray-200  pt-2 px-3  border-l border-r whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                                            <Link
                                                                to="/Patient/Detail"
                                                                class="my-2 sm:mt-0 inline-flex items-start justify-start px-3 py-2 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg"
                                                            >
                                                                <p class="text-lg first-letter:uppercase font-semibold leading-none text-white">
                                                                    Detail
                                                                </p>
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>}


                        </div>
                    </div>
                </div>
                <FootterCopy />
            </div>
        </>
    );
}

export default Patient;
