/* eslint-disable no-unused-vars */


/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable eqeqeq */
import React, { Component, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import FootterCopy from "../../FootterCopy/FootterCopy";

function ChoixRapportPrefetDisplin({ getSizeMobille }) {
    const [mobile1, Setmonile1] = useState(window.innerWidth < 990)
    const [mobile2, Setmonile2] = useState(window.innerWidth < 520)
    useEffect(() => {
        const hundlesSize = () => {
            Setmonile1(window.innerWidth < 990)
            Setmonile2(window.innerWidth < 520)
        }
        window.addEventListener('resize', hundlesSize)

        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])

    const [mobile11, SetMobile11] = useState(window.innerWidth < 501)

    useEffect(() => {
        const hundlesSize = () => {
            SetMobile11(window.innerWidth < 501)
        }
        window.addEventListener('resize', hundlesSize)
        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])



    return (
        <>
            <div className={`bg-white  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
                <div className="p-6 min-h-[90vh]">
                    <div class="my-10 flex items-center justify-center">
                        <h3 class="text-xl sm:text-2xl text-center leading-none font-bold text-gray-900">
                            Votre choix
                        </h3>
                    </div>
                    <div class="my-4 w-full items-center flex flex-col gap-4">
                        <Link
                            to="/Statistique/Displin"
                            class="bg-white border border-fuchsia-300 w-[15em] rounded-lg p-4 flex justify-center items-center cursor-pointer hover:bg-gray-100s hover:shadow-lg transition duration-300 ease-in-out hover:scale-105 transform"
                        >
                            <div class="flex items-center">
                                <div class="flex-shrink-0">
                                    <h3 class="text-xl font-normal text-gray-700">
                                        Education

                                    </h3>
                                </div>
                            </div>
                        </Link>
                        <Link to='/Statistique/materiel'
                            class="bg-white border w-[15em] border-fuchsia-300 rounded-lg p-4 flex justify-center items-center cursor-pointer hover:bg-gray-100s hover:shadow-lg transition duration-300 ease-in-out hover:scale-105 transform"
                        >
                            <div class="flex items-center">
                                <div class="flex-shrink-0">
                                    <h3 class="text-xl font-normal text-gray-700">
                                        Matériel
                                    </h3>
                                </div>
                            </div>
                        </Link>
                    </div>


                </div>
                <FootterCopy />
            </div>

        </>
    );
}

export default ChoixRapportPrefetDisplin;



