import React from 'react'
import AcceuilCantine from './AcceuilCantine'
import SlideNavBarsCantin from '../SlideNavBarsCantin/SlideNavBarsCantin'
function AcceuilCantineCompnent() {
    return (
        <div className='overflow-hidden pt-10 flex bg-white'>
            <SlideNavBarsCantin />
            <AcceuilCantine />
        </div>
    )
}

export default AcceuilCantineCompnent
