import React, { useState } from "react";
import EmprunterPersonnelComponent from "../../component/Bibliotheque/Emprunter/EmprunterPersonnelComponent";
function EmprunterPersonnelPage({ getSizeMobille, getMenuMobille }) {
  return (
    <>
      <EmprunterPersonnelComponent getSizeMobille={getSizeMobille} getMenuMobille={getMenuMobille} />
    </>
  );
}

export default EmprunterPersonnelPage;
