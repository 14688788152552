import React from 'react'
import SlideEncadrementNavBars from '../SlideEncadrementNavBars/SlideEncadrementNavBars'
import AddPrevisionEncadreur from './AddPrevisionEncadreur'
function AddPrevisionEncadreurComponent() {
    return (
        <div className='flex justify-between overflow-hidden bg-white'>
            <SlideEncadrementNavBars />
            <AddPrevisionEncadreur />
        </div>
    )
}

export default AddPrevisionEncadreurComponent
