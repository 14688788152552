import React, { useState } from "react";
import ModifierPersonnelComponent from "../../component/Secretariat/DossierPersonnel/ModifierPersonnelComponent";
function ModifierElevePage({ getMenuMobille, getSizeMobille }) {
  return (
    <>
      <ModifierPersonnelComponent getSizeMobille={getSizeMobille} getMenuMobille={getMenuMobille} />
    </>
  );
}

export default ModifierElevePage;
