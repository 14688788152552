import React from 'react'
import DetteEconome from './DetteEconome'
import SideBarSecretaire from '../SideBarSecretaire/SideBarSecretaire'
function DetteEconomeComponent({ getSizeMobille, getMenuMobille }) {
    return (
        <div className='flex justify-between overflow-hidden bg-white'>
            <SideBarSecretaire getMenuMobille={getMenuMobille} />
            <DetteEconome getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default DetteEconomeComponent
