/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { API } from "../../../Api/API";
function AjouteCongeBibliothe({ getSizeMobille }) {
    const history = useNavigate()
    const Situationoptions = [
        { value: "", label: "Type de conge" },
        { value: "Conge Annuel", label: "Congé Annuel" },
        { value: "Repos Medical", label: "Repos Médical" },
        { value: "Conge Maladie", label: "Congé Maladie" },
        { value: "Conge Parental", label: "Congé Parental" },
        { value: "Conge Maternite", label: "Congé Maternité" },
        { value: "Conge Paternite", label: "Congé Paternité" },
        { value: "Conge Mariage", label: "Congé Mariage" },
        { value: "Conge Sans Solde", label: "Congé Sans Solde" },
        { value: "Conge Deuil", label: "Congé Deuil" },
    ];
    const hiddeOption = Situationoptions.slice(1)

    const [selectedOption, setSelectedOption] = useState(Situationoptions[0]);

    const handleSitSelectChange = (option) => {
        setSelectedOption(option);
    };



    const customStyles = {
        control: (provided) => ({
            ...provided,
            height: 50,
        }),
    };
    const [mobile1, Setmonile1] = useState(window.innerWidth < 349)
    const [mobile11, SetMobile11] = useState(window.innerWidth < 501)

    useEffect(() => {
        const hundlesSize = () => {
            Setmonile1(window.innerWidth < 349)
            SetMobile11(window.innerWidth < 501)
        }

        window.addEventListener('resize', hundlesSize)

        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])






    const [dateDebut, SetdateDebut] = useState('')
    const [animationClassdateDebut, setAnimationClassdateDebut] = useState('');
    const elemetRefdateDebut = useRef(null)


    const [dateFin, SetdateFin] = useState('')
    const [animationClassdateFin, setanimationClassdateFin] = useState('');
    const elemetRefdateFin = useRef(null)


    const [animationClassdateType, setanimationClassdateType] = useState('');
    const elemetRefType = useRef(null)


    const [Motif, SetMotif] = useState('')
    const [animationClassMotif, setAnimationClassMotif] = useState('');
    const elemetRefMotif = useRef(null)



    useEffect(() => {
        elemetRefMotif.current.style.height = '7em';
        elemetRefMotif.current.style.height = `${elemetRefMotif.current.scrollHeight}px`;
        elemetRefMotif.current.scrollTop = elemetRefMotif.current.scrollHeight;
    }, [Motif]);


    const handleSubmit = (e) => {
        e.preventDefault()
        if (dateDebut == '') {
            toast.warning("La date début est oblogatoire", {
                autoClose: 2000
            });
            setAnimationClassdateDebut('animate__animated animate__shakeX  border-2 border-red-500')
            setTimeout(() => {
                setAnimationClassdateDebut(' ')
            }, 3000)
            elemetRefdateDebut.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            elemetRefdateDebut.current.focus()
            return false
        }

        else if (dateFin == '') {
            toast.warning('La date de la fin du conge', {
                autoClose: 2000
            });
            setanimationClassdateFin('animate__animated animate__shakeX border-2 border-red-500')
            setTimeout(() => {
                setanimationClassdateFin(' ')
            }, 3000)
            elemetRefdateFin.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            elemetRefdateFin.current.focus()
            return;
        }
        else if (dateDebut == dateFin) {
            toast.warning(`Le conge est inutile car la date de début est égale à la date de fin du conge`, {
                autoClose: 10000
            });
            setanimationClassdateFin('animate__animated animate__shakeX border-2 border-red-500')
            setTimeout(() => {
                setanimationClassdateFin(' ')
            }, 3000)
            elemetRefdateFin.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            elemetRefdateFin.current.focus()
            return;
        }

        else if (dateDebut > dateFin) {
            toast.warning(`La plage de votre conge n'existe pas car la date de début est superieur à la date de fin du conge`, {
                autoClose: 10000
            });
            setanimationClassdateFin('animate__animated animate__shakeX border-2 border-red-500')
            setTimeout(() => {
                setanimationClassdateFin(' ')
            }, 3000)
            elemetRefdateFin.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            elemetRefdateFin.current.focus()
            return;
        }
        else if (selectedOption.value == '') {
            toast.warning('Selectionne le type de conge', {
                autoClose: 2000
            });
            setanimationClassdateType('animate__animated animate__shakeX border-2 border-red-500')
            setTimeout(() => {
                setanimationClassdateType(' ')
            }, 3000)
            elemetRefType.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            return;
        } else if (Motif.trim() == '') {
            toast.warning('Laisse le motif de conge', {
                autoClose: 2000
            });
            setAnimationClassMotif('animate__animated animate__shakeX border-2 border-red-500')
            setTimeout(() => {
                setAnimationClassMotif(' ')
            }, 3000)
            elemetRefMotif.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            elemetRefMotif.current.focus()

            return false
        }

        function nombreAleatoire() {
            const min = 1;
            const max = 23;
            const nombre = Math.floor(Math.random() * (max - min + 1)) + min;
            const resultat = nombre.toString();
            return resultat;
        }
        const formdata = {
            staffId: nombreAleatoire(),
            type: selectedOption.value,
            Description: Motif,
            debutconge: dateDebut,
            finconge: dateFin,
        }
        API.post("/staff/conges", formdata).then((res) => {
            toast.success(res.data)
            history("/Conge")
        }).catch((error) => {
            if (error.response) {
                // Le serveur a répondu avec un code d'erreur (status code)
                if (Array.isArray(error.response.data)) {
                    // Si c'est un tableau, afficher le premier élément
                    toast.warn(error.response.data[0]);
                } else if (typeof error.response.data === 'object') {
                    // S'il s'agit d'un objet, afficher la première valeur de la propriété
                    const keys = Object.keys(error.response.data);
                    if (keys.length > 0) {
                        toast.warn(error.response.data[keys[0]]);
                    }
                } else if (typeof error.response.data === 'string') {
                    // Si c'est une chaîne de caractères, l'afficher directement
                    toast.warn(error.response.data);
                } else {
                    // Autre type non pris en charge
                    toast.warn("Erreur du serveur")

                }
                // Afficher les erreurs au sein de votre composant ou effectuer une action en conséquence
            } else if (error.request) {
                // La requête a été faite mais aucune réponse n'a été reçue
                console.error('Pas de réponse du serveur :', error.request);
            } else {
                // Une erreur s'est produite lors de la configuration de la requête
                console.error('Erreur lors de la requête :', error.message);
            }
        })
    }






    return (
        <>
            <div className={`bg-white  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>

                <Link to='/Conge' className="ml-2 text-[18px] text-blue-500 hover:text-blue-800 underline">Retour</Link>

                <div className="p-6 ">

                    <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                        <div class="my-5">
                            <div class="w-full flex flex-row items-center justify-between">
                                <p class="text-navy-700 shrink  ">
                                    <p
                                        class="hover:text-navy-700 text-gray-700 uppercase text-2xl font-bold "

                                    >
                                        DEMANDE D'UN CONGE
                                    </p>
                                </p>

                            </div>
                        </div>
                        <div class="w-full my-4 grid grid-cols-1 ">
                            <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">
                                <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col my-2">


                                    <div class="-mx-3 md:flex mb-6">
                                        <div class="md:w-1/2 px-3">
                                            <label
                                                class="block uppercase tracking-wide text-gray-700 text-lg font-bold mb-2"
                                                for="grid-password"
                                            >
                                                Date de Debut
                                            </label>
                                            <input
                                                class={` outline-none block w-full bg-grey-lighter  border rounded py-3 px-4 mb-3 ${dateDebut.trim() === '' ? animationClassdateDebut : 'focus:border-2 focus:border-blue-700'} `}
                                                ref={elemetRefdateDebut}
                                                onInput={(e) => SetdateDebut(e.target.value)}
                                                type="date"
                                            />
                                        </div>
                                        <div class="md:w-1/2 px-3">
                                            <label
                                                class="block uppercase tracking-wide text-gray-700 text-lg font-bold mb-2"
                                                for="grid-password"
                                            >
                                                Date de Fin
                                            </label>
                                            <input
                                                class={` outline-none block w-full bg-grey-lighter  border rounded py-3 px-4 mb-3 ${dateFin.trim() === '' ? animationClassdateFin : 'focus:border-2 focus:border-blue-700'} `}
                                                onInput={(e) => SetdateFin(e.target.value)}
                                                ref={elemetRefdateFin}
                                                type="date"
                                            />

                                        </div>
                                        <div class="md:w-1/2 px-3">
                                            <label
                                                class="block uppercase tracking-wide text-gray-700 text-lg font-bold mb-2"
                                                for="grid-state"
                                            >
                                                TYPE DE CONGE
                                            </label>
                                            <div ref={elemetRefType} className={` rounded  ${selectedOption.value == '' ? animationClassdateType : 'focus:border-2 focus:border-blue-700'}`}>
                                                <Select
                                                    value={selectedOption}
                                                    options={hiddeOption}
                                                    styles={customStyles}
                                                    onChange={(option) => {
                                                        handleSitSelectChange(option);

                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div class={`w-full  mb-6`}>
                                        <div class={`w-full`}>
                                            <label
                                                class="block  tracking-wide  text-lg font-bold mb-2"
                                                for="grid-last-name"
                                            >
                                                Motif
                                            </label>
                                            <textarea
                                                ref={elemetRefMotif}
                                                value={Motif}
                                                onInput={(e) => SetMotif(e.target.value)}
                                                className={`appearance-none resize-none outline-none block w-full bg-grey-lighter border rounded p-2 ${Motif.trim() === "" ? animationClassMotif : "focus:border-2 focus:border-blue-700"
                                                    }`}
                                                id="grid-last-name"
                                                rows={1}
                                                placeholder="Motif.."
                                            ></textarea>
                                        </div>
                                    </div>



                                    <div class="mb-4 flex items-center justify-end">
                                        <div class="flex-shrink-0">
                                            <button
                                                type="submit"
                                                class="mt-4 sm:mt-0 text-lg font-medium leading-none text-white inline-flex items-start justify-start px-6 py-3 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg"
                                            >
                                                Demander
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>


        </>
    );
}


export default AjouteCongeBibliothe;
