import React, { useState } from "react";
import SideBarSecretaire from "../../SideBarSecretaire/SideBarSecretaire";
import ListeRecus from "./ListeRecus";
function ListeRecusComponent({ getMenuMobille, getSizeMobille }) {
  return (
    <>
      <div class="flex overflow-hidden bg-white justify-between">
        <SideBarSecretaire getMenuMobille={getMenuMobille} />
        <ListeRecus getSizeMobille={getSizeMobille} />
      </div>
    </>
  );
}

export default ListeRecusComponent;
