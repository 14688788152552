import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import { Link, useNavigate, useParams } from "react-router-dom";
import FootterCopy from "../../FootterCopy/FootterCopy";
import { API, STATIC_URL } from "../../../Api/API";
import { decryptData } from "../../../encryptionModule";
function ModifierEleve({ getSizeMobille }) {


  const history = useNavigate()
  const { id } = useParams()
  const studentId = decryptData(id)
  const Sexeoptions = [
    { value: "", label: "Sexe" },
    { value: "M", label: "Masculin" },
    { value: "F", label: "Feminin" },
  ];

  const Situationoptions = [
    { value: "", label: "Interne/Externe" },
    { value: "Interne", label: "Interne" },
    { value: "Externe", label: "Externe" },
  ];



  const [Classeursoptions, setClasseursoptions] = useState([]);

  useEffect(() => {
    API.get("/classeur/all")
      .then((response) => {
        // Transformation des données pour correspondre à la structure attendue
        const classeursFromAPI = response.data.map(classeur => ({
          value: classeur.id.toString(), // Conversion en chaîne si nécessaire
          label: classeur.noClasseur
        }));
        // Mettre à jour l'état avec les nouvelles données
        setClasseursoptions([selectedOption3, ...classeursFromAPI]);

      })

      .catch((err) => {
        console.log(err.message);
      });
  }, []);




  const hiddenSituationoptions = Situationoptions.slice(1);
  const hiddenSexeoptions = Sexeoptions.slice(1);
  const hiddenSClasseursoptions = Classeursoptions.slice(1);


  const [selectedOption, setSelectedOption] = useState(Situationoptions[0]);
  const [selectedOption1, setSelectedOption1] = useState({ value: "", label: "Classe" });
  const [selectedOption2, setSelectedOption2] = useState(Sexeoptions[0]);
  const [selectedOption3, setSelectedOption3] = useState({ value: "", label: "Classeur" });


  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: 50,
      backgroundColor: "white",

    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "blue" : state.isFocused ? "lightblue" : "white",
      color: state.isSelected ? "white" : "black",
    }),
  };



  const SelectIntex_Inter = (options) => {
    setSelectedOption(options)
  }

  const SelectClasse = (options) => {
    setSelectedOption1(options)
  }
  const SelectSexe = (options) => {
    setSelectedOption2(options)
  }

  const SelectClasseur = (option) => {
    setSelectedOption3(option)
  }
  const [getUrl, SetGetUrl] = useState(null)

  const [mobile1, Setmonile1] = useState(window.innerWidth < 1335)

  useEffect(() => {
    const hundlesSize = () => {
      Setmonile1(window.innerWidth < 1335)

    }

    window.addEventListener('resize', hundlesSize)

    return () => {
      window.removeEventListener('resize', hundlesSize)
    }
  }, [])
  const [Nom, SetNom] = useState('')
  const [animationClassNom, setAnimationClassNom] = useState('');
  const elemetRefNom = useRef(null)




  const [Matricule, SetMatricule] = useState('')
  const [animationClassMatricule, setAnimationClassMatricule] = useState('');
  const elemetRefMatricule = useRef(null)



  const [Lieu_naissance, SetLieu_naissance] = useState('')
  const [animationClassLieu_naissance, setanimationClassLieu_naissance] = useState('');
  const elemetRefLieu_naissance = useRef(null)

  const [date_de_Naissance, Setdate_de_Naissance] = useState('')
  const [animationClassgetdate_de_Naissance, setanimationClassgetdate_de_Naissance] = useState('');
  const elemetRefdate_de_Naissance = useRef(null)

  const [animationClassSexe, setanimationClassSexe] = useState('');
  const elemetRefSexe = useRef(null)



  const [Nom_père, SetNom_père] = useState('')
  const [animationClassgetNom_père, setanimationClassgetNom_père] = useState('');
  const elemetRefNom_père = useRef(null)


  const [Nom_mère, SetNom_mère] = useState('')
  const [animationClassgetNom_mère, setanimationClassgetNom_mère] = useState('');
  const elemetRefNom_mère = useRef(null)








  const [Addresse_Parents, SetAddresse_Parents] = useState('')
  const [animationClassAddresse_Parents, setanimationClassAddresse_Parents] = useState('');
  const elemetRefAddresse_Parents = useRef(null)



  const [religion, SetReligion] = useState('')
  const [animationClassReligion, setanimationClassReligion] = useState('');
  const elemetRefReligion = useRef(null)



  const [animationClassInterne_Externe, setanimationClassInterne_Externe] = useState('');
  const elemetRefInterne_Externe = useRef(null)









  const [Ecole_origine, SetEcole_origine] = useState('')
  const [animationClassEcole_origine, setanimationClassEcole_origine] = useState('');
  const elemetRefSEcole_origine = useRef(null)


  const [Nationalité, SetNationalité] = useState('')
  const [animationClassNationalité, setanimationClassNationalité] = useState('');
  const elemetRefNationalité = useRef(null)


  const [animationClassClasseurs, setanimationClassCpassport] = useState('');
  const elemetRefClasseurs = useRef(null)

  // Fonction pour vérifier si la date de naissance n'est pas valide
  function isNotValid(dateString) {
    const birthDate = new Date(dateString); // Convertit la date de naissance en objet Date
    const today = new Date(); // Récupère la date actuelle
    const differenceInYears = today.getFullYear() - birthDate.getFullYear();
    return differenceInYears < 10; // Renvoie true si la différence en années est inférieure à 10
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    if (Nom.trim() === '') {
      toast.warning('Le Nom  est  obligatoire', {
        autoClose: 2000
      });
      setAnimationClassNom('animate__animated animate__shakeX border-2 border-red-500 ')
      setTimeout(() => {
        setAnimationClassNom(' ')
      }, 3000)
      elemetRefNom.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      elemetRefNom.current.focus()

      return false
    }
    else if (Matricule.trim() == '') {
      toast.warning("Matricule est obligatoire", {
        autoClose: 2000
      });
      setAnimationClassMatricule('animate__animated animate__shakeX  border-2 border-red-500')
      setTimeout(() => {
        setAnimationClassMatricule(' ')
      }, 3000)
      elemetRefMatricule.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      elemetRefMatricule.current.focus()
      return false
    }


    else if (Lieu_naissance.trim() == '') {
      toast.warning('Lieu de naissance est obligatoire ', {
        autoClose: 2000
      });
      setanimationClassLieu_naissance('animate__animated animate__shakeX border-2 border-red-500')
      setTimeout(() => {
        setanimationClassLieu_naissance(' ')
      }, 3000)
      elemetRefLieu_naissance.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      elemetRefLieu_naissance.current.focus()
      return;
    } else if (date_de_Naissance.trim() == '') {
      toast.warning('Date de naissance est obligatoire ', {
        autoClose: 2000
      });
      setanimationClassgetdate_de_Naissance('animate__animated animate__shakeX border-2 border-red-500')
      setTimeout(() => {
        setanimationClassgetdate_de_Naissance(' ')
      }, 3000)
      elemetRefdate_de_Naissance.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      elemetRefdate_de_Naissance.current.focus()
      return;

    } else if (isNotValid(date_de_Naissance)) {
      toast.warning('La date de naissance doit avoir une différence d\'au moins 10 ans jusqu\'à aujourd\'hui', {
        autoClose: 2000
      });
      setanimationClassgetdate_de_Naissance('animate__animated animate__shakeX border-2 border-red-500')
      setTimeout(() => {
        setanimationClassgetdate_de_Naissance(' ')
      }, 3000)
      elemetRefdate_de_Naissance.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      elemetRefdate_de_Naissance.current.focus()
      return;

    }
    else if (selectedOption2.value == '') {
      toast.warning('Genre est obligatoire ', {
        autoClose: 2000
      });
      setanimationClassSexe('animate__animated animate__shakeX border-2 border-red-500')
      setTimeout(() => {
        setanimationClassSexe(' ')
      }, 3000)
      elemetRefSexe.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      return;

    }
    else if (religion.trim() == '') {
      toast.warning('Religion est obligatoire ', {
        autoClose: 2000
      });
      setanimationClassReligion('animate__animated animate__shakeX border-2 border-red-500')
      setTimeout(() => {
        setanimationClassReligion(' ')
      }, 3000)
      elemetRefReligion.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      elemetRefReligion.current.focus()
      return;

    }

    else if (Ecole_origine.trim() == '') {
      toast.warning('Ecole d\'originede  est obligatoire ', {
        autoClose: 2000
      });
      setanimationClassEcole_origine('animate__animated animate__shakeX border-2 border-red-500')
      setTimeout(() => {
        setanimationClassEcole_origine(' ')
      }, 3000)
      elemetRefSEcole_origine.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      elemetRefSEcole_origine.current.focus()
      return;

    }
    else if (selectedOption.value == '') {
      toast.warning('Précisez s\'il/elle est interne ou externe ', {
        autoClose: 2000
      });
      setanimationClassInterne_Externe('animate__animated animate__shakeX border-2 border-red-500')
      setTimeout(() => {
        setanimationClassInterne_Externe(' ')
      }, 3000)
      elemetRefInterne_Externe.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      elemetRefInterne_Externe.current.focus()
      return;

    }




    else if (Nom_père.trim() == '') {
      toast.warning('Nom du père est obligatoire', {
        autoClose: 2000
      });
      setanimationClassgetNom_père('animate__animated animate__shakeX border-2 border-red-500')
      setTimeout(() => {
        setanimationClassgetNom_père(' ')
      }, 3000)
      elemetRefNom_père.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      elemetRefNom_père.current.focus()
      return;

    }


    else if (Nom_mère.trim() == '') {
      toast.warning('Nom du mère est obligatoire', {
        autoClose: 2000
      });
      setanimationClassgetNom_mère('animate__animated animate__shakeX border-2 border-red-500')
      setTimeout(() => {
        setanimationClassgetNom_mère(' ')
      }, 3000)
      elemetRefNom_mère.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      elemetRefNom_mère.current.focus()
      return;

    }

    else if (Nom_mère.trim() == '') {
      toast.warning('Nom du mère est obligatoire', {
        autoClose: 2000
      });
      setanimationClassgetNom_mère('animate__animated animate__shakeX border-2 border-red-500')
      setTimeout(() => {
        setanimationClassgetNom_mère(' ')
      }, 3000)
      elemetRefNom_mère.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      elemetRefNom_mère.current.focus()
      return;

    }

    else if (Nationalité.trim() == '') {
      toast.warning('Nationalité est obligatoire', {
        autoClose: 2000
      });
      setanimationClassNationalité('animate__animated animate__shakeX border-2 border-red-500')
      setTimeout(() => {
        setanimationClassNationalité(' ')
      }, 3000)
      elemetRefNationalité.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      elemetRefNationalité.current.focus()
      return;
    }

    else if (Addresse_Parents.trim() == '') {
      toast.warning('Address est obligatoire', {
        autoClose: 2000
      });
      setanimationClassAddresse_Parents('animate__animated animate__shakeX border-2 border-red-500')
      setTimeout(() => {
        setanimationClassAddresse_Parents(' ')
      }, 3000)
      elemetRefAddresse_Parents.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      elemetRefAddresse_Parents.current.focus()
      return;
    }

    else if (selectedOption3.value == '') {
      toast.warning('Le classeurs est obligatoire', {
        autoClose: 2000
      });
      setanimationClassCpassport('animate__animated animate__shakeX border-2 border-red-500')
      setTimeout(() => {
        setanimationClassCpassport(' ')
      }, 3000)
      elemetRefClasseurs.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      return;
    }

    const formadata = new FormData();
    const staffId = localStorage.getItem("staff") || 1
    formadata.append("nomprenom", Nom)
    formadata.append("matricule", Matricule)
    formadata.append("lieu", Lieu_naissance)
    formadata.append("date", date_de_Naissance)
    formadata.append("nomP", Nom_père)
    formadata.append("nomM", Nom_mère)
    formadata.append("addressPerent", Addresse_Parents)
    formadata.append("religion", religion)
    formadata.append("type_acces", selectedOption.value)
    formadata.append("nationalite", Nationalité)
    formadata.append("sexe", selectedOption2.value)
    formadata.append("origine", Ecole_origine)
    formadata.append("classeurId", selectedOption3.value)
    formadata.append("staffId", staffId)
    if (getUrl) {
      formadata.append("photo", getUrl)
    }



    API.put(`/student/OneChange/${studentId}`, formadata, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then((res) => {
      toast.success(res.data)
      history("/ClasseChoixSecretaire")
    }).catch((error) => {
      if (error.response) {
        // Le serveur a répondu avec un code d'erreur (status code)
        if (Array.isArray(error.response.data)) {
          // Si c'est un tableau, afficher le premier élément
          toast.warn(error.response.data[0]);
        } else if (typeof error.response.data === 'object') {
          // S'il s'agit d'un objet, afficher la première valeur de la propriété
          const keys = Object.keys(error.response.data);
          if (keys.length > 0) {
            toast.warn(error.response.data[keys[0]]);
          }
        } else if (typeof error.response.data === 'string') {
          // Si c'est une chaîne de caractères, l'afficher directement
          toast.warn(error.response.data);
        } else {
          // Autre type non pris en charge
          toast.warn("Erreur du serveur")

        }

        // Afficher les erreurs au sein de votre composant ou effectuer une action en conséquence
      } else if (error.request) {
        // La requête a été faite mais aucune réponse n'a été reçue
        console.error('Pas de réponse du serveur :', error.request);
      } else {
        // Une erreur s'est produite lors de la configuration de la requête
        console.error('Erreur lors de la requête :', error.message);
      }
    })

  }

  const [photo, setPototo] = useState(null)
  useEffect(() => {
    API.get(`/student/ParId/${studentId}`).then((res) => {
      const studentData = res.data;
      SetNom(studentData.nomprenom)
      SetMatricule(studentData.matricule)
      SetLieu_naissance(studentData.lieu)
      SetNom_père(studentData.nomP)
      SetNom_mère(studentData.nomM)
      SetAddresse_Parents(studentData.addressPerent)
      SetReligion(studentData.religion)
      SetNationalité(studentData.nationalite)
      SetEcole_origine(studentData.origine)
      setPototo(studentData.photo)
      if (studentData.classeurId) {
        const correspondingClasseur = Classeursoptions.find(
          (classeur) => classeur.value == studentData.classeurId.toString()
        );

        if (correspondingClasseur) {
          setSelectedOption3(correspondingClasseur);
        }
      }
      if (studentData.type_acces) {
        const correspondingacces = Situationoptions.find(
          (acces) => acces.value == studentData.type_acces
        );
        if (correspondingacces) {
          setSelectedOption(correspondingacces);
        }
      }
      if (studentData.sexe) {
        const correspondingsexe = Sexeoptions.find(
          (sexe) => sexe.value == studentData.sexe
        );
        if (correspondingsexe) {
          setSelectedOption2(correspondingsexe);
        }
      }
      Setdate_de_Naissance(studentData.date.substring(0, 10))
    }).catch((err) => {
      console.log(err.message)
    })
  }, [id, Classeursoptions])





  return (
    <>
      <div className={`bg-white  overflow-x-auto  h-[85vh] ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
        <Link to='/ClasseChoixSecretaire' className="ml-2 text-[18px] text-blue-500 hover:text-blue-800 underline">Retour</Link>

        <div className="sm:p-4 flex justify-center ">



          <div class="sm:px-4 w-full">
            <div class="sm:my-5">
              <div class="w-full flex flex-row items-center justify-between">
                <p
                  class="hover:text-navy-700 text-gray-700 max-sm:pl-3 uppercase sm:text-2xl font-bold"

                >
                  Modifier les information de l'élève
                </p>
              </div>
            </div>
            <div class="w-full my-2 grid grid-cols-1 ">
              <div class="bg-white  rounded-lg  p-2 sm:p-6 xl:p-8">
                <form onSubmit={handleSubmit}>
                  <div class="bg-white shadow rounded px-3 sm:px-8 pt-6 pb-8 mb-4 flex flex-col my-2">
                    <div class="-mx-3 md:flex mb-6">
                      <div class="md:w-1/2 px-3 mb-6 md:mb-0">
                        <label
                          class="block uppercase tracking-wide text-gray-700 text-lg font-bold mb-2"
                          for="grid-Title"
                        >
                          Nom Complet
                        </label>
                        <input
                          class={` outline-none block w-full bg-grey-lighter  border rounded py-3 px-4 mb-3 ${Nom.trim() === '' ? animationClassNom : 'focus:border-2 focus:border-blue-700'} `}

                          ref={elemetRefNom} value={Nom} onInput={(e) => SetNom(e.target.value)}
                          placeholder="Nom Complet"

                        />

                      </div>
                      <div class="md:w-1/2 px-3">
                        <label
                          class="block uppercase tracking-wide text-gray-700 text-lg font-bold mb-2"
                          for="grid-last-name"
                        >
                          Matricule
                        </label>
                        <input
                          class={` outline-none block w-full bg-grey-lighter  border rounded py-3 px-4 mb-3 ${Matricule.trim() === '' ? animationClassMatricule : 'focus:border-2 focus:border-blue-700'} `}
                          type="text"
                          ref={elemetRefMatricule} value={Matricule} onInput={(e) => SetMatricule(e.target.value)}
                          placeholder="Matricule"
                        />

                      </div>
                    </div>
                    <div class="-mx-3 md:flex mb-6">
                      <div class="md:w-1/2 px-3">
                        <label
                          class="block uppercase tracking-wide text-gray-700 text-lg font-bold mb-2"
                          for="grid-password"
                        >
                          Lieu de Naissance
                        </label>
                        <input
                          class={` outline-none block w-full bg-grey-lighter  border rounded py-3 px-4 mb-3 ${Lieu_naissance.trim() === '' ? animationClassLieu_naissance : 'focus:border-2 focus:border-blue-700'} `}
                          type="text"
                          ref={elemetRefLieu_naissance} value={Lieu_naissance} onInput={(e) => SetLieu_naissance(e.target.value)}
                          placeholder="Lieu de Naissance"
                          name="lieuNaissance"
                        />

                      </div>




                      <div class="md:w-1/2 px-3">
                        <label
                          class="block uppercase tracking-wide text-gray-700 text-lg font-bold mb-2"
                          for="grid-password"
                        >
                          Date de Naissance
                        </label>
                        <input
                          class={` outline-none block w-full bg-grey-lighter  border rounded py-3 px-4 mb-3 ${date_de_Naissance.trim() === '' ? animationClassgetdate_de_Naissance : 'focus:border-2 focus:border-blue-700'} `}
                          type="date"
                          ref={elemetRefdate_de_Naissance} value={date_de_Naissance} onInput={(e) => Setdate_de_Naissance(e.target.value)}
                          placeholder="Date de Naissance"
                          name="dateNaissance"
                        />

                      </div>






                    </div>
                    <div class="-mx-3 md:flex mb-6">
                      <div class="md:w-1/2 px-3">
                        <label
                          class="block uppercase tracking-wide text-gray-700 text-lg font-bold mb-2"
                          for="grid-state"
                        >
                          Sexe
                        </label>
                        <div ref={elemetRefSexe} className={` rounded  ${selectedOption2.value == '' ? animationClassSexe : 'focus:border-2 focus:border-blue-700'} `}
                        >
                          <Select
                            options={hiddenSexeoptions}
                            styles={customStyles}
                            value={selectedOption2}
                            onChange={SelectSexe}
                          />
                        </div>

                      </div>

                      <div class="md:w-1/2 px-3">
                        <label
                          class="block uppercase tracking-wide text-gray-700 text-lg font-bold mb-2"
                          for="grid-password"
                        >
                          Religion
                        </label>
                        <input
                          class={` outline-none block w-full bg-grey-lighter  border rounded py-3 px-4 mb-3 ${religion.trim() === '' ? animationClassReligion : 'focus:border-2 focus:border-blue-700'} `}
                          type="text"
                          ref={elemetRefReligion} value={religion} onInput={(e) => SetReligion(e.target.value)}
                          placeholder="Religion"
                        />

                      </div>
                    </div>
                    <div class="-mx-3 md:flex mb-6">
                      <div class="md:w-1/2 px-3">
                        <label
                          class="block uppercase tracking-wide text-gray-700 text-lg font-bold mb-2"
                          for="grid-password"
                        >
                          Ecole d'origine
                        </label>
                        <input
                          class={` outline-none block w-full bg-grey-lighter  border rounded py-3 px-4 mb-3 ${Ecole_origine.trim() === '' ? animationClassEcole_origine : 'focus:border-2 focus:border-blue-700'} `}
                          type="text"
                          ref={elemetRefSEcole_origine} value={Ecole_origine} onInput={(e) => SetEcole_origine(e.target.value)}
                          placeholder="Ecole d'origine"
                          name="ecoleOrigine"
                        />

                      </div>
                      <div class="md:w-1/2 px-3">
                        <label
                          class="block uppercase tracking-wide text-gray-700 text-lg font-bold mb-2"
                          for="grid-state"
                        >
                          Interne/Externe
                        </label>
                        <div ref={elemetRefInterne_Externe} className={` rounded  ${selectedOption.value == '' ? animationClassInterne_Externe : 'focus:border-2 focus:border-blue-700'} `}
                        >
                          <Select
                            value={selectedOption}
                            options={hiddenSituationoptions}
                            styles={customStyles}
                            onChange={SelectIntex_Inter}

                          />
                        </div>

                      </div>

                    </div>
                    <div class="-mx-3 md:flex mb-6">






                      <div class="md:w-1/2 px-3">
                        <label
                          class="block uppercase tracking-wide text-gray-700 text-lg font-bold mb-2"
                          for="grid-password"
                        >
                          Nom du père
                        </label>
                        <input
                          class={` outline-none block w-full bg-grey-lighter  border rounded py-3 px-4 mb-3 ${Nom_père.trim() === '' ? animationClassgetNom_père : 'focus:border-2 focus:border-blue-700'} `}
                          type="text"
                          ref={elemetRefNom_père} value={Nom_père} onInput={(e) => SetNom_père(e.target.value)}
                          placeholder="Nom du père"
                          name="pere"
                        />

                      </div>


                      <div class="md:w-1/2 px-3">
                        <label
                          class="block uppercase tracking-wide text-gray-700 text-lg font-bold mb-2"
                          for="grid-password"
                        >
                          Nom du mère
                        </label>
                        <input
                          class={` outline-none block w-full bg-grey-lighter  border rounded py-3 px-4 mb-3 ${Nom_mère.trim() === '' ? animationClassgetNom_mère : 'focus:border-2 focus:border-blue-700'} `}
                          type="text"
                          ref={elemetRefNom_mère} value={Nom_mère} onInput={(e) => SetNom_mère(e.target.value)}
                          placeholder="Nom du Mere"
                        />

                      </div>

                    </div>

                    <div class="-mx-3 md:flex mb-6">

                      <div class="md:w-1/2 px-3">
                        <label
                          class="block uppercase tracking-wide text-gray-700 text-lg font-bold mb-2"
                          for="grid-password"
                        >
                          Nationalité
                        </label>
                        <input
                          class={` outline-none block w-full bg-grey-lighter  border rounded py-3 px-4 mb-3 ${Nationalité.trim() === '' ? animationClassNationalité : 'focus:border-2 focus:border-blue-700'} `}
                          type="text"
                          ref={elemetRefNationalité} value={Nationalité} onInput={(e) => SetNationalité(e.target.value)}
                          placeholder="Nationalité"
                        />

                      </div>

                      <div class="md:w-1/2 px-3">
                        <label
                          class="block uppercase tracking-wide text-gray-700 text-lg font-bold mb-2"
                          for="grid-password"
                        >
                          Addresse des Parents
                        </label>
                        <input
                          class={` outline-none block w-full bg-grey-lighter  border rounded py-3 px-4 mb-3 ${Addresse_Parents.trim() === '' ? animationClassAddresse_Parents : 'focus:border-2 focus:border-blue-700'} `}
                          type="text"
                          ref={elemetRefAddresse_Parents} value={Addresse_Parents} onInput={(e) => SetAddresse_Parents(e.target.value)}
                          placeholder="Addresse des Parents"
                          name="addressParent"
                        />

                      </div>





                      <div class="md:w-1/2 px-3">
                        <label
                          class="block uppercase tracking-wide text-gray-700 text-lg font-bold mb-2"
                          for="grid-state"



                        >
                          Classeurs
                        </label>
                        <div ref={elemetRefClasseurs} className={` rounded  ${selectedOption3.value == '' ? animationClassClasseurs : 'focus:border-2 focus:border-blue-700'} `}
                        >
                          <Select
                            value={selectedOption3}
                            options={hiddenSClasseursoptions}
                            styles={customStyles}
                            onChange={SelectClasseur}

                          />
                        </div>

                      </div>


                    </div>
                    <div class={` px-3 ${mobile1 ? 'w-full' : 'md:w-1/2'}`}>
                      <label htmlFor="possport"
                        class="appearance-none text-center  cursor-pointer uppercase tracking-wide text-gray-700 sm:text-lg font-bold block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4 mb-3"


                      >
                        <input type="file"
                          id="possport"
                          accept="image/*"
                          hidden
                          onChange={(e) => { SetGetUrl(e.target.files[0]) }}
                        />
                        Photos passeport s'il est nécessaire
                      </label>


                    </div>

                    {getUrl ? (<div class="md:w-1/2 h-[20em] bg-white border rounded px-3">
                      <img src={URL.createObjectURL(getUrl)} className="w-full h-full object-contain object-center" />
                    </div>) : photo && (
                      <div class="md:w-1/2 h-[20em] bg-white border rounded px-3">
                        <img src={`${STATIC_URL}/students/${photo}`} className="w-full h-full object-contain object-center" />
                      </div>
                    )}

                    <div class="mb-4 flex items-center justify-end">
                      <div class="flex-shrink-0">
                        <button
                          type="submit"
                          class="mt-4 sm:mt-0 text-lg font-medium leading-none text-white inline-flex items-start justify-start px-6 py-3 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg"
                        >
                          Modifier
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

          </div>


        </div>
        <FootterCopy />
      </div>
    </>
  );
}

export default ModifierEleve;
