/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import FootterCopy from "../../FootterCopy/FootterCopy";
import { API } from "../../../Api/API";

function Accueil({ getSizeMobille }) {

  const [monthlyStats, setMonthlyStats] = useState([]);
  const [months, setMonths] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await API.get('/livres/statistiques-emprunts-mensuels');
        setMonthlyStats(response.data.stats);
        setMonths(response.data.months);
      } catch (error) {
        console.error('Error fetching monthly statistics:', error);
      }
    };

    fetchData();
  }, []);

  const options = {
    chart: {
      id: "basic-bar",
      type: 'bar',
      height: 500,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded',
      },
    },
    xaxis: {
      categories: months,
    },
    colors: ['#FF5733', '#3366FF', '#33FF57'],
    fill: {
      opacity: 1,
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
  };

  const series = [
    {
      name: 'Elèves',
      data: monthlyStats.map(stat => stat.eleves),
    },
    {
      name: 'Personnels',
      data: monthlyStats.map(stat => stat.personnels),
    },
    {
      name: 'Visiteurs',
      data: monthlyStats.map(stat => stat.visiteurs),
    },
  ];

  const [monthlyReturnedStats, setMonthlyReturnedStats] = useState([]);

  useEffect(() => {
    const fetchMonthlyReturnedStats = async () => {
      try {
        const response = await API.get('/livres/statistiques-emprunts-retournes');
        setMonthlyReturnedStats(response.data.monthlyReturnedStats);
      } catch (error) {
        console.error('Erreur lors de la récupération des données :', error.message);
      }
    };

    fetchMonthlyReturnedStats();
  }, [])


  const [mobile1, Setmonile1] = useState(window.innerWidth < 349)

  const [mobile11, SetMobile11] = useState(window.innerWidth < 501)

  useEffect(() => {
    const hundlesSize = () => {
      Setmonile1(window.innerWidth < 349)
      SetMobile11(window.innerWidth < 501)
    }

    window.addEventListener('resize', hundlesSize)

    return () => {
      window.removeEventListener('resize', hundlesSize)
    }
  }, [])

  const [getStati, SetgetStati] = useState('Statistique sur un mois')

  return (

    <div className={`bg-white  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
      <div className="sm:p-4 p-2 min-h-screen">
        <div class="my-5">
          <div class="w-full flex flex-row items-center justify-between">
            <p class="text-navy-700 shrink text-[33px]">
              <p
                class="hover:text-navy-700 font-bold "

              >
                Rapports de 12 mois
              </p>
            </p>
          </div>
        </div>

        <div class="w-full grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-4">

          <div className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 2xl:col-span-2">
            <div className="flex items-center justify-between mb-4">
              <div>
                <h3 className="text-xl leading-none font-bold text-blue-600">
                  Evolution des livres emprunts
                </h3>
              </div>
            </div>
            <div id="main-chart" style={{ minHeight: '435px' }}>
              <Chart options={options} series={series} type="bar" height={500} />
            </div>
          </div>

          <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 2xl:col-span-2">
            <div class="flex items-center justify-between mb-4">
              <div class="">
                <h3 class="text-xl leading-none font-bold text-blue-600">
                  Evolutions des livres retournés
                </h3>
              </div>
            </div>
            {monthlyReturnedStats.length > 0 && (
              <div className="bar-chart">
                <Chart
                  options={{
                    chart: {
                      height: 350,
                      type: 'bar',
                      toolbar: {
                        show: true
                      }
                    },
                    xaxis: {
                      categories: monthlyReturnedStats.map(stat => stat.month),
                      labels: {
                        rotate: -45,
                        style: {
                          fontSize: '12px'
                        }
                      }
                    },
                    yaxis: {
                      title: {
                        text: 'Nombre d\'emprunts retournés'
                      }
                    },
                    colors: ['#FF5733', '#3366CC', '#33CC33']
                  }}
                  series={[
                    {
                      name: 'Élèves',
                      data: monthlyReturnedStats.map(stat => stat.returnedEleves)
                    },
                    {
                      name: 'Personnel',
                      data: monthlyReturnedStats.map(stat => stat.returnedPersonnel)
                    },
                    {
                      name: 'Visiteurs',
                      data: monthlyReturnedStats.map(stat => stat.returnedVisiteurs)
                    }
                  ]}
                  type="bar"
                  height={500}
                />
              </div>
            )}

          </div>

        </div>















      </div>
      <FootterCopy />
    </div>
  );
}

export default Accueil;
