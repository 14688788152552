import React from "react";
import { Link } from "react-router-dom";
import FootterCopy from "../../FootterCopy/FootterCopy";
import swal from "sweetalert";
function PatientDetail({ getSizeMobille }) {
    const handleConfirmation = async () => {
        try {
            const result = await swal({
                title: "Confirmation",
                text: "Vous Voulez Supprimer ?",
                icon: "warning",
                buttons: ["No", "Oui"],
                dangerMode: true,
                className: "centered-buttons",
            });

            if (result) {
                // User clicked "Yes"
                // Perform the desired action
                console.log("Confirmed");
            } else {
                // User clicked "No" or closed the dialog
                console.log("Cancelled");
            }
        } catch (error) {
            // Handle error or dialog dismissal
            console.log("Error or dismissal:", error);
        }
    };
    return (
        <>
            <div className={`bg-white  overflow-x-auto  h-[85vh] ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
                <Link to='/Patient' className="ml-2 text-[18px] text-blue-500 hover:text-blue-800 underline">Retour</Link>
                <div className="sm:p-6 p-2  min-h-[85vh]">
                    <div class="my-3 flex  w-full justify-between">
                        <div class="flex flex-row items-center justify-between">
                            <p class="text-navy-700 shrink  ">
                                <p class="hover:text-navy-700 text-gray-700 uppercase text-3xl font-bold "                                >
                                    Detail
                                </p>
                            </p>
                        </div>
                        <div onClick={() => { alert('Je suis un bouton,mon role est que si un patient est deja enregistre dans votre systeme,L\'infirmiere pose la question:"Mwari mumaze kwivusa ngaga ", twoca dutegura formulaire yabamaze kuba bari muri systeme kuko baratanduhanye ivyo twobasaba,comme nom,prenom,matricule,CAM,CNI, nkivyo ntivyogaruka kuwusanzw ari muri systeme     ') }} class="cursor-pointer py-1 px-2 rounded text-white bg-blue-700 ">
                            Consulte
                        </div>
                    </div>
                    <div class="w-full my-4 grid grid-cols-1 ">
                        <div class="rounded-lg p-2 sm:p-8">
                            <div class=" rounded-lg bg-gray-100 border sm:p-4 flex flex-col items-center justify-start">
                                <h2 className="w-full text-2xl text-blue-700">Consultation du mardi ,le 15/05/2024</h2>
                                <div className="h-[1px] w-full bg-white my-2"></div>
                                <div class="flex flex-col bg-white p-3 rounded-md justify-start">
                                    <p class="mb-4  text-xl ">
                                        Nom complet : NIYONKURU Gratien
                                    </p>
                                    <p class="mb-4  text-xl ">
                                        Classe : IM
                                    </p>
                                    <p class="mb-4  text-xl ">
                                        Matricule : 02/1002
                                    </p>
                                    <p class="mb-4  text-xl ">
                                        Age: 10
                                    </p>

                                    <p class="mb-4  text-xl ">
                                        Poid : 30 Kg
                                    </p>
                                    <p class="mb-4  text-xl ">
                                        Groupe d'age : A2
                                    </p>
                                    <p class="mb-4  text-xl ">
                                        Origine : HC
                                    </p>
                                    <p class="mb-4  text-xl ">
                                        Ancien cas :  Non
                                    </p>
                                    <p class="mb-4  text-xl ">
                                        Contre reference :  Non
                                    </p>

                                    <p class="mb-4  text-xl ">Alcool :  Non</p>
                                    <p class="mb-4  text-xl ">Tabac :  Non</p>
                                    <p class="mb-4  text-xl ">Paramettre :
                                        <div className="pl-5 text-xl">hshs ssjjs sjsjsj sjssjss s sksskskkkskss sisisis idjdud sisoss sisoiisisd sisiis d didididis sisusidis s sisisisodudis sisis</div>
                                    </p>
                                    <p class="mb-4  text-xl ">Fièvre:  umutwe</p>
                                    <p class="mb-4  text-xl ">Numéro de CNI :  290302/92030.HBS</p>
                                    <p class="mb-4  text-xl ">Numéro de CAM :  290302/92030.HBS</p>
                                    <p class="mb-4  text-xl ">Status : Non</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FootterCopy />
            </div>
        </>
    );
}

export default PatientDetail;
