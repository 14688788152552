import React, { useState } from "react";
import DetailExpedierComponent from "../../component/Secretariat/Courriers/Expedier/DetailExpedierComponent";
function DetailExpedierPage() {
  return (
    <>
      <DetailExpedierComponent />
    </>
  );
}

export default DetailExpedierPage;
