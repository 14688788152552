import React from 'react'
import PrevisioModifier from './PrevisioModifier'
import SlideEncadrementNavBars from '../SlideEncadrementNavBars/SlideEncadrementNavBars'
function PrevisioModifierEncadreurComponent({ getSizeMobille, getMenuMobille }) {
    return (
        <div className='flex justify-between overflow-hidden bg-white'>
            <SlideEncadrementNavBars getMenuMobille={getMenuMobille} />
            <PrevisioModifier getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default PrevisioModifierEncadreurComponent
