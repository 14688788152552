import React from 'react'
import AcceuilEncadrement from './AcceuilEncadrement'
import SlideEncadrementNavBars from '../SlideEncadrementNavBars/SlideEncadrementNavBars'
function AcceuilEncadrementComponent({ getSizeMobille, getMenuMobille }) {
    return (
        <div class="overflow-hidden flex justify-between">
            <SlideEncadrementNavBars getMenuMobille={getMenuMobille} />
            <AcceuilEncadrement getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default AcceuilEncadrementComponent
