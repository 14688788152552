import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import "apexcharts/dist/apexcharts.css";
import FootterCopy from "../../FootterCopy/FootterCopy";
import { API } from "../../../Api/API"

function Accueil({ getSizeMobille }) {


  const [mobile1, Setmonile1] = useState(window.innerWidth < 349)

  const [mobile11, SetMobile11] = useState(window.innerWidth < 501)

  useEffect(() => {
    const hundlesSize = () => {
      Setmonile1(window.innerWidth < 349)
      SetMobile11(window.innerWidth < 501)
    }

    window.addEventListener('resize', hundlesSize)

    return () => {
      window.removeEventListener('resize', hundlesSize)
    }
  }, [])

  const [emprunts, setEmprunts] = useState({})
  useEffect(() => {
    API.get('/livres/statistiques-emprunts').then((resp) => {
      setEmprunts(resp.data)
    }).catch((err) => {
      console.log(err.message)
    })
  }, [])

  const [monthlyStats, setMonthlyStats] = useState([]);
  const [months, setMonths] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await API.get('/livres/statistiques-emprunts-mensuels');
        setMonthlyStats(response.data.stats);
        setMonths(response.data.months);
      } catch (error) {
        console.error('Error fetching monthly statistics:', error);
      }
    };

    fetchData();
  }, []);

  const options = {
    chart: {
      id: "basic-bar",
      type: 'bar',
      height: 500,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded',
      },
    },
    xaxis: {
      categories: months,
    },
    colors: ['#FF5733', '#3366FF', '#33FF57'],
    fill: {
      opacity: 1,
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
  };

  const series = [
    {
      name: 'Elèves',
      data: monthlyStats.map(stat => stat.eleves),
    },
    {
      name: 'Personnels',
      data: monthlyStats.map(stat => stat.personnels),
    },
    {
      name: 'Visiteurs',
      data: monthlyStats.map(stat => stat.visiteurs),
    },
  ];

  const [monthlyReturnedStats, setMonthlyReturnedStats] = useState([]);

  useEffect(() => {
    const fetchMonthlyReturnedStats = async () => {
      try {
        const response = await API.get('/livres/statistiques-emprunts-retournes');
        setMonthlyReturnedStats(response.data.monthlyReturnedStats);
      } catch (error) {
        console.error('Erreur lors de la récupération des données :', error.message);
      }
    };

    fetchMonthlyReturnedStats();
  }, [])


  const [prev, setLibraryCount] = useState(0);
  const [dep, setDep] = useState(0);
  const [res, setRes] = useState(0);
  const staffId = 21
  useEffect(() => {
    const fetchLibraryCount = async () => {
      try {
        const response = await API.get(`/prev/bibliotequeCountByStaffId/${staffId}`);
        setLibraryCount(response.data);
      } catch (error) {
        console.log(error.message);
      }
    };

    fetchLibraryCount();
  }, [staffId]);


  useEffect(() => {
    const fetchLibraryCount = async () => {
      try {
        const response = await API.get(`/dep/bibliotequeCountByStaffId/${staffId}`);
        setDep(response.data);
      } catch (error) {
        console.log(error.message);
      }
    };

    fetchLibraryCount();
  }, [staffId]);

  useEffect(() => {
    const fetchLibraryCount = async () => {
      try {
        const response = await API.get("/livres/touslesresumesCount");
        setRes(response.data);
      } catch (error) {
        console.log(error.message);
      }
    };

    fetchLibraryCount();
  }, []);
  return (

    <div className={`bg-white  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>

      <div className="sm:p-4 p-2 min-h-screen">
        <div class="sm:my-10 my-2">
          <div class="w-full flex flex-row items-center justify-between">
            <p class="text-navy-700 shrink text-[25px] sm:text-[33px]  text-gray-600">
              <p
                class="hover:text-navy-700 font-bold  text-gray-600 font-serif"

              >
                Main Accueil
              </p>
            </p>

          </div>
        </div>

        <div class="my-4 w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">

          {emprunts && (
            <>
              <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">
                <div class="flex  flex-col">
                  <div class=" flex">
                    <span class="text-[20px]  sm:text-3xl leading-none font-bold text-gray-900">
                      {emprunts.totalLivres}
                    </span>
                    <div class="ml-5 w-0 flex items-center justify-end flex-1 text-green-500 text-xl font-bold">
                      {(emprunts.totalLivres * 100 / emprunts.totalLivres).toFixed(1)}%
                      <svg
                        class="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M5.293 7.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L6.707 7.707a1 1 0 01-1.414 0z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </div>
                  </div>
                  <h3 class="text-[18px] max-sm:w-full text-center mt-4 sm:text-xl font-normal text-gray-500">
                    Nombres de Livres Total
                  </h3>
                </div>
              </div>
              <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">
                <div class="flex  flex-col">
                  <div class=" flex">
                    <span class="text-[20px]  sm:text-3xl leading-none font-bold text-gray-900">

                      {emprunts.totalEmprunts}
                    </span>


                    <div class="ml-5 w-0 flex items-center justify-end flex-1 text-green-500 text-xl font-bold">
                      {(emprunts.totalEmprunts * 100 / emprunts.totalLivres).toFixed(1)}%
                      <svg
                        class="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M5.293 7.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L6.707 7.707a1 1 0 01-1.414 0z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </div>
                  </div>
                  <h3 class="text-[18px] max-sm:w-full text-center mt-4 sm:text-xl font-normal text-gray-500">
                    Nombre de Livre Empruntes
                  </h3>
                </div>
              </div>
              <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">
                <div class="flex  flex-col">
                  <div class=" flex">
                    <span class="text-[20px]  sm:text-3xl leading-none font-bold text-gray-900">
                      {emprunts.totalRetournes}
                    </span>


                    <div class="ml-5 w-0 flex items-center justify-end flex-1 text-green-500 text-xl font-bold">
                      {(emprunts.totalRetournes * 100 / emprunts.totalLivres).toFixed(1)}%
                      <svg
                        class="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M5.293 7.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L6.707 7.707a1 1 0 01-1.414 0z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </div>
                  </div>
                  <h3 class="text-[18px] max-sm:w-full text-center mt-4 sm:text-xl font-normal text-gray-500">
                    Nombres Livres Retournes
                  </h3>
                </div>
              </div>
              <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">
                <div class="flex  flex-col">
                  <div class=" flex">
                    <span class="text-[20px]  sm:text-3xl leading-none font-bold text-gray-900">
                      {emprunts.totalNonRetournes}
                    </span>


                    <div class="ml-5 w-0 flex items-center justify-end flex-1 text-green-500 text-xl font-bold">
                      {(emprunts.totalNonRetournes * 100 / emprunts.totalLivres).toFixed(1)}%
                      <svg
                        class="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M5.293 7.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L6.707 7.707a1 1 0 01-1.414 0z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </div>
                  </div>
                  <h3 class="text-[18px] max-sm:w-full text-center mt-4 sm:text-xl font-normal text-gray-500">
                    Nombres Livres Non retournes
                  </h3>
                </div>
              </div>
              <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">
                <div class="flex  flex-col">
                  <div class=" flex">
                    <span class="text-[20px]  sm:text-3xl leading-none font-bold text-gray-900">
                      {emprunts.totalLivres - emprunts.totalEmprunts}
                    </span>


                    <div class="ml-5 w-0 flex items-center justify-end flex-1 text-green-500 text-xl font-bold">
                      {((emprunts.totalLivres - emprunts.totalEmprunts) * 100 / emprunts.totalLivres).toFixed(1)}%
                      <svg
                        class="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M5.293 7.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L6.707 7.707a1 1 0 01-1.414 0z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </div>
                  </div>
                  <h3 class="text-[18px] max-sm:w-full text-center mt-4 sm:text-xl font-normal text-gray-500">
                    Nombres Livres Hors Usage
                  </h3>
                </div>
              </div>
            </>
          )}



          <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">
            <div class="flex  flex-col">
              <div class=" flex">
                <span class="text-[20px]  sm:text-3xl leading-none font-bold text-gray-900">
                  {prev}
                </span>
              </div>
              <h3 class="text-[18px] max-sm:w-full text-center mt-4 sm:text-xl font-normal text-gray-500">
                Nombres des prévisions
              </h3>
            </div>
          </div>


          <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">
            <div class="flex  flex-col">
              <div class=" flex">
                <span class="text-[20px]  sm:text-3xl leading-none font-bold text-gray-900">
                  {dep}
                </span>
              </div>
              <h3 class="text-[18px] max-sm:w-full text-center mt-4 sm:text-xl font-normal text-gray-500">
                Nombres de s depenses
              </h3>
            </div>
          </div>

          <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">
            <div class="flex  flex-col">
              <div class=" flex">
                <span class="text-[20px]  sm:text-3xl leading-none font-bold text-gray-900">
                  {res}
                </span>
              </div>
              <h3 class="text-[18px] max-sm:w-full text-center mt-4 sm:text-xl font-normal text-gray-500">
                Nombres des résumes
              </h3>
            </div>
          </div>

        </div>


        <div class="w-full grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-4">

          <div className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 2xl:col-span-2">
            <div className="flex items-center justify-between mb-4">
              <div>
                <h3 className="text-xl leading-none font-bold text-blue-600">
                  Evolution des livres emprunts
                </h3>
              </div>
            </div>
            <div id="main-chart" style={{ minHeight: '435px' }}>
              <Chart options={options} series={series} type="bar" height={500} />
            </div>
          </div>

          <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 2xl:col-span-2">
            <div class="flex items-center justify-between mb-4">
              <div class="">
                <h3 class="text-xl leading-none font-bold text-blue-600">
                  Evolutions des livres retournés
                </h3>
              </div>
            </div>
            {monthlyReturnedStats.length > 0 && (
              <div className="bar-chart">
                <Chart
                  options={{
                    chart: {
                      height: 350,
                      type: 'bar',
                      toolbar: {
                        show: true
                      }
                    },
                    xaxis: {
                      categories: monthlyReturnedStats.map(stat => stat.month),
                      labels: {
                        rotate: -45,
                        style: {
                          fontSize: '12px'
                        }
                      }
                    },
                    yaxis: {
                      title: {
                        text: 'Nombre d\'emprunts retournés'
                      }
                    },
                    colors: ['#FF5733', '#3366CC', '#33CC33']
                  }}
                  series={[
                    {
                      name: 'Élèves',
                      data: monthlyReturnedStats.map(stat => stat.returnedEleves)
                    },
                    {
                      name: 'Personnel',
                      data: monthlyReturnedStats.map(stat => stat.returnedPersonnel)
                    },
                    {
                      name: 'Visiteurs',
                      data: monthlyReturnedStats.map(stat => stat.returnedVisiteurs)
                    }
                  ]}
                  type="bar"
                  height={500}
                />
              </div>
            )}

          </div>

        </div>
      </div>
      <FootterCopy />
    </div>


  );
}

export default Accueil;
