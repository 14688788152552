import React from 'react'
import MaterielListeEncadrementEmpruntElevesAll from './MaterielListeEncadrementEmpruntElevesAll'
import SlideEncadrementNavBars from '../SlideEncadrementNavBars/SlideEncadrementNavBars'
function MaterielListeEncadrementEmpruntElevesAllComponent({ getSizeMobille, getMenuMobille }) {
    return (
        <div className='flex justify-between overflow-hidden bg-white'>
            <SlideEncadrementNavBars getMenuMobille={getMenuMobille} />
            <MaterielListeEncadrementEmpruntElevesAll getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default MaterielListeEncadrementEmpruntElevesAllComponent
