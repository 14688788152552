import React, { useState } from "react";
import AjouterDepenseBiblioComponent from "../../component/Bibliotheque/Depenses/AjouterDepenseBiblioComponent";

function AjouterDepenseBiblioPage({ getSizeMobille, getMenuMobille }) {
  return (
    <>
      <AjouterDepenseBiblioComponent getMenuMobille={getMenuMobille} getSizeMobille={getSizeMobille} />
    </>
  );
}

export default AjouterDepenseBiblioPage;
