import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ADD } from '../../../Redux/Action/Action';
import { toast } from 'react-toastify';
import { cardData } from '../../../Data/Data';
import { InView } from 'react-intersection-observer';
import { FadeLoader } from 'react-spinners';
import { Link, useParams } from 'react-router-dom';
import { API } from '../../../Api/API';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
function MaterielListeEncadrementEmpruntPersonne({ getSizeMobille }) {
    const { id } = useParams()
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [totalElements, setTotalElements] = useState(0)

    const [etat, setEtat] = useState(1)
    const handleEtatChange = (etat) => {
        setEtat(etat);
    };
    const [rechercher, setRechercher] = useState(false)
    const [isrechercher, setisRechercher] = useState(0)
    function handleSearch() {
        setRechercher(true)
        setisRechercher(isrechercher + 1)
    }



    useEffect(() => {
        fetchPrev(currentPage, pageSize, searchTerm, rechercher);
    }, [currentPage, pageSize, isrechercher]);

    const fetchPrev = async (page, size, searchTerm, rechercher) => {
        try {
            let url = `/materielinternant/All?page=${page}&pageSize=${size}`;
            if (rechercher) {
                url = `/materielinternant/AllSearch?page=${page}&pageSize=${size}&searchTerm=${searchTerm}`;
            }
            const response = await API.get(url);
            setData(response.data.materielinternant);
            setTotalPages(response.data.totalPages);
            setTotalElements(response.data.totalEntries);

        } catch (error) {
            console.error('Erreur lors de la récupération des materielinternant :', error.message);
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePageSizeChange = (size) => {
        setPageSize(size);
        setCurrentPage(1);
    };



    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const getRange = () => {
        const from = (currentPage - 1) * pageSize + 1;
        const to = Math.min(currentPage * pageSize, totalElements);
        return `${from} à ${to} Materiels sur ${totalElements}`;
    };
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        if (searchTerm.toString().trim().length === 1) {
            setRechercher(false);
            setisRechercher(isrechercher + 1);
        }
    };

    const dispatch = useDispatch();
    const [visibleItems, setVisibleItems] = useState([]);
    const [loadedItems, setLoadedItems] = useState([]);

    const getData = useSelector((state) => state.CartsReducer.Carts)



    const [mobile11, SetMobile11] = useState(window.innerWidth < 501)


    useEffect(() => {
        const hundlesSize = () => {
            SetMobile11(window.innerWidth < 501)
        }
        window.addEventListener('resize', hundlesSize)
        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])


    const send = (e) => {
        dispatch(ADD(e));
        toast.success(<div> Félicitation, vous avez ajouté <b>{e.name}</b> de nos matériels au panier</div>, {
            position: 'top-center',
            autoClose: 3000,
        });
    };

    useEffect(() => {
        const observerOptions = {
            threshold: 0.1,
        };

        const handleIntersection = (position) => {
            setVisibleItems((prev) => [...prev, position]);
        };


        const intersectionObserver = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const position = parseInt(entry.target.getAttribute('data-position'), 10);
                    handleIntersection(position);
                }
            });
        }, observerOptions);

        const elements = document.querySelectorAll('.blocks');

        elements.forEach((element) => {
            intersectionObserver.observe(element);
            return () => {
                intersectionObserver.unobserve(element);
            };
        });

        return () => {
            intersectionObserver.disconnect();
        };
    }, []);

    const handleImageLoad = (position) => {
        setLoadedItems((prev) => [...prev, position]);
    };
    const [mobile2, Setmonile2] = useState(window.innerWidth < 1008.9)
    return (
        <>            <div className={`bg-white relative overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
            <Link to={`/Materiel/EmpruntPersonne`} className="ml-2 text-[18px] text-blue-500 hover:text-blue-800 underline">Retour</Link>
            <h2 className="p-2 text-center text-2xl font-bold">Emprunter le matériel</h2>
            <div className='flex absolute top-10 z-50 bg-red-600 justify-end w-full items-center pr-5'>
                <Link
                    to={`/Materiel/EmpruntPersonneMateriel/Carts/${id}`}
                    class={`fixed  inline-flex items-center p-3 text-lg font-medium text-center text-white rounded-lg ${getData.length < 1 ? 'pointer-events-none bg-blue-300  ' : 'pointer-events-auto bg-blue-500  opacity-100'} `}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6 text-white"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
                        />
                    </svg>

                    <div class={`absolute inline-flex items-center justify-center w-6 h-6 text-sm font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -right-2 dark:border-gray-900 `}>
                        {getData ? getData.length : "0"}
                    </div>
                </Link>
            </div>
            <div className="flex w-full px-3    items-center justify-between flex-wrap">
                <div className={`${mobile2 ? 'mb-5' : ''}`}>
                    <div class="">
                        <div class="">
                            <div class="">
                                <div className=" flex items-center">
                                    <p className="sm:text-xl">Taille de la page :</p>
                                    <select
                                        value={pageSize}
                                        onChange={(e) => handlePageSizeChange(Number(e.target.value))}
                                        className="ml-2 p-2.5 cursor-pointer bg-white border border-gray-300 rounded"
                                    >
                                        <option value={1}>1</option>
                                        <option value={5}>5</option>
                                        <option value={10}>10</option>
                                        <option value={20}>20</option>
                                        <option value={50}>50</option>
                                        <option value={100}>100</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class={`sm:flex items-center ${mobile2 ? 'w-full ' : ''}  justify-end`}>
                    <div class="sm:flex items-center justify-end">
                        <div class="mx-2 z-10">
                            <div class="relative lg:w-64">
                                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <svg
                                        class="w-5 h-5 text-gray-500"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                            clip-rule="evenodd"
                                        ></path>
                                    </svg>
                                </div>
                                <input
                                    type="text"
                                    name="search"
                                    value={searchTerm}
                                    onChange={handleSearchChange}
                                    id="topbar-search"
                                    class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full pl-8 py-1"
                                    placeholder="Rechercher par nom ou type"
                                />

                            </div>
                        </div>
                        <button
                            className="mt-4 sm:mt-0 inline-flex items-start justify-start px-6 py-3 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg"
                            onClick={handleSearch}
                        >
                            <p className="text-lg font-medium leading-none text-white">
                                Rechercher
                            </p>
                        </button>
                    </div>
                </div>
            </div>
            <div className="">
                <div className=" p-2 flex flex-wrap justify-center items-center">
                    {data.map((element, position) => (
                        <div
                            key={position}
                            data-aos="fade-left"
                            className="blocks w-[11em] rounded-md border h-[20em] m-[10px] bg-opacity-50 p-2 flex flex-col justify-center items-center"
                            data-position={position}
                        >
                            <div className="m-1 w-full text-left font-bold">{element.nom}</div>
                            <div className="w-full p-1 text-gray-400 text-[12px]">Type: {element.type}</div>
                            <div className="w-full p-1 text-gray-400 text-[12px]">Nombre fonctionnel: {element.nombrefonctionnel}</div>
                            <button onClick={() => send(element)} className="text-white rounded w-full cursor-pointer bg-blue-600 m-1 p-1">
                                Emprunter
                            </button>
                        </div>
                    ))}

                    {data.length == 0 &&
                        <div>
                            <div className="w-full">
                                <div className="bg-transparent sm:text-[25px] w-full h-[50vh] flex justify-center items-center">
                                    Désolé, aucune correspondance  pour   <span className='font-semibold ml-4 mr-4'>{rechercher && searchTerm ? searchTerm : "les materiels"}</span>{" "} trouvée
                                </div>
                            </div>
                        </div>}
                </div>
                {data && data.length > 0 && (
                    <>

                        <div className="mt-4 flex items-center justify-center">
                            <div className="flex items-center">
                                <button
                                    disabled={currentPage === 1}
                                    onClick={handlePreviousPage}
                                    className="mx-1 px-3 py-1 rounded bg-gray-300 text-gray-700"
                                >
                                    Précédent
                                </button>
                                {Array.from({ length: totalPages }, (_, index) => index + 1).map((pageNumber) => (
                                    <button
                                        key={pageNumber}
                                        onClick={() => handlePageChange(pageNumber)}
                                        className={`mx-1 px-3 py-1 rounded ${currentPage === pageNumber ? 'bg-gray-700 text-white' : 'bg-gray-300 text-gray-700'}`}
                                    >
                                        {pageNumber}
                                    </button>
                                ))}
                                <button
                                    disabled={currentPage === totalPages}
                                    onClick={handleNextPage}
                                    className="mx-1 px-3 py-1 rounded bg-gray-300 text-gray-700"
                                >
                                    Suivant
                                </button>
                            </div>
                        </div>

                        <div className="mt-4 flex items-center justify-center">
                            <p className="text-gray-600">{getRange()}</p>
                        </div>
                    </>
                )}
            </div>
        </div>
        </>
    );
}

export default MaterielListeEncadrementEmpruntPersonne;
