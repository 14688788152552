import React from 'react'
import DetailConsultSuiveurEncadrement from './DetailConsultSuiveurEncadrement'
import SlideEncadrementNavBars from '../SlideEncadrementNavBars/SlideEncadrementNavBars'
function DetailConsultSuiveurEncadrementComponent({ getSizeMobille, getMenuMobille }) {
    return (
        <div className='flex justify-between overflow-hidden'>
            <SlideEncadrementNavBars getMenuMobille={getMenuMobille} />
            <DetailConsultSuiveurEncadrement getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default DetailConsultSuiveurEncadrementComponent
