import React, { useState } from "react";
import SideBar from "../SideBar/SideBarBibliote";
import ListeLivres from "./ListeLivres";
function ListeLivresComponent({ getSizeMobille, getMenuMobille }) {
  return (
    <>
      {" "}
      <div class="overflow-hidden flex justify-between">
        <SideBar getMenuMobille={getMenuMobille} />
        <ListeLivres getSizeMobille={getSizeMobille} />
      </div>
    </>
  );
}

export default ListeLivresComponent;
