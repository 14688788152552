/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

function AjouterReponseSecretaire({ getSizeMobille }) {

  const [mobile1, Setmonile1] = useState(window.innerWidth < 349)
  const [mobile11, SetMobile11] = useState(window.innerWidth < 501)

  useEffect(() => {
    const hundlesSize = () => {
      Setmonile1(window.innerWidth < 349)
      SetMobile11(window.innerWidth < 501)
    }

    window.addEventListener('resize', hundlesSize)

    return () => {
      window.removeEventListener('resize', hundlesSize)
    }
  }, [])





  const [nomComplet, SetnomComplet] = useState('')
  const [animationClassnomComplet, setAnimationClassnomComplet] = useState('');
  const elemetRefnomComplet = useRef(null)


  const [Matricule, SetMatricule] = useState('')
  const [animationClassMatricule, setAnimationClassMatricule] = useState('');
  const elemetRefMatricule = useRef(null)



  const [Objectif, SetObjectif] = useState('')
  const [animationClassObjectif, setAnimationClassObjectif] = useState('');
  const elemetRefObjectif = useRef(null)



  useEffect(() => {
    elemetRefObjectif.current.style.height = '7em';
    elemetRefObjectif.current.style.height = `${elemetRefObjectif.current.scrollHeight}px`;
    elemetRefObjectif.current.scrollTop = elemetRefObjectif.current.scrollHeight;
  }, [Objectif]);


  const handleSubmit = (e) => {
    e.preventDefault()
    if (nomComplet.trim() === '') {
      toast.warning('Le nom Complet  est  obligatoire', {
        autoClose: 2000
      });
      setAnimationClassnomComplet('animate__animated animate__shakeX border-2 border-red-500 ')
      setTimeout(() => {
        setAnimationClassnomComplet(' ')
      }, 3000)
      elemetRefnomComplet.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      elemetRefnomComplet.current.focus()

      return false
    } else if (Matricule.trim() == '') {
      toast.warning('Le matricule est obligatoire', {
        autoClose: 2000
      });
      setAnimationClassMatricule('animate__animated animate__shakeX border-2 border-red-500')
      setTimeout(() => {
        setAnimationClassMatricule(' ')
      }, 3000)
      elemetRefMatricule.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      elemetRefMatricule.current.focus()

      return false
    }

    else if (Objectif.trim() == '') {
      toast.warning('Laisse l\'objectif de reponse', {
        autoClose: 2000
      });
      setAnimationClassObjectif('animate__animated animate__shakeX border-2 border-red-500')
      setTimeout(() => {
        setAnimationClassObjectif(' ')
      }, 3000)
      elemetRefObjectif.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      elemetRefObjectif.current.focus()

      return false
    }
  }






  return (
    <>
      <div className={`bg-white  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>

        <Link to='/CongeAnnuel' className="ml-2 text-[18px] text-blue-500 hover:text-blue-800 underline">Retour</Link>

        <div className="p-6 ">

          <form onSubmit={handleSubmit} className="flex flex-col gap-4">
            <div class="my-5">
              <div class="w-full flex flex-row items-center justify-between">
                <p class="text-navy-700 shrink  ">
                  <p
                    class="hover:text-navy-700 text-gray-700 uppercase text-2xl font-bold "

                  >
                    Ajouter un reponse
                  </p>
                </p>

              </div>
            </div>
            <div class="w-full my-4 grid grid-cols-1 ">
              <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">
                <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col my-2">
                  <div class="-mx-3 md:flex mb-6">
                    <div class="md:w-1/2 px-3 mb-6 md:mb-0">
                      <label
                        class="block uppercase tracking-wide text-gray-700 text-lg font-bold mb-2"
                        for="grid-Title"
                      >
                        Nom et Prenom
                      </label>
                      <input
                        class={` outline-none block w-full bg-grey-lighter  border rounded py-3 px-4 mb-3 ${nomComplet.trim() === '' ? animationClassnomComplet : 'focus:border-2 focus:border-blue-700'} `}
                        id="grid-Title"
                        type="text"
                        ref={elemetRefnomComplet}
                        onInput={(e) => SetnomComplet(e.target.value)}
                        placeholder="Nom et Prenom"
                        name="nomComplet"
                      />
                    </div>
                    <div class="md:w-1/2 px-3">
                      <label
                        class="block uppercase tracking-wide text-gray-700 text-lg font-bold mb-2"
                        for="grid-last-name"
                      >
                        Matricule
                      </label>
                      <input
                        class={` outline-none block w-full bg-grey-lighter  border rounded py-3 px-4 mb-3 ${Matricule.trim() === '' ? animationClassMatricule : 'focus:border-2 focus:border-blue-700'} `}


                        ref={elemetRefMatricule}

                        onInput={(e) => SetMatricule(e.target.value)}
                        type="text"
                        placeholder="Matricule"

                      />

                    </div>
                  </div>
                  <div class={`w-full  mb-6`}>
                    <div class={`w-full`}>
                      <label
                        class="block  tracking-wide  text-lg font-bold mb-2"
                        for="grid-last-name"
                      >
                        Objectif
                      </label>
                      <textarea
                        ref={elemetRefObjectif}
                        value={Objectif}
                        onInput={(e) => SetObjectif(e.target.value)}
                        className={`appearance-none resize-none outline-none block w-full bg-grey-lighter border rounded p-2 ${Objectif.trim() === "" ? animationClassObjectif : "focus:border-2 focus:border-blue-700"
                          }`}
                        id="grid-last-name"
                        rows={1}
                        placeholder="Objectif.."
                      ></textarea>
                    </div>
                  </div>



                  <div class="mb-4 flex items-center justify-end">
                    <div class="flex-shrink-0">
                      <button
                        type="submit"
                        class="mt-4 sm:mt-0 text-lg font-medium leading-none text-white inline-flex items-start justify-start px-6 py-3 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg"
                      >
                        Repondre
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>


    </>
  );
}

export default AjouterReponseSecretaire;
