import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { InView } from 'react-intersection-observer'
import { FadeLoader } from 'react-spinners'
import { Link, useParams } from 'react-router-dom'
import { API } from '../../../Api/API'
import { format } from 'date-fns'
import { fr } from 'date-fns/locale'
import FootterCopy from '../../FootterCopy/FootterCopy'
import { encryptData } from '../../../encryptionModule'
function MaterielPrefetDisplin({ getSizeMobille }) {
  const [matData, setmatData] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [pageSize, setPageSize] = useState(20)
  const [totalElements, setTotalElements] = useState(0)

  const [etat, setEtat] = useState(1)
  const handleEtatChange = (etat) => {
    setEtat(etat)
  }
  const [rechercher, setRechercher] = useState(false)
  const [isrechercher, setisRechercher] = useState(0)
  function handleSearch() {
    setRechercher(true)
    setisRechercher(isrechercher + 1)
  }

  useEffect(() => {
    fetchPrev(currentPage, pageSize, searchTerm, rechercher)
  }, [currentPage, pageSize, isrechercher])

  const fetchPrev = async (page, size, searchTerm, rechercher) => {
    try {
      let url = `/materielinternant/All?page=${page}&pageSize=${size}`
      if (rechercher) {
        url = `/materielinternant/AllSearch?page=${page}&pageSize=${size}&searchTerm=${searchTerm}`
      }
      const response = await API.get(url)
      setmatData(response.data.materielinternant)
      setTotalPages(response.data.totalPages)
      setTotalElements(response.data.totalEntries)
    } catch (error) {
      console.error(
        'Erreur lors de la récupération des materielinternant :',
        error.message,
      )
    }
  }

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const handlePageSizeChange = (size) => {
    setPageSize(size)
    setCurrentPage(1)
  }

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1)
    }
  }

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  const formatDate = (date) => {
    return format(new Date(date), "'Le 'd, MMMM yyyy", { locale: fr })
  }

  const getRange = () => {
    const from = (currentPage - 1) * pageSize + 1
    const to = Math.min(currentPage * pageSize, totalElements)
    return `${from} à ${to} Materiels sur ${totalElements}`
  }
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value)
    if (searchTerm.toString().trim().length === 1) {
      setRechercher(false)
      setisRechercher(isrechercher + 1)
    }
  }

  const [mobile1, Setmonile1] = useState(window.innerWidth < 990)
  const [mobile2, Setmonile2] = useState(window.innerWidth < 520)

  useEffect(() => {
    const hundlesSize = () => {
      Setmonile1(window.innerWidth < 990)
      Setmonile2(window.innerWidth < 520)
    }
    window.addEventListener('resize', hundlesSize)

    return () => {
      window.removeEventListener('resize', hundlesSize)
    }
  }, [])

  const [mobile11, SetMobile11] = useState(window.innerWidth < 501)

  useEffect(() => {
    const hundlesSize = () => {
      SetMobile11(window.innerWidth < 501)
    }
    window.addEventListener('resize', hundlesSize)
    return () => {
      window.removeEventListener('resize', hundlesSize)
    }
  }, [])

  return (
    <>
      <div
        className={`bg-white  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'
          }   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}
      >
        <div className="p-6 min-h-[90vh]">
          <div className="flex w-full px-3    items-center justify-between flex-wrap">
            <div className={`${mobile2 ? 'mb-5' : ''}`}>
              <div class="">
                <div class="">
                  <div class="">
                    <div className=" flex items-center">
                      <p className="sm:text-xl">Taille de la page :</p>
                      <select
                        value={pageSize}
                        onChange={(e) =>
                          handlePageSizeChange(Number(e.target.value))
                        }
                        className="ml-2 p-2.5 cursor-pointer bg-white border border-gray-300 rounded"
                      >
                        <option value={1}>1</option>
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class={`sm:flex items-center ${mobile2 ? 'w-full ' : ''
                }  justify-end`}
            >
              <div class="sm:flex items-center justify-end">
                <div class="mx-2 z-10">
                  <div class="relative lg:w-64">
                    <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <svg
                        class="w-5 h-5 text-gray-500"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </div>
                    <input
                      type="text"
                      name="search"
                      value={searchTerm}
                      onChange={handleSearchChange}
                      id="topbar-search"
                      class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full pl-8 py-1"
                      placeholder="Rechercher par nom ou type"
                    />
                  </div>
                </div>
                <button
                  className="mt-4 sm:mt-0 inline-flex items-start justify-start px-6 py-3 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg"
                  onClick={handleSearch}
                >
                  <p className="text-lg font-medium leading-none text-white">
                    Rechercher
                  </p>
                </button>
              </div>
            </div>
          </div>
          <div class="w-full my-4 ">
            <div class="bg-white shadow rounded-lg p-4 ">
              <div
                class={`b-4 flex items-center justify-between ${mobile2 ? 'flex-col' : ''
                  }`}
              >
                <div>
                  <h3 class="sm:text-xl font-bold text-gray-900 mb-2">
                    Listes des matériel
                  </h3>
                </div>
                <Link
                  to="/Materiel/Ajouter"
                  class=" font-bold text-gray-900  bg-blue-200 p-2 rounded-full cursor-pointer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-plus-circle-fill w-6 text-blue-700 h-6"
                    viewBox="0 0 16 16"
                  >
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z" />
                  </svg>
                </Link>
              </div>

              <div class="flex flex-col mt-8 ">
                <div class="overflow-x-auto rounded-lg">
                  <div
                    class={`align-middle inline-block  ${mobile1 ? 'min-w-[70em]' : 'min-w-full'
                      }`}
                  >
                    <div class="shadow overflow-hidden sm:rounded-lg">
                      <table class="min-w-full divide-y  divide-gray-200">
                        <thead class="bg-gray-50">
                          <tr>
                            <th

                              scope="col"
                              class=" py-4 px-2 font-semibold relative border-l text-left cursor-pointer text-lg first-letter:uppercase  text-gray-500  tracking-wider"
                            >
                              Nom du matériel

                            </th>
                            <th

                              scope="col"
                              class=" py-4 px-2 font-semibold relative border-l text-left cursor-pointer text-lg first-letter:uppercase  text-gray-500  tracking-wider"
                            >
                              Type

                            </th>
                            <th

                              scope="col"
                              class=" py-4  px-2 border-l text-left cursor-pointer relative text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                            >
                              Total

                            </th>
                            <th

                              scope="col"
                              class=" py-4  px-2 border-l text-left cursor-pointer relative text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                            >
                              Fonctionnel

                            </th>

                            <th
                              scope="col"
                              class=" w-[5em] overflow-hidden py-4 px-1 border-l text-center  relative text-lg first-letter:uppercase font-semibold text-gray-500  tracking-wider"
                            >
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody class="bg-white">
                          {matData.map((md, index) => {
                            return (
                              <tr key={index}>
                                <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                  {md.nom}
                                </td>

                                <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase  text-gray-500">
                                  {md.type}
                                </td>
                                <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase  text-gray-500">
                                  {md.nombredispo}
                                </td>
                                <td class="border-b border-gray-200  px-3  border-l whitespace-nowrap text-lg first-letter:uppercase  text-gray-500">
                                  {md.nombrefonctionnel}
                                </td>
                                <td class="border-b  relative  border-gray-200 overflow-hidden flex  pt-2 px-3  border-l border-r whitespace-nowrap text-lg first-letter:uppercase font-normal text-gray-500">
                                  <Link
                                    to={`/Materiel/detail/${encryptData((md.id).toString())}`}
                                    class="my-2 cursor-pointer flex items-center justify-center px-2 py-1 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg"
                                  >
                                    <p class="first-letter:uppercase text-white font-semibold ">
                                      Detail
                                    </p>
                                  </Link>
                                </td>
                              </tr>
                            )
                          })}
                          {matData.length == 0 && (
                            <tr>
                              <td
                                colSpan={7}
                                className="h-20 w-full text-center text-2xl text-gray-400"
                              >
                                {' '}
                                Aucun élément correspondant à{' '}
                                <span className="font-bold">
                                  {searchTerm}
                                </span>{' '}
                              </td>
                            </tr>
                          )}
                        </tbody>
                        {matData && matData.length > 0 && (
                          <>
                            <div className="mt-4 flex items-center justify-center">
                              <div className="flex items-center">
                                <button
                                  disabled={currentPage === 1}
                                  onClick={handlePreviousPage}
                                  className="mx-1 px-3 py-1 rounded bg-gray-300 text-gray-700"
                                >
                                  Précédent
                                </button>
                                {Array.from(
                                  { length: totalPages },
                                  (_, index) => index + 1,
                                ).map((pageNumber) => (
                                  <button
                                    key={pageNumber}
                                    onClick={() => handlePageChange(pageNumber)}
                                    className={`mx-1 px-3 py-1 rounded ${currentPage === pageNumber
                                      ? 'bg-gray-700 text-white'
                                      : 'bg-gray-300 text-gray-700'
                                      }`}
                                  >
                                    {pageNumber}
                                  </button>
                                ))}
                                <button
                                  disabled={currentPage === totalPages}
                                  onClick={handleNextPage}
                                  className="mx-1 px-3 py-1 rounded bg-gray-300 text-gray-700"
                                >
                                  Suivant
                                </button>
                              </div>
                            </div>

                            <div className="mt-4 flex items-center justify-center">
                              <p className="text-gray-600">{getRange()}</p>
                            </div>
                          </>
                        )}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FootterCopy />
      </div>
    </>
  )
}

export default MaterielPrefetDisplin
