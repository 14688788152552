import React, { useEffect, useRef, useState } from "react";
import FootterCopy from "../../FootterCopy/FootterCopy";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select"
import { FadeLoader } from 'react-spinners'
import { API } from "../../../Api/API";

function AddLatrinePreftDisplin({ getSizeMobille }) {
    const [mobile11, SetMobile11] = useState(window.innerWidth < 501)
    useEffect(() => {
        const hundlesSize = () => {
            SetMobile11(window.innerWidth < 501)
        }
        window.addEventListener('resize', hundlesSize)
        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])

    const [mobile, SetMobile] = useState(window.innerWidth <= 640)
    const [eledemmade, GeteleDemmande] = useState([])
    const [boutLoading, setboutLoading] = useState(false)

    useEffect(() => {
        const hundleSize = () => {
            SetMobile(window.innerWidth <= 640)
        }
        window.addEventListener('resize', hundleSize)

        return () => {
            window.removeEventListener('resize', hundleSize)
        }
    }, [])

    const OptionRéservé_aux = [
        { value: '', label: 'Lundi' },
        { value: 'garçon', label: 'garçon' },
        { value: 'fille', label: 'fille' },
        { value: 'autorites', label: 'autorites' }
    ]


    const hiddenOptionRéservé_aux = OptionRéservé_aux.slice(1);
    const customStyles = {
        control: (provided) => ({
            ...provided,
            height: mobile ? 25 : 50,
            backgroundColor: "white",
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? "blue" : state.isFocused ? "lightblue" : "white",
            color: state.isSelected ? "white" : "black",
        }),
    };

    const [blocs, setBlocs] = useState([
        { nombre_latrine: '', Réservé_aux: '' }
    ]);
    const addBloc = () => {
        setBlocs([...blocs, { nombre_latrine: '', Réservé_aux: '' }]);
    };
    const removeBloc = (index) => {
        const newBlocs = [...blocs];
        newBlocs.splice(index, 1);
        setBlocs(newBlocs);
    };
    const inputRefs = useRef([]);
    if (inputRefs.current.length !== blocs.length) {
        inputRefs.current = Array(blocs.length).fill({}).map((_, index) => ({
            nomHome: React.createRef(null),
            nombre_latrine: React.createRef(null),
            Réservé_aux: React.createRef(null)
        }));
    }

    const handleInputChange = (index, field, value) => {
        const newBlocs = [...blocs];
        newBlocs[index][field] = value;
        setBlocs(newBlocs);
        if (value && inputRefs.current[index][field].current) {
            inputRefs.current[index][field].current.classList.remove('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500');
        }
    };


    const animateAndScrollToRef = (ref) => {
        if (ref && ref.current) {
            ref.current.classList.add('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500', 'outline-none');
            setTimeout(() => {
                ref.current.classList.remove('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500', 'outline-none');
            }, 5000);
            ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            ref.current.focus();
        }
    };


    const validateBloc = (bloc, index) => {
        const requiredFields = ['nombre_latrine', 'Réservé_aux'];

        //Validation pour les champs vide
        for (const field of requiredFields) {
            if (!bloc[field]) {
                toast.warning(
                    <div>
                        <>Le <strong>{field === 'nombre_latrine' ? 'nombre de latrine' : null}</strong> est obligatoire à la bloc {index + 1}.</>

                        {field === 'Réservé_aux' && (
                            <span>{field === 'Réservé_aux' ? 'Ce latrine est réservé à qui 😒' : null}</span>
                        )}
                    </div>
                );
                if (inputRefs.current[index][field].current) {
                    inputRefs.current[index][field].current.classList.add('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500', 'outline-none');
                    setTimeout(() => {
                        inputRefs.current[index][field].current.classList.remove('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500', 'outline-none');
                    }, 3000);
                    inputRefs.current[index][field].current.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    inputRefs.current[index][field].current.focus();
                    return false;
                }
            }
        }
        const isSamenomHome = blocs.some((b, i) => i !== index && b.Réservé_aux.value === bloc.Réservé_aux.value)
        if (isSamenomHome) {
            const duplicateBlockIndex = blocs.findIndex((b, i) => i !== index && b.Réservé_aux.value === bloc.Réservé_aux.value);
            const duplicateBlockNumber = toRoman(duplicateBlockIndex + 1);
            toast.warning(
                <div>
                    Vous avez  utilisé la même reservation aux  <span className="font-bold">{bloc.Réservé_aux.value}</span> à la block <span className="font-bold">{toRoman(index + 1)}</span> et à la block <span className="font-bold">{duplicateBlockNumber}</span>. Evitez-le
                </div>
            );
            animateAndScrollToRef(inputRefs.current[index].Réservé_aux);
            return false;
        }
        return true;
    };


    const navigate = useNavigate()
    function toRoman(num) {
        const romanNumerals = [
            "I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX", "X",
            "XI", "XII", "XIII", "XIV", "XV", "XVI", "XVII", "XVIII", "XIX", "XX",
            "XXI", "XXII", "XXIII", "XXIV", "XXV", "XXVI", "XXVII", "XXVIII", "XXIX", "XXX"
        ];
        return romanNumerals[num - 1] || num.toString();
    }


    const Datas = blocs.map((bloc) => ({
        nombre_latrine: bloc.nombre_latrine,
        Réservé_aux: bloc.Réservé_aux.value
    }))


    const handleSubmit = (e) => {
        e.preventDefault();
        for (let index = 0; index < blocs.length; index++) {
            if (!validateBloc(blocs[index], index)) {
                return;
            }
        }


        let result = {
            garconstudent: 0,
            fillestudent: 0,
            personnelle: 0
        };

        Datas.forEach(item => {
            const latrineCount = parseInt(item.nombre_latrine);
            switch (item.Réservé_aux.toLowerCase()) {
                case 'garçon':
                    result.garconstudent += latrineCount;
                    break;
                case 'fille':
                    result.fillestudent += latrineCount;
                    break;
                case 'autorites':
                    result.personnelle += latrineCount;
                    break;
                default:
                    break;
            }
        });

        console.log(result);

        API.post('/LT/add', result)
            .then(response => {
                toast.success(response.data);
                navigate('/Latrines')
            })
            .catch(error => {
                if (error.response.data) {
                    toast.warn(error.response.data.error);
                    return
                }
                console.error(error.message);
            });

    };

    return (

        <div className={`bg-white  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
            <Link to='/Latrines' className="ml-2 text-[18px] text-blue-500 hover:text-blue-800 underline">Retour</Link>
            <div className="sm:p-4 p-2 min-h-screen">
                <div class="my-5">
                    <div class="w-full flex flex-row items-center justify-between">
                        <p class="text-navy-700   ">
                            <a
                                class="hover:text-navy-700 text-2xl font-bold  "
                            >
                                Enregistrement la latrine
                            </a>
                        </p>

                    </div>
                </div>
                <div className="w-full my-4 grid grid-cols-1">
                    <div className="w-full my-4 grid grid-cols-1">
                        <form onSubmit={handleSubmit} className="flex flex-col items-center">
                            <div className="rounded-lg p-2 border border-blue-500 md:w-[40em] w-[95%] sm:p-4">
                                {blocs.map((bloc, index) => (
                                    <div key={index} className="justify_center rounded-xl w-full  p-1 sm:p-2 mb-10  border-[1px] flex flex-col">
                                        <div className="w-full">
                                            <div className="h-max w-full">
                                                <div className="px-3  w-full">
                                                    <div className="w-full flex items_center justify-between">
                                                        <label className="block  text-xs mt-5 font-serif text-gray-700 first_letter:uppercase tracking_wide text-grey-darker sm:text-lg  mb-1">
                                                            {toRoman(index + 1)}. Latrine
                                                        </label>
                                                        {index > 0 && (
                                                            <button
                                                                montant="button"
                                                                className="text-red-500 px-1 max-sm:text-xs py-2"
                                                                onClick={() => removeBloc(index)}
                                                            >
                                                                Supprimer
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mb-2">
                                            <div className="rounded-xl p-2 w-full">
                                                <div className="flex flex-col">

                                                    <div className="rounded-lg w-full">
                                                        <div className="w-full mb-5">
                                                            <label
                                                                className="block tracking-wide text-grey-darker text-lg mb-2"
                                                            >
                                                                Nombres des latrines
                                                            </label>
                                                            <input
                                                                ref={inputRefs.current[index].nombre_latrine}
                                                                className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-red rounded py-3 px-4 mb-3"
                                                                value={bloc.nombre_latrine}
                                                                onChange={(option) => handleInputChange(index, "nombre_latrine", option.target.value)}
                                                                placeholder="Nombres des latrines..."

                                                            />
                                                        </div>
                                                    </div>


                                                    <div className="rounded-lg w-full">
                                                        <div class="w-full mb-5">
                                                            <label class="block  tracking-wide text-gray-700 text-xs sm:text-lg  mb-1">
                                                                Réservé aux
                                                            </label>
                                                            <div ref={inputRefs.current[index].Réservé_aux} className={` rounded `}>
                                                                <Select
                                                                    value={bloc.Réservé_aux}
                                                                    options={hiddenOptionRéservé_aux}
                                                                    styles={customStyles}
                                                                    onChange={(option1) => handleInputChange(index, "Réservé_aux", option1)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <div className="w-full flex justify-end">
                                    <button montant="button" className="bg-blue-500 hover-bg-blue-700 text-white py-1 px-2  max-sm:text-xs sm:py-2 sm:px-4 rounded" onClick={addBloc}>
                                        Autre  Latrines
                                    </button>
                                </div>
                            </div>
                            <div className=" flex justify-end  md:w-[40em] w-[95%]">
                                {boutLoading ? (
                                    <>
                                        <label disabled className="cursor-no-drop w-max relative  mt-3 flex justify-center  items-center   bg-green-950    p-2 rounded  text-gray-400">
                                            <input type="submit" id="send" value='Soumettre' className='pointer-events-none' />
                                            <i class="bi bi-send ml-2  pointer-events-none "></i>
                                            <div disabled className='absolute pointer-events-none  bg-transparent pt-4  pl-4  w-full h-full flex justify-center items-center z-50'>
                                                <FadeLoader
                                                    color="rgb(255, 255, 255)"
                                                    height={10}
                                                    margin={-9}
                                                    radius={100}
                                                    speedMultiplier={1}
                                                    width={1}
                                                />
                                            </div>
                                        </label>
                                    </>
                                ) : (<>
                                    <label for="send" className=" mt-3 cursor-pointer w-max  flex justify-end  bg-green-600   p-2 rounded  text-white">
                                        <input type="submit" id="send" value='Soumettre' className='cursor-pointer'></input>
                                        <i class="bi bi-send ml-2 "></i>
                                    </label>
                                </>)}

                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <FootterCopy />
        </div>

    );
}


export default AddLatrinePreftDisplin;










