import React from 'react'
import AddDemandePrefetDisplin from './AddDemandePrefetDisplin'
import SideBarsPrefetDisplin from '../SideBarsPrefetDisplin/SideBarsPrefetDisplin'
function AddDemandePrefetDisplinComponent({ getSizeMobille, getMenuMobille }) {
    return (
        <div className='flex justify-between overflow-hidden bg-white'>
            <SideBarsPrefetDisplin getMenuMobille={getMenuMobille} />
            <AddDemandePrefetDisplin getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default AddDemandePrefetDisplinComponent
