/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable eqeqeq */
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FadeLoader } from 'react-spinners'
import FootterCopy from "../../FootterCopy/FootterCopy";
import { toast } from "react-toastify";
import Select from "react-select";
import { MaterielPrefet } from "../../../Data/Data";
import { API } from "../../../Api/API";
import { decryptData } from "../../../encryptionModule";
function ModifierHorairePrefetDisplin({ getSizeMobille }) {


    const { jour, id } = useParams()
    const { data, setData } = useState({})
    const idHI = decryptData(id)
    const [mobile11, SetMobile11] = useState(window.innerWidth < 501);
    const navigate = useNavigate()

    useEffect(() => {
        const hundlesSize = () => {
            SetMobile11(window.innerWidth < 501);
        };
        window.addEventListener('resize', hundlesSize);
        return () => {
            window.removeEventListener('resize', hundlesSize);
        };
    }, []);






    useEffect(() => {
        const hundlesSize = () => {
            SetMobile11(window.innerWidth < 501)
        }
        window.addEventListener('resize', hundlesSize)
        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])

    const [Loading, setforMotivemboutLoading] = useState(false);
    const [plage, setplage] = useState("");
    const [animationClassplage, setAnimationClassplage] = useState("");
    const plageRef = useRef(null);

    const [textarea, setTextarea] = useState("");
    const [animationClassTextarea, setAnimationClassTextarea] = useState("");
    const textaresRef = useRef(null);

    useEffect(() => {
        API.get(`/HI/hinternecontentById/${idHI}`)
            .then((res) => {
                setplage(res.data.time)
                setTextarea(res.data.activite)
            })
            .catch((err) => {
                // Handle error
                console.log(err.message);
            });
    }, [id])

    const handleSubmit = (e) => {
        e.preventDefault();
        if (plage.trim() === "") {
            toast.warning("Ce nombres est obligatoire", {
                autoClose: 2000,
            });
            setAnimationClassplage(
                "animate__animated animate__shakeX placeholder-shown:border-red-500"
            );
            setTimeout(() => {
                setAnimationClassplage("");
            }, 3000);
            plageRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            plageRef.current.focus();
            return false
        }
        if (textarea.trim() === "") {
            toast.warning("Le motif est nécessaire", {
                autoClose: 2000,
            });
            setAnimationClassTextarea(
                "animate__animated animate__shakeX placeholder-shown:border-red-500"
            );
            setTimeout(() => {
                setAnimationClassTextarea("");
            }, 3000);
            textaresRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            textaresRef.current.focus();
            return false
        }

        API.put(`/HI/hinternecontentById/${idHI}`, { time: plage, activite: textarea })
            .then(response => {
                toast.success(response.data)
                navigate('/Horaire')
            })
            .catch(error => {
                console.error('Erreur lors du chargement des données :', error);
            });
    }


    return (
        <>
            <div className={`bg-white  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
                <Link to='/Horaire' className="ml-2 text-[18px] text-blue-500 hover:text-blue-800 underline">Retour</Link>
                <div className="p-6 min-h-[90vh]">
                    <div class="w-full my-4 ">
                        <div class="bg-white border relative overflow-hidden rounded-lg p-4 ">
                            <div class={`b-4 flex items-center justify-between `}>
                                <div>
                                    <h3 class="sm:text-xl font-bold text-gray-900 mb-2">
                                        Modifier l'horaire
                                    </h3>
                                </div>
                            </div>
                            <div className={`w-full  flex justify-center p-2 h-max   z-[900] `}>
                                <form onSubmit={handleSubmit}>
                                    <div className="relative w-full flex-col border border-blue-400 sm:p-4 rounded-lg items-center flex justify-center ">
                                        <div className="rounded-lg w-full ">
                                            <div class="w-full mb-5">
                                                <label class="block  tracking-wide text-gray-700 text-xs sm:text-lg  mb-1 ">
                                                    {jour}
                                                </label>

                                            </div>
                                        </div>

                                        <div className="mb-6 w-max bg-white p-2 border border-blue-600  pt-5 rounded-xl ">
                                            <div className="transition-all">
                                                <div className="relative  w-full flex-col flex  mr-2">
                                                    <label className="text-gray-600 font-semibold mb-2">
                                                        Plage d'horaire
                                                    </label>
                                                    <input
                                                        ref={plageRef}
                                                        value={plage}
                                                        onInput={(e) => setplage(e.target.value)}
                                                        placeholder="plage"
                                                        className={`py-3 px-2 border-blue-300 outline-none rounded-md border-2 focus:border-blue-700 ${animationClassplage}`}
                                                    />

                                                </div>

                                                <div className="relative mt-4 text-gray-700 flex flex-col h-full w-full">
                                                    <label
                                                    >
                                                        Activite
                                                    </label>
                                                    <textarea
                                                        ref={textaresRef}
                                                        value={textarea}
                                                        onChange={(e) => {
                                                            setTextarea(e.target.value);
                                                        }}
                                                        placeholder="Activite "
                                                        className={`min-h-[12em] resize-none text-black border-blue-300 overflow-y-auto min-w-[25em] py-2 px-2 outline-none rounded-md border-2 focus:border-blue-700 ${animationClassTextarea}`}
                                                    />
                                                </div>

                                            </div>
                                        </div>






                                        <div className="flex justify-end w-full  items-center mt-[20px]">
                                            {Loading ? (
                                                <>
                                                    <div className="flex justify-end items-center  relative pointer-events-none opacity-80 bg-gray-900 rounded  cursor-pointer px-5 py-1 text-gray-600">
                                                        <div className='absolute bg-transparent   pt-5 pl-10  w-full h-full flex justify-center items-center z-50'>
                                                            <FadeLoader
                                                                color="rgb(255, 255, 255)"
                                                                height={10}
                                                                margin={-9}
                                                                radius={100}
                                                                speedMultiplier={1}
                                                                width={1}
                                                            /></div>

                                                        <input type="submit" id="send" value="Modifier" class=" transition-all "></input>
                                                        <i class="bi bi-send ml-2 "></i>
                                                    </div>
                                                </>
                                            ) : (
                                                <>

                                                    <label htmlFor="send" className="flex bg-blue-600 rounded cursor-pointer px-2 py-1 text-white">
                                                        <input type="submit" id="send" value="Modifier" className="cursor-pointer" />
                                                    </label>
                                                </>
                                            )}

                                        </div>
                                    </div>

                                </form>
                            </div>

                        </div>
                    </div>
                </div>
                <FootterCopy />
            </div>

        </>
    );
}

export default ModifierHorairePrefetDisplin;





