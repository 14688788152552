/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
function SideBarBibliote({ getMenuMobille }) {
  const { pathname } = useLocation();
  let Accueil = /^\/Accueil.*$/;
  let Livres = /^\/Livres.*$/;
  let Emprunter = /^\/Emprunter.*$/;
  let Retours = /^\/Retour.*$/;
  let Stats = /^\/Stats.*$/;
  let DemandeReponse = /^\/Demande.*$/;
  let Conge = /^\/Conge.*$/;
  let Prevision = /^\/Prevision.*$/;
  let Depenses = /^\/Depenses.*$/;
  let Resumes = /^\/Resumes.*$/;


  const [mobile, SetMobile] = useState(window.innerWidth < 1292)
  const [mobile11, SetMobile11] = useState(window.innerWidth < 501)
  useEffect(() => {
    const hundleSize = () => {
      SetMobile(window.innerWidth < 1292)
      SetMobile11(window.innerWidth < 501)
    }

    window.addEventListener('resize', hundleSize)

    return () => window.removeEventListener('resize', hundleSize)
  }, [])


  return (
    <>
      <div className={`bg-white   border-r-2 border-blue-800 w-[10vw]  overflow-y-auto overflow-x-hidden min-w-[13em] ${mobile ? 'fixed  z-[100]' : ''} ${getMenuMobille ? 'left-0' : '-left-full'} transition-all duration-300 ${mobile11 ? 'top-[10vh] h-[90vh]' : 'top-[15vh] h-[85vh]'} `}>
        <div className="p-1 mb-5 border-b border-white">
          <div class="flex-1 flex flex-col bg-white pt-5 pb-4 overflow-y-auto">
            <div class="flex-1 px-1  divide-y space-y-1">
              <ul class="space-y-2 pb-2">
                {Accueil.test(pathname) || pathname === "/" ? (
                  <Link to="/">
                    <li>
                      <div class="text-xl font-normal rounded bg-blue-600 text-white  flex items-center p-2 group">
                        <svg
                          class="w-6 h-6 text-white transition duration-75"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path>
                          <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path>
                        </svg>

                        <span class="ml-3 flex-1 whitespace-nowrap">
                          Accueil
                        </span>

                      </div>
                    </li>
                  </Link>
                ) : (
                  <Link to="/">
                    <li>
                      <div class="text-xl  font-normal rounded hover:text-gray-400 flex items-center p-2 group">
                        <svg
                          class="w-6 h-6  transition duration-75"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path>
                          <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path>
                        </svg>

                        <span class="ml-3 flex-1 whitespace-nowrap">
                          Accueil
                        </span>

                      </div>
                    </li>
                  </Link>
                )}

                {
                  pathname == '/Presence' ? (
                    <Link to='/Presence'>
                      <div className="flex w-full  rounded bg-blue-600 text-white h-12 px-2 justify-left items-center">
                        <div>
                          <svg
                            class="w-6 h-6  transition duration-75"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path>
                            <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path>
                          </svg></div>
                        <div className="ml-3 flex-1 whitespace-nowrap font-normal text-xl">Presence</div>

                        <span class=" ml-3 text-lg font-medium inline-flex items-center justify-center px-2 rounded-full">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-4 h-4 hidden "

                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M8.25 4.5l7.5 7.5-7.5 7.5"
                            />
                          </svg>
                        </span>
                      </div>
                    </Link>
                  ) : (
                    <Link to='/Presence'>
                      <div className="flex w-full  rounded hover:text-gray-400  transition-all h-12 px-2 justify-left items-center">
                        <div>
                          <svg
                            class="w-6 h-6  transition duration-75"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path>
                            <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path>
                          </svg></div>
                        <div className="ml-3 flex-1 whitespace-nowrap font-normal text-xl">Presence</div>

                        <span class=" ml-3 text-lg font-medium inline-flex items-center justify-center px-2 rounded-full">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-4 h-4 hidden "

                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M8.25 4.5l7.5 7.5-7.5 7.5"
                            />
                          </svg>
                        </span>
                      </div>
                    </Link>
                  )
                }
                {Livres.test(pathname) ? (
                  <Link to="/Livres">
                    <li>
                      <div
                        target="_blank"
                        class="text-xl font-normal rounded bg-blue-500 text-white  flex items-center p-2 group"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6 text-white flex-shrink-0 transition duration-75"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25"
                          />
                        </svg>

                        <span class="ml-3 flex-1 whitespace-nowrap">
                          Livres
                        </span>

                      </div>
                    </li>
                  </Link>
                ) : (
                  <Link to="/Livres">
                    <li>
                      <div
                        target="_blank"
                        class="text-xl  font-normal rounded hover:text-gray-400 flex items-center p-2 group"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6  flex-shrink-0  transition duration-75"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25"
                          />
                        </svg>

                        <span class="ml-3 flex-1 whitespace-nowrap">
                          Livres
                        </span>

                      </div>
                    </li>
                  </Link>
                )}

                {Emprunter.test(pathname) ? (
                  <Link to="/Emprunter">
                    <li>
                      <div class="text-xl font-normal rounded bg-blue-500 text-white  flex items-center p-2 group">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6 text-white flex-shrink-0 transition duration-75"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m6.75 12l-3-3m0 0l-3 3m3-3v6m-1.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                          />
                        </svg>

                        <span class="ml-3 flex-1 whitespace-nowrap">
                          Emprunter
                        </span>

                      </div>
                    </li>
                  </Link>
                ) : (
                  <Link to="/Emprunter">
                    <li>
                      <div class="text-xl  font-normal rounded hover:text-gray-400 flex items-center p-2 group">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6  flex-shrink-0  transition duration-75"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m6.75 12l-3-3m0 0l-3 3m3-3v6m-1.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                          />
                        </svg>

                        <span class="ml-3 flex-1 whitespace-nowrap">
                          Emprunter
                        </span>

                      </div>
                    </li>
                  </Link>
                )}

                {Retours.test(pathname) ? (
                  <Link to="/Retour">
                    <li>
                      <div class="text-xl font-normal rounded bg-blue-500 text-white  flex items-center p-2 group">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-return-left w-6 h-6" viewBox="0 0 16 16">
                          <path fill-rule="evenodd" d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z" />
                        </svg>

                        <span class="ml-3 flex-1 whitespace-nowrap">
                          Retour
                        </span>

                      </div>
                    </li>
                  </Link>
                ) : (
                  <Link to="/Retour">
                    <li>
                      <div class="text-xl  font-normal rounded hover:text-gray-400 flex items-center p-2 group">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-return-left h-6 w-6" viewBox="0 0 16 16">
                          <path fill-rule="evenodd" d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z" />
                        </svg>

                        <span class="ml-3 flex-1 whitespace-nowrap">
                          Retours
                        </span>

                      </div>
                    </li>
                  </Link>
                )}
                {
                  Conge.test(pathname) ? (
                    <Link to='/Conge'>
                      <div className="flex w-full  rounded bg-blue-600 text-white h-12 px-2 justify-left items-center">
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 h-6 te flex-shrink-0  transition duration-75"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25M9 16.5v.75m3-3v3M15 12v5.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                            />
                          </svg></div>
                        <div className="ml-3 flex-1 whitespace-nowrap font-normal text-xl"> Conge</div>

                        <span class=" ml-3 text-lg font-medium inline-flex items-center justify-center px-2 rounded-full">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-4 h-4 hidden "

                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M8.25 4.5l7.5 7.5-7.5 7.5"
                            />
                          </svg>
                        </span>
                      </div>
                    </Link>
                  ) : (
                    <Link to='/Conge'>
                      <div className="flex w-full  rounded hover:text-gray-400  transition-all h-12 px-2 justify-left items-center">
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 h-6 flex-shrink-0  transition duration-75"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25M9 16.5v.75m3-3v3M15 12v5.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                            />
                          </svg></div>
                        <div className="ml-3 flex-1 whitespace-nowrap font-normal text-xl">Conge</div>

                        <span class=" ml-3 text-lg font-medium inline-flex items-center justify-center px-2 rounded-full">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-4 h-4 hidden "
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M8.25 4.5l7.5 7.5-7.5 7.5"
                            />
                          </svg>
                        </span>
                      </div>
                    </Link>
                  )
                }

                {
                  DemandeReponse.test(pathname) ? (
                    <Link to='/Demande'>
                      <div className="flex w-full  rounded bg-blue-600 text-white h-12 px-2 justify-left items-center">
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 h-6 text-white flex-shrink-0  transition duration-75"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z"
                            />
                          </svg></div>
                        <div className="ml-3 flex-1 whitespace-nowrap font-normal text-xl">Demand & Expli</div>

                        <span class=" ml-3 text-lg font-medium inline-flex items-center justify-center px-2 rounded-full">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-4 h-4 hidden "

                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M8.25 4.5l7.5 7.5-7.5 7.5"
                            />
                          </svg>
                        </span>
                      </div>
                    </Link>
                  ) : (
                    <Link to='/Demande'>
                      <div className="flex w-full  rounded hover:text-gray-400  transition-all h-12 px-2 justify-left items-center">
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 h-6 flex-shrink-0  transition duration-75"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z"
                            />
                          </svg></div>
                        <div className="ml-3 flex-1 whitespace-nowrap font-normal text-xl"> Demand & Expli</div>

                        <span class=" ml-3 text-lg font-medium inline-flex items-center justify-center px-2 rounded-full">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-4 h-4 hidden "

                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M8.25 4.5l7.5 7.5-7.5 7.5"
                            />
                          </svg>
                        </span>
                      </div>
                    </Link>
                  )
                }
                {Stats.test(pathname) ? (
                  <Link to="/Stats">
                    <li>
                      <div class="text-xl font-normal rounded bg-blue-500 text-white  flex items-center p-2 group">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6 text-white flex-shrink-0  transition duration-75"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25M9 16.5v.75m3-3v3M15 12v5.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                          />
                        </svg>

                        <span class="ml-3 flex-1 whitespace-nowrap">
                          Rapports
                        </span>

                      </div>
                    </li>
                  </Link>
                ) : (
                  <Link to="/Stats">
                    <li>
                      <a
                        href="#e-commerce/products/"
                        class="text-xl  font-normal rounded hover:text-gray-400 flex items-center p-2 group"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6  flex-shrink-0  transition duration-75"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25M9 16.5v.75m3-3v3M15 12v5.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                          />
                        </svg>

                        <span class="ml-3 flex-1 whitespace-nowrap">
                          Rapports
                        </span>

                      </a>
                    </li>
                  </Link>
                )}

                {Prevision.test(pathname) ? (
                  <Link to="/Prevision">
                    <li>
                      <div class="text-xl font-normal rounded bg-blue-500 text-white  flex items-center p-2 group">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6 text-white flex-shrink-0  transition duration-75"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z"
                          />
                        </svg>

                        <span class="ml-3 flex-1 whitespace-nowrap">
                          Prevision
                        </span>

                      </div>
                    </li>
                  </Link>
                ) : (
                  <Link to="/Prevision">
                    <li>
                      <div class="text-xl  font-normal rounded hover:text-gray-400 flex items-center p-2 group">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6  flex-shrink-0  transition duration-75"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z"
                          />
                        </svg>

                        <span class="ml-3 flex-1 whitespace-nowrap">
                          Prevision
                        </span>

                      </div>
                    </li>
                  </Link>
                )}

                {Depenses.test(pathname) ? (
                  <Link to="/Depenses">
                    <li>
                      <div class="text-xl font-normal rounded bg-blue-500 text-white  flex items-center p-2 group">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="w-6 h-6 text-white flex-shrink-0 transition duration-75"
                          fill="currentColor"
                          viewBox="0 0 16 16"
                        >
                          <path d="M7.752.066a.5.5 0 0 1 .496 0l3.75 2.143a.5.5 0 0 1 .252.434v3.995l3.498 2A.5.5 0 0 1 16 9.07v4.286a.5.5 0 0 1-.252.434l-3.75 2.143a.5.5 0 0 1-.496 0l-3.502-2-3.502 2.001a.5.5 0 0 1-.496 0l-3.75-2.143A.5.5 0 0 1 0 13.357V9.071a.5.5 0 0 1 .252-.434L3.75 6.638V2.643a.5.5 0 0 1 .252-.434L7.752.066ZM4.25 7.504 1.508 9.071l2.742 1.567 2.742-1.567L4.25 7.504ZM7.5 9.933l-2.75 1.571v3.134l2.75-1.571V9.933Zm1 3.134 2.75 1.571v-3.134L8.5 9.933v3.134Zm.508-3.996 2.742 1.567 2.742-1.567-2.742-1.567-2.742 1.567Zm2.242-2.433V3.504L8.5 5.076V8.21l2.75-1.572ZM7.5 8.21V5.076L4.75 3.504v3.134L7.5 8.21ZM5.258 2.643 8 4.21l2.742-1.567L8 1.076 5.258 2.643ZM15 9.933l-2.75 1.571v3.134L15 13.067V9.933ZM3.75 14.638v-3.134L1 9.933v3.134l2.75 1.571Z" />
                        </svg>

                        <span class="ml-3 flex-1 whitespace-nowrap">
                          Depenses
                        </span>

                      </div>
                    </li>
                  </Link>
                ) : (
                  <Link to="/Depenses">
                    <li>
                      <div class="text-xl  font-normal rounded hover:text-gray-400 flex items-center p-2 group">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="w-6 h-6  flex-shrink-0  transition duration-75"
                          fill="currentColor"
                          viewBox="0 0 16 16"
                        >
                          <path d="M7.752.066a.5.5 0 0 1 .496 0l3.75 2.143a.5.5 0 0 1 .252.434v3.995l3.498 2A.5.5 0 0 1 16 9.07v4.286a.5.5 0 0 1-.252.434l-3.75 2.143a.5.5 0 0 1-.496 0l-3.502-2-3.502 2.001a.5.5 0 0 1-.496 0l-3.75-2.143A.5.5 0 0 1 0 13.357V9.071a.5.5 0 0 1 .252-.434L3.75 6.638V2.643a.5.5 0 0 1 .252-.434L7.752.066ZM4.25 7.504 1.508 9.071l2.742 1.567 2.742-1.567L4.25 7.504ZM7.5 9.933l-2.75 1.571v3.134l2.75-1.571V9.933Zm1 3.134 2.75 1.571v-3.134L8.5 9.933v3.134Zm.508-3.996 2.742 1.567 2.742-1.567-2.742-1.567-2.742 1.567Zm2.242-2.433V3.504L8.5 5.076V8.21l2.75-1.572ZM7.5 8.21V5.076L4.75 3.504v3.134L7.5 8.21ZM5.258 2.643 8 4.21l2.742-1.567L8 1.076 5.258 2.643ZM15 9.933l-2.75 1.571v3.134L15 13.067V9.933ZM3.75 14.638v-3.134L1 9.933v3.134l2.75 1.571Z" />
                        </svg>

                        <span class="ml-3 flex-1 whitespace-nowrap">
                          Depenses
                        </span>

                      </div>
                    </li>
                  </Link>
                )}

                {Resumes.test(pathname) ? (
                  <Link to="/Resumes">
                    <li>
                      <div class="text-xl font-normal rounded bg-blue-500 text-white  flex items-center p-2 group">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6 text-white flex-shrink-0 transition duration-75"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12"
                          />
                        </svg>
                        <span class="ml-3 flex-1 whitespace-nowrap">
                          Resumes
                        </span>

                      </div>
                    </li>
                  </Link>
                ) : (
                  <Link to="/Resumes">
                    <li>
                      <div class="text-xl  font-normal rounded hover:text-gray-400 flex items-center p-2 group">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6  flex-shrink-0  transition duration-75"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12"
                          />
                        </svg>

                        <span class="ml-3 flex-1 whitespace-nowrap">
                          Resumes
                        </span>

                      </div>
                    </li>
                  </Link>
                )}
              </ul>
              <a href="mailto:btr.dev@burundientempsreel.com" className=" cursor-pointer hover:text-gray-600 py-2 mb-4  flex pl-5 items-center">
                <div><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi mr-3 h-6 w-6 bi-question-diamond-fill" viewBox="0 0 16 16">
                  <path d="M9.05.435c-.58-.58-1.52-.58-2.1 0L.436 6.95c-.58.58-.58 1.519 0 2.098l6.516 6.516c.58.58 1.519.58 2.098 0l6.516-6.516c.58-.58.58-1.519 0-2.098L9.05.435zM5.495 6.033a.237.237 0 0 1-.24-.247C5.35 4.091 6.737 3.5 8.005 3.5c1.396 0 2.672.73 2.672 2.24 0 1.08-.635 1.594-1.244 2.057-.737.559-1.01.768-1.01 1.486v.105a.25.25 0 0 1-.25.25h-.81a.25.25 0 0 1-.25-.246l-.004-.217c-.038-.927.495-1.498 1.168-1.987.59-.444.965-.736.965-1.371 0-.825-.628-1.168-1.314-1.168-.803 0-1.253.478-1.342 1.134-.018.137-.128.25-.266.25h-.825zm2.325 6.443c-.584 0-1.009-.394-1.009-.927 0-.552.425-.94 1.01-.94.609 0 1.028.388 1.028.94 0 .533-.42.927-1.029.927z" />
                </svg></div>
                <div>
                  Aide
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SideBarBibliote;
