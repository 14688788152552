import React from "react";
import SildeEnseign from "../SildeEnseign/SildeEnseign";
import AjouterDepenseEns from "../DepenseArgent/AjouterDepenseEns";
import AjouterDemmandeEnse from "./AjouterDemmandeEnse";
function AjouterDemmandeEnseComponent() {
  return (
    <>

      <div class="overflow-hidden flex justify-between">
        <SildeEnseign />
        <AjouterDemmandeEnse />
      </div>
    </>
  );
}

export default AjouterDemmandeEnseComponent;
