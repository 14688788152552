import React, { useState } from "react";
import SideBarSecretaire from "../SideBarSecretaire/SideBarSecretaire";
import Registres from "./Registres";
function RegistresComponent({ getMenuMobille, getSizeMobille }) {
  return (
    <>
      {" "}
      <div class="flex overflow-hidden bg-white justify-between">
        <SideBarSecretaire getMenuMobille={getMenuMobille} />
        <Registres getSizeMobille={getSizeMobille} />
      </div>
    </>
  );
}

export default RegistresComponent;
