import React from 'react'
import SlideParentNavBars from '../SlideParentNavBars/SlideParentNavBars'
import ListeEvaluationParent from './ListeEvaluationParent'
function ListeEvaluationParentComponent({ getMenuMobille, getSizeMobille }) {
    return (
        <div class="overflow-hidden flex justify-between">
            <SlideParentNavBars getMenuMobille={getMenuMobille} />
            <ListeEvaluationParent getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default ListeEvaluationParentComponent
