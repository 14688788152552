import React from 'react'
import SlideParentNavBars from '../SlideParentNavBars/SlideParentNavBars'
import NoteDispline from './NoteDispline'
function NoteDisplineComponent({ getSizeMobille, getMenuMobille }) {
    return (
        <div className='bg-white flex overflow-hidden justify-between'>
            <SlideParentNavBars getMenuMobille={getMenuMobille} />
            <NoteDispline getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default NoteDisplineComponent
