import React, { useState } from "react";
import AjouterPrevisionComponent from "../../component/Bibliotheque/Prevision/AjouterPrevisionComponent";
function PrevisionPage({ getSizeMobille, getMenuMobille }) {
  return (
    <>
      <AjouterPrevisionComponent getMenuMobille={getMenuMobille} getSizeMobille={getSizeMobille} />
    </>
  );
}

export default PrevisionPage;
