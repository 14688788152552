/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import FootterCopy from "../../FootterCopy/FootterCopy";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { API } from "../../../Api/API";
import Select from "react-select";
function Ajouter({ getSizeMobille }) {

  const [mobile11, SetMobile11] = useState(window.innerWidth < 501)
  const History = useNavigate()
  useEffect(() => {
    const hundlesSize = () => {

      SetMobile11(window.innerWidth < 501)
    }

    window.addEventListener('resize', hundlesSize)

    return () => {
      window.removeEventListener('resize', hundlesSize)
    }
  }, [])


  const [titre, Settitre] = useState('')
  const [animationClasstitre, setAnimationClasstitre] = useState('');
  const elemetReftitre = useRef(null)

  const [source, SetSource] = useState('')
  const [animationClasssource, setAnimationClasssource] = useState('');
  const elemetRefsource = useRef(null)
  const [livreCherche, SetlivreCherche] = useState('')
  const [animationClasslivreCherche, setAnimationClasslivreCherche] = useState('');
  const elemetRefCherch = useRef(null)
  const [getDedie, SetgetDedie] = useState('')
  const [animationClassgetDedie, setanimationClassgetDedie] = useState('');
  const elemetRefdedie = useRef(null)
  const [animationClassexemplaire, setanimationClassexemplaire] = useState('');
  const [exemplaires, setExemplaires] = useState([]);
  const inputRefs = useRef([]);

  //start class options
  const [Classoptions, SetClassoptions] = useState([])
  const [selectedOption1, setSelectedOption1] = useState({ value: "Tous", label: "Classe" });
  const hiddenClassoptions = Classoptions.slice(1);
  const [animationClassClasse, setanimationClassClasse] = useState('');
  const elemetRefClasse = useRef(null)
  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: 50,
      backgroundColor: "white",

    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "blue" : state.isFocused ? "lightblue" : "white",
      color: state.isSelected ? "white" : "black",
    }),
  };
  useEffect(() => {
    API.get("/classes")
      .then((response) => {
        // Transformation des données pour correspondre à la structure attendue
        const classesFromAPI = response.data.map(classe => ({
          value: classe.abreviation,
          label: classe.abreviation
        }));
        // Mettre à jour l'état avec les nouvelles données
        SetClassoptions([selectedOption1, ...classesFromAPI]);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);
  const SelectClasse = (options) => {
    setSelectedOption1(options)
  }
  //end class options

  const handleAjouterExemplaire = () => {
    const nouvelExemplaire = {
      id: exemplaires.length + 1,
      valeur: ''
    };

    setExemplaires([...exemplaires, nouvelExemplaire]);
  };

  const handleValeurChange = (e, index) => {
    const nouvellesValeurs = [...exemplaires];
    nouvellesValeurs[index].valeur = e.target.value;
    setExemplaires(nouvellesValeurs);
  };

  const handleSupprimerExemplaire = (index) => {
    const nouvellesValeurs = [...exemplaires];
    nouvellesValeurs.splice(index, 1);
    setExemplaires(nouvellesValeurs);
  };





  const handleSubmit = (e) => {
    e.preventDefault()
    if (titre.trim() === '') {
      toast.warning('Le titre du livre est  obligatoire', {
        autoClose: 2000
      });
      setAnimationClasstitre('animate__animated animate__shakeX border-2 border-red-500 ')
      setTimeout(() => {
        setAnimationClasstitre(' ')
      }, 3000)
      elemetReftitre.current.focus()

      return false
    } else if (source.trim() == '') {
      toast.warning('Source du livre est obligatoire', {
        autoClose: 2000
      });
      setAnimationClasssource('animate__animated animate__shakeX border-2 border-red-500')
      setTimeout(() => {
        setAnimationClasssource(' ')
      }, 3000)
      elemetRefsource.current.focus()

      return false
    }
    else if (livreCherche == '') {
      toast.warning("Reponds si le livre est recherche ou pas ", {
        autoClose: 2000
      });
      setAnimationClasslivreCherche('animate__animated animate__shakeX  border-2 border-red-500')
      setTimeout(() => {
        setAnimationClasslivreCherche(' ')
      }, 3000)
      elemetRefCherch.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      return false
    }
    else if (getDedie == '') {
      toast.warning('Livre est dediée avec qui ?', {
        autoClose: 2000
      });
      setanimationClassgetDedie('animate__animated animate__shakeX border-2 border-red-500')
      setTimeout(() => {
        setanimationClassgetDedie(' ')
      }, 3000)
      elemetRefsource.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      return;
    }
    if (getDedie !== 'Tous') {
      if (selectedOption1.value === 'Tous') {
        toast.warning(`A quel classe `, {
          autoClose: 2000
        });
        setanimationClassClasse('animate__animated animate__shakeX border-2 border-red-500')
        setTimeout(() => {
          setanimationClassClasse(' ')
        }, 3000)
        elemetRefClasse.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
        return;
      }

    }
    if (exemplaires.length == 0) {
      toast.warning('Exemplaire de livre est obligatoire', {
        autoClose: 2000
      });
      setanimationClassexemplaire('animate__animated animate__shakeX border-2 border-red-500')
      setTimeout(() => {
        setanimationClassexemplaire(' ')
      }, 3000)
      return false
    }
    const exemplairesVides = exemplaires.reduce((acc, exemplaire, index) => {
      if (exemplaire.valeur === '') {
        elemetReftitre.current.focus()
        acc.push(index + 1);
      }
      return acc;
    }, []);

    if (exemplairesVides.length > 0) {
      const firstEmptyInputRef = inputRefs.current[exemplairesVides[0] - 1];
      toast.warning(`Completer le champ ou le supprimer Exemplaire ${exemplairesVides[0]}`, {
        autoClose: 10000
      });
      firstEmptyInputRef.scrollIntoView({ behavior: 'smooth', block: 'center' });
      firstEmptyInputRef.focus();
      return;
    }

    const data = exemplaires.reduce((acc, exemplaire) => {
      if (exemplaire.valeur) {
        acc.push({ numero: exemplaire.valeur });
      }
      return acc;
    }, []);

    const formData = {
      titre: titre,
      source: source,
      dedie: getDedie,
      classe: selectedOption1.value,
      livrecherche: livreCherche,
      exemplairelivres: data
    }

    API.post("/livres", formData)
      .then((res) => {
        toast.success('Enregistrement avec succes')
        History("/Livres")
      })
      .catch((err) => {
        if (err.response) {
          if (Array.isArray(err.response.data)) {
            toast.warn(err.response.data[0])
          } else if (typeof err.response.data === 'object') {
            // Si c'est un objet, afficher la valeur (par exemple, en la convertissant en chaîne JSON)
            toast.warn(JSON.stringify(err.response.data))
          } else if (typeof err.response.data === 'string') {
            // Si c'est une chaîne de caractères, l'afficher directement
            toast.warn(err.response.data)
          } else {
            // Si c'est autre chose, afficher un message par défaut
            toast.warn('Erreur inattendue lors de la récupération des données de réponse')
          }
          return false;
        }
        console.log(err.message)
      })
      .finally(() => {
        console.log("")
      });
  }


  return (

    <div className={`bg-white  overflow-y-auto  overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
      <Link to='/Livres' className="ml-2 text-[18px] text-blue-500 hover:text-blue-800 underline">Retour</Link>

      <div className="sm:p-4 p-2 min-h-screen">
        <div class="my-5">
          <div class="w-full flex flex-row items-center justify-between">
            <p class="text-navy-700   ">
              <a
                class="hover:text-navy-700 text-2xl font-bold  "

              >
                Ajouter un Livre
              </a>
            </p>

          </div>
        </div>
        <div class="w-full my-4 grid grid-cols-1 ">
          <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">
            <form onSubmit={handleSubmit}>
              <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col my-2">
                <div class="-mx-3 md:flex mb-6  ">
                  <div class={`md:w-1/2 px-3 mb-6 md:mb-0`}>
                    <label
                      class="block uppercase tracking-wide  text-lg font-bold mb-2"
                      for="grid-Title"
                    >
                      Titre
                    </label>

                    <input
                      class={` outline-none block w-full bg-grey-lighter  border rounded py-3 px-4 mb-3 ${titre.trim() === '' ? animationClasstitre : 'focus:border-2 focus:border-blue-700'} `}
                      type="text"
                      ref={elemetReftitre} value={titre} onInput={(e) => Settitre(e.target.value)}
                      placeholder="Title du Livre"
                    />
                  </div>
                  <div class={`md:w-1/2 px-3`}>
                    <label
                      class="block uppercase tracking-wide  text-lg font-bold mb-2"
                      for="grid-last-name"
                    >
                      Source
                    </label>
                    <input
                      ref={elemetRefsource} value={source} onInput={(e) => SetSource(e.target.value)}
                      class={`appearance-none outline-none block w-full bg-grey-lighter  border rounded py-3 px-4  ${source.trim() === '' ? animationClasssource : 'focus:border-2 focus:border-blue-700'}`}
                      id="grid-last-name"
                      type="text"
                      placeholder="Source du Livre"
                    />
                  </div>
                  <div class={`md:w-1/2 px-3 mb-6 md:mb-0`}>
                    <label
                      class="block uppercase tracking-wide  text-lg font-bold mb-2"
                      for="grid-last-name"
                    >
                      Livre recherche
                    </label>
                    <select
                      class={`block appearance-none w-full bg-white cursor-pointer border text-gray-600   py-3 px-4 pr-8 rounded  ${animationClasslivreCherche}`}
                      onChange={(e) => { SetlivreCherche(e.target.value) }}
                      ref={elemetRefCherch}
                    >
                      <option value='' hidden >Livre recherche</option>
                      <option value='Oui'>Oui</option>
                      <option value='Non'>Non</option>
                    </select>

                  </div>
                </div>

                <div class={`-mx-3 md:flex mb-6`}>

                  <div class={` px-3 ${getDedie === 'Tous' || getDedie == '' ? 'w-64' : 'md:w-1/2'}`}>
                    <label
                      class="block uppercase tracking-wide  text-lg font-bold mb-2"
                      for="grid-state"
                    >
                      Dediée Aux
                    </label>
                    <div class="relative flex flex-row space-x-3">
                      <select
                        class={`block appearance-none cursor-pointer w-full bg-white border text-gray-600 py-3 px-4 pr-8 rounded ${animationClassgetDedie}`}
                        id="grid-state"
                        ref={elemetRefdedie}
                        onChange={(e) => { SetgetDedie(e.target.value) }}
                      >
                        <option value='' hidden> Dediée Aux</option>
                        <option value='Enseignant'>Enseignant</option>
                        <option value='Elèves'>Elèves</option>
                        <option value='Tous'>Tous</option>
                      </select>

                      {
                        getDedie === 'Tous' || getDedie == '' ?
                          null : (
                            <>
                              <div class="md:w-1/2 px-3">
                                <label
                                  class="block uppercase tracking-wide text-gray-700 text-lg font-bold mb-2"
                                  for="grid-password"
                                >
                                  Classe
                                </label>

                                <div ref={elemetRefClasse} className={` rounded  ${selectedOption1.value == '' ? animationClassClasse : 'focus:border-2 focus:border-blue-700'} `}
                                >
                                  <Select
                                    value={selectedOption1}
                                    options={hiddenClassoptions}
                                    styles={customStyles}
                                    onChange={SelectClasse}
                                  />
                                </div>

                              </div>
                            </>)
                      }
                    </div>
                  </div>
                </div>


                <div class="-mx-3 md:flex flex-col mb-6">
                  {exemplaires.map((exemplaire, index) => (
                    <div key={exemplaire.id} class="md:w-1/2 px-3 mb-6 md:mb-0">
                      <label htmlFor={`exemplaire-${exemplaire.id}`} class="block uppercase tracking-wide  text-lg font-bold mb-2">Exemplaire {index + 1}</label>
                      <div className="flex flex-row space-x-3">
                        <input
                          id={`exemplaire-${exemplaire.id}`}
                          type="text"
                          value={exemplaire.valeur}
                          onChange={(e) => handleValeurChange(e, index)}
                          ref={(ref) => (inputRefs.current[index] = ref)}
                          placeholder="Prefixe de l'exemplaire"
                          className={`appearance-none block w-full bg-grey-lighter  border border-red rounded py-3 px-4 mb-3`}

                        />
                        <div class="flex items-center justify-center">
                          <div
                            onClick={() => handleSupprimerExemplaire(index)}
                            class="w-8 h-8 bg-gray-100 mb-4 hover:bg-gray-200 cursor-pointer p-1 rounded-xl  "
                          >
                            {" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="w-6 h-6  mb-3  text-blue-500"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                              />
                            </svg>
                          </div>
                        </div>

                      </div>
                    </div>
                  ))}
                </div>
                <div class={`mb-4 flex items-center justify-start`}>
                  <div class="">
                    <div
                      className={`mt-4 sm:mt-0 inline-flex cursor-pointer items-start justify-start px-6 py-3 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg  ${animationClassexemplaire}`}
                      onClick={handleAjouterExemplaire}
                    >
                      <p className="text-lg font-medium leading-none text-white">
                        Ajouter un Exemplaire
                      </p>
                    </div>
                  </div>
                </div>
                <div class="mb-4 flex items-center justify-end">
                  <div class="">
                    <button class="mt-4 sm:mt-0 inline-flex items-start justify-start px-2 sm:px-6 py-3 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg">
                      <p class="text-lg font-medium leading-none text-white">
                        Ajouter
                      </p>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <FootterCopy />
    </div>

  );
}


export default Ajouter;










