/* eslint-disable no-unused-vars */
import { useEffect, useRef, useState } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import 'animate.css/animate.min.css'
import { ToastContainer } from 'react-toastify'

import NavBarsBiblioth from './component/Bibliotheque/NavBarsBiblioth/NavBarsBiblioth'
import AccueilPage from './pages/Bibliotheque/AccueilPage'
import LivresPage from './pages/Bibliotheque/LivresPage'
import ListeLivresPage from './pages/Bibliotheque/ListeLivresPage'
import RetourLivresPage from './pages/Bibliotheque/RetourLivresPage'
import RetourLivresElevesPage from './pages/Bibliotheque/RetourLivresElevesPage'
import RetourLivresEnseignPage from './pages/Bibliotheque/RetourLivresEnseignPage'
import RetourLivresAutrePersonPage from './pages/Bibliotheque/RetourLivresAutrePersonPage'
import RetourLivresVisiteurPage from './pages/Bibliotheque/RetourLivresVisiteurPage'
import AjouterPage from './pages/Bibliotheque/AjouterPage'
import DetailLivrePage from './pages/Bibliotheque/DetailLivrePage'
import EmprunterPage from './pages/Bibliotheque/EmprunterPage'
import EmprunterEleveListePage from './pages/Bibliotheque/EmprunterEleveListePage'
import EmprunterElevePage from './pages/Bibliotheque/EmprunterElevePage'
import EmprunterEnseignantPage from './pages/Bibliotheque/EmprunterEnseignantPage'
import EmprunterVisitorPage from './pages/Bibliotheque/EmprunterVisitorPage'
import EmprunterPersonnelPage from './pages/Bibliotheque/EmprunterPersonnelPage'
import DepensesPage from './pages/Bibliotheque/DepensesPage'
import PrevisionPage from './pages/Bibliotheque/PrevisionPage'
import AjouterPrevisionPage from './pages/Bibliotheque/AjouterPrevisionPage'
import DetailAjouteExemp from './component/Bibliotheque/DetailLivre/DetailAjouteExemp'
import StatsPage from './pages/Bibliotheque/StatsPage'
import CartsElevePage from './pages/Bibliotheque/CartsElevePage'
import ModifierBiblioPage from './pages/Bibliotheque/ModifierBiblioPage'
import EmprunterEnseignantLivresPage from './pages/Bibliotheque/EmprunterEnseignantLivresPage'
import CartEnseignPage from './pages/Bibliotheque/CartEnseignPage'
import PersonnelLivresPage from './pages/Bibliotheque/PersonnelLivresPage'
import PersonlivreCardPage from './pages/Bibliotheque/PersonlivreCardPage'
import EmpruntListLivreVisitorPage from './pages/Bibliotheque/EmpruntListLivreVisitorPage'
import EmpruntVisitorCartsPage from './pages/Bibliotheque/EmpruntVisitorCartsPage'
import AjouteVisiteurBiblioPage from './component/Bibliotheque/Emprunter/AjouteVisiteurBiblioPage'
import ResumeBiblio from './component/Bibliotheque/RetourLivres/ResumeBiblio'
import PrevisiondetailPage from './pages/Bibliotheque/PrevisiondetailPage'
import PrevisioModifierPage from './pages/Bibliotheque/PrevisioModifierPage'
import AjouterDepenseBiblioPage from './pages/Bibliotheque/AjouterDepenseBiblioPage'
import DepenseBiblioDetailPage from './pages/Bibliotheque/DepenseBiblioDetailPage'
import ResumesBibliothequePage from './pages/Bibliotheque/ResumesBibliothequePage'
import ResumesBibliothequeDetailPage from './pages/Bibliotheque/ResumesBibliothequeDetailPage'
import PresenceBlioPage from './pages/Bibliotheque/PresenceBlioPage'
import CongeBibliothePage from './pages/Bibliotheque/CongeBibliothePage'
import AjouteCongeBibliothePage from './pages/Bibliotheque/AjouteCongeBibliothePage'
import CongeBibliotheDetailPage from './pages/Bibliotheque/CongeBibliotheDetailPage'
import DemandeBiblioPage from './pages/Bibliotheque/DemandeBiblioPage'
import AjouteDemandeBiblioPage from './pages/Bibliotheque/AjouteDemandeBiblioPage'
import DemandeBiblioDetailPage from './pages/Bibliotheque/DemandeBiblioDetailPage'

//Secretaire
import AccueilSecretairePage from './pages/Secretariat/AccueilSecretairePage'
import NavBarSecretaire from './component/Secretariat/NavBarSecretaire/NavBarSecretaire'
import ClasseChoixSecretairePage from './pages/Secretariat/ClasseChoixSecretairePage'
import DetailDossierPage from './pages/Secretariat/DetailDossierPage'
import AjouterElevePage from './pages/Secretariat/AjouterElevePage'
import ModifierElevePage from './pages/Secretariat/ModifierElevePage'
import AjouterPersonnelPage from './pages/Secretariat/AjouterPersonnelPage'
import AjouterRecusPage from './pages/Secretariat/AjouterRecusPage'
import ClasseurPage from './pages/Secretariat/ClasseurPage'
import CongeAnnuelPage from './pages/Secretariat/CongeAnnuelPage'
import CourriersPage from './pages/Secretariat/CourriersPage'
import DemandeReponsePage from './pages/Secretariat/DemandeReponsePage'
import DDossierEleveSecretairePage from './pages/Secretariat/DossierEleveSecretairePage'
import DossierPersonnelPage from './pages/Secretariat/DossierPersonnelPage'
import DetailDossierPersPage from './pages/Secretariat/DetailDossierPersPage'
import ModifierPersonelPage from './pages/Secretariat/ModifierPersonelPage'
import ListeRecusPage from './pages/Secretariat/ListeRecusPage'
import RegistresPage from './pages/Secretariat/RegistresPage'
import CongeAnnuelDetailPage from './pages/Secretariat/CongeAnnuelDetailPage'
import AjouterCongeAnnuelSecretPage from './pages/Secretariat/AjouterCongeAnnuelSecretPage'
import ModifierRecusPage from './pages/Secretariat/ModifierRecusPage'
import DetailRecusPage from './pages/Secretariat/DetailRecusPage'
import DocClasseurPage from './pages/Secretariat/DocClasseurPage'
import DemandeReponseDetaillPage from './pages/Secretariat/DemandeReponseDetaillPage'
import DetteBibliothequePage from './pages/Secretariat/DetteBibliothequePage'
import ChoixClasseDetteBiblioPage from './pages/Secretariat/ChoixClasseDetteBiblioPage'
import ListDetteBiblioPage from './pages/Secretariat/ListDetteBiblioPage'
import DetteBibliothequeDetailPage from './pages/Secretariat/DetteBibliothequeDetailPage'
import ListDetteEconomPage from './pages/Secretariat/ListDetteEconomPage'
import DetteEconomDetailPage from './pages/Secretariat/DetteEconomDetailPage'
import ChoixClasseEconomPage from './pages/Secretariat/ChoixClasseEconomPage'
import AjouteReponseListPersonnePage from './pages/Secretariat/AjouteReponseListPersonnePage'
import AjouterReponseSecretairePage from './pages/Secretariat/AjouterReponseSecretairePage'
import PresenceSecraitrePage from './pages/Secretariat/PresenceSecraitrePage'
import AjouterExpedierPage from './pages/Secretariat/AjouterExpedierPage'
import ListeExpedierPage from './pages/Secretariat/ListeExpedierPage'
import ModifierExpedierPage from './pages/Secretariat/ModifierExpedierPage'
import DetailExpedierPage from './pages/Secretariat/DetailExpedierPage'
import AjouteExpliChaquePersonPage from './pages/Secretariat/AjouteExpliChaquePersonPage'

//infirmier
import NavBarInfirmier from './component/NavBarInfirmier/NavBarInfirmier'
import AccueilInfirmPage from './pages/Infirmerie/AccueilInfirmPage'
import PresenceInfiemierPage from './pages/Infirmerie/PresenceInfiemierPage'
import MedicamentPage from './pages/Infirmerie/MedicamentPage'
import AjouterMedicamentPage from './pages/Infirmerie/AjouterMedicamentPage'
import MedicamDetailPage from './pages/Infirmerie/MedicamDetailPage'
import MedicamentModifierPage from './pages/Infirmerie/MedicamentModifierPage'
import ConsultationPage from './pages/Infirmerie/ConsultationPage'
import EleveConsultationPage from './pages/Infirmerie/EleveConsultationPage'
import PersonnelConsultationPage from './pages/Infirmerie/PersonnelConsultationPage'
import ParticulierConsultationPage from './pages/Infirmerie/ParticulierConsultationPage'
import TraitementInfirmniePage from './pages/Infirmerie/TraitementInfirmniePage'
import DestrubuerTraitementInfirmniePage from './pages/Infirmerie/DestrubuerTraitementInfirmniePage'
import LaboratoirePage from './pages/Infirmerie/LaboratoirePage'
import LaboratoireDistriPage from './pages/Infirmerie/LaboratoireDistriPage'
import PatientPage from './pages/Infirmerie/PatientPage'
import PatientDetailPage from './pages/Infirmerie/PatientDetailPage'
import MedicamentsExpiresPage from './pages/Infirmerie/MedicamentsExpiresPage'
import DepensesInfirmeierPage from './pages/Infirmerie/DepensesInfirmeierPage'
import AjouterDepensesInfirmeierPage from './pages/Infirmerie/AjouterDepensesInfirmeierPage'
import DepensesInfirmeierDetailPage from './pages/Infirmerie/DepensesInfirmeierDetailPage'
import PrevisionInfirmierPage from './pages/Infirmerie/PrevisionInfirmierPage'
import AjouterPrevisionInfirmierPage from './pages/Infirmerie/AjouterPrevisionInfirmierPage'
import PrevisionInfirmierDetailPage from './pages/Infirmerie/PrevisionInfirmierDetailPage'
import PrevisionInfirmierModifierPage from './pages/Infirmerie/PrevisionInfirmierModifierPage'
import RapportInfirmierPage from './pages/Infirmerie/RapportInfirmierPage'
import CongeInfiermierPage from './pages/Infirmerie/CongeInfiermierPage'
import AjouterCongeInfiermierPage from './pages/Infirmerie/AjouterCongeInfiermierPage'
import CongeInfiermierDetailPage from './pages/Infirmerie/CongeInfiermierDetailPage'
import DetteEconomePage from './pages/Secretariat/DetteEconomePage'

// Enseignant
import NavBarsEnseign from './component/Enseignant/NavBarsEnseign/NavBarsEnseign'
import EnregistreNotesPage from './pages/Enseingent/EnregistreNotesPage'
import EnregistreNotesChoixClassPage from './pages/Enseingent/EnregistreNotesChoixClassPage'
import DepenseArgentPage from './pages/Enseingent/DepenseArgentPage'
import DepenseArgentDetailPage from './pages/Enseingent/DepenseArgentDetailPage'
import PrevisionMatiersEnsPage from './pages/Enseingent/PrevisionMatiersEnsPage'
import NeuwPrevisionMatiersEnsPage from './pages/Enseingent/NeuwPrevisionMatiersEnsPage'
import AcceuilEnseingPage from './pages/Enseingent/AcceuilEnseingPage'
import PresenceEnsPage from './pages/Enseingent/PresenceEnsPage'
import ModifierNotesElevePage from './pages/Enseingent/ModifierNotesElevePage'
import EvaluationPage from './pages/Enseingent/EvaluationPage'
import EvaluationDetailPage from './pages/Enseingent/EvaluationDetailPage'
import CoursEnsPage from './pages/Enseingent/CoursEnsPage'
import DemmandeEnsePage from './pages/Enseingent/DemmandeEnsePage'
import AjouterDepenseEnsPage from './pages/Enseingent/AjouterDepenseEnsPage'
import AjouterDemmandeEnsePage from './pages/Enseingent/AjouterDemmandeEnsePage'
import DetailDepenseEnsPage from './pages/Enseingent/ModifierDepenseEnsPage'

//cantine
import NavBArsCantin from './component/Cantine/NavBArsCantin/NavBArsCantin'
import AcceuilCantinePage from './pages/Cantine/AcceuilCantinePage'
import AchatPage from './pages/Cantine/AchatPage'
import AjouteAchatPaage from './pages/Cantine/AjouteAchatPaage'
import DetailAchatPage from './pages/Cantine/DetailAchatPage'
import VentePage from './pages/Cantine/VentePage'
import AjouteVentePage from './pages/Cantine/AjouteVentePage'
import DetailVentePage from './pages/Cantine/DetailVentePage'
import StatistiquePage from './pages/Cantine/StatistiquePage'
import ComptePage from './pages/Cantine/ComptePage'

//Parent
import AcceuilParentPage from './pages/Parent/AcceuilParentPage'
import NavBarsParent from './component/Parent/NavBarsParent/NavBarsParent'
import NoteEnfPage from './pages/Parent/NoteEnfPage'
import ListeCoursParentPage from './pages/Parent/ListeCoursParentPage'
import ListeEvaluationParentPage from './pages/Parent/ListeEvaluationParentPage'
import NoteDisplinePage from './pages/Parent/NoteDisplinePage'
import NoteAllElevPage from './pages/Parent/NoteAllElevPage'
import ListeCoursAllParentPage from './pages/Parent/ListeCoursAllParentPage'
import ListeEvaluationAllParentPage from './pages/Parent/ListeEvaluationAllParentPage'
import Autor from './Autor/Autor'

// Prefet des displines
import NavBarsPrefetDisplin from './component/PrefetDiscipline/NavBarsPrefetDisplin/NavBarsPrefetDisplin'
import AcceuilPrefetDisplinPage from './pages/PrefetDisplin/AcceuilPrefetDisplinPage'
import PresencePrefetDisplinPage from './pages/PrefetDisplin/PresencePrefetDisplinPage'
import SuiveurPrefetDisplinPage from './pages/PrefetDisplin/SuiveurPrefetDisplinPage'
import ListeSuiveurPrefetDisplinPage from './pages/PrefetDisplin/ListeSuiveurPrefetDisplinPage'
import ListeSuiveurDetailPrefetDisplinPage from './pages/PrefetDisplin/ListeSuiveurDetailPrefetDisplinPage'
import HaritePrefetDisplinPage from './pages/PrefetDisplin/HaritePrefetDisplinPage'
import EncadeurPrefetDisplinPage from './pages/PrefetDisplin/EncadeurPrefetDisplinPage'
import HoraireEncaPreftDisplinPage from './pages/PrefetDisplin/HoraireEncaPreftDisplinPage'
import AddHoraireEncaPreftDisplinPage from './pages/PrefetDisplin/AddHoraireEncaPreftDisplinPage'
import ModeHoraireEncaPreftDisplinPage from './pages/PrefetDisplin/ModeHoraireEncaPreftDisplinPage'
import RapportEncaderPrefetPage from './pages/PrefetDisplin/RapportEncaderPrefetPage'
import SalleClassePrefetDisplinPage from './pages/PrefetDisplin/SalleClassePrefetDisplinPage'
import AddSallePrefDisplinPage from './pages/PrefetDisplin/AddSallePrefDisplinPage'
import ModSalleClassePrefetDisplinPage from './pages/PrefetDisplin/ModSalleClassePrefetDisplinPage'
import RefectoirePrefetDisplinPage from './pages/PrefetDisplin/RefectoirePrefetDisplinPage'
import DetailRefectoirePrefetDisplinPage from './pages/PrefetDisplin/DetailRefectoirePrefetDisplinPage'
import MaterielPrefetDisplinPage from './pages/PrefetDisplin/MaterielPrefetDisplinPage'
import MaterielDetailPrefetDisplinPage from './pages/PrefetDisplin/MaterielDetailPrefetDisplinPage'
import AddMaterielPrefetDisplinPage from './pages/PrefetDisplin/AddMaterielPrefetDisplinPage'
import ModiMaterielPrefetDisplinPage from './pages/PrefetDisplin/ModiMaterielPrefetDisplinPage'
import HorairePrefetDisplinPage from './pages/PrefetDisplin/HorairePrefetDisplinPage'
import AjoutHorairePrefetDisplinPage from './pages/PrefetDisplin/AjoutHorairePrefetDisplinPage'
import ModiHorairePrefetDisplinPage from './pages/PrefetDisplin/ModiHorairePrefetDisplinPage'
import HostpotalisePrefetDisplinPage from './pages/PrefetDisplin/HostpotalisePrefetDisplinPage'
import DortoirePrefetDisplinPage from './pages/PrefetDisplin/DortoirePrefetDisplinPage'
import GarconCompartementDortoirePrefetDisplinPage from './pages/PrefetDisplin/GarconCompartementDortoirePrefetDisplinPage'
import HomeGarconDetailDortoirePrefetDisplinPage from './pages/PrefetDisplin/HomeGarconDetailDortoirePrefetDisplinPage'
import AddDortoirePrefetDisplinPage from './pages/PrefetDisplin/AddDortoirePrefetDisplinPage'
import DortoireAffichangePrefetDisplinPage from './pages/PrefetDisplin/DortoireAffichangePrefetDisplinPage'
import HomeFillDortoirePrefetDisplinPage from './pages/PrefetDisplin/HomeFillDortoirePrefetDisplinPage'
import HomeFillDetailDortoirePrefetDisplinPage from './pages/PrefetDisplin/HomeFillDetailDortoirePrefetDisplinPage'
import FilleCompartementDortoirePrefetDisplinPage from './pages/PrefetDisplin/FilleCompartementDortoirePrefetDisplinPage'
import RepreseantPredDisplinPage from './pages/PrefetDisplin/RepreseantPredDisplinPage'
import AddRepreseantPredDisplinPage from './pages/PrefetDisplin/AddRepreseantPredDisplinPage'
import ModifRepreseantPredDisplinPage from './pages/PrefetDisplin/ModifRepreseantPredDisplinPage'
import LatrinePreftDisplinPage from './pages/PrefetDisplin/LatrinePreftDisplinPage'
import InformaMaterielPrefetDisplinPage from './pages/PrefetDisplin/InformaMaterielPrefetDisplinPage'
import AddLatrinePreftDisplinPage from './pages/PrefetDisplin/AddLatrinePreftDisplinPage'
import ModifieLatrinePreftDisplinPage from './pages/PrefetDisplin/ModifieLatrinePreftDisplinPage'
import RapporDisplintPrefetDisplinPage from './pages/PrefetDisplin/RapporDisplintPrefetDisplinPage'
import ChoixRapportPrefetDisplinPage from './pages/PrefetDisplin/ChoixRapportPrefetDisplinPage'
import RappoMaterilePrefetDisplinPage from './pages/PrefetDisplin/RappoMaterilePrefetDisplinPage'
import CongePrefetDisplinPage from './pages/PrefetDisplin/CongePrefetDisplinPage'
import AddCongePrefetDisplinPage from './pages/PrefetDisplin/AddCongePrefetDisplinPage'
import DetailCongePrefetDisplinPage from './pages/PrefetDisplin/DetailCongePrefetDisplinPage'
import DemandePrefetDisplinPage from './pages/PrefetDisplin/DemandePrefetDisplinPage'
import AddDemandePrefetDisplinPage from './pages/PrefetDisplin/AddDemandePrefetDisplinPage'
import DetailDemandePrefetDisplinPage from './pages/PrefetDisplin/DetailDemandePrefetDisplinPage'
import PrevisionPrefetDisplinPage from './pages/PrefetDisplin/PrevisionPrefetDisplinPage'
import AddPrevisionPrefetDisplinPage from './pages/PrefetDisplin/AddPrevisionPrefetDisplinPage'
import DetailPrevisionPrefetDisplinPage from './pages/PrefetDisplin/DetailPrevisionPrefetDisplinPage'
import ModifierPrevisionPrefetDisplinPage from './pages/PrefetDisplin/ModifierPrevisionPrefetDisplinPage'
import DepensePrefetDisplinPage from './pages/PrefetDisplin/DepensePrefetDisplinPage'
import AddDepensePrefetDisplinPage from './pages/PrefetDisplin/AddDepensePrefetDisplinPage'
import DetailDepensePrefetDisplinPage from './pages/PrefetDisplin/DetailDepensePrefetDisplinPage'

//Encadrement
import NavBarsEncadrement from './component/Encadrement/NavBarsEncadrement/NavBarsEncadrement'
import AcceuilEncadrementPage from './pages/Encadrement/AcceuilEncadrementPage'
import PresenceEncadrementPage from './pages/Encadrement/PresenceEncadrementPage'
import SuiveurEncadrementPage from './pages/Encadrement/SuiveurEncadrementPage'
import ConsultSuiveurEncadrementPage from './pages/Encadrement/ConsultSuiveurEncadrementPage'
import DetailConsultSuiveurEncadrementPage from './pages/Encadrement/DetailConsultSuiveurEncadrementPage'
import AddFauteConsultSuiveurEncadrementPage from './pages/Encadrement/AddFauteConsultSuiveurEncadrementPage'
import RentreEncadrementPage from './pages/Encadrement/RentreEncadrementPage'
import AddRentreEncadrementPage from './pages/Encadrement/AddRentreEncadrementPage'

import MaterielEncadrementPage from './pages/Encadrement/MaterielEncadrementPage'
import AddEmpruntMaterielEncadrementPage from './pages/Encadrement/AddEmpruntMaterielEncadrementPage'
import DetailMaterielEncadrementPage from './pages/Encadrement/DetailMaterielEncadrementPage'
import ModifieEmpruntMaterielEncadrementPage from './pages/Encadrement/ModifieEmpruntMaterielEncadrementPage'
import DetailRentreEncadrementPage from './pages/Encadrement/DetailRentreEncadrementPage'
import ModifieRentreEncadrementPage from './pages/Encadrement/ModifieRentreEncadrementPage'
import RetournMaterielEncadrementPage from './pages/Encadrement/RetournMaterielEncadrementPage'
import MaterielEncadrementEmpruntListeClassePage from './pages/Encadrement/MaterielEncadrementEmpruntListeClassePage'
import MaterielEncadrementEmpruntListeElevesPage from './pages/Encadrement/MaterielEncadrementEmpruntListeElevesPage'
import MaterielListeEncadrementEmpruntElevesPage from './pages/Encadrement/MaterielListeEncadrementEmpruntElevesPage'
import MaterielListeEncadrementEmpruntElevesAllPage from './pages/Encadrement/MaterielListeEncadrementEmpruntElevesAllPage'
import MaterielEncadrementEmpruntPersonnePage from './pages/Encadrement/MaterielEncadrementEmpruntPersonnePage'
import MaterielListeEncadrementEmpruntPersonnePage from './pages/Encadrement/MaterielListeEncadrementEmpruntPersonnePage'
import MaterielListeEncadrementEmpruntPersonneAllPage from './pages/Encadrement/MaterielListeEncadrementEmpruntPersonneAllPage'
import RapportEncadreursAddRapportPage from './pages/Encadrement/RapportEncadreursAddRapportPage'

import EncadrementHorairePage from './pages/Encadrement/EncadrementHorairePage'
import PresentantEncadrementPage from './pages/Encadrement/PresentantEncadrementPage'
import CongeEncadreurPage from './pages/Encadrement/CongeEncadreurPage'
import AddCongeEncadreurPage from './pages/Encadrement/AddCongeEncadreurPage'
import DetailCongeEncadreurPage from './pages/Encadrement/DetailCongeEncadreurPage'
import DepenseEncadreurPage from './pages/Encadrement/DepenseEncadreurPage'
import AddDepenseEncadreurPage from './pages/Encadrement/AddDepenseEncadreurPage'
import DetailDepenseEncadreurPage from './pages/Encadrement/DetailDepenseEncadreurPage'
import RapportEncadreursPage from './pages/Encadrement/RapportEncadreursPage'
import PrevisiondetailComponen from './component/Encadrement/PrevisionEncadreur/PrevisiondetailComponen'
import AddPrevisionEncadreurComponent from './component/Encadrement/PrevisionEncadreur/AddPrevisionEncadreurComponent'
import PrevisionEncadreurComponent from './component/Encadrement/PrevisionEncadreur/PrevisionEncadreurComponent'
import PrevisioModifierEncadreurComponent from './component/Encadrement/PrevisionEncadreur/PrevisioModifierEncadreurComponent'
import DemandeEncadreurComponent from './component/Encadrement/DemandeEncadreur/DemandeEncadreurComponent'
import AjouteDemandeEncadreurComponent from './component/Encadrement/DemandeEncadreur/AjouteDemandeEncadreurComponent'
import DemandeEncadreurDetailComponent from './component/Encadrement/DemandeEncadreur/DemandeEncadreurDetailComponent'
import RentreEncadrementComponentTrimestre from './component/Encadrement/RentreEncadrement/RentreEncadrementComponentTrimestre'
import ListesElevesParClassComponent from './component/Encadrement/RentreEncadrement/ListesElevesParClassComponent'
import AddMaterielEncandreurPage from './pages/Encadrement/AddMaterielEncandreurPage'
import RetournMaterielEncadrementComponentPerso from './component/Encadrement/MaterielEncadrement/RetournMaterielEncadrementComponentPerso'
import AjoutRefectoirePrefetDisplinPage from './pages/PrefetDisplin/AjoutRefectoirePrefetDisplinPage'
import ModifierRefectoirePrefetDisplinPage from './pages/PrefetDisplin/ModifierRefectoirePrefetDisplinPage'
import ParticulierConsultationPageListe from './pages/Infirmerie/ParticulierConsultationPageListe'
import ParticulierConsultationPageAjout from './pages/Infirmerie/ParticulierConsultationPageAjout'

function App() {
  const [isSecretaire, SetisSecretaire] = useState(true)
  const [isBiblio, SetisBiblio] = useState(false)
  const [isEncadrement, SetisEncadrement] = useState(false)
  const [isPrefetDisplin, SetisPrefetDisplin] = useState(false)
  const [isInfirmerie, SetisInfirmerie] = useState(false)


  const [iscantine, Setiscantine] = useState(false)
  const [isParent, SetParent] = useState(false)
  const [isEnseignat, SetisEnseignat] = useState(false)



  const [resume, SetResume] = useState(false)
  const [resumeQui, SetResumeQui] = useState('')
  const [empruntId, SetEmpruntId] = useState(0)
  const [LivreReturn, setLivreReturn] = useState([])
  const [exempalire, SetExemple] = useState(false)
  const [LivreId, SetLivreId] = useState(0)

  const [getSizeMobille, SetgetSizeMobille] = useState()
  const [getMenuMobille, SetgetMenuMobille] = useState()

  const GetMobileSize = (boole) => {
    SetgetSizeMobille(boole)
  }

  const GetshowMenu = (e) => {
    SetgetMenuMobille(e)
  }
  const getDivScroll = (e) => {
    SetgetMenuMobille(e)
  }


  return isEncadrement ? (
    <>
      <Router>
        <div className="w-full h-screen overflow-hidden">
          <NavBarsEncadrement
            getMenuMobille={getMenuMobille}
            GetMobileSize={GetMobileSize}
            GetshowMenu={GetshowMenu}
          />
          <div className="overflow-hidden">
            <Routes>
              <Route
                path="/"
                element={
                  <AcceuilEncadrementPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Presence"
                element={
                  <PresenceEncadrementPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Suiveur"
                element={
                  <SuiveurEncadrementPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Suiveur/consult/:schoolyearId/:classId"
                element={
                  <ConsultSuiveurEncadrementPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Suiveur/consult/Detail/:id/:schoolyearId/:classId"
                element={
                  <DetailConsultSuiveurEncadrementPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Suiveur/consult/Ajout/:id/:schoolyearId/:classId"
                element={
                  <AddFauteConsultSuiveurEncadrementPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />

              <Route
                path="/Rentre"
                element={
                  <RentreEncadrementComponentTrimestre
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Rentre/listEleves/:schoolyearId/:classId"
                element={
                  <ListesElevesParClassComponent
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />

              <Route path="/RentreB"
                element={
                  <RentreEncadrementPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Rentre/Detial/:id/:schoolyearId/:classId"
                element={
                  <DetailRentreEncadrementPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Rentre/Detial/Modifier/:idRenter/:id/:schoolyearId/:classId"
                element={
                  <ModifieRentreEncadrementPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Rentre/ajout/:id/:schoolyearId/:classId"
                element={
                  <AddRentreEncadrementPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />

              <Route
                path="/Materiel"
                element={
                  <MaterielEncadrementPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Materiel/EmpruntEleves"
                element={
                  <MaterielEncadrementEmpruntListeClassePage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Materiel/Ajouter"
                element={
                  <AddMaterielEncandreurPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Materiel/EmpruntElevesList/:schoolyearId/:classId"
                element={
                  <MaterielEncadrementEmpruntListeElevesPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Materiel/EmpruntListMateriel/:id/:schoolyearId/:classId"
                element={
                  <MaterielListeEncadrementEmpruntElevesPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Materiel/EmpruntListMateriel/Carts/:id/:schoolyearId/:classId"
                element={
                  <MaterielListeEncadrementEmpruntElevesAllPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />

              <Route
                path="/Materiel/EmpruntPersonne"
                element={
                  <MaterielEncadrementEmpruntPersonnePage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Materiel/EmpruntPersonneMateriel/:id"
                element={
                  <MaterielListeEncadrementEmpruntPersonnePage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Materiel/EmpruntPersonneMateriel/Carts/:id"
                element={
                  <MaterielListeEncadrementEmpruntPersonneAllPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />

              <Route
                path="/Materiel/Emprunt/Ajout"
                element={
                  <AddEmpruntMaterielEncadrementPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Materiel/Emprunt/Detail"
                element={
                  <DetailMaterielEncadrementPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Materiel/Emprunt/Modifier"
                element={
                  <ModifieEmpruntMaterielEncadrementPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Materiel/RetourEleves"
                element={
                  <RetournMaterielEncadrementPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Materiel/RetourPerso"
                element={
                  <RetournMaterielEncadrementComponentPerso
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />

              <Route
                path="/Horaire"
                element={
                  <EncadrementHorairePage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Represant"
                element={
                  <PresentantEncadrementPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Conge"
                element={
                  <CongeEncadreurPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Conge/Ajoute"
                element={
                  <AddCongeEncadreurPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Conge/detail/:id"
                element={
                  <DetailCongeEncadreurPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />

              <Route
                path="/Demande"
                element={
                  <DemandeEncadreurComponent
                    getMenuMobille={getMenuMobille}
                    getSizeMobille={getSizeMobille}
                  />
                }
              />
              <Route
                path="/Demande/Ajoute"
                element={
                  <AjouteDemandeEncadreurComponent
                    getMenuMobille={getMenuMobille}
                    getSizeMobille={getSizeMobille}
                  />
                }
              />
              <Route
                path="/Demande/Detail/:id"
                element={
                  <DemandeEncadreurDetailComponent
                    getMenuMobille={getMenuMobille}
                    getSizeMobille={getSizeMobille}
                  />
                }
              />

              <Route
                path="/Prevision"
                element={
                  <PrevisionEncadreurComponent
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Prevision/Ajouter"
                element={
                  <AddPrevisionEncadreurComponent
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Prevision/detail/:id"
                element={
                  <PrevisiondetailComponen
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Prevision/Modifier/:id"
                element={
                  <PrevisioModifierEncadreurComponent
                    getMenuMobille={getMenuMobille}
                    getSizeMobille={getSizeMobille}
                  />
                }
              />

              <Route
                path="/Depenses"
                element={
                  <DepenseEncadreurPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Depenses/AjouterDepens"
                element={
                  <AddDepenseEncadreurPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Depenses/Detail/:id"
                element={
                  <DetailDepenseEncadreurPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />

              <Route
                path="/Rapport"
                element={
                  <RapportEncadreursPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Rapport/ajout"
                element={
                  <RapportEncadreursAddRapportPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
            </Routes>
          </div>
        </div>
        <ToastContainer position="top-center" autoClose={3000} />
      </Router>
    </>
  ) : isPrefetDisplin ? (
    <>
      <Router>
        <div className="w-full h-screen overflow-hidden">
          <NavBarsPrefetDisplin
            getMenuMobille={getMenuMobille}
            GetMobileSize={GetMobileSize}
            GetshowMenu={GetshowMenu}
          />
          <div className="overflow-hidden">
            <Routes>
              <Route
                path="/"
                element={
                  <AcceuilPrefetDisplinPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Presence"
                element={
                  <PresencePrefetDisplinPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Suiveur"
                element={
                  <SuiveurPrefetDisplinPage
                    getMenuMobille={getMenuMobille}
                    getSizeMobille={getSizeMobille}
                  />
                }
              />
              <Route
                path="/Suiveur/consult/:schoolyearId/:classId"
                element={
                  <ListeSuiveurPrefetDisplinPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Suiveur/consult/Detail/:id/:schoolyearId/:classId"
                element={
                  <ListeSuiveurDetailPrefetDisplinPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Harite"
                element={
                  <HaritePrefetDisplinPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Encadreur"
                element={
                  <EncadeurPrefetDisplinPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Encadreur/List"
                element={
                  <HoraireEncaPreftDisplinPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Encadreur/List/Ajouter"
                element={
                  <AddHoraireEncaPreftDisplinPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Encadreur/List/Modifier/:id"
                element={
                  <ModeHoraireEncaPreftDisplinPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Encadreur/Rapport"
                element={
                  <RapportEncaderPrefetPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Salle"
                element={
                  <SalleClassePrefetDisplinPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Salle/ajoute"
                element={
                  <AddSallePrefDisplinPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Salle/Modifier/:id"
                element={
                  <ModSalleClassePrefetDisplinPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Refectoire"
                element={
                  <RefectoirePrefetDisplinPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Refectoire/Detail"
                element={
                  <DetailRefectoirePrefetDisplinPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Refectoire/Modifier/:id"
                element={
                  <ModifierRefectoirePrefetDisplinPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route path="/Refectoire/ajoute" element={<AjoutRefectoirePrefetDisplinPage getSizeMobille={getSizeMobille} getMenuMobille={getMenuMobille} />} />
              <Route
                path="/Materiel"
                element={
                  <MaterielPrefetDisplinPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Materiel/detail/:id"
                element={
                  <MaterielDetailPrefetDisplinPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Materiel/detail/Info/:id"
                element={
                  <InformaMaterielPrefetDisplinPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Materiel/Ajouter"
                element={
                  <AddMaterielPrefetDisplinPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Materiel/Modifier/:id"
                element={
                  <ModiMaterielPrefetDisplinPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Horaire"
                element={
                  <HorairePrefetDisplinPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Horaire/Ajout"
                element={
                  <AjoutHorairePrefetDisplinPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Horaire/Modifier/:jour/:id"
                element={
                  <ModiHorairePrefetDisplinPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Hopital"
                element={
                  <HostpotalisePrefetDisplinPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Dortoire"
                element={
                  <DortoirePrefetDisplinPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Dortoire/garcon/comparti"
                element={
                  <GarconCompartementDortoirePrefetDisplinPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Dortoire/garcon/comparti/Affich"
                element={
                  <DortoireAffichangePrefetDisplinPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Dortoire/garcon/comparti/detail"
                element={
                  <HomeGarconDetailDortoirePrefetDisplinPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Dortoire/ajout"
                element={
                  <AddDortoirePrefetDisplinPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Dortoire/fille/comparti"
                element={
                  <FilleCompartementDortoirePrefetDisplinPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Dortoire/fille/Affich"
                element={
                  <HomeFillDortoirePrefetDisplinPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Dortoire/fille/detail"
                element={
                  <HomeFillDetailDortoirePrefetDisplinPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Represant"
                element={
                  <RepreseantPredDisplinPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Represant/Ajout"
                element={
                  <AddRepreseantPredDisplinPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Represant/Modifie"
                element={
                  <ModifRepreseantPredDisplinPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Latrines"
                element={
                  <LatrinePreftDisplinPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Latrines/ajout"
                element={
                  <AddLatrinePreftDisplinPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Latrines/Modifier/:qui/:nombre"
                element={
                  <ModifieLatrinePreftDisplinPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Statistique/Displin"
                element={
                  <RapporDisplintPrefetDisplinPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Statistique"
                element={
                  <ChoixRapportPrefetDisplinPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Statistique/materiel"
                element={
                  <RappoMaterilePrefetDisplinPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Conge"
                element={
                  <CongePrefetDisplinPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Conge/Ajoute"
                element={
                  <AddCongePrefetDisplinPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Conge/detail/:id"
                element={
                  <DetailCongePrefetDisplinPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/demande"
                element={
                  <DemandePrefetDisplinPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/demande/ajout"
                element={
                  <AddDemandePrefetDisplinPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/demande/Detail/:id"
                element={
                  <DetailDemandePrefetDisplinPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Prevision"
                element={
                  <PrevisionPrefetDisplinPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Prevision/ajouter"
                element={
                  <AddPrevisionPrefetDisplinPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Prevision/Detail/:id"
                element={
                  <DetailPrevisionPrefetDisplinPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Prevision/Modifier"
                element={
                  <ModifierPrevisionPrefetDisplinPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Depenses"
                element={
                  <DepensePrefetDisplinPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Depenses/AjouterDepens"
                element={
                  <AddDepensePrefetDisplinPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Depenses/Detail/:id"
                element={
                  <DetailDepensePrefetDisplinPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
            </Routes>
          </div>
        </div>
        <ToastContainer position="top-center" autoClose={3000} />
      </Router>
    </>
  ) : isParent ? (
    <>
      <Router>
        <div className="  w-full h-screen overflow-hidden">
          <NavBarsParent
            getMenuMobille={getMenuMobille}
            GetMobileSize={GetMobileSize}
            GetshowMenu={GetshowMenu}
          />
          <div className="overflow-hidden">
            <Routes>
              <Route
                path="/"
                element={
                  <AcceuilParentPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/NoteEnt"
                element={
                  <NoteEnfPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/NoteEnt/ListeCours"
                element={
                  <ListeCoursParentPage
                    getMenuMobille={getMenuMobille}
                    getSizeMobille={getSizeMobille}
                  />
                }
              />
              <Route
                path="/NoteEnt/ListeCours/Evaluation"
                element={
                  <ListeEvaluationParentPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Notedispline"
                element={
                  <NoteDisplinePage
                    getMenuMobille={getMenuMobille}
                    getSizeMobille={getSizeMobille}
                  />
                }
              />
              <Route
                path="/NoteAllElev"
                element={
                  <NoteAllElevPage
                    getMenuMobille={getMenuMobille}
                    getSizeMobille={getSizeMobille}
                  />
                }
              />
              <Route
                path="/NoteAllElev/ListeCoursAll"
                element={
                  <ListeCoursAllParentPage
                    getMenuMobille={getMenuMobille}
                    getSizeMobille={getSizeMobille}
                  />
                }
              />
              <Route
                path="/NoteAllElev/ListeCoursAll/ListeEvaluationAll"
                element={
                  <ListeEvaluationAllParentPage
                    getMenuMobille={getMenuMobille}
                    getSizeMobille={getSizeMobille}
                  />
                }
              />
              <Route
                path="/Autor"
                element={
                  <Autor
                    getMenuMobille={getMenuMobille}
                    getSizeMobille={getSizeMobille}
                  />
                }
              />
            </Routes>
          </div>
        </div>
        <ToastContainer position="top-center" autoClose={3000} />
      </Router>
    </>
  ) : iscantine ? (
    <>
      <Router>
        <NavBArsCantin
          getMenuMobille={getMenuMobille}
          GetMobileSize={GetMobileSize}
          GetshowMenu={GetshowMenu}
        />
        <Routes>
          <Route path="/" Component={AcceuilCantinePage} />
          <Route path="/Achat" Component={AchatPage} />
          <Route path="/Achat/AjouteAchat" Component={AjouteAchatPaage} />
          <Route path="/Achat/DetailAchat" Component={DetailAchatPage} />
          <Route path="/Vente" Component={VentePage} />
          <Route path="/Vente/Ajoute" Component={AjouteVentePage} />
          <Route path="/Vente/Detail" Component={DetailVentePage} />
          <Route path="/Statistique" Component={StatistiquePage} />
          <Route path="/Compte" Component={ComptePage} />
        </Routes>
        <ToastContainer position="top-center" autoClose={3000} />
      </Router>
    </>
  ) : isEnseignat ? (
    <>
      <Router>
        <NavBarsEnseign
          getMenuMobille={getMenuMobille}
          GetMobileSize={GetMobileSize}
          GetshowMenu={GetshowMenu}
        />
        <Routes>
          <Route path="/" Component={AcceuilEnseingPage} />
          <Route path="/Presence" Component={PresenceEnsPage} />
          <Route path="/EnregistreNotes" Component={EnregistreNotesPage} />
          <Route
            path="/EnregistreNotes/ChoixEleve"
            Component={EnregistreNotesChoixClassPage}
          />
          <Route
            path="/EnregistreNotes/ChoixEleve/ModifierNotesEleve"
            Component={ModifierNotesElevePage}
          />
          <Route path="/Evaluation" Component={EvaluationPage} />
          <Route path="/Evaluation/Detail" Component={EvaluationDetailPage} />
          <Route path="/DemmandeEns" Component={DemmandeEnsePage} />
          <Route
            path="/DemmandeEns/AjouterDemmandeEns"
            Component={AjouterDemmandeEnsePage}
          />

          <Route path="/DepenseArgent" Component={DepenseArgentPage} />
          <Route
            path="/DepenseArgent/DetailDepenseEns"
            Component={DetailDepenseEnsPage}
          />
          <Route
            path="/DepenseArgent/AjouterDepenseEns"
            Component={AjouterDepenseEnsPage}
          />
          <Route
            path="/DepenseArgent/Detail"
            Component={DepenseArgentDetailPage}
          />
          <Route
            path="/PrevisionMatiersEns"
            Component={PrevisionMatiersEnsPage}
          />
          <Route
            path="/PrevisionMatiersEns/NeuwPrevision"
            Component={NeuwPrevisionMatiersEnsPage}
          />
          <Route path="/CoursEns" Component={CoursEnsPage} />
        </Routes>
        <ToastContainer position="top-center" autoClose={3000} />
      </Router>
    </>
  ) : isBiblio ? (
    <>
      {resume ? (
        <ResumeBiblio
          resumeQui={resumeQui}
          SetResume={SetResume}
          empruntId={empruntId}
          LivreReturn={LivreReturn}
          setLivreReturn={setLivreReturn}
        />
      ) : null}
      {exempalire ? (
        <DetailAjouteExemp
          SetExemple={SetExemple}
          LivreId={LivreId}
          SetLivreId={SetLivreId}
        />
      ) : null}
      <Router>
        <NavBarsBiblioth
          getMenuMobille={getMenuMobille}
          GetMobileSize={GetMobileSize}
          GetshowMenu={GetshowMenu}
        />
        <Routes>
          <Route
            path="/Accueil"
            element={
              <AccueilPage
                getMenuMobille={getMenuMobille}
                getSizeMobille={getSizeMobille}
              />
            }
          />
          <Route
            path="/Presence"
            element={
              <PresenceBlioPage
                getMenuMobille={getMenuMobille}
                getSizeMobille={getSizeMobille}
              />
            }
          />
          <Route
            path="/Livres"
            element={
              <LivresPage
                getMenuMobille={getMenuMobille}
                getSizeMobille={getSizeMobille}
              />
            }
          />
          <Route
            path="/Livres/Ajouter"
            element={
              <AjouterPage
                getMenuMobille={getMenuMobille}
                getSizeMobille={getSizeMobille}
              />
            }
          />
          <Route
            path="/Livres/DetailLivre/:id"
            element={
              <DetailLivrePage
                SetExemple={SetExemple}
                LivreId={LivreId}
                SetLivreId={SetLivreId}
                getMenuMobille={getMenuMobille}
                exempalire={exempalire}
                getSizeMobille={getSizeMobille}
              />
            }
          />

          <Route
            path="/Livres/DetailLivre/ModifierBiblio/:id"
            element={
              <ModifierBiblioPage
                getMenuMobille={getMenuMobille}
                getSizeMobille={getSizeMobille}
              />
            }
          />

          <Route
            path="/Emprunter"
            element={
              <EmprunterPage
                getMenuMobille={getMenuMobille}
                getSizeMobille={getSizeMobille}
              />
            }
          />
          <Route
            path="/Emprunter/eleve"
            element={
              <EmprunterElevePage
                getMenuMobille={getMenuMobille}
                getSizeMobille={getSizeMobille}
              />
            }
          />
          <Route
            path="/Emprunter/eleve/Liste/:schoolyearId/:classId"
            element={
              <EmprunterEleveListePage
                getMenuMobille={getMenuMobille}
                getSizeMobille={getSizeMobille}
              />
            }
          />
          <Route
            path="/Emprunter/eleve/Liste/Emprunt/:id"
            element={
              <ListeLivresPage
                getMenuMobille={getMenuMobille}
                getSizeMobille={getSizeMobille}
              />
            }
          />
          <Route
            path="/Emprunter/eleve/Liste/Emprunt/Carts/:id"
            element={
              <CartsElevePage
                getMenuMobille={getMenuMobille}
                getSizeMobille={getSizeMobille}
              />
            }
          />

          <Route
            path="/Emprunter/enseignant"
            element={
              <EmprunterEnseignantPage
                getMenuMobille={getMenuMobille}
                getSizeMobille={getSizeMobille}
              />
            }
          />
          <Route
            path="/Emprunter/enseignant/Listes/Livre/:id"
            element={
              <EmprunterEnseignantLivresPage
                getMenuMobille={getMenuMobille}
                getSizeMobille={getSizeMobille}
              />
            }
          />
          <Route
            path="/Emprunter/enseignant/Listes/Livre/Carts/:id"
            element={
              <CartEnseignPage
                getMenuMobille={getMenuMobille}
                getSizeMobille={getSizeMobille}
              />
            }
          />

          <Route
            path="/Emprunter/personnel"
            element={
              <EmprunterPersonnelPage
                getMenuMobille={getMenuMobille}
                getSizeMobille={getSizeMobille}
              />
            }
          />
          <Route
            path="/Emprunter/personnel/Livres/:id"
            element={
              <PersonnelLivresPage
                getMenuMobille={getMenuMobille}
                getSizeMobille={getSizeMobille}
              />
            }
          />
          <Route
            path="/Emprunter/personnel/Livres/Carts/:id"
            element={
              <PersonlivreCardPage
                getMenuMobille={getMenuMobille}
                getSizeMobille={getSizeMobille}
              />
            }
          />

          <Route
            path="/Emprunter/visitor" element={<EmprunterVisitorPage getMenuMobille={getMenuMobille} getSizeMobille={getSizeMobille}
            />
            }
          />
          <Route
            path="/Emprunter/visitor/Livre/:id"
            element={
              <EmpruntListLivreVisitorPage
                getMenuMobille={getMenuMobille}
                getSizeMobille={getSizeMobille}
              />
            }
          />
          <Route
            path="/Emprunter/visitor/Livre/Carts/:id"
            element={
              <EmpruntVisitorCartsPage
                getMenuMobille={getMenuMobille}
                getSizeMobille={getSizeMobille}
              />
            }
          />
          <Route
            path="/Emprunter/visitor/Ajoute"
            element={
              <AjouteVisiteurBiblioPage
                getMenuMobille={getMenuMobille}
                getSizeMobille={getSizeMobille}
              />
            }
          />

          <Route
            path="/Retour"
            element={
              <RetourLivresPage
                SetResumeQui={SetResumeQui}
                SetResume={SetResume}
                getMenuMobille={getMenuMobille}
                getSizeMobille={getSizeMobille}
              />
            }
          />
          <Route
            path="/Retour/Eleves"
            element={
              <RetourLivresElevesPage
                SetResumeQui={SetResumeQui}
                SetResume={SetResume}
                SetEmpruntId={SetEmpruntId}
                LivreReturn={LivreReturn}
                setLivreReturn={setLivreReturn}
                getMenuMobille={getMenuMobille}
                getSizeMobille={getSizeMobille}
              />
            }
          />
          <Route
            path="/Retour/Enseign"
            element={
              <RetourLivresEnseignPage
                SetResume={SetResume}
                getMenuMobille={getMenuMobille}
                getSizeMobille={getSizeMobille}
              />
            }
          />
          <Route
            path="/Retour/Person"
            element={
              <RetourLivresAutrePersonPage
                SetResumeQui={SetResumeQui}
                SetEmpruntId={SetEmpruntId}
                LivreReturn={LivreReturn}
                setLivreReturn={setLivreReturn}
                SetResume={SetResume}
                getMenuMobille={getMenuMobille}
                getSizeMobille={getSizeMobille}
              />
            }
          />
          <Route
            path="/Retour/Visiteur"
            element={
              <RetourLivresVisiteurPage
                SetResumeQui={SetResumeQui}
                SetEmpruntId={SetEmpruntId}
                LivreReturn={LivreReturn}
                setLivreReturn={setLivreReturn}
                SetResume={SetResume}
                getMenuMobille={getMenuMobille}
                getSizeMobille={getSizeMobille}
              />
            }
          />

          <Route
            path="/Conge"
            element={
              <CongeBibliothePage
                getMenuMobille={getMenuMobille}
                getSizeMobille={getSizeMobille}
              />
            }
          />
          <Route
            path="/Conge/Ajoute"
            element={
              <AjouteCongeBibliothePage
                getMenuMobille={getMenuMobille}
                getSizeMobille={getSizeMobille}
              />
            }
          />
          <Route
            path="/Conge/Detail/:id"
            element={
              <CongeBibliotheDetailPage
                getMenuMobille={getMenuMobille}
                getSizeMobille={getSizeMobille}
              />
            }
          />

          <Route
            path="/Demande"
            element={
              <DemandeBiblioPage
                getMenuMobille={getMenuMobille}
                getSizeMobille={getSizeMobille}
              />
            }
          />
          <Route
            path="/Demande/Ajoute"
            element={
              <AjouteDemandeBiblioPage
                getMenuMobille={getMenuMobille}
                getSizeMobille={getSizeMobille}
              />
            }
          />
          <Route
            path="/Demande/Detail/:id"
            element={
              <DemandeBiblioDetailPage
                getMenuMobille={getMenuMobille}
                getSizeMobille={getSizeMobille}
              />
            }
          />

          <Route
            path="/Stats"
            element={
              <StatsPage
                getMenuMobille={getMenuMobille}
                getSizeMobille={getSizeMobille}
              />
            }
          />
          <Route
            path="/Prevision"
            element={
              <PrevisionPage
                getMenuMobille={getMenuMobille}
                getSizeMobille={getSizeMobille}
              />
            }
          />
          <Route
            path="/Prevision/Ajoute"
            element={
              <AjouterPrevisionPage
                getMenuMobille={getMenuMobille}
                getSizeMobille={getSizeMobille}
              />
            }
          />
          <Route
            path="/Prevision/Detail/:id"
            element={
              <PrevisiondetailPage
                getMenuMobille={getMenuMobille}
                getSizeMobille={getSizeMobille}
              />
            }
          />
          <Route
            path="/Prevision/Modifier/:id"
            element={
              <PrevisioModifierPage
                getMenuMobille={getMenuMobille}
                getSizeMobille={getSizeMobille}
              />
            }
          />

          <Route
            path="/Depenses"
            element={
              <DepensesPage
                getMenuMobille={getMenuMobille}
                getSizeMobille={getSizeMobille}
              />
            }
          />
          <Route
            path="/Depenses/AjouterDepens"
            element={
              <AjouterDepenseBiblioPage
                getMenuMobille={getMenuMobille}
                getSizeMobille={getSizeMobille}
              />
            }
          />
          <Route
            path="/Depenses/Detail/:id"
            element={
              <DepenseBiblioDetailPage
                getMenuMobille={getMenuMobille}
                getSizeMobille={getSizeMobille}
              />
            }
          />

          <Route
            path="/Resumes"
            element={
              <ResumesBibliothequePage
                getMenuMobille={getMenuMobille}
                getSizeMobille={getSizeMobille}
              />
            }
          />
          <Route
            path="/Resumes/Detail/:num/:id"
            element={
              <ResumesBibliothequeDetailPage
                getMenuMobille={getMenuMobille}
                getSizeMobille={getSizeMobille}
              />
            }
          />

          <Route
            path="/"
            element={
              <AccueilPage
                getMenuMobille={getMenuMobille}
                getSizeMobille={getSizeMobille}
              />
            }
          />
          <Route
            path="*"
            element={
              <AccueilPage
                getMenuMobille={getMenuMobille}
                getSizeMobille={getSizeMobille}
              />
            }
          />
        </Routes>
        <ToastContainer position="top-center" autoClose={3000} />
      </Router>
    </>
  ) : isSecretaire ? (
    <>
      <Router>
        <div className="  w-full h-screen overflow-hidden">
          <NavBarSecretaire
            getMenuMobille={getMenuMobille}
            GetMobileSize={GetMobileSize}
            GetshowMenu={GetshowMenu}
          />
          <div className="overflow-hidden">
            <Routes>
              <Route
                path="/"
                element={
                  <AccueilSecretairePage
                    getDivScroll={getDivScroll}
                    getMenuMobille={getMenuMobille}
                    getSizeMobille={getSizeMobille}
                  />
                }
              />
              <Route
                path="/ClasseChoixSecretaire/DossierEleve/DetailDossier/:id/:schoolyearId/:classId"
                element={
                  <DetailDossierPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/ClasseChoixSecretaire"
                element={
                  <ClasseChoixSecretairePage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/ClasseChoixSecretaire/AjouterEleve"
                element={
                  <AjouterElevePage
                    getMenuMobille={getMenuMobille}
                    getSizeMobille={getSizeMobille}
                  />
                }
              />
              <Route
                path="/ClasseChoixSecretaire/DossierEleve/ModifierEleve/:id"
                element={
                  <ModifierElevePage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/DossierPersonnel/AjouterPersonnel"
                element={
                  <AjouterPersonnelPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Classeur"
                element={
                  <ClasseurPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Classeur/DocClasseur/:id"
                element={
                  <DocClasseurPage
                    getMenuMobille={getMenuMobille}
                    getSizeMobille={getSizeMobille}
                  />
                }
              />
              <Route
                path="/CongeAnnuel"
                element={
                  <CongeAnnuelPage
                    getMenuMobille={getMenuMobille}
                    getSizeMobille={getSizeMobille}
                  />
                }
              />
              <Route
                path="/CongeAnnuel/ajouter"
                element={
                  <AjouterCongeAnnuelSecretPage
                    getMenuMobille={getMenuMobille}
                    getSizeMobille={getSizeMobille}
                  />
                }
              />
              <Route
                path="/CongeAnnuel/CongeAnnuelDetail/:id"
                element={
                  <CongeAnnuelDetailPage
                    getMenuMobille={getMenuMobille}
                    getSizeMobille={getSizeMobille}
                  />
                }
              />
              <Route
                path="/DemandeReponse"
                element={
                  <DemandeReponsePage
                    getMenuMobille={getMenuMobille}
                    getSizeMobille={getSizeMobille}
                  />
                }
              />
              <Route
                path="/DemandeReponse/Ajouter"
                element={
                  <AjouterReponseSecretairePage
                    getMenuMobille={getMenuMobille}
                    getSizeMobille={getSizeMobille}
                  />
                }
              />
              <Route
                path="/DemandeReponse/AjouterListPerson"
                element={
                  <AjouteReponseListPersonnePage
                    getMenuMobille={getMenuMobille}
                    getSizeMobille={getSizeMobille}
                  />
                }
              />
              <Route
                path="/DemandeReponse/AjouterListPerson/AjoutExpli/:id"
                element={
                  <AjouteExpliChaquePersonPage
                    getMenuMobille={getMenuMobille}
                    getSizeMobille={getSizeMobille}
                  />
                }
              />

              <Route
                path="/DemandeReponse/DemandeReponseDetail/:id"
                element={
                  <DemandeReponseDetaillPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/ClasseChoixSecretaire/DossierEleve/:schoolyearId/:classId"
                element={
                  <DDossierEleveSecretairePage
                    getMenuMobille={getMenuMobille}
                    getSizeMobille={getSizeMobille}
                  />
                }
              />
              <Route
                path="/DossierPersonnel"
                element={
                  <DossierPersonnelPage
                    getMenuMobille={getMenuMobille}
                    getSizeMobille={getSizeMobille}
                  />
                }
              />
              <Route
                path="/DossierPersonnel/ModifierPersonnel/:id"
                element={
                  <ModifierPersonelPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/DossierPersonnel/DossierPers/:id"
                element={
                  <DetailDossierPersPage
                    getMenuMobille={getMenuMobille}
                    getSizeMobille={getSizeMobille}
                  />
                }
              />
              <Route
                path="/Courriers/ListeRecus"
                element={
                  <ListeRecusPage
                    getMenuMobille={getMenuMobille}
                    getSizeMobille={getSizeMobille}
                  />
                }
              />
              <Route
                path="/Courriers/ListeRecus/ModifierRecus/:id"
                element={
                  <ModifierRecusPage
                    getMenuMobille={getMenuMobille}
                    getSizeMobille={getSizeMobille}
                  />
                }
              />
              <Route
                path="/Courriers/AjouterRecus"
                element={
                  <AjouterRecusPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Courriers/ListeRecus/DetailRecus/:id"
                element={
                  <DetailRecusPage
                    getMenuMobille={getMenuMobille}
                    getSizeMobille={getSizeMobille}
                  />
                }
              />
              <Route
                path="/Courriers"
                element={
                  <CourriersPage
                    getMenuMobille={getMenuMobille}
                    getSizeMobille={getSizeMobille}
                  />
                }
              />

              <Route
                path="/Courriers/ListeExpedier"
                element={
                  <ListeExpedierPage
                    getMenuMobille={getMenuMobille}
                    getSizeMobille={getSizeMobille}
                  />
                }
              />
              <Route
                path="/Courriers/ListeExpedier/DetailExpedier/ModifierExpedier/:id"
                element={
                  <ModifierExpedierPage
                    getMenuMobille={getMenuMobille}
                    getSizeMobille={getSizeMobille}
                  />
                }
              />
              <Route
                path="/Courriers/AjouterLettreExpedier"
                element={
                  <AjouterExpedierPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/Courriers/ListeExpedier/DetailExpedier/:id"
                element={
                  <DetailExpedierPage
                    getMenuMobille={getMenuMobille}
                    getSizeMobille={getSizeMobille}
                  />
                }
              />

              <Route
                path="/Registres"
                element={
                  <RegistresPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/DetteBibli"
                element={
                  <DetteBibliothequePage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/DetteBibli/ChoixClasseDetteBibli"
                element={
                  <ChoixClasseDetteBiblioPage
                    getMenuMobille={getMenuMobille}
                    getSizeMobille={getSizeMobille}
                  />
                }
              />
              <Route
                path="/DetteBibli/ChoixClasseDetteBibli/ListDetteBiblio"
                element={
                  <ListDetteBiblioPage
                    getMenuMobille={getMenuMobille}
                    getSizeMobille={getSizeMobille}
                  />
                }
              />
              <Route
                path="/DetteBibli/ChoixClasseDetteBibli/ListDetteBiblio/detail"
                element={
                  <DetteBibliothequeDetailPage
                    getMenuMobille={getMenuMobille}
                    getSizeMobille={getSizeMobille}
                  />
                }
              />
              <Route
                path="/DetteEconome"
                element={
                  <DetteEconomePage
                    getMenuMobille={getMenuMobille}
                    getSizeMobille={getSizeMobille}
                  />
                }
              />
              <Route
                path="/DetteEconome/ListDetteEconom"
                element={
                  <ListDetteEconomPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/DetteEconome/ListDetteEconom/Detail"
                element={
                  <DetteEconomDetailPage
                    getSizeMobille={getSizeMobille}
                    getMenuMobille={getMenuMobille}
                  />
                }
              />
              <Route
                path="/DetteEconome/ChoixClasseEconom"
                element={
                  <ChoixClasseEconomPage
                    getMenuMobille={getMenuMobille}
                    getSizeMobille={getSizeMobille}
                  />
                }
              />
              <Route
                path="/Presence"
                element={
                  <PresenceSecraitrePage
                    getMenuMobille={getMenuMobille}
                    getSizeMobille={getSizeMobille}
                  />
                }
              />
            </Routes>
          </div>
        </div>
        <ToastContainer position="top-center" autoClose={3000} />
      </Router>
    </>
  ) : isInfirmerie ? (
    <Router>
      <div className="  w-full h-screen overflow-hidden">
        <NavBarInfirmier
          getMenuMobille={getMenuMobille}
          GetMobileSize={GetMobileSize}
          GetshowMenu={GetshowMenu}
        />
        <div className="overflow-hidden">
          <Routes>
            <Route
              path="/"
              element={
                <AccueilInfirmPage
                  getMenuMobille={getMenuMobille}
                  getSizeMobille={getSizeMobille}
                />
              }
            />

            <Route
              path="/Presence"
              element={
                <PresenceInfiemierPage
                  getMenuMobille={getMenuMobille}
                  getSizeMobille={getSizeMobille}
                />
              }
            />
            <Route
              path="/AccueilInfirmerie"
              element={
                <AccueilInfirmPage
                  getMenuMobille={getMenuMobille}
                  getSizeMobille={getSizeMobille}
                />
              }
            />
            <Route
              path="/Medicament"
              element={
                <MedicamentPage
                  getMenuMobille={getMenuMobille}
                  getSizeMobille={getSizeMobille}
                />
              }
            />
            <Route
              path="/Medicament/AjouterMedicament"
              element={
                <AjouterMedicamentPage
                  getMenuMobille={getMenuMobille}
                  getSizeMobille={getSizeMobille}
                />
              }
            />
            <Route
              path="/Medicament/Detail/:id"
              element={
                <MedicamDetailPage
                  getMenuMobille={getMenuMobille}
                  getSizeMobille={getSizeMobille}
                />
              }
            />
            <Route
              path="/Medicament/Modifier/:id"
              element={
                <MedicamentModifierPage
                  getMenuMobille={getMenuMobille}
                  getSizeMobille={getSizeMobille}
                />
              }
            />
            <Route
              path="/Consultation"
              element={
                <ConsultationPage
                  getMenuMobille={getMenuMobille}
                  getSizeMobille={getSizeMobille}
                />
              }
            />
            <Route
              path="/Consultation/Eleve"
              element={
                <EleveConsultationPage
                  getMenuMobille={getMenuMobille}
                  getSizeMobille={getSizeMobille}
                />
              }
            />
            <Route
              path="/Consultation/Personne"
              element={
                <PersonnelConsultationPage
                  getMenuMobille={getMenuMobille}
                  getSizeMobille={getSizeMobille}
                />
              }
            />
            <Route
              path="/Consultation/ParticulierListe"
              element={
                <ParticulierConsultationPageListe
                  getMenuMobille={getMenuMobille}
                  getSizeMobille={getSizeMobille}
                />
              }
            />
            <Route
              path="/Consultation/ParticulierAjout"
              element={
                <ParticulierConsultationPageAjout
                  getMenuMobille={getMenuMobille}
                  getSizeMobille={getSizeMobille}
                />
              }
            />
            <Route
              path="/Consultation/Particulier"
              element={
                <ParticulierConsultationPage
                  getMenuMobille={getMenuMobille}
                  getSizeMobille={getSizeMobille}
                />
              }
            />
            <Route
              path="/Traitement"
              element={
                <TraitementInfirmniePage
                  getMenuMobille={getMenuMobille}
                  getSizeMobille={getSizeMobille}
                />
              }
            />
            <Route
              path="/Traitement/Destrubuer"
              element={
                <DestrubuerTraitementInfirmniePage
                  getMenuMobille={getMenuMobille}
                  getSizeMobille={getSizeMobille}
                />
              }
            />
            <Route
              path="/Laboratoire"
              element={
                <LaboratoirePage
                  getMenuMobille={getMenuMobille}
                  getSizeMobille={getSizeMobille}
                />
              }
            />
            <Route
              path="/Laboratoire/Destrubuer"
              element={
                <LaboratoireDistriPage
                  getMenuMobille={getMenuMobille}
                  getSizeMobille={getSizeMobille}
                />
              }
            />
            <Route
              path="/Patient"
              element={
                <PatientPage
                  getMenuMobille={getMenuMobille}
                  getSizeMobille={getSizeMobille}
                />
              }
            />
            <Route
              path="/Patient/Detail"
              element={
                <PatientDetailPage
                  getMenuMobille={getMenuMobille}
                  getSizeMobille={getSizeMobille}
                />
              }
            />
            <Route
              path="/Expires"
              element={
                <MedicamentsExpiresPage
                  getMenuMobille={getMenuMobille}
                  getSizeMobille={getSizeMobille}
                />
              }
            />
            <Route
              path="/Depense"
              element={
                <DepensesInfirmeierPage
                  getMenuMobille={getMenuMobille}
                  getSizeMobille={getSizeMobille}
                />
              }
            />
            <Route
              path="/Depense/Ajouter"
              element={
                <AjouterDepensesInfirmeierPage
                  getMenuMobille={getMenuMobille}
                  getSizeMobille={getSizeMobille}
                />
              }
            />
            <Route
              path="/Depense/Detail/:id"
              element={
                <DepensesInfirmeierDetailPage
                  getMenuMobille={getMenuMobille}
                  getSizeMobille={getSizeMobille}
                />
              }
            />
            <Route
              path="/Prevision"
              element={
                <PrevisionInfirmierPage
                  getMenuMobille={getMenuMobille}
                  getSizeMobille={getSizeMobille}
                />
              }
            />
            <Route
              path="/Prevision/Ajoute"
              element={
                <AjouterPrevisionInfirmierPage
                  getMenuMobille={getMenuMobille}
                  getSizeMobille={getSizeMobille}
                />
              }
            />
            <Route
              path="/Prevision/Detail/:id"
              element={
                <PrevisionInfirmierDetailPage
                  getMenuMobille={getMenuMobille}
                  getSizeMobille={getSizeMobille}
                />
              }
            />
            <Route
              path="/Prevision/Modifier/:id"
              element={
                <PrevisionInfirmierModifierPage
                  getMenuMobille={getMenuMobille}
                  getSizeMobille={getSizeMobille}
                />
              }
            />
            <Route
              path="/Rapport"
              element={
                <RapportInfirmierPage
                  getMenuMobille={getMenuMobille}
                  getSizeMobille={getSizeMobille}
                />
              }
            />
            <Route
              path="/Conge"
              element={
                <CongeInfiermierPage
                  getMenuMobille={getMenuMobille}
                  getSizeMobille={getSizeMobille}
                />
              }
            />
            <Route
              path="/Conge/Ajoute"
              element={
                <AjouterCongeInfiermierPage
                  getMenuMobille={getMenuMobille}
                  getSizeMobille={getSizeMobille}
                />
              }
            />
            <Route
              path="/Conge/Detail/:id"
              element={
                <CongeInfiermierDetailPage
                  getMenuMobille={getMenuMobille}
                  getSizeMobille={getSizeMobille}
                />
              }
            />
          </Routes>
        </div>
      </div>
      <ToastContainer position="top-center" autoClose={3000} />
    </Router>
  ) : null
}

export default App
