import React from 'react'
import PrevisionMatiersEns from './PrevisionMatiersEns'
import SildeEnseign from '../SildeEnseign/SildeEnseign'
function PrevisionMatiersEnsComponent() {
  return (
    <div class="overflow-hidden flex justify-between">
      <SildeEnseign />
      <PrevisionMatiersEns />
    </div>)
}

export default PrevisionMatiersEnsComponent
