import React from 'react'
import ModifierBiblio from './ModifierBiblio'
import SideBarBibliote from '../SideBar/SideBarBibliote'
function ModifierBiblioComponnent({ getSizeMobille, getMenuMobille }) {
    return (
        <div className='flex justify-between bg-white overflow-hidden'>
            <SideBarBibliote getMenuMobille={getMenuMobille} />
            <ModifierBiblio getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default ModifierBiblioComponnent
