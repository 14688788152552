import React from 'react'
import AddPrevisionPrefetDisplin from './AddPrevisionPrefetDisplin'
import SideBarsPrefetDisplin from '../SideBarsPrefetDisplin/SideBarsPrefetDisplin'
function AddPrevisionPrefetDisplinComponent({ getSizeMobille, getMenuMobille }) {
    return (
        <div className='flex justify-between overflow-hidden bg-white'>
            <SideBarsPrefetDisplin getMenuMobille={getMenuMobille} />
            <AddPrevisionPrefetDisplin getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default AddPrevisionPrefetDisplinComponent
