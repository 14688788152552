import React, { useState } from "react";
import EmprunterEleveComponent from "../../component/Bibliotheque/Emprunter/EmprunterEleveComponent";
function EmprunterElevePage({getSizeMobille,getMenuMobille}) {
  return (
    <>
      <EmprunterEleveComponent getMenuMobille={getMenuMobille} getSizeMobille={getSizeMobille} />
    </>
  );
}

export default EmprunterElevePage;
