import React from 'react'
import SildeEnseign from '../SildeEnseign/SildeEnseign'
import DetailDepenseEns from './ModifierDepenseEns'
function DetailDepenseEnsComponent() {
    return (
        <div className='flex overflow-hidden pt-16 bg-white'>
            <SildeEnseign />
            <DetailDepenseEns />
        </div>
    )
}

export default DetailDepenseEnsComponent
