import React from 'react'
import SlideEncadrementNavBars from '../SlideEncadrementNavBars/SlideEncadrementNavBars'
import PrevisionEncadreur from './PrevisionEncadreur'
function PrevisionEncadreurComponent() {
    return (
        <div className='flex justify-between overflow-hidden bg-white'>
            <SlideEncadrementNavBars />
            <PrevisionEncadreur />
        </div>
    )
}

export default PrevisionEncadreurComponent
