import React from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import FootterCopy from "../../FootterCopy/FootterCopy";

function DetteEconomDetail({ getSizeMobille }) {

    return (
        <>
            <div className={`bg-white  overflow-y-auto  overflow-x-hidden  h-[85vh] ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>

                <Link to='/DetteEconome/ListDetteEconom' className="ml-2 text-[18px] text-blue-500 hover:text-blue-800 underline">Retour</Link>
                <div className=":sm:p-6 ">
                    <div className="sm:text-[25px] text-gray-700 font-bold ml-2">Les dossiers</div>

                    <div class="w-full my-2 grid ">
                        <div class="bg-white  rounded-lg p-2 sm:p-6 xl:p-8">
                            <div class=" rounded-lg bg-white border flex items-center w-full sm:w-max p-4 justify-center flex-col ">

                                <div class="flex flex-col justify-start sm:p-6">

                                    <p class="mb-4  text-xl text-neutral-600 ">
                                        Nom & Prenom : Jean Marie Vianney
                                    </p>

                                    <p class="mb-4  text-xl text-neutral-600 ">
                                        Frais non payé: 2 000 000
                                    </p>
                                    <div className=" mt-10 text-blue-900 text-[23px]">Les année scolaires</div>
                                    <div className="w-full font-semibold  flex justify-start">

                                        <div className="m-1">
                                            <div className="mt-3">Année scolaires de 2000-2001 : 150 000</div>
                                            <div className="mt-3">Année scolaires de 2010-2011 : 750 000</div>
                                            <div className="mt-3">Année scolaires de 2019-2022 : 150 000</div>
                                        </div>
                                    </div>
                                    <div class=" mt-8 flex items-center justify-end ">
                                        <div class="flex flex-row flex-shrink-0 space-x-3">

                                            <button

                                                class="mt-4 sm:mt-0 inline-flex items-start justify-start px-6 py-3 bg-red-700 hover:bg-red-600 focus:outline-none rounded-lg"
                                            >
                                                <p class="text-lg font-medium leading-none text-white">
                                                    Supprimer
                                                </p>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FootterCopy />
            </div>
        </>
    );
}

export default DetteEconomDetail;
