/* eslint-disable no-lone-blocks */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react';
import { MaterielPrefet } from '../../../Data/Data';
import FootterCopy from '../../FootterCopy/FootterCopy';
import ReactApexChart from 'react-apexcharts';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode } from 'swiper/modules';

function RappoMaterilePrefetDisplin({ getSizeMobille }) {
    const [mobile11, SetMobile11] = useState(window.innerWidth < 501);
    const [mobile12, SetMobile12] = useState(window.innerWidth < 1137);
    const [mobile13, SetMobile13] = useState(window.innerWidth < 420);
    const [mobile14, SetMobile14] = useState(window.innerWidth < 618);
    const [mobile15, SetMobile15] = useState(window.innerWidth < 1464);
    const [mobile16, SetMobile16] = useState(window.innerWidth < 900);
    const [mobile17, SetMobile17] = useState(window.innerWidth < 613);
    const [mobile18, SetMobile18] = useState(window.innerWidth < 358);

    useEffect(() => {
        const hundlesSize = () => {
            SetMobile11(window.innerWidth < 501);
            SetMobile12(window.innerWidth < 1137);
            SetMobile13(window.innerWidth < 420);
            SetMobile14(window.innerWidth < 618);
            SetMobile15(window.innerWidth < 1464);
            SetMobile16(window.innerWidth < 900);
            SetMobile17(window.innerWidth < 613);
            SetMobile18(window.innerWidth < 358);
        };

        window.addEventListener('resize', hundlesSize);

        return () => {
            window.removeEventListener('resize', hundlesSize);
        };
    }, []);

    MaterielPrefet.forEach((e) => {
        const manquants = e.nombre_total - e.disponible;
        const nonFonct = e.disponible - e.fonctionnel;
        e.no_fonctionnel = nonFonct;
        e.manquant = manquants;
    });

    MaterielPrefet.forEach((e) => {
        e.pourcentage = (e.Nombres_Eleves_fautes * 100 / e.Nombres_total_eleves_classe).toFixed(2);
    });

    const [searchTerm, setSearchTerm] = useState('');
    const [SelectTerm, getSelectTerm] = useState('disponible');

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value.trim());
    };


    const handleSearchSelect = (e) => {
        getSelectTerm(e.target.value);
    };

    const filteredData = MaterielPrefet.filter(data => {
        for (const key in data) {
            if (data.hasOwnProperty(key) && typeof data[key] === 'string') {
                if (data[key].toLowerCase().includes(searchTerm.toLowerCase())) {
                    return true;
                }
            }
        }
        return false;
    });

    SelectTerm === 'disponible' && filteredData.sort((a, b) => b.disponible - a.disponible);
    SelectTerm === 'manquant' && filteredData.sort((a, b) => b.manquant - a.manquant);
    SelectTerm === 'non fonctionnle' && filteredData.sort((a, b) => b.no_fonctionnel - a.no_fonctionnel);
    SelectTerm === 'fonctionnle' && filteredData.sort((a, b) => b.fonctionnel - a.fonctionnel);
    SelectTerm === 'emprunts' && filteredData.sort((a, b) => b.nombre_emprunt - a.nombre_emprunt);

    const sommeNombreTotal = MaterielPrefet.reduce((acc, materiel) => acc + materiel.nombre_total, 0);
    const sommeNombreFonctionnel = MaterielPrefet.reduce((acc, materiel) => acc + materiel.fonctionnel, 0);
    const sommeNombrenombre_emprunt = MaterielPrefet.reduce((acc, materiel) => acc + materiel.nombre_emprunt, 0);
    const sommeNombrmdisponible = MaterielPrefet.reduce((acc, materiel) => acc + materiel.disponible, 0);
    const sommeNombrmanquant = MaterielPrefet.reduce((acc, materiel) => acc + materiel.manquant, 0);
    const sommeNombrno_fonctionnel = MaterielPrefet.reduce((acc, materiel) => acc + materiel.no_fonctionnel, 0);



    return (
        <div className={`bg-white flex flex-col items-center overflow-y-auto overflow-x-hidden ${mobile11 ? 'h-[90vh]' : 'h-[85vh]'}   ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>
            <div className='w-full flex justify-start'>
                <Link to='/Statistique' className="ml-2 text-left text-[18px] text-blue-500 hover:text-blue-800 underline">Retour</Link>
            </div>
            <div className='sm:my-2  font-bold text-xl px-4 w-full text-start'>Statistique</div>
            <div className='w-full'>
                <div className='p-2 cursor-grab'>
                    <Swiper
                        freeMode={true}
                        slidesPerView={mobile18 ? 1 : mobile17 ? 2 : mobile16 ? 3 : mobile15 ? 4 : 5}
                        modules={[FreeMode]}
                    >
                        <SwiperSlide className='flex justify-center items-center'  >
                            <div className={`  ${mobile17 ? mobile18 : 'w-[12em] h-[5em]'}  ${mobile18 ? 'mr-0  w-[95%]' : 'w-[95%]'} flex justify-between m-1 rounded  border border-green-300 p-1 bg-[#00ff0015]`}>
                                <div className=''>
                                    <div className={`font-extrabold text-[#00A70E] ${mobile17 ? '' : 'text-3xl'} `}>{sommeNombrmdisponible}</div>
                                    <div className={`text-[#00A70E]  ${mobile17 ? 'text-[13px]' : ''}`}>Disponible</div>
                                </div>
                                <div className='ml-2 px-2 flex flex-col justify-between'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class={`bi bi-bag-check-fill text-[#00A70E]   ${mobile17 ? 'w-4 h-4' : 'w-6 h-6'} `} viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M10.5 3.5a2.5 2.5 0 0 0-5 0V4h5zm1 0V4H15v10a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4h3.5v-.5a3.5 3.5 0 1 1 7 0m-.646 5.354a.5.5 0 0 0-.708-.708L7.5 10.793 6.354 9.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0z" />
                                    </svg>
                                    <div className={` italic underline text-[#00A70E]  ${mobile17 ? '' : 'text-xl'} font-extrabold`}>
                                        {(sommeNombrmdisponible * 100 / sommeNombreTotal).toFixed(2)}%
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>



                        <SwiperSlide>
                            <div className={`  ${mobile17 ? mobile18 : 'w-[12em] h-[5em]'}  ${mobile18 ? 'mr-0  w-[95%]' : 'w-[95%]'} flex justify-between m-1 rounded  border border-green-300  border-[#88a82f49] p-1 bg-[#88a82f33]`}>
                                <div className=''>
                                    <div className={`font-extrabold text-[#88a82f] ${mobile17 ? '' : 'text-3xl'} `}>{sommeNombrmanquant}</div>
                                    <div className={`text-[#88a82f] ${mobile17 ? 'text-[13px]' : ''}`}>Indisponible</div>
                                </div>
                                <div className='ml-2 px-2 flex flex-col justify-between'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class={` text-[#00A70E]   ${mobile17 ? 'w-4 h-4' : 'w-6 h-6'} `} viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M10.5 3.5a2.5 2.5 0 0 0-5 0V4h5zm1 0V4H15v10a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4h3.5v-.5a3.5 3.5 0 1 1 7 0M6 9.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1z" />
                                    </svg>
                                    <div className={` italic underline text-[#88a82f]  ${mobile17 ? '' : 'text-xl'} font-extrabold`}>
                                        {(sommeNombrmanquant * 100 / sommeNombreTotal).toFixed(2)}%
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>





                        <SwiperSlide>
                            <div className={`  ${mobile17 ? mobile18 : 'w-[12em] h-[5em]'}  ${mobile18 ? 'mr-0  w-[95%]' : 'w-[95%]'} flex justify-between m-1 rounded  border   border-[#88a82f49] p-1 bg-blue-100`}>
                                <div className=''>
                                    <div className={`font-extrabold text-[#000EA3] ${mobile17 ? '' : 'text-3xl'} `}>{sommeNombreFonctionnel}</div>
                                    <div className={`text-[#000EA3] ${mobile17 ? 'text-[13px]' : ''}`}>Fontionnel</div>
                                </div>
                                <div className='ml-2 px-2 flex flex-col justify-between'>

                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class={` text-[#000EA3]  ${mobile17 ? 'w-4 h-4' : 'w-6 h-6'} `} viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M10.5 3.5a2.5 2.5 0 0 0-5 0V4h5zm1 0V4H15v10a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4h3.5v-.5a3.5 3.5 0 1 1 7 0m-.646 5.354a.5.5 0 0 0-.708-.708L7.5 10.793 6.354 9.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0z" />
                                    </svg>
                                    <div className={` italic underline text-[#000EA3] ${mobile17 ? '' : 'text-xl'} font-extrabold`}>
                                        {(sommeNombreFonctionnel * 100 / sommeNombrmdisponible).toFixed(2)}%
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={`  ${mobile17 ? mobile18 : 'w-[12em] h-[5em]'}  ${mobile18 ? 'mr-0  w-[95%]' : 'w-[95%]'} flex justify-between m-1 rounded  border   border-red-200 p-1 bg-red-100`}>
                                <div className=''>
                                    <div className={`font-extrabold text-[#C40707] ${mobile17 ? '' : 'text-3xl'} `}>{sommeNombrno_fonctionnel}</div>
                                    <div className={`text-[#C40707] ${mobile17 ? 'text-[13px]' : ''}`}>Dysfonctionnel</div>
                                </div>
                                <div className='ml-2 px-2 flex flex-col justify-between'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class={` text-[#C40707] ${mobile17 ? 'w-4 h-4' : 'w-6 h-6'} `} viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M10.5 3.5a2.5 2.5 0 0 0-5 0V4h5zm1 0V4H15v10a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4h3.5v-.5a3.5 3.5 0 1 1 7 0M6.854 8.146a.5.5 0 1 0-.708.708L7.293 10l-1.147 1.146a.5.5 0 0 0 .708.708L8 10.707l1.146 1.147a.5.5 0 0 0 .708-.708L8.707 10l1.147-1.146a.5.5 0 0 0-.708-.708L8 9.293z" />
                                    </svg>
                                    <div className={` italic underline text-[#C40707] ${mobile17 ? '' : 'text-xl'} font-extrabold`}>
                                        {(sommeNombrno_fonctionnel * 100 / sommeNombrmdisponible).toFixed(2)}%
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>




                        <SwiperSlide>
                            <div className={`  ${mobile17 ? mobile18 : 'w-[12em] h-[5em] ml-10'}  ${mobile18 ? 'mr-0  w-[95%]' : 'w-[95%]ml-5'}  flex justify-between m-1 rounded  border border-yellow-200 p-1 bg-yellow-100`}>
                                <div className=''>
                                    <div className={`font-extrabold text-yellow-700 ${mobile17 ? '' : 'text-3xl'} `}>{sommeNombrenombre_emprunt}</div>
                                    <div className={`text-yellow-700 ${mobile17 ? 'text-[13px]' : ''}`}>Emprunt</div>
                                </div>
                                <div className='ml-2 px-2 flex flex-col justify-between'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class={` text-yellow-700 ${mobile17 ? 'w-4 h-4' : 'w-6 h-6'} `} viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M10.5 3.5a2.5 2.5 0 0 0-5 0V4h5zm1 0V4H15v10a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4h3.5v-.5a3.5 3.5 0 1 1 7 0M6 9.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1z" />
                                    </svg>
                                    <div className={` italic underline text-yellow-700 ${mobile17 ? '' : 'text-xl'} font-extrabold`}>
                                        {(sommeNombrenombre_emprunt * 100 / sommeNombreFonctionnel).toFixed(2)}%
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
                <div class="flex items-end pr-4   w-full h-max  justify-between">
                    <div className='w-max'>
                        <div class="mx-2">
                            <div className='flex items-center'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi ml-1 mr-1 bi-funnel" viewBox="0 0 16 16">
                                    <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2z" />
                                </svg>
                                <div> filtrage</div>
                            </div>
                            <div class="relative lg:w-64">
                                <select
                                    id="topbar-search"
                                    class={`bg-white ${SelectTerm === 'disponible' ? 'text-green-600 ' : SelectTerm === 'manquant' ? 'text-[#88a82f]' : SelectTerm === 'fonctionnle' ? 'text-blue-600' : SelectTerm === 'non fonctionnle' ? 'text-red-600' : 'text-yellow-600'} border  border-gray-300 h-10  font-bold sm:text-lg first-letter:uppercase rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full pl-2`}
                                    value={SelectTerm}
                                    onChange={handleSearchSelect}
                                >
                                    <option className='text-green-600 font-bold' value='disponible'>Disponible</option>
                                    <option className='text-[#88a82f] font-bold' value='manquant'>Manquant</option>
                                    <option className='text-blue-600 font-bold' value='fonctionnle'>Fonctionnle</option>
                                    <option className='text-red-600 font-bold' value='non fonctionnle'> Non fonctionnle</option>
                                    <option className='text-yellow-600 font-bold' value='emprunts'>Emprunts</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className='flex  items-center' >
                        <div class="mx-2">
                            <div class="relative lg:w-64">
                                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <svg
                                        class="w-5 h-5 text-gray-500"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                            clip-rule="evenodd"
                                        ></path>
                                    </svg>
                                </div>
                                <input
                                    type="text"
                                    name="search"
                                    id="topbar-search"
                                    class="bg-white border border-gray-300 h-10 text-gray-900 sm:text-lg first-letter:uppercase rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full pl-10 .5"
                                    placeholder="Rechercher..."
                                    value={searchTerm}
                                    onChange={handleSearchChange}
                                />
                            </div>
                        </div>
                        <button class="mt-0 inline-flex items-start justify-start px-1 py-3 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg">
                            <p class="text-lg first-letter:uppercase font-semibold leading-none text-white">
                                ok
                            </p>
                        </button>
                    </div>
                </div>
                <div className='flex m-1 sm:m-2 justify-center w-[98%]  bg-gray-50 p-[2px] sm:p-1 rounded'>
                    <div className='flex flex-col  w-[98%] bg-slate-50'>
                        <div className='flex justify-center items-center border-b border-white pt-3 pb-3 font-bold sm:text-2xl max-sm:text-center'>Statistique des matériels</div>
                        <ApexChart filteredData={filteredData} mobile14={mobile14} searchTerm={searchTerm} mobile12={mobile12} mobile13={mobile13} />
                    </div>
                </div>
            </div>
            <FootterCopy />
        </div>
    );
}

export default RappoMaterilePrefetDisplin;

class ApexChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            series: [],
            colors: ['color_0', 'color_1', 'color_2', 'color_3', 'color_4', 'color_5'],
        };
    }

    componentDidMount() {
        this.updateSeries();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.filteredData !== this.props.filteredData) {
            this.updateSeries();
        }
    }

    updateSeries() {
        const { filteredData } = this.props;

        if (filteredData && filteredData.length > 0) {
            const resultArray = filteredData.map(item => {
                const total = item.nombre_total || 0;
                const disponible = item.disponible || 0;
                const manquant = item.manquant || 0;
                const fonctionnel = item.fonctionnel || 0;
                const no_fonctionnel = item.no_fonctionnel || 0;
                const nombre_emprunt = item.nombre_emprunt || 0;

                const pourcentageTotla = total;
                const pourcentageDisponible = (disponible * 100 / total).toFixed(2);
                const pourcentageManquant = (manquant * 100 / total).toFixed(2);
                const pourcentageFonctionnel = (fonctionnel * 100 / disponible).toFixed(2);
                const pourcentageNoFonctionnel = (no_fonctionnel * 100 / disponible).toFixed(2);
                const pourcentageEmprunt = (nombre_emprunt * 100 / fonctionnel).toFixed(2);

                return [
                    pourcentageTotla,
                    pourcentageDisponible,
                    pourcentageManquant,
                    pourcentageFonctionnel,
                    pourcentageNoFonctionnel,
                    pourcentageEmprunt
                ];
            });

            this.setState({
                series: resultArray,
            });
        } else {
            this.setState({
                series: [],
            });
        }
    }

    getColorCode = (colorName) => {
        const colorMap = {
            color_0: '#ffffff',
            color_1: '#00A70E',
            color_2: '#88a82f',
            color_3: '#000EA3',
            color_4: '#C40707',
            color_5: '#F9F102',
        };

        return colorMap[colorName] || '#99027C';
    };


    render() {
        const { searchTerm, filteredData, mobile12, mobile13, mobile14 } = this.props;
        const { series } = this.state;

        const options = {
            chart: {
                height: 350,
                type: 'radialBar',
            },
            plotOptions: {
                radialBar: {
                    dataLabels: {
                        name: {
                            fontSize: '22px',
                        },
                        value: {
                            fontSize: '16px',
                        },
                        total: {
                            show: true,
                            label: 'Total',
                            formatter: (val) => {
                                return val.config.series[0];
                            },
                        },
                    },
                },
            },
            labels: ['Total', 'Disponible', 'Indisponible', 'Fontionnel', 'Dysfonctionnel', 'Emprunt'],
            colors: this.state.colors.map((colorName) => this.getColorCode(colorName)),
        };

        return (
            <div className='flex flex-wrap'>
                {series.map((seriesData, posi) => (
                    seriesData &&
                    filteredData[posi] &&
                    <div key={posi} className={`p-1 m-2 flex  bg-white shadow-2xl  w-full items-center ${mobile12 ? 'flex-col' : ''} ${posi % 2 !== 0 ? 'flex-row-reverse' : 'flex-row'} `}>
                        <div className={` p-2  m-2 border ${mobile12 ? 'w-full mx-2 h-max' : 'w-1/2 h-[25em]'}  border-fuchsia-300 rounded-lg`}>
                            <div className='w-full text-center  text-fuchsia-600 font-bold text-xl'>{filteredData[posi].nom_materiel}</div>
                            <ReactApexChart
                                options={options}
                                series={seriesData}
                                type="radialBar"
                                height={mobile13 ? 280 : 400}
                            />
                        </div>

                        <div className={`bg-white   p-2 mx-2 my-3 ${mobile12 ? 'w-full mx-2 h-max' : 'w-1/2 h-[25em]'}   border  border-fuchsia-300 rounded-lg`}>
                            <div className={` flex ${mobile14 ? 'flex-col   gap-2 w-max' : 'justify-between  w-full'}`}>
                                <div className='flex items-center'>
                                    <div>Nom:</div>
                                    <div className='w-full text-center text-fuchsia-600 font-bold sm:text-xl mx-1'>{filteredData[posi].nom_materiel}</div>
                                </div>
                                <div className='flex items-center'>
                                    <div>Type:</div>
                                    <div className='w-full  text-fuchsia-600 font-bold sm:text-xl ml-1'>{filteredData[posi].type}</div>
                                </div>
                                <div className='flex items-center'>
                                    <div>Total:</div>
                                    <div className='w-full  text-fuchsia-600 font-bold sm:text-xl mx-1'>{filteredData[posi].nombre_total}</div>
                                </div>
                            </div>
                            <div className='mt-5'>
                                <div className='mt-2'>
                                    <div className={`text-lg ml-3 text-[green] `}> {filteredData[posi].disponible} disponible</div>
                                    <div className='flex items-center w-full'>
                                        <div className={` flex items-center w-[95%]`}>
                                            <div className={`h-2   w-full bg-green-200 relative overflow-hidden  ml-3  rounded-full`}>
                                                <div className={`bg-[green]  rounded-full absolute   h-full`} style={{ width: `${(filteredData[posi].disponible * 100 / filteredData[posi].nombre_total).toFixed(2)}%` }}></div>
                                            </div>
                                        </div>
                                        <div className={` font-bold ml-3 text-green-600 flex items-center  `}>
                                            <div>
                                                {(filteredData[posi].disponible * 100 / filteredData[posi].nombre_total).toFixed(2)}%
                                            </div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi ml-1 bi-graph-up-arrow" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M0 0h1v15h15v1H0zm10 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4.9l-3.613 4.417a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61L13.445 4H10.5a.5.5 0 0 1-.5-.5" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>

                                <div className='mt-2'>
                                    <div className={`text-lg ml-3 text-[#88a82f] `}> {filteredData[posi].nombre_total - filteredData[posi].disponible} manquants</div>
                                    <div className='flex items-center w-full'>
                                        <div className={` flex items-center w-[95%]`}>
                                            <div className={`h-2   w-full bg-[#88a82f60] relative overflow-hidden  ml-3  rounded-full`}>
                                                <div className={`bg-[#88a82f]  rounded-full absolute   h-full`} style={{ width: `${((filteredData[posi].nombre_total - filteredData[posi].disponible) * 100 / filteredData[posi].nombre_total).toFixed(2)}%` }}></div>
                                            </div>
                                        </div>
                                        <div className={` font-bold ml-3 text-[#88a82f] flex items-center  `}>
                                            <div>
                                                {((filteredData[posi].nombre_total - filteredData[posi].disponible) * 100 / filteredData[posi].nombre_total).toFixed(2)}%
                                            </div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-graph-down-arrow ml-1" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M0 0h1v15h15v1H0zm10 11.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 0-1 0v2.6l-3.613-4.417a.5.5 0 0 0-.74-.037L7.06 8.233 3.404 3.206a.5.5 0 0 0-.808.588l4 5.5a.5.5 0 0 0 .758.06l2.609-2.61L13.445 11H10.5a.5.5 0 0 0-.5.5" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>


                                <div className='mt-4'>
                                    <div className={`text-lg ml-3 text-[blue] `}> {filteredData[posi].fonctionnel} fonctionnles</div>
                                    <div className='flex items-center w-full'>
                                        <div className={` flex items-center w-[95%]`}>
                                            <div className={`h-2   w-full bg-blue-200 relative overflow-hidden  ml-3  rounded-full`}>
                                                <div className={`bg-[blue]  rounded-full absolute   h-full`} style={{ width: `${(filteredData[posi].fonctionnel * 100 / filteredData[posi].disponible).toFixed(2)}%` }}></div>
                                            </div>
                                        </div>
                                        <div className={` font-bold ml-3 text-[blue] flex items-center  `}>
                                            <div>
                                                {(filteredData[posi].fonctionnel * 100 / filteredData[posi].disponible).toFixed(2)}%
                                            </div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi ml-1 bi-graph-up-arrow" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M0 0h1v15h15v1H0zm10 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4.9l-3.613 4.417a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61L13.445 4H10.5a.5.5 0 0 1-.5-.5" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>


                                <div className='mt-4'>
                                    <div className={`text-lg ml-3 text-[red] `}> {filteredData[posi].no_fonctionnel} Dysfonctionnel</div>
                                    <div className='flex items-center w-full'>
                                        <div className={` flex items-center w-[95%]`}>
                                            <div className={`h-2   w-full bg-red-200 relative overflow-hidden  ml-3  rounded-full`}>
                                                <div className={`bg-[red]  rounded-full absolute   h-full`} style={{ width: `${20}%` }}></div>
                                            </div>
                                        </div>
                                        <div className={` font-bold ml-3 text-[red] flex items-center  `}>
                                            <div>
                                                {((filteredData[posi].disponible - filteredData[posi].fonctionnel) * 100 / filteredData[posi].disponible).toFixed(2)}%
                                            </div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-graph-down-arrow ml-1" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M0 0h1v15h15v1H0zm10 11.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 0-1 0v2.6l-3.613-4.417a.5.5 0 0 0-.74-.037L7.06 8.233 3.404 3.206a.5.5 0 0 0-.808.588l4 5.5a.5.5 0 0 0 .758.06l2.609-2.61L13.445 11H10.5a.5.5 0 0 0-.5.5" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className='mt-4'>
                                    <div className={`text-lg ml-3 text-yellow-500`}> {filteredData[posi].nombre_emprunt} emprunts</div>
                                    <div className='flex items-center w-full'>
                                        <div className={` flex items-center w-[95%]`}>
                                            <div className={`h-2   w-full bg-yellow-200 relative overflow-hidden  ml-3  rounded-full`}>
                                                <div className={`bg-yellow-500 rounded-full absolute   h-full`} style={{ width: `${(filteredData[posi].nombre_emprunt * 100 / filteredData[posi].fonctionnel).toFixed(2)}%` }}></div>
                                            </div>
                                        </div>
                                        <div className={`font-bold ml-3 text-yellow-500 flex items-center`}>
                                            <div>
                                                {(filteredData[posi].nombre_emprunt * 100 / filteredData[posi].fonctionnel).toFixed(2)}%
                                            </div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi ml-1 bi-graph-up-arrow" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M0 0h1v15h15v1H0zm10 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4.9l-3.613 4.417a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61L13.445 4H10.5a.5.5 0 0 1-.5-.5" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                {series.length === 0 && <div className='w-full h-[70vh] flex justify-center items-center text-gray-600 text-lg'>Aucune correspondance avec <span className='font-bold ml-3'> {searchTerm}</span> </div>}
            </div>
        );
    }
}


