import React from 'react'
import ListeSuiveurPrefetDisplin from './ListeSuiveurPrefetDisplin'
import SideBarsPrefetDisplin from '../SideBarsPrefetDisplin/SideBarsPrefetDisplin'
function ListeSuiveurPrefetDisplinCompoenent({ getSizeMobille, getMenuMobille }) {
    return (
        <div className='w-full justify-between flex bg-white'>
            <SideBarsPrefetDisplin getMenuMobille={getMenuMobille} />
            <ListeSuiveurPrefetDisplin getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default ListeSuiveurPrefetDisplinCompoenent
