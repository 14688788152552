import React from "react";
import SlideNavInfermie from "../SlideNavInfermie/SlideNavInfermie";
import AjouterPrevisionInfirmier from "./AjouterPrevisionInfirmier";
function AjouterPrevisionInfirmierComponent() {
  return (
    <>

      <div class="overflow-hidden flex justify-between">
        <SlideNavInfermie />
        <AjouterPrevisionInfirmier />
      </div>
    </>
  );
}

export default AjouterPrevisionInfirmierComponent;
