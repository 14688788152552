import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FootterCopy from "../../FootterCopy/FootterCopy";

function DortoirePrefetDisplin({ getSizeMobille }) {
    const [isMobile1, setIsMobile1] = useState(window.innerWidth < 990);
    const [isMobile2, setIsMobile2] = useState(window.innerWidth < 520);
    const [isMobile11, setIsMobile11] = useState(window.innerWidth < 501);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile1(window.innerWidth < 990);
            setIsMobile2(window.innerWidth < 520);
            setIsMobile11(window.innerWidth < 501);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            <div className={`bg-white overflow-y-auto overflow-x-hidden ${isMobile11 ? 'h-[90vh]' : 'h-[85vh]'} ${getSizeMobille ? 'w-full' : 'w-[85vw]'}`}>
                <div className="p-6 min-h-[90vh]">
                    <Link to="/Dortoire/ajout" className="font-bold text-gray-900 bg-blue-200 p-2 rounded-full cursor-pointer absolute right-11 top-25">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-circle-fill w-6 text-blue-700 h-6" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z" />
                        </svg>
                    </Link>
                    <div className="my-10 flex items-center justify-center">
                        <h3 className="text-xl sm:text-2xl text-center leading-none font-bold text-gray-900">
                            Votre choix
                        </h3>
                    </div>
                    <div className="my-4 w-full flex flex-col gap-4 items-center">
                        <Link to="/Dortoire/garcon/comparti/Affich" className="bg-white border border-fuchsia-300 w-[15em] rounded-lg p-4 flex justify-center items-center cursor-pointer hover:bg-gray-100 hover:shadow-lg transition duration-300 ease-in-out hover:scale-105 transform">
                            <div className="flex items-center">
                                <div className="flex-shrink-0">
                                    <h3 className="text-xl font-normal text-gray-700">
                                        Home garçon
                                    </h3>
                                </div>
                            </div>
                        </Link>
                        <Link to="/Dortoire/fille/Affich" className="bg-white border w-[15em] border-fuchsia-300 rounded-lg p-4 flex justify-center items-center cursor-pointer hover:bg-gray-100 hover:shadow-lg transition duration-300 ease-in-out hover:scale-105 transform">
                            <div className="flex items-center">
                                <div className="flex-shrink-0">
                                    <h3 className="text-xl font-normal text-gray-700">
                                        Home fille
                                    </h3>
                                </div>
                            </div>
                        </Link>
                    </div>

                </div>
                <FootterCopy />
            </div>
        </>
    );
}

export default DortoirePrefetDisplin;
