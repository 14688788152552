import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { API, STATIC_URL } from "../../../Api/API";
import { decryptData } from "../../../encryptionModule";
import FootterCopy from "../../FootterCopy/FootterCopy";
import { toast } from "react-toastify";
function DemandeEncadreurDetail({ getSizeMobille }) {
    const [reponse, Getrerepose] = useState(false)
    const [reponse1, Getrerepose1] = useState(false)
    const [reponse2, Getrerepose2] = useState(false)
    const [formul, GetFormul] = useState(false)
    const history = useNavigate()
    const [hiddenButton, setHiddenButton] = useState(true)

    const { id } = useParams()
    const [mobile1, Setmonile1] = useState(window.innerWidth < 349)
    const [mobile11, SetMobile11] = useState(window.innerWidth < 501)
    const demandeexpricationId = decryptData(id)
    useEffect(() => {
        const hundlesSize = () => {
            Setmonile1(window.innerWidth < 349)
            SetMobile11(window.innerWidth < 501)
        }
        window.addEventListener('resize', hundlesSize)

        return () => {
            window.removeEventListener('resize', hundlesSize)
        }
    }, [])


    const [Motif, SetMotif] = useState('')
    const [animationClassMotif, setAnimationClassMotif] = useState('');
    const elemetRefMotif = useRef(null)


    const [photosReponse, GetPhotosReponse] = useState(null)
    const [animationClassPhotosReponse, setAnimationClassPhotosReponse] = useState('');
    const elementPhotosReponse = useRef(null)


    useEffect(() => {
        if (elemetRefMotif.current) {
            elemetRefMotif.current.style.height = '7em';
            elemetRefMotif.current.style.height = `${elemetRefMotif.current.scrollHeight}px`;
            elemetRefMotif.current.scrollTop = elemetRefMotif.current.scrollHeight;
        }

    }, [Motif]);



    const [demandeexprication, setDemandeexprication] = useState({})
    const [classeurId, SetClasseurId] = useState("")
    useEffect(() => {
        API.get(`/staff/demandeexprications/${demandeexpricationId}`).then((response) => {
            setDemandeexprication(response.data)
            const data = response.data
            const data1 = data.status
            if (data && data1 == "En attente") {
                GetFormul(true)
            } const claaseur = data.classeurId
            if (data && claaseur) {
                SetClasseurId(claaseur)
            }
        }).catch((err) => {
            console.log(err.message)
        })
    }, [id])


    function formaterDate(dateString) {
        const months = [
            "janvier", "février", "mars", "avril", "mai", "juin",
            "juillet", "août", "septembre", "octobre", "novembre", "décembre"
        ];
        const date = new Date(dateString);
        const jour = date.getDate();
        const mois = months[date.getMonth()];
        const annee = date.getFullYear();

        return `Le ${jour}, ${mois} ${annee}`;
    }


    const hundleSbumit = (e) => {
        e.preventDefault();
        if (elemetRefMotif.current && Motif.trim() == '') {
            toast.warning('Laisse votre justification', {
                autoClose: 2000
            });
            setAnimationClassMotif('animate__animated animate__shakeX border-2 border-red-500')
            setTimeout(() => {
                setAnimationClassMotif(' ')
            }, 3000)
            elemetRefMotif.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            elemetRefMotif.current.focus()
            return false
        } else if (photosReponse == null) {
            toast.warning('Laisse photos de justification', {
                autoClose: 2000
            });
            setAnimationClassPhotosReponse('animate__animated animate__shakeX border-2 border-red-500')
            setTimeout(() => {
                setAnimationClassPhotosReponse(' ')
            }, 3000)
            elementPhotosReponse.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            elementPhotosReponse.current.focus()
            return false
        }
        // GetFormul(false)
        const formData = new FormData()

        formData.append("LettrePhoto", photosReponse)
        formData.append("classeurId", classeurId)
        formData.append("demandeexpricationId", demandeexpricationId)
        formData.append("Objectif", Motif)
        API.post("/staff/repdemandeexprication", formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }).then((res) => {
            toast.success(res.data)
            history("/Demande")
        }).catch((error) => {
            if (error.response) {
                // Le serveur a répondu avec un code d'erreur (status code)
                if (Array.isArray(error.response.data)) {
                    // Si c'est un tableau, afficher le premier élément
                    toast.warn(error.response.data[0]);
                } else if (typeof error.response.data === 'object') {
                    // S'il s'agit d'un objet, afficher la première valeur de la propriété
                    const keys = Object.keys(error.response.data);
                    if (keys.length > 0) {
                        toast.warn(error.response.data[keys[0]]);
                    }
                } else if (typeof error.response.data === 'string') {
                    // Si c'est une chaîne de caractères, l'afficher directement
                    toast.warn(error.response.data);
                } else {
                    // Autre type non pris en charge
                    toast.warn("Erreur du serveur")

                }
                // Afficher les erreurs au sein de votre composant ou effectuer une action en conséquence
            } else if (error.request) {
                // La requête a été faite mais aucune réponse n'a été reçue
                console.error('Pas de réponse du serveur :', error.request);
            } else {
                // Une erreur s'est produite lors de la configuration de la requête
                console.error('Erreur lors de la requête :', error.message);
            }
        })


    }

    return (
        <>
            <>
                <div className={`bg-white  overflow-x-auto  h-[85vh] ${getSizeMobille ? 'w-full ' : 'w-[85vw]'}`}>

                    <Link to='/Demande' className="ml-2 text-[18px] text-blue-500 hover:text-blue-800 underline">Retour</Link>
                    <div className="sm:p-6 p-2 ">
                        <div className="sm:text-[25px] text-gray-700 font-bold">Demande d'Explication/Reponse</div>

                        <div class="w-full my-2 grid grid-cols-1 ">
                            <div class="bg-white  ">
                                <div class="w-full my-2 grid grid-cols-1 ">
                                    <div class=" w-full  rounded-lg p-2 sm:p-6 xl:p-8">
                                        <div class=" border w-full border-blue-200 rounded-lg   p-3 flex flex-col items-center justify-center  ">


                                            {demandeexprication && demandeexprication.staff && demandeexprication.staff.Profile && (
                                                <div className="max-w-[15em] max-h-[15em] rounded-xl overflow-hidden">
                                                    <img
                                                        src={`${STATIC_URL}/personnel/${demandeexprication.staff.Profile}`}
                                                        alt=" "
                                                        className="w-full h-full object-cover"
                                                    />
                                                </div>
                                            )}
                                            <div class="flex flex-col w-full justify-start p-6">
                                                <p class="mb-4  text-xl text-neutral-600 ">
                                                    Nom & Prenom : {demandeexprication && demandeexprication.staff && demandeexprication.staff.nomcomplet}
                                                </p>
                                                <p class="mb-4  text-xl text-neutral-600 ">
                                                    Matricule :{demandeexprication && demandeexprication.staff && demandeexprication.staff.matricule}
                                                </p>
                                                <p class="mb-4  text-xl text-neutral-600 ">
                                                    Status : {demandeexprication && demandeexprication.status}
                                                </p>
                                            </div>

                                            <div className="p-2 mt-10 w-full ">
                                                <div className="text-xl mb-4 text-blue-600">
                                                    Votre demande
                                                </div>
                                                <div>
                                                    <div>
                                                        <p className="underline font-bold text-xl">Objectif:</p>
                                                        <p className="text-gray-500 text-[1rem] ">

                                                            {demandeexprication && demandeexprication.Objectif}

                                                        </p>
                                                    </div>
                                                    <div className="mt-4">
                                                        <div>Photos de  votre demande</div>
                                                        {demandeexprication && demandeexprication.LettrePhoto && (
                                                            <div onClick={() => { Getrerepose(true); }} className="w-[10em] h-[10em] border rounded-xl mb-5 overflow-hidden">
                                                                <img src={`${STATIC_URL}/personnel/${demandeexprication.LettrePhoto}`} draggable='false' className="w-full h-full object-contain bg-white" />
                                                            </div>
                                                        )}

                                                        {reponse &&
                                                            <div className="fixed w-full h-full top-0 left-0 bg-[#0000008f] z-[900] flex justify-center pt-2">

                                                                <div className="w-[95%] h-[95vh]  bg-white rounded-xl relative overflow-hidden">
                                                                    <div className="absolute z-[1000] flex justify-end w-full pr-3 pt-3">
                                                                        <div onClick={(e) => { Getrerepose(false); e.stopPropagation() }} className="bg-red-500 transition-all hover:bg-red-700 cursor-pointer text-white p-2 rounded-full"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle" viewBox="0 0 16 16">
                                                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                                                                        </svg></div>
                                                                    </div>
                                                                    <img src={`${STATIC_URL}/personnel/${demandeexprication.LettrePhoto}`} draggable='false' className="w-full h-full object-contain bg-white" />
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                            {demandeexprication && demandeexprication.repdemandeexprication && (
                                                <div className="p-2 mt-10 w-full ">
                                                    <div className="text-xl mb-4 text-blue-600">
                                                        La reponse
                                                    </div>
                                                    <div>
                                                        <div>
                                                            <p className="underline font-bold text-xl">Objectif:</p>
                                                            <p className="text-gray-500 text-[1rem] ">


                                                                {demandeexprication.repdemandeexprication && demandeexprication.repdemandeexprication.Objectif}
                                                            </p>
                                                        </div>
                                                        <div className="mt-10 border cursor-pointer p-10">
                                                            <div>Photos de reponse</div>
                                                            <div onClick={() => Getrerepose1(true)} className="w-[10em] h-[10em] bg-green-700 rounded-xl mb-5 overflow-hidden">
                                                                <img src={`${STATIC_URL}/personnel/${demandeexprication.repdemandeexprication.LettrePhoto}`} draggable='false' className="w-full h-full object-contain bg-white" />
                                                            </div>
                                                            {reponse1 &&
                                                                <div className="fixed w-full h-full top-0 left-0 bg-[#0000008f] z-[900] flex justify-center pt-2">
                                                                    <div className="w-[95%] h-[95vh]  bg-white rounded-xl relative overflow-hidden">
                                                                        <div className="absolute z-[1000] flex justify-end w-full pr-3 pt-3">
                                                                            <div onClick={(e) => { Getrerepose1(false); e.stopPropagation() }} className="bg-red-500 transition-all hover:bg-red-700 cursor-pointer text-white p-2 rounded-full"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle" viewBox="0 0 16 16">
                                                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                                                                            </svg></div>
                                                                        </div>
                                                                        <img src={`${STATIC_URL}/personnel/${demandeexprication.repdemandeexprication.LettrePhoto}`} draggable='false' className="w-full h-full object-contain bg-white" />
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )}


                                            {/* hiddenButton && <div className="w-full">
                                                <button onClick={() => { GetFormul(true); setHiddenButton(false) }} className="bg-blue-600 rounded mt-3 px-4 py-1 text-white">
                                                    Votre  justification
                                                </button>
                                            </div>
                                            */}
                                            {formul &&
                                                <form onSubmit={hundleSbumit} class={`w-full  mb-6`}>
                                                    <div class={`w-full`}>
                                                        <label
                                                            class="block  tracking-wide  text-lg font-bold mb-2"
                                                            for="grid-last-name"
                                                        >
                                                            Votre justification
                                                        </label>
                                                        <textarea
                                                            ref={elemetRefMotif}
                                                            value={Motif}
                                                            onInput={(e) => SetMotif(e.target.value)}
                                                            className={`appearance-none resize-none outline-none block w-full min-h-[7em] bg-grey-lighter border border-gray-400 rounded p-2 ${Motif.trim() === "" ? animationClassMotif : "focus:border-2 focus:border-blue-700"
                                                                }`}
                                                            id="grid-last-name"
                                                            rows={1}
                                                            placeholder=" Votre justification.."
                                                        ></textarea>
                                                    </div>
                                                    <div className="mt-5 ">
                                                        <label htmlFor="reponse" ref={elementPhotosReponse} className={` h-full cursor-pointer w-full border ${animationClassPhotosReponse} border-gray-300 rounded-lg p-3`} >
                                                            <input type="file" onChange={(e) => GetPhotosReponse(e.target.files[0])} id="reponse" hidden accept="image/*" />
                                                            Photos de reponse
                                                        </label>
                                                    </div>
                                                    {!photosReponse == '' &&
                                                        <div onClick={() => Getrerepose2(true)} className="w-[10em] h-[10em] cursor-pointer border rounded-xl mt-5 overflow-hidden">
                                                            <img src={URL.createObjectURL(photosReponse)} draggable='false' className="w-full h-full object-contain bg-white" />
                                                        </div>
                                                    }
                                                    <button type="submit" className="bg-blue-600 rounded mt-5 px-4 py-1 text-white">
                                                        Envoyer
                                                    </button>
                                                </form>
                                            }
                                        </div>
                                        {reponse2 &&
                                            <div className="fixed w-full h-full top-0 left-0 bg-[#0000008f] z-[900] flex justify-center pt-2">
                                                <div className="w-[95%] h-[95vh]  bg-white rounded-xl relative overflow-hidden">
                                                    <div className="absolute z-[1000] flex justify-end w-full pr-3 pt-3">
                                                        <div onClick={(e) => { Getrerepose2(false); e.stopPropagation() }} className="bg-red-500 transition-all hover:bg-red-700 cursor-pointer text-white p-2 rounded-full"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle" viewBox="0 0 16 16">
                                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                                                        </svg></div>
                                                    </div>
                                                    <img src={photosReponse} draggable='false' className="w-full h-full object-contain bg-white" />
                                                </div>
                                            </div>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <FootterCopy />
                </div>
            </>
        </>
    );
}

export default DemandeEncadreurDetail;
