import React from 'react'
import DetteBibliotheque from './DetteBibliotheque'
import SideBarSecretaire from '../SideBarSecretaire/SideBarSecretaire'
function DetteBibliothequeComponent({ getMenuMobille, getSizeMobille }) {
    return (
        <div className='flex justify-between overflow-hidden bg-white'>
            <SideBarSecretaire getMenuMobille={getMenuMobille} />
            <DetteBibliotheque getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default DetteBibliothequeComponent
