import React, { useState, useRef } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { encryptData } from "../../../encryptionModule";
import { API } from '../../../Api/API';

const DetailAjouteExemp = ({ SetExemple, LivreId, SetLivreId }) => {

    const [exemplaires, setExemplaires] = useState([]);
    const inputRefs = useRef([]);

    const handleAjouterExemplaire = () => {
        const nouvelExemplaire = {
            id: exemplaires.length + 1,
            valeur: ''
        };

        setExemplaires([...exemplaires, nouvelExemplaire]);
    };

    const handleValeurChange = (e, index) => {
        const nouvellesValeurs = [...exemplaires];
        nouvellesValeurs[index].valeur = e.target.value;
        setExemplaires(nouvellesValeurs);
    };

    const handleSupprimerExemplaire = (index) => {
        const nouvellesValeurs = exemplaires.filter((exemplaire, idx) => idx !== index);
        setExemplaires(nouvellesValeurs);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Validation des valeurs vides
        const exemplairesVides = exemplaires.reduce((acc, exemplaire, index) => {
            if (exemplaire.valeur === '') {
                acc.push(index + 1);
            }
            return acc;
        }, []);

        if (exemplairesVides.length > 0) {
            const firstEmptyInputRef = inputRefs.current[exemplairesVides[0] - 1];
            toast.warning(`Completer le champ ou le supprimer Exemplaire ${exemplairesVides[0]}`, {
                autoClose: 2000
            });
            firstEmptyInputRef.scrollIntoView({ behavior: 'smooth', block: 'center' });
            firstEmptyInputRef.focus();
            return;
        }

        const data = exemplaires.reduce((acc, exemplaire) => {
            if (exemplaire.valeur) {
                acc.push({ numero: exemplaire.valeur });
            }
            return acc;
        }, []);
        console.log('Données:', data);



        const formData = {
            LivreId: LivreId,
            exemplairelivres: data
        }

        API.post("/livres/AjoutExempleLivres", formData)
            .then((res) => {
                SetExemple(false)
                toast.success('Enregistrement avec succes')
            })
            .catch((err) => {
                if (err.response) {
                    if (Array.isArray(err.response.data)) {
                        toast.warn(err.response.data[0])
                    } else if (typeof err.response.data === 'object') {
                        // Si c'est un objet, afficher la valeur (par exemple, en la convertissant en chaîne JSON)
                        toast.warn(JSON.stringify(err.response.data))
                    } else if (typeof err.response.data === 'string') {
                        // Si c'est une chaîne de caractères, l'afficher directement
                        toast.warn(err.response.data)
                    } else {
                        // Si c'est autre chose, afficher un message par défaut
                        toast.warn('Erreur inattendue lors de la récupération des données de réponse')
                    }
                    return false;
                }
                console.log(err.message)
            })
            .finally(() => {
                console.log("")
            });
    };

    return (
        <>


            <div className='w-full h-full fixed top-0 left-0 bg-[#0000007c] flex justify-center items-start overflow-y-auto '>
                <div className='sm:w-[40em]  w-[90%]  overflow-hidden relative my-10 bg-white rounded-2xl'>
                    <div onClick={() => { SetExemple(false) }} className="p-3 bg-red-600 cursor-pointer absolute right-0 w-max flex justify-end">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle text-white" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                        </svg>
                    </div>
                    <div class="bg-white  rounded px-8 pt-6 pb-8 mb-4 flex flex-col my-2">


                        <div class="mb-4 flex items-center justify-start">
                            <div class="">
                                <button
                                    className="mt-4 sm:mt-0 inline-flex items-start justify-start px-6 py-3 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg"
                                    onClick={handleAjouterExemplaire}
                                >
                                    <p className="text-lg font-medium leading-none text-white">
                                        Ajouter un Exemplaire
                                    </p>
                                </button>
                            </div>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div class="-mx-3 md:flex flex-col mb-6">



                                {exemplaires.map((exemplaire, index) => {
                                    return (
                                        <div class="md:w-1/2 px-3 mb-6 md:mb-0">
                                            <label
                                                class="block uppercase tracking-wide text-grey-darker text-lg font-bold mb-2"
                                                for="grid-Title"
                                            >
                                                Exemplaire {index + 1}

                                            </label>
                                            <div class="flex flex-row space-x-3">
                                                <input
                                                    className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-red rounded py-3 px-4 mb-3"

                                                    id={`exemplaire-${exemplaire.id}`}
                                                    type="text"
                                                    value={exemplaire.valeur}
                                                    onChange={(e) => handleValeurChange(e, index)}
                                                    ref={(ref) => (inputRefs.current[index] = ref)}
                                                    placeholder="Prefixe de l'exemplaire"
                                                />
                                                <div class="flex items-center justify-center">
                                                    <div
                                                        onClick={() => handleSupprimerExemplaire(index)}
                                                        class="w-8 h-8 cursor-pointer bg-gray-100 mb-4 hover:bg-gray-200 p-1 rounded-xl  "
                                                    >
                                                        {" "}
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            stroke-width="1.5"
                                                            stroke="currentColor"
                                                            class="w-6 h-6  mb-3  text-blue-500"
                                                        >
                                                            <path
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                d="M6 18L18 6M6 6l12 12"
                                                            />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>

                            {exemplaires.length !== 0 && <div class="mb-4 flex items-center justify-end">
                                <div class="">
                                    <button class="mt-4 sm:mt-0 inline-flex items-start justify-start px-2 sm:px-6 py-3 bg-blue-700 hover:bg-blue-600 focus:outline-none rounded-lg">
                                        <p class="text-lg font-medium leading-none text-white">
                                            Ajouter
                                        </p>
                                    </button>
                                </div>
                            </div>
                            }
                        </form>
                    </div>
                </div>
            </div>

        </>
    );
};

export default DetailAjouteExemp;





