import React from 'react'
import PresenceEncadrement from './PresenceEncadrement'
import SlideEncadrementNavBars from '../SlideEncadrementNavBars/SlideEncadrementNavBars'
function PresenceEncadrementComponent({ getSizeMobille, getMenuMobille }) {
    return (
        <div className='flex justify-between overflow-hidden bg-white'>
            <SlideEncadrementNavBars getMenuMobille={getMenuMobille} />
            <PresenceEncadrement getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default PresenceEncadrementComponent
