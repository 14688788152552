import React from 'react'
import SideBarBibliote from '../SideBar/SideBarBibliote'
import EmpruntListLivreVisitor from './EmpruntListLivreVisitor'
function EmpruntListLivreVisitorComponent({ getSizeMobille, getMenuMobille }) {
    return (
        <div className='flex justify-between bg-white  overflow-hidden'>
            <SideBarBibliote getMenuMobille={getMenuMobille} />
            <EmpruntListLivreVisitor getSizeMobille={getSizeMobille} />
        </div>
    )
}

export default EmpruntListLivreVisitorComponent
