import React, { useState } from "react";
import ListeExpedierComponent from "../../component/Secretariat/Courriers/Expedier/ListeExpedierComponent";
function ListeExpedierPage() {
  return (
    <>
      <ListeExpedierComponent />
    </>
  );
}

export default ListeExpedierPage;
