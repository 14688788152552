import React from 'react'
import SlideEncadrementNavBars from '../SlideEncadrementNavBars/SlideEncadrementNavBars'
import RetournMaterielEncadrementPerso from './RetournMaterielEncadrementPerso'
function RetournMaterielEncadrementComponentPerso() {
    return (
        <div className='flex justify-between bg-white overflow-hidden'>
            <SlideEncadrementNavBars />
            <RetournMaterielEncadrementPerso />
        </div>
    )
}

export default RetournMaterielEncadrementComponentPerso
